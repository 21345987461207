import { Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Widget = () => {
  const { t, i18n } = useTranslation();
  const locale = i18n.language.split("-")[0];

  const sendbirdUnreadCount = useSelector((state) => state.instantMessagingWS.unreadCount);

  return (
    <Link to="/instantMessaging">
      <div
        style={{
          position: "absolute",
          top: 10,
          right: 10,
          width: 10,
          height: 10,
          borderRadius: "100%",
          backgroundColor: "red",
          display: !!sendbirdUnreadCount ? "block" : "none",
        }}
      />
      <Typography.Text
        type="secondary"
        style={{
          fontSize: "12px",
          display: !!sendbirdUnreadCount ? "block" : "none",
          position: "absolute",
          top: 25,
          right: 10,
        }}
      >
        {sendbirdUnreadCount} {t("MESSAGE_UNREAD")}
        {sendbirdUnreadCount > 1 && locale === "fr" ? "s" : ""}
      </Typography.Text>
    </Link>
  );
};

export default Widget;
