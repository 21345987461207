/* eslint-disable import/no-anonymous-default-export */
import DWMTrads from "@trad/DWMProduct.json";
import CommonTrads from "./Common.json";

const trads = DWMTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.nl };
}, {});

const common = CommonTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.nl };
}, {});

export default {
  translation: {
    //COMMON
    "NoResult": "Geen resultaat",
    "Available": "Beschikbaar",
    "NotAvailable": "Niet beschikbaar",
    "In": "In",
    "SeeItinerary": "Uw route",
    "Today": "Vandaag",
    "Filters": "Filters",
    "Back": "Terug",
    "Next": "Doorgaan",
    "Finish": "Beëindigen",
    "Save": "Opslaan",
    "Floor": "Verdieping",
    "Cancel": "Annuleren",
    "Infos": "Informatie",
    "SeeMore": "Meer weergeven",
    "Full": "Vol",
    "Confirm": "Bevestigen",
    "SelectAll": "Alles selecteren",
    "All": "Allemaal",
    "PrivacyPolicy": "Privacybeleid",
    "CreateActu": "Nieuws maken",
    "CreateActuPermanent": "Beheer permanente inhoud",
    "Hidden": "Verborgen",
    "ErrorOccured": "Er is een fout opgetreden",
    "Add": "Toevoegen",
    "Refresh": "Vernieuwen",
    "Yes": "Ja",
    "Guests": "Gasten & Extern",
    "Collaborators": "Medewerkers",
    "Next_onboarding": "Als vervolg op",
    "Skip": "Sla  over",

    "Languages": "Talen",
    "French": "Frans",
    "English": "Engels",
    "Dutch": "Nederlands",
    "LanguageChangeWarnTitle": "Start de applicatie opnieuw?",
    "LanguageChangeWarnExplain": "Om de taalwijziging toe te passen, moet u de applicatie opnieuw starten. Wilt u doorgaan?",

    "OCCUPIED": "Bezet",
    "AVAILABLE": "Beschikbaar",

    // LOGIN
    "Welcome": "Welkom",
    "OnDashboard": "Log in op uw portal om verder te gaan.",
    "Login": "Mij verbinden",

    // LOADER
    "Loading": "Bezig met laden",
    "LoadingMap": "Bezig met laden van de kaart...",
    "LoadingPlanning": "De kalender laden",

    //DASHBOARD
    "booking": "reservering",
    "bookings": "reserveringen",
    "WelcomeDesk": "Receptie",

    //FastBooking
    "SpaceAvailable": "Beschikbare zalen ",
    "CurrentReservation": "Mijn Reserveringen",
    "Book": "Reserveren",
    "BookDuring": "Reserveren tijdens",
    "CancelBooking": "De reservering annuleren",
    "BackHome": "Terug naar de homepage",
    "BookingArea": "Gereserveerde zaal",
    "BookingDontWork": "De reservering is niet afgerond",
    "TryAgain": "Probeer de zaal opnieuw te reserveren.",
    "Erase": "Wis de filters",
    "Apply": "Toepassen",
    "SpacesAvailable": "Geef de zalen weer die binnenkort beschikbaar zijn",
    "ReservationDetail": "Informatie over de reservering",
    "Slots": "Tijdslot",
    "numberPeople": "Aantal personen",
    "AllSpaces": "Alle ruimtes",
    "NoResultFloor": "Geen resultaat op deze verdieping",
    "TrySearching": "Probeer op een andere verdieping te zoeken of verwijder de filters.",
    "AvailbleFloor": "Beschikbaarheid op andere verdiepingen",
    "ResultFloor": "resultaat(en) op deze verdieping",
    "Equipements": "Uitrusting",

    //GuidMe
    "FindSpace": "Voer de naam van de ruimte in",
    "LastSearch": "Mijn laatste zoekopdrachten",
    "Favoris": "Mijn favorieten",
    "Itinerary": "Route",
    "Duration": "Duur",
    "Start": "Voer een startpunt in",
    "End": "Voer een aankomstpunt in",

    //Ticketing
    "Requests": "Mijn tickets",
    "NewTicket": "Nieuw ticket",
    "New": "Nieuw",
    "TakenIntoAccount": "In aanmerking genomen",
    "Suspended": "Opschorten",
    "Close": "Gesloten",
    "NoWaiting": "Geen nieuwe ticket",
    "NoTaken": "Geen erkend ticket",
    "NoSuspended": "Geen opgeschort ticket",
    "NoClose": "Geen gesloten ticket",
    "Status": "Status",
    "Location": "Plaats",
    "Pictures": "Foto's",
    "By": "Door",
    "Category": "Categorie",
    "SelectCategory": "Kies een categorie",
    "ChooseLocation": "Stel een locatie in",
    "WriteSomething": "Voeg details over uw verzoek en de locatie toe",
    "Adds": "Documenten toevoegen",
    "YourRequest": "Uw aanvraag wordt behandeld",
    "Informed": "U wordt op de hoogte gehouden van de voortgang van uw aanvraag in de applicatie",
    "UseSearch": "Gebruik het zoekveld om een zaal te vinden.",
    "closed": "Gesloten",
    "CreateTicket": "Maak een ticket aan",
    "AddComment": "Voeg een opmerking toe",
    "AllRequest": "Al mijn tickets",
    "Summary": "Overzicht",
    "Followed": "Gevolgd",
    "Discussion": "Commentaar",
    "Subcategory": "Ondercategorie",
    "MoreDetails": "Voer een ondercategorie in",
    "Description": "Beschrijving",
    "errorTicket": "Fout bij aanmaken ticket",
    "errorDoc": "U heeft de limiet van het aantal toegestane documenten bereikt",

    /**
     * VISITORS
     */
    "visitors.delegated": "Mijn bezoeken die ik heb gedelegeerd",
    "visitors.forms.visit.create": "Nieuw bezoek aanmaken",
    "visitors.forms.visit.update": "Bezoek bijwerken",
    "visitors.forms.visit.delete": "Bezoek verwijderen",
    "visitors.forms.visit.fields.duration.day": "{{count}} dag",
    "visitors.forms.visit.fields.duration.day_other": "{{count}} dagen",
    "visitors.forms.visit.resident": "Namens iemand anders uitnodigen",
    "visitors.forms.visit.fields.resident": "Zoek een bewoner",
    "visitors.forms.visit.fields.visitors": "Gasten toevoegen",
    "visitors.forms.visit.fields.visitors.helper": "Voer de naam in van de persoon die u aan de uitnodiging wilt toevoegen. Als u deze persoon niet in de referenties kunt vinden, voert u gewoon zijn e-mailadres in.",
    "visitors.forms.user.create": "Maak gegevens van de bezoeker aan",
    "visitors.forms.user.update": "Wijzig de gegevens van de bezoeker",
    "visitors.forms.user.fields.firstname": "Voornaam",
    "visitors.forms.user.fields.lastname": "Achternaam",
    "visitors.forms.user.fields.login": "e-mailadres",
    "visitors.forms.user.fields.company": "Bedrijf",
    "visitors.forms.user.fields.function": "Functie",
    "visitors.forms.visit.detail": "Details van uw bezoek",

    /**
     * WIFI
     */
    "wifi.ssid": "Naam van het netwerk",
    "wifi.password": "Wachtwoord",
    "wifi.helper.title": "Boek tijdswinst!",
    "wifi.helper.description": "Scan deze QR-code met uw smartphone om automatisch verbinding te maken met het wifi-netwerk.",

    /**
     * MAP
     */
    "map.loading": "De kaart wordt geladen",

    /**
     * SPACE AS A SERVICE
     */
    "spas": "Ruimte als een service",
    "spas.status.PENDING": "In afwachting",
    "spas.status.PENDING_other": "In afwachting",
    "spas.status.UNASSIGNED": "Niet toegewezen",
    "spas.status.UNASSIGNED_other": "Niet toegewezen",
    "spas.status.APPROVED": "Aangifte geaccepteerd",
    "spas.status.APPROVED_other": "Aangifte geaccepteerd",
    "spas.status.DECLINED": "Geweigerd",
    "spas.status.DECLINED_other": "Geweigerd",
    "spas.status.CANCELLED": "Geannuleerd",
    "spas.status.CANCELLED_other": "Geannuleerd",
    "spas.period.MORNING": "Ochtend",
    "spas.period.AFTERNOON": "Middag",
    "spas.period.DAY": "Dag",
    "spas.type.ON_SITE": "Op kantoor",
    "spas.type.TELEWORKING": "Telewerken",
    "spas.type.TRAVEL": "Reizen",
    "spas.type.DAY_OFF": "Vrije dag",
    "spas.type.NOT_DECLARED": "Niet gedeclareerd",
    "spas.recurrence.NONE": "Geen",
    "spas.recurrence.RECURRENCE": "Herhaling:",
    "spas.recurrence.EVERY_DAY": "Alle dagen",
    "spas.recurrence.EVERY_WEEK": "Elke {{day}}",
    "spas.recurrence.EVERY_MONTH": "Alle maanden",
    "spas.recurrence.EVERY_2_WEEK": "Elke andere {{day}}",
    "spas.teleworking.limit.WEEK": "U heeft de {{count}} dagen telewerken bereikt die deze week zijn toegestaan.",
    "spas.teleworking.limit.MONTH": "U heeft de {{count}} dagen telewerken bereikt die deze maand zijn toegestaan.",
    "spas.tabs.MY_PLANNING": "Mijn planning",
    "spas.tabs.MY_TEAM": "Mijn team",
    "spas.tabs.MY_CONTACTS": "Mijn contacten",
    "spas.steps.DATES": "Stap {{count}} : Datums",
    "spas.steps.TEAM_MEMBERS": "Stap {{count}} : Teamleden",
    "spas.steps.WORKPLACE": "Stap {{count}} : Werkplek",
    "spas.steps.ROOM": "Stap {{count}} : Zaal",
    "spas.steps.REVIEW": "Stap {{count}} : Controle",
    "spas.history.createRequest": "Het verzoek is aangemaakt",
    "spas.history.approve": "Een datum is geaccepteerd",
    "spas.history.approveAll": "Alle datums zijn geaccepteerd",
    "spas.history.decline": "Er is een datum geannuleerd",
    "spas.history.declineAll": "Alle datums zijn geannuleerd",
    "spas.history.cancel": "Een datum is geannuleerd",
    "spas.history.cancelAll": "Alle datums zijn geannuleerd",
    "spas.history.overrided": "Datums zijn geannuleerd om ruimte vrij te maken",
    "spas.planning": "Planning",
    "spas.request": "Aanvraag",
    "spas.request.none": 'U heeft vandaag geen verzoek. Om er een te maken, gaat u naar de knop "Nieuwe aanvraag" bovenaan uw scherm.',
    "spas.request.details": "Details van le aanvraag",
    "spas.request.update": "Wijziging van de verklaring",
    "spas.request.new": "Nieuwe verklaring",
    "spas.request.new.team": "Nieuwe verklaring voor het team",
    "spas.request.cancel": "Annulering van de aanvraag",
    "spas.request.comments": "Opmerkingen",
    "spas.request.id.title": "ID aanvraag {{id}}",
    "spas.request.site": "Waar wilt u vandaag werken?",
    "spas.request.isSerie": "Deze reservering bevat een gebeurtenis",
    "spas.request.type.title": "Welk soort aangifte wilt u doen?",
    "spas.request.site.title": "Waar wilt u vandaag werken?",
    "spas.request.dates": "Datums",
    "spas.request.dates.title": "Wanneer wil je werken?",
    "spas.request.dates.helper": "Selecteer de datum(s) waarop je wilt werken",
    "spas.request.dates.helper2": "Selecteer de datum(s) waarop je wilt werken",
    "spas.request.dates.dateOccupied": "Deze datum is bezet in de geselecteerde periode. Het zal worden genegeerd.",
    "spas.request.dates.validator.min": "U moet minimaal één datum selecteren.",
    "spas.request.dates.validator.occupied": "Alle data zijn bezet in de geselecteerde periode. Selecteer andere data.",
    "spas.request.startDate.title": "Datum begin",
    "spas.request.startDate.helper": "Beginnen op",
    "spas.request.period.title": "Duur",
    "spas.request.period.helper": "Kies een duur",
    "spas.request.recurrence": "Herhaling",
    "spas.request.recurrence.helper": "Kies een herhaling",
    "spas.request.recurrence.multi": "U kunt geen herhaling met meerdere datums selecteren",
    "spas.request.recurrence.end": "Einddatum",
    "spas.request.members.title": "Verzoek tot",
    "spas.request.members.search": "Zoek in mijn team",
    "spas.request.members.selected": "Geselecteerd",
    "spas.request.members.noAccess": "Heeft geen toegang tot de geselecteerde sector",
    "spas.request.members.warning.availability": "Sommige gebruikers zijn niet toegevoegd omdat ze niet beschikbaar zijn.",
    "spas.request.members.warning.access": "Sommige gebruikers zijn niet toegevoegd omdat ze geen toegang hebben tot de geselecteerde sector.",
    "spas.request.roomIds.title": "Selecteer een of meer ruimtes",
    "spas.request.roomIds.warning": "Let op: Er zijn niet genoeg plaatsen beschikbaar voor het aantal geselecteerde personen. Kies een tweede ruimte of een ruimte met voldoende ruimte.",
    "spas.request.endDate": "Eindigt op {{date}}",
    "spas.request.forceEndDate": "Einddatum forceren",
    "spas.request.from": "Van: {{user}}",
    "spas.request.comment.title": "Commentaar",
    "spas.request.comment.helper": "Voeg een opmerking toe (facultatief)",
    "spas.request.actions.confirmBooking": "Bevestig de reservering",
    "spas.request.actions.book": "doorgaan",
    "spas.request.actions.map": "Weergeven op de kaart",
    "spas.request.actions.workplaces": "Zie de lijst",
    "spas.request.actions.approve": "Een plaats toewijzen",
    "spas.request.actions.approve_other": "Plaatsen toewijzen",
    "spas.request.actions.decline": "De aanvraag weigeren",
    "spas.request.actions.decline_other": "De aanvragen weigeren",
    "spas.request.actions.cancel": "Aangifte annuleren",
    "spas.request.actions.updateReservation": "Wijzig de aangifte",
    "spas.request.actions.saveReservation": "Wijzigingen opslaan",
    "spas.request.actions.updateSpace": "De toewijzing wijzigen",
    "spas.request.actions.updateSpace_other": "De toewijzingen wijzigen",
    "spas.request.actions.removeSpace": "De toewijzing verwijderen",
    "spas.request.actions.removeSpace_other": "De toewijzingen verwijderen",
    "spas.request.actions.occurence": "Alleen op deze dag",
    "spas.request.actions.serie": "De hele serie",
    "spas.request.actions.assignValidate": "De toewijzing bevestigen",
    "spas.request.actions.assignIn": "Toewijzen in {{desk}}",
    "spas.request.actions.assignAuto": "Automatisch toewijzen",
    "spas.request.actions.assignAuto.selectMore": "U moet meer zalen selecteren om voldoende plaatsen te bereiken",
    "spas.request.actions.assignAuto.helper": "Selecteer aan welke zaal(zalen) u {{count}} personen wilt toewijzen",
    "spas.request.actions.assignAuto.helper_other": "Selecteer in welke zaal(zalen) u {{count}} personen wilt toewijzen",
    "spas.request.backCalendar": "Terug naar kalender",
    "spas.request.seeDay": "zie mijn dag",
    "spas.request.reservation": "Huidig record",
    "spas.request.resaAccepted": "Aangifte geaccepteerd",
    "spas.request.resaRefused": "De aangifte is niet gedaan",
    "spas.request.tryAgain": "Probeer het nog eens",
    "spas.conflict": "Op de volgende dagen kon geen aangifte worden gedaan",
    "spas.conflict.other": "Het door jou geselecteerde bureau is niet meer beschikbaar",
    "spas.conflict.recurrence.other": "Het door u geselecteerde bureau was niet meer beschikbaar",
    "spas.conflict.recurrence.self": "U had al een andere aangifte",
    "spas.admin.header.week": "Week {{number}}",
    "spas.admin.header.weekPrevious": "Vorige week",
    "spas.admin.header.weekNext": "Volgende week",
    "spas.admin.header.pending": "Openstaande aanvragen",
    "spas.admin.header.approved": "Bevestigde aanvragen",
    "spas.admin.header.estimated": "Geschatte bezetting",
    "spas.admin.header.available": "{{count}} / {{total}} beschikbaar",
    "spas.admin.header.available_other": "{{count}} / {{total}} beschikbaar",
    "spas.admin.header.occupied": "{{count}} / {{total}} bezette plaatsen",
    "spas.admin.header.occupied_other": "{{count}} / {{total}} bezette plaatsen",
    "spas.admin.header.map": "Zie de plattegrond van het gebouw",
    "spas.admin.tabs.search": "Zoek een persoon",
    "spas.admin.tabs.selected": "{{count}} geselecteerd",
    "spas.admin.tabs.selected_other": "{{count}} geselecteerd",
    "spas.admin.tabs.resident": "Naam bewoner",
    "spas.admin.tabs.manager": "Naam manager",
    "spas.admin.tabs.from": "Auteur aanvraag ",
    "spas.admin.tabs.createdAt": "Datum aanvraag",
    "spas.admin.tabs.recurrence": "Herhaling",
    "spas.admin.tabs.space": "Plaats",
    "spas.admin.tabs.affectedAt": "Datum toewijzing",
    "spas.admin.tabs.declinedAt": "Datum weigering",
    "spas.admin.map.search.title": "Een plaats kiezen",
    "spas.admin.map.search.helper": "Gebruik de plattegrond of het zoekveld om een toe te wijzen plaats te vinden.",
    "spas.admin.map.search": "Zoek een ruimte",
    "spas.widget.noResa": "Geen reserveringen tot nu toe",
    "spas.manage.followingPerson": "Persoon (personen) gecontroleerd",
    "spas.manage.waiting.invitation": "openstaande uitnodiging(en)",
    "spas.manage.lengthPerson": "Tik hier om je openstaande uitnodigingen te bekijken",
    "spas.manage.noResult": "Geen resultaten in uw mensen gevolgd.",
    "spas.manage.addPerson": "Mensen toevoegen",
    "spas.manage.stopFollow": "Niet langer volgen",
    "spas.manage.noFollower": "Je volgt momenteel niemand.",
    "spas.manage.searchFollower": "Zoek iemand in de directory van uw organisatie en stuur een uitnodiging.",
    "spas.manage.received.invitation": "Uitnodigingen ontvangen",
    "spas.manage.shareNotebook": "Tik op een uitnodiging om je agenda te delen.",
    "spas.manage.accepted": "Accepteer de uitnodiging",
    "spas.manage.ignored": "Negeer prompt",
    "spas.messages.create.error": "Onmogelijk om de aanvraag aan te maken",
    "spas.messages.cancel.error": "Het is niet mogelijk om de aanvraag te annuleren",
    "spas.messages.decline.error": "Kan de aanvraag niet weigeren",
    "spas.messages.removeSpace.error": "Kan de toewijzing niet verwijderen",
    "spas.messages.conflicts.error": "Kan de beschikbaarheid van ruimte niet controleren",
    "spas.people.present": "Wie zal aanwezig zijn?",
    "spas.people.whoResa": "Ontdek wie er die dag aanwezig zullen zijn",
    "spas.people.noResult": "Geen resultaten in uw mensen gevolgd voor deze dag.",
    "spas.people.find": "Zoek mensen gevolgd",
    "spas.messages.cancel.success": "Er is rekening gehouden met het annuleringsverzoek.",
    "spas.request.confirm.presence": "Bevestig mijn aanwezigheid",
    "spas.request.validate": "Vevestigen",
    "spas.presence.confirmed": "Bevestigde aanwezigheid",
    "spas.presence.present": "Uw aanwezigheid is al bevestigd",
    "spas.presence.error": "Er is een fout opgetreden, uw aanwezigheid kon niet worden bevestigd. Probeer het nog eens.",
    "spas.presence.today": "Uw aanwezigheid kon niet worden bevestigd. U kunt uw aanwezigheid alleen bevestigen tijdens de betreffende reserveringsperiode.",
    "spas.presence.success": "U heeft uw aanwezigheid bevestigd",
    "spas.presence.code": "Voer de 6-cijferige code in die op het bureaulabel staat",
    "spas.place.available": "Plaatsen beschikbaar",
    "spas.workplace.title": "Werkstation",
    "spas.workplace.subtitle": "Selecteer een werkstation",
    "spas.workplace.multiple_dates": "Er zijn meerdere dagen geselecteerd, de weergave van gevolgde personen is niet beschikbaar",
    "spas.workplace.loading.title": "Werkstation zoeken",
    "spas.workplace.loading.subtitle": "We zoeken een beschikbaar werkstation voor u",
    "spas.workplace.your": "Jouw werkruimte",
    "spas.workplace.free": "Plaatsen beschikbaar",
    "spas.workplacemap.helper": "Selecteer een beschikbaar kantoor door op de kaart te klikken.",
    "spas.room.nbDesk": "{{count}} plaats",
    "spas.prsence.alreadyConfirmed": "Al bevestigd",
    "spas.presence.noConfirmed": "U kunt uw aanwezigheid niet bevestigen op een reservering die nog niet is gestart",
    "spas.request.error.sectors": "Geen sector toegewezen",
    "spas.request.error.sectors.content": "Er is op deze site geen sector aan u toegewezen.",
    "spas.request.error.date": "Begindatum groter dan einddatum",
    "spas.collabs.xDeclared": "verklaarde collaborateur",
    "spas.collabs.presence": "Aanwezigheid",
    "spas.declarations": "{{count}} aangifte",

    /**
     * PHONE BOOK
     */

    "phoneBook.seachPhoneBook": "Iemand zoeken in de directory van uw organisatie",
    "phonebook.useSearch": "Gebruik het invoerveld bovenaan het scherm om een ​​persoon te vinden door de eerste 3 tekens van hun naam of achternaam te schrijven.",
    "phoneBook.backToSearch": "Terug naar zoeken",
    "phoneBook.title": "Telefoonboek",
    "phoneBook.send.invitation": "Een uitnodiging sturen",
    "phoneBook.success": "Uitnodiging verzonden",
    "phoneBook.error": "De uitnodiging is niet verzonden",
    "phoneBook.error.alreadySend": "De uitnodiging is al verzonden",
    "phoneBook.number.contact": "Weergave van {{nbItems}}/{{totalItems}} contacten. Verfijn uw zoekopdracht om de persoon te vinden die u zoekt.",
    "phoneBook.search": "Zoek een contactpersoon",

    /**
     * CONFORT
     */

    "Stage": "Verdieping",
    "Temperature": "Temperatuur",
    "Lighting": "Verlichting",
    "Ventilation": "Ventilatie",
    "Blind": "Rolgordijn",
    "Light": "Licht",

    // SEARCH ROOM
    "SelectSpace": "Een zaal kiezen",
    "UseCard": "Gebruik het zoekveld om een te testen ruimte te vinden.",
    "ResultSearch": "Zoekresultaat",
    "SearchSpace": "Een ruimte zoeken...",

    // ERROR
    "ErrorSetLight": "Er is iets misgegaan met de verlichting",
    "ErrorSetBlind": "Er is iets misgegaan met de jaloezieën",
    "ErrorSetBlindAngle": "Er is iets misgegaan met de schuinzetting van de jaloezieën",
    "ErrorSetTemperatures": "Er is iets misgegaan met de temperatuur",
    "ErrorSetVentilation": "Er is iets misgegaan met de ventilatie",

    "ErrorModeAuto": "het is niet mogelijk om de auto-modus te starten",

    "ErrorGetLight": "Er is een fout opgetreden met de verlichting, probeer het opnieuw.",
    "ErrorGetBlind": "Er is een fout opgetreden met de jaloezieën, probeer het opnieuw.",
    "ErrorGetTemperatures": "Er is een fout opgetreden met de temperatuur, probeer het opnieuw.",
    "ErrorGetVentilation": "Er is een fout opgetreden met de ventilatie, probeer het opnieuw.",
    "ErrorGetComfort": "Het comfort van de zaal kon niet worden geladen. Voer opnieuw een zoekopdracht uit.",

    // POWERBI
    "TypeDuration": "Kies hier om de resultaten voor de hele dag of slechts voor een halve dag weer te geven.",
    "IntervalDate": "Selecteer een vooraf geselecteerd datuminterval of definieer een aangepast interval.",
    "Interval": "Interval",

    // NEWS
    "BackTo": "Terug naar {{topic}}",
    "Sure_Leave": "U verlaat de inhoudsbewerker. Je verliest je onuitgegeven werk in uitvoering. Weet je zeker dat je door wilt gaan?",
    "Yes_Leave": "Ja, verlaat",
    "Select_A_Group": "Selecteer ten minste één groep om de publicatie te valideren",
    "Content_Editing": "De inhoud wijzigen",
    "Notif_Editing": "Verandering van kennisgeving",
    "Publish": "Publiceren",
    "Update": "Bijwerken",
    "Upload": "Toevoegen",
    "Edit_Internationalized": "Selecteer de taal waarin u uw publicatie wilt bewerken.",
    "Image_Library": "Beeld bibliotheek",
    "Add_Image": "Een beeld toevoegen",
    "Select_Image": "Selecteer een beeld uit de bibliotheek",
    "Title": "Titel",
    "Notif_Trigger": "De kennisgeving activeren",
    "Subtitle": "Subtitel",
    "Choose_Title": "Kies een titel",
    "Choose_Subtitle": "Kies een sub titel",
    "Publication": "Publicatie",
    "Content_To_Be_Shawn": "De inhoud zal in de toepassing zichtbaar zijn vanaf",
    "Hide_Content": "Inhoud verbergen voor publicatie",
    "Send_Notif": "Een kennisgeving sturen",
    "Access": "Beperking van het zicht",
    "Target": "Doelstellingen",
    "Select_Group": "Om de zichtbaarheid van deze publicatie te beperken, selecteert u de gebruikersgroepen die de publicatie mogen bekijken. (Standaard is de publicatie zichtbaar voor alle groepen)",
    "Select_Group2": "Selecteer de ontvangers van de kennisgeving",
    "No_Group_Found": "Geen groepen gevonden",
    "Topics": "Topics",
    "Select_Topics": "Selecteer een of meer topics voor het nieuws",
    "No_Topic": "Geen topics beschikbaar",
    "Attach_To_Place": "Als het om een evenement gaat, kunt u een locatie aan uw publicatie toevoegen.",
    "Attach_To_Place2": "Deze inhoud koppelen aan een locatie",
    "Room": "Ruimte",
    "No_Stage_Found": "Geen vloer gevonden",
    "Select_A_Room": "Selecteer een ruimte",
    "Select_A_Stage": "Selecteer een vloer",
    "No_Room_Found": "Geen ruimte gevonden",
    "Name": "Naam",
    "Enter_Name_Place": "Noteer de naam van de plaats",
    "Publication_Preview": "Voorbeeld in de app",
    "Add_News": "Een nieuwsbericht toevoegen",
    "Add_Notif": "Een kennisgeving aanmaken",
    "Add_Topic": "Een topic toevoegen",
    "Update_Topic": "Een topic bewerken",
    "Manage_Topic": "Topic beheren",
    "Trendings": "Op de voorpagina",
    "Published_On": "Gepubliceerd op",
    "Created_On": "Gemaakt op",
    "Header_Image": "Koptekst beeld",
    "At": "Op",
    "Get_details": "Ophalen van sectie details...",
    "Get_Content": "Inhoud ophalen...",
    "Get_Content_Error": "Fout bij het ophalen van sectie details...",
    "Get_Groups": "Herstel van Swizi groepen...",
    "Get_Groups_Error": "Fout bij het ophalen van groepen ",
    "Get_Topics_Error": "Fout bij het ophalen van topic",
    "Get_Media": "Herstel van Swizi media...",
    "Get_Media_Error": "Fout bij het ophalen van Swizi groepen ",
    "Get_Resource": "Herstel van Swizi middelen...",
    "Get_Resource_Error": "Fout bij het ophalen van Swizi middelen",
    "Show_HTML": "Overslaan in HTML modus",
    "Show_Editor": "Overschakelen naar de editor modus",
    "Format_Code": "HTML code opmaken",
    "ShowMore": "Meer weergeven >",
    "Published": "Gepubliceerd {{date}}",
    "PublishedAt": "Gepubliceerd op {{date}}",
    "News": "Nieuws",
    "InTheSameTopic": "In dezelfde rubriek",
    "Event": "Evenement",
    "Event_description": "Definieer een evenement die in het nieuws moet worden weergegeven",
    "Event_startDate": "Start",
    "Event_endDate": "Einde",
    "Event_date_place": "Datum en plaats van het evenement",
    "Event_from": "Van",
    "Event_to": "Op",
    "noFloorSelect": "Geen verdieping geselecteerd",

    // AGORA
    "Places.Frequentation": "Instroom",
    "Places.Now": "Nu",
    "Places.Informations": "Informatie",
    "Places.hours": "Openingstijden",
    "Places.Location": "Zie locatie",
    "Places.Equipments": "Uitrustingen",
    "Places.others": "Anderen",
    "Places.Open": "Overt",
    "Places.Closed": "Gesloten",
    "Places.Services": "Diensten",
    "Places.Clicked": "Klik hier",

    //PUBLIC
    "Public.activated": "Uw account is geactiveerd !",
    "Public.connected": "Maak nu verbinding met onze applicatie met uw login",
    "Public.upload": "Download de mobiele app uit de applicatiewinkels en maak verbinding met uw eerder aangemaakte gebruikersnaam en wachtwoord.",
    "Public.error.activated": "Uw account is al geactiveerd",
    "Public.password.verification": "Verificatie door de gebruiker",
    "Public.password.activated": "Activeer uw account",
    "Public.password.experience": "Om van de ervaring op onze mobiele applicatie te genieten en op het webportaal, moet u uw gastaccount activeren door een wachtwoord te kiezen.",
    "Public.password.identifiant": "Login",
    "Public.password.email": "Gebruik het e-mailadres waarvan u de uitnodigingsmail hebt ontvangen.",
    "Public.password.password": "Kies een wachtwoord",
    "Public.password.obligatory": "Het wachtwoordveld is verplicht",
    "Public.password.strongPassword": "Kies een sterk wachtwoord volgens de volgende eisen.",
    "Public.password.eight": "8 tekens minimaal",
    "Public.password.one": "1 speciaal teken",
    "Public.password.maj": "1 hoofdletters",
    "Public.password.number": "1 cijfer",
    "Public.password.min": "1 kleine",
    "Public.password.confirm": "Bevestig uw wachtwoord",
    "Public.password.same": "Dezelfde wachtwoorden",
    "Public.password.active": "Mijn account activeren",
    "Public.expired.finish": "Uw reservering is voorbij",
    "Public.expired.free": "U kunt uw plaats niet vrijgeven / behouden",
    "Public.failure.request": "Uw verzoek kon niet worden verwerkt.",
    "Public.failure.spaces": "Uw verzoek om de ruimte {{title}} van {{startHour}} tot {{endHour}} te reserveren kon niet worden verwerkt vanwege een fout.",
    "Public.failure.excuse": "Wij verontschuldigen ons voor het ongemak en nodigen u uit het later nog eens te proberen.",
    "Public.success.free": "De ruimte is vrijgemaakt",
    "Public.success.spaces": "De reservering van de ruimte {{title}} van {{startHour}} tot {{endHour}} is geannuleerd.",
    "Public.success.nowFree": "Deze ruimte is nu beschikbaar voor andere boekingen.",
    "Public.keep.kept": "Uw reservering wordt bewaard",
    "Public.keep.noAlert": "U zult geen waarschuwingen meer ontvangen voor deze boeking.",
    "public.keep.spaces": "De reservering van de ruimte {{title}} van {{startHour}} tot {{endHour}} wordt bewaard.",
    "Public.password.error": "Fout bij het aanmaken van het wachtwoord",

    // CATERING
    "catering.prestationAvailable": "Beschikbare dienst(en)",
    "catering.prestationNoAvailable": "Dienst(en) niet beschikbaar",
    "catering.orderOutOfTime": "Uw bestelling is te laat, het is te laat om onderstaande diensten te boeken.",
    "catering.order": "Order a service",
    "catering.newOrder": "New request",
    "catering.orderFor": "Order for the",
    "catering.prestation": "My services in progress",
    "catering.outlook": "Ordered from Outlook",
    "catering.newPresta": "New service",
    "catering.thisSpace": "Choose this space",
    "catering.addPresta": "Add the service",
    "catering.saveOrder": "Register the order",
    "catering.askSpace": "In which space would you like to order the service?",
    "catering.orderThe": "Order the",
    "catering.addAPrestra": "Add a service",
    "catering.selectRubrique": "Select an item",
    "Total": "Total",
    "catering.resume": "Summary of the service",
    "catering.canceled": "Cancel the order",
    "catering.sureCanceled": "Are you sure you want to cancel the order?",
    "No": "No",
    "catring.successUpdate": "Successful change",
    "catering.errorUpdate": "Error when editing",
    "catering.update": "Edit the order",
    "catering.noOrder": "You can no longer edit the order",
    "amount": "Quantity",
    "obligatory": "Required field",
    "canceled": "Canceled",
    "catering.date": "Datum :",
  },
  csv: trads,
  common: common,
};
