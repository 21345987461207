import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "react-html-parser";
import moment from "moment";
import SwiziConnector from "./connectors/SwiziConnector2";
import ResourceConnector from "./connectors/ResourceConnector";
import { isEmpty } from "ramda";

import "moment/locale/fr";
import "./lang/i18n";
import "./SwiziNews.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../../services/api/Firebase/Firebase";

class SwiziNews extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: null,
      contents: null,
      medias: null,
      err: null,
      loadingLabel: null,
      selectedTopic: null,
      selectedContent: null,
      hasRM: false,
      resources: null,
      isLoading: false,
    };
  }

  async componentDidMount() {
    await this.updateComponent();
  }

  async componentDidUpdate(prevProps, prevState) {
    if (
      this.props.selectedContentId !== prevProps.selectedContentId ||
      this.props.selectedTopicId !== prevProps.selectedTopicId
    ) {
      await this.updateComponent();
    }
  }

  async updateComponent() {
    moment.locale(this.props.i18n.language);
    const { t } = this.props;
    this.t = t;

    this.setState({ isLoading: true });

    // colors
    this.applyColors();

    this.Swizi = new SwiziConnector({
      url: this.props.swiziApiUrl,
      apiKey: this.props.swiziApiKey,
      token: this.props.token,
      appId: this.props.appId,
    });

    this.ResourceManager = new ResourceConnector({ url: this.props.rmApiUrl });
    let isRMAuthenticated;
    try {
      isRMAuthenticated = await this.ResourceManager.authent(
        this.props.rmApiKey,
        this.props.rmSecretKey,
        this.props.token,
      );
    } catch (err) {
      this.setState({ err: t("Get_Resource_Error") });
    }

    let section;
    let medias;
    let contents;
    let topics;

    // get section
    try {
      section = await this.Swizi.getSection(this.props.swiziSectionId);
      this.setState({ section });
    } catch (err) {
      this.setState({ err: t("Get_Content_Error") });
    }

    if (section && section.type === "NEWS") {
      // get medias
      try {
        medias = await this.Swizi.getMedias("news-builder");
        this.setState({ medias });
      } catch (err) {
        this.setState({ err: t("Get_Medias_Error") });
      }

      // get contents
      try {
        contents = await this.Swizi.getContents(this.props.swiziSectionId, true);
        let selectedContent;
        if (this.props.selectedContentId) {
          selectedContent = contents.find((c) => c.id === parseInt(this.props.selectedContentId));
        }
        this.setState({ contents, loadingLabel: t("Get_Groups"), selectedContent });
      } catch (err) {
        this.setState({ err: t("Get_Contents_Error") });
      }

      this.setState({ isLoading: false });

      // get topics
      try {
        topics = await this.Swizi.getTopics(this.props.swiziSectionId);
        if (topics) {
          if (topics.options) section.options = { ...(section.options || {}), ...topics.options };
          if (topics.topics && topics.topics.length) {
            section.topics = topics.topics;
          }

          let selectedTopic;
          if (this.props.selectedTopicId && section.topics.length) {
            section.topics = section.topics.filter(
              (t) => t.id === parseInt(this.props.selectedTopicId),
            );
            selectedTopic = section.topics[0];
          }

          this.setState({ section, selectedTopic });
        }
      } catch (err) {
        this.setState({ err: t("Get_Topics_Error") });
      }

      // get ressources
      if (isRMAuthenticated) {
        try {
          let resources = await this.ResourceManager.getResources(this.props.i18n.language, false, [
            "title",
          ]);
          this.setState({ hasRM: true, resources });
        } catch (err) {
          this.setState({ err: t("Get_Resource_Error") });
        }
      }
    }
  }

  applyColors = () => {
    let root = document.documentElement;
    if (this.props.accentColor)
      root.style.setProperty("--SwiziNewsViewer-accent-color", this.props.accentColor);
    if (this.props.topicTitleColor)
      root.style.setProperty("--SwiziNewsViewer-topic-title-color", this.props.topicTitleColor);
    if (this.props.topicTitleBigColor)
      root.style.setProperty(
        "--SwiziNewsViewer-topic-title-big-color",
        this.props.topicTitleBigColor,
      );
    if (this.props.textColor)
      root.style.setProperty("--SwiziNewsViewer-text-color", this.props.textColor);
    if (this.props.textBackground)
      root.style.setProperty("--SwiziNewsViewer-text-background", this.props.textBackground);
  };

  getSectionTitle = (
    section = this.state.section,
    lang = this.props.i18n.language.split("-")[0],
  ) => {
    if (section && section.title && section.title.translations) {
      let sectionName = section.title.translations.find((t) => t.locale.code === lang);
      if (sectionName) return sectionName.translation;
    }
    return "";
  };

  getTopicTranslation = (topic, lang = this.props.i18n.language.split("-")[0]) => {
    if (topic && topic.label && topic.label.translations) {
      let topicName = topic.label.translations.find((t) => t.locale.code === lang);
      if (topicName) return topicName.translation;
    }
    return "";
  };

  getContentsForTopic = (topic) => {
    return (this.state.contents || []).filter((c) => {
      if (c.topics) {
        const topicsIds = c.topics.map((t) => t.id);
        return topicsIds.indexOf(topic.id) !== -1;
      }
      return false;
    });
  };

  getMedia = (content, lang = "fr") => {
    if (
      content &&
      content.header &&
      content.header.medias &&
      this.state.medias &&
      this.state.medias.content.length > 0
    ) {
      const media = content.header.medias.find((m) => m.locale.code === lang);
      if (media) {
        let mediaRef = this.state.medias.content.find((c) => c.id === media.media.id);
        if (mediaRef) {
          return mediaRef.url;
        } else if (media.media && media.media.url) {
          return media.media.url;
        }
      }
    }
    return "";
  };

  getSubtitle = (content, lang = this.props.i18n.languages[0]) => {
    let subtitle = content?.subtitle?.translations.find((t) => t.locale.code === lang)?.translation;
    if (!subtitle)
      subtitle = content?.subtitle?.translations.find((t) => t.locale.code === "en")?.translation;
    if (!subtitle)
      subtitle = content?.subtitle?.translations.find((t) => !!t.translation)?.translation;
    return subtitle || "";
  };

  getTitle = (content, lang = this.props.i18n.languages[0]) => {
    let title = content?.title?.translations.find((t) => t.locale.code === lang)?.translation;
    if (!title)
      title = content?.title?.translations.find((t) => t.locale.code === "en")?.translation;
    if (!title) title = content?.title?.translations.find((t) => !!t.translation)?.translation;
    return title || "";
  };

  getEvent = (content, lang = this.props.i18n.language.split("-")[0]) => {
    moment.locale(this.props.i18n.language);
    const { t } = this.props;
    this.t = t;
    if (content.event) {
      let title = "";
      let startDate;
      let endDate;
      let place = {
        label: null,
      };
      let campus = {
        label: this.props.campusData?.title,
      };
      const titleTranslation = content.event.title.translations.find((t) => t.locale.code === lang);
      if (titleTranslation) title = titleTranslation.translation;
      if (content.event.startDate)
        startDate = moment(Date.parse(content.event.startDate)).locale(lang).format("LLLL");
      if (content.event.endDate)
        endDate = moment(Date.parse(content.event.endDate)).locale(lang).format("LLLL");
      if (content.place && content.place.translations) {
        const placeTranslation = content.place.translations.find((t) => t.locale.code === lang);
        if (placeTranslation) place.label = placeTranslation.translation;
        if (place.label.indexOf("${SRN=") !== -1) {
          const srn = place.label.replace("${SRN=", "").replace("}", "");
          place.label = null;

          if (this.state.hasRM && this.state.resources.length > 0) {
            const resource = this.state.resources.find((r) => r.id === srn);
            if (resource) {
              place.label = resource.title;
            }
          }
        }
      }

      return (
        <div className="contentEvent" style={{ marginBottom: "16px" }}>
          <div className="title customFont">{t("Event_date_place")}</div>
          {title ? <div className="eventTitle">{title}</div> : null}
          <div style={{ display: "flex" }}>
            {startDate ? (
              <div className="eventStartDate" style={{ marginRight: "8px" }}>
                {t("Event_from")} {startDate}
              </div>
            ) : null}
            {endDate ? (
              <div className="eventEndDate">
                {t("Event_to")} {endDate}{" "}
              </div>
            ) : null}
          </div>

          {place.label ? (
            <div className="eventPlace">
              {campus.label} - {place.label}
            </div>
          ) : null}
        </div>
      );
    }
  };

  getHtmlContent = (content, lang = this.props.i18n.languages[0]) => {
    let htmlContent = content?.content?.translations.find(
      (t) => t.locale.code === lang,
    )?.translation;
    if (!htmlContent)
      htmlContent = content?.content?.translations.find((t) => t.locale.code === "en")?.translation;
    if (!htmlContent)
      htmlContent = content?.content?.translations.find((t) => !!t.translation)?.translation;
    return ReactHtmlParser(htmlContent) || "";
  };

  getOtherContentsForTopic = () => {
    moment.locale(this.props.i18n.language);
    const { t } = this.props;
    this.t = t;
    if (this.state.selectedContent && this.state.selectedTopic) {
      const contents = this.getContentsForTopic(this.state.selectedTopic)
        .filter((c) => c.id !== this.state.selectedContent.id)
        .slice(0, 2);
      if (contents.length === 0) return null;
      return (
        <>
          <div className="topicTitle">{t("InTheSameTopic")}</div>
          <div className="topicContents">
            {contents.map((c) => {
              return this.renderContentOther(c, this.state.selectedTopic);
            })}
          </div>
        </>
      );
    }
    return null;
  };

  renderContentOther = (content, topic, lang = this.props.i18n.language.split("-")[0]) => {
    moment.locale(this.props.i18n.language);
    const { t } = this.props;
    this.t = t;
    return (
      <div
        key={content.id}
        className="content"
        data-fullsize={
          (this.props.selectedContentId && parseInt(this.props.selectedContentId) === content.id) ||
          (this.state.selectedContent && parseInt(this.state.selectedContent.id) === content.id)
        }
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (this.props.onGoToNews) {
            this.props.onGoToNews(topic.id, content.id);
          } else {
            if (!this.state.selectedContent || this.state.selectedContent.id !== content.id) {
              this.setState({ selectedContent: content, selectedTopic: topic });
            }
          }
        }}
      >
        {this.props.showBackButton &&
        this.state.selectedContent &&
        this.state.selectedContent.id === content.id ? (
          <div className="closeSelected" onClick={() => this.setState({ selectedContent: null })}>
            {t("BackTo", { topic: this.getTopicTranslation(this.state.selectedTopic) })}
          </div>
        ) : null}
        <div className="contentMedia" style={{ width: "100%" }}>
          {content.header ? (
            <img src={this.getMedia(content)} />
          ) : (
            <div style={{ backgroundColor: "white", height: "100%" }}></div>
          )}
        </div>
        <div
          className={
            "contentTitle" +
            (this.state.selectedContent && this.state.selectedContent.id === content.id
              ? " customFont"
              : "")
          }
        >
          {this.getTitle(content)}
        </div>
        <div className="contentDate">
          {this.state.selectedContent && this.state.selectedContent.id === content.id
            ? t("PublishedAt", {
                date: moment(content.publication).locale(lang).format("LLLL"),
              })
            : t("Published", { date: moment(content.publication).locale(lang).fromNow() })}
        </div>
        {this.state.selectedContent && this.state.selectedContent.id === content.id ? (
          <div className="contentSubTitle">{this.getSubtitle(content)}</div>
        ) : null}
        {this.state.selectedContent && this.state.selectedContent.id === content.id ? (
          <div className="htmlContentAndOther">
            <div className="contentHTML">
              {this.getEvent(content)}
              {this.getHtmlContent(content)}
            </div>
            <div className="contentOtherInSameTopics">{this.getOtherContentsForTopic()}</div>
          </div>
        ) : null}
      </div>
    );
  };

  renderContent = (content, topic, highlight, lang = this.props.i18n.language.split("-")[0]) => {
    moment.locale(this.props.i18n.language);
    const { t } = this.props;
    this.t = t;
    return (
      <div
        key={content.id}
        className="content"
        data-fullsize={
          (this.props.selectedContentId && parseInt(this.props.selectedContentId) === content.id) ||
          (this.state.selectedContent && parseInt(this.state.selectedContent.id) === content.id)
        }
        onClick={(e) => {
          if (this.props.onGoToNews) {
            this.props.onGoToNews(topic.id, content.id);
          } else {
            if (!this.state.selectedContent || this.state.selectedContent.id !== content.id) {
              this.setState({ selectedContent: content, selectedTopic: topic });
            }
          }
          if (highlight) {
            logEvent(analytics, "news_highlight_news", {
              news_name: content.title.translations.find((t) => t.locale.code === lang)
                ?.translation,
            });
          }
          logEvent(analytics, "news_select_details", {
            news_name: content.title.translations.find((t) => t.locale.code === lang)?.translation,
          });
        }}
      >
        {this.props.showBackButton &&
        this.state.selectedContent &&
        this.state.selectedContent.id === content.id ? (
          <div className="closeSelected" onClick={() => this.setState({ selectedContent: null })}>
            {t("BackTo", { topic: this.getTopicTranslation(this.state.selectedTopic) })}
          </div>
        ) : null}
        <div
          className={
            "contentMedia" +
            (this.state.selectedContent && this.state.selectedContent.id === content.id
              ? " customMedia"
              : "")
          }
        >
          {content.header ? (
            <img src={this.getMedia(content)} />
          ) : (
            <div style={{ backgroundColor: "white", height: "100%" }}></div>
          )}
        </div>
        <div
          className={
            "contentTitle" +
            (this.state.selectedContent && this.state.selectedContent.id === content.id
              ? " customFont"
              : "")
          }
        >
          {this.getTitle(content)}
        </div>
        {this.props.newsInfo.find((n) => n.id === this.state.section.id).cold ? null : (
          <div
            className={
              "contentDate" +
              (this.state.selectedContent && this.state.selectedContent.id === content.id
                ? " customFont"
                : "")
            }
          >
            {this.state.selectedContent && this.state.selectedContent.id === content.id
              ? t("PublishedAt", {
                  date: moment(content.publication).locale(lang).format("LLLL"),
                })
              : t("Published", { date: moment(content.publication).locale(lang).fromNow() })}
          </div>
        )}

        {this.state.selectedContent && this.state.selectedContent.id === content.id ? (
          <div className="contentSubTitle">{this.getSubtitle(content)}</div>
        ) : null}
        {this.state.selectedContent && this.state.selectedContent.id === content.id ? (
          <div className="htmlContentAndOther ql-editor">
            <div className="contentHTML">
              {this.getEvent(content)}
              {this.getHtmlContent(content)}
            </div>
            <div className="contentOtherInSameTopics">{this.getOtherContentsForTopic()}</div>
          </div>
        ) : null}
      </div>
    );
  };

  renderTrendings() {
    const contents = (this.state.contents || []).slice(0, 2);
    moment.locale(this.props.i18n.language);
    const { t } = this.props;
    this.t = t;
    return (
      <div className="trendings" data-preview={this.props.showTrendingsOnly}>
        <div className="topicTitle">
          {this.props.showTrendingsOnly ? this.getSectionTitle() : t("Trendings")}
          {this.props.showTrendingsOnly && this.props.onGoToNews ? (
            <div className="topicShowMore" onClick={() => this.props.onGoToNews()}>
              {t("ShowMore")}
            </div>
          ) : null}
        </div>
        <div className="topicContents">
          {contents.map((c) => {
            let topicForContent;
            let highlight = true;
            if (c.topics && c.topics.length > 0)
              topicForContent = this.state.section.topics.find((t) => t.id === c.topics[0].id);
            return this.renderContent(c, topicForContent, highlight);
          })}
        </div>
      </div>
    );
  }

  renderTopic(topic, lang = this.props.i18n.language.split("-")[0]) {
    moment.locale(this.props.i18n.language);
    const { t } = this.props;
    this.t = t;
    if (this.props.selectedTopicId) {
      if (parseInt(this.props.selectedTopicId) !== topic.id) return null;
    }

    let contents = this.getContentsForTopic(topic);
    // if (!this.state.selectedTopic) {
    //   contents = contents.slice(0, 3);
    // }
    return (
      <div key={topic.id}>
        {this.props.showBackButton && this.state.selectedTopic && !this.state.selectedContent ? (
          <div className="closeSelected" onClick={() => this.setState({ selectedTopic: null })}>
            {t("Back")}
          </div>
        ) : null}
        {!isEmpty(contents) ? (
          <>
            {!this.props.selectedContentId && !this.state.selectedContent ? (
              <div className="topicTitle">
                {this.getTopicTranslation(topic)}
                {!this.state.selectedTopic ? (
                  contents.length > 3 ? (
                    <div
                      className="topicShowMore"
                      onClick={() => {
                        if (this.props.onGoToNews) {
                          this.props.onGoToNews(topic.id, null);
                          logEvent(analytics, "news_access_topic_list", {
                            topic_name: topic.label.translations.find((t) => t.locale.code === lang)
                              .translation,
                          });
                        } else {
                          this.setState({ selectedTopic: topic });
                        }
                      }}
                    >
                      {t("ShowMore")}
                    </div>
                  ) : null
                ) : null}
              </div>
            ) : null}
            {!this.state.selectedTopic ? (
              <div className={"topicContents topics" + contents.length}>
                {contents.slice(0, 3).map((c) => {
                  if (this.props.selectedContentId)
                    return c.id === parseInt(this.props.selectedContentId)
                      ? this.renderContent(c, topic)
                      : null;
                  else if (this.state.selectedContent)
                    return c.id === parseInt(this.state.selectedContent.id)
                      ? this.renderContent(c, topic)
                      : null;
                  return this.renderContent(c, topic);
                })}
              </div>
            ) : (
              <div className={"topicContents topics" + contents.length}>
                {contents.map((c) => {
                  if (this.props.selectedContentId)
                    return c.id === parseInt(this.props.selectedContentId)
                      ? this.renderContent(c, topic)
                      : null;
                  else if (this.state.selectedContent)
                    return c.id === parseInt(this.state.selectedContent.id)
                      ? this.renderContent(c, topic)
                      : null;
                  return this.renderContent(c, topic);
                })}
              </div>
            )}
          </>
        ) : null}
      </div>
    );
  }

  renderSectionContent() {
    return (
      <>
        {!this.props.selectedTopicId &&
        !this.state.selectedTopic &&
        !this.props.selectedContentId &&
        !this.state.selectedContent &&
        this.state.section.options &&
        this.state.section.options.showTrendings
          ? this.renderTrendings()
          : null}
        {!this.props.showTrendingsOnly &&
        this.state.section.topics &&
        this.state.section.topics.length > 0
          ? this.state.section.topics.map((t) => {
              if (this.state.selectedTopic)
                return t.id === this.state.selectedTopic.id ? this.renderTopic(t) : null;
              return this.renderTopic(t);
            })
          : null}
        <div className="swiziNewsLoader" data-show={this.state.isLoading}>
          <div className="lds-roller">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <div className="swiziNews">
        {this.state.section && this.state.section.type === "NEWS"
          ? this.renderSectionContent()
          : null}
      </div>
    );
  }
}

export default withTranslation()(SwiziNews);
