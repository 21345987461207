import { Card, Modal, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import Glyph from "../../../../Common/Glyph/Glyph";

const EditReferentialLeft = ({
  referential,
  handleCreateRef,
  selectedRef,
  saveEnabled,
  section,
}) => {
  const { colors, symbols } = useDesignTokens();
  const { siteId, stuffId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();

  const handleConfirm = (url) => {
    Modal.confirm({
      title: t("ticketingv2.changesNotSaved"),
      content: t("ticketingv2.changeRefConfirm"),
      onOk: () => {
        history.push(url);
      },
    });
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <Typography.Text strong style={{ fontSize: 17 }}>
        {t("ticketingv2.ticketTypes")}
      </Typography.Text>
      {referential?.map((item) => (
        <div key={item.id}>
          <div
            style={{ cursor: item.id === parseInt(stuffId) ? "default" : "pointer" }}
            onClick={(e) => {
              if (saveEnabled) {
                e.preventDefault();
                handleConfirm(`/${section}/referential/${siteId}/${item.id}`);
              } else {
                history.push(`/${section}/referential/${siteId}/${item.id}`);
              }
            }}
          >
            <Card
              style={{ width: "100%" }}
              bodyStyle={{
                border:
                  item.id === selectedRef?.id
                    ? `2px solid ${colors.primary_base}`
                    : "2px solid transparent",
                borderRadius: symbols.base_shape.radius,
                padding: 8,
              }}
            >
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                  <Glyph
                    name={item.icon || "search"}
                    style={{
                      fontSize: 24,
                      color: item.enabled ? colors.secondary_base : colors.grey_40,
                    }}
                  />
                  <Typography.Text
                    strong
                    style={{ fontSize: 14, color: item.enabled ? "black" : colors.grey_40 }}
                  >
                    {item?.name}
                  </Typography.Text>
                </div>
                <Glyph
                  name={
                    item.id === selectedRef?.id ||
                    item.id === selectedRef?.parentTypeId ||
                    item.id === selectedRef?.parentCategoryId
                      ? "expand_less"
                      : "expand_more"
                  }
                  style={{ fontSize: 24 }}
                />
              </div>
            </Card>
          </div>
          <div
            style={{
              display:
                item.id === selectedRef?.id ||
                item.id === selectedRef?.parentTypeId ||
                item.id === selectedRef?.parentCategoryId
                  ? "flex"
                  : "none",
              transition: "height 0.3s ease",
              height:
                item.id === selectedRef?.id ||
                item.id === selectedRef?.parentTypeId ||
                item.id === selectedRef?.parentCategoryId
                  ? "100%"
                  : "0%",
              overflow: "hidden",
              flexDirection: "column",
              alignItems: "center",
              marginTop: -symbols.base_shape.radius,
              paddingTop: symbols.base_shape.radius + 8,
              backgroundColor: colors.light_background,
              borderBottomLeftRadius: symbols.base_shape.radius,
              borderBottomRightRadius: symbols.base_shape.radius,
            }}
          >
            <div className="referential-categories" style={{ width: "100%" }}>
              {/* categories */}
              {item.categories?.map((category, i) => (
                <div
                  key={category.id}
                  style={{
                    borderTop:
                      i === 0 || category.id === selectedRef?.id
                        ? "1px solid transparent"
                        : `1px solid ${colors.grey_40}`,
                  }}
                >
                  <div
                    style={{ cursor: category.id === parseInt(stuffId) ? "default" : "pointer" }}
                    onClick={(e) => {
                      if (saveEnabled) {
                        e.preventDefault();
                        handleConfirm(`/${section}/referential/${siteId}/${category.id}`);
                      } else {
                        history.push(`/${section}/referential/${siteId}/${category.id}`);
                      }
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        padding: "8px 10px 8px 16px",
                        border:
                          category.id === selectedRef?.id
                            ? `2px solid ${colors.primary_base}`
                            : `2px solid ${colors.light_background}`,
                        borderRadius: symbols.base_shape.radius,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            gap: 8,
                          }}
                        >
                          <Typography.Text
                            strong={category.id === selectedRef?.id}
                            style={{
                              fontSize: 14,
                              color: category.enabled ? "black" : colors.grey_40,
                            }}
                          >
                            {category.name}
                          </Typography.Text>
                        </div>
                        <Glyph
                          name={
                            category.id === selectedRef?.id ||
                            category.id === selectedRef?.parentCategoryId
                              ? "expand_less"
                              : "expand_more"
                          }
                          style={{ fontSize: 20, color: colors.grey_60 }}
                        />
                      </div>
                    </div>
                  </div>

                  {(category.id === selectedRef?.id ||
                    category.id === selectedRef?.parentCategoryId) && (
                    <div
                      style={{
                        paddingLeft: 16,
                        paddingRight: 8,
                        marginBottom: 0,
                        paddingTop: 0,
                      }}
                    >
                      {/* subCategories */}
                      {category.subCategories?.map((subCategory) => (
                        <div
                          key={subCategory.id}
                          style={{
                            cursor: subCategory.id === parseInt(stuffId) ? "default" : "pointer",
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            justifyContent: "start",
                          }}
                          onClick={(e) => {
                            if (saveEnabled) {
                              e.preventDefault();
                              handleConfirm(`/${section}/referential/${siteId}/${subCategory.id}`);
                            } else {
                              history.push(`/${section}/referential/${siteId}/${subCategory.id}`);
                            }
                          }}
                        >
                          {/* L 
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginRight: 8,
                            }}
                          >
                            <span
                              style={{
                                height: 16,
                                width: 10,
                                borderBottom: `2px solid ${colors.grey_40}`,
                                borderLeft: `2px solid ${colors.grey_40}`,
                              }}
                            />
                            <span
                              style={{
                                height: 16,
                                width: 10,
                                borderLeft: `2px solid ${colors.grey_40}`,
                              }}
                            />
                          </div>
                          */}
                          <Glyph
                            name="subdirectory_arrow_right"
                            style={{ fontSize: 20, color: colors.grey_60, marginTop: -6 }}
                          />
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              width: "100%",
                              border: `2px solid ${
                                subCategory.id === selectedRef?.id
                                  ? colors.primary_base
                                  : "transparent"
                              }`,
                              borderRadius: symbols.base_shape.radius,
                              padding: "4px 8px 4px 10px",
                              marginLeft: 4,
                            }}
                          >
                            <Typography.Text
                              strong={subCategory.id === selectedRef?.id}
                              style={{
                                fontSize: 12,
                                color: subCategory.enabled ? "black" : colors.grey_40,
                              }}
                            >
                              {subCategory.name}
                            </Typography.Text>
                            {/*<Glyph
                              name="arrow_right"
                              style={{ fontSize: 20, color: colors.grey_60 }}
                            />*/}
                          </div>
                        </div>
                      ))}
                      {/* add subCategories button */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          cursor: "pointer",
                          width: "100%",
                          justifyContent: "start",
                        }}
                        onClick={() => handleCreateRef("subCategory")}
                      >
                        {/* L
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            marginRight: 8,
                          }}
                        >
                          <span
                            style={{
                              height: 16,
                              width: 10,
                              borderBottom: `2px solid ${colors.grey_40}`,
                              borderLeft: `2px solid ${colors.grey_40}`,
                            }}
                          />
                          <span
                            style={{
                              height: 16,
                              width: 10,
                              borderLeft: "2px solid transparent",
                            }}
                          />
                        </div> */}
                        <Glyph
                          name="subdirectory_arrow_right"
                          style={{ fontSize: 20, color: colors.grey_60, marginTop: -6 }}
                        />
                        <div
                          style={{
                            display: "flex",
                            gap: 8,
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            padding: "4px 8px 4px 10px",
                            marginLeft: 4,
                            border: "2px solid transparent",
                          }}
                        >
                          <Typography.Text
                            strong
                            style={{ fontSize: 12, color: colors.primary_base }}
                          >
                            {t("ticketingv2.newSubCat")}
                          </Typography.Text>
                          {/*<Glyph
                            name="add_circle"
                            style={{
                              fontSize: 16,
                              color: colors.primary_base,
                              marginRight: 2,
                              marginTop: -4,
                            }}
                          />*/}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              ))}
              {/* add category button */}
              <div>
                <div
                  style={{
                    width: "100%",
                    padding: "8px 16px",
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 8,
                    //marginBottom: 8,
                    borderTop: !!item?.categories?.length ? `1px solid ${colors.grey_40}` : "",
                  }}
                  onClick={() => handleCreateRef("category")}
                >
                  <Typography.Text strong style={{ fontSize: 14, color: colors.primary_base }}>
                    {t("ticketingv2.newCat")}
                  </Typography.Text>
                  <Glyph
                    name={"add"}
                    style={{ fontSize: 20, color: colors.primary_base, marginRight: -4 }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}

      <Card
        style={{ width: "100%" }}
        bodyStyle={{
          border: "2px solid transparent",
          borderRadius: symbols.base_shape.radius,
          cursor: "pointer",
          padding: 8,
        }}
        onClick={() => handleCreateRef("type")}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", gap: 8 }}>
            <Typography.Text strong style={{ fontSize: 14, color: colors.primary_base }}>
              {t("ticketingv2.newType")}
            </Typography.Text>
          </div>
          <Glyph name="add" style={{ fontSize: 24, color: colors.primary_base }} />
        </div>
      </Card>
    </div>
  );
};

export default EditReferentialLeft;
