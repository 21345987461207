import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  accessCode: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// QRCODE ///////////////////////////////
export const getAccessCode = thunk("qrCode/getAccessCode", async ({ siteId }) => {
  const accessCode = await DWMConnector.getAccessCode(siteId);
  return accessCode;
});

EXTRA_REDUCERS[getAccessCode.fulfilled] = (state, action) => {
  state.accessCode = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const qrCodeSlice = createSlice({
  name: "qrCode",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default qrCodeSlice.reducer;
