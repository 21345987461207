import { createAsyncThunk as thunk, createSlice, createSelector } from "@reduxjs/toolkit";
import { evolve, head } from "ramda";

import DWMConnector from "../../api/DWMConnector";
import { selectedCampus } from "./UserWS";

const INITIAL_STATE = {};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CAMPUS DATA ///////////////////////////////
export const getMapData = thunk("mapData/getMapData", async ({ locale, siteId }) => {
  // const campusId = selectedCampus(state)?.id;
  const data = await DWMConnector.campusData(locale, siteId);
  return { siteId, data };
});

EXTRA_REDUCERS[getMapData.fulfilled] = (state, action) => {
  state[action.payload.siteId] = evolve({ mapData: head }, action.payload.data);
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const mapDataSlice = createSlice({
  name: "mapData",
  initialState: INITIAL_STATE,
  reducers: {
    updateMapData(state, action) {
      state[action.payload.siteId] = evolve({ mapData: head }, action.payload.data);
    },
  },
  extraReducers: EXTRA_REDUCERS,
});

export const mapDataSelector = createSelector(
  (state) => state.mapDataWS,
  (state, siteId) => siteId || selectedCampus(state)?.id,
  (mapData, siteId) => {
    logger.log("Evaluate mapDataSelector", siteId);
    return mapData?.[+siteId]?.mapData;
  },
);

export const { updateMapData } = mapDataSlice.actions;
export default mapDataSlice.reducer;
