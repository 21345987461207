import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import { useMapLayer } from "../../Common/Map/useMap";
import { TitleSource } from "../../Common/Teleporters/Title";
import GuidMeSearch from "./GuidMeSearch";
import Itinerary from "./Itinerary";
import RoomGuidMe from "./RoomGuidMe";

const mapOptions = {
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 358,
  },
};

const GuidMe = ({ setIsFloor }) => {
  useMapLayer(mapOptions);
  const { t } = useTranslation();

  return (
    <>
      <TitleSource>{t("guide_me_title", { ns: "csv" })}</TitleSource>
      <Switch>
        <Route exact path="/indoorMapping">
          <GuidMeSearch setIsFloor={setIsFloor} />
        </Route>
        <Route path="/indoorMapping/itinerary">
          <Itinerary />
        </Route>
        <Route path="/indoorMapping/:id">
          <RoomGuidMe />
        </Route>
      </Switch>
    </>
  );
};

export default GuidMe;
