import { Typography } from "antd";

import Glyph from "../Glyph/Glyph";

import styles from "./Checkbox.module.less";
import useDesignTokens from "../../../hook/useDesignTokens";

const Checkbox = ({ text, glyph, value, onClick, checked, style, textStyle }) => {
  const { size } = useDesignTokens();
  return (
    <article style={style} className={styles["checkbox"]}>
      <input
        type="checkbox"
        className={styles["checkbox__input"]}
        checked={checked}
        onClick={onClick}
        value={value}
      />
      <Typography.Text
        className={styles["checkbox__text"]}
        style={{ justifyContent: !style ? "center" : "flex-start", ...textStyle }}
      >
        {glyph && <Glyph name={glyph} style={{ fontSize: size.l, marginRight: "8px" }} />}

        {text}
      </Typography.Text>
    </article>
  );
};

export default Checkbox;
