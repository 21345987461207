import { useTranslation } from "react-i18next";
import { TitleSource } from "../../Common/Teleporters/Title";
import { Card, Col, Grid, Row, Typography } from "antd";
import Tile from "../../Common/Tile/Tile";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import MapsGoogleAroundMe from "./GoogleMap/MapsGoogleAroundMe";
import Search from "./Search";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isNil } from "ramda";
import PlaceAroundMe from "./PlaceAroundMe";
import { getAroundMe } from "../../../services/redux/services/AroundMeWS";

const AroundMe = () => {
  const { t } = useTranslation();
  const { colors, size } = useDesignTokens();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  const aroundMe = useSelector((state) => state.aroundMeWS.aroundMe);

  const [categorie, setCategorie] = useState(null);
  const [place, setPlace] = useState(null);

  useEffect(() => {
    dispatch(getAroundMe());
  }, [dispatch]);

  return (
    <>
      <TitleSource>{t("around_me", { ns: "csv" })}</TitleSource>

      <MapsGoogleAroundMe categorie={categorie} place={place} setPlace={setPlace} />

      <div style={{ padding: "16px 16px 0px 16px", display: "flex" }}>
        <Card
          style={{
            width: screens.xl ? "35%" : 350,
            minwidth: screens.xl ? "35%" : 350,
            height: "fit-content",
            overflow: "auto",
            maxHeight: "100%",
          }}
          title={
            <div style={{ display: "flex" }}>
              {(!isNil(categorie) || !isNil(place)) && (
                <div
                  style={{
                    backgroundColor: colors?.grey_20,
                    minHeight: "30px",
                    height: "30px",
                    minWidth: "30px",
                    width: "30px",
                    borderRadius: "100px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "8px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (!isNil(place)) {
                      setPlace(null);
                    } else {
                      setCategorie(null);
                    }
                  }}
                >
                  <Glyph
                    name="arrow_back_ios"
                    style={{
                      verticalAlign: "-2px",
                      fontSize: size.xl,
                      color: "black",
                    }}
                  />
                </div>
              )}

              <div>
                <Typography.Title level={5} ellipsis style={{ textWrap: "wrap" }}>
                  {!isNil(categorie) && isNil(place)
                    ? t(categorie?.title)
                    : !isNil(place)
                    ? place?.title
                    : t("around_me", { ns: "csv" })}
                </Typography.Title>

                {!isNil(categorie) && isNil(place) ? (
                  <Typography.Text type="secondary" strong ellipsis>
                    {categorie?.places?.length + " " + t("Result")}
                  </Typography.Text>
                ) : !isNil(place) ? (
                  place?.subtitle && (
                    <Typography.Text type="secondary" strong ellipsis>
                      {place?.subtitle}
                    </Typography.Text>
                  )
                ) : (
                  <Typography.Text type="secondary" strong ellipsis>
                    {t("aroundMe.select.categorie")}
                  </Typography.Text>
                )}
              </div>
            </div>
          }
        >
          {isNil(categorie) && (
            <Row gutter={[10, 10]}>
              {aroundMe?.categories?.map((around, index) => (
                <Col span={12} key={index}>
                  <Tile
                    style={{
                      height: "100%",
                      display: "flex",
                      flexFlow: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "10px",
                      width: "100%",
                      margin: "0",
                    }}
                    onClick={() => {
                      setCategorie(around);
                    }}
                  >
                    <Glyph name={around?.icon} style={{ color: colors?.interactive_03 }} />
                    <Typography.Text strong>{t(around?.title)}</Typography.Text>
                  </Tile>
                </Col>
              ))}
            </Row>
          )}

          {!isNil(categorie) && isNil(place) && (
            <Search categorie={categorie} setPlace={setPlace} />
          )}
          {!isNil(place) && <PlaceAroundMe place={place} />}
        </Card>
      </div>
    </>
  );
};

export default AroundMe;
