import React from "react";
import { Button, Modal, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context";

const LeaveChannelModal = ({
  showLeaveChannelModal,
  setShowLeaveChannelModal,
  setCurrentChannel,
  setQuery,
}) => {
  const { t } = useTranslation();
  const channelListContext = useChannelListContext();

  const handleLeaveChannel = async () => {
    try {
      await channelListContext.currentChannel.leave();
    } catch (error) {
      logger.error("Erreur lors de la suppression du canal :", error);
    } finally {
      const tempChannels = channelListContext.allChannels.filter(
        (channel) => channel.url !== channelListContext.currentChannel.url,
      );
      setCurrentChannel(tempChannels[0] || {});
      const query = {
        channelListQuery: {
          includeEmpty: true,
          channelNameContainsFilter: "",
        },
      };
      setQuery(query);
      setShowLeaveChannelModal(false);
    }
  };

  return (
    <Modal
      open={showLeaveChannelModal}
      onCancel={() => setShowLeaveChannelModal(false)}
      footer={null}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "10px",
        }}
      >
        <Typography.Title level={4}>{t("MODAL__LEAVE_CHANNEL__TITLE")}</Typography.Title>

        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <Button type="primary" style={{ width: "200px" }} onClick={handleLeaveChannel}>
            {t("CHANNEL_PREVIEW_MOBILE_LEAVE")}
          </Button>
          <Button
            type="ghost"
            style={{
              width: "200px",
            }}
            onClick={() => setShowLeaveChannelModal(false)}
          >
            {t("BUTTON__CANCEL")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default LeaveChannelModal;
