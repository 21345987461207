import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Modal, Progress, Row, Space, Tag, Typography } from "antd";
import { logEvent } from "firebase/analytics";
import moment from "moment";
import { T, clone, cond, equals } from "ramda";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as Confirm } from "../../../../../assets/svg/confirmation.svg";
import { ReactComponent as Error } from "../../../../../assets/svg/error.svg";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { analytics } from "../../../../../services/api/Firebase/Firebase";
import { getMapData } from "../../../../../services/redux/services/MapDataWS";
import {
  createRequest,
  createTeamRequest,
  listDeskAvailability,
} from "../../../../../services/redux/services/SpaceService";
import Glyph from "../../../../Common/Glyph/Glyph";
import StepsBars from "../../../../Common/StepsBars/StepsBars";
import styles from "../../SpaceBooking.module.less";
import { DAY_TYPES, STEPS } from "../../SpaceBookingResident";
import CreateStepDate from "./Steps/CreateStepDate";
import CreateStepReview from "./Steps/CreateStepReview";
import CreateStepRoom from "./Steps/CreateStepRoom";
import CreateStepTeamMembers from "./Steps/CreateStepTeamMembers";
import CreateStepsType from "./Steps/CreateStepType";
import CreateStepWorkplace from "./Steps/CreateStepWorkplace";

const PROGRESS_TOTAL_DURATION = 5000;

const AnimatedProgress = ({ status, onComplete }) => {
  const interval = useRef(null);

  const [percent, setPercent] = useState(0);

  useEffect(() => {
    setPercent(0);
    interval.current = setInterval(() => {
      setPercent((i) => {
        const newPercent = i + (100 / PROGRESS_TOTAL_DURATION) * 10;
        if (newPercent >= 100 && interval.current) {
          clearInterval(interval.current);
          interval.current = null;
          onComplete();
        }
        return newPercent;
      });
    }, 10);
    return () => {
      if (!interval.current) return;
      clearInterval(interval.current);
      interval.current = null;
    };
  }, [onComplete]);

  return (
    <Progress
      type="circle"
      percent={status === "loading" ? 60 : percent}
      style={{
        display: "flex",
        justifyContent: "end",
        animation: status === "loading" ? "rotating 2s linear infinite" : "none",
      }}
      width={80}
      format={() =>
        cond([
          [equals("success"), () => <CheckOutlined />],
          [equals("error"), () => <CloseOutlined />],
          [T, () => null],
        ])(status)
      }
      status={cond([
        [equals("success"), () => "success"],
        [equals("error"), () => "exception"],
        [T, () => "normal"],
      ])(status)}
    />
  );
};

const CreateRequestModal = ({ mode = "self", visible, onFinish, onCancel }) => {
  const [formDayType] = Form.useForm();
  const [formDate] = Form.useForm();
  const [formTeamMembers] = Form.useForm();
  const [formWorkplace] = Form.useForm();
  const [formRoom] = Form.useForm();

  const { colors, size } = useDesignTokens();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const campus = useSelector((state) => state.clientsWS.campus);

  const availableSites = useMemo(() => {
    return sites
      ?.filter((s) => !!s.spaceBooking && !!s.sectors?.length)
      ?.filter((s) => mode === "self" || s.spaceBooking.type !== 1);
  }, [mode, sites]);

  const DEFAULT_DATE =
    typeof visible === "string"
      ? moment.max(moment(visible).startOf("day"), moment().startOf("day")).toISOString()
      : moment().startOf("day").toISOString();
  const DEFAULT_SITE_ID =
    availableSites?.find((s) => s.id === campus?.mapData?.id)?.id || availableSites[0]?.id;
  const DEFAULT_SECTOR_ID = sites?.find((s) => s.id === DEFAULT_SITE_ID)?.sectors[0]?.id;
  const DEFAULT_VALUES = useMemo(
    () => ({
      type: DAY_TYPES.ON_SITE,
      dates: [moment(DEFAULT_DATE)],
      startDate: moment(DEFAULT_DATE),
      siteId: DEFAULT_SITE_ID,
      sectorId: DEFAULT_SECTOR_ID,
      period: "DAY",
      recurrence: undefined,
    }),
    [DEFAULT_DATE, DEFAULT_SECTOR_ID, DEFAULT_SITE_ID],
  );

  const [step, setStep] = useState(STEPS.DAY_TYPE);
  const [statusRequest, setStatusRequest] = useState();
  const [statusConflicts, setStatusConflicts] = useState();
  const [values, setValues] = useState(DEFAULT_VALUES);

  useEffect(() => {
    if (visible) {
      setStep(STEPS.DAY_TYPE);
      setStatusRequest(undefined);
      setValues(DEFAULT_VALUES);
      formDayType.resetFields();
      formDate.resetFields();
      formWorkplace.resetFields();
    }
  }, [DEFAULT_VALUES, formDate, formDayType, formWorkplace, visible]);

  useEffect(() => {
    if (step !== STEPS.WORKPLACE && step !== STEPS.ROOM) return;
    dispatch(
      listDeskAvailability({
        siteId: values.siteId,
        sectorId: values.sectorId,
        period: values.period,
        dates: values.dates?.length
          ? values.dates.map((d) => moment(d).format("YYYY-MM-DD"))
          : null,
        startDate: values.startDate ? moment(values.startDate).format("YYYY-MM-DD") : null,
        endDate: values.endDate ? moment(values.endDate).format("YYYY-MM-DD") : null,
        recurrence: values.recurrence,
      }),
    );
  }, [
    dispatch,
    step,
    values.dates,
    values.endDate,
    values.period,
    values.recurrence,
    values.sectorId,
    values.siteId,
    values.startDate,
  ]);

  const locale = i18n.language.split("-")[0];
  useEffect(() => {
    if (values.siteId) dispatch(getMapData({ locale, siteId: values.siteId }));
  }, [dispatch, locale, values.siteId]);

  const handleCreateRequest = useCallback(async () => {
    logger.log("handleCreateRequest", values);

    const action = mode === "team" ? createTeamRequest : createRequest;

    const data = clone(values);
    if (data.dates?.length === 1) {
      data.startDate = data.dates[0];
      data.endDate = data.dates[0];
      data.dates = undefined;
    }

    const params = {
      ...data,
      dates: data.dates?.map((d) => d.format("YYYY-MM-DD")),
      startDate: data.startDate?.format("YYYY-MM-DD"),
      endDate: data.endDate?.format("YYYY-MM-DD"),
      forUserIds: data.users?.map((u) => +u.id),
      comment: " ",
    };

    setStatusRequest("loading");
    setStatusConflicts(null);
    dispatch(action(params))
      .unwrap()
      .then((res) => {
        logEvent(analytics, "spaas_book_desk", {
          type: sites?.find((s) => s.id === values.siteId)?.spaceBooking?.type,
          campus_name: sites?.find((s) => s.id === values.siteId)?.label,
        });

        if (res?.noSlots) {
          setStatusRequest("conflict");
        } else if (res?.excludedCount) {
          setStatusRequest("partial");
          setStatusConflicts(res);
        } else {
          setStatusRequest("success");
        }
      })
      .catch((error) => {
        logger.error(error);
        setStatusRequest("error");
      })
      .finally(() => {
        setStep(STEPS.VALIDATION);
      });
  }, [dispatch, mode, sites, values]);

  const spaceBookingType = useMemo(() => {
    return sites?.find((c) => c.id === values.siteId)?.spaceBooking?.type || 0;
  }, [values.siteId, sites]);

  const hasStepSpace = values.type === DAY_TYPES.ON_SITE && spaceBookingType === 3;

  const steps = (
    mode === "self"
      ? [STEPS.DATES, hasStepSpace ? STEPS.WORKPLACE : null, STEPS.REVIEW]
      : [STEPS.DATES, STEPS.TEAM_MEMBERS, hasStepSpace ? STEPS.ROOM : null, STEPS.REVIEW]
  ).filter(Boolean);

  return (
    <Form.Provider
      onFormFinish={(name, info) => {
        setValues((pre) => {
          const data =
            name === "type"
              ? Object.assign({}, DEFAULT_VALUES, info.values)
              : Object.assign({}, pre, info.values);
          logger.log("onFormFinish", name, data, info.values);
          if (name === "dates") {
            // useRecurrence was unchecked
            if (!info.values.recurrence) {
              data.recurrence = undefined;
              data.endDate = undefined;
            }
            let dates, startDate;
            if (data.recurrence) startDate = data.dates?.[0];
            else dates = data.dates;
            Object.assign(data, { dates, startDate });
          }
          return data;
        });

        const idxOfStep = steps.indexOf(step);
        const nextStep = steps[idxOfStep + 1];
        setStep(nextStep);
      }}
    >
      <Modal
        open={visible && step === STEPS.DAY_TYPE}
        title={<Typography.Text strong>{t("spas.request.new")}</Typography.Text>}
        onCancel={onCancel}
        footer={null}
      >
        <CreateStepsType mode={mode} form={formDayType} initialValues={values} />
      </Modal>

      <Modal
        destroyOnClose
        open={
          step === STEPS.DATES ||
          step === STEPS.TEAM_MEMBERS ||
          step === STEPS.WORKPLACE ||
          step === STEPS.ROOM ||
          step === STEPS.REVIEW
        }
        className={styles.modal}
        title={
          <>
            <Typography.Text strong>{t("spas.request.new")}</Typography.Text>
            {cond([
              [equals(STEPS.WORKPLACE), () => <CreateStepWorkplace.Helper />],
              [T, () => null],
            ])(step)}
          </>
        }
        onCancel={onCancel}
        width={1000}
        bodyStyle={{ padding: "0", display: "flex", flexDirection: "column" }}
        footer={null}
      >
        <StepsBars
          style={{ padding: "20px" }}
          selectedKey={step}
          steps={steps.map((s, idx) => ({
            key: s,
            label: (
              <Typography.Text strong>{t(`spas.steps.${s}`, { count: idx + 1 })}</Typography.Text>
            ),
          }))}
        />

        <div style={{ flexGrow: 1, overflow: "hidden" }}>
          {step === STEPS.DATES && (
            <CreateStepDate mode={mode} form={formDate} initialValues={values} />
          )}
          {step === STEPS.TEAM_MEMBERS && (
            <CreateStepTeamMembers mode={mode} form={formTeamMembers} initialValues={values} />
          )}
          {step === STEPS.WORKPLACE && (
            <CreateStepWorkplace mode={mode} form={formWorkplace} initialValues={values} />
          )}
          {step === STEPS.ROOM && (
            <CreateStepRoom mode={mode} form={formRoom} initialValues={values} />
          )}
          {step === STEPS.REVIEW && (
            <CreateStepReview mode={mode} hasStepSpace={hasStepSpace} values={values} />
          )}
        </div>

        <div style={{ width: "80%", alignSelf: "center", display: "flex", gap: 20, padding: 10 }}>
          <Button
            block
            type="default"
            ghost
            onClick={() => {
              if (step === STEPS.DATES) {
                setStep(STEPS.DAY_TYPE);
              } else {
                const idxOfStep = steps.indexOf(step);
                const nextStep = steps[idxOfStep - 1];
                setStep(nextStep);
              }
            }}
          >
            {t("Back")}
          </Button>
          <Button
            block
            type="primary"
            onClick={() =>
              cond([
                [equals(STEPS.DATES), () => formDate.submit()],
                [equals(STEPS.TEAM_MEMBERS), () => formTeamMembers.submit()],
                [equals(STEPS.WORKPLACE), () => formWorkplace.submit()],
                [equals(STEPS.ROOM), () => formRoom.submit()],
                [equals(STEPS.REVIEW), () => setStep(STEPS.VALIDATION)],
              ])(step)
            }
          >
            {t("Next")}
          </Button>
        </div>
      </Modal>

      <Modal
        destroyOnClose
        open={step === STEPS.VALIDATION}
        className={styles.modal}
        title={<Typography.Text strong>{t("spas.request.new")}</Typography.Text>}
        onCancel={onCancel}
        bodyStyle={{ padding: 30, display: "flex", flexDirection: "column", gap: "1rem" }}
        footer={null}
      >
        {(!statusRequest || statusRequest === "loading") && (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginBottom: "3rem",
              }}
            >
              <Typography.Title strong level={2}>
                {t("spas.request.reservation")}...
              </Typography.Title>
              <AnimatedProgress status={statusRequest} onComplete={handleCreateRequest} />
            </div>
            <Space direction="vertical" size={20}>
              <Space>
                <Glyph
                  name="check_circle"
                  style={{ fontSize: "1.5rem", color: colors.success_light }}
                />
                <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                  {values.type === DAY_TYPES.ON_SITE
                    ? sites?.find((site) => site.id === values.siteId)?.title
                    : t(`spas.type.${values.type}`)}
                </Typography.Text>
              </Space>
              <Space>
                <Glyph
                  name="check_circle"
                  style={{ fontSize: "1.5rem", color: colors.success_light }}
                />
                <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                  {t(`spas.period.${values.period}`)}
                </Typography.Text>
              </Space>
              <Space direction="vertical">
                <Space>
                  <Glyph
                    name="check_circle"
                    style={{ fontSize: "1.5rem", color: colors.success_light }}
                  />
                  {values.startDate || values.dates?.length === 1 ? (
                    <Typography.Text
                      strong
                      style={{ fontSize: "1.5rem", textTransform: "capitalize" }}
                    >
                      {moment(values.startDate || values.dates[0]).format("dddd DD MMM")}
                    </Typography.Text>
                  ) : (
                    <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                      {t("spas.request.dates")} :
                    </Typography.Text>
                  )}
                </Space>
                <div>
                  {values.dates?.length > 1 &&
                    values.dates.map((date) => (
                      <Tag style={{ marginRight: 5, marginBottom: 5, fontWeight: "bold" }}>
                        display: "flex", alignItems: "center",
                        {date.format("DD MMM YYYY")}
                      </Tag>
                    ))}
                </div>
              </Space>
              {!!values.recurrence && values.recurrence !== "NONE" && (
                <>
                  <Space>
                    <Glyph
                      name="check_circle"
                      style={{ fontSize: "1.5rem", color: colors.success_light }}
                    />
                    <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                      {t(`spas.recurrence.${values.recurrence}`, {
                        day: moment(values.startDate).format("dddd"),
                      })}
                    </Typography.Text>
                  </Space>
                  <Space>
                    <Glyph
                      name="check_circle"
                      style={{ fontSize: "1.5rem", color: colors.success_light }}
                    />
                    <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                      {t(`spas.request.endDate`, {
                        date: moment(values.endDate).format("dddd DD MMM"),
                      })}
                    </Typography.Text>
                  </Space>
                </>
              )}
            </Space>
            <div style={{ flexGrow: 1 }} />
            <Row>
              <Col offset={2} span={20}>
                <Button block type="primary" onClick={() => setStep(STEPS.REVIEW)}>
                  {t("Cancel")}
                </Button>
              </Col>
            </Row>
          </>
        )}

        {(statusRequest === "error" || statusRequest === "conflict") && (
          <>
            <div
              style={{
                paddingTop: "2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Error />
              <Typography.Paragraph strong style={{ marginTop: "35px", fontSize: size.xxl }}>
                <Glyph
                  name="cancel"
                  style={{
                    fontSize: size.xl,
                    verticalAlign: "-1px",
                    marginRight: "8px",
                    fontWeight: "normal",
                    color: colors.error_light,
                  }}
                />
                {t("spas.request.resaRefused")}
              </Typography.Paragraph>
              {statusRequest === "error" ? (
                <Typography.Paragraph strong type="secondary">
                  {t("spas.request.tryAgain")}
                </Typography.Paragraph>
              ) : (
                <Typography.Paragraph strong type="secondary">
                  {t("spas.conflict.other")}
                </Typography.Paragraph>
              )}
            </div>

            <div style={{ flexGrow: 1 }} />

            <Row style={{ position: "sticky", bottom: 0 }}>
              <Col offset={2} span={20}>
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    setStatusConflicts(null);
                    setStatusRequest(undefined);
                    return setStep(STEPS.WORKPLACE);
                  }}
                >
                  {t("Back")}
                </Button>
              </Col>
            </Row>
          </>
        )}

        {(statusRequest === "success" || statusRequest === "partial") && (
          <>
            <div
              style={{
                paddingTop: "2rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Confirm />
              <Space style={{ marginTop: "1rem" }}>
                <Glyph
                  name="check_circle"
                  style={{
                    fontSize: "1.5rem",
                    color:
                      statusRequest === "success" ? colors.success_light : colors.highlight_light,
                  }}
                />
                <Typography.Text strong style={{ fontSize: "1.5rem" }}>
                  {t("spas.request.resaAccepted")}
                </Typography.Text>
              </Space>
              {statusRequest === "success" ? (
                <div style={{ textAlign: "center" }}>
                  <Typography.Paragraph strong type={"secondary"}>
                    {[...(values.dates || []), values.startDate]
                      .filter(Boolean)
                      .map((date) => moment(date).format("DD MMM YYYY"))
                      .join(" - ")}
                  </Typography.Paragraph>
                  <Typography.Paragraph strong type={"secondary"}>
                    {values.type === DAY_TYPES.ON_SITE
                      ? sites?.find((site) => site.id === values.siteId)?.title
                      : t(`spas.type.${values.type}`)}
                  </Typography.Paragraph>
                </div>
              ) : (
                <Row gutter={[10, 10]}>
                  <Col span={24}>
                    <Typography.Text strong>{t("spas.conflict")}</Typography.Text>
                  </Col>
                  {statusConflicts?.conflicts?.some((i) => i.type === "other") && (
                    <Col span={24}>
                      <Card bordered={false}>
                        <Typography.Paragraph>
                          {t("spas.conflict.recurrence.other")}
                        </Typography.Paragraph>
                        {statusConflicts?.conflicts
                          ?.filter((i) => i.type === "other")
                          .map(({ date }) => (
                            <Tag
                              className="ghost"
                              style={{
                                padding: "0.2rem 0.8rem",
                                marginRight: 5,
                                marginTop: 5,
                                fontWeight: "bold",
                              }}
                            >
                              {moment(date).format("DD MMM YYYY")}
                            </Tag>
                          ))}
                      </Card>
                    </Col>
                  )}
                  {statusConflicts?.conflicts?.some((i) => i.type === "self") && (
                    <Col span={24}>
                      <Card bordered={false}>
                        <Typography.Paragraph>
                          {t("spas.conflict.recurrence.self")}
                        </Typography.Paragraph>
                        {statusConflicts?.conflicts
                          ?.filter((i) => i.type === "self")
                          .map(({ date }) => (
                            <Tag
                              className="ghost"
                              style={{
                                padding: "0.2rem 0.8rem",
                                marginRight: 5,
                                marginTop: 5,
                                fontWeight: "bold",
                              }}
                            >
                              {moment(date).format("DD MMM YYYY")}
                            </Tag>
                          ))}
                      </Card>
                    </Col>
                  )}
                </Row>
              )}
            </div>
            <div style={{ flexGrow: 1 }} />
            <Row style={{ position: "sticky", bottom: 0 }}>
              <Col offset={2} span={20}>
                <Button block type="primary" onClick={onFinish}>
                  {t("spas.request.seeDay")}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </Modal>
    </Form.Provider>
  );
};

export default CreateRequestModal;
