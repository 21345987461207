import { Card, Form, Image, Radio, Result, Space, Spin, Typography } from "antd";
import moment from "moment";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "../../../../../../services/redux/managers/LoadingManager";
import { mapDataSelector } from "../../../../../../services/redux/services/MapDataWS";
import {
  desksAvailabilitySelector,
  listDeskAvailability,
  listPresence,
} from "../../../../../../services/redux/services/SpaceService";
import Glyph from "../../../../../Common/Glyph/Glyph";
import HelperBubble from "../../../../../Common/Helper/Helper";
import FloorSelector from "../../../../../Common/Map/Mapbox/FloorSelector";
import ZoomInOut from "../../../../../Common/Map/Mapbox/ZoomInOut";
import {
  MapboxProvider,
  useMapboxInstance,
} from "../../../../../Common/Map/Mapbox/useMapboxInstance";
import styles from "../../../SpaceBooking.module.less";
import useDesignTokens from "../../../../../../hook/useDesignTokens";

const CreateStepWorkplaceContent = ({ form, initialValues }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { map, registerMap, mapReady, setDesksDisplay, centerOnPlace } = useMapboxInstance();
  const { size } = useDesignTokens();

  const { siteId, sectorId } = initialValues;

  const mapData = useSelector((state) => mapDataSelector(state, siteId));
  const availability = useSelector((state) => desksAvailabilitySelector(state, siteId));
  const presence = useSelector((state) => state.spaceServiceWS.presence?.followed);
  const isLoading = useSelector(
    createLoadingSelector([listDeskAvailability.typePrefix, listPresence.typePrefix]),
  );

  const onChange = useCallback(
    (spaceId) => {
      form.setFieldsValue({ spaceId });
    },
    [form],
  );

  useEffect(() => {
    if (initialValues.recurrence && initialValues.recurrence !== "NONE") return;
    if (initialValues.dates && initialValues.dates.length > 1) return;
    dispatch(listPresence({ siteId, date: moment(initialValues.dates[0]).format("YYYY-MM-DD") }));
  }, [dispatch, initialValues.dates, initialValues.recurrence, siteId]);

  const usersOnMap = useMemo(() => {
    if (!presence) return [];
    if (initialValues.recurrence && initialValues.recurrence !== "NONE") return [];
    if (initialValues.dates && initialValues.dates.length > 1) return [];
    return presence.map((f) => ({
      id: f.userId,
      name: f.displayName,
      photoUrl: f.photoUrl,
      deskUid: f.spaceId,
    }));
  }, [initialValues.dates, initialValues.recurrence, presence]);

  const [deskDisplay, allowedFloors] = useMemo(() => {
    if (!mapData) return [];
    const rooms = mapData.resources.filter((i) => i.sectorId === sectorId);
    const floorIds = new Set(rooms.map((i) => i.floorReference));
    return [
      {
        rooms: rooms.map((room) => ({
          roomUid: room.id,
          availableDeskUids: (availability || [])
            .filter((w) => w?.workplace?.roomId === room.id)
            .map((w) => w.id),
        })),
        users: usersOnMap,
      },
      Array.from(floorIds),
    ];
  }, [availability, mapData, sectorId, usersOnMap]);

  useEffect(() => {
    if (!mapReady) return;
    setDesksDisplay(deskDisplay).then(() => {
      if (initialValues.spaceId) {
        logger.log("centerOnPlace desk", initialValues.spaceId);
        centerOnPlace(initialValues.spaceId, true, false);
      } else if (deskDisplay?.rooms.length) {
        logger.log("centerOnPlace room", deskDisplay.rooms[0].roomUid);
        centerOnPlace(deskDisplay.rooms[0].roomUid, false, false);
      }
    });
  }, [centerOnPlace, deskDisplay, initialValues.spaceId, mapReady, setDesksDisplay]);

  useEffect(() => {
    if (!mapReady || !map || !mapData) return;

    const handler = ({ details: { id } }) => {
      const desk = mapData.workplaces.find((i) => i.map?.findAndOrder.placeId === id);
      if (desk && availability?.some((w) => w.id === desk.id)) {
        document.getElementById(`desk-${desk.id}`).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        onChange(desk.id);
        centerOnPlace(desk.id, true, true);
      }
    };

    map.on("clickOnDesk", handler);
    return () => {
      map.off("clickOnDesk", handler);
    };
  }, [map, mapData, mapReady, onChange, availability, centerOnPlace]);

  const workplacesWithData = useMemo(() => {
    if (!mapData) return [];
    return (availability || [])
      .map((w) => {
        const room = mapData.resources.find((r) => r.id === w?.workplace?.roomId);
        const floor = mapData.floors.find((f) => f.reference === room?.floorReference);
        if (!room || !floor || !w.workplace) return null;
        return {
          ...w,
          workplace: {
            ...w.workplace,
            title: w.workplace.title || "",
            room,
            floor,
          },
        };
      })
      .filter(Boolean);
  }, [availability, mapData]);

  return (
    <Form style={{ display: "flex", height: "100%" }} form={form} name={"workplace"}>
      <Form.Item name={"spaceId"} noStyle hidden rules={[{ required: true }]} />
      <div
        style={{
          padding: "0px 20px",
          flexBasis: "40%",
          borderRight: "1px solid #ececec",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography.Paragraph strong>{t("spas.workplace.subtitle")}</Typography.Paragraph>
        <Card bordered={false} style={{ flexGrow: 1, overflowY: "auto" }}>
          {!isLoading ? (
            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {[...workplacesWithData]
                .sort((a, b) => a?.workplace?.title.localeCompare(b?.workplace?.title))
                .map(({ workplace }, index) => (
                  <Card
                    id={`desk-${workplace.id}`}
                    key={index}
                    style={{ cursor: "pointer" }}
                    bodyStyle={{ padding: 10 }}
                    onClick={() => {
                      onChange(workplace.id);
                      centerOnPlace(workplace.id, true, true);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Card.Meta
                        avatar={
                          <Image
                            width={60}
                            height={60}
                            preview={false}
                            src={workplace?.photos?.[0] || workplace?.room?.photos?.[0]}
                            placeholder={
                              <div className={styles["picture_icon"]}>
                                <Glyph
                                  style={{ fontSize: "30px", color: "white", opacity: 0.8 }}
                                  name="desk"
                                />
                              </div>
                            }
                          />
                        }
                        title={
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography.Text style={{ fontSize: size.s }} strong>
                              {workplace?.title}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: size.s }}>
                              {workplace?.floor?.displayName}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: size.s }}>
                              {workplace?.room?.title}
                            </Typography.Text>
                          </div>
                        }
                      />
                      <Form.Item noStyle shouldUpdate={(pre, cur) => pre.spaceId !== cur.spaceId}>
                        {({ getFieldValue }) => (
                          <Radio checked={getFieldValue("spaceId") === workplace.id} />
                        )}
                      </Form.Item>
                    </div>
                  </Card>
                ))}
            </Space>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Result
                icon={<Spin />}
                title={t("spas.workplace.loading.title")}
                subTitle={t("spas.workplace.loading.subtitle")}
              />
            </div>
          )}
        </Card>
      </div>
      <div
        style={{
          padding: "0px 20px",
          flexBasis: "60%",
          height: "100%",
        }}
      >
        <Card bordered={false} style={{ height: "100%" }} bodyStyle={{ padding: 0 }}>
          <div ref={registerMap} id="workplace_map" style={{ width: "100%", height: "100%" }} />
          {!!mapReady && (
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                padding: 10,
                display: "flex",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              {allowedFloors?.length > 1 && <FloorSelector allowedFloors={allowedFloors} />}
              {((initialValues.recurrence && initialValues.recurrence !== "NONE") ||
                initialValues.dates.length > 1) && (
                <HelperBubble title={t("spas.workplace.multiple_dates")} />
              )}
            </div>
          )}
          <div style={{ position: "absolute", bottom: 0, right: 0, padding: 10 }}>
            <ZoomInOut />
          </div>
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "white",
              opacity: mapReady ? 0 : 1,
              transition: mapReady ? "opacity .3s ease-in-out" : "none",
              pointerEvents: "none",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography.Title level={3}>{t("map.loading")}...</Typography.Title>
          </div>
        </Card>
      </div>
    </Form>
  );
};

const Helper = () => {
  const { t } = useTranslation();

  return (
    <>
      <br />
      <Typography.Text type="secondary">{t("spas.workplacemap.helper")}</Typography.Text>
    </>
  );
};

const CreateStepWorkplace = (props) => {
  const { siteId } = props.initialValues;
  return (
    <MapboxProvider siteId={siteId} mode={"2D"}>
      <CreateStepWorkplaceContent {...props} />
    </MapboxProvider>
  );
};

CreateStepWorkplace.Helper = Helper;

export default CreateStepWorkplace;
