import { createSelector, createSlice, createAsyncThunk as thunk } from "@reduxjs/toolkit";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  userData: [],
  confirmPassword: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// USER DATA ///////////////////////////////

export const getUserData = thunk("users/getUserData", async ({ locale, siteId, isMobile }) => {
  // const state = getState();
  //const campusId = selectedCampus(state)?.id;
  const userData = await DWMConnector.userData(locale, siteId, isMobile);
  return userData;
});

EXTRA_REDUCERS[getUserData.fulfilled] = (state, action) => {
  state.userData = action.payload;
};

///////////////////////// RELEASE NOW ///////////////////////////////
export const releaseRoom = thunk("users/releaseRoom", async ({ action, token }) => {
  const releaseRoom = await DWMConnector.releaseRoom(action, token);
  return releaseRoom;
});

export const getCreatePassword = thunk(
  "users/getCreatePassword",
  async ({ login, code, newPassword }) => {
    const createPassword = await DWMConnector.getCreatePassword(login, code, newPassword);
    return createPassword;
  },
);

export const confirmPassword = thunk(
  "users/confirmPassword",
  async ({ login, code, newPassword }) => {
    const confirmPassword = await DWMConnector.confirmPassword(login, code, newPassword);
    return confirmPassword;
  },
);

EXTRA_REDUCERS[confirmPassword.fulfilled] = (state, action) => {
  state.confirmPassword = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const userSlice = createSlice({
  name: "user",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export const selectedCampus = createSelector(
  (state) => state.userWS.userData,
  (state, siteId) =>
    siteId || localStorage.getItem("siteId") || state.userWS.userData?.profile.campusId,
  (userData, siteId) => {
    return userData.campus?.find(({ id }) => id === +siteId) || userData.campus?.[0];
  },
);

export default userSlice.reducer;
