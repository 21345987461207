import { Button, Col, Layout, Row, Space, Spin, Typography } from "antd";
import moment from "moment";
import { groupBy, isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getConfig } from "../../../services/redux/services/VisitorsWS";
import { TitleSource } from "../../Common/Teleporters/Title";
import EditVisit from "./EditVisit";
import VisitItem from "./VisitItem";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";

const DATE_FORMAT = "YYYY-MM";

const Visitors = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isLoading = useSelector(
    createLoadingSelector(["visitors/getConfig", "visitors/listVisits"]),
  );
  const user = useSelector((state) => state.userWS.userData?.profile);

  const [selectedVisit, setSelectedVisit] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const visits = useSelector((state) => state.visitorsWS.visits);
  const isVisitor = useSelector((state) => state.userWS.userData.profile.isVisitor);

  useEffect(() => {
    dispatch(getConfig());
  }, [dispatch]);

  const delegatedVisits = visits?.items?.filter((i) => {
    return i?.creatorId === user?.id && i?.resident?.id !== user?.id;
  });

  return (
    <>
      <TitleSource>
        {!isVisitor ? t("manage_visits", { ns: "csv" }) : t("my_visits", { ns: "csv" })}
      </TitleSource>
      <Spin spinning={isLoading}>
        <Layout style={{ padding: "50px", width: "100%", overflowY: "auto" }}>
          <Space direction="vertical" size="large">
            <Row gutter={[30, 30]}>
              {!isVisitor && (
                <Col span={24}>
                  <Button
                    type="primary"
                    onClick={() => {
                      setSelectedVisit({
                        startDate: moment(),
                        endDate: moment().add(1, "h"),
                      });
                      setIsOpen(true);
                    }}
                  >
                    {t("visitors.forms.visit.create")}
                  </Button>
                </Col>
              )}
              {!isEmpty(visits) && (
                <Col span={24}>
                  {Object.entries(
                    groupBy(
                      (v) => moment(v.startDate).format(DATE_FORMAT),
                      visits?.items.filter(
                        (i) =>
                          (i?.creatorId === user?.id && i?.resident?.id === user?.id) ||
                          (i?.resident?.id === user?.id && i?.creatorId !== user?.id),
                      ),
                    ),
                  )?.map(([date, visit]) => (
                    <>
                      <Typography.Title style={{ marginTop: 20 }} level={3}>
                        {moment(date, DATE_FORMAT).isSame(moment(), "month")
                          ? "Ce mois ci"
                          : moment(date, DATE_FORMAT).format("MMMM YYYY")}
                      </Typography.Title>
                      <Row gutter={[20, 20]}>
                        {visit?.map((v) => (
                          <Col span={6}>
                            <VisitItem
                              key={v.id}
                              visit={v}
                              onClick={setSelectedVisit}
                              setIsOpen={setIsOpen}
                            />
                          </Col>
                        ))}
                      </Row>
                    </>
                  ))}
                </Col>
              )}
              {!!delegatedVisits?.length && (
                <>
                  <Col span={24}>
                    <Typography.Title style={{ marginTop: 20 }} level={3}>
                      {t("visitors.delegated")}
                    </Typography.Title>
                  </Col>
                  <Col span={24}>
                    {Object.entries(
                      groupBy((v) => moment(v.startDate).format(DATE_FORMAT), delegatedVisits),
                    ).map(([date, visits]) => (
                      <div style={{ marginTop: "20px" }}>
                        <Typography.Title style={{ marginTop: 0 }} level={3}>
                          {moment(date, DATE_FORMAT).isSame(moment(), "month")
                            ? "Ce mois ci"
                            : moment(date, DATE_FORMAT).format("MMMM YYYY")}
                        </Typography.Title>
                        <Row gutter={[20, 20]}>
                          {visits.map((v) => (
                            <Col span={6}>
                              <VisitItem
                                key={v.id}
                                visit={v}
                                onClick={setSelectedVisit}
                                setIsOpen={setIsOpen}
                              />
                            </Col>
                          ))}
                        </Row>
                      </div>
                    ))}
                  </Col>
                </>
              )}
            </Row>
          </Space>
          {selectedVisit && (
            <EditVisit
              user={user}
              visit={selectedVisit}
              open={isOpen}
              onDismiss={() => {
                setSelectedVisit(undefined);
                setIsOpen(false);
              }}
            />
          )}
        </Layout>
      </Spin>
    </>
  );
};

export default Visitors;
