import { Card, Col, Grid, Row, Space, Typography } from "antd";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import Tile from "../../Common/Tile/Tile";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavorisTransport,
  removeFavorisTransport,
} from "../../../services/redux/services/ClientsWS";
import { isEmpty, isNil } from "ramda";
import moment from "moment";
import { useTranslation } from "react-i18next";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const Line = ({ line }) => {
  const { colors, symbols, size } = useDesignTokens();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const favoris = useSelector((state) => state.clientsWS.favorisTransport);

  const lineFavori = favoris?.find((f) => f?.routeId === line?.routeId);

  return (
    <Card
      style={{
        width: screens.xl ? "35%" : 350,
        minWidth: screens.xl ? "35%" : 350,
        maxHeight: "calc(100vh - 110px)",
        overflow: "auto",
        height: "fit-content",
      }}
      headStyle={{ minHeight: 90, maxHeight: 90 }}
      title={
        <div style={{ display: "flex" }}>
          <NavLink
            to="/transport/stop"
            style={{
              backgroundColor: colors?.grey_20,
              height: "30px",
              width: "30px",
              borderRadius: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "8px",
            }}
          >
            <Glyph
              name="arrow_back_ios"
              style={{
                verticalAlign: "-2px",
                fontSize: size.xl,
                color: "black",
              }}
            />
          </NavLink>
          <div>
            <Typography.Title level={5}>{line?.stopName}</Typography.Title>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Glyph
                name={line?.modes?.find((m) => m?.id === "metro") ? "subway" : "directions_bus"}
                style={{ marginRight: "8px" }}
              />
              <Typography.Text
                strong
                style={{
                  backgroundColor: line?.color,
                  borderRadius: symbols?.base_shape?.radius,
                  textAlign: "center",
                  color: line?.textColor,
                  minWidth: "30px",
                  height: "30px",
                  lineHeight: "30px",
                  padding: "0 6px",
                  marginRight: "4px",
                }}
              >
                {line?.code}
              </Typography.Text>
            </div>
          </div>
        </div>
      }
    >
      <div style={{ overflowY: "auto", height: "fit-content", padding: "0 10px 0 0" }}>
        <Row gutter={[8, 8]} style={{ marginBottom: "16px" }}>
          <Col span={12}>
            <Tile
              href="/transport/journeys"
              style={{
                margin: "0",
                height: "auto",
                padding: "10px",
                justifyContent: "center",
                flexFlow: "column",
              }}
            >
              <Glyph name="near_me" style={{ color: colors?.primary_base }} />
              <Typography.Text strong>{t("go_to")}</Typography.Text>
            </Tile>
          </Col>
          <Col span={12}>
            <Tile
              style={{
                margin: "0",
                height: "auto",
                padding: "10px",
                justifyContent: "center",
                flexFlow: "column",
              }}
              onClick={() => {
                if (isNil(lineFavori)) {
                  dispatch(addFavorisTransport(line));
                  logEvent(analytics, `transport_add_favorite`);
                } else {
                  dispatch(removeFavorisTransport(line?.routeId));
                }
              }}
            >
              <Glyph
                name={isNil(lineFavori) ? "star_border" : "star"}
                style={{ color: colors?.primary_base }}
              />
              <Typography.Text strong>{t("favorite")}</Typography.Text>
            </Tile>
          </Col>
        </Row>
        {!isEmpty(line?.departures) ? (
          <>
            <Typography.Text>{t("next_departures")}</Typography.Text>

            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {line?.departures?.map((departure, index) => (
                <Tile
                  key={index}
                  style={{
                    margin: "0",
                    height: "auto",
                    padding: "10px 20px",
                    cursor: "default",
                    justifyContent: "flex-start",
                  }}
                >
                  <Typography.Text strong>à {moment(departure).format("HH:mm")}</Typography.Text>
                </Tile>
              ))}
            </Space>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexFlow: "column",
              alignItems: "center",
            }}
          >
            <Typography.Text strong type="secondary">
              {t("no_next_departures")}
            </Typography.Text>
          </div>
        )}
        <div style={{ display: "flex", justifyContent: "center", marginTop: "8px" }}>
          <Typography.Text type="secondary">{t("navitia_data")}</Typography.Text>
        </div>
      </div>
    </Card>
  );
};

export default Line;
