import { Card, Divider } from "antd";
import Glyph from "../../Glyph/Glyph";
import { useMapboxInstance } from "./useMapboxInstance";

const ZoomInOut = ({ ...props }) => {
  const { zoomIn, zoomOut } = useMapboxInstance();

  return (
    <Card bodyStyle={{ padding: 5 }}>
      <div style={{ display: "flex", flexDirection: "column", gap: 5 }}>
        <Glyph name={"add"} onClick={zoomIn} style={{ cursor: "pointer", padding: 5 }} />
        <Divider style={{ margin: 0 }} />
        <Glyph name={"remove"} onClick={zoomOut} style={{ cursor: "pointer", padding: 5 }} />
      </div>
    </Card>
  );
};

export default ZoomInOut;
