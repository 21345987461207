import { LoadingOutlined } from "@ant-design/icons";
import { Button, Calendar, Card, Modal, Segmented, Select, Spin, Typography, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as DeliveryTruck } from "../../../../assets/svg/undraw_delivery_truck.svg";
import useDesignTokens from "../../../../hook/useDesignTokens";
import {
  createBooking,
  deleteParkingBooking,
  emptyZones,
  getBookings,
  getZones,
} from "../../../../services/redux/services/ParkingWS";
import Glyph from "../../../Common/Glyph/Glyph";
import MonthAndYearSelector from "../Component/MonthAndYearSelector";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const CreateOrModifyBookingModal = ({
  showCreateOrModifyBookingModal,
  setShowCreateOrModifyBookingModal,
  selectedDate,
  setSelectedDate,
  selectedMonth,
  setSelectedMonth,
  setSelectedBooking,
  selectedBooking,
}) => {
  const { i18n, t } = useTranslation();
  const { colors, symbols, size } = useDesignTokens();
  const dispatch = useDispatch();
  const locale = i18n.language.split("-")[0];

  const [updating, setUpdating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [siteSelection, setSiteSelection] = useState(!!!selectedBooking);

  const [step, setStep] = useState(1);
  const [tempBooking, setTempBooking] = useState({
    date: selectedBooking ? moment(selectedBooking.date) : selectedDate ? selectedDate : moment(),
    zone: selectedBooking ? selectedBooking.zone.id : null,
    period: selectedBooking
      ? selectedBooking.period
      : moment(selectedDate).isSame(moment())
      ? moment().isBefore(moment().startOf("day").add(12, "hours"))
        ? "AM"
        : "PM"
      : "DAY",
  });

  const campus = useSelector((state) => state.userWS.userData.campus);
  const siteId = useSelector((state) => state.clientsWS.campus)?.mapData?.id;
  const bookings = useSelector((state) => state.parkingWS.bookings.reservations);
  const availability = useSelector((state) => state.parkingWS.availability);

  const allSites = campus?.filter((c) => c.parking);
  const [selectedSite, setSelectedSite] = useState(
    selectedBooking?.site?.id || allSites.find((s) => s.id === siteId)?.id || allSites[0]?.id,
  );
  const isMultiSite = !!campus.length;

  const campusData = allSites?.find((c) =>
    c.id === selectedBooking?.site?.id ? selectedBooking : selectedSite,
  )?.parking;

  // on invalid selectedDate, set it to the first valid date
  useEffect(() => {
    if (
      moment(selectedDate).isBefore(moment().startOf("day")) ||
      campusData?.days?.find((d) => d === moment(selectedDate).day()) === undefined
    ) {
      if (campusData?.days?.find((d) => d === moment().day()) !== undefined) {
        setSelectedDate(moment());
      } else {
        setSelectedDate(
          moment()
            .add(campusData?.days?.find((d) => d > moment().day()) || campusData?.days[0], "days")
            .startOf("day"),
        );
      }
    }
  }, [campusData, selectedDate, setSelectedDate]);

  // manually add selected zone to available zones as it will be freed on updating
  const availableZones =
    selectedBooking &&
    moment(selectedBooking.date).isSame(tempBooking.date, "date") &&
    ((selectedBooking.period === "DAY" && tempBooking.period !== "DAY") ||
      (selectedBooking.period !== "DAY" && tempBooking.period === "DAY"))
      ? availability?.concat([selectedBooking.zone])
      : availability;

  const closeModal = () => {
    setSelectedBooking(null);
    setShowCreateOrModifyBookingModal(false);
  };

  //set temp booking date and period
  useEffect(() => {
    setTempBooking((p) => ({ ...p, date: selectedDate }));

    if (moment(selectedDate).isSame(moment(), "date")) {
      if (moment().isAfter(moment().startOf("day").add(12, "hours"))) {
        setTempBooking((p) => ({ ...p, period: "PM" }));
      } else {
        setTempBooking((p) => ({ ...p, period: "AM" }));
      }
    }
  }, [selectedDate]);

  //fetch zones
  useEffect(() => {
    const fetchZones = async () => {
      selectedBooking
        ? moment(selectedBooking.date).isSame(tempBooking.date, "date")
          ? setTempBooking((p) => ({ ...p, zone: selectedBooking.zone.id }))
          : setTempBooking((p) => ({ ...p, zone: null }))
        : setTempBooking((p) => ({ ...p, zone: null }));

      if (isDisabled(tempBooking.period)) {
        dispatch(emptyZones());
        return;
      }

      setLoading(true);
      await dispatch(
        getZones({
          date: moment(tempBooking.date).format("YYYY-MM-DD"),
          period: tempBooking.period,
          siteId: selectedSite,
        }),
      );
      setLoading(false);
    };
    fetchZones();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, tempBooking.date, tempBooking.period, bookings, selectedBooking, selectedSite]);

  //create booking
  const handleCreateBooking = async () => {
    try {
      setUpdating(true);

      if (selectedBooking) {
        await dispatch(
          deleteParkingBooking({
            bookingId: selectedBooking.id,
            siteId,
            date: selectedBooking.date,
          }),
        );
      }

      await dispatch(
        createBooking({
          date: moment(tempBooking.date).format("YYYY-MM-DD"),
          period: tempBooking.period,
          zoneId: tempBooking.zone,
          siteId: selectedSite || siteId,
        }),
      );

      logEvent(analytics, "parking_booking_done");

      await dispatch(
        getBookings({
          siteId,
          from: moment(selectedMonth).startOf("month").subtract(10, "days"),
          to: moment(selectedMonth).endOf("month").add(10, "days"),
        }),
      );

      setUpdating(false);
      closeModal();

      message.success(
        selectedBooking
          ? t("parking.BookingUpdatedWithSuccess")
          : t("parking.BookingCreatedWithSuccess"),
      );
    } catch (error) {
      logger.error("Error while processing request", error);
      setUpdating(false);

      message.error(selectedBooking ? t("parking.UpdateBookingError") : t("parking.BookingError"));
    }
  };

  //calendar style
  useEffect(() => {
    const panel = document.querySelector(".parkingCalendarModal .ant-picker-panel");
    if (panel) {
      panel.style.backgroundColor = colors.light_background;
      panel.style.borderTop = "none";
    }
  }, [colors]);

  const isDisabled = (period) => {
    const morningEnd = moment(campusData?.morningHours[1], "HH:mm:ss");
    const afternoonEnd = moment(campusData?.afternoonHours[1], "HH:mm:ss");
    const isSameDate = moment(tempBooking.date).isSame(moment(), "date");
    const bookingsOnDate = bookings?.filter(
      (b) =>
        b.site.id === selectedSite &&
        moment(b.date).isSame(tempBooking.date, "date") &&
        b.id !== selectedBooking?.id,
    );

    logger.log("bookingsOnDate", bookingsOnDate);

    if (bookingsOnDate?.find((b) => b.period === period) && !selectedBooking) {
      return true;
    }

    if (bookingsOnDate?.find((b) => b.period === "DAY")) {
      return true;
    }
    if (period === "DAY" && bookingsOnDate?.length > 0) {
      return true;
    }

    if (isSameDate) {
      switch (period) {
        case "AM":
          return moment().isAfter(morningEnd);
        case "PM":
          return moment().isAfter(afternoonEnd);
        case "DAY":
          return moment().isAfter(morningEnd);
        default:
          return true;
      }
    } else {
      return !!bookingsOnDate?.find((b) => b.period === period);
    }
  };

  const dateCellRender = (value) => {
    if (value.isBefore(moment().startOf("day"))) return null;

    const bookingsOnDate = bookings
      ?.filter((b) => moment(b.date).isSame(value, "date"))
      ?.filter((b) => b.site.id === selectedSite);

    const morningBookings = bookingsOnDate?.filter((b) => b.period === "AM");
    const afternoonBookings = bookingsOnDate?.filter((b) => b.period === "PM");
    const dayBookings = bookingsOnDate?.filter((b) => b.period === "DAY");

    return (
      <div
        style={{
          position: "absolute",
          display: "flex",
          width: "100%",
          justifyContent: "center",
          gap: 2,
          marginTop: 1,
          opacity: 0.8,
        }}
      >
        {bookingsOnDate?.length ? (
          <>
            {dayBookings?.length ? (
              <div
                style={{
                  minWidth: 16,
                  minHeight: 6,
                  height: 6,
                  backgroundColor: colors.primary_base,
                  borderRadius: 3,
                }}
              />
            ) : (
              <>
                <div
                  style={{
                    minWidth: 6,
                    minHeight: 6,
                    height: 6,
                    backgroundColor: colors.primary_base,
                    borderRadius: 3,
                    opacity: morningBookings?.length ? 1 : 0,
                  }}
                />
                <div
                  style={{
                    minWidth: 6,
                    minHeight: 6,
                    height: 6,
                    backgroundColor: colors.primary_base,
                    borderRadius: 3,
                    opacity: afternoonBookings?.length ? 1 : 0,
                  }}
                />
              </>
            )}
          </>
        ) : null}
      </div>
    );
  };

  return (
    <Modal
      className="parkingCalendarModal"
      open={showCreateOrModifyBookingModal}
      width={isMultiSite && siteSelection ? 400 : 700}
      closable={!updating}
      onCancel={closeModal}
      title={
        <Typography.Text strong>
          {selectedBooking ? t("parking.ModifyBooking") : t("parking.ParkingBooking")}
        </Typography.Text>
      }
      footer={null}
      bodyStyle={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        gap: 8,
        padding: "16px",
        alignItems: "center",
      }}
    >
      {isMultiSite && siteSelection ? (
        <div style={{ display: "flex", flexDirection: "column", gap: 8, padding: "12px 24px" }}>
          <Typography.Text strong style={{ color: colors.pending_light }}>
            {t("parking.SelectSite")}
          </Typography.Text>
          <div
            style={{
              padding: "16px 10px",
              borderRadius: symbols.base_shape.radius,
              backgroundColor: colors.light_background,
              marginBottom: 24,
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <Glyph name="business" className={"secondary"} />
              <Select
                className="accent"
                bordered={false}
                size={"middle"}
                defaultValue={selectedSite}
                style={{
                  width: "100%",
                  marginTop: -8,
                  marginBottom: -8,
                  backgroundColor: colors.secondary_base.replace(/(.{2})$/i, "33"),
                }}
                options={allSites.map((s) => ({
                  label: s.title,
                  value: s.id,
                }))}
                onChange={(e) => {
                  setSelectedSite(e);
                }}
              />
            </div>
          </div>
          <Button type="primary" onClick={() => setSiteSelection(false)}>
            {t("parking.Continue")}
          </Button>
        </div>
      ) : (
        <>
          {/* STEPS HEADER */}
          <div style={{ display: "flex", gap: 8, marginBottom: 16, width: "100%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                width: "100%",
                opacity: step === 1 ? 1 : 0.6,
              }}
            >
              <Typography.Text
                strong
                style={{ fontSize: size.l, width: "100%", textAlign: "center" }}
              >
                {t("parking.Date")}
              </Typography.Text>
              <div
                style={{
                  height: 6,
                  borderRadius: 6,
                  width: "100%",
                  backgroundColor: colors.secondary_base,
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                width: "100%",
                opacity: step === 1 ? 0.6 : 1,
              }}
            >
              <Typography.Text
                strong
                style={{ fontSize: size.l, width: "100%", textAlign: "center" }}
              >
                {t("parking.Confirmation")}
              </Typography.Text>
              <div
                style={{
                  height: 6,
                  borderRadius: 6,
                  width: "100%",
                  backgroundColor: colors.secondary_base,
                }}
              />
            </div>
          </div>

          {step === 1 ? (
            <div style={{ display: "flex" }}>
              {/* LEFT */}
              <div
                style={{
                  display: "flex",
                  gap: 8,
                  justifyContent: "space-between",
                  position: "relative",
                  width: "50%",
                }}
              >
                <div
                  style={{ display: "flex", flexDirection: "column", gap: 8, padding: "0 16px" }}
                >
                  <Typography.Text strong>{t("parking.SelectDate")}</Typography.Text>
                  <div
                    style={{
                      backgroundColor: colors.light_background,
                      padding: 8,
                      borderRadius: symbols.base_shape.radius,
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                    }}
                  >
                    <MonthAndYearSelector
                      selectedMonth={selectedMonth}
                      setSelectedMonth={setSelectedMonth}
                      setSelectedDate={setSelectedDate}
                      clickable={!loading}
                    />
                    <Calendar
                      fullscreen={false}
                      disabledDate={(date) =>
                        date.isBefore(moment().startOf("day")) ||
                        campusData?.days?.find((d) => d === date.day()) === undefined
                      }
                      value={tempBooking.date}
                      onSelect={(date) => {
                        !loading && setSelectedDate(date);
                        !loading &&
                          !moment(selectedMonth).isSame(date, "month") &&
                          setSelectedMonth(date);
                      }}
                      headerRender={() => null}
                      dateCellRender={dateCellRender}
                    />
                  </div>
                  <Typography.Text strong>{t("parking.SelectDuration")}</Typography.Text>
                  <div
                    style={{
                      padding: 16,
                      borderRadius: symbols.base_shape.radius,
                      width: "100%",
                      backgroundColor: colors.light_background,
                    }}
                  >
                    <Segmented
                      disabled={loading}
                      options={[
                        {
                          value: "DAY",
                          label: t("parking.Day"),
                          disabled: isDisabled("DAY"),
                        },
                        {
                          value: "AM",
                          label: t("parking.Am"),
                          disabled: isDisabled("AM"),
                        },
                        {
                          value: "PM",
                          label: t("parking.Pm"),
                          disabled: isDisabled("PM"),
                        },
                      ]}
                      value={tempBooking.period}
                      onChange={(period) => {
                        moment(tempBooking.date).isSame(moment(selectedBooking?.date), "date") &&
                        period === selectedBooking?.period
                          ? setTempBooking({ ...tempBooking, zone: selectedBooking?.zone.id })
                          : setTempBooking({ ...tempBooking, zone: null });
                        setTempBooking({ ...tempBooking, period });
                      }}
                      size="large"
                      style={{ width: "100%" }}
                    />
                  </div>
                </div>
                <div
                  style={{
                    position: "absolute",
                    right: 0,
                    height: "100%",
                    width: 2,
                    backgroundColor: colors.light_background,
                  }}
                />
              </div>

              {/* RIGHT */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 16,
                  padding: "0 16px",
                  width: "50%",
                }}
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography.Text strong>{t("parking.SelectZone")}</Typography.Text>
                  <Typography.Text type="secondary">
                    {t("parking.SelectDateAndDurationBeforeZone")}
                  </Typography.Text>
                </div>

                <Spin
                  spinning={loading}
                  indicator={<LoadingOutlined style={{ fontSize: size.xxxxl }} />}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: colors.light_background,
                      borderRadius: symbols.base_shape.radius,
                      padding: 8,
                    }}
                  >
                    {selectedBooking &&
                      moment(selectedBooking.date).isSame(tempBooking.date, "date") &&
                      (selectedBooking.period === tempBooking.period ||
                        selectedBooking.period === "DAY") && (
                        <Card
                          style={{
                            width: "100%",
                            cursor: "pointer",
                            opacity: 0.6,
                          }}
                        >
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                              <Typography.Text strong>{selectedBooking.zone.name}</Typography.Text>
                            </div>
                            <Glyph
                              name={
                                tempBooking?.zone === selectedBooking.zone.id
                                  ? "radio_button_checked"
                                  : "radio_button_unchecked"
                              }
                              size={24}
                              style={{ color: colors.grey_40 }}
                            />
                          </div>
                        </Card>
                      )}

                    {!loading &&
                      (!!availability?.length ? (
                        availableZones.map((zone) => (
                          <Card
                            style={{ width: "100%", cursor: "pointer" }}
                            key={zone.id}
                            onClick={() => setTempBooking({ ...tempBooking, zone: zone.id })}
                          >
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                                <Typography.Text strong>{zone.name}</Typography.Text>
                              </div>
                              <Glyph
                                name={
                                  tempBooking?.zone === zone.id
                                    ? "radio_button_checked"
                                    : "radio_button_unchecked"
                                }
                                size={24}
                                style={{
                                  color:
                                    tempBooking?.zone === zone.id
                                      ? colors.secondary_base
                                      : colors.grey_40,
                                }}
                              />
                            </div>
                          </Card>
                        ))
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                          }}
                        >
                          <Card
                            style={{ width: "100%" }}
                            bodyStyle={{ textWrap: "balance", textAlign: "center" }}
                          >
                            {bookings?.find(
                              (b) =>
                                b.site.id === selectedSite &&
                                b.period === tempBooking.period &&
                                moment(b.date).isSame(tempBooking.date, "date"),
                            ) ||
                            bookings?.find(
                              (b) =>
                                b.site.id === selectedSite &&
                                b.period === "DAY" &&
                                moment(b.date).isSame(tempBooking.date, "date"),
                            )
                              ? selectedBooking
                                ? t("parking.NoOtherZonesAvailable")
                                : t("parking.ThereAreBookingsOnThisPeriod")
                              : t("parking.NoZonesAvailable")}
                          </Card>
                        </div>
                      ))}
                  </div>
                </Spin>
              </div>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: colors.light_background,
                borderRadius: symbols.base_shape.radius,
                padding: 16,
                marginBottom: 16,
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <Typography.Text strong>{t("parking.BookingRecap")}</Typography.Text>
                <DeliveryTruck style={{ width: "100%", height: 150, margin: "16px 0" }} />
                {isMultiSite && (
                  <Card style={{ width: "100%" }} bodyStyle={{ padding: 12 }}>
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        <Glyph name="business" size={24} style={{ color: colors.grey_60 }} />
                        <Typography.Text strong>{t("parking.Site")}</Typography.Text>
                      </div>
                      <Typography.Text>
                        {!!selectedBooking
                          ? selectedBooking.site?.title
                          : allSites?.find((s) => s.id === selectedSite)?.title}
                      </Typography.Text>
                    </div>
                  </Card>
                )}
                <Card style={{ width: "100%" }} bodyStyle={{ padding: 12 }}>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                      <Glyph name="calendar_today" size={24} style={{ color: colors.grey_60 }} />
                      <Typography.Text strong>{t("parking.BookingDate")}</Typography.Text>
                    </div>
                    <Typography.Text>
                      {selectedBooking &&
                        !moment(selectedBooking.date).isSame(tempBooking.date, "date") && (
                          <span
                            style={{
                              textDecoration: "line-through",
                              color: colors.grey_40,
                            }}
                          >
                            {moment(selectedBooking.date).format(
                              locale === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY",
                            )}
                          </span>
                        )}{" "}
                      {moment(tempBooking.date).format(
                        locale === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY",
                      )}
                    </Typography.Text>
                  </div>
                </Card>
                <Card style={{ width: "100%" }} bodyStyle={{ padding: 12 }}>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                      <Glyph name="schedule" size={24} style={{ color: colors.grey_60 }} />
                      <Typography.Text strong>{t("parking.Duration")}</Typography.Text>
                    </div>
                    <Typography.Text>
                      {selectedBooking && selectedBooking.period !== tempBooking.period && (
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: colors.grey_40,
                          }}
                        >
                          {selectedBooking.period === "AM"
                            ? t("parking.Am")
                            : selectedBooking.period === "PM"
                            ? t("parking.Pm")
                            : t("parking.Day")}
                        </span>
                      )}{" "}
                      {tempBooking.period === "AM"
                        ? t("parking.Am")
                        : tempBooking.period === "PM"
                        ? t("parking.Pm")
                        : t("parking.Day")}
                    </Typography.Text>
                  </div>
                </Card>
                <Card style={{ width: "100%" }} bodyStyle={{ padding: 12 }}>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                      <Glyph name="location_on" size={24} style={{ color: colors.grey_60 }} />
                      <Typography.Text strong>{t("parking.Zone")}</Typography.Text>
                    </div>
                    <Typography.Text>
                      {selectedBooking && selectedBooking.zone.id !== tempBooking.zone && (
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: colors.grey_40,
                          }}
                        >
                          {selectedBooking.zone.name}
                        </span>
                      )}{" "}
                      {availableZones?.find((zone) => zone.id === tempBooking.zone)?.name}
                    </Typography.Text>
                  </div>
                </Card>
              </div>
            </div>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: 8,
              width: "100%",
              marginTop: 16,
            }}
          >
            <Button
              type={updating ? "primary" : "tertiary"}
              disabled={updating}
              onClick={() =>
                step === 1
                  ? !!!selectedBooking
                    ? isMultiSite
                      ? setSiteSelection(true)
                      : closeModal()
                    : closeModal()
                  : setStep(1)
              }
              style={{ width: "100%", maxWidth: 300 }}
            >
              {t("parking.Back")}
            </Button>
            <Button
              type="primary"
              disabled={
                !tempBooking.zone ||
                !tempBooking.period ||
                !tempBooking.date ||
                (tempBooking.zone === selectedBooking?.zone?.id &&
                  tempBooking.period === selectedBooking?.period &&
                  moment(tempBooking.date).isSame(moment(selectedBooking?.date), "date"))
              }
              onClick={() => (step === 1 ? setStep(2) : handleCreateBooking())}
              style={{ width: "100%", maxWidth: 300 }}
              loading={updating}
            >
              {step === 1
                ? t("parking.Continue")
                : selectedBooking
                ? t("parking.Modify")
                : t("parking.Book")}
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
};

export default CreateOrModifyBookingModal;
