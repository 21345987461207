import { Col, Image, Row, Tag, Typography } from "antd";
import { cond, equals, isEmpty } from "ramda";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";

// import redux
import { getRoomOccupancy } from "../../../services/redux/services/OccupancyWS";

// import component
import Glyph from "../Glyph/Glyph";

// import css
import styles from "./RoomDetails.module.less";

const RoomDetailsPhotos = ({ room }) => {
  const { colors, symbols } = useDesignTokens();

  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);
  const views = useSelector((state) => state.userWS.userData?.views);

  const hasPhotos = !isEmpty(room?.photos);
  const hasFastbookingV2 = views?.some((v) => v?.type === "fastBookingV2");

  return hasPhotos ? (
    <Row gutter={[8, 8]} wrap={false} style={{ overflow: "auto" }}>
      {room?.photos?.map((photo, index) => (
        <Col
          key={index}
          span={room?.photos?.length > 2 ? 11 : 12}
          style={{ marginBottom: "8px" }}
          className={styles["pictures"]}
        >
          <Image src={photo} preview={false} />
        </Col>
      ))}
    </Row>
  ) : hasFastbookingV2 ? (
    <Row gutter={[8, 8]} wrap={false} style={{ overflow: "auto" }}>
      <Col span="12" style={{ marginBottom: "8px" }} className={styles["pictures"]}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "128px",
            width: "100%",
            backgroundColor: colors.grey_40,
            opacity: 0.3,
            borderRadius: symbols.base_shape.radius,
          }}
        >
          <Glyph
            style={{ fontSize: "60px", color: "white", opacity: 0.8 }}
            name={
              mapData?.categories?.find((categorie) => categorie?.id === room?.categoryId)?.icon ||
              "meeting_room"
            }
          />
        </div>
      </Col>
    </Row>
  ) : null;
};

const RoomDetailsTitle = ({ room, ...rest }) => {
  return (
    <Typography.Text {...rest} style={{ display: "block", fontSize: "1.2rem" }} strong>
      {room?.title || ""}
    </Typography.Text>
  );
};

const RoomDetailsFloor = ({ room, ...rest }) => {
  const { t } = useTranslation();
  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  const floor = useMemo(() => {
    return mapData?.floors.find((f) => f.reference === room?.floorReference);
  }, [mapData?.floors, room?.floorReference]);

  return floor ? (
    <Typography.Text {...rest} strong type="secondary">
      {`${t("Stage")} ${floor?.displayName || ""}`}
    </Typography.Text>
  ) : null;
};

const TAG_STYLES = { marginBottom: 8 };
const TAG_GLYPH_STYLES = { verticalAlign: -4, marginRight: 4 };
const RoomDetailsEquipments = ({ room, withOccupancy }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { colors } = useDesignTokens();

  const occupancy = useSelector((state) => state.occupancyWS.occupancy);
  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  useEffect(() => {
    if (room?.id) dispatch(getRoomOccupancy({ roomId: room?.id }));
  }, [dispatch, room?.id]);

  const equipments = useMemo(() => {
    return (room?.equipments || [])
      .map((id) => mapData?.equipments.find((e) => e.id === id))
      .filter(Boolean);
  }, [mapData?.equipments, room?.equipments]);

  const occupancyColor = cond([
    [equals("AVAILABLE"), () => colors.success_light],
    [equals("OCCUPIED"), () => colors.error_light],
  ])(occupancy?.status);

  const displayOccupancy =
    withOccupancy && (occupancy.status === "OCCUPIED" || occupancy.status === "AVAILABLE");

  return (
    <>
      {displayOccupancy && (
        <Tag
          style={TAG_STYLES}
          icon={
            <Glyph
              name="circlefiber_manual_record"
              style={{ ...TAG_GLYPH_STYLES, color: occupancyColor }}
            />
          }
        >
          {t(occupancy.status)}
        </Tag>
      )}

      {!!room?.features?.seats && (
        <Tag style={TAG_STYLES} icon={<Glyph name="group" style={TAG_GLYPH_STYLES} />}>
          {room?.features?.seats}
        </Tag>
      )}

      {equipments.map((e, index) => (
        <Tag
          key={index}
          style={TAG_STYLES}
          icon={e?.icon ? <Glyph name={e?.icon} style={TAG_GLYPH_STYLES} /> : undefined}
        >
          {e?.title}
        </Tag>
      ))}
    </>
  );
};

const RoomDetails = ({ room, withTitle = true, withOccupancy = false }) => {
  return (
    <Row gutter={[0, 5]}>
      {withTitle && (
        <Col span={24}>
          <RoomDetailsTitle room={room} />
          <RoomDetailsFloor room={room} />
        </Col>
      )}

      <Col span={24}>
        <RoomDetailsPhotos room={room} />
      </Col>

      <Col span={24}>
        <RoomDetailsEquipments room={room} withOccupancy={withOccupancy} />
      </Col>
    </Row>
  );
};

RoomDetails.Title = RoomDetailsTitle;
RoomDetails.Floor = RoomDetailsFloor;
RoomDetails.Photos = RoomDetailsPhotos;
RoomDetails.Equipments = RoomDetailsEquipments;

export default RoomDetails;
