import { Input, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import Glyph from "../../../../Common/Glyph/Glyph";
import Tile from "../../../../Common/Tile/Tile";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../services/api/Firebase/Firebase";

const SearchRoom = ({ setSelectedRoom, selectedRoom, setIsZone, setBackDrop }) => {
  const { t } = useTranslation();
  const { symbols, shadows } = useDesignTokens();

  const campus = useSelector((state) => state.clientsWS.campus);

  const [searchRoom, setSearchRoom] = useState("");

  const handleSearch = (evt) => {
    setSearchRoom(evt.target.value);
    if (evt.target.value.length === 1) {
      logEvent(analytics, "smart_control_search_poi_webOnMobile");
    }
  };

  const searchResult = searchRoom
    ? campus?.mapData?.resources
        .filter((r) => !!r.features?.comfort)
        .filter((value) => {
          if (searchRoom === "") {
            return value;
          } else {
            return value.title.toLowerCase().includes(searchRoom.toLowerCase());
          }
        })
        .sort((a, b) => (a.title > b.title ? 1 : -1))
    : null;

  const Row = ({ _, index }) => (
    <Tile
      key={index}
      onClick={() => {
        setSelectedRoom(searchResult[index]);
        setBackDrop(false);
        setSearchRoom("");
        setIsZone(false);
      }}
      style={{
        width: "100%",
        marginTop: 8,
      }}
    >
      <Typography.Text
        strong
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {searchResult[index].title}
      </Typography.Text>
      <span
        style={{
          width: "fit-content",
          whiteSpace: "nowrap",
        }}
      >
        {t("Stage")}{" "}
        {
          campus?.mapData?.floors.find(
            (floor) => floor.reference === searchResult[index].floorReference,
          ).displayName
        }
        <Glyph name="navigate_next" />
      </span>
    </Tile>
  );

  return (
    <div
      style={{
        position: "relative",
        display: "flex",
        justifyContent: "center",
        marginTop: 16,
        padding: "0 16px",
      }}
    >
      <Input
        onChange={handleSearch}
        value={searchRoom}
        placeholder={t("SearchSpace")}
        prefix={<Glyph name="search" />}
        allowClear={true}
        onFocus={() => setBackDrop(true)}
        style={{ zIndex: 990, width: "100%" }}
      />
      {!selectedRoom && (
        <Typography.Text
          style={{
            position: "absolute",
            zIndex: 999,
            marginTop: 74,
            width: "calc(100% - 32px)",
            textWrap: "balance",
            textAlign: "center",
          }}
        >
          {t("UseCard")}
        </Typography.Text>
      )}

      {searchResult && (
        <div
          style={{
            position: "absolute",
            padding: 16,
            zIndex: 999,
            marginTop: 54,
            backgroundColor: "white",
            borderRadius: symbols.base_shape.radius,
            width: "calc(100% - 32px)",
            boxShadow: `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`,
            height: "fit-content",
            maxHeight: "calc(100vh - 160px)",
            overflowY: "auto",
            overflowX: "hidden",
          }}
        >
          <Typography.Title level={5}>{t("mobile.searchResult")}</Typography.Title>

          {!searchResult?.length ? (
            <Typography.Text type="secondary">{t("NoResult")}</Typography.Text>
          ) : (
            searchResult.map((r, index) => <Row key={r.title} index={index} />)
          )}
        </div>
      )}
    </div>
  );
};

export default SearchRoom;
