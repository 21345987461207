import { WarningOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Typography, message } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";
import { cancelSlot } from "../../../services/redux/services/SpaceService";

const ModalCancel = ({ slot, onClose }) => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isCanceling, setIsCanceling] = useState(false);

  const handleCancel = (dateOnly) => {
    logger.log("handleCancel", slot, dateOnly);
    setIsCanceling(dateOnly ? "occurence" : "serie");
    return dispatch(cancelSlot({ id: slot.id, data: { dateOnly } }))
      .unwrap()
      .then(() => {
        message.success(t("spas.messages.cancel.success"));
        onClose();
      })
      .catch((error) => {
        logger.error(error);
        message.error(t("spas.messages.cancel.error"));
      })
      .finally(() => {
        setIsCanceling(false);
      });
  };

  return (
    <Modal open={!!slot} onCancel={onClose} footer={null}>
      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Typography.Title level={3}>
            <Space size={16}>
              <WarningOutlined style={{ color: colors.error_light }} />
              {t("spas.request.actions.cancel")}
            </Space>
          </Typography.Title>
        </Col>
        {slot?.request?.isSeries ? (
          <>
            <Col offset={1} span={11}>
              <Button
                loading={isCanceling === "serie"}
                disabled={!!isCanceling}
                size="middle"
                block
                type="primary"
                onClick={() => handleCancel(false)}
              >
                {t("spas.request.actions.serie")}
              </Button>
            </Col>
            <Col span={11}>
              <Button
                loading={isCanceling === "occurence"}
                disabled={!!isCanceling}
                size="middle"
                block
                type="primary"
                onClick={() => handleCancel(true)}
              >
                {t("spas.request.actions.occurence")}
              </Button>
            </Col>
          </>
        ) : (
          <Col offset={4} span={16}>
            <Button
              loading={!!isCanceling}
              disabled={!!isCanceling}
              size="middle"
              block
              type="primary"
              onClick={() => handleCancel(true)}
            >
              {t("Confirm")}
            </Button>
          </Col>
        )}
      </Row>
    </Modal>
  );
};

export default ModalCancel;
