import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";
import { getPlacesInfos } from "../../../services/redux/services/PlacesInfosWS";
import { Divider, Typography } from "antd";
import Places from "./Places";
import { isNil } from "ramda";

const Widget = ({ view }) => {
  const { t, i18n } = useTranslation();
  const { colors, size } = useDesignTokens();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const placeInfos = useSelector(
    (state) => state.placesInfosWS[view.id]?.placesInfos?.contents || [],
  );

  useEffect(() => {
    dispatch(
      getPlacesInfos({
        viewId: view?.id,
        locale: i18n.language.split("-")[0],
      }),
    );
  }, [dispatch, i18n.language, view?.id]);

  return placeInfos?.map((placeInfo) => (
    <>
      <div style={{ cursor: "pointer" }} onClick={() => setShowModal(true)}>
        {placeInfo?.isOpen !== null && (
          <Typography.Text strong style={{ fontSize: size.l }}>
            {placeInfo?.isOpen ? t("Places.Open") : t("Places.Closed")}
          </Typography.Text>
        )}
        {!isNil(placeInfo?.affluence) && (
          <>
            <Divider style={{ margin: "8px" }} />
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <Typography.Text>{t("Places.Frequentation")}</Typography.Text>
              <Typography.Text
                style={{ fontSize: size.xxxxl, color: colors.secondary_base }}
                strong
              >
                {isNil(placeInfo?.affluence?.currentForecast?.occupancy)
                  ? 0
                  : placeInfo?.affluence?.currentForecast?.occupancy}
                %
              </Typography.Text>
            </div>
          </>
        )}
      </div>
      {placeInfo && (
        <Places
          visible={showModal}
          setVisible={setShowModal}
          placeInfo={placeInfo}
          onCancel={() => setShowModal(false)}
          viewId={view.id}
          setVisible={setShowModal}
        />
      )}
    </>
  ));
};

export default Widget;
