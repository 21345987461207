import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { slugify } from "../helpers/utils";

export const getViewURL = (view) => {
  switch (view.type) {
    case "socialnews":
    case "news":
      return `/news/${view.id}`;
    case "ticketing":
      return `/ticketing?group=${view.ticketing?.groupType}`;
    case "ticketingV2Agent":
    case "ticketingV2Admin":
    case "ticketingV2":
      return `/${slugify(view.title)}`;
    case "places":
      return `/places/${view.id}`;
    case "webview":
    case "reception":
      return view?.url;
    default:
      return `/${view.type}`;
  }
};

const useView = (id) => {
  const location = useLocation();

  const views = useSelector((state) => state.userWS.userData?.views || []);

  // path without domain and query params
  const path = location.pathname.split("?")[0];
  const viewType = path.split("/")[1];
  const viewId = id || path.split("/")[2];

  const view = useMemo(() => {
    let view = views.find((i) => i.id === +viewId);
    if (!view) view = views.find((i) => i.type === viewType);
    return view;
  }, [viewId, viewType, views]);

  return view;
};

export default useView;
