import React from "react";
import Glyph from "../../../Common/Glyph/Glyph";
import { Typography } from "antd";
import useDesignTokens from "../../../../hook/useDesignTokens";

const WarningMessage = ({ caption }) => {
  const { colors, symbols } = useDesignTokens();

  return (
    <div
      style={{
        display: "flex",
        gap: 16,
        backgroundColor: colors.grey_20,
        borderRadius: symbols.base_shape.radius,
        border: `2px solid ${colors.primary_base}`,
        padding: 8,
        alignItems: "center",
      }}
    >
      <Glyph name="warning" style={{ fontSize: 30, color: colors.primary_base }} />
      <Typography.Text strong style={{ fontSize: 14 }}>
        {caption}
      </Typography.Text>
    </div>
  );
};

export default WarningMessage;
