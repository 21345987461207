import { Button, Modal, Switch, Typography } from "antd";
import { useEffect, useState } from "react";
import SegmentedLanguageSelector from "../SegmentedLanguageSelector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CreateStatusModal = ({
  showCreateStatusModal,
  setShowCreateStatusModal,
  createFunction,
  statusToEdit,
}) => {
  const { t } = useTranslation();
  const appLocales = useSelector((state) => state.userWS?.userData?.locales);
  const [tempData, setTempData] = useState(
    statusToEdit
      ? {
          label: statusToEdit.label,
          color: statusToEdit.color,
          isClosed: statusToEdit.isClosed,
        }
      : {
          label: Object.fromEntries(appLocales.map((l) => [l.title, ""])),
          color: "",
          isClosed: false,
        },
  );

  useEffect(() => {
    setTempData(
      statusToEdit
        ? {
            label: statusToEdit.label,
            color: statusToEdit.color,
            isClosed: statusToEdit.isClosed,
          }
        : {
            label: Object.fromEntries(appLocales.map((l) => [l.title, ""])),
            color: "",
            isClosed: false,
          },
    );
  }, [appLocales, statusToEdit]);

  const handleSubmit = () => {
    createFunction(
      {
        label: tempData?.label,
        color: tempData?.color,
        isClosed: tempData?.isClosed,
      },
      statusToEdit?.id,
    );
  };

  return (
    <Modal
      open={showCreateStatusModal}
      width={655}
      onCancel={() => setShowCreateStatusModal(false)}
      title={null}
      footer={null}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: "16px",
      }}
    >
      <div style={{ display: "flex" }}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t("ticketingv2.AddStatus")}
        </Typography.Text>
      </div>

      <SegmentedLanguageSelector
        tempData={tempData}
        setTempData={setTempData}
        color
        caption={t("ticketingv2.NameAndColor")}
        type="status"
      />

      <div style={{ display: "flex", gap: 8 }}>
        <Switch
          size="small"
          checked={tempData?.isClosed}
          onChange={() => setTempData({ ...tempData, isClosed: !tempData?.isClosed })}
        />
        <Typography.Text style={{ fontSize: 12 }}>
          {t("ticketingv2.IsClosingState")}
        </Typography.Text>
      </div>

      <div style={{ display: "flex", marginTop: 24 }}>
        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
          <Button
            type="tertiary"
            onClick={() => setShowCreateStatusModal(false)}
            style={{ width: 270 }}
          >
            {t("ticketingv2.Cancel")}
          </Button>
          <Button
            type="primary"
            disabled={
              statusToEdit
                ? appLocales.every(
                    (locale) =>
                      tempData?.label?.[locale.title] === statusToEdit?.label[locale.title],
                  ) &&
                  tempData?.color === statusToEdit?.color &&
                  tempData?.isClosed === statusToEdit?.isClosed
                : !appLocales.some((locale) => tempData?.label?.[locale.title])
            }
            onClick={handleSubmit}
            style={{ width: 270 }}
          >
            {t("ticketingv2.Continue")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateStatusModal;
