import { Input, Popover, Segmented, Typography } from "antd";
import React, { useState } from "react";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";
import { useTranslation } from "react-i18next";
import IconPicker from "../../../Common/IconPicker/IconPicker";
import { CirclePicker } from "react-color";
import { useSelector } from "react-redux";

const SegmentedLanguageSelector = ({ tempData, setTempData, caption, color, icon, type }) => {
  const { colors, symbols } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const [lang, setLang] = useState(i18n.language.split("-")[0]);
  const appLocales = useSelector((state) => state.userWS?.userData?.locales);

  const dataType = tempData?.type || type;

  //! bg optionnel ?
  //TODO LABEL PERSONNALISE

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        borderRadius: symbols.base_shape.radius,
        backgroundColor: colors.light_background,
        padding: 10,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {caption}
        </Typography.Text>
        <Segmented
          size="medium"
          style={{
            backgroundColor: colors.grey_40,
            width: "fit-content",
          }}
          value={lang}
          options={
            !!appLocales?.length &&
            appLocales.map((l) => ({
              label: (
                <div
                  style={{
                    transition: "all 0.3s ease",
                    padding: "0 8px 0 12px",
                    display: "flex",
                    alignItems: "center",
                    gap: 8,
                    color: lang === l.title ? colors.primary_dark : "white",
                  }}
                >
                  <Typography.Text
                    strong
                    style={{ color: lang === l.title ? colors.primary_dark : "white" }}
                  >
                    {l.title?.toUpperCase()}
                  </Typography.Text>
                  {tempData?.label?.[l.title] ? (
                    <Glyph name="done" style={{ fontSize: 15 }} />
                  ) : (
                    <Glyph name="close" style={{ fontSize: 15 }} />
                  )}
                </div>
              ),
              value: l.title,
            }))
          }
          onChange={(value) => {
            setLang(value);
          }}
        />
      </div>
      <div style={{ display: "flex", gap: 8 }}>
        {icon && (
          <IconPicker
            icon={tempData?.icon}
            setIcon={(value) =>
              setTempData({
                ...tempData,
                icon: value,
              })
            }
            color={colors.grey_40}
            target={
              <div
                style={{
                  minHeight: 50,
                  minWidth: 50,
                  backgroundColor: colors.grey_40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: symbols.base_shape.radius,
                  cursor: "pointer",
                }}
              >
                <Glyph name={tempData?.icon || "search"} style={{ color: "white", fontSize: 20 }} />
              </div>
            }
          />
        )}
        {color && (
          <Popover
            title={null}
            content={
              <CirclePicker
                style={{ borderRadius: symbols.base_shape.radius }}
                onChange={(c) => {
                  setTempData({ ...tempData, color: c.hex });
                }}
              />
            }
          >
            <div
              style={{
                backgroundColor: tempData?.color || colors.grey_40,
                borderRadius: symbols.base_shape.radius,
                height: 50,
                width: 50,
                minHeight: 50,
                minWidth: 50,
              }}
            />
          </Popover>
        )}
        <Input
          suffix={
            tempData?.label?.[lang] ? (
              <Glyph
                name="cancel"
                style={{ color: colors.grey_40, cursor: "pointer" }}
                onClick={() =>
                  setTempData({
                    ...tempData,
                    label: { ...tempData?.label, [lang]: "" },
                  })
                }
              />
            ) : (
              // prevent square borderRadius from antd
              <></>
            )
          }
          value={tempData?.label?.[lang]}
          placeholder={
            dataType === "type"
              ? `${t("ticketingv2.TypeName")} ( ${lang} )`
              : dataType === "category"
              ? `${t("ticketingv2.CatName")} ( ${lang} )`
              : dataType === "subCategory"
              ? `${t("ticketingv2.SubCatName")} ( ${lang} )`
              : dataType === "status"
              ? `${t("ticketingv2.StatusName")} ( ${lang} )`
              : ""
          }
          onChange={(e) => {
            setTempData({
              ...tempData,
              label: { ...tempData?.label, [lang]: e.target.value },
            });
          }}
        />
      </div>
    </div>
  );
};

export default SegmentedLanguageSelector;
