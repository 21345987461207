import { Button, Card, Col, Form, Row, Select, Typography } from "antd";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useDesignTokens from "../../../../../../hook/useDesignTokens";
import Glyph from "../../../../../Common/Glyph/Glyph";
import { DAY_TYPES, DAY_TYPES_ICONS } from "../../../SpaceBookingResident";

const SelectDayType = ({ value, onChange }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  const DAY_TYPE_STYLE = {
    padding: 3,
    cursor: "pointer",
  };
  const DAY_TYPE_STYLE_SELECTED = {
    padding: 0,
    cursor: "pointer",
    border: `3px solid ${colors.secondary_base}`,
  };
  const DAY_TYPE_BODY_STYLE = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
    padding: 0,
  };

  return (
    <div style={{ display: "grid", gridTemplateColumns: "repeat(4, 1fr)", gap: 10 }}>
      <Card
        onClick={() => onChange(DAY_TYPES.ON_SITE)}
        style={value === DAY_TYPES.ON_SITE ? DAY_TYPE_STYLE_SELECTED : DAY_TYPE_STYLE}
        bodyStyle={DAY_TYPE_BODY_STYLE}
      >
        <Glyph
          className={value === DAY_TYPES.ON_SITE ? "secondary" : undefined}
          name={DAY_TYPES_ICONS[DAY_TYPES.ON_SITE]}
        />
        <Typography.Text strong style={{ hyphens: "auto", textAlign: "center" }}>
          {t(`spas.type.${DAY_TYPES.ON_SITE}`)}
        </Typography.Text>
      </Card>
      <Card
        onClick={() => onChange(DAY_TYPES.TELEWORKING)}
        style={value === DAY_TYPES.TELEWORKING ? DAY_TYPE_STYLE_SELECTED : DAY_TYPE_STYLE}
        bodyStyle={DAY_TYPE_BODY_STYLE}
      >
        <Glyph
          className={value === DAY_TYPES.TELEWORKING ? "secondary" : undefined}
          name={DAY_TYPES_ICONS[DAY_TYPES.TELEWORKING]}
        />
        <Typography.Text strong style={{ hyphens: "auto", textAlign: "center" }}>
          {t(`spas.type.${DAY_TYPES.TELEWORKING}`)}
        </Typography.Text>
      </Card>
      <Card
        onClick={() => onChange(DAY_TYPES.TRAVEL)}
        style={value === DAY_TYPES.TRAVEL ? DAY_TYPE_STYLE_SELECTED : DAY_TYPE_STYLE}
        bodyStyle={DAY_TYPE_BODY_STYLE}
      >
        <Glyph
          className={value === DAY_TYPES.TRAVEL ? "secondary" : undefined}
          name={DAY_TYPES_ICONS[DAY_TYPES.TRAVEL]}
        />
        <Typography.Text strong style={{ hyphens: "auto", textAlign: "center" }}>
          {t(`spas.type.${DAY_TYPES.TRAVEL}`)}
        </Typography.Text>
      </Card>
      <Card
        onClick={() => onChange(DAY_TYPES.DAY_OFF)}
        style={value === DAY_TYPES.DAY_OFF ? DAY_TYPE_STYLE_SELECTED : DAY_TYPE_STYLE}
        bodyStyle={DAY_TYPE_BODY_STYLE}
      >
        <Glyph
          className={value === DAY_TYPES.DAY_OFF ? "secondary" : undefined}
          name={DAY_TYPES_ICONS[DAY_TYPES.DAY_OFF]}
        />
        <Typography.Text strong style={{ hyphens: "auto", textAlign: "center" }}>
          {t(`spas.type.${DAY_TYPES.DAY_OFF}`)}
        </Typography.Text>
      </Card>
    </div>
  );
};

const CreateStepsType = ({ mode, form, initialValues }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const teleworkingLimit = useSelector((state) => state.spaceServiceWS.teleworkingLimit);

  const siteId = Form.useWatch("siteId", form);

  const spaceBookingType = useMemo(() => {
    return sites?.find((c) => c.id === siteId)?.spaceBooking?.type || 0;
  }, [siteId, sites]);

  return (
    <Form
      form={form}
      name={"type"}
      preserve={false}
      initialValues={initialValues}
      onValuesChange={(changed) => {
        logger.log("changed", changed);
        if (changed.siteId) {
          form.setFieldsValue({
            sectorId: sites?.find((c) => c?.id === changed.siteId)?.sectors[0]?.id,
          });
        }
      }}
    >
      <Row gutter={[10, 40]}>
        <Col span={24}>
          <Typography.Paragraph strong type="secondary">
            {t("spas.request.type.title")}
          </Typography.Paragraph>
          <Form.Item noStyle name={["type"]} rules={[{ required: true }]}>
            <SelectDayType />
          </Form.Item>
        </Col>
        <Form.Item noStyle shouldUpdate={(pre, cur) => pre.type !== cur.type}>
          {({ getFieldValue }) => (
            <>
              {mode === "self" &&
                getFieldValue(["type"]) === DAY_TYPES.TELEWORKING &&
                teleworkingLimit?.limitReached && (
                  <Col offset={3} span={18} style={{ textAlign: "center" }}>
                    <Typography.Text strong type="secondary">
                      {t(`spas.teleworking.limit.${teleworkingLimit.unit}`, {
                        count: teleworkingLimit.max,
                      })}
                    </Typography.Text>
                  </Col>
                )}
              {getFieldValue(["type"]) === DAY_TYPES.ON_SITE && (
                <Col span={24}>
                  <Typography.Paragraph strong type="secondary">
                    {t("spas.request.site.title")}
                  </Typography.Paragraph>
                  <Card style={{ marginBottom: 10 }}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                        gap: 20,
                      }}
                    >
                      <Glyph name="business" className={"secondary"} />
                      <Form.Item noStyle name={["siteId"]} rules={[{ required: true }]}>
                        <Select
                          className="accent"
                          bordered={false}
                          size={"middle"}
                          style={{
                            width: "100%",
                            marginTop: -8,
                            marginBottom: -8,
                            backgroundColor: colors.secondary_base.replace(/(.{2})$/i, "33"),
                          }}
                          options={sites
                            ?.filter((s) => !!s.spaceBooking && !!s.sectors?.length)
                            ?.filter((s) => mode === "self" || s.spaceBooking.type !== 1)
                            .map((s, index) => ({
                              label: s.title,
                              value: s.id,
                              key: index,
                            }))}
                        />
                      </Form.Item>
                    </div>
                  </Card>
                  <Form.Item noStyle name={["sectorId"]} rules={[{ required: true }]}>
                    <Select
                      size={"large"}
                      style={{
                        width: "100%",
                        display: spaceBookingType !== 1 ? "block" : "none",
                      }}
                      options={(sites.find((s) => s.id === siteId)?.sectors || [])
                        .filter((s) => s.canBook)
                        .sort((a, b) => (a?.title).localeCompare(b?.title))
                        .map((s) => ({
                          label: s.title,
                          value: s.id,
                          key: s.id,
                        }))}
                    />
                  </Form.Item>
                </Col>
              )}
            </>
          )}
        </Form.Item>

        <Col span={20} offset={2}>
          <Button block type="primary" htmlType="submit">
            {t("Next")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default CreateStepsType;
