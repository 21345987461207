/* eslint-disable import/no-anonymous-default-export */
import DWMTrads from "@trad/DWMProduct.json";
import CommonTrads from "./Common.json";

const trads = DWMTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.fr };
}, {});

const common = CommonTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.fr };
}, {});

export default {
  translation: {
    //COMMON
    "NoResult": "Aucun résultat",
    "Available": "Disponible",
    "NotAvailable": "Indisponible",
    "In": "Dans",
    "SeeItinerary": "Voir l'itinéraire",
    "Today": "Aujourd'hui",
    "Filters": "Filtres",
    "Back": "Retour",
    "Next": "Continuer",
    "Finish": "Terminer",
    "Save": "Enregistrer",
    "Floor": "Étage",
    "Cancel": "Annuler",
    "Infos": "Infos",
    "SeeMore": "Voir plus",
    "Full": "Complet",
    "Confirm": "Confirmer",
    "SelectAll": "Tout sélectionner",
    "All": "Tous",
    "PrivacyPolicy": "Politique de confidentialité",
    "CreateActu": "Gérer les actualités",
    "CreateActuPermanent": "Gérer les contenus froids",
    "Hidden": "Masqué",
    "ErrorOccured": "Une erreur s'est produite.",
    "Add": "Ajouter",
    "Refresh": "Rafraichir",
    "Yes": "Oui",
    "Guests": "Invités & Externes",
    "Collaborators": "Collaborateurs",
    "cancel": "Annuler",
    "accept": "Accepter",
    "notAccept": "Ne pas accepter",
    "pleaseRead": "Veuillez lire et accepter les CGU et la politique de confidentialité pour continuer",
    "readthe": "Je confirme avoir lu les",
    "readthe2": "Je confirme avoir lu la",
    "CGU": "CGU",
    "validCGU": "Validez les CGU et la Politique de confidentialité",
    "Next_onboarding": "Suivant",
    "Skip": "Passer",
    "toStart": "Commencer",
    "Closed": "Fermer",

    "Languages": "Langues",
    "French": "Français",
    "English": "Anglais",
    "Dutch": "Néerlandais",
    "LanguageChangeWarnTitle": "Relancer l'application ?",
    "LanguageChangeWarnExplain": "Afin que le changement de langue soit effectif, votre application va être relancée. Êtes-vous sûr de vouloir continuer ?",

    "OCCUPIED": "Occupé",
    "AVAILABLE": "Disponible",

    // LOGIN
    "Welcome": "Bienvenue",
    "OnDashboard": "Identifiez-vous pour continuer.",
    "Login": "Me connecter",

    // LOADER
    "Loading": "Chargement en cours",
    "LoadingMap": "Chargement de la carte...",
    "LoadingPlanning": "Chargement de l'agenda",

    //HEADER

    //DASHBOARD
    "booking": "réservation",
    "bookings": "réservations",
    "WelcomeDesk": "Accueil",

    //FastBooking
    "SpaceAvailable": "Espaces disponibles",
    "CurrentReservation": "Mes réservations",
    "Book": "Réserver",
    "BookDuring": "Réserver pendant",
    "CancelBooking": "Annuler la réservation",
    "BackHome": "Retour à la liste",
    "BookingArea": "Espace réservé",
    "BookingDontWork": "La réservation n’a pas été effectuée",
    "TryAgain": "Réessayez de réserver la salle à nouveau.",
    "Erase": "Effacer les filtres",
    "Apply": "Appliquer",
    "SpacesAvailable": "Afficher les espaces bientôt disponible",
    "ReservationDetail": "Détails de la réservation",
    "Slots": "Créneau horaire",
    "numberPeople": "Nombre de personnes",
    "AllSpaces": "Tous les espaces",
    "NoResultFloor": "Aucun résultat à cet étage",
    "TrySearching": "Essayez de rechercher à un autre étage ou supprimez des filtres.",
    "AvailbleFloor": "Disponibilité dans les autres étages",
    "ResultFloor": "résultat(s) à cet étage",
    "Equipements": "Équipements",

    //fastbooking evo
    "SeeSpaces": "Voir la liste des espaces",
    "BookThe": "Réserver le",
    "From": "À partir de",
    "From_": "de",
    "When": "Pendant",
    "MyDay": "Ma journée",
    "Consultbook": "Cliquez ici pour consulter toutes vos réservations",
    "ApplyFilter": "Appliquer les filtres",
    "AvancedSearch": "Recherche avancée",
    "AllFloor": "Tous les étages",
    "Services": "Services",
    "Result": "résultat",
    "Treatment": "En cours de traitement",
    "Places": "place(s)",
    "BookCanceled": "Réservation annulée",
    "ErrorCancelBook": "Impossible d'annuler la réservation",
    "Spaces": "Espace(s)",
    "SelectTheSpace": "Sélectionner l'espace",
    "Of": "de",
    "NumberPlaces": "Nombre de place(s)",
    "None": "Aucun",
    "SearchByName": "Rechercher par nom",
    "Until": "Jusqu'à",
    "To": "à",
    "Started": "Début à",
    "StartedThe": "Début le",
    "AdvertisementMessage": "Attention, au delà de cette date, les espaces en libre service ne seront pas affichés.",
    "fastbooking.sureCanceled": "Êtes-vous sûr de vouloir annuler la réservation ?",
    "fastbooking.rooms.types": "Type d'espace",
    "fastbooking.updateSite": "Changer de site",
    "fastbooking.redirectSite": "Vous allez être redirigé sur le site de {{site}}. Une fois connecté, cliquez sur {{fastbooking_name}} pour réserver un espace.",
    "backToFilter": "Retour aux filtres",
    "fastbooking.place": "place",
    "fastbooking.The_": "Le ",
    "fastbooking._from_": " de ",
    "fastbooking._to_": " à ",
    "fastbooking.Today": "Aujourd'hui",

    //WebOnMobile
    "mobile.downloadApp": "Téléchargez l'application",
    "mobile.ExpireOn_": "Expire le : ",
    "mobile.AccessService": "Accéder au service",
    "mobile.YourAccessBadge": "Votre badge d'accès au bâtiment",
    "mobile.NoAccessCode": "Aucun code d'accès",
    "mobile.logout": "Déconnexion",
    "mobile.Home": "Accueil",

    //GuidMe
    "FindSpace": "Saisir le nom de l'espace",
    "LastSearch": "Mes dernières recherches",
    "Favoris": "Mes favoris",
    "Itinerary": "Itinéraire",
    "Duration": "Durée",
    "Start": "Saisir un point de départ",
    "End": "Saisir un point d'arrivée",

    //Ticketing
    "Requests": "Mes tickets",
    "NewTicket": "Nouveau Ticket",
    "New": "Nouveau",
    "TakenIntoAccount": "Pris en compte",
    "Suspended": "Suspendu",
    "Close": "Fermé",
    "NoWaiting": "Aucun nouveau ticket",
    "NoTaken": "Aucun ticket pris en compte",
    "NoSuspended": "Aucun ticket suspendu",
    "NoClose": "Aucun ticket fermé",
    "Status": "Statut",
    "Location": "Localisation",
    "Pictures": "Photos",
    "By": "Par",
    "Category": "Catégorie",
    "SelectCategory": "Séléctionner une catégorie",
    "ChooseLocation": "Définir une localisation",
    "WriteSomething": "Ajoutez des précisions sur votre demande et sa localisation",
    "Adds": "Ajouter des documents",
    "YourRequest": "Votre demande est prise en compte",
    "Informed": "Vous serez informé·e de l’avancement de votre demande dans l’application",
    "UseSearch": "Utilisez le champ de recherche pour trouver un espace.",
    "closed": "Fermé",
    "CreateTicket": "Créer un ticket",
    "AddComment": "Ajouter un commentaire",
    "AllRequest": "Tous mes tickets",
    "Summary": "Résumé",
    "Followed": "Suivi",
    "Discussion": "Discussion",
    "Subcategory": "Sous-catégorie",
    "MoreDetails": "Saisie une sous-catégorie",
    "Description": "Description",
    "errorTicket": "Erreur lors de la création du ticket",
    "errorDoc": "Vous avez atteint la limite du nombre de documents autorisés",
    "refresh": "Actualiser",

    /**
     * VISITORS
     */
    "visitors.delegated": "Mes visites déléguées",
    "visitors.forms.visit.create": "Créer une nouvelle visite",
    "visitors.forms.visit.update": "Mettre à jour la visite",
    "visitors.forms.visit.delete": "Supprimer la visite",
    "visitors.forms.visit.fields.duration.day": "{{count}} journée",
    "visitors.forms.visit.fields.duration.day_other": "{{count}} journées",
    "visitors.forms.visit.resident": "Inviter au nom de quelqu'un d'autre",
    "visitors.forms.visit.fields.resident": "Rechercher un résident",
    "visitors.forms.visit.fields.visitors": "Ajouter des invités",
    "visitors.forms.visit.fields.visitors.helper": "Saisissez le nom de la personne que vous souhaitez ajouter à l’invitation. Si vous ne trouvez pas cette personne dans le référentiel, entrez simplement son adresse email.",
    "visitors.forms.user.create": "Créer une fiche visiteur",
    "visitors.forms.user.update": "Modifier la fiche visiteur",
    "visitors.forms.user.fields.firstname": "Prénom",
    "visitors.forms.user.fields.lastname": "Nom de famille",
    "visitors.forms.user.fields.login": "Adresse mail",
    "visitors.forms.user.fields.login.forbidden": "Ce nom de domaine n'est pas autorisé pour créer une fiche visiteur",
    "visitors.forms.user.fields.company": "Société",
    "visitors.forms.user.fields.function": "Fonction",
    "visitors.forms.visit.detail": "Détail de votre visite",

    /**
     * WIFI
     */
    "wifi.ssid": "Nom du réseau",
    "wifi.password": "Mot de passe",
    "wifi.helper.title": "Gagnez du temps !",
    "wifi.helper.description": "Scannez ce QR Code avec votre smartphone pour vous connecter automatiquement au réseau Wifi.",

    /**
     * MAP
     */
    "map.loading": "Chargement du plan",

    /**
     * SPACE AS A SERVICE
     */
    "spas": "Space As a Service",
    "spas.status.PENDING": "En attente",
    "spas.status.PENDING_other": "En attentes",
    "spas.status.UNASSIGNED": "Non affecté",
    "spas.status.UNASSIGNED_other": "Non affectés",
    "spas.status.APPROVED": "Déclaration acceptée",
    "spas.status.APPROVED_other": "Déclaration acceptée",
    "spas.status.DECLINED": "Refusée",
    "spas.status.DECLINED_other": "Refusées",
    "spas.status.CANCELLED": "Annulée",
    "spas.status.CANCELLED_other": "Annulées",
    "spas.period.MORNING": "Matinée",
    "spas.period.AFTERNOON": "Après-midi",
    "spas.period.DAY": "Journée",
    "spas.type.ON_SITE": "Sur Site",
    "spas.type.TELEWORKING": "Télétravail",
    "spas.type.TRAVEL": "Déplacement",
    "spas.type.DAY_OFF": "Off",
    "spas.type.NOT_DECLARED": "Non déclaré",
    "spas.recurrence.NONE": "Aucune",
    "spas.recurrence.RECURRENCE": "Récurrence :",
    "spas.recurrence.EVERY_DAY": "Tous les jours",
    "spas.recurrence.EVERY_WEEK": "Tous les {{day}}s",
    "spas.recurrence.EVERY_MONTH": "Tous les mois",
    "spas.recurrence.EVERY_2_WEEK": "Un {{day}} sur deux",
    "spas.teleworking.limit.WEEK": "Vous avez atteint les {{count}} jours de télétravail autorisés cette semaine.",
    "spas.teleworking.limit.MONTH": "Vous avez atteint les {{count}} jours de télétravail autorisés ce mois-ci.",
    "spas.tabs.MY_PLANNING": "Mon planning",
    "spas.tabs.MY_TEAM": "Mon équipe",
    "spas.tabs.MY_CONTACTS": "Mes connaissances",
    "spas.steps.DATES": "Etape {{count}} : Date",
    "spas.steps.TEAM_MEMBERS": "Etape {{count}} : Membres",
    "spas.steps.WORKPLACE": "Etape {{count}} : Espace",
    "spas.steps.ROOM": "Etape {{count}} : Espace",
    "spas.steps.REVIEW": "Etape {{count}} : Confirmation",
    "spas.history.createRequest": "La requête a été créé",
    "spas.history.approve": "Une date a été accepté",
    "spas.history.approveAll": "Toutes les dates ont été accéptés",
    "spas.history.decline": "Une date a été annulé",
    "spas.history.declineAll": "Toutes les dates ont été annulé",
    "spas.history.cancel": "Une date a été annulé",
    "spas.history.cancelAll": "Toutes les dates ont été annulés",
    "spas.history.overrided": "Des date ont été annulé pour libérer l'espace",
    "spas.planning": "Planning",
    "spas.request": "Demande",
    "spas.request.none": "Vous n’avez aucune déclaration ce jour. Pour en effectuer une, rendez-vous sur le bouton « Nouvelle déclaration » en haut de votre écran.",
    "spas.request.details": "Détails de le demande",
    "spas.request.update": "Modification de la déclaration",
    "spas.request.new": "Nouvelle déclaration",
    "spas.request.new.team": "Nouvelle déclaration d'équipe",
    "spas.request.cancel": "Annulation de la déclaration",
    "spas.request.comments": "Commentaires",
    "spas.request.id.title": "Demande ID {{id}}",
    "spas.request.site": "Où souhaitez vous travailler ce jour ?",
    "spas.request.isSerie": "Cette réservation comporte une occurence",
    "spas.request.type.title": "Que souhaitez-vous déclarer ?",
    "spas.request.site.title": "Où souhaitez-vous travailler ce jour ?",
    "spas.request.dates": "Pour les dates du",
    "spas.request.dates.title": "Sélectionnez une date",
    "spas.request.dates.helper": "Sélectionnez un ou plusieurs jours en cliquant sur le calendrier ci-dessous.",
    "spas.request.dates.helper2": "La déclaration sera effectuée sur le(s) jour(s) suivant(s) :",
    "spas.request.dates.dateOccupied": "Cette date n'est pas disponible sur la durée de réservation sélectionnée. Elle sera ignorée à la création.",
    "spas.request.dates.validator.min": "Vous devez sélectionner au moins une date",
    "spas.request.dates.validator.occupied": "Toutes les dates sélectionnées sont occupées sur la période sélectionnée.",
    "spas.request.startDate.title": "Date de début",
    "spas.request.startDate.helper": "Démarre le",
    "spas.request.period.title": "Durée",
    "spas.request.period.helper": "Sélectionnez une durée",
    "spas.request.recurrence": "Récurrence",
    "spas.request.recurrence.helper": "Définir une récurrence",
    "spas.request.recurrence.multi": "Vous ne pouvez pas définir de récurrence en sélectionnant plusieurs jours sur le calendrier.",
    "spas.request.recurrence.end": "Fin de la récurrence",
    "spas.request.members.title": "Déclarer pour",
    "spas.request.members.search": "Rechercher dans mon équipe",
    "spas.request.members.selected": "Personnes sélectionnées",
    "spas.request.members.noAccess": "N'a pas accès à ce secteur.",
    "spas.request.members.warning.availability": "Des utilisateurs n'ont pas été ajoutés car ils sont indisponibles.",
    "spas.request.members.warning.access": "Des utilisateurs n'ont pas été ajoutés car ils n'ont pas l'autorisation de déclarer sur ce secteur.",
    "spas.request.roomIds.title": "Sélectionnez un ou plusieurs espaces de travail",
    "spas.request.roomIds.warning": "Attention : Il n’y a pas assez d’espaces disponibles pour le nombre de personnes sélectionnées. Veuillez choisir un second espace ou un espace avec suffisamment de place.",
    "spas.request.endDate": "Se termine le {{date}}",
    "spas.request.forceEndDate": "Forcer la date de fin",
    "spas.request.from": "De : {{user}}",
    "spas.request.comment.title": "Commentaire",
    "spas.request.comment.helper": "Ajouter un commentaire (obligatoire)",
    "spas.request.actions.confirmBooking": "Confirmer la réservation",
    "spas.request.actions.book": "Continuer",
    "spas.request.actions.map": "Voir sur le plan",
    "spas.request.actions.workplaces": "Voir la liste",
    "spas.request.actions.approve": "Affecter une place",
    "spas.request.actions.approve_other": "Affecter des places",
    "spas.request.actions.decline": "Refuser la demande",
    "spas.request.actions.decline_other": "Refuser les demandes",
    "spas.request.actions.cancel": "Annuler la déclaration",
    "spas.request.actions.updateReservation": "Modifier la déclaration",
    "spas.request.actions.saveReservation": "Enregistrer les modifications",
    "spas.request.actions.updateSpace": "Modifier l'affectation",
    "spas.request.actions.updateSpace_other": "Modifier les affectations",
    "spas.request.actions.removeSpace": "Supprimer l'affectation",
    "spas.request.actions.removeSpace_other": "Supprimer les affectations",
    "spas.request.actions.occurence": "Uniquement cette journée",
    "spas.request.actions.serie": "Toute la série",
    "spas.request.actions.assignValidate": "Valider l'affectation",
    "spas.request.actions.assignIn": "Affecter dans {{desk}}",
    "spas.request.actions.assignAuto": "Affecter automatiquement",
    "spas.request.actions.assignAuto.selectMore": "Vous devez sélectionner plus de salle pour atteindre un nombre de place suffisant",
    "spas.request.actions.assignAuto.helper": "Sélectionner dans quel(s) espace(s) affecter {{count}} personne",
    "spas.request.actions.assignAuto.helper_other": "Sélectionner dans quel(s) espace(s) affecter {{count}} personnes",
    "spas.request.backCalendar": "Retour au calendrier",
    "spas.request.seeDay": "Voir ma journée",
    "spas.request.reservation": "Déclaration en cours",
    "spas.request.resaAccepted": "Déclaration reçue",
    "spas.request.resaRefused": "La déclaration n'a pas été effectuée",
    "spas.request.tryAgain": "Veuillez réessayer",
    "spas.conflict": "La déclaration n’a pas été effectuée sur les jours suivants",
    "spas.conflict.other": "Le poste de travail sélectionné n'est plus disponible",
    "spas.conflict.recurrence.other": "Le poste de travail sélectionné n’était pas disponible",
    "spas.conflict.recurrence.self": "Vous aviez déjà une déclaration",
    "spas.admin.header.week": "Semaine {{number}}",
    "spas.admin.header.weekPrevious": "Semaine précédente",
    "spas.admin.header.weekNext": "Semaine suivante",
    "spas.admin.header.pending": "Demandes en attente",
    "spas.admin.header.approved": "Demandes validés",
    "spas.admin.header.estimated": "Occupation estimée",
    "spas.admin.header.available": "{{count}} / {{total}} disponible",
    "spas.admin.header.available_other": "{{count}} / {{total}} disponibles",
    "spas.admin.header.occupied": "{{count}} / {{total}} place occupée",
    "spas.admin.header.occupied_other": "{{count}} / {{total}} places occupées",
    "spas.admin.header.map": "Voir le plan du bâtiment",
    "spas.admin.tabs.search": "Rechercher une personne",
    "spas.admin.tabs.selected": "{{count}} sélectionnée",
    "spas.admin.tabs.selected_other": "{{count}} sélectionnées",
    "spas.admin.tabs.resident": "Nom résident",
    "spas.admin.tabs.manager": "Nom manager",
    "spas.admin.tabs.from": "Auteur demande",
    "spas.admin.tabs.createdAt": "Date de la demande",
    "spas.admin.tabs.recurrence": "Récurrence",
    "spas.admin.tabs.space": "Emplacement",
    "spas.admin.tabs.affectedAt": "Date de l'affectation",
    "spas.admin.tabs.declinedAt": "Date refus",
    "spas.admin.map.search.title": "Sélectionner un espace",
    "spas.admin.map.search.helper": "Utilisez la carte ou le champ de recherche pour sélectionner un espace à attribuer.",
    "spas.admin.map.search": "Rechercher un espace",
    "spas.widget.noResa": "Aucune déclaration aujourd'hui",
    "spas.manage.followingPerson": "Personne(s) suivie(s)",
    "spas.manage.waiting.invitation": "invitation(s) en attente",
    "spas.manage.lengthPerson": "Appuyez ici pour consulter vos invitations en attente",
    "spas.manage.noResult": "Aucun résultat dans vos personnes suivies.",
    "spas.manage.addPerson": "Ajouter des personnes",
    "spas.manage.stopFollow": "Ne plus suivre",
    "spas.manage.noFollower": "Vous ne suivez aucune personne pour le moment.",
    "spas.manage.searchFollower": "Recherchez une personne dans l’annuaire de votre organisation et envoyez une invitation.",
    "spas.manage.received.invitation": "Invitations reçues",
    "spas.manage.shareNotebook": "Appuyez sur une invitation pour partager votre agenda.",
    "spas.manage.accepted": "Accepter l'invitation",
    "spas.manage.ignored": "Ignorer l'invitation",
    "spas.messages.create.error": "Impossible de modifier la déclaration",
    "spas.messages.cancel.error": "Impossible d'annuler la déclaration",
    "spas.messages.decline.error": "Impossible de refuser la demande",
    "spas.messages.removeSpace.error": "Impossible de supprimer l'affectation",
    "spas.messages.conflicts.error": "Impossible de vérifier la disponibilité de l'espace",
    "spas.people.present": "Qui sera présent sur site ?",
    "spas.people.whoResa": "Découvrez qui sera présent ce jour",
    "spas.people.noResult": "Aucun résultat dans vos personnes suivies pour ce jour.",
    "spas.people.find": "Rechercher dans les personnes suivies",
    "spas.messages.cancel.success": "La demande d'annulation a bien été prise en compte",
    "spas.request.confirm.presence": "Confirmer ma présence",
    "spas.request.validate": "Valider",
    "spas.presence.confirmed": "Présence confirmée",
    "spas.presence.present": "Votre présence a déjà été confirmée",
    "spas.presence.error": "Une erreur est survenue, votre présence n’a pas pu être confirmée. Veuillez réessayer.",
    "spas.presence.today": "Votre présence n’a pas pu être confirmée. Vous pouvez confirmer votre présence uniquement durant la période de réservation correspondante.",
    "spas.presence.success": "Vous avez confirmé votre présence",
    "spas.presence.code": "Saisir le code à 6 chiffres situé sur l’étiquette de bureau",
    "spas.place.available": "place(s) disponible(s)",
    "spas.workplace.title": "Poste de travail",
    "spas.workplace.subtitle": "Sélectionnez un poste de travail",
    "spas.workplace.multiple_dates": "Plusieurs jours sont sélectionnés, l'affichage des personnes suivies n'est pas disponible",
    "spas.workplace.loading.title": "Encore un instant",
    "spas.workplace.loading.subtitle": "Nous recherchons des postes de travail disponibles.",
    "spas.workplace.your": "Votre espace de travail",
    "spas.workplace.free": "places disponible(s)",
    "spas.workplacemap.helper": "Sélectionnez un bureau disponible en cliquant sur le plan.",
    "spas.room.nbDesk": "{{count}} bureaux",
    "spas.prsence.alreadyConfirmed": "Déjà confirmé",
    "spas.presence.noConfirmed": "Vous ne pouvez pas confirmer votre présence sur une réservation qui n’a pas démarré",
    "spas.request.error.sectors": "Vous ne pouvez pas utiliser cette fonctionnalité",
    "spas.request.error.sectors.content": "Vous n'avez pas les droits suffisants pour accéder à cette fonctionnalité. Veuillez contacter votre administrateur.",
    "spas.request.error.date": "Date de début supérieure à la date de fin",
    "spas.collabs.xDeclared": "{{count}} collaborateur(s) déclaré(s)",
    "spas.collabs.presence": "Présence",
    "spas.declarations": "{{count}} déclaration(s)",

    /**
     * PHONE BOOK
     */

    "phoneBook.seachPhoneBook": "Recherchez une personne dans l’annuaire de votre organisation",
    "phonebook.useSearch": "Utilisez le champ de saisie situé en haut de l’écran pour trouver une personne en écrivant les 3 premiers caractères de son nom ou de son prénom.",
    "phoneBook.backToSearch": "Retour à la recherche",
    "phoneBook.title": "Annuaire",
    "phoneBook.send.invitation": "Envoyer une invitation",
    "phoneBook.success": "Invitation envoyée",
    "phoneBook.error": "L'invitation n'a pas été envoyée",
    "phoneBook.error.alreadySend": "L'invitation a déjà été envoyée",
    "phoneBook.number.contact": "Affichage de {{nbItems}}/{{totalItems}} contacts. Affinez votre recherche pour trouver la personne que vous recherchez.",
    "phoneBook.search": "Rechercher un contact",

    /**
     * CONFORT
     */

    "Stage": "Étage",
    "Temperature": "Température",
    "Lighting": "Éclairage",
    "Ventilation": "Ventilation",
    "Blind": "Store",
    "Blinds": "Tous les stores",
    "Light": "Lumière",
    "ControlEquipment": "Contrôlez les équipements de confort d’un espace en le sélectionnant sur la partie gauche de l’écran.",

    // SEARCH ROOM
    "SelectSpace": "Sélectionnez un espace",
    "UseCard": "Utilisez le champ de recherche pour trouver un espace à piloter.",
    "ResultSearch": "Résultat de la recherche",
    "SearchSpace": "Rechercher un espace...",
    "mobile.searchResult": "Résultat de la recherche",

    // ERROR
    "ErrorSetLight": "Un problème est survenu sur l'éclairage",
    "ErrorSetBlind": "Un problème est survenu sur les stores",
    "ErrorSetBlindAngle": "Un problème est survenu sur l'inclinaison des stores",
    "ErrorSetTemperatures": "Un problème est survenu sur la température",
    "ErrorSetVentilation": "Un problème est survenu sur la ventilation",

    "ErrorModeAuto": "Impossible de lancer le mode auto",

    "ErrorGetLight": "Une erreur s'est produite sur l'éclairage, veuillez réessayer.",
    "ErrorGetBlind": "Une erreur s'est produite sur les stores, veuillez réessayer.",
    "ErrorGetTemperatures": "Une erreur s'est produite sur la température, veuillez réessayer.",
    "ErrorGetVentilation": "Une erreur s'est produite sur la ventilation, veuillez réessayer.",
    "ErrorGetComfort": "Le confort de la salle n'a pas pu être récupéré. Veuillez relancer une recherche.",

    // POWERBI
    "TypeDuration": "Choisissez ici d’afficher les résultats sur la journée entière ou uniquement sur une demie-journée.",
    "IntervalDate": "Sélectionnez un intervalle de date pré-sélectionné ou définissez un intervalle personnalisé.",
    "Interval": "Intervalle",

    // AGORA
    "Places.Frequentation": "Affluence",
    "Places.Now": "Cet espace est actuellement occupé à",
    "Places.Informations": "Informations",
    "Places.hours": "Horaires d'ouverture",
    "Places.Location": "Voir l'emplacement",
    "Places.Equipments": "Équipements",
    "Places.others": "Autres",
    "Places.Open": "Ouvert",
    "Places.Closed": "Fermé",
    "Places.Services": "Services",
    "Places.Clicked": "Cliquer ici",
    "Places.email": "Email",
    "Places.closed": "Fermé",
    "Places.phone": "Téléphone",
    "Places.Menu": "Menu",
    "Places.Card": "Ma carte",

    "Places.Frequentation.Content": "Le taux d’occupation en temps réel est obtenu grâce à des capteurs pouvant compter le nombre de personnes présentes dans cet espace.",
    "Places.Booking.Details.Title": "Détails de votre réservation",
    "Places.Booking.Details.Date": "Date de la réservation",
    "Places.Booking.Details.Hours": "Horaire",
    "Places.Booking.Details.available": "place(s) disponible(s)",
    "Places.Booking.Success.Title": "Réservation enregistrée",
    "Places.Booking.Success.View": " Consulter ma réservation",
    "Places.Booking.Cancel.Title": "Détail de ma réservation",
    "Places.Booking.Cancel.Party": "Réservation pour {{party}} personne(s)",
    "Places.Booking.Cancel.Button": "Annuler ma réservation",
    "Places.Solde.Text": "Solde restant",
    "Places.Solde.Link": "Recharger mon compte",
    "Places.Booking.Recap.Title": "Réservation en cours",
    "Places.Booking.Recap.seats": "Pour {{seats}} personnes",
    "Places.Booking.Error.Title": "La réservation n’a pas été effectuée",
    "Places.Booking.Error.Text": "Essayez de réserver à nouveau.",
    "Places.Menu.date": "Choisissez une date",
    "Places.Title.Booking": "Réserver un créneau",
    "Places.Title.Solde": "Mon solde",
    "Places.Title.Cancel": "Ma réservation",
    "Places.Title.Menu": "Menu",
    "Places.Booking.Next": "Mes 2 prochaines réservations",

    // FACILITIES
    "facilities.week": "Semaine {{number}}",
    "facilities.weekPrevious": "Semaine précédente",
    "facilities.weekNext": "Semaine suivante",
    "facilities.dashboard.noEvent": "Aucun événement prévu ce jour.",
    "facilities.resident.helper": "Suivez l'organisation de vos événements et collaborez facilement avec les services généraux.",
    "facilities.resident.noEvent.title": "Vous n'avez aucun événement à venir.",
    "facilities.resident.noEvent.subtitle": "Réservez un espace dans votre calendrier Outlook pour que les services généraux organisent votre événement.",
    "facilities.filters.categories": "Catégories",
    "facilities.filters.rooms": "Espaces",
    "facilities.filters.rooms.rooms": "Espace",
    "facilities.filters.rooms.types": "Type d'espace",
    "facilities.filters.organizer": "Organisateur",
    "facilities.filters.hidden": "Afficher les événements masqués",
    "facilities.filters.new": "Masquer les événements non traités",
    "facilities.filters.tasks": "Masquer les événements sans action en cours",
    "facilities.filters.erase": "Effacer les filtres",
    "facilities.event.new": "Nouvel événement",
    "facilities.event.notHandled": "Non traité",
    "facilities.event.noCategory": "Non catégorisé",
    "facilities.event.tasks.tooltip": "{{completed}} actions sur {{total}}",
    "facilities.event.subscribed": "Les mails d'alerte sont activés",
    "facilities.event.notSubscribed": "Les mails d'alerte ne sont pas activés",
    "facilities.event.helper": "Dans l'onglet Général, renommez l'événement et attribuez lui une catégorie pour le retrouver facilement. \n Ajoutez de nouveaux onglets pour répartir les informations entre les différents contributeurs.",
    "facilities.event.tab.new": "Nouvel onglet",
    "facilities.event.tab.helper": "Donnez un nom à cet onglet puis ajoutez des contributeurs. \n Personnalisez cet onglet en utilisant le mode édition en haut à droite, bouton (...).",
    "facilities.event.tab.general": "Général",
    "facilities.event.tab.description": "Notes",
    "facilities.event.section.TEXT": "Zone de texte",
    "facilities.event.section.TEXT.helper": "Ajoutez un paragraphe de texte qui sera visible de tous les contributeurs.",
    "facilities.event.section.TEXT.new": "Nouvelle zone de texte",
    "facilities.event.section.RADIO": "Liste de choix",
    "facilities.event.section.RADIO.helper": "Ajoutez une liste de choix, celle-ci pourra être modifiée par les contributeurs.",
    "facilities.event.section.RADIO.new": "Nouvelle Liste de choix",
    "facilities.event.section.LIST": "Liste à puce",
    "facilities.event.section.LIST.helper": "Créez une liste à puces, celle-ci pourra être modifiée par les contributeurs.",
    "facilities.event.section.LIST.new": "Nouvelle liste à puce",
    "facilities.event.section.CHECKBOX": "Liste d'actions",
    "facilities.event.section.CHECKBOX.helper": "Listez ici les différentes actions à mener. Les contributeurs pourront modifier la liste des actions et marquer une action comme réalisée.",
    "facilities.event.section.CHECKBOX.new": "Nouvelle liste d'actions",
    "facilities.event.section.FILE": "Pièces jointes",
    "facilities.event.section.FILE.helper": "Téléchargez des fichiers qui seront visibles par les contributeurs. \n Taille maximum : 50Mo",
    "facilities.event.section.FILE.new": "Nouvelle zone de pièces jointes",
    "facilities.event.section.add": "Ajoutez une section de votre choix",
    "facilities.event.hide": "Masquer l'événement",
    "facilities.event.hide_off": "Réafficher l'événement",
    "facilities.event.date": "Date",
    "facilities.event.startDate": "Débute le",
    "facilities.event.endDate": "Se termine le",
    "facilities.event.location": "Lieu",
    "facilities.event.description": "Description",
    "facilities.event.category": "Catégorie",
    "facilities.event.organizer": "Organisateur",
    "facilities.event.save_cancel": "Annuler les changements",
    "facilities.event.save": "Sauvegarder",
    "facilities.event.contributors": "Contributeurs",
    "facilities.event.contributors.add": "Ajouter un contributeur",
    "facilities.event.contributors.remove": "Retirer",
    "facilities.event.contributors.helper": "Ajoutez ici des personnes faisant partie de votre organisation. \n Les contributeurs auront accès uniquement à cet onglet. Ils pourront collaborez avec vous en modifiant le contenu et en ajoutant des éléments à cet onglet.",
    "facilities.event.externals": "Externes",
    "facilities.event.externals.add": "Ajouter un email externe",
    "facilities.event.externals.remove": "Retirer",
    "facilities.event.externals.helper": "Ajoutez ici des personnes en dehors de votre organisation. \n Elles seront notifiées par mail des modifications ayant lieu sur cet onglet.",
    "facilities.event.edit.open": "Editer cet onglet",
    "facilities.event.edit.close": "Quitter le mode édition",
    "facilities.event.delete": "Supprimer l'onglet",
    "facilities.event.comments": "Commentaires",
    "facilities.event.comments.add": "Rédiger un commentaire",
    "facilities.event.comments.send": "Envoyer",
    "facilities.categories.new": "Créer une catégorie",
    "facilities.files.status.NEW": "Nouveau",
    "facilities.files.status.PENDING": "En attente",
    "facilities.files.status.VALIDATED": "Validé",
    "facilities.files.status.DECLINED": "Refusé",
    "facilities.files.status.ABANDONED": "Abandonné",
    "facilities.files.remove": "Retirer",
    "facilities.files.upload.title": "Cliquez ou faites glisser un fichier dans cette zone pour charger un fichier",
    "facilities.files.upload.save": "Enregistrer ce(s) fichier(s)",

    //PUBLIC
    "Public.activated": "Votre compte est activé !",
    "Public.connected": "Connectez-vous dès à présent sur l'application mobile et sur le portail web avec votre identifiant",
    "Public.connected2": "Connectez-vous dès à présent sur l'application mobile avec votre identifiant",
    "Public.upload": "Téléchargez l’application mobile depuis les stores d’applications ou rendez-vous sur le portail web en utilisant les liens ci-dessous.",
    "Public.upload2": "Téléchargez l’application mobile depuis les stores d’applications en utilisant les liens ci-dessous.",
    "Public.error.activated": "Votre compte est déjà activé",
    "Public.password.verification": "Vérification de l'utilisateur",
    "Public.password.activated": "Activez votre compte",
    "Public.password.experience": "Pour profiter de l’expérience sur notre application mobile et sur le portail web, activez votre compte invité en choisissant un mot de passe.",
    "Public.password.identifiant": "Identifiant",
    "Public.password.email": "Utilisez l’adresse email sur laquelle vous avez reçu votre mail d’invitation.",
    "Public.password.password": "Choisissez un mot de passe",
    "Public.password.obligatory": "Le champ mot de passe est obligatoire",
    "Public.password.strongPassword": "Choisissez un mot de passe fort en suivant les conditions suivantes.",
    "Public.password.eight": "8 caractères min.",
    "Public.password.one": "1 caractère spécial",
    "Public.password.maj": "1 majuscule",
    "Public.password.number": "1 chiffre",
    "Public.password.min": "1 minuscule",
    "Public.password.confirm": "Confirmez votre mot de passe",
    "Public.password.same": "Mots de passe identiques",
    "Public.password.active": "Activer mon compte",
    "Public.expired.finish": "Votre réservation est terminée",
    "Public.expired.free": "Vous ne pouvez pas libérer / conserver votre espace",
    "Public.failure.request": "Votre demande n’a pu être prise en compte.",
    "Public.failure.spaces": "Votre demande concernant la réservation de l'espace {{title}} de {{startHour}} à {{endHour}} n’a pas pu être traitée suite à une erreur.",
    "Public.failure.excuse": "Nous nous excusons pour la gêne occasionnée et vous invitons à réessayer plus tard.",
    "Public.success.free": "L'espace a été libéré",
    "Public.success.spaces": "La réservation de l'espace {{title}} de {{startHour}} à {{endHour}} a été annulée.",
    "Public.success.nowFree": "Cet espace est maintenant libre pour d’autres réservations.",
    "Public.keep.kept": "Votre réservation est conservée",
    "Public.keep.noAlert": "Vous ne recevrez plus d’alerte pour cette réservation.",
    "public.keep.spaces": "La réservation de l'espace {{title}} de {{startHour}} à {{endHour}} est conservée.",
    "Public.password.error": "Erreur lors de la création du mot de passe",

    //TRANSFERT
    "transfert.myDeparture": "Mon départ",
    "transfert.site": "Site de départ",
    "transfert.building": "Bâtiment",
    "transfert.boxes": "Dépôt des cartons",
    "transfert.move": "Déménagement",
    "transfert.myMoveIn": "Mon Emménagement",
    "transfert.moveIn": "Emménagement prévu",
    "transfert.zone": "Zone d’arrivée",
    "transfert.help": "Besoin d'aide ?",
    "transfert.question": "Une question sur votre déménagement ?",
    "transfert.contact": "Contacter votre correspondant qui pourra vous aider.",
    "transfert.myMove": "Mon déménagement",
    "transfert.welcome": "Bienvenue dans la fonctionnalité Transfert !",
    "transfert.findInfo": "Retrouvez ici toutes les informations liées à votre déménagement.",
    "transfert.understand": "J’ai compris",
    "transfert.noMove": "Vous n'avez aucun déménagement",
    "transfert.addAndUpdateFile": "Ajouter ou modifier un fichier",
    "transfert.addFile": "Ajouter un fichier",
    "transfert.error": "Erreur lors de l’ajout du fichier",
    "transfert.success": "Ajout du fichier réussi",
    "transfert.created": "Créé(s)",
    "transfert.updated": "Modifié(s)",
    "transfert.deleted": "Supprimé(s)",
    "transfert.row": "Ligne",
    "transfert.errors": "Erreur(s)",

    // CATERING
    "catering.prestationAvailable": "Prestation(s) disponible(s)",
    "catering.prestationNoAvailable": "Prestation(s) non disponible(s)",
    "catering.orderOutOfTime": "Votre commande est hors délai, il est trop tard pour réserver les prestations ci-dessous.",
    "catering.order": "Commander une prestation",
    "catering.newOrder": "Nouvelle demande",
    "catering.orderFor": "Commander pour le",
    "catering.prestation": "Mes prestations en cours",
    "catering.outlook": "Commandé depuis Outlook",
    "catering.newPresta": "Nouvelle prestation",
    "catering.thisSpace": "Choisir cet espace",
    "catering.addPresta": "Ajouter la prestation",
    "catering.saveOrder": "Enregistrer la commande",
    "catering.askSpace": "Dans quel espace souhaiteriez-vous commander une prestation ?",
    "catering.orderThe": "Commander le",
    "catering.addAPrestra": "Ajouter une prestation",
    "catering.selectRubrique": "Sélectionnez une rubrique",
    "Total": "Total",
    "catering.resume": "Résumé de la prestation",
    "catering.canceled": "Annuler la commande",
    "catering.sureCanceled": "Êtes-vous sûr de vouloir annuler la commande ?",
    "No": "Non",
    "catring.successUpdate": "Modification réussie",
    "catering.errorUpdate": "Erreur lors de la modification",
    "catering.update": "Modifier la commande",
    "catering.noOrder": "Vous ne pouvez plus modifier la commande",
    "amount": "Quantité",
    "obligatory": "Champ obligatoire",
    "canceled": "Annulé",
    "catering.request": "Demande",
    "catering.date": "Date :",

    // NEWS
    "BackTo": "Retour à {{topic}}",
    "Sure_Leave": "Vous êtes sur le point de quitter l'éditeur de contenu. Vous perdrez le travail en cours non publié. Êtes-vous sûr de vouloir continuer ?",
    "Yes_Leave": "Oui, quitter",
    "Select_A_Group": "Sélectionnez au moins un groupe pour pouvoir publier",
    "Content_Editing": "Edition de contenu",
    "Notif_Editing": "Edition de notification",
    "Publish": "Publier",
    "Update": "Mettre à jour",
    "Upload": "Ajouter",
    "Edit_Internationalized": "Sélectionnez la langue dans laquelle vous souhaitez éditer votre publication.",
    "Image_Library": "Bibliothèque d'image",
    "Add_Image": "Ajouter une image",
    "Select_Image": "Sélectionnez une image depuis la bibliothèque",
    "Title": "Titre",
    "Notif_Trigger": "Déclenchement de la notification",
    "Subtitle": "Sous-titre",
    "Choose_Title": "Choisissez un titre",
    "Choose_Subtitle": "Choisissez un sous-titre",
    "Publication": "Publication",
    "Content_To_Be_Shawn": "Le contenu sera visible dans l'application à partir du",
    "Hide_Content": "Cacher le contenu avant sa publication",
    "Send_Notif": "Envoyer une notification",
    "Access": "Limiter la visibilité de la publication",
    "Target": "Cibles",
    "Select_Group": "Pour limiter la visibilité de cette publication, sélectionnez les groupes d'utilisateurs autorisés à la consulter. (Par défaut, la publication sera visible par tous les groupes)",
    "Select_Group2": "Sélectionner les destinataires de la notification",
    "No_Group_Found": "Aucun groupe trouvé",
    "Topics": "Topics",
    "Select_Topics": "Sélectionner un ou plusieurs topics pour la news",
    "No_Topic": "Aucun topic disponible",
    "Attach_To_Place": "S'il s'agit d'un événement, vous pouvez ajouter un lieu à votre publication.",
    "Attach_To_Place2": "Rattacher ce contenu à un lieu",
    "Room": "Espace",
    "No_Stage_Found": "Aucun étage trouvé",
    "Select_A_Room": "Sélectionnez un espace",
    "Select_A_Stage": "Sélectionnez un étage",
    "No_Room_Found": "Aucun espace trouvé",
    "Name": "Nom",
    "Enter_Name_Place": "Entrez le nom du lieu",
    "Publication_Preview": "Prévisualisation dans l'app",
    "Add_News": "Ajouter une news",
    "Add_Notif": "Créer une notification",
    "Add_Topic": "Ajouter un topic",
    "Update_Topic": "Modifier un topic",
    "Manage_Topic": "Gérer les topics",
    "Trendings": "À la une",
    "Published_On": "Publié le",
    "Created_On": "Créé le",
    "Header_Image": "Image d'en-tête",
    "At": "à",
    "Get_details": "Récupération des détails de la section...",
    "Get_Content": "Récupération des contenus...",
    "Get_Content_Error": "Echec lors de la récupération des détails de la section...",
    "Get_Groups": "Récupération des groupes Swizi...",
    "Get_Groups_Error": "Echec lors de la récupération des groupes",
    "Get_Topics_Error": "Echec lors de la récupération des topics",
    "Get_Media": "Récupération des médias Swizi...",
    "Get_Media_Error": "Echec lors de la récupération des groupes Swizi",
    "Get_Resource": "Récupération des ressources Swizi..",
    "Get_Resource_Error": "Echec lors de la récupération des ressources Swizi.",
    "Show_HTML": "Passer en mode HTML",
    "Show_Editor": "Passer en mode editeur",
    "Format_Code": "Formater le code HTML",
    "ShowMore": "Voir plus >",
    "Published": "Publié {{date}}",
    "PublishedAt": "Publié le {{date}}",
    "News": "Actualités",
    "InTheSameTopic": "Dans la même rubrique",
    "Event": "Événement",
    "Event_description": "Définissez un événement pour l'afficher dans la news",
    "Event_startDate": "Début",
    "Event_endDate": "Fin",
    "Event_date_place": "Date et lieu de l'évènement",
    "Event_from": "Le",
    "Event_to": "au",
    "noFloorSelect": "Aucun étage sélectionné",

    // NEWS V2
    "news.read.article": "Lire l'article",
    "news.spotlight": "À la une",
    "news.all.article": "Consulter tous les articles",
    "news.event.title": "Date et lieu de l'évènement",
    "news.event.agence": "Agence de",
    "news.article.like": "J'aime cet article",
    "news.article.share": "Partager cet article",
    "news.article.same": "Articles similaires",
    "news.comments": "Commentaires",
    "news.comment.write": "Ecrivez votre commentaire ici",
    "news.comment.send": "Envoyer",
    "news.editor.confirm.title": "Supprimer la news",
    "news.editor.cnfirm.content": "Êtes-vous sûr de vouloir supprimer la news",
    "news.editor.new": "Nouvelle publication",
    "news.editor.new.topic": "Gérer les topics",
    "news.edior.all.topics": "Tous les topics",
    "news.editor.published.topics": " Publié dans",
    "news.editor.update": "Modifier",
    "news.editor.hide": "Masquer",
    "news.editor.noHide": "Démasquer",
    "news.editor.delete": "Supprimer",
    "news.editor.publish": "Publier",
    "news.editor.hide.title": "Masquer la publication",
    "news.editor.noHide.title": "Démasquer la publication",
    "news.editor.hide.content": "Cette publication sera masquée et ne sera plus visible par les utilisateurs. Son contenu ne sera pas supprimé.",
    "news.editor.noHide.content": "Cette publication sera démasquée et visible par les utilisateurs",
    "news.editor.hide.success": "News masquée",
    "news.editor.noHide.success": "News démasquée",
    "news.editor.hide.error": "La news n'a pas été caché",
    "news.editor.topics.create.success": "Création du topic réussie",
    "news.editor.topics.create.error": "Echec creation topic",
    "news.editor.topics.update.success": "Modification du topic réussie",
    "news.editor.topics.update.error": "Echec modification topic",
    "news.editor.topics.delete": "Topic supprimé",
    "news.editor.topics.use": "Déjà utilisé",
    "news.editor.topics.add": "Ajouter un topic",
    "news.editor.topics.new": "Nouveau topic",
    "news.editor.topics.update": "Modifier le topic",
    "news.editor.topics.create": "Créer le topic",
    "news.editor.topics.list": "Liste des topics",
    "news.editor.topics.name": "Nom du topic",
    "news.editor.update.success": "Modification réussie",
    "news.editor.update.error": "Echec des modifications",
    "news.editor.create.success": "Publication réussie",
    "news.editor.create.error": "Echec de la publication",
    "news.editor.create.error.pictures": "Veuillez ajouter une image d'en tête",
    "news.editor.web": "Web",
    "news.editor.mobile": "Mobile",
    "news.editor.title": "Cliquez pour ajouter un titre",
    "news.editor.subtitle": "Cliquez pour ajouter un sous-titre",
    "news.editor.header": "Cliquez pour ajouter une image d’entête",
    "news.editor.content": "Cliquez pour saisir le corps de la publication",
    "news.editor.options.languages": "Sélectionnez la langue et éditez le contenu de cette publication sur la partie gauche de l’écran.",
    "news.editor.options.topics": "Sélectionnez un ou plusieurs topics dans lesquels ajouter la publication.",
    "news.editor.options.notification.title": "Envoyer une notification",
    "news.editor.options.notification.content": "Sélectionnez cette option pour que les utilisateurs recoivent une notification au moment de la mise en ligne de cette publication.",
    "news.editor.options.publication.title": "Programmer la publication",
    "news.editor.options.publication.content": "Sélectionnez cette option et indiquez la date et l’heure à laquelle cette publication sera mise en ligne automatiquement.",
    "news.editor.options.hide.title": "Masquer automatiquement",
    "news.editor.options.hide.content": "Sélectionnez cette option et indiquez la date et l’heure à laquelle cette publication sera masquée aux utilisateurs.",
    "news.editor.options.event.title": "Ajouter un événement",
    "news.editor.options.event.placeholder": "Ajouter un nom à l'événement",
    "news.editor.options.event.content": "S’il s’agit d’un événement, vous pouvez ajouter un lieu à votre publication.",
    "news.editor.options.event.name": "Nom de l'événement",
    "news.editor.options.event.start": "Début de l'événement",
    "news.editor.options.event.end": "Fin de l'événement",
    "news.editor.options.event.localisation": "Localisation",
    "news.editor.options.event.building": "Bâtiment",
    "news.editor.options.event.outBuilding": "Hors bâtiment",
    "news.editor.options.event.findAdress": "Rechercher une adresse",
    "news.editor.options.groups.title": "Limiter la visibilité",
    "news.editor.options.groups.content": "Pour limiter la visibilité de cette publication, sélectionnez les groupes d’utilisateurs autorisés à la consulter. (Par défaut, la publication sera visible par tous les groupes).",
    "news.editor.options.groups.select": "Sélectionnez un groupe",
    "news.editor.options.comments.title": "Autoriser les commentaires",
    "news.editor.options.comments.content": "Sélectionnez cette option pour autoriser les utilisateurs à ajouter un commentaire à la publication.",
    "news.editor.iframe.title": "Integration HTML",
    "news.editor.iframe.content": "Copiez le code HTML du site que vous voulez intégrer.",
    "news.editor.iframe.placeholder": "Insérez le code HTML à intégrer ici",
    "news.editor.library.import.error": "erreur lors de l'ajout d'une image",
    "news.editor.library.title": "Inserer une image",
    "news.editor.library.validation": "Sélectionner cette image",
    "news.editor.library.content": "Sélectionnez une image existante dans la bibliothèque ci-dessous ou importez une nouvelle image depuis votre ordinateur.",
    "news.editor.library.format": "Formats pris en charge : png, jpeg Dimensions recommandées pour le header : 1220x450",
    "news.editor.library.import": "Importer",
    "news.editor.pictures.title": "Intégrer une balise image",
    "news.editor.library.import.modal.title": "Confirmer l'import ?",
    "news.editor.library.import.modal.p1": "L’import de votre image va commencer.",
    "news.editor.library.import.modal.p2": "Elle sera compressée et disponible dans quelques instants.",
    "news.editor.library.import.success": "Import réussi",
    "news.details.start.event": "À partir du",
    "news.details.end.event": "jusqu'au",
    "news.detail.noNews": "Aucune publication pour le moment, revenez plus tard",
    "news.editor.find": "Rechercher une news",
    "news.editor.library.search": "Rechercher une image",
    "news.editor.NewsDeleted": "News supprimée",
    "news.editor.BackToTheList": "Retour à la liste des news",
    "news.editor.success": "Réussi",

    // TRANSPORT
    "transport.title": "Transports en commun",
    "transport.subtitle": "Sélectionnez un arrêt",
    "transport.segmented.allStop": "Tous les arrêts",
    "transport.segmented.fav": "Mes favoris",
    "transport_no_favorite": "Parcourez les arrêts et ajoutez des lignes en favoris pour les retrouver ici.",
    "select_a_line": "Sélectionnez une ligne",
    "go_to": "Y aller",
    "traffic_info": "Info trafic",
    "all_lines": "Toutes les lignes",
    "favorite": "Favori",
    "next_departures": "Prochains départs",
    "no_next_departures": "Aucun prochain départ",
    "navitia_data": "Données fournies par Navitia",
    "transport_no_alerts": "Tout va bien, aucune alerte pour le moment.",
    "my_latest_research": "Mes dernières recherches",
    "itinerary_sub": "Indiquez un point de départ et d'arrivée",
    "walking": "à pied",
    "departure_from": "Départ de",
    "walk_to": "Marcher jusqu'à",
    "stop": "arrêts",

    // QRCODE
    "qrcode.title": "Faites en plus avec l'application mobile {{app}}",
    "qrcode.content": "Scannez ce QR Code pour télécharger l’application",
    // AROUNDME
    "aroundMe.select.categorie": "Sélectionnez une catégorie",
    "phone_number": "Téléphone",
    "web_site": "Site web",
    "around_me_search": "Rechercher",
    "restaurants": "Restaurants",
    "bars": "Bars",
    "hotels": "Hôtels",
    "banks": "Banques",
    "parking": "Parking",
    "other_place": "Autre lieu",
    "pharmacy": "Pharmacie",
    "bakery": "Boulangerie",
    "shopping": "Commerces",
    "meeting_room": "Salle de réunion",
    "restaurants_sing": "Restaurant",
    "bars_sing": "Bar",
    "hotels_sing": "Hôtel",
    "banks_sing": "Banque",
    "parking_sing": "Parking",
    "other_place_sing": "Autre lieu",
    "pharmacy_sing": "Pharmacie",
    "bakery_sing": "Boulangerie",
    "shopping_sing": "Commerces",
    "meeting_room_sing": "Salle de réunion",
    "outstanding_locations": "Outstanding locations",
    "bars_restaurants": "Bars & Restaurants",
    "walks": "Walks",
    "services": "Services",

    // INSTANT MESSAGING
    "MESSAGE_STATUS__YESTERDAY": "Hier",
    "CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE": "1 nouveau message",
    "CHANNEL__MESSAGE_LIST__NOTIFICATION__ON": "Activer les notifications",
    "CHANNEL_PREVIEW_MOBILE_LEAVE": "Quitter le canal",
    "CHANNEL_SETTING__HEADER__TITLE": "Information sur le canal",
    "CHANNEL_SETTING__PROFILE__EDIT": "Modifier",
    "CHANNEL_SETTING__MEMBERS__TITLE": "Membres",
    "CHANNEL_SETTING__MEMBER__REMOVE": "Retirer du groupe",
    "CHANNEL_SETTING__MEMBER__TITLE": "Membre",
    "CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS": "Rechercher",
    "CHANNEL_SETTING__MEMBERS__INVITE_MEMBER": "Inviter des membres",
    "CHANNEL_SETTING__MEMBERS__YOU": "(Vous)",
    "CHANNEL_SETTING__MEMBERS__SELECT_TITLE": "Sélectionner des membres",
    "CHANNEL_SETTING__MEMBERS__OPERATOR": "Admin",
    "CHANNEL_SETTING__LEAVE_CHANNEL__TITLE": "Quitter le canal",
    "CHANNEL_SETTING__OPERATORS__TITLE": "Modérateurs",
    //"CHANNEL_SETTING__OPERATORS__TITLE_ALL": "TranslationKey001",
    "CHANNEL_SETTING__OPERATORS__TITLE_ADD": "Nommer modérateur",
    "CHANNEL_SETTING__OPERATORS__ADD_BUTTON": "Ajouter",
    "CHANNEL_SETTING__MUTED_MEMBERS__TITLE": "Admin",
    //"CHANNEL_SETTING__MUTED_MEMBERS__TITLE_ALL": "TranslationKey002",
    //"CHANNEL_SETTING__NO_UNMUTED": "Sélectionné4",
    //"CHANNEL_SETTING__BANNED_MEMBERS__TITLE": "Sélectionné5",
    //"CHANNEL_SETTING__FREEZE_CHANNEL": "Admin",
    "CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR": "Donner le rôle d'administrateur",
    "CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR": "Enlever le rôle d'administrateur",
    "CHANNEL_SETTING__MODERATION__MUTE": "Mettre en sourdine",
    "CHANNEL_SETTING__MODERATION__UNMUTE": "Enlever la sourdine",
    //"CHANNEL_SETTING__MODERATION__BAN": "Bannir",
    //"//CHANNEL_SETTING__MODERATION__UNBAN": "Débannir",
    //"CHANNEL_SETTING__MODERATION__EMPTY_BAN": "Sélectionné6",
    //"CHANNEL_SETTING__MODERATION__ALL_BAN": "Sélectionné7",
    //"OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS": "Sélectionné8",
    //"OPEN_CHANNEL_CONVERSATION__SELECT_PARTICIPANTS": "Sélectionné9",
    //"OPEN_CHANNEL_LIST__TITLE": "Modifier le nom",
    "CREATE_OPEN_CHANNEL_LIST__TITLE": "Créer",
    "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION": "Modifier l'image du canal",
    "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_UPLOAD": "Créer",
    "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_SECTION": "Saisir le nom",
    "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_PLACE_HOLDER": "Saisir le nom",
    "CREATE_OPEN_CHANNEL_LIST__SUBMIT": "Créer",
    "OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE": "Admin",
    //"OPEN_CHANNEL_SETTINGS__OPERATOR_URL": "Sélectionné10",
    //"OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE": "Sélectionné11",
    "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL": "Supprimer le canal",
    "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE": "Supprimer le message ?",
    "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_CONTEXT": "Supprimer le message ?",
    "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT": "Supprimer",
    "OPEN_CHANNEL_SETTINGS__OPERATORS_TITLE": "Admin",
    "OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ADD": "Ajouter",
    //"OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ALL": "Sélectionné12",
    //"OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE": "Admin",
    //"OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE_ALL": "Sélectionné13",
    //"OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__NO_ONE": "Sélectionné14",
    //"OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE": "Sélectionné15",
    //"OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE_ALL": "Sélectionné16",
    //"OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__NO_ONE": "Sélectionné17",
    //"OPEN_CHANNEL_SETTINGS__MEMBERS__YOU": "(Vous)",
    //"OPEN_CHANNEL_SETTINGS__MEMBERS__OPERATOR": "Admin",
    //"OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE": "Sélectionné18",
    //"OPEN_CHANNEL_SETTINGS__EMPTY_LIST": "Supprimer le canal",
    //"OPEN_CHANNEL_SETTINGS__SEE_ALL": "Rechercher",
    //"OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE": "Sélectionné19",
    //"OPEN_CHANNEL_SETTINGS__NO_TITLE": "Saisir le nom",
    //"OPEN_CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR": "Ajouter",
    //"OPEN_CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR": "Supprimer",
    //"OPEN_CHANNEL_SETTING__MODERATION__MUTE": "Mettre en sourdine",
    //"OPEN_CHANNEL_SETTING__MODERATION__UNMUTE": "Enlever la sourdine",
    //"OPEN_CHANNEL_SETTING__MODERATION__BAN": "Bannir",
    //"OPEN_CHANNEL_SETTING__MODERATION__UNBAN": "Débannir",
    "TRYING_TO_CONNECT": "Tentative de connexion...",
    "TYPING_INDICATOR__IS_TYPING": "écrit...",
    "TYPING_INDICATOR__AND": "et",
    "TYPING_INDICATOR__ARE_TYPING": "écrivent...",
    "TYPING_INDICATOR__MULTIPLE_TYPING": "plusieurs personnes écrivent...",
    "CHANNEL_FROZEN": "Canal gelé",
    "PLACE_HOLDER__NO_CHANNEL": "Aucun canal",
    "PLACE_HOLDER__WRONG": "Erreur lors de la récupération de la liste des canaux",
    "PLACE_HOLDER__RETRY_TO_CONNECT": "Réessayer de se connecter",
    "PLACE_HOLDER__NO_MESSAGES": "Aucun message dans ce canal",
    "TOOLTIP__AND_YOU": "et vous",
    "TOOLTIP__YOU": "vous",
    "TOOLTIP__UNKNOWN_USER": "Utilisateur inconnu",
    "UNKNOWN__UNKNOWN_MESSAGE_TYPE": "Type de message inconnu",
    "UNKNOWN__CANNOT_READ_MESSAGE": "Impossible de lire le message",
    "MESSAGE_EDITED": "Message modifié",
    "MODAL__DELETE_MESSAGE__TITLE": "Supprimer le message ?",
    "MODAL__CHANNEL_INFORMATION__TITLE": "Informations sur le canal",
    "MODAL__CHANNEL_INFORMATION__CHANNEL_IMAGE": "Modifier l'image du canal",
    "MODAL__CHANNEL_INFORMATION__UPLOAD": "Charger une image",
    "MODAL__CHANNEL_INFORMATION__CHANNEL_NAME": "Saisir le nom",
    "MODAL__CHANNEL_INFORMATION__INPUT__PLACE_HOLDER": "Saisir le nom",
    "MODAL__INVITE_MEMBER__TITLE": "Inviter des membres",
    "MODAL__INVITE_NO_MEMBER_SELECTED": "Aucun membre sélectionné",
    "MODAL__INVITE_SEARCH_MEMBER": "Rechercher un membre",
    "MODAL__INVITE_MEMBER__SELECTED": "Sélectionné",
    "MODAL__CHOOSE_CHANNEL_TYPE__TITLE": "Sélectionner le type de canal",
    "MODAL__CHOOSE_CHANNEL_TYPE__GROUP": "Groupe",
    "MODAL__CHOOSE_CHANNEL_TYPE__SUPER_GROUP": "Supergroupe",
    "MODAL__CHOOSE_CHANNEL_TYPE__BROADCAST": "Broadcast",
    "MODAL__CREATE_CHANNEL__TITLE": "Créer un canal",
    "MODAL__CREATE_CHANNEL__GROUP": "Groupe",
    "MODAL__CREATE_CHANNEL__SUPER": "Supergroupe",
    "MODAL__CREATE_CHANNEL__BROADCAST": "Broadcast",
    "MODAL__CREATE_CHANNEL__SELECTED": "Sélectionné",
    "MODAL__LEAVE_CHANNEL__TITLE": "Quitter le canal",
    "MODAL__LEAVE_CHANNEL__FOOTER": "Confirmer",
    "MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_MUTED": "Vous êtes en sourdine",
    "MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_FROZEN": "Le chat est indisponible sur ce canal",
    "USER_PROFILE__MESSAGE": "Message",
    "USER_PROFILE__USER_ID": "ID utilisateur",
    "EDIT_PROFILE__TITLE": "Modifier le profil",
    "EDIT_PROFILE__IMAGE_LABEL": "Modifier l'image",
    "EDIT_PROFILE__IMAGE_UPLOAD": "Modifier",
    "EDIT_PROFILE__NICKNAME_LABEL": "Pseudo",
    "EDIT_PROFILE__NICKNAME_PLACEHOLDER": "Saisir un pseudo",
    "EDIT_PROFILE__USERID_LABEL": "ID utilisateur",
    "EDIT_PROFILE__THEME_LABEL": "Thème",
    "MESSAGE_INPUT__PLACE_HOLDER": "Entrez votre message",
    "MESSAGE_INPUT__PLACE_HOLDER__DISABLED": "Le chat est indisponible sur ce canal",
    "MESSAGE_INPUT__PLACE_HOLDER__MUTED": "Vous êtes en sourdine",
    "MESSAGE_INPUT__PLACE_HOLDER__MUTED_SHORT": "Vous êtes en sourdine",
    "MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER": "Répondre au message",
    "BUTTON__CANCEL": "Annuler",
    "BUTTON__DELETE": "Supprimer",
    "BUTTON__SAVE": "Sauvegarder",
    "BUTTON__CREATE": "Créer",
    "BUTTON__INVITE": "Inviter",
    "BUTTON__OK": "OK",
    "BADGE__OVER": "plus de",
    "NO_TITLE": "Pas de nom",
    "NO_NAME": "Pas de nom",
    "NO_MEMBERS": "Aucun membre",
    "MESSAGE_MENU__COPY": "Copier",
    "MESSAGE_MENU__REPLY": "Répondre",
    "MESSAGE_MENU__THREAD": "Répondre au message",
    "MESSAGE_MENU__OPEN_IN_CHANNEL": "Ouvrir dans le canal",
    "MESSAGE_MENU__EDIT": "Modifier",
    "MESSAGE_MENU__RESEND": "Réessayer",
    "MESSAGE_MENU__DELETE": "Supprimer",
    "MESSAGE_MENU__SAVE": "Sauvegarder",
    "SEARCH": "Rechercher",
    "SEARCH_IN_CHANNEL": "Rechercher dans le canal",
    "SEARCH_IN": "Rechercher dans",
    "SEARCHING": "Recherche en cours...",
    "NO_SEARCHED_MESSAGE": "Aucun résultat",
    "QUOTE_MESSAGE_INPUT__REPLY_TO": "Répondre à",
    "QUOTE_MESSAGE_INPUT__FILE_TYPE_IMAGE": "Image",
    "QUOTE_MESSAGE_INPUT__FILE_TYPE_GIF": "GIF",
    "QUOTE_MESSAGE_INPUT__FILE_TYPE__VIDEO": "Vidéo",
    "QUOTED_MESSAGE__REPLIED_TO": "a répondu à",
    "QUOTED_MESSAGE__CURRENT_USER": "(Vous)",
    "QUOTED_MESSAGE__UNAVAILABLE": "Message non disponible",
    "THREAD__HEADER_TITLE": "Réponses",
    "CHANNEL__THREAD_REPLY": "Répondre",
    "CHANNEL__THREAD_REPLIES": "Réponses",
    "CHANNEL__THREAD_OVER_MAX": "plus de réponses",
    "THREAD__THREAD_REPLY": "Répondre",
    "THREAD__THREAD_REPLIES": "Réponses",
    "THREAD__INPUT__REPLY_TO_THREAD": "Répondre au fil",
    "THREAD__INPUT__REPLY_IN_THREAD": "Répondre dans le fil",
    "MENTION_NAME__NO_NAME": "Utilisateur inconnu",
    "MENTION_COUNT__OVER_LIMIT": "Vous pouvez mentionner jusqu'à %d personnes maximum par message",
    "UI__FILE_VIEWER__UNSUPPORT": "Type de fichier non pris en charge",
    "VOICE_MESSAGE": "Message vocal",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GIF": "GIF",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_PHOTO": "Image",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VIDEO": "Vidéo",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_AUDIO": "Audio",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VOICE_MESSAGE": "Message vocal",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GENERAL": "Fichier",
    "instantMessaging": "Messagerie instantanée",
    "tokenError": "Erreur lors de la récupération du token",
    "CHANNEL_SETTING__MEMBER__PROFIL": "Afficher le profil",
    "SHOW_MORE_CONTACTS": "Afficher plus de contacts",
    "FILE_TYPE_NOT_ALLOWED": "Erreur : Type de fichier non autorisé",
    "FILE_TOO_BIG": "Erreur : Fichier trop volumineux",
    "MESSAGE_UNREAD": "non lu",

    // CONTACTLIST
    "contactList.inputPlaceholder": "Rechercher un contact dans la liste",
    "contactList.noResult0": "Aucun résultat pour cette recherche dans cette liste.",
    "contactList.noResult1": "Vérifier l'orthographe ou essayez votre recherche dans une autre liste.",
    "contactList.lists": "Listes",
    "contactList.all": "All",
    "contactList.loading": "Chargement  ...",
    "contactList.noData": "L'utilisateur n'a pas renseigné cette section",
    "contactList.segmentedContact": "Contact",
    "contactList.segmentedAboutMe": "À propos",
    "contactList.favorite": "Favoris",
    "contactList.onSite": "Sur site",
    "contactList.showMore": "Afficher plus ...",
    "contactList.biography": "Biographie :",
    "contactList.hobbies": "Centres d'interêts :",
    "contactList.save": "Enregistrer",
    "contactList.addHobby": "Ajouter un centre d'interêt",
    "contactList.bioPlaceholder": "Saisissez une courte biographie",
    "contactList.myProfile": "Mon profil",
    "contactList.unknownUser": "Utilisateur inconnu",
    "contactList.newChat": "Nouvelle conversation",
    "contactList.favoriteAdd": "Ajouter aux favoris",
    "contactList.favoriteRemove": "Retirer des favoris",

    // PARKING
    "parking.MyBookings": "Mes réservations",
    "parking.BookingHistory": "Historique des réservations",
    "parking.NewBooking": "Nouvelle réservation",
    "parking.ManageMyVehicles": "Gérer mes véhicules",
    "parking.MyBooking": "Ma réservation",
    "parking.Today": "Aujourd'hui",
    "parking.Day": "Journée",
    "parking.Am": "Matin",
    "parking.Pm": "Après-midi",
    "parking.ModifyMyBooking": "Modifier ma réservation",
    "parking.CancelMyBooking": "Annuler ma réservation",
    "parking.NoBookingToday": "Vous n'avez effectué aucune réservation",
    "parking.MakeABookingToAccessList": "Veuillez effectuer une réservation pour accéder à votre liste.",
    "parking.NoVehicleRegistered": "Vous n’avez renseigné aucun véhicule",
    "parking.AddVehicleToMakeBooking": "Veuillez ajouter un véhicule pour faire une réservation.",
    "parking.AddVehicle": "Ajouter un véhicule",
    "parking.NextBooking": "Réservation à venir",
    "parking.PreviousBooking": "Réservation passée",
    "parking.manageVehicles": "Gérer mes véhicules",
    "parking.RegistredVehiclesSummary": "Récapitulatif des véhicules enregistrés",
    "parking.SelectAVehicleType": "Sélectionnez un type de véhicule",
    "parking.WhatTypeOfVehicle": "Quel type de véhicule souhaitez-vous enregistrer ?",
    "parking.Car": "Voiture",
    "parking.Bike": "Vélo",
    "parking.Moto": "Moto",
    "parking.ElectricCar": "Voiture électrique",
    "parking.Back": "Retour",
    "parking.AddNameToYourVehicle": "Ajoutez un nom à votre véhicule",
    "parking.WhatNameOfVehicle": "Quel nom souhaitez-vous donner à votre véhicule ?",
    "parking.VehicleName": "Nom du véhicule",
    "parking.AddPlate": "Ajoutez la plaque d'immatriculation de votre véhicule",
    "parking.WhatIsYourVehiclePlate": "Quelle est la plaque d'immatriculation de votre véhicule ?",
    "parking.PlatePlaceholder": "Plaque d'immatriculation",
    "parking.All": "Toutes",
    "parking.Date": "Date",
    "parking.Zone": "Zone",
    "parking.Duration": "Durée",
    "parking.UpdateVehicle": "Modifier le véhicule",
    "parking.ParkingBooking": "Réservation d’une place de parking",
    "parking.SelectDate": "Sélectionnez une date",
    "parking.SelectZone": "Sélectionnez une zone",
    "parking.Confirmation": "Confirmation",
    "parking.SelectDateAndDurationBeforeZone": "Sélectionnez une date et durée avant de sélectionner une zone",
    "parking.Continue": "Continuer",
    "parking.DeleteVehicle": "Supprimer le véhicule ?",
    "parking.Yes": "Oui",
    "parking.No": "Non",
    "parking.SelectDuration": "Sélectionnez une durée",
    "parking.CancelBooking": "Annuler la réservation",
    "parking.DoYouWantToCancelBookingOn_": "Voulez-vous annuler la réservation du ",
    "parking.ThisActionIsIrreversible": "Cette action est irréversible",
    "parking.NoBookings": "Vous n’avez effectué aucune réservation pour le moment.",
    "parking.NoZonesAvailable": "Aucune zone disponible pour cette date.",
    "parking.Book": "Réserver",
    "parking.Finish": "Terminer",
    "parking.BookingRecap": "Récapitulatif de votre réservation",
    "parking.Modify": "Modifier",
    "parking.Cancel": "Annuler",
    "parking.ModifyBooking": "Modifier la réservation",
    "parking.BookingDate": "Réserver le",
    "parking.Delete": "Supprimer",
    "parking.30LastDays": "30 derniers jours",
    "parking.DoYouWantToCancelBooking": "Voulez-vous annuler la réservation ?",
    "parking.Confirm": "Confirmer",
    "parking.SelectSite": "Sur quel site souhaitez-vous réserver une place de parking?",
    "parking.Site": "Site",
    "parking.BookingCreatedWithSuccess": "Réservation effectuée avec succès",
    "parking.BookingUpdatedWithSuccess": "Réservation modifiée avec succès",
    "parking.BookingCanceledWithSuccess": "Réservation annulée avec succès",
    "parking.BookingError": "Erreur lors de la réservation",
    "parking.UpdateBookingError": "Erreur lors de la modification de la réservation",
    "parking.CancelBookingError": "Erreur lors de l'annulation de la réservation",
    "parking.VehicleAddedWithSuccess": "Véhicule ajouté avec succès",
    "parking.VehicleUpdatedWithSuccess": "Véhicule modifié avec succès",
    "parking.VehicleDeletedWithSuccess": "Véhicule supprimé avec succès",
    "parking.VehicleError": "Erreur lors de l'ajout du véhicule",
    "parking.UpdateVehicleError": "Erreur lors de la modification du véhicule",
    "parking.DeleteVehicleError": "Erreur lors de la suppression du véhicule",
    "parking.ThereAreBookingsOnThisPeriod": "Il y a déjà une réservation sur cette période",
    "parking.NoOtherZonesAvailable": "Aucune autre zone disponible pour cette date",

    // TICKETING V2
    "ticketingv2.referential": "Référentiel",
    "ticketingv2.referentialComment": "Naviguez dans l’arborescence des tickets pour consulter et modifier les types et catégories de tickets disponibles à la déclaration.",
    "ticketingv2.save": "Enregistrer",
    "ticketingv2.referentialManagement": "Gestion du référentiel",
    "ticketingv2.referentialManagementComment": "Ce portail de gestion du ticketing permet de modifier les types et catégories de tickets disponibles à la déclaration et d’y affecter des droits.",
    "ticketingv2.siteSelector": "Sélectionnez un site",
    "ticketingv2.ticketTypes": "Types de ticket",
    "ticketingv2.ticketTypeComment": "Modifier le référentiel de tickets disponibles à la déclaration.",
    "ticketingv2.Modify": "Modifier",
    "ticketingv2.theReferential": "le reférentiel",
    "ticketingv2.availableStatus": "Statuts disponibles",
    "ticketingv2.availableStatusComment": "Modifier ou ajouter des statuts disponibles.",
    "ticketingv2.defaultStatus": "Statuts par défaut",
    "ticketingv2.customStatus": "Statuts personnalisés",
    "ticketingv2.addStatus": "Ajouter un statut",
    "ticketingv2.Delete": "Supprimer",
    "ticketingv2.changesNotSaved": "Les modifications n'ont pas été enregistrées",
    "ticketingv2.changeRefConfirm": "Êtes-vous sûr de vouloir changer de réferenciel?",
    "ticketingv2.newSubCat": "Nouvelle sous-catégorie",
    "ticketingv2.newCat": "Nouvelle catégorie",
    "ticketingv2.newType": "Nouveau type",
    "ticketingv2.emptyRef": "Le référentiel est vide",
    "ticketingv2.Type": "Type",
    "ticketingv2.Cat": "Catégorie",
    "ticketingv2.SubCat": "Sous-catégorie",
    "ticketingv2.subCat": "sous-catégorie",
    "ticketingv2.subCats": "sous-catégories",
    "ticketingv2.aType": "un type",
    "ticketingv2.aCat": "une catégorie",
    "ticketingv2.aSubCat": "une sous-catégorie",
    "ticketingv2.aStatus": "un status",
    "ticketingv2.CantDeleteRefWithTickets": "Impossible de supprimer un referentiel possédant des tickets.",
    "ticketingv2.Desactivate": "Désactiver",
    "ticketingv2.Activate": "Activer",
    "ticketingv2.CantUseNoCat": "Ce type ne peut pas être utilisé car il ne comporte aucune catégorie",
    "ticketingv2.TypeInvisible": "Ce type ne sera pas visible par : ",
    "ticketingv2.and": " et ",
    "ticketingv2.users": "utilisateurs",
    "ticketingv2.Users": "Utilisateurs",
    "ticketingv2.andIcon": " et icône",
    "ticketingv2.Name": "Nom",
    "ticketingv2.ExistingForm": "Il existe un formulaire sur ",
    "ticketingv2.ToAddaFormOnCat": "Pour ajouter un formulaire sur la catégorie « ",
    "ticketingv2.youMustDeleteForm": " », vous devez supprimer le formulaire",
    "ticketingv2.fromSubCat": " de la sous-catégorie « ",
    "ticketingv2.FormExistOnParentCat": "Il existe un formulaire sur la catégorie supérieure « ",
    "ticketingv2.ToAddFormOnSubCat": "Pour ajouter un formulaire sur la sous-catégorie « ",
    "ticketingv2.youMustDeleteFormOnParentCat": " », vous devez supprimer le formulaire associé à la catégorie supérieure « ",
    "ticketingv2.FormExplanationStart": "Vous pouvez créer un formulaire qui sera proposé à vos utilisateurs lorsqu’ils déclareront un ticket dans : ",
    "ticketingv2.FormExplanationEnd": "Un formulaire vous permettra de demander des informations complémentaires à vos utilisateurs pour vous aider dans la résolution de leur ticket.",
    "ticketingv2.CreateForm": "Créer un formulaire",
    "ticketingv2.Params": "Paramètres",
    "ticketingv2.Form": "Formulaire",
    "ticketingv2.AddFormPart": "Ajoutez une section au formulaire",
    "ticketingv2.AddFormPartComment": "Ajoutez une section de votre choix au formulaire",
    "ticketingv2.DeleteForm": "Supprimer le formulaire",
    "ticketingv2.TypeName": "Nom du type",
    "ticketingv2.CatName": "Nom de la catégorie",
    "ticketingv2.SubCatName": "Nom de la sous-catégorie",
    "ticketingv2.StatusName": "Nom du statut",
    "ticketingv2.Add_": "Ajouter ",
    "ticketingv2.NameAndIcon": "Nom et icône",
    "ticketingv2.Cancel": "Annuler",
    "ticketingv2.Continue": "Continuer",
    "ticketingv2.AddStatus": "Ajouter un statut",
    "ticketingv2.IsClosingState": "Correspond à un état de fermeture",
    "ticketingv2.Loading": "Chargement...",
    "ticketingv2.WarningIrreversibleAction": "Attention, cette action est irreversible",
    "ticketingv2.UserImportFileHere": "L’utilisateur importera un document ici",
    "ticketingv2.UserImportPhotoHere": "L’utilisateur importera une image ici",
    "ticketingv2.ImportFileHere": "Importer un document",
    "ticketingv2.ImportPhotoHere": "Importer une image",
    "ticketingv2.FileSizeAndTypeLimitation": "Taille Maxi : 100M, Formats pris en charge : png, jpeg, mp4, wav",
    "ticketingv2.UserTypeAnswer": "L’utilisateur saisira sa réponse ici",
    "ticketingv2.IsANumber": "Est un nombre",
    "ticketingv2.AddOption": "Ajouter une option",
    "ticketingv2.OrderMustBe_": "La commande peut être passée ",
    "ticketingv2._hoursBeforeDelivery": " heures avant la livraison.",
    "ticketingv2.OrderCanBeCanceled_": "La commande peut être annulée jusqu'à ",
    "ticketingv2._hours_": " heures ",
    "ticketingv2.WeekendsAndHolidays_": "Les week-ends et jours fériés",
    "ticketingv2._countInDelays": "comptabilisés dans ces délais.",
    "ticketingv2.AskTime": "Demander l’heure",
    "ticketingv2.IsAProduct": "Il s’agit d’un produit",
    "ticketingv2.Price": "Prix",
    "ticketingv2.SetAUnitPrice": "Saisir un prix unitaire",
    "ticketingv2.AddProductCode": "Ajouter un code produit",
    "ticketingv2.AllTypes": "Tous les types",
    "ticketingv2.FormPartCaption": "Texte secondaire",
    "ticketingv2.Currency": "Devise",
    "ticketingv2.ProductKey": "Clé ou code produit",
    "ticketingv2.ProductName": "Nom du produit",
    "ticketingv2.Description": "Description",
    "ticketingv2.AddDescription": "Ajouter une description",
    "ticketingv2.AddProductName": "Ajouter un nom de produit",
    "ticketingv2.Gbp": "Livres",
    "ticketingv2.are_": " sont ",
    "ticketingv2.areNot_": " ne sont pas ",
    "ticketingv2.beforeDelivery": "avant la livraison.",
    "ticketingv2.afterOrdering": "après la commande.",
    "ticketingv2.Previous": "Précédent",
    "ticketingv2.Next": "Suivant",
    "ticketingv2.CreateTicket": "Créer le ticket",
    "ticketingv2.ModifyTicket": "Modifier le ticket",
    "ticketingv2.Localization": "Localisation",
    "ticketingv2.Categorization": "Catégorisation",
    "ticketingv2.MyRequestSubject": "Ma demande concerne",
    "ticketingv2.ChangeTypeResetForm": "Changer le type réinitialisera le formulaire",
    "ticketingv2.ChangeCatResetForm": "Changer la catégorie réinitialisera le formulaire",
    "ticketingv2.ChangeSubCatResetForm": "Changer la sous-catégorie réinitialisera le formulaire",
    "ticketingv2.ticketTitle": "Titre du ticket",
    "ticketingv2.AllSubCats": "Toutes les sous-catégories",
    "ticketingv2.AllCats": "Toutes les catégories",
    "ticketingv2.AddTitle": "Ajouter un titre",
    "ticketingv2.Asc": "Le plus récent",
    "ticketingv2.Desc": "Le plus ancien",
    "ticketingv2.Date": "Date",
    "ticketingv2.RequalifyStatus": "Requalifier les tickets sur ce statut",
    "ticketingv2.NameAndColor": "Nom et couleur",
    "ticketingv2.EvaluateTicketResolution": "Évaluer la résolution du ticket",
    "ticketingv2.Back": "Retour",
    "ticketingv2.ValidateEvaluation": "Valider mon évaluation",
    "ticketingv2.GlobalRating": "Note globale",
    "ticketingv2.Comment": "Commentaire",
    "ticketingv2.TypeComment": "Saisir un commentaire",
    "ticketingv2.SetTicketStatusToCancelled": "Passer le statut du ticket à Annulé ?",
    "ticketingv2.Confirm": "Confirmer",
    "ticketingv2.AllFilters": "Tous les filtres",
    "ticketingv2.Status": "Statut",
    "ticketingv2.AllStatus": "Tous les statuts",
    "ticketingv2.Priority": "Priorité",
    "ticketingv2.AllPriorities": "Toutes les priorités",
    "ticketingv2.AssignedTo": "Attribué à",
    "ticketingv2.AllAgents": "Tous les agents",
    "ticketingv2.Floor": "Étage",
    "ticketingv2.AllFloors": "Tous les étages",
    "ticketingv2.DeleteAll": "Réinitializer",
    "ticketingv2.ApplyFilters": "Appliquer les filtres",
    "ticketingv2.TicketId_": "ID du ticket: ",
    "ticketingv2.NewTicket": "Nouveau ticket",
    "ticketingv2.MyTickets": "Mes tickets",
    "ticketingv2.MapLoading": "Chargement de la carte...",
    "ticketingv2.UsersGroup": "Groupe d'utilisateurs",
    "ticketingv2.LimitUsersGroups": "Limitez les groupes utilisateurs",
    "ticketingv2.TypeUsersGroupComment": "Ajoutez un ou plusieurs groupes d’utilisateurs pouvant créer un ticket du type sélectionné. Par défaut, tous les utilisateurs sont autorisés.",
    "ticketingv2.CatUsersGroupComment": "Par défaut, la catégorie hérite de la liste de groupe utilisateurs défini pour le type de ticket. En activant cette option, vous pourrez limiter davantage les groupes concernés pour cette catégorie et ses sous-catégories.",
    "ticketingv2.SubCatUsersGroupComment": "Par défaut, la sous-catégorie hérite de la liste de groupe utilisateurs défini pour la catégorie. En activant cette option, vous pourrez limiter davantage les groupes concernés pour cette sous-catégorie.",
    "ticketingv2.SearchAndAddUserGroups": "Rechercher un groupe pour l'ajouter en tant qu'utilisateur",
    "ticketingv2.AgentsGroup": "Groupe d'agents",
    "ticketingv2.LimitAgentsGroups": "Limitez les groupes agents",
    "ticketingv2.TypeAgentsGroupComment": "Ajoutez un ou plusieurs groupes d’agent pouvant intervenir sur les tickets. Par défaut, tous les utilisateurs sont autorisés.",
    "ticketingv2.CatAgentsGroupComment": "Par défaut, la catégorie hérite de la liste de groupe d'agents défini pour le type de ticket. En activant cette option, vous pourrez limiter davantage les groupes concernés pour cette catégorie et ses sous-catégories.",
    "ticketingv2.SubCatAgentsGroupComment": "Par défaut, la sous-catégorie hérite de la liste de groupe d'agents défini pour la catégorie. En activant cette option, vous pourrez limiter davantage les groupes concernés pour cette sous-catégorie.",
    "ticketingv2.SearchAndAddAgentGroups": "Rechercher un groupe pour l'ajouter en tant qu'agent",
    "ticketingv2.ManagersGroup": "Groupe de managers",
    "ticketingv2.LimitManagersGroups": "Limitez les groupes manager",
    "ticketingv2.TypeManagersGroupComment": "Ajoutez un ou plusieurs groupes de manager pouvant attribuer des tickets aux agents. Par défaut, tous les utilisateurs sont autorisés.",
    "ticketingv2.CatManagersGroupComment": "Par défaut, la catégorie hérite de la liste de groupe de manager défini pour le type de ticket. En activant cette option, vous pourrez limiter davantage les groupes concernés pour cette catégorie et ses sous-catégories.",
    "ticketingv2.SubCatManagersGroupComment": "Par défaut, la sous-catégorie hérite de la liste de groupe de manager défini pour la catégorie. En activant cette option, vous pourrez limiter davantage les groupes concernés pour cette sous-catégorie.",
    "ticketingv2.SearchAndAddManagerGroups": "Rechercher un groupe pour l'ajouter en tant que manager",
    "ticketingv2.Spaces": "Espaces concernés",
    "ticketingv2.LimitSpaces": "Limitez les espaces concernés",
    "ticketingv2.TypeSpacesComment": "Sélectionnez les types d’espaces concernés. Par défaut, tous les espaces sont concernés.",
    "ticketingv2.CatSpacesComment": "Par défaut, la catégorie hérite de la liste des espaces défini pour le type de ticket. En activant cette option, vous pourrez limiter davantage les groupes concernés pour cette catégorie et ses sous-catégories.",
    "ticketingv2.SubCatSpacesComment": "Par défaut, la sous-catégorie hérite de la liste des espaces défini pour la catégorie. En activant cette option, vous pourrez limiter davantage les groupes concernés pour cette sous-catégorie.",
    "ticketingv2.SearchAndAddSpace": "Rechercher un espace pour l'ajouter à la liste des limitations",
    "ticketingv2.Pass": "Passer",
    "ticketingv2.TicketEvaluation": "Évaluation du ticket",
    "ticketingv2.NoRequestSubject": "Il n'est pas possible de créer un ticket sur cet espace",
    "ticketingv2.ChooseADate": "Choisissez une date",
    "ticketingv2._andATime": " et une heure",
    "ticketingv2.GeneralInformations": "Informations générales",
    "ticketingv2.TicketCategory": "Catégorie du ticket",
    "ticketingv2.Localisation": "Localisation",
    "ticketingv2.Floor_": "Étage ",
    "ticketingv2.DetailedInformations": "Informations détaillées",
    "ticketingv2.CancelTicket": "Annuler le ticket",
    "ticketingv2.Evaluate": "Évaluer",
    "ticketingv2.Summary": "Résumé",
    "ticketingv2.Conversation": "Conversation",
    "ticketingv2.TicketCreated": "Ticket créé",
    "ticketingv2.TicketPriorityChangedFrom_": "Priorité du ticket changée de ",
    "ticketingv2._to_": " à ",
    "ticketingv2.TicketStatusChangedFrom_": "Statut du ticket changé de ",
    "ticketingv2.TicketQualificationModifiedFrom_": "Qualification du ticket modifiée de ",
    "ticketingv2.TicketHasBeenRated_": "Le ticket a été évalué ",
    "ticketingv2.TicketHasBeenTransferredFrom_": "Le ticket a été transféré de ",
    "ticketingv2.TicketHasBeenAssignedTo_": "Le ticket a été assigné à ",
    "ticketingv2.By_": "Par: ",
    "ticketingv2.You": "Vous",
    "ticketingv2.InternalNote": "Note interne",
    "ticketingv2.TicketIsNowClose": "Le ticket est maintenant fermé",
    "ticketingv2.TicketHasBeenReopenned": "Le ticket a été réouvert",
    "ticketingv2.StartingHour": "De",
    "ticketingv2.EndingHour": "À",
    "ticketingv2.DeliveriesArePossibleFrom_": "Les livraisons sont possibles de ",
    "ticketingv2.Time": "Heure",
    "ticketingv2.NoDateSelected": "Aucune date sélectionnée",
    "ticketingv2.NoTimeSelected": "Aucune heure sélectionnée",
    "ticketingv2.AddFile": "Ajouter un document",
    "ticketingv2.AddPhoto": "Ajouter une photo",
    "ticketingv2.Photos": "Photos",
    "ticketingv2.AddPhotoCaption": "Si nécessaire, sélectionnez jusqu'à trois photos ou vidéos pour illustrer votre ticket.",
    "ticketingv2.Files": "Documents",
    "ticketingv2.FilesCaption": "Si nécessaire, sélectionnez jusqu'à trois documents a associer à votre ticket.",
    "ticketingv2.AssignedToMe": "Qui me sont assignés",
    "ticketingv2.SelectColumnsToDisplay": "Sélectionnez les colonnes à afficher",
    "ticketingv2.TicketDetails": "Détails du ticket",
    "ticketingv2.Creation": "Création",
    "ticketingv2.NotAssigned": "Non assigné",
    "ticketingv2.Categorisation": "Catégorisation",
    "ticketingv2.Reset": "Réinitialiser",
    "ticketingv2.Apply": "Appliquer",
    "ticketingv2.Object": "Objet",
    "ticketingv2.Requester": "Nom du demandeur",
    "ticketingv2.CreatedAt": "Créé le",
    "ticketingv2.NoStatus": "Aucun statut",
    "ticketingv2.NoType": "Aucun type",
    "ticketingv2.NoCat": "Aucune catégorie",
    "ticketingv2.NoSubCat": "-",
    "ticketingv2.NoTitle": "Aucun titre",
    "ticketingv2.NoAgent": "Aucun agent",
    "ticketingv2.NoLocation": "-",
    "ticketingv2.TypeAnswser": "Saisissez une réponse",
    "ticketingv2.ChangeLocation": "Changer la localisation",
    "ticketingv2.ChangeLocationWillResetForm": "Changer la localisation réinitialisera le formulaire",
    "ticketingv2.NoAnswer": "Aucune réponse",
    "ticketingv2.MarkAsResolved": "Marquer comme résolu",
    "ticketingv2.AddCommentSentToUser": "Ajouter un commentaire qui sera envoyé à l'utilisateur",
    "ticketingv2.Optionnal": "Facultatif",
    "ticketingv2.NoPriority": "Aucune",
    "ticketingv2.InvalidFileType": "Type de fichier invalide",
    "ticketingv2.FileTooLarge": "Fichier trop volumineux",
    "ticketingv2.xxx": "xxx",
  },
  csv: trads,
  common: common,
};
