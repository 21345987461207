import { Button, Drawer, Form, Select, Typography } from "antd";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAgents,
  updateTicketByAgent,
  updateTicketByManager,
} from "../../../../services/redux/services/TicketingV2WS";
import priorities from "../Ticket/Component/priorities";
import { useTranslation } from "react-i18next";

const BatchActionDrawer = ({
  showBatchActionDrawer,
  setShowBatchActionDrawer,
  siteId,
  selectedTickets,
  fetchData,
}) => {
  const formRef = createRef();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];

  const referentialManager = useSelector(
    (state) => state.ticketingV2WS.referentialsManager?.referential,
  );
  const referentialAgent = useSelector(
    (state) => state.ticketingV2WS.referentialsAgent?.referential,
  );

  const referential = referentialManager || referentialAgent;

  //const referential = useSelector((state) => state.ticketingV2WS.referential);
  const status = useSelector((state) => state.ticketingV2WS.status);
  const allAgents = useSelector((state) => state.ticketingV2WS.agents);
  const viewId = useSelector((state) => state.ticketingV2WS.currentViewId);

  const [allTypes, setAllTypes] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [page, setPage] = useState(1);
  const [applyModifications, setApplyModifications] = useState(false);
  const [tempData, setTempData] = useState({
    // initialisation avec les valeurs communes des tickets sélectionnés
    ticketIds: selectedTickets.map((t) => t.id),
    type: selectedTickets.every((val, i, arr) => val.type === arr[0].type)
      ? referential?.find((r) => r.label === selectedTickets[0].type)
      : null,
    cat: selectedTickets.every((val, i, arr) => val.cat === arr[0].cat)
      ? referential
          ?.find((r) => r.label === selectedTickets[0].type)
          ?.categories?.find((c) => c.id === selectedTickets[0].cat)
      : null,
    subCategory: selectedTickets.every((val, i, arr) => val.subCategory === arr[0].subCategory)
      ? referential
          ?.find((r) => r.label === selectedTickets[0].type)
          ?.categories?.find((c) => c.id === selectedTickets[0].cat)
          ?.subCategories?.find((s) => s.id === selectedTickets[0].subCategory)
      : null,
    status: selectedTickets.every((val, i, arr) => val.status.id === arr[0].status.id)
      ? selectedTickets[0].status
      : null,
    priorities: selectedTickets.every((val, i, arr) => val.priorities === arr[0].priorities)
      ? selectedTickets[0].priorities
      : null,
    agent: selectedTickets.every((val, i, arr) => val.agent === arr[0].agent)
      ? selectedTickets[0].agent
      : null,
  });

  const isManager = useSelector(
    (state) => state.userWS.userData.profile.rights.ticketingV2?.managers,
  )?.includes(viewId);

  const statusOptions = status?.map((s) => ({
    label: s.label,
    value: s.id,
  }));

  const prioritiesOptions = priorities.map((p) => ({
    label: p.label[locale],
    value: p.name,
    id: p.name,
  }));

  const agentOptions = allAgents?.items?.map((a) => ({
    label: a.firstname && a.lastname ? `${a.firstname} ${a.lastname}` : a.email ? a.email : a.id,
    value: a.id,
  }));

  useEffect(() => {
    logger.log("tempData", tempData);
  }, [tempData]);

  useEffect(() => {
    logger.log("referential", referential);
  }, [referential]);

  // types
  useEffect(() => {
    const getTypes = () => {
      const tempTypes = [];
      referential?.forEach((d) => {
        tempTypes.push({
          value: d.id,
          label: d.label,
        });
      });
      setAllTypes(tempTypes);
    };
    getTypes();
  }, [referential]);

  // categories
  useEffect(() => {
    const getCategories = () => {
      const tempCategories = [];
      referential
        ?.find((d) => d.id === tempData.type)
        ?.categories?.forEach((d) => {
          tempCategories.push({
            value: d.id,
            label: d.label,
          });
        });
      setAllCategories(tempCategories);
    };
    getCategories();
  }, [tempData.type, referential]);

  // subcategories
  useEffect(() => {
    const getSubCategories = () => {
      const tempSubCategories = [];
      referential
        ?.find((d) => d.id === tempData.type)
        ?.categories?.find((d) => d.id === tempData.cat)
        ?.subCategories?.forEach((d) => {
          tempSubCategories.push({
            value: d.id,
            label: d.label,
          });
        });
      setAllSubCategories(tempSubCategories);
    };
    getSubCategories();
  }, [tempData.cat, tempData.type, referential]);

  const handlePopupScroll = (e) => {
    const target = e.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (!!allAgents.nextPage) {
        setPage(page + 1);
        dispatch(
          getAgents({
            siteId,
            page,
            size: 2,
          }),
        );
      }
    }
  };

  const handleApplyModifications = async () => {
    setApplyModifications(true);
    // dispatch batch action
    //check if manager or agent
    // send only the fields that have been modified

    const changedData = Object.keys(tempData).reduce((acc, key) => {
      if (tempData[key] !== selectedTickets[0][key]) {
        acc[key] = tempData[key];
      }
      return acc;
    }, {});

    try {
      const updateTicket = isManager
        ? await dispatch(
            updateTicketByManager({
              siteId,
              data: changedData,
              viewId,
            }),
          )
        : await dispatch(
            updateTicketByAgent({
              siteId,
              data: changedData,
              viewId,
            }),
          );

      if (updateTicket) {
        await fetchData();
        setApplyModifications(false);
        setShowBatchActionDrawer(false);
      }
    } catch (error) {
      logger.error("Error updating ticket:", error);
    }

    setApplyModifications(false);
    setShowBatchActionDrawer(false);
  };

  return (
    <Drawer
      placement="right"
      footerStyle={{ display: "none" }}
      headerStyle={{ display: "none" }}
      onClose={() => setShowBatchActionDrawer(false)}
      open={showBatchActionDrawer}
      contentWrapperStyle={{ backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Form ref={formRef} name="control-ref">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {!!allTypes?.length && (
              <Form.Item name="type">
                <>
                  <Typography.Text>{t("ticketingv2.Type")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={allTypes}
                    onChange={(type) =>
                      setTempData({ ...tempData, type: type, cat: null, subCategory: null })
                    }
                    placeholder={t("ticketingv2.AllTypes")}
                    value={tempData.type?.label}
                  />
                </>
              </Form.Item>
            )}

            {!!allCategories?.length && !!tempData.type && (
              <Form.Item name="category">
                <>
                  <Typography.Text>{t("ticketingv2.Cat")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={allCategories}
                    onChange={(cat) => setTempData({ ...tempData, cat: cat, subCategory: null })}
                    placeholder={t("ticketingv2.AllCats")}
                    value={tempData.cat || null}
                  />
                </>
              </Form.Item>
            )}

            {!!allSubCategories?.length && !!tempData.cat && (
              <Form.Item name="subCategory">
                <>
                  <Typography.Text>{t("ticketingv2.SubCat")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={allSubCategories}
                    onChange={(subcat) => setTempData({ ...tempData, subCategory: subcat })}
                    placeholder="Toutes les sous-catégories"
                    value={tempData.subCategory || null}
                  />
                </>
              </Form.Item>
            )}

            {!!statusOptions?.length && (
              <Form.Item name="status">
                <>
                  <Typography.Text>{t("ticketingv2.Status")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={statusOptions}
                    onChange={(status) => setTempData({ ...tempData, status: status })}
                    placeholder={t("ticketingv2.AllStatus")}
                    value={tempData.status?.label}
                  />
                </>
              </Form.Item>
            )}

            {!!prioritiesOptions?.length && (
              <Form.Item name="priorities">
                <>
                  <Typography.Text>{t("ticketingv2.Priority")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={prioritiesOptions}
                    onChange={(priorities) => setTempData({ ...tempData, priorities: priorities })}
                    placeholder={t("ticketingv2.AllPriorities")}
                    value={tempData.priorities?.label}
                  />
                </>
              </Form.Item>
            )}

            {isManager && (
              <Form.Item name="assignedTo">
                <>
                  <Typography.Text>{t("ticketingv2.AssignedTo")}</Typography.Text>
                  <Select
                    allowClear
                    onPopupScroll={handlePopupScroll}
                    style={{ marginTop: 5 }}
                    options={agentOptions}
                    onChange={(agent) => setTempData({ ...tempData, agent: agent })}
                    placeholder={t("ticketingv2.AllAgents")}
                    value={tempData.agent?.label}
                  />
                </>
              </Form.Item>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "revert-wrap",
              gap: 8,
            }}
          >
            <Button
              type="ghost"
              disabled={Object.keys(tempData).length === 0}
              onClick={() => {
                formRef.current.resetFields();
                setTempData({});
              }}
              style={{ flexGrow: 1 }}
            >
              {t("ticketingv2.DeleteAll")}
            </Button>
            <Button
              type="primary"
              disabled={Object.keys(tempData).length === 0}
              onClick={() => {
                handleApplyModifications();
              }}
              style={{ flexGrow: 1 }}
              loading={applyModifications}
            >
              {t("ticketingv2.Confirm")}
            </Button>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default BatchActionDrawer;
