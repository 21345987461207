import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Grid, Image, Modal, Spin, Tag, Typography, message } from "antd";
import moment from "moment";
import { isEmpty, isNil } from "ramda";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useHistory } from "react-router-dom";
import useDesignTokens from "../../../../hook/useDesignTokens";
import useDidUpdateEffect from "../../../../hook/useDidUpdate";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import {
  getRoomFastbooking,
  patchFilter,
  setRemoveBooking,
} from "../../../../services/redux/services/FastbookingWS";
import { getPlanning } from "../../../../services/redux/services/PlanningWS";
import Glyph from "../../../Common/Glyph/Glyph";
import useMap, { useMapLayer } from "../../../Common/Map/useMap";
import Tile from "../../../Common/Tile/Tile";
import NoResults from "./NoResults";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

import styles from "../Fastbooking.module.less";

const mapOptions = {
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 358,
  },
};

const FastbookingList = () => {
  useMapLayer(mapOptions);
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { colors, size } = useDesignTokens();
  const { centerOnPlaceId } = useMap();
  const history = useHistory();
  const screens = Grid.useBreakpoint();

  const planning = useSelector((state) => state.planningWS.planning);
  const roomFastbooking = useSelector((state) => state.fastbookingWS.roomFastbooking);
  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const allRoomFastbooking = useSelector((state) => state.fastbookingWS.allRoomFastbooking);

  const isLoading = useSelector(createLoadingSelector(["fastbooking/getRoomFastbooking"]));

  const roomsFilteredByFloor = roomFastbooking?.rooms?.filter(
    (r) =>
      campus?.mapData?.floors.find((f) => f.reference === r?.resources?.floorReference)?.id ===
      filters.floor,
  );

  const isRoom = planning?.slots?.items
    ?.filter((f) => f.locations.length !== 0)
    ?.filter((r) => r.isOrganizer === true);

  // Filtre par défaut
  useEffect(() => {
    if (isNil(filters?.floor)) {
      const floor = allRoomFastbooking?.rooms?.reduce(
        (acc, r) =>
          !acc
            ? campus?.mapData?.floors.find((f) => f.reference === r?.resources?.floorReference)
            : acc,
        null,
      );
      dispatch(
        patchFilter({
          floor: floor?.id,
        }),
      );
    } else {
      const floor = filters?.floor;
      dispatch(
        patchFilter({
          floor: floor,
        }),
      );
    }
  }, [allRoomFastbooking?.rooms, campus?.mapData?.floors, dispatch, filters]);

  moment.defineLocale(i18n.language.split("-")[0], {
    relativeTime: {
      future: "dans %s",
      s: "1 sec",
      m: "1 min",
      mm: "%d mins",
      h: "%d mins",
      hh: "%d h",
      d: "1 j",
      dd: "%d j",
    },
  });

  useEffect(() => {
    if (!!filters?.seats) {
      logEvent(analytics, "fast_booking_filter_capacity", {
        selected_capacity: filters?.seats,
      });
    }
  }, [filters?.seats]);

  useEffect(() => {
    if (!!filters?.startDate) {
      logEvent(analytics, "fast_booking_filter_time");
    }
  }, [filters?.startDate]);

  useEffect(() => {
    if (!!filters?.equipments) {
      logEvent(analytics, "fast_booking_filter_devices", {
        equipment_type: filters?.equipments
          ?.map((equipment) => {
            return campus?.mapData?.equipments.find((e) => e.id === equipment)?.title;
          })
          .join(","),
      });
    }
  }, [campus?.mapData?.equipments, filters?.equipments]);

  useEffect(() => {
    if (!!filters?.services) {
      logEvent(analytics, "fast_booking_filter_services", {
        service_type: filters?.services
          ?.map((service) => {
            return campus?.mapData?.services.find((e) => e.id === service)?.title;
          })
          .join(","),
      });
    }
  }, [campus?.mapData?.services, filters?.services]);

  useDidUpdateEffect(() => {
    if (roomFastbooking?.rooms?.find((r) => r?.pending === true)) return;
    dispatch(
      getRoomFastbooking({
        locale: i18n.language.split("-")[0],
        startDate: filters?.startDate || moment().toISOString(),
      }),
    );
  }, [dispatch, filters, i18n.language]);

  useEffect(() => {
    if (roomFastbooking?.rooms?.find((r) => r?.pending === true)) {
      setTimeout(() => {
        dispatch(
          getRoomFastbooking({
            locale: i18n.language.split("-")[0],
            startDate: moment().toISOString(),
          }),
        );
        dispatch(
          getPlanning({
            locale: i18n.language.split("-")[0],
            startDate: moment().toISOString(),
            endDate: moment().add(7, "days").toISOString(),
          }),
        );
      }, 10000);
    }
  }, [dispatch, i18n.language, roomFastbooking?.rooms]);

  const goRoom = (value) => {
    centerOnPlaceId(value?.map?.findAndOrder?.placeId);
    dispatch(
      patchFilter({
        floor: campus.mapData.floors.find((floor) => floor.reference === value.floorReference)?.id,
      }),
    );
  };

  const confirm = (r, item) => {
    Modal.confirm({
      title: t("CancelBooking"),
      content: t("fastbooking.sureCanceled"),
      okText: (
        <div style={{ width: "100%", display: "block" }} block>
          {t("Yes")}
        </div>
      ),
      cancelText: <div block>{t("No")}</div>,
      onOk: () => {
        dispatch(
          setRemoveBooking({
            slotId: item?.id,
            roomId: r?.id,
          }),
        )
          .unwrap()
          .then(() => {
            logEvent(analytics, "fast_booking_book_cancelled");
            message.success(t("BookCanceled"));
            history.push("/fastBookingV2/map");
            dispatch(
              getPlanning({
                locale: i18n.language.split("-")[0],
                startDate: moment().toISOString(),
                endDate: moment().add(7, "days").toISOString(),
              }),
            );
            dispatch(
              getRoomFastbooking({
                floor: filters.floor === "all" ? null : filters.floor,
                locale: i18n.language.split("-")[0],
                startDate: moment().toISOString(),
              }),
            );
          })
          .catch(() => {
            message.error(t("ErrorCancelBook"));
          });
      },
    });
  };

  return (
    <Card
      title={
        <>
          <Typography.Title level={5}>{t("fast_booking_title", { ns: "csv" })}</Typography.Title>
          <Typography.Text strong style={{ fontSize: size.m, color: colors.pending_light }}>
            {roomsFilteredByFloor?.length === 0
              ? t("NoResultFloor")
              : `${roomsFilteredByFloor?.length} ${t("ResultFloor")}`}
          </Typography.Text>
        </>
      }
      extra={
        <NavLink to="/fastBookingV2/filter">
          <Glyph name="tune" />
        </NavLink>
      }
      style={{
        width: screens.xl ? "35%" : 350,
        minWidth: screens.xl ? "35%" : 350,
        marginLeft: 8,
        height: "fit-content",
        overflow: "auto",
        maxHeight: "calc(100% - 8px)",
      }}
    >
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center", padding: "20px 0" }}>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        </div>
      ) : (
        <>
          {!isEmpty(isRoom) &&
            !isEmpty(
              isRoom?.filter((r) => r?.locations?.find((l) => l.siteId === campus?.mapData?.id)),
            ) && (
              <>
                <Typography.Paragraph strong style={{ fontSize: size.l }}>
                  {t("CurrentReservation")}
                </Typography.Paragraph>
                {planning?.slots?.items?.map((item, index) => (
                  <div key={index}>
                    {item?.resources?.map((r, index) => (
                      <Tile
                        style={{
                          display: "flex",
                          margin: 0,
                          marginLeft: 8,
                          height: "auto",
                          padding: 8,
                          cursor: "default",
                          flexDirection: "column",
                          gap: 8,
                        }}
                        key={index}
                      >
                        <div style={{ display: "flex", width: "100%" }}>
                          {r?.photos && !isEmpty(r?.photos) ? (
                            <div className={styles["pictures"]}>
                              <Image preview={false} width={110} height={80} src={r?.photos[0]} />
                            </div>
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "80px",
                                width: "110px",
                                backgroundColor: colors.grey_40,
                                opacity: 0.3,
                              }}
                            >
                              <Glyph
                                style={{ fontSize: "40px", color: "white", opacity: 0.8 }}
                                name={
                                  campus?.mapData?.categories?.find(
                                    (categorie) => categorie?.id === r?.categoryId,
                                  )?.icon
                                }
                              />
                            </div>
                          )}
                          <div style={{ marginLeft: "8px" }}>
                            <Typography.Paragraph
                              strong
                              style={{ fontSize: size.m, marginBottom: "0" }}
                            >
                              {r?.title}
                            </Typography.Paragraph>
                            {moment(item?.startDate) > moment() ? (
                              <Typography.Paragraph style={{ fontSize: size.m }}>
                                {moment(item?.startDate).format("DD MMM HH:mm")}
                              </Typography.Paragraph>
                            ) : (
                              <Typography.Paragraph style={{ fontSize: size.m }}>
                                {t("Until")} {moment(item?.endDate).format("HH:mm")}
                              </Typography.Paragraph>
                            )}

                            <div
                              style={{
                                content: "",
                                display: "block",
                                width: "30px",
                                height: "1px",
                                margin: "8px 0",
                                backgroundColor: colors.grey_20,
                              }}
                            ></div>
                            <Typography.Paragraph
                              style={{
                                fontSize: size.xs,
                                marginBottom: "0",
                                marginRight: "8px",
                              }}
                            >
                              <Glyph
                                name="group"
                                style={{ verticalAlign: "-2px", marginRight: "2px" }}
                              />
                              {r?.features?.seats}
                            </Typography.Paragraph>
                          </div>
                        </div>
                        <div
                          style={{ display: "flex", width: "100%", alignItems: "center", gap: 8 }}
                        >
                          <Button
                            ghost
                            size="medium"
                            style={{ width: "100%" }}
                            onClick={() => confirm(r, item)}
                          >
                            {t("BUTTON__CANCEL")}
                          </Button>
                          <Button
                            onClick={() => {
                              // centerOnPlaceId(r?.map?.findAndOrder?.placeId);
                              logEvent(analytics, "fast_booking_itinerary");
                            }}
                            type="primary"
                            size="medium"
                            style={{ width: "100%" }}
                          >
                            <Link
                              to={{
                                pathname: "/indoorMapping/itinerary",
                                state: {
                                  back: `/fastBookingV2/map`,
                                  selectedRoom: r,
                                },
                              }}
                              style={{ color: "white" }}
                            >
                              {t("SeeItinerary")}
                            </Link>
                          </Button>
                        </div>
                      </Tile>
                    ))}
                  </div>
                ))}
              </>
            )}
          {!isEmpty(roomsFilteredByFloor) ? (
            <Typography.Paragraph strong style={{ fontSize: size.l }}>
              {t("SpaceAvailable")}
            </Typography.Paragraph>
          ) : (
            <NoResults />
          )}
          {!isEmpty(roomFastbooking) &&
            [...(roomsFilteredByFloor || [])]
              ?.sort(
                (a, b) =>
                  /^\d/.test(a?.resources?.title) - /^\d/.test(b?.resources?.title) ||
                  a?.resources?.title.localeCompare(b?.resources?.title),
              )
              ?.filter((r) => {
                if (filters.floor === null) return r;
                const floor = campus?.mapData?.floors.find(
                  (f) => f.reference === r?.resources?.floorReference,
                );
                return floor?.id === filters.floor;
              })
              ?.map((room) => (
                <NavLink
                  to={{
                    pathname: `/fastBookingV2/${room?.resources?.map?.findAndOrder?.placeId}`,
                    state: { room: room },
                  }}
                  exact
                  className={styles["article--room"]}
                  style={{ display: "flex", margin: "16px 0" }}
                >
                  <div
                    onClick={() => {
                      goRoom(room?.resources);
                      logEvent(analytics, "fast_booking_select_poi_from_list", {
                        poi_name: room?.resources?.title,
                      });
                    }}
                    className={styles["tile--fastbooking"]}
                    style={{ marginLeft: "8px", display: "flex", flexFlow: "column" }}
                  >
                    <div style={{ marginLeft: "8px", display: "flex", position: "relative" }}>
                      {room?.resources?.photos && !isEmpty(room?.resources?.photos) ? (
                        <div className={styles["pictures"]}>
                          <Image
                            preview={false}
                            width={110}
                            height={80}
                            src={room?.resources?.photos[0]}
                          />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "80px",
                            width: "110px",
                            backgroundColor: colors.grey_40,
                            opacity: 0.3,
                          }}
                        >
                          <Glyph
                            style={{ fontSize: "40px", color: "white", opacity: 0.8 }}
                            name={
                              campus?.mapData?.categories?.find(
                                (categorie) => categorie?.id === room?.resources?.categoryId,
                              )?.icon
                            }
                          />
                        </div>
                      )}
                      <div style={{ marginLeft: "8px" }}>
                        <Typography.Paragraph
                          strong
                          style={{ fontSize: size.m, marginBottom: "0" }}
                        >
                          {room?.resources?.title}
                        </Typography.Paragraph>
                        {room?.available && (
                          <Typography.Paragraph style={{ fontSize: size.m }}>
                            {room?.availableTime >= 60
                              ? `${t("Available")} > 1h`
                              : `${moment
                                  .duration(
                                    moment(room.endDate).diff(moment(), "minutes"),
                                    "minutes",
                                  )
                                  .minutes()} mins`}
                          </Typography.Paragraph>
                        )}

                        <div
                          style={{
                            content: "",
                            display: "block",
                            width: "30px",
                            height: "1px",
                            margin: "8px 0",
                            backgroundColor: colors.grey_20,
                          }}
                        />
                        <Typography.Paragraph
                          style={{ fontSize: size.xs, marginBottom: "0", marginRight: "8px" }}
                        >
                          <Glyph
                            name="group"
                            style={{ verticalAlign: "-2px", marginRight: "2px" }}
                          />
                          {room?.resources?.features?.seats}
                        </Typography.Paragraph>
                      </div>
                      {room?.pending && (
                        <Tag
                          style={{
                            position: "absolute",
                            zIndex: "900",
                            top: "10px",
                            right: "16px",
                          }}
                          color={colors.pending_light}
                        >
                          <Typography.Text strong style={{ color: "white" }}>
                            {t("Treatment")}
                          </Typography.Text>
                        </Tag>
                      )}
                    </div>
                  </div>
                </NavLink>
              ))}
        </>
      )}
    </Card>
  );
};

export default FastbookingList;
