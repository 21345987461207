import { Avatar, Card, Input, Typography } from "antd";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Tile from "../../Common/Tile/Tile";
import { useKeycloak } from "@react-keycloak/web";
import { getContacts } from "../../../services/redux/services/ContactListWS";
import RightPanelContact from "./RightPanelContact";
import { useTranslation } from "react-i18next";
import { groupBy } from "ramda";
import { Link } from "react-router-dom";

const RightPanel = ({ groupFilter, currentContact }) => {
  const { colors } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const { keycloak } = useKeycloak();
  const dispatch = useDispatch();

  const [filter, setFilter] = useState("");
  const [page, setPage] = useState(1);

  const campus = useSelector((state) => state.clientsWS.campus);
  const contacts = useSelector((state) => state.contactListWS.contacts);

  const grouped = useMemo(() => {
    const sortedContacts = contacts?.items || [];
    const toReturn = groupBy((contact) => {
      const firstLetter = (contact.lastname || "#")[0].toUpperCase();
      return firstLetter;
    }, sortedContacts);
    return Object.entries(toReturn).sort((a, b) => {
      if (a[0] === "#") return 1;
      if (b[0] === "#") return -1;
      return a[0].localeCompare(b[0]);
    });
  }, [contacts]);

  useEffect(() => {
    dispatch(
      getContacts({
        siteId: campus?.mapData?.id,
        search: filter,
        page: page,
        size: 50, //!valeur test
        id: groupFilter?.id ? groupFilter.id : null,
        groupId: groupFilter?.searchParams ? groupFilter.searchParams.groupId : null,
      }),
    );
    // logger.log("getContacts");
  }, [campus?.mapData?.id, filter, dispatch, i18n.language, groupFilter, page]);

  useEffect(() => {
    setPage(1);
  }, [groupFilter, filter]);

  // infinite scrolling
  useEffect(() => {
    const container = document.getElementById("container");
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        if (contacts.currentPage !== contacts.totalPages) {
          setPage(contacts.nextPage);
        }
      }
    };
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [contacts]);

  if (currentContact.id) return <RightPanelContact currentContact={currentContact} />;

  return (
    <Card style={{ width: "60%", height: "100%" }} id="contactListCard">
      <Input
        placeholder={t("contactList.inputPlaceholder")}
        prefix={<Glyph name="search" style={{ color: colors.grey_40 }} />}
        suffix={
          !!filter && (
            <Glyph
              name="cancel"
              style={{ cursor: "pointer", color: colors.grey_40 }}
              onClick={() => setFilter("")}
            />
          )
        }
        onChange={(e) => setFilter(e.target.value)}
        value={filter}
        style={{ marginBottom: "16px" }}
        id="searchInput"
      />
      <div
        id="container"
        style={{
          height:
            document.getElementById("contactListCard") &&
            document.getElementById("contactListCard").clientHeight - 100,
          overflowY: "auto",
          paddingRight: "10px",
        }}
      >
        {contacts &&
          grouped?.map(([letter, contacts]) => (
            <div key={letter}>
              <Typography.Text
                style={{ color: colors.grey_80, display: "flex", marginTop: "10px" }}
              >
                {letter}
              </Typography.Text>
              {contacts.map((contact) => (
                <Link to={`/contactListEnhanced/${contact.id}`} key={contact.id}>
                  <Tile
                    style={{
                      margin: "0",
                      marginBottom: "8px",
                      height: "auto",
                      padding: "10px 20px",
                      cursor: "pointer",
                    }}
                  >
                    <div style={{ display: "flex" }}>
                      <Avatar
                        size={"large"}
                        src={`${contact.photoUrl}?access_token=${keycloak.token}`}
                        style={{
                          borderColor: "white",
                          minWidth: 40,
                        }}
                      >
                        {contact.firstname?.charAt(0).toUpperCase()}
                        {contact.lastname?.charAt(0).toUpperCase()}
                      </Avatar>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          marginLeft: "16px",
                        }}
                      >
                        <Typography.Text strong>
                          {contact.firstname}&nbsp;{contact.lastname}
                        </Typography.Text>
                        {contact.function && <Typography.Text>{contact.function}</Typography.Text>}
                      </div>
                    </div>
                    {(groupFilter.isPresence || contact.presence) && (
                      <Glyph name="pin_drop" style={{ color: colors.primary_base }} />
                    )}
                  </Tile>
                </Link>
              ))}
            </div>
          ))}

        {contacts.items?.length === 0 && (
          <Typography.Text style={{ color: colors.pending_light }}>
            {t("contactList.noResult0")}
            <br />
            {t("contactList.noResult1")}
          </Typography.Text>
        )}
        {contacts.currentPage !== contacts.totalPages && contacts.totalPages > 1 && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Typography.Text
              id="showmore"
              style={{
                cursor: "pointer",
                color: colors.pending_light,
                marginTop: "10px",
              }}
              onClick={() => setPage(contacts.nextPage)}
            >
              {t("contactList.showMore")}
            </Typography.Text>
          </div>
        )}
      </div>
    </Card>
  );
};

export default RightPanel;
