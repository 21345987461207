import { LoadingOutlined } from "@ant-design/icons";
import { Calendar, Card, Drawer, Grid, Spin, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";
import { getBookings } from "../../../services/redux/services/ParkingWS";
import Glyph from "../../Common/Glyph/Glyph";
import CalendarRightPanel from "./Component/CalendarRightPanel";
import HeaderButtons from "./Component/HeaderButtons";
import MonthAndYearSelector from "./Component/MonthAndYearSelector";

// Firebase
// import { logEvent } from "firebase/analytics";
// import { analytics } from "../../../services/api/Firebase/Firebase";

const ParkingTabBooking = ({
  setShowManageVehiclesModal,
  setShowCreateOrModifyBookingModal,
  selectedDate,
  setSelectedDate,
  setSelectedBooking,
  setShowDeleteBookingModal,
}) => {
  const { t } = useTranslation();
  const { colors, size } = useDesignTokens();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();

  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);

  const campus = useSelector((state) => state.clientsWS.campus);
  const bookings = useSelector((state) => state.parkingWS.bookings);
  const vehicles = useSelector((state) => state.parkingWS.vehicles);

  // check common disabled days for all sites
  const allSiteDisabledDays = useSelector((state) => state.userWS.userData.campus)
    ?.filter((c) => c.parking)
    ?.reduce(
      (acc, campus) => {
        const campusDisabledDays = campus.parking.days;
        return acc.filter((day) => !campusDisabledDays.includes(day));
      },
      [0, 1, 2, 3, 4, 5, 6],
    );

  useEffect(() => {
    logger.log(allSiteDisabledDays);
  }, [allSiteDisabledDays]);

  const [selectedMonth, setSelectedMonth] = useState(moment());
  const [loading, setLoading] = useState(false);
  const [initialLaunch, setInitialLaunch] = useState(true);

  useEffect(() => {
    if (!!!vehicles?.length) return;

    setLoading(true);

    const fetchData = async () => {
      await dispatch(
        getBookings({
          siteId: campus?.mapData?.id,
          from: moment(selectedMonth).startOf("month").subtract(10, "days"),
          to: moment(selectedMonth).endOf("month").add(10, "days"),
        }),
      );
      setLoading(false);
    };
    fetchData();
  }, [campus?.mapData?.id, dispatch, selectedMonth, vehicles]);

  useEffect(() => {
    if (
      !initialLaunch &&
      bookings?.reservations?.find((s) => moment(s.from).isSame(selectedDate, "date"))
    ) {
      setOpenDetailsDrawer(true);
    }
    setInitialLaunch(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDate]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        height: "100%",
        maxWidth: 1500,
        margin: "auto",
      }}
    >
      <HeaderButtons
        setShowManageVehiclesModal={setShowManageVehiclesModal}
        setShowCreateOrModifyBookingModal={setShowCreateOrModifyBookingModal}
      />
      <div style={{ display: "flex", gap: 16, height: "calc(100% - 92px)" }}>
        {/* CALENDAR */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
            width: screens.xl ? "67%" : "100%",
          }}
        >
          <MonthAndYearSelector
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            setSelectedDate={setSelectedDate}
            selectedDate={selectedDate}
            clickable={!loading && !!vehicles?.length}
          />
          <Card bodyStyle={{ padding: 0 }}>
            <Spin
              spinning={loading}
              indicator={<LoadingOutlined style={{ fontSize: size.xxxxl }} />}
            >
              <Calendar
                value={selectedDate}
                onSelect={(date) => {
                  !loading && setSelectedDate(date);
                  !loading &&
                    !!vehicles?.length &&
                    !moment(selectedMonth).isSame(date, "month") &&
                    setSelectedMonth(date);
                }}
                onChange={() => {
                  /*logEvent(analytics, "spaas_select_date_current_week")*/
                }}
                disabledDate={(date) => allSiteDisabledDays?.includes(date.day())}
                headerRender={() => null}
                dateCellRender={(date) => {
                  const todaySlots = bookings?.reservations?.filter((s) => {
                    return moment(s.from).isSame(date, "date");
                  });
                  return (
                    <>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "end",
                          overflow: "hidden",
                        }}
                      >
                        {!!todaySlots?.length &&
                          !!vehicles?.length &&
                          todaySlots?.map((s) => (
                            <div
                              style={{
                                display: "flex",
                                gap: 4,
                              }}
                            >
                              {screens.lg && (
                                <Glyph
                                  name="local_parking"
                                  style={{ color: colors.secondary_base, fontSize: size.l }}
                                />
                              )}
                              <Typography.Text
                                ellipsis
                                strong
                                style={{ fontSize: size.s, color: colors.secondary_base }}
                              >
                                {s.period === "AM"
                                  ? t("parking.Am")
                                  : s.period === "PM"
                                  ? t("parking.Pm")
                                  : t("parking.Day")}
                              </Typography.Text>
                            </div>
                          ))}
                      </div>
                    </>
                  );
                }}
              />
            </Spin>
          </Card>
        </div>

        {/* RIGHT CARD */}
        {screens.xl ? (
          <Card
            style={{ width: "35%" }}
            bodyStyle={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "hidden",
              padding: 8,
              paddingTop: 16,
            }}
          >
            <CalendarRightPanel
              setShowCreateOrModifyBookingModal={setShowCreateOrModifyBookingModal}
              selectedDate={selectedDate}
              setSelectedBooking={setSelectedBooking}
              setShowDeleteBookingModal={setShowDeleteBookingModal}
              loading={loading}
              setShowManageVehiclesModal={setShowManageVehiclesModal}
            />
          </Card>
        ) : (
          <Drawer
            width={350}
            placement="right"
            open={openDetailsDrawer}
            onClose={() => setOpenDetailsDrawer(false)}
            headerStyle={{ padding: "16px 4px 0 0" }}
            bodyStyle={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "hidden",
              padding: 16,
            }}
          >
            <CalendarRightPanel
              setShowCreateOrModifyBookingModal={setShowCreateOrModifyBookingModal}
              selectedDate={selectedDate}
              setSelectedBooking={setSelectedBooking}
              setShowDeleteBookingModal={setShowDeleteBookingModal}
              loading={loading}
              setShowManageVehiclesModal={setShowManageVehiclesModal}
            />
          </Drawer>
        )}
      </div>
    </div>
  );
};

export default ParkingTabBooking;
