import { Avatar, Button, Card, Col, Input, Row, Space, Tabs, Typography } from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FixedSizeList as VirtualList } from "react-window";
import { normalizeString } from "../../../helpers/utils";
import useDebounce from "../../../hook/useDebounce";
import useDimensions from "../../../hook/useDimensions";
import useQuery from "../../../hook/useQuery";
import SpasConnector from "../../../services/api/DWM/SpasConnector";
import { getMapData, mapDataSelector } from "../../../services/redux/services/MapDataWS";
import {
  getFollowedDayList,
  getTeamDayList,
  listSlots,
  listTeamMapFloorUsers,
  slotsWithSitesSelector,
} from "../../../services/redux/services/SpaceService";
import { selectedCampus } from "../../../services/redux/services/UserWS";
import Glyph from "../../Common/Glyph/Glyph";
import DatePickerInline from "../../Common/Inputs/DatePickerInline/DatePickerInline";
import FloorSelector from "../../Common/Map/Mapbox/FloorSelector";
import SiteSelector from "../../Common/Map/Mapbox/SiteSelector";
import ZoomInOut from "../../Common/Map/Mapbox/ZoomInOut";
import {
  MapboxMap,
  MapboxProvider,
  useMapboxInstance,
} from "../../Common/Map/Mapbox/useMapboxInstance";
import ModalCancel from "./ModalCancel";
import { baseName } from "./SpaceBooking";
import { PERIODS } from "./SpaceBookingResident";

const SIDER_WIDTH = 450;

const ROW_SIZE = 80;
const ROW_GAP = 10;

const UserList = ({ siteId, date, period, setPeriod, onSelectUser }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [listRef, listDiemensions] = useDimensions();

  const query = useQuery();
  const _view = query.get("view") || "contacts";

  const [search, setSearch] = useState("");

  const searchDebounce = useDebounce(search, 500);

  const selectedSite = useSelector((state) => selectedCampus(state, siteId));
  const planningDayList = useSelector((state) => state.spaceServiceWS.planningDayList);
  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  const minDate = moment().subtract(1, "month").format("YYYY-MM-DD");
  const maxDate = moment()
    .add(selectedSite?.spaceBooking.maxRecurrenceMonths, "month")
    .format("YYYY-MM-DD");

  useEffect(() => {
    if (!siteId) return;
    const params = { siteId, page: 1, size: 100, date, search: searchDebounce, period };
    if (_view !== "team") return; // Only team members are paginated and contacts are already fetched on previous page
    dispatch(getTeamDayList(params));
  }, [date, dispatch, period, searchDebounce, siteId, _view]);

  const handleDate = (date) => {
    const query = new URLSearchParams(history.location.search);
    query.set("date", date.format("YYYY-MM-DD"));
    history.replace({ search: query.toString() });
  };

  const planningDayListMemo = useMemo(() => {
    if (!planningDayList) return null;
    const list = planningDayList.list.find((i) => i.site?.id === siteId);
    if (_view === "team") return list; // Team is already filtered
    return list
      ? {
          ...list,
          items: list.items.filter((i) => {
            const isSamePeriod = i.period === period || i.period === PERIODS.DAY;
            const normalizedSearch = normalizeString(search);
            const normalizedName = normalizeString(`${i.user.firstname} ${i.user.lastname}`);
            const isSearched = new RegExp(normalizedSearch, "i").test(normalizedName);
            return isSamePeriod && isSearched;
          }),
        }
      : null;
  }, [period, planningDayList, search, siteId, _view]);

  const Row = useCallback(
    ({ style, index }) => {
      const item = planningDayListMemo?.items?.[index];
      const displayName = `${item?.user?.firstname || ""} ${item?.user?.lastname || ""}`.trim();

      const spaceBookingType = sites.find((s) => s.id === siteId)?.spaceBooking?.type;

      return (
        <div key={item.id} style={style}>
          <Card
            bordered={false}
            style={{ height: ROW_SIZE - ROW_GAP, cursor: "pointer" }}
            bodyStyle={{ display: "flex", alignItems: "center", padding: "0px 10px 0 10px" }}
            onClick={() => onSelectUser(item)}
          >
            <Space size="middle">
              <Avatar src={item?.user?.photoUrl}>{displayName.charAt(0).toUpperCase()}</Avatar>
              {spaceBookingType === 3 ? (
                <div>
                  <Typography.Paragraph strong style={{ margin: 0 }}>
                    {displayName}
                  </Typography.Paragraph>
                  <Space size={5}>
                    <Typography.Text type="secondary">{item.sector?.title}</Typography.Text>
                    <Typography.Text type="secondary">{"\u2022"}</Typography.Text>
                    <Typography.Text type="secondary">{item.room?.title}</Typography.Text>
                    <Typography.Text type="secondary">{"\u2022"}</Typography.Text>
                    <Typography.Text type="secondary">
                      {t("Floor")} {item.floor?.title}
                    </Typography.Text>
                  </Space>
                </div>
              ) : (
                <Typography.Text strong>{displayName}</Typography.Text>
              )}
            </Space>
          </Card>
        </div>
      );
    },
    [onSelectUser, planningDayListMemo?.items, siteId, sites, t],
  );

  return (
    <Card
      style={{ width: SIDER_WIDTH, height: "100%", float: "left", marginRight: 20 }}
      bodyStyle={{ padding: 0 }}
    >
      <Button
        style={{ position: "absolute", top: 15, left: 15, zIndex: 1 }}
        size="middle"
        type="dashed"
        icon={<Glyph name={"chevron_left"} />}
        onClick={() => history.goBack()}
      />
      <div
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          gap: 10,
          padding: "20px 10px 10px 10px",
        }}
      >
        <Card
          bordered={false}
          style={{ borderRadius: 0, margin: "-20px -10px 0 -10px" }}
          bodyStyle={{ padding: "20px 10px 10px 10px" }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            <div style={{ textAlign: "center" }}>
              <Typography.Title style={{ textTransform: "capitalize", margin: 0 }} level={3}>
                {moment(date).format("dddd DD MMMM")}
              </Typography.Title>
              <Typography.Text type={"secondary"}>
                {planningDayListMemo?.items?.length || 0} collaborateur(s) sur site
              </Typography.Text>
            </div>
            <DatePickerInline
              min={minDate}
              max={maxDate}
              value={moment(date)}
              onChange={handleDate}
            />
          </div>
        </Card>

        <Input
          placeholder={"Chercher un membre présent"}
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />

        <Tabs className="segmented" activeKey={period} onChange={setPeriod}>
          <TabPane tab={t("spas.period.MORNING")} key="MORNING" />
          <TabPane tab={t("spas.period.AFTERNOON")} key="AFTERNOON" />
        </Tabs>

        <div ref={listRef} style={{ flexGrow: 1 }}>
          {!!listDiemensions.height && (
            <VirtualList
              height={listDiemensions.height}
              itemCount={planningDayListMemo?.items?.length || 0}
              itemSize={ROW_SIZE}
            >
              {Row}
            </VirtualList>
          )}
        </div>
      </div>
    </Card>
  );
};

const UserItem = ({ date, info, onBack }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const query = useQuery();
  const _view = query.get("view") || "contacts";

  const [slot, setSlot] = useState(null);
  const [showCancelModal, setShowCancelModal] = useState(false);

  useEffect(() => {
    if (!info?.user?.id || _view !== "team") return;

    SpasConnector.listSpaasSlots({
      startDate: moment(date).format("YYYY-MM-DD"),
      endDate: moment(date).format("YYYY-MM-DD"),
      forUserId: info.user.id,
    }).then((res) => {
      setSlot(res.find((i) => i.id === info.id));
    });

    return () => {
      setSlot(null);
    };
  }, [date, info, _view]);

  const handleBookingSameDay = () => {
    history.push({ pathname: `${baseName}`, state: { date: moment(date).format("YYYY-MM-DD") } });
  };

  const { period, user, space, room } = info;
  const displayName = `${user?.firstname || ""} ${user?.lastname || ""}`.trim();
  return (
    <Card
      style={{ width: SIDER_WIDTH, float: "left", marginRight: 20 }}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: 10,
        padding: "20px 10px 10px 10px",
      }}
    >
      <Button
        style={{ position: "absolute", top: 15, left: 15 }}
        size="middle"
        type="dashed"
        icon={<Glyph name={"chevron_left"} />}
        onClick={onBack}
      />

      <div style={{ textAlign: "center" }}>
        <Typography.Title style={{ textTransform: "capitalize", margin: 0 }} level={3}>
          {moment(date).format("dddd DD MMMM")}
        </Typography.Title>
        <Typography.Paragraph type={"secondary"}>{displayName}</Typography.Paragraph>
        <Avatar size={50} src={user?.photoUrl}>
          {displayName.charAt(0).toUpperCase()}
        </Avatar>
      </div>

      <Row gutter={[10, 10]}>
        <Col span={24}>
          <Card bordered={false}>
            <Space>
              <Glyph className={"secondary"} name={"business"} />
              <Typography.Text strong ellipsis>
                {room.title} {"\u2022"} {space.title}
              </Typography.Text>
            </Space>
          </Card>
        </Col>
        <Col span={24}>
          <Card bordered={false}>
            <Space>
              <Glyph className={"secondary"} name={"schedule"} />
              <Typography.Text strong ellipsis>
                {t("spas.period." + period)}
              </Typography.Text>
            </Space>
          </Card>
        </Col>
      </Row>

      <br />
      <br />

      <Row gutter={[10, 10]}>
        {/* <Col offset={2} span={20}>
          <Button block type="primary" icon={<Glyph name={"chat"} />}>
            {"Envoyer un message"}
          </Button>
        </Col> */}
        {moment(date).isAfter(moment(), "date") && (
          <>
            <Col offset={2} span={20}>
              <Button
                block
                type="secondary"
                icon={<Glyph name={"event_available"} />}
                onClick={handleBookingSameDay}
              >
                {"Réserver le même jour"}
              </Button>
            </Col>
            {_view === "team" && !!slot && (
              <Col offset={2} span={20}>
                <Button
                  block
                  ghost
                  icon={<Glyph name={"cancel"} />}
                  onClick={() => setShowCancelModal(true)}
                >
                  {"Annuler la réservation"}
                </Button>
              </Col>
            )}
          </>
        )}
      </Row>

      <ModalCancel slot={showCancelModal ? slot : null} onClose={() => setShowCancelModal(false)} />
    </Card>
  );
};

const MapViewContent = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { mapReady, floor, setDesksDisplay, centerOnPlace } = useMapboxInstance();

  const locale = i18n.language.split("-")[0];

  const location = useLocation();
  const query = useQuery();
  const _siteId = query.get("siteId");
  const _date = query.get("date") || moment().format("YYYY-MM-DD");
  const _view = query.get("view") || "contacts";
  const _spaceId = location.state?.spaceId;

  const [selectedUser, setSelectedUser] = useState(null);
  const [period, setPeriod] = useState("MORNING");

  const profile = useSelector((state) => state.userWS.userData?.profile);
  const selectedSite = useSelector((state) => selectedCampus(state, _siteId));
  const planningDayList = useSelector((state) => state.spaceServiceWS.planningDayList);
  const mapFloorUsers = useSelector((state) => state.spaceServiceWS.mapFloorUsers);
  const slots = useSelector(slotsWithSitesSelector);

  const siteId = selectedSite?.id;

  const mapData = useSelector((state) => mapDataSelector(state, siteId));

  useEffect(() => {
    if (!!mapData) return;
    dispatch(getMapData({ siteId, locale }));
  }, [dispatch, locale, mapData, siteId]);

  const floorId = useMemo(() => {
    if (!floor) return null;
    return mapData?.floors?.find((i) => i.reference === floor.id)?.id;
  }, [floor, mapData?.floors]);

  useEffect(() => {
    const params = { startDate: _date, endDate: _date };
    dispatch(listSlots(params));
  }, [_date, dispatch]);

  useEffect(() => {
    if (_view !== "team") return;
    if (!siteId || !floorId) return;
    const params = { siteId, date: _date, floor: floorId, search: "", period };
    dispatch(listTeamMapFloorUsers(params));
  }, [_date, dispatch, floorId, period, siteId, _view]);

  useEffect(() => {
    if (_view !== "contacts") return;
    dispatch(getFollowedDayList({ date: _date }));
  }, [_date, dispatch, _view]);

  const mapFloorUsersMemo = useMemo(() => {
    if (_view === "team") return mapFloorUsers?.list;
    if (!siteId) return null;
    const list = planningDayList?.list?.find((i) => i.site?.id === siteId);
    return list?.items;
  }, [mapFloorUsers, planningDayList?.list, siteId, _view]);

  useEffect(() => {
    if (!mapReady || !_spaceId) return;
    centerOnPlace(_spaceId, false, false, { zoom: 22 });
  }, [centerOnPlace, mapReady, _spaceId]);

  const mySlot = useMemo(() => {
    const slot = slots.filter((i) => {
      return (
        i.site?.id === siteId &&
        i.date === _date &&
        (i.request.period === period || i.request.period === PERIODS.DAY)
      );
    })[0];

    if (slot) {
      slot.user = {
        id: profile.id,
        firstname: profile.firstName,
        lastname: profile.lastName,
        photoUrl: profile.photoUrl,
      };
    }

    return slot;
  }, [
    _date,
    period,
    profile.firstName,
    profile.id,
    profile.lastName,
    profile.photoUrl,
    siteId,
    slots,
  ]);

  useEffect(() => {
    if (!mapReady) return;

    const roomIds = new Set((mapData?.workplaces || []).map((i) => i.roomId));
    const roomsWithWorkplaces = (mapData?.resources || [])
      .filter((i) => roomIds.has(i.id))
      .map((i) => ({
        ...i,
        workplaces: (mapData?.workplaces || []).filter((j) => j.roomId === i.id),
        category: mapData?.categories?.find((j) => j.id === i.categoryId),
      }));

    const spaceIds = mapFloorUsersMemo?.filter((i) => i.space?.id)?.map((i) => i.space?.id) || [];
    const occupiedDesks = new Set(spaceIds);

    const users =
      mapFloorUsersMemo
        ?.filter((i) => i.space?.id)
        ?.map(({ user, space }) => ({
          id: user.id,
          name: `${user.firstname || ""} ${user.lastname || ""}`.trim(),
          photoUrl: user.photoUrl,
          deskUid: space.id,
        })) || [];

    if (mySlot) {
      occupiedDesks.add(mySlot.space.id);
      users.push({
        id: mySlot.user.id,
        name: `${mySlot.user.firstname || ""} ${mySlot.user.lastname || ""}`.trim(),
        photoUrl: mySlot.user.photoUrl,
        deskUid: mySlot.space.id,
      });
    }

    setDesksDisplay({
      rooms: roomsWithWorkplaces.map((room) => ({
        roomUid: room.id,
        availableDeskUids: room.workplaces.filter((w) => !occupiedDesks.has(w.id)).map((w) => w.id),
      })),
      users,
    });
  }, [
    mapData?.categories,
    mapData?.resources,
    mapData?.workplaces,
    mapFloorUsersMemo,
    mapReady,
    mySlot,
    setDesksDisplay,
  ]);

  const onSelectUser = useCallback(
    (info) => {
      if (info.space?.id) centerOnPlace(info.space.id);
      setSelectedUser(info);
    },
    [centerOnPlace],
  );

  return (
    <div style={{ position: "relative", width: "100%", height: "100%", padding: 20 }}>
      <MapboxMap
        style={{ position: "absolute", width: "100%", height: "100%", top: 0, left: 0 }}
        id="MapView-map-container"
      />
      {selectedUser ? (
        <UserItem date={_date} info={selectedUser} onBack={() => setSelectedUser()} />
      ) : (
        <UserList
          siteId={siteId}
          date={_date}
          period={period}
          setPeriod={setPeriod}
          onSelectUser={onSelectUser}
        />
      )}

      <div style={{ float: "left", display: "flex", gap: 10 }}>
        <SiteSelector
          filter={(i) => i.spaceBooking?.type === 3}
          value={+siteId}
          onChange={(siteId) => {
            const query = new URLSearchParams(history.location.search);
            query.set("siteId", siteId);
            history.replace({ search: query.toString() });
          }}
        />
        {!!mapReady && <FloorSelector />}
      </div>

      <div style={{ position: "absolute", bottom: 20, right: 20 }}>
        <ZoomInOut />
      </div>
    </div>
  );
};

const MapView = ({ ...props }) => {
  const query = useQuery();
  const siteId = query.get("siteId");

  // Prevent whole map redraw on query change
  const providerProps = useMemo(
    () => ({ siteId, mode: "2D", padding: { left: SIDER_WIDTH } }),
    [siteId],
  );

  return (
    <MapboxProvider {...providerProps}>
      <MapViewContent {...props} />
    </MapboxProvider>
  );
};

export default MapView;
