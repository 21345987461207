import { Avatar, Button, Card, Segmented, Spin, Tag, Tooltip, Typography } from "antd";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addFavoriteContact,
  getContactCategories,
  getContactDetails,
  removeFavoriteContact,
} from "../../../services/redux/services/ContactListWS";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import RightPanelMisc from "./RightPanelMisc";
import RightPanelGeneral from "./RightPanelGeneral";
import { LoadingOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { ReactComponent as UnknowUser } from "../../../assets/svg/undraw_searching.svg";

const RightPanelContact = ({ currentContact }) => {
  const dispatch = useDispatch();
  const { colors, size } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const { keycloak } = useKeycloak();
  const history = useHistory();

  const [segment, setSegment] = useState("contact");
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [unknownUser, setUnknownUser] = useState(true);
  const [favoriteCategory, setFavoriteCategory] = useState({});
  const [presentCategory, setPresentCategory] = useState({});

  const campus = useSelector((state) => state.clientsWS.campus);
  const contactDetails = useSelector((state) => state.contactListWS.contactDetails);
  const profile = useSelector((state) => state.userWS.userData.profile);

  /*useEffect(() => {
    logger.log("contactDetails", contactDetails, "profile", profile);
  }, [contactDetails, profile]);*/

  useEffect(() => {
    setFavoriteCategory(contactDetails.contact?.lists?.find((list) => list.isFavorite));
    setPresentCategory(contactDetails.contact?.lists?.find((list) => list.isPresence));
  }, [contactDetails]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setUnknownUser(false);

      const details = await dispatch(
        getContactDetails({
          siteId: campus?.mapData?.id,
          userId: currentContact.id,
          locale: i18n.language,
        }),
      );
      if (details.error) setUnknownUser(true);
      setIsLoading(false);
      // logger.log("getContactDetails");
    };
    fetchData();
  }, [campus, currentContact, dispatch, i18n.language]);

  useEffect(() => {
    setUserData({ ...contactDetails, profile: profile?.id === contactDetails.contact?.id });
  }, [contactDetails, profile]);

  const handleFavorite = () => {
    contactDetails.contact.lists.find((list) => list.isFavorite)
      ? dispatch(removeFavoriteContact({ userId: contactDetails.contact.id }))
      : dispatch(addFavoriteContact({ userId: contactDetails.contact.id }));

    dispatch(
      getContactCategories({
        siteId: campus?.mapData?.id,
        locale: i18n.language,
      }),
    );
    // logger.log("getContactCategories - handleFavorite");
  };

  if (!isLoading && unknownUser)
    return (
      <Card
        style={{
          width: "60%",
          height: "100%",
        }}
        bodyStyle={{
          width: "100%",
          gap: "32px",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <UnknowUser />
        <Typography.Title level={3}>{t("contactList.unknownUser")}</Typography.Title>
      </Card>
    );

  return !isLoading ? (
    <Card
      style={{
        width: "60%",
        gap: "16px",
        height: "100%",
      }}
      headStyle={{
        backgroundColor: colors.light_background,
        minHeight: "fit-content",
        boxShadow: "0 0 8px rgba(11,41,47,0.3)",
        border: "none",
      }}
      title={
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Glyph
              name="arrow_back_ios"
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
            />
            <Avatar
              size={56}
              src={`${userData.contact?.photoUrl}?access_token=${keycloak.token}`}
              style={{ border: "0", boxShadow: "none", marginBottom: "10px" }}
            >
              {(userData.contact?.firstname || "").charAt(0).toUpperCase()}
              {(userData.contact?.lastname || "").charAt(0).toUpperCase()}
            </Avatar>
            <div
              onClick={handleFavorite}
              style={{
                cursor: "pointer",
                visibility: userData.profile ? "hidden" : "visible",
                width: "25px",
              }}
            >
              {!userData.profile &&
                (userData.contact?.lists?.find((list) => list.isFavorite) ? (
                  <Tooltip title={t("contactList.favoriteRemove")}>
                    <Glyph name="star" style={{ color: colors.secondary_base }} />
                  </Tooltip>
                ) : (
                  <Tooltip title={t("contactList.favoriteAdd")}>
                    <Glyph name="star_border" />
                  </Tooltip>
                ))}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography.Text strong style={{ fontSize: size.l }}>
              {userData.contact?.firstname} {userData.contact?.lastname?.toUpperCase()}
            </Typography.Text>
            {userData.contact?.function && (
              <Typography.Text
                style={{ fontSize: size.xs, marginBottom: "10px", letterSpacing: "2px" }}
              >
                {userData.contact?.function.toUpperCase()}
              </Typography.Text>
            )}
          </div>

          {!userData.profile && (
            <div style={{ display: "flex" }}>
              {favoriteCategory && (
                <Link
                  to={`${favoriteCategory.id}-${favoriteCategory.title
                    .replace(/\s(.)/g, (_, match) => match.toUpperCase())
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")}`}
                >
                  <Tag
                    style={{
                      margin: "4px",
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: colors.secondary_base,
                    }}
                  >
                    <Glyph name="star" style={{ color: "white", marginRight: "10px" }} />
                    <Typography.Text strong style={{ color: "white" }}>
                      {t("contactList.favorite")}
                    </Typography.Text>
                  </Tag>
                </Link>
              )}
              {presentCategory && (
                <Link
                  to={`${presentCategory.id}-${presentCategory.title
                    .replace(/\s(.)/g, (_, match) => match.toUpperCase())
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")}`}
                >
                  <Tag
                    style={{
                      margin: "4px",
                      width: "fit-content",
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: colors.primary_base,
                    }}
                  >
                    <Glyph name="pin_drop" style={{ color: "white", marginRight: "10px" }} />
                    <Typography.Text strong style={{ color: "white" }}>
                      {t("contactList.onSite")}
                    </Typography.Text>
                  </Tag>
                </Link>
              )}
              {userData.contact?.lists?.map(
                (list) =>
                  !list.isFavorite &&
                  !list.isPresence && (
                    <Link
                      to={`${list.id}-${list.title
                        .replace(/\s(.)/g, (_, match) => match.toUpperCase())
                        .normalize("NFD")
                        .replace(/[\u0300-\u036f]/g, "")}`}
                    >
                      <Tag
                        key={list.id}
                        style={{ margin: "4px", width: "fit-content", backgroundColor: "black" }}
                      >
                        <Typography.Text strong style={{ color: "white" }}>
                          {list.title}
                        </Typography.Text>
                      </Tag>
                    </Link>
                  ),
              )}
            </div>
          )}
        </div>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
          gap: "10px",
        }}
      >
        <Segmented
          size="normal"
          style={{ marginBottom: "32px", backgroundColor: colors.grey_40 }}
          value={segment}
          options={[
            {
              label: (
                <Typography.Text
                  strong
                  style={{
                    margin: "0",
                    padding: "0 40px",
                    color: segment === "contact" ? colors.primary_dark : "white",
                  }}
                >
                  {t("contactList.segmentedContact")}
                </Typography.Text>
              ),
              value: "contact",
            },
            {
              label: (
                <Typography.Text
                  strong
                  style={{
                    margin: "0",
                    padding: "0 40px",
                    color: segment === "aboutMe" ? colors.primary_dark : "white",
                  }}
                >
                  {t("contactList.segmentedAboutMe")}
                </Typography.Text>
              ),
              value: "aboutMe",
            },
          ]}
          onChange={(value) => {
            setSegment(value);
          }}
        />
        <div style={{ height: "100%", width: "100%" }}>
          {userData && segment === "contact" ? (
            <RightPanelGeneral userData={userData} />
          ) : (
            <RightPanelMisc userData={userData} />
          )}
        </div>
        {userData.contact.chatId && !userData.profile && (
          <Link
            to={`../../instantMessaging/${userData.contact.chatId}`}
            style={{ display: "flex" }}
          >
            <Button block type="primary" icon={<Glyph name="chat" />} style={{ width: "320px" }}>
              {t("contactList.newChat")}
            </Button>
          </Link>
        )}
      </div>
    </Card>
  ) : (
    <Card
      style={{
        width: "60%",
        height: "100%",
      }}
      bodyStyle={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
        <br />
        <Typography.Title level={3}>{t("Loading")}...</Typography.Title>
      </div>
    </Card>
  );
};

export default RightPanelContact;
