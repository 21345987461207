import { Card, Col, DatePicker, Row } from "antd";
import useDesignTokens from "../../../../hook/useDesignTokens";

const CardDatePicker = ({ label, value, onChange, pickerProps, ...props }) => {
  const { colors } = useDesignTokens();
  const invalid = props["aria-invalid"]; // antd form validation
  return (
    <Card
      style={{
        boxShadow: invalid ? `0px 0px 0px 2px ${colors.error_light}` : undefined,
      }}
    >
      <Row>
        <Col flex={"auto"}>{label}</Col>
        <Col>
          <DatePicker
            {...pickerProps}
            value={value}
            onChange={onChange}
            style={{ margin: -8 }}
            format="DD MMM YYYY"
          />
        </Col>
      </Row>
    </Card>
  );
};

export default CardDatePicker;
