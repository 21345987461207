import { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { getSendbirdUnreadCount } from "../services/redux/services/InstantMessagingWS";

const useSendbirdPolling = (view) => {
  const dispatch = useDispatch();
  const initialPollingIntervalRef = useRef(null);
  const longPollingIntervalRef = useRef(null);
  const timeoutRef = useRef(null);

  const startInitialPolling = () => {
    dispatch(getSendbirdUnreadCount());

    initialPollingIntervalRef.current = setInterval(() => {
      dispatch(getSendbirdUnreadCount());
    }, 10000);

    logger.log("sendbirdPolling initialPolling + 5s interval");

    timeoutRef.current = setTimeout(() => {
      clearInterval(initialPollingIntervalRef.current);
      startLongPolling();
    }, 300000);
  };

  const startLongPolling = () => {
    dispatch(getSendbirdUnreadCount());

    longPollingIntervalRef.current = setInterval(() => {
      dispatch(getSendbirdUnreadCount());
    }, 300000);

    logger.log("sendbirdPolling longPolling + 5m interval");
  };

  const clearPolling = () => {
    if (initialPollingIntervalRef.current) {
      clearInterval(initialPollingIntervalRef.current);
      initialPollingIntervalRef.current = null;
    }

    if (longPollingIntervalRef.current) {
      clearInterval(longPollingIntervalRef.current);
      longPollingIntervalRef.current = null;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (
        document.visibilityState === "visible" &&
        view?.find((v) => v.type === "instantMessaging")
      ) {
        clearPolling();
        startInitialPolling();
      } else {
        clearPolling();
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    // Initial check
    if (
      document.visibilityState === "visible" &&
      view?.find((v) => v.type === "instantMessaging")
    ) {
      startInitialPolling();
    }

    return () => {
      clearPolling();
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, view]);

  return null;
};

export default useSendbirdPolling;
