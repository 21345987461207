import { Channel, sendbirdSelectors, useSendbirdStateContext } from "@sendbird/uikit-react";
import { useEffect, useState } from "react";
import CustomChannelSettings from "./CustomChannelSettings";
import CustomChannelList from "./CustomChannelList";
import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context";
import { ChannelSettingsProvider } from "@sendbird/uikit-react/ChannelSettings/context";
import LeaveChannelModal from "./LeaveChannelModal";
import { useParams, useHistory } from "react-router-dom";
import CustomInput from "./CustomInput";
import { Grid } from "antd";
const SET_CURRENT_CHANNEL = "SET_CURRENT_CHANNEL";

function ImApp({ setQuery }) {
  const { userId } = useParams();
  const history = useHistory();
  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);
  const channelListContext = useChannelListContext();
  const screens = Grid.useBreakpoint();

  const setCurrentChannel = (channel) => {
    channelListContext.channelListDispatcher({
      type: SET_CURRENT_CHANNEL,
      payload: channel,
    });
  };

  const [showSettings, setShowSettings] = useState(false);
  const [showLeaveChannelModal, setShowLeaveChannelModal] = useState(false);
  const [showContactList, setShowContactList] = useState(window.innerWidth > 992);

  //TODO traduction: remove unused vars (wait for final version)
  //TODO finaliser modal de modification de channel custom

  //create new conversation from contactList
  useEffect(() => {
    if (!userId || !sdkInstance?.groupChannel || !channelListContext.channelListQuery) return;
    const fetchChannelListLoop = async () => {
      while (
        channelListContext.channelListQuery.hasNext &&
        !channelListContext.channelListQuery.isLoading
      ) {
        await channelListContext.fetchChannelList();
      }
      return true;
    };

    const createDiscussion = async () => {
      const channels = channelListContext.allChannels.filter(
        (channel) =>
          channel.members.length === 2 &&
          channel.members.find((member) => member.userId === userId) &&
          channel.members.find((member) => member.userId === sdkInstance?.currentUser?.userId),
      );
      if (channels.length > 0) {
        channelListContext.channelListDispatcher({
          type: SET_CURRENT_CHANNEL,
          payload: channels[0],
        });
        history.replace("/instantMessaging");
        return;
      } else {
        const params = {
          invitedUserIds: [userId],
          isDistinct: true,
          operatorUserIds: [sdkInstance?.currentUser?.userId],
        };
        const newChannel = await sdkInstance.groupChannel.createChannel(params);
        channelListContext.channelListDispatcher({
          type: SET_CURRENT_CHANNEL,
          payload: newChannel,
        });
        history.replace("/instantMessaging");
        return;
      }
    };

    const runChannelOperations = async () => {
      await fetchChannelListLoop();
      await createDiscussion();
    };
    runChannelOperations();

    /* sendbird way (problem with operator attribution)
    const createDiscussion = async () => {
      let channel;

      try {
        if (!userId || !sdkInstance?.groupChannel) return;
        channel = await sdkInstance.groupChannel.createChannelWithUserIds([userId], true);
        logger.log("Channel créé :", channel);

        channelListContext.channelListDispatcher({
          type: SET_CURRENT_CHANNEL,
          payload: channel,
        });
      } catch (error) {
        logger.error("Une erreur s'est produite :", error);
      } finally {
        if (!channel) return;
        await channel.addOperators([sdkInstance.currentUser?.userId]);
        history.replace("/instantMessaging");
      }
    };

    createDiscussion();*/
  }, [
    channelListContext,
    globalStore,
    history,
    sdkInstance?.currentUser?.userId,
    sdkInstance.groupChannel,
    userId,
  ]);

  useEffect(() => {
    // monitor screen width. If screen width > 992px, show contact list
    const handleResize = () => {
      if (window.innerWidth > 992) {
        setShowContactList(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <ChannelSettingsProvider
      channelUrl={channelListContext?.currentChannel?.url}
      className="fullwidth"
    >
      <div
        className={`channel-wrap ${showContactList ? "leftPanelExpanded" : "leftPanelCollapsed"}`}
        style={{ width: "100%", height: "100%" }}
      >
        <div
          className="channel-list"
          style={{ height: "100%", width: showContactList ? "unset" : "94px" }}
        >
          <CustomChannelList
            setCurrentChannel={setCurrentChannel}
            setQuery={setQuery}
            setShowLeaveChannelModal={setShowLeaveChannelModal}
            showContactList={showContactList}
            setShowContactList={setShowContactList}
          />
        </div>

        <div
          className="channel-chat"
          style={{
            width: showSettings && screens.lg ? "calc(100% - 320px)" : "100%",
            cssFloat: showSettings ? "left" : "none",
            minWidth: "120px",
          }}
        >
          <Channel
            channelUrl={channelListContext?.currentChannel?.url}
            onChatHeaderActionClick={() => setShowSettings(!showSettings)}
            renderMessageInput={() => {
              return <CustomInput />;
            }}
          />
        </div>

        {showSettings && (
          <div
            className="channel-settings"
            style={{
              minWidth: "320px",
              width: "320px",
              backgroundColor: "white",
              height: "100%",
              right: 0,
              position: screens.lg ? "unset" : "fixed",
              borderLeft: screens.lg ? "unset" : "1px solid var(--sendbird-light-onlight-04)",
            }}
          >
            <CustomChannelSettings
              setShowSettings={setShowSettings}
              setCurrentChannel={setCurrentChannel}
              setShowLeaveChannelModal={setShowLeaveChannelModal}
              setQuery={setQuery}
            />
          </div>
        )}
      </div>
      {showLeaveChannelModal && (
        <LeaveChannelModal
          showLeaveChannelModal={showLeaveChannelModal}
          setShowLeaveChannelModal={setShowLeaveChannelModal}
          setCurrentChannel={setCurrentChannel}
          setQuery={setQuery}
        />
      )}
    </ChannelSettingsProvider>
  );
}

export default ImApp;
