import { Card, Form, Select } from "antd";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

const SiteSelector = ({ width }) => {
  const { colors } = useDesignTokens();
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const site = urlParams.get("site");

  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  return (
    <Card style={{ width }} bodyStyle={{ display: "flex" }}>
      <Glyph name="business" className={"secondary"} />
      <Form initialValues={{ siteId: parseInt(site) }}>
        <Form.Item noStyle name={["siteId"]} rules={[{ required: true }]}>
          <Select
            className="accent"
            bordered={false}
            size={"middle"}
            style={{
              width: width - 55,
              marginTop: -8,
              marginBottom: -8,
              color: colors.secondary_base + " !important",
            }}
            options={sites.map((s) => ({
              label: s.title,
              value: s.id,
            }))}
            onChange={(e) => {
              history.push({ search: `?site=${e}` });
            }}
          />
        </Form.Item>
      </Form>
    </Card>
  );
};

export default SiteSelector;
