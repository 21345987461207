import { Button, Typography } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Glyph from "../../Common/Glyph/Glyph";
import styles from "../Dashboard/Dashboard.module.less";
import useDesignTokens from "../../../hook/useDesignTokens";
import {
  getAllRoomFastbooking,
  getRoomFastbooking,
} from "../../../services/redux/services/FastbookingWS";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "ramda";

const Widget = () => {
  const { t, i18n } = useTranslation();
  const { colors, size } = useDesignTokens();
  const dispatch = useDispatch();

  const allRoomFastbooking = useSelector((state) => state.fastbookingWS.allRoomFastbooking);
  const userData = useSelector((state) => state.userWS.userData);
  const planning = useSelector((state) => state.planningWS.planning);

  const planningToDay = planning?.slots?.items
    .filter((i) => moment(i.startDate).format("DD/MM/YYYY") === moment().format("DD/MM/YYYY"))
    .map((item) => {
      return item;
    });

  const bookingRoom = planningToDay
    ?.filter((p) => p.resources.length !== 0 && moment(p.endDate) >= moment())
    ?.filter((r) => r.isOrganizer === true);

  useEffect(() => {
    dispatch(
      getRoomFastbooking({
        locale: i18n.language.split("-")[0],
        startDate: moment().toISOString(),
      }),
    );
  }, [dispatch, i18n.language]);

  useEffect(() => {
    isEmpty(allRoomFastbooking) &&
      !isEmpty(userData) &&
      dispatch(
        getAllRoomFastbooking({
          locale: i18n.language.split("-")[0],
          startDate: moment().toISOString(),
        }),
      );
  }, [allRoomFastbooking, dispatch, i18n, userData]);

  return bookingRoom?.length !== 0 ? (
    <>
      <Typography.Title style={{ fontSize: "56px", margin: "0" }}>
        {bookingRoom?.length}
        <Typography.Text strong style={{ fontSize: size.l, marginLeft: "8px" }}>
          {bookingRoom?.length <= 1 ? t("booking") : t("bookings")}
        </Typography.Text>
      </Typography.Title>
      <div style={{ display: "flex", overflow: "auto" }}>
        {bookingRoom?.map((booking) => (
          <Typography.Text
            className={styles["room"]}
            style={{ marginBottom: "8px", padding: "5px" }}
          >
            <Glyph name="meeting_room" style={{ verticalAlign: "-2px", marginRight: "8px" }} />
            {booking?.locations[0]?.title}
          </Typography.Text>
        ))}
      </div>
    </>
  ) : (
    <>
      <Typography.Title style={{ fontSize: "56px", margin: "0", color: colors.pending_light }}>
        {bookingRoom?.length}
        <Typography.Text strong style={{ fontSize: size.l, marginLeft: "8px" }}>
          {t("booking")}
        </Typography.Text>
      </Typography.Title>
      <Button ghost style={{ width: "100%" }}>
        <Link to="/fastBooking">{t("fast_booking_title", { ns: "csv" })}</Link>
      </Button>
    </>
  );
};

export default Widget;
