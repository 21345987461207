import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Switch } from "react-router-dom";
import { Redirect, Route } from "react-router-dom/cjs/react-router-dom.min";
import useView from "../../../hook/useView";
import { TitleSource } from "../../Common/Teleporters/Title";
import MapViewContent from "./MapView";
import SpaceBookingResident from "./SpaceBookingResident";

export const baseName = "/spaceBooking";

export const RECURRENCE = {
  EVERY_DAY: "EVERY_DAY",
  EVERY_WEEK: "EVERY_WEEK",
  EVERY_2_WEEK: "EVERY_2_WEEK",
  EVERY_MONTH: "EVERY_MONTH",
};

const SpaceBooking = () => {
  const { t } = useTranslation();
  const view = useView();

  logger.log("view", view);

  return (
    <>
      {view && <TitleSource>{t(view.title, { ns: "csv" })}</TitleSource>}

      <Switch>
        <Route path={`${baseName}/map`} component={MapViewContent} />
        <Route path={`${baseName}/`} component={SpaceBookingResident} />
        <Redirect to={`${baseName}/`} />
      </Switch>
    </>
  );
};

export default SpaceBooking;
