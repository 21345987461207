import { Button, Card, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  createTicketReferential,
  getAdminReferential,
  getAllReferentialsAdmin,
  updateTicketReferential,
} from "../../../../../services/redux/services/TicketingV2WS";
import Glyph from "../../../../Common/Glyph/Glyph";
import CreateRefModal from "../../Component/Modals/CreateRefModal";
import EditReferentialLeft from "./EditReferentialLeft";
import EditReferentialRight from "./EditReferentialRight";

const AdminReferential = ({ section }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const { siteId, stuffId } = useParams();

  const referential = useSelector((state) => state.ticketingV2WS.referentialsAdmin);

  const [tempData, setTempData] = useState({});
  const [type, setType] = useState(null);
  const [showCreateRefModal, setShowCreateRefModal] = useState(false);
  const [saveEnabled, setSaveEnabled] = useState(false);
  const [leftCardWidth, setLeftCardWidth] = useState("25%");
  const [reference, setReference] = useState(null);

  const handleCreateRef = (t) => {
    setType(t);
    setShowCreateRefModal(true);
  };

  const fetchData = async () => {
    try {
      await dispatch(getAllReferentialsAdmin({ siteId, locale }));
    } catch (error) {
      logger.error("Error fetching data:", error);
    }
  };

  const createReferential = async (modalData) => {
    const data = {
      type: modalData.type,
      icon: modalData.icon,
      label: modalData.label,

      ...(modalData.type === "subCategory" &&
        tempData.type === "subCategory" && {
          parentTypeId: tempData.parentTypeId,
          parentCategoryId: tempData.parentCategoryId,
        }),

      ...(modalData.type === "subCategory" &&
        tempData.type === "category" && {
          parentTypeId: tempData.parentTypeId,
          parentCategoryId: tempData.id,
        }),

      ...(modalData.type === "category" &&
        tempData.type === "category" && { parentTypeId: tempData.parentTypeId }),

      ...(modalData.type === "category" &&
        tempData.type === "type" && { parentTypeId: tempData.id }),

      ...(modalData.type === "category" &&
        tempData.type === "subCategory" && {
          parentTypeId: tempData.parentTypeId,
        }),
    };

    try {
      const createRef = await dispatch(
        createTicketReferential({
          siteId: siteId,
          data,
        }),
      );

      if (createRef) {
        fetchData();
        //setTempData(createRef.payload);
        setShowCreateRefModal(false);
      }
    } catch (error) {
      logger.error("Error creating referential:", error);
    }
  };

  const updateReferential = async (type) => {
    const data =
      type === "ref"
        ? {
            icon: tempData.icon,
            label: tempData.label,
            groups: {
              agent:
                tempData.type === "type" ||
                tempData.groups?.agent?.length !== tempData.groups.agent?.ids?.length
                  ? tempData.groups?.agent
                  : [],
              user:
                tempData.type === "type" ||
                tempData.groups?.user?.length !== tempData.groups.user?.ids?.length
                  ? tempData.groups?.user
                  : [],
              manager:
                tempData.type === "type" ||
                tempData.groups?.manager?.length !== tempData.groups.manager?.ids?.length
                  ? tempData.groups?.manager
                  : [],
            },
            spaceTypes:
              tempData.type === "type" ||
              tempData.spaceTypes?.length !== tempData.oldSpaceTypes?.ids?.length
                ? tempData.spaceTypes
                : [],
            form: tempData.form,
          }
        : {
            enabled: !tempData.enabled,
          };

    try {
      const updateRef = await dispatch(
        updateTicketReferential({
          siteId: siteId,
          data,
          stuffId,
        }),
      );

      if (updateRef) {
        fetchData();
        //setTempData(updateRef.payload);
      }
    } catch (error) {
      logger.error("Error updating referential:", error);
    }
  };

  // resize left/right cards
  const handleMouseDown = (e) => {
    e.preventDefault();
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e) => {
    const newWidth = `${((e.clientX / window.innerWidth) * 100).toFixed(2)}%`;
    setLeftCardWidth(newWidth);
  };

  const handleMouseUp = () => {
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  // create a reference for enabling/disabling save button
  useEffect(() => {
    if (!referential) return;
    function findElementById(reference, id) {
      for (const element of reference) {
        if (element.id === id) return element;

        if (!!element?.categories?.length) {
          const categoryResult = findElementById(element.categories, id);
          if (categoryResult) return categoryResult;
        }

        if (!!element?.subCategories?.length) {
          const subCategoryResult = findElementById(element.subCategories, id);
          if (subCategoryResult) return subCategoryResult;
        }
      }

      return null;
    }
    const refData = findElementById(referential, parseInt(stuffId));
    setReference({
      ...refData,
      groups: {
        agent: refData?.groups?.agent?.ids,
        user: refData?.groups?.user?.ids,
        manager: refData?.groups?.manager.ids,
      },
      oldSpaceTypes: refData?.spaceTypes,
      spaceTypes: refData?.spaceTypes?.ids,
    });

    setTempData({
      ...refData,
      groups: {
        agent: refData?.groups?.agent?.ids,
        user: refData?.groups?.user?.ids,
        manager: refData?.groups?.manager.ids,
      },
      oldSpaceTypes: refData?.spaceTypes,
      spaceTypes: refData?.spaceTypes?.ids,
    });
  }, [referential, stuffId, dispatch, siteId]);

  // get tickets for referential (allow "delete only if no tickets are linked to the referential")
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getAdminReferential({ siteId, stuffId }));
      } catch (error) {
        logger.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dispatch, stuffId, siteId]);

  // if tempData is different from referential, enable save button
  useEffect(() => {
    if (referential && tempData) {
      const tempDataString = JSON.stringify(tempData);
      const referentialString = JSON.stringify(reference);
      if (tempDataString !== referentialString) {
        if (!saveEnabled) setSaveEnabled(true);
      } else {
        if (saveEnabled) setSaveEnabled(false);
      }
    }
  }, [reference, referential, saveEnabled, tempData]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 20,
          padding: "0 24px 24px 24px",
          height: "100%",
          overflowY: "auto",
          position: "relative",
        }}
      >
        {/* header */}
        <div style={{ display: "flex", justifyContent: "space-between", marginTop: 18 }}>
          <div style={{ display: "flex", flexDirection: "column", marginRight: 380 }}>
            <Typography.Text strong style={{ fontSize: 24 }}>
              {t("ticketingv2.referential")}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              style={{ fontSize: 12, lineHeight: "16px", textWrap: "balance" }}
            >
              {t("ticketingv2.referentialComment")}
            </Typography.Text>
          </div>
          <div style={{ display: "flex", gap: 8, position: "absolute", right: 32 }}>
            {/* <Button
              type="secondary"
              style={{ display: "flex", gap: 8, padding: "12px 27px" }}
              onClick={() => handleCreateRef("type")}
            >
              <Glyph name="add" />
              Nouveau Type
            </Button> */}
            <Button
              type="primary"
              disabled={!saveEnabled}
              style={{
                display: "flex",
                gap: 8,
                padding: 12,
                right: 0,
              }}
              onClick={() => updateReferential("ref")}
            >
              <Glyph name="save" />
              {saveEnabled && t("ticketingv2.save")}
            </Button>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            height: "100%",
            padding: 15,
            margin: -15,
            overflowY: "scroll",
          }}
        >
          <Card style={{ width: leftCardWidth, height: "100%" }} bodyStyle={{}}>
            <EditReferentialLeft
              referential={referential}
              selectedRef={tempData}
              handleCreateRef={handleCreateRef}
              saveEnabled={saveEnabled}
              section={section}
            />
          </Card>
          <div
            style={{
              width: 8,
              height: "100%",
              backgroundColor: "transparent",
              cursor: "col-resize",
            }}
            onMouseDown={handleMouseDown}
          />

          <Card style={{ width: `calc(100% - ${leftCardWidth})`, height: "100%" }}>
            <EditReferentialRight
              referential={referential}
              tempData={tempData}
              setTempData={setTempData}
              updateReferential={updateReferential}
            />
          </Card>
        </div>
      </div>
      {showCreateRefModal && (
        <CreateRefModal
          showCreateRefModal={showCreateRefModal}
          setShowCreateRefModal={setShowCreateRefModal}
          type={type}
          createReferential={createReferential}
        />
      )}
    </>
  );
};

export default AdminReferential;
