import React from "react";
import useDesignTokens from "../../../hook/useDesignTokens";
import { Popover } from "antd";
import Glyph from "../Glyph/Glyph";

const IconPicker = ({ icon, setIcon, target, color }) => {
  const { colors } = useDesignTokens();

  const iconSelection = [
    "search",
    "account_circle",
    "done",
    "info",
    "visibility",
    "description",
    "lock",
    "schedule",
    "event",
    "dashboard",
    "list",
    "question_answer",
    "lightbulb",
    "help",
    "verified_user",
    "assignment",
    "star_rate",
    "build",
    "work",
    "print",
    "place",
    "shopping_basket",
    "dns",
    "bug_report",
    "pan_tool",
    "accessibility_new",
    "announcement",
    "restore",
    "offline_bolt",
    "hourglass_full",
    "person",
    "group",
    "support_agent",
    "wc",
    "woman",
    "man",
    "accessible",
    "phone_iphone",
    "computer",
    "desktop_windows",
    "devices",
    "local_hospital",
    "phone",
    "directions_car",
    "local_parking",
    "elevator",
    "stairs",
    "public",
    "construction",
    "save",
  ];

  const iconPicker = (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "40px 40px 40px 40px 40px 40px 40px",
        alignItems: "center",
        alignSelf: "center",
        justifySelf: "center",
        justifyContent: "center",
      }}
    >
      {iconSelection.map((value) => {
        return (
          <div
            key={value}
            onClick={() => setIcon(value)}
            style={{
              cursor: "pointer",
              minWidth: 40,
              minHeight: 40,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Glyph
              name={value}
              style={{ color: value === icon ? colors.primary_base : color, fontSize: 20 }}
            />
          </div>
        );
      })}
    </div>
  );

  return <Popover content={iconPicker}>{target}</Popover>;
};

export default IconPicker;
