import React from "react";
import Glyph from "../../../../Common/Glyph/Glyph";
import { Card, Popover, Typography, notification } from "antd";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { useTranslation } from "react-i18next";

const FormDefault = ({ newTicket, setNewTicket, attachements, setAttachements }) => {
  const { colors, symbols } = useDesignTokens();
  const { t } = useTranslation();

  const handleAddAttachement = (e, type) => {
    const params = {};
    params.file = e.target.files[0];
    logger.log(params.file.type);

    const allowed =
      type === "files"
        ? ["image", "audio", "video"].includes(params.file.type.split("/")[0]) ||
          params.file.type === "application/pdf"
        : ["image", "video"].includes(params.file.type.split("/")[0]);

    if (!allowed) {
      e.target.value = "";
      notification.open({
        message: t("ticketingv2.InvalidFileType"),
      });
      return;
    }

    if (params.file.size > 25000000) {
      e.target.value = "";
      notification.open({
        message: t("ticketingv2.FileTooLarge"),
      });
      return;
    }

    setAttachements({
      ...attachements,
      [type]: [...attachements[type], params.file],
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 8,
        gap: 8,
        alignItems: "start",
        width: "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
          <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 16 }}>
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t("ticketingv2.Photos")}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {t("ticketingv2.AddPhotoCaption")}
            </Typography.Text>
          </div>
          <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
            <input
              type="file"
              accept={"image/*, video/*"}
              disabled={attachements.pictures.length >= 3}
              onChange={(e) => {
                handleAddAttachement(e, "pictures");
                e.target.value = null;
              }}
              style={{ display: "none" }}
              id="photoInput"
            />
            <label htmlFor="photoInput">
              <Card
                style={{
                  width: 137,
                  height: 112,
                  cursor: attachements.pictures.length >= 3 ? "default" : "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <Glyph
                    name="add_a_photo"
                    style={{
                      fontSize: 28,
                      color:
                        attachements.pictures.length >= 3 ? colors.grey_40 : colors.secondary_base,
                    }}
                  />
                  <Typography.Text
                    strong
                    style={{
                      fontSize: 12,
                      textAlign: "center",
                      color: attachements.pictures.length >= 3 && colors.grey_40,
                    }}
                  >
                    {t("ticketingv2.AddPhoto")}
                  </Typography.Text>
                </div>
              </Card>
            </label>
            {attachements.pictures.map((image, index) => (
              <Popover content={image.name} trigger="hover" key={image}>
                <Card
                  style={{ width: 136, height: 112, position: "relative", overflow: "visible" }}
                  bodyStyle={{
                    padding: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={URL.createObjectURL(image)}
                    alt="uploaded pic"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      borderRadius: symbols.base_shape.radius,
                    }}
                  />

                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      top: -5,
                      right: -5,
                      cursor: "pointer",
                      width: 18,
                      height: 18,
                      borderRadius: "50%",
                    }}
                  >
                    <Glyph
                      name="cancel"
                      onClick={() =>
                        setAttachements({
                          ...attachements,
                          pictures: attachements.pictures.filter((_, i) => i !== index),
                        })
                      }
                      style={{
                        color: colors.primary_base,
                        fontSize: 16,
                        position: "absolute",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Card>
              </Popover>
            ))}
          </div>
          <div style={{ display: "flex", flexDirection: "column", width: "100%", marginTop: 16 }}>
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t("ticketingv2.Files")}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {t("ticketingv2.FilesCaption")}
            </Typography.Text>
          </div>
          <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
            <input
              type="file"
              disabled={attachements.files.length >= 3}
              accept={"application/pdf, image/*, video/*, audio/*"}
              onChange={(e) => {
                handleAddAttachement(e, "files");
                e.target.value = null;
              }}
              style={{ display: "none" }}
              id="fileInput"
            />
            <label htmlFor="fileInput">
              <Card
                style={{
                  width: 137,
                  height: 112,
                  cursor: attachements.files.length >= 3 ? "default" : "pointer",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 8,
                  }}
                >
                  <Glyph
                    name="cloud_upload"
                    style={{
                      fontSize: 28,
                      color:
                        attachements.files.length >= 3 ? colors.grey_40 : colors.secondary_base,
                    }}
                  />
                  <Typography.Text
                    strong
                    style={{
                      fontSize: 12,
                      textAlign: "center",
                      lineHeight: 1.2,
                      color: attachements.files.length >= 3 && colors.grey_40,
                    }}
                  >
                    {t("ticketingv2.AddFile")}
                  </Typography.Text>
                </div>
              </Card>
            </label>
            {attachements.files.map((file, index) => (
              <Popover content={file.name} trigger="hover" key={file}>
                <Card
                  style={{ width: 136, height: 112, position: "relative", overflow: "visible" }}
                  bodyStyle={{
                    padding: 0,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {file.type.split("/")[0] === "image" ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt="uploaded pic"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: symbols.base_shape.radius,
                      }}
                    />
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: 8,
                      }}
                    >
                      <Glyph
                        name="insert_drive_file"
                        style={{
                          fontSize: 28,
                          color: colors.grey_60,
                        }}
                      />
                      <Typography.Text
                        strong
                        style={{ fontSize: 12, textAlign: "center", lineHeight: 1.2 }}
                      >
                        {file.name}
                      </Typography.Text>
                    </div>
                  )}

                  <div
                    style={{
                      position: "absolute",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: "white",
                      top: -5,
                      right: -5,
                      cursor: "pointer",
                      width: 18,
                      height: 18,
                      borderRadius: "50%",
                    }}
                  >
                    <Glyph
                      name="cancel"
                      onClick={() =>
                        setAttachements({
                          ...attachements,
                          files: attachements.files.filter((_, i) => i !== index),
                        })
                      }
                      style={{
                        color: colors.primary_base,
                        fontSize: 16,
                        position: "absolute",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                </Card>
              </Popover>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormDefault;
