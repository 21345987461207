import React from "react";
import { Button, Card, Segmented, Tooltip, Typography } from "antd";
import { useEffect, useState } from "react";
import Glyph from "../../../Common/Glyph/Glyph";
import useDesignTokens from "../../../../hook/useDesignTokens";
import TicketViewRight from "./Component/TicketViewRight";
import TicketTabConversation from "./Component/TicketTabConversation";
import TicketTabGeneral from "./Component/TicketTabGeneral";
import DeleteTicketModal from "../Component/Modals/DeleteTicketModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllFloors, getTicketDetails } from "../../../../services/redux/services/TicketingV2WS";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import CreateOrEditTicketModal from "../Component/Modals/CreateOrEditTicketModal";
import RateModal from "../Component/Modals/RateModal";
import TicketSkeleton from "./Component/TicketSkeleton";
import MarkAsResolvedModal from "../Component/Modals/MarkAsResolvedModal";

const TicketView = ({ section }) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const dispatch = useDispatch();
  const { siteId, stuffId } = useParams();
  const { colors } = useDesignTokens();

  const ticketDetails = useSelector((state) => state.ticketingV2WS.ticketDetails);
  const userId = useSelector((state) => state.userWS.userData.profile.id);
  const views = useSelector((state) => state.userWS.userData.views);
  const currentViewId = useSelector((state) => state.ticketingV2WS.currentViewId);

  const viewIdByRights =
    views.find((v) => v.id === currentViewId)?.ticketingViews?.agent ||
    (views.find((v) => v.id === currentViewId)?.type === "ticketingV2Agent" && currentViewId) ||
    (views.find((v) => v.id === currentViewId)?.type === "ticketingV2Manager" && currentViewId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const rights = {
    manager: ticketDetails?.isManager,
    agent: userId === ticketDetails?.agent?.id,
  };

  const referentialUser = useSelector((state) => state.ticketingV2WS?.referential);
  const referentialManager = useSelector(
    (state) => state.ticketingV2WS.referentialsManager?.referential,
  );
  const referentialAgent = useSelector(
    (state) => state.ticketingV2WS.referentialsAgent?.referential,
  );

  //! unifier les referentiels !!!

  const referential = rights.manager
    ? referentialManager
    : rights.agent
    ? referentialAgent
    : referentialUser;

  const [segment, setSegment] = useState("general");
  const [showDeleteTicketModal, setShowDeleteTicketModal] = useState(false);
  const [showCreateOrEditTicketModal, setShowCreateOrEditTicketModal] = useState(false);
  const [showMarkAsResolvedModal, setShowMarkAsResolvedModal] = useState(false);
  const [showRateModal, setShowRateModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    logger.log("rights", rights);
  }, [rights]);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        await dispatch(
          getTicketDetails({
            siteId,
            locale: locale,
            ticketId: stuffId,
          }),
        );
        await dispatch(getAllFloors({ locale, siteId }));
        setIsLoading(false);
      } catch (error) {
        logger.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [dispatch, locale, stuffId, siteId]);

  return isLoading ? (
    <TicketSkeleton rights={rights} />
  ) : (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          padding: "0 14px",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 24,
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", marginTop: -4 }}>
            <Typography.Text strong style={{ fontSize: 17, fontWeight: "bold", marginBottom: 8 }}>
              {ticketDetails.title?.toUpperCase()}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 14 }}>
              {t("ticketingv2.TicketId_")}
              {ticketDetails.id}
            </Typography.Text>
          </div>
          <div style={{ display: "flex", gap: 13 }}>
            {/* <Button type="secondary" style={{ height: 50, width: 50 }}>
            <Glyph name="notifications" />
          </Button> */}
            {rights.agent && (
              <Tooltip title="Role: Agent">
                <Button type="primary" disabled={true} icon={<Glyph name="assignment" />} />
              </Tooltip>
            )}
            {rights.manager && (
              <Tooltip title="Role: Manager">
                <Button type="primary" disabled={true} icon={<Glyph name="assignment_ind" />} />
              </Tooltip>
            )}
            {ticketDetails.requester.id === userId && !ticketDetails?.status?.isCanceled && (
              <Button
                type="tertiary"
                style={{ display: "flex", gap: 8 }}
                onClick={() => setShowDeleteTicketModal(true)}
              >
                <Glyph name="cancel" />
                {t("ticketingv2.CancelTicket")}
              </Button>
            )}
            {ticketDetails.requester.id === userId && ticketDetails?.status?.isNew && (
              <Button
                type="primary"
                style={{ display: "flex", gap: 8 }}
                onClick={() => setShowCreateOrEditTicketModal(true)}
              >
                <Glyph name="edit" />
                {t("ticketingv2.Modify")}
              </Button>
            )}

            {
              //! probleme dans le selecteur de referential
              ticketDetails.requester.id === userId &&
                ticketDetails?.status?.isCanceled &&
                (ticketDetails?.rating ? (
                  <Button type="secondary" onClick={() => setShowRateModal(true)}>
                    <div style={{ display: "flex", gap: 8 }}>
                      {Array.from({ length: ticketDetails?.rating }, () => (
                        <Glyph name="star" style={{ color: "white" }} />
                      ))}
                      {Array.from({ length: 5 - ticketDetails?.rating }, () => (
                        <Glyph name="star_border" style={{ color: "white" }} />
                      ))}
                    </div>
                  </Button>
                ) : (
                  <Button type="primary" onClick={() => setShowRateModal(true)}>
                    <div style={{ display: "flex", gap: 8 }}>
                      <Glyph name="star_border" />
                      {t("ticketingv2.Evaluate")}
                    </div>
                  </Button>
                ))
            }
            {(rights.manager || rights.agent) &&
              !ticketDetails?.status?.isClosed &&
              !ticketDetails?.status?.isCanceled && (
                <Button
                  type="primary"
                  style={{ display: "flex", gap: 8 }}
                  onClick={() => setShowMarkAsResolvedModal(true)}
                >
                  <Glyph name="task_alt" />
                  {t("ticketingv2.MarkAsResolved")}
                </Button>
              )}
          </div>
        </div>
        {/* {(rights.manager || rights.agent) && (
          <Card
            onClick={() => setShowMarkAsResolvedModal(true)}
            style={{ cursor: "pointer", width: "fit-content" }}
            bodyStyle={{ overflow: "hidden" }}
          >
            <Glyph name="task_alt" className={"secondary"} />
            <Typography.Text strong style={{ color: "black", marginLeft: "10px" }}>
              {t("ticketingv2.MarkAsResolved")}
            </Typography.Text>
          </Card>
        )} */}
        <div
          style={{
            display: "flex",
            gap: 8,
            flexGrow: 1,
            marginBottom: 12,
            height: `calc(100% - ${!rights.manager && !rights.agent ? "130px" : "165px"})`,
          }}
        >
          <Card
            style={{ width: "100%", height: "100%" }}
            bodyStyle={{ padding: 0, overflowY: "auto" }}
          >
            <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
              <Segmented
                size="large"
                style={{
                  margin: 16,
                  backgroundColor: colors.grey_20,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                value={segment}
                options={[
                  {
                    label: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                          justifyContent: "center",
                        }}
                      >
                        <Glyph
                          name="description"
                          style={{ color: colors.primary_base, fontSize: 20 }}
                        />
                        <Typography.Text
                          strong={segment === "general" ? true : false}
                          style={{
                            margin: "0",
                            fontSize: 12,
                            color: colors.primary_dark,
                          }}
                        >
                          {t("ticketingv2.Summary")}
                        </Typography.Text>
                      </div>
                    ),
                    value: "general",
                  },
                  {
                    label: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                          justifyContent: "center",
                        }}
                      >
                        <Glyph name="chat" style={{ color: colors.primary_base, fontSize: 20 }} />
                        <Typography.Text
                          strong={segment === "conversation" ? true : false}
                          style={{
                            margin: "0",
                            fontSize: 12,
                            color: colors.primary_dark,
                          }}
                        >
                          {t("ticketingv2.Conversation")}
                        </Typography.Text>
                      </div>
                    ),
                    value: "conversation",
                  },
                ]}
                onChange={(value) => {
                  setSegment(value);
                }}
              />
            </div>
            {segment === "general" ? (
              <TicketTabGeneral
                data={ticketDetails}
                showCreateOrEditTicketModal={showCreateOrEditTicketModal}
              />
            ) : (
              <TicketTabConversation
                data={ticketDetails}
                locale={locale}
                rights={rights}
                referential={referential}
              />
            )}
          </Card>

          <TicketViewRight
            ticketDetails={ticketDetails}
            rights={rights}
            viewIdByRights={viewIdByRights}
            referential={referential}
          />
        </div>
        {showDeleteTicketModal && (
          <DeleteTicketModal
            ticketData={ticketDetails}
            showDeleteTicketModal={showDeleteTicketModal}
            setShowDeleteTicketModal={setShowDeleteTicketModal}
            section={section}
          />
        )}
      </div>
      {/* Edit ticket Modal */}
      {showCreateOrEditTicketModal && (
        <CreateOrEditTicketModal
          showCreateOrEditTicketModal={showCreateOrEditTicketModal}
          setShowCreateOrEditTicketModal={setShowCreateOrEditTicketModal}
          siteId={siteId}
          locale={locale}
          ticketDetails={ticketDetails}
          section={section}
        />
      )}
      {/* Rate Modal */}
      {showRateModal && (
        <RateModal
          showRateModal={showRateModal}
          setShowRateModal={setShowRateModal}
          locale={locale}
          data={ticketDetails}
        />
      )}
      {/* Mark as resolved Modal */}
      {showMarkAsResolvedModal && (
        <MarkAsResolvedModal
          showMarkAsResolvedModal={showMarkAsResolvedModal}
          setShowMarkAsResolvedModal={setShowMarkAsResolvedModal}
          siteId={siteId}
          locale={locale}
          ticketDetails={ticketDetails}
          viewIdByRights={viewIdByRights}
        />
      )}
    </>
  );
};

export default TicketView;
