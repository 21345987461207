import { Avatar, Button, Card, Form, Input, Space, Typography, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDebounce from "../../../../../../hook/useDebounce";
import useDesignTokens from "../../../../../../hook/useDesignTokens";
import { listTeamAvailability } from "../../../../../../services/redux/services/SpaceService";
import Glyph from "../../../../../Common/Glyph/Glyph";

const PAGINATION_SIZE = 50;

const UserItem = ({ user, direction = "left", onClick }) => {
  const { t } = useTranslation();
  const { colors, size } = useDesignTokens();

  const displayName = `${user.firstname} ${user.lastname}`.trim();
  const available = !user.conflicts.length;
  const canAccess = !!user.canBook;
  const canBook = available && canAccess;

  return (
    <Card
      key={user.id}
      style={{ cursor: "pointer", flexShrink: 0, opacity: canBook ? 1 : 0.5 }}
      bodyStyle={{ padding: 0 }}
      onClick={() => (canBook ? onClick?.(user) : null)}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: 10,
        }}
      >
        <Space size="middle">
          <Avatar src={user.photoUrl}>{displayName.charAt(0).toUpperCase()}</Avatar>
          <div>
            <Typography.Paragraph strong style={{ margin: 0 }}>
              {displayName}
            </Typography.Paragraph>
            <Typography.Text type="secondary">
              {!canAccess
                ? t("spas.request.members.noAccess")
                : !available
                ? t("NotAvailable")
                : t("Available")}
            </Typography.Text>
          </div>
        </Space>
        {canBook && (
          <Glyph
            name={direction === "left" ? "arrow_left" : "arrow_right"}
            style={{ color: colors.primary, fontSize: size.xxl, cursor: "pointer" }}
          />
        )}
      </div>
    </Card>
  );
};

const CreateStepTeamMembers = ({ form, initialValues }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const teamAvailability = useSelector((state) => state.spaceServiceWS.teamAvailability);

  const [search, setSearch] = useState("");
  const [displayWarning, setDisplayWarning] = useState(false);

  const searchDebounced = useDebounce(search, 500);

  const handleListTeamAvailability = useCallback(
    (page = 1) => {
      dispatch(
        listTeamAvailability({
          siteId: initialValues.siteId,
          sectorId: initialValues.sectorId,
          page,
          size: PAGINATION_SIZE,
          search: searchDebounced,
          dates: initialValues.dates?.map((date) => date.format("YYYY-MM-DD")),
          period: initialValues.period,
          startDate: initialValues.startDate?.format("YYYY-MM-DD"),
          endDate: initialValues.endDate?.format("YYYY-MM-DD"),
          recurrence: initialValues.recurrence,
        }),
      );
    },
    [
      dispatch,
      initialValues.dates,
      initialValues.endDate,
      initialValues.period,
      initialValues.recurrence,
      initialValues.sectorId,
      initialValues.siteId,
      initialValues.startDate,
      searchDebounced,
    ],
  );

  useEffect(() => {
    handleListTeamAvailability(1);
  }, [handleListTeamAvailability]);

  useEffect(() => {
    if (!displayWarning) return;
    const timeout = setTimeout(() => setDisplayWarning(false), 5000);
    return () => clearTimeout(timeout);
  }, [displayWarning]);

  const handleAllUsers = () => {
    form.setFieldsValue({
      users: (teamAvailability?.items || []).filter((u) => !u.conflicts.length && !!u.canBook),
    });
    if (teamAvailability?.items?.some((u) => u.conflicts.length)) {
      notification.warning({ message: t("spas.request.members.warning.availability") });
    }
    if (teamAvailability?.items?.some((u) => !u.canBook)) {
      notification.warning({ message: t("spas.request.members.warning.access") });
    }
  };
  const handleAddUser = (user) => {
    if (user.conflicts.length) return;
    const users = form.getFieldValue(["users"]) || [];
    form.setFieldsValue({ users: [...users, user] });
  };
  const handleRemoveUser = (user) => {
    const users = form.getFieldValue(["users"]) || [];
    form.setFieldsValue({ users: users.filter((u) => u.id !== user.id) });
  };

  return (
    <Form
      style={{ display: "flex", height: "100%" }}
      form={form}
      name={"members"}
      initialValues={initialValues}
    >
      <Form.Item hidden name={"users"} rules={[{ required: true }, { type: "array", min: 1 }]} />
      <div
        style={{
          padding: "0px 20px",
          flexBasis: "50%",
          overflowY: "auto",
          borderRight: "1px solid #ececec",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={4}>{t("spas.request.members.title")}</Typography.Title>
        <Card bordered={false} style={{ flexGrow: 1 }} bodyStyle={{ padding: 0 }}>
          <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
            <div style={{ padding: "1rem 1rem 0 1rem" }}>
              <Input
                placeholder={t("spas.request.members.search")}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {teamAvailability?.totalItems <= PAGINATION_SIZE && (
              <div
                style={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  gap: 5,
                  padding: "1rem",
                  alignSelf: "flex-end",
                }}
                onClick={handleAllUsers}
              >
                <Typography.Text strong>{t("All")}</Typography.Text>
                <Glyph name={"arrow_right"} />
              </div>
            )}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
                gap: 8,
                overflowY: "auto",
                padding: "1rem",
              }}
            >
              <Form.Item noStyle shouldUpdate={(pre, cur) => pre.users !== cur.users}>
                {({ getFieldValue }) => {
                  const value = getFieldValue("users") || [];
                  const ids = new Set(value.map((user) => user.id));
                  return teamAvailability?.items
                    .filter((user) => !ids.has(user.id))
                    .map((user) => (
                      <UserItem
                        key={user.id}
                        user={user}
                        onClick={handleAddUser}
                        direction="right"
                      />
                    ));
                }}
              </Form.Item>
              {!!teamAvailability?.nextPage && (
                <Button
                  size={"middle"}
                  block
                  type={"text"}
                  onClick={() => handleListTeamAvailability(teamAvailability.nextPage)}
                >
                  {t("SeeMore")}
                </Button>
              )}
            </div>
          </div>
        </Card>
      </div>
      <div
        style={{
          padding: "0px 20px",
          flexBasis: "50%",
          overflowY: "auto",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Form.Item noStyle shouldUpdate={(pre, cur) => pre.users !== cur.users}>
          {({ getFieldValue }) => (
            <Card bordered={false} style={{ flexGrow: 1 }} bodyStyle={{ padding: 0 }}>
              <div
                style={{ display: "flex", flexDirection: "column", height: "100%", gap: "1rem" }}
              >
                <div
                  style={{
                    padding: "1rem 1rem 0 1rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography.Title level={4} style={{ margin: 0 }}>
                    {t("spas.request.members.selected")}
                  </Typography.Title>
                  <Typography.Title className="secondary" level={4} style={{ margin: 0 }}>
                    {getFieldValue("users")?.length || 0}
                  </Typography.Title>
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    gap: 8,
                    overflowY: "auto",
                    padding: "1rem",
                  }}
                >
                  {getFieldValue("users")?.map((user) => (
                    <UserItem key={user.id} user={user} onClick={handleRemoveUser} />
                  ))}
                </div>
              </div>
            </Card>
          )}
        </Form.Item>
      </div>
    </Form>
  );
};

export default CreateStepTeamMembers;
