import React, { useEffect, useState } from "react";
import { Avatar, Card, Dropdown, Image, Typography, Switch, Tag, Popover, Spin } from "antd";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import Glyph from "../../../../Common/Glyph/Glyph";
import { useDispatch, useSelector } from "react-redux";
import TextArea from "antd/lib/input/TextArea";
import {
  addAssetToTicketComment,
  addCommentToTicket,
  deleteCommentToTicket,
  getTicketDetails,
} from "../../../../../services/redux/services/TicketingV2WS";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { slugify } from "../../../../../helpers/utils";

const TicketTabConversation = ({ data, locale, rights, referential }) => {
  const { colors, symbols } = useDesignTokens();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { siteId, stuffId } = useParams();

  const userData = useSelector((state) => state.userWS.userData);
  const allStatus = useSelector((state) => state.ticketingV2WS.status);
  const currentViewId = useSelector((state) => state.ticketingV2WS.currentViewId);
  const agents = useSelector((state) => state.ticketingV2WS.agents?.items);

  const [comment, setComment] = useState("");
  const [cardHeight, setCardHeight] = useState(80);
  const [isInternalNote, setIsInternalNote] = useState(false);
  const [postImage, setPostImage] = useState({
    files: [],
  });
  const [uploading, setUploading] = useState(false);
  const [visible, setVisible] = useState(false);

  const scrollBottom = () => {
    const conversationCards = document.querySelector(".conversationCards");
    conversationCards.scrollTop = conversationCards.scrollHeight;
  };

  useEffect(() => {
    logger.log("postImage", postImage, typeof postImage);
  }, [postImage]);

  const addComment = async () => {
    setUploading(true);
    let formData = new FormData();
    if (!!postImage.files.length) {
      postImage.files.forEach((file) => {
        formData.append(
          file.type?.split("/")[0] === "image"
            ? "img_" + slugify(file.name)
            : "file_" + slugify(file.name),
          file,
          file.name,
        );
      });
    }
    logger.log("formData", formData);

    try {
      const postComment = await dispatch(
        addCommentToTicket({
          siteId: data?.siteId,
          ticketId: data?.id,
          data: { comment: comment, internalNote: isInternalNote },
        }),
      );
      if (postComment && !!postImage.files.length) {
        await dispatch(
          addAssetToTicketComment({
            siteId: data?.siteId,
            ticketId: data?.id,
            commentId: postComment.payload.id,
            formData,
          }),
        );
      }
      await dispatch(
        getTicketDetails({
          siteId: data?.siteId,
          locale: locale,
          ticketId: data?.id,
        }),
      );
      setComment("");
      setPostImage({ files: [] });
      scrollBottom();
    } catch (error) {
      logger.error("Error sending comment:", error);
    }
    setUploading(false);
  };

  const deleteComment = async (commentId) => {
    try {
      await dispatch(deleteCommentToTicket({ siteId: data.siteId, ticketId: data.id, commentId }));
      dispatch(
        getTicketDetails({
          siteId: data.siteId,
          locale: locale,
          ticketId: data.id,
        }),
      );
    } catch (error) {
      logger.error("Error deleting comment:", error);
    }
  };

  useEffect(() => {
    setCardHeight(
      data.status.isClosed ? 0 : document.querySelector(".inputCard")?.clientHeight + 16,
    );
    scrollBottom();
  }, [postImage, comment, data.status.isClosed]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 8,
        width: "100%",
        height: "calc(100% - 80px)",
        position: "relative",
      }}
    >
      <div
        style={{
          height: "100%",
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          padding: "16px",
          gap: 8,
          marginBottom: cardHeight,
        }}
        className="conversationCards"
      >
        {data?.history?.map((h, i) => (
          <div
            key={i}
            style={{
              marginLeft:
                h.createdBy.id === userData.profile.id || h.createdBy.id === userData.profile.id
                  ? "20%"
                  : 0,
              marginRight:
                h.createdBy.id === userData.profile.id || h.createdBy.id === userData.profile.id
                  ? 0
                  : "20%",
            }}
          >
            {(() => {
              const oldAgent = agents
                ? agents.find((a) => a.id === h.oldAgent)?.firstname +
                  " " +
                  agents.find((a) => a.id === h.oldAgent)?.lastname
                : h.oldAgent;
              const newAgent = agents
                ? agents.find((a) => a.id === h.newAgent)?.firstname +
                  " " +
                  agents.find((a) => a.id === h.newAgent)?.lastname
                : data.agent
                ? data.agent?.firstname + " " + data.agent?.lastname
                : h.newAgent;

              switch (h.action) {
                case "qualification":
                case "status":
                case "priority":
                case "assigned":
                case "creation":
                case "rating":
                  return (
                    <Card
                      style={{
                        padding: 0,
                        border: `2px solid ${colors.primary_base}`,
                      }}
                    >
                      <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                        <Glyph
                          name={h.action === "creation" ? "receipt" : "receipt"}
                          style={{ fontSize: 16, color: colors.secondary_base }}
                        />
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Typography.Text strong style={{ fontSize: 14 }}>
                              {h.action === "creation"
                                ? t("ticketingv2.TicketCreated")
                                : h.action === "priority"
                                ? t("ticketingv2.TicketPriorityChangedFrom_") +
                                  h.oldPriority +
                                  t("ticketingv2._to_") +
                                  h.newPriority
                                : h.action === "status"
                                ? `${t("ticketingv2.TicketStatusChangedFrom_")} "${
                                    allStatus.find((s) => s.id === h.oldStatus)?.label
                                  }" 
                                  ${t("ticketingv2._to_")} "${
                                    allStatus.find((s) => s.id === h.newStatus)?.label
                                  }" `
                                : h.action === "qualification"
                                ? `${t("ticketingv2.TicketQualificationModifiedFrom_")} 
                                "${
                                  referential.find((r) => r.id === h.oldQualification.typeId)?.label
                                } > ${h.oldQualification.categoryId} > ${
                                    h.oldQualification.subCategoryId
                                  }"
                                  ${t("ticketingv2._to_")} "${h.newQualification.typeId} > ${
                                    h.newQualification.categoryId
                                  } > ${h.newQualification.subCategoryId}"`
                                : h.action === "rate"
                                ? `${t("ticketingv2.TicketHasBeenRated_")} ${h.rating} ★`
                                : h.action === "assigned"
                                ? h.oldAgent
                                  ? t("ticketingv2.TicketHasBeenTransferredFrom_") +
                                    oldAgent +
                                    t("ticketingv2._to_") +
                                    newAgent
                                  : t("ticketingv2.TicketHasBeenAssignedTo_") + newAgent
                                : null}
                            </Typography.Text>
                            <Typography.Text
                              type="secondary"
                              style={{ fontSize: 12, width: "fit-content", textWrap: "nowrap" }}
                            >
                              {moment(h.date).format(
                                locale === "fr" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY HH:mm",
                              )}
                            </Typography.Text>
                          </div>
                          <Typography.Text style={{ fontSize: 14 }}>
                            {t("ticketingv2.By_")}
                            {h.createdBy.id === userData.profile.id ||
                            h.createdBy.id === userData.profile.id
                              ? t("ticketingv2.You")
                              : h.createdBy?.displayName}
                          </Typography.Text>
                        </div>
                      </div>
                    </Card>
                  );

                case "comment":
                  return (
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                      }}
                    >
                      <Avatar
                        size={32}
                        src={h.createdBy?.photoUrl}
                        style={{
                          backgroundColor: colors.grey_20,
                          color: colors.primary_dark,
                          boxShadow: "none",
                        }}
                      >
                        {h.createdBy?.firstname[0]?.toUpperCase()}
                        {h.createdBy?.lastname[0]?.toUpperCase()}
                      </Avatar>
                      <Card
                        style={{
                          width: "100%",
                          [h.comment.internalNote && "boxShadow"]: "none",
                        }}
                        bodyStyle={{
                          display: "flex",
                          flexDirection: "column",
                          backgroundColor: h.comment.internalNote
                            ? colors.light_background
                            : "white",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography.Text strong style={{ fontSize: 14 }}>
                            {h.createdBy?.displayName}
                          </Typography.Text>
                          <div style={{ display: "flex", gap: 8 }}>
                            <Typography.Text style={{ fontSize: 11 }}>
                              {moment(h.date).format(
                                locale === "fr" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY HH:mm",
                              )}
                            </Typography.Text>
                            {h.createdBy?.id === userData.profile?.id && (
                              <Dropdown
                                trigger={["click"]}
                                menu={{
                                  items: [
                                    {
                                      key: "1",
                                      label: (
                                        <div
                                          style={{ cursor: "pointer" }}
                                          onClick={() => deleteComment(h.comment.id)}
                                        >
                                          {t("ticketingv2.Delete")}
                                        </div>
                                      ),
                                    },
                                  ],
                                }}
                              >
                                <Glyph
                                  name="more_horiz"
                                  style={{ fontSize: 16, cursor: "pointer" }}
                                />
                              </Dropdown>
                            )}
                          </div>
                        </div>
                        <Typography.Text style={{ fontSize: 14, hyphens: "auto" }}>
                          {h.comment.content}
                        </Typography.Text>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            gap: 8,
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          {!!h.comment.attachments?.length && (
                            <div style={{ display: "flex", flexDirection: "column", marginTop: 8 }}>
                              {/* <Glyph
                                name="attachment"
                                style={{
                                  fontSize: 16,
                                  color: colors.grey_60,
                                  transform: "rotate(90deg)",
                                }}
                              /> */}

                              {h.comment.attachments.map((file) =>
                                file.type === "picture" ? (
                                  <div
                                    key={file.key}
                                    style={{ display: "flex", gap: 8, alignItems: "center" }}
                                  >
                                    <Glyph
                                      name="photo"
                                      style={{ fontSize: 20, color: colors.grey_60 }}
                                    />
                                    <Typography.Text
                                      style={{
                                        fontSize: 12,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                      onClick={() => setVisible(!visible)}
                                    >
                                      {file.name}
                                    </Typography.Text>

                                    <Image
                                      style={{ display: "none" }}
                                      src={`${process.env.REACT_APP_DWM_URL}/api/mobile/apps/${process.env.REACT_APP_ID}/views/${currentViewId}/ticketingV2/tickets/${stuffId}/attachment/${file.id}?siteId=${siteId}&access_token=${keycloak.token}`}
                                      preview={{
                                        visible,
                                        onVisibleChange: (value) => {
                                          setVisible(value);
                                        },
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <a
                                    href={`${process.env.REACT_APP_DWM_URL}/api/mobile/apps/${process.env.REACT_APP_ID}/views/${currentViewId}/ticketingV2/tickets/${stuffId}/attachment/${file.id}?siteId=${siteId}&access_token=${keycloak.token}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ display: "flex", gap: 8, alignItems: "center" }}
                                  >
                                    <Glyph
                                      name="insert_drive_file"
                                      style={{ fontSize: 20, color: colors.grey_60 }}
                                    />
                                    <Typography.Text
                                      style={{
                                        fontSize: 12,
                                        textDecoration: "underline",
                                        cursor: "pointer",
                                      }}
                                    >
                                      {file.name}
                                    </Typography.Text>
                                  </a>
                                ),
                              )}
                            </div>
                          )}
                          {h.comment.internalNote && (
                            <Tag
                              style={{
                                marginLeft: "auto",
                                marginRight: 0,
                                backgroundColor: colors.grey_40,
                              }}
                            >
                              <Typography.Text strong style={{ fontSize: 12, color: "white" }}>
                                {t("ticketingv2.InternalNote")}
                              </Typography.Text>
                            </Tag>
                          )}
                        </div>
                      </Card>
                    </div>
                  );

                case "reopen":
                case "closed":
                  return (
                    <Card
                      style={{
                        padding: 0,
                        border: `2px solid ${colors.primary_base}`,
                      }}
                    >
                      <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                        <Glyph
                          name="receipt"
                          style={{ fontSize: 16, color: colors.secondary_base }}
                        />
                        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <Typography.Text strong style={{ fontSize: 14 }}>
                              {h.action === "closed"
                                ? t("ticketingv2.TicketIsNowClose")
                                : t("ticketingv2.TicketHasBeenReopenned")}
                            </Typography.Text>
                            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                              {moment(h.date).format(
                                locale === "fr" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY HH:mm",
                              )}
                            </Typography.Text>
                          </div>
                          <Typography.Text style={{ fontSize: 14 }}>
                            {t("ticketingv2.By_")}
                            {h.createdBy.id === userData.profile.id
                              ? t("ticketingv2.You")
                              : h.createdBy?.displayName}
                          </Typography.Text>
                        </div>
                      </div>
                    </Card>
                  );

                default:
                  return null;
              }
            })()}
          </div>
        ))}
      </div>
      {!data.status.isClosed && (
        <Card
          style={{ margin: 16, position: "absolute", bottom: 0, width: "calc(100% - 32px)" }}
          bodyStyle={{
            padding: 8,
            display: "flex",
            flexDirection: "column",
            bottom: 0,
            justifyContent: "center",
          }}
          className="inputCard"
        >
          <div style={{ display: "flex", gap: 8, alignItems: "start" }}>
            <TextArea
              bordered={false}
              autoSize
              style={{
                width: "100%",
                marginTop: 4,
              }}
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              placeholder="Ajouter un commentaire"
            />
            <div style={{ display: "flex", alignItems: "center", width: "fit-content" }}>
              {(rights.manager || rights.agent) && (
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    backgroundColor: isInternalNote ? colors.light_background : "white",
                    borderRadius: symbols.base_shape.radius,
                    overflow: "hidden",
                    padding: 8,
                  }}
                >
                  <Switch
                    size="small"
                    checked={isInternalNote}
                    onChange={() => setIsInternalNote(!isInternalNote)}
                  />
                  <Typography.Text strong style={{ fontSize: 12, width: 75 }}>
                    {t("ticketingv2.InternalNote")}
                  </Typography.Text>
                </div>
              )}
              <input
                type="file"
                //accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  setPostImage({ ...postImage, files: [...postImage.files, e.target.files[0]] });
                  e.target.value = null;
                }}
                style={{ display: "none" }}
                id="fileInput"
              />
              <label htmlFor="fileInput">
                <Glyph
                  name="attach_file"
                  style={{
                    color: colors.primary_base,
                    margin: 8,
                    cursor: "pointer",
                    height: "fit-content",
                    transform: "rotate(-90deg)",
                  }}
                />
              </label>
              {uploading ? (
                <div
                  style={{
                    color: colors.primary_base,
                    margin: 8,
                    height: "fit-content",
                  }}
                >
                  <Spin />
                </div>
              ) : (
                <Glyph
                  name={"send"}
                  onClick={addComment}
                  style={{
                    color: colors.primary_base,
                    margin: 8,
                    cursor: "pointer",
                    height: "fit-content",
                  }}
                />
              )}
            </div>
          </div>
          {!!postImage.files.length && (
            <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
              {postImage.files.map((file, index) => (
                <Popover content={file.name} trigger="hover" key={index}>
                  <Card
                    style={{ width: 40, height: 40, position: "relative", overflow: "visible" }}
                    bodyStyle={{
                      padding: 0,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    {file.type.split("/")[0] === "image" ? (
                      <img
                        src={URL.createObjectURL(file)}
                        alt="uploaded pic"
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          borderRadius: symbols.base_shape.radius,
                        }}
                      />
                    ) : (
                      <Glyph
                        name="insert_drive_file"
                        style={{
                          fontSize: 20,
                          color: colors.grey_60,
                        }}
                      />
                    )}

                    <div
                      style={{
                        position: "absolute",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "white",
                        top: -5,
                        right: -5,
                        cursor: "pointer",
                        width: 18,
                        height: 18,
                        borderRadius: "50%",
                      }}
                    >
                      <Glyph
                        name="cancel"
                        onClick={() =>
                          setPostImage({
                            ...postImage,
                            files: postImage.files.filter((_, i) => i !== index),
                          })
                        }
                        style={{
                          color: colors.primary_base,
                          fontSize: 16,
                          position: "absolute",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </Card>
                </Popover>
              ))}
            </div>
          )}
        </Card>
      )}
    </div>
  );
};

export default TicketTabConversation;
