import styles from "./StepsBars.module.less";
import cx from "clsx";

const StepsBars = ({ selectedKey, steps, ...rest }) => {
  return (
    <div {...rest} className={styles["steps-bars"]}>
      {steps.map((step) => (
        <div key={step.key} className={styles["steps-bars__step"]}>
          <div className={styles["steps-bars__step__title"]}>{step.label}</div>
          <div
            className={cx(styles["steps-bars__step__indicator"], {
              [styles["steps-bars__step__indicator--active"]]: step.key === selectedKey,
            })}
          />
        </div>
      ))}
    </div>
  );
};

export default StepsBars;
