import { Button, Card, Modal, Typography, message } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { deleteParkingBooking, getBookings } from "../../../../services/redux/services/ParkingWS";
import { useState } from "react";
import Glyph from "../../../Common/Glyph/Glyph";
import useDesignTokens from "../../../../hook/useDesignTokens";

const DeleteBookingModal = ({
  showDeleteBookingModal,
  setShowDeleteBookingModal,
  selectedMonth,
  selectedBooking,
  setSelectedBooking,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { colors, symbols, size } = useDesignTokens();
  const locale = i18n.language.split("-")[0];
  const [loading, setLoading] = useState(false);

  const campus = useSelector((state) => state.clientsWS.campus);
  const isMultiSite = !!useSelector((state) => state.userWS.userData.campus)?.length;

  const handleDeleteBooking = async () => {
    try {
      setLoading(true);

      const deleteResponse = await dispatch(
        deleteParkingBooking({
          bookingId: selectedBooking.id,
          siteId: selectedBooking.site.id,
          date: selectedBooking.date,
        }),
      );

      if (deleteResponse) {
        await dispatch(
          getBookings({
            siteId: campus?.mapData?.id,
            from: moment(selectedMonth).startOf("month").subtract(10, "days"),
            to: moment(selectedMonth).endOf("month").add(10, "days"),
          }),
        );

        setSelectedBooking(null);
        setLoading(false);
        setShowDeleteBookingModal(false);

        message.success(t("parking.BookingCanceledWithSuccess"));
      } else {
        message.error(t("parking.CancelBookingError"));
      }
    } catch (error) {
      logger.error("An error occurred while deleting booking:", error);
      setLoading(false);

      message.error(t("parking.CancelBookingError"));
    }
  };

  return (
    <Modal
      title={t("parking.CancelBooking")}
      open={showDeleteBookingModal}
      footer={null}
      closable={!loading}
      onCancel={() => setShowDeleteBookingModal(false)}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: 16,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          backgroundColor: colors.light_background,
          borderRadius: symbols.base_shape.radius,
          padding: 16,
        }}
      >
        <Typography.Text strong style={{ fontSize: size.l }}>
          {t("parking.DoYouWantToCancelBooking")}
        </Typography.Text>

        {isMultiSite && (
          <Card style={{ width: "100%" }} bodyStyle={{ padding: 12 }}>
            <div
              style={{
                display: "flex",
                gap: 8,
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <Glyph name="business" size={24} style={{ color: colors.grey_60 }} />
                <Typography.Text strong>{t("parking.Site")}</Typography.Text>
              </div>
              <Typography.Text>{selectedBooking?.site?.title}</Typography.Text>
            </div>
          </Card>
        )}
        <Card style={{ width: "100%" }} bodyStyle={{ padding: 12 }}>
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <Glyph name="calendar_today" size={24} style={{ color: colors.grey_60 }} />
              <Typography.Text strong>{t("parking.Date")}</Typography.Text>
            </div>
            <Typography.Text>
              {moment(selectedBooking?.date).format(locale === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY")}
            </Typography.Text>
          </div>
        </Card>
        <Card style={{ width: "100%" }} bodyStyle={{ padding: 12 }}>
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <Glyph name="schedule" size={24} style={{ color: colors.grey_60 }} />
              <Typography.Text strong>{t("parking.Duration")}</Typography.Text>
            </div>
            <Typography.Text>
              {selectedBooking?.period === "AM"
                ? t("parking.Am")
                : selectedBooking?.period === "PM"
                ? t("parking.Pm")
                : t("parking.Day")}
            </Typography.Text>
          </div>
        </Card>
      </div>
      <div style={{ display: "flex", gap: 8 }}>
        <Button
          disabled={loading}
          type={loading ? "primary" : "tertiary"}
          style={{ width: "100%" }}
          onClick={() => setShowDeleteBookingModal(false)}
        >
          {t("parking.Back")}
        </Button>
        <Button
          type="primary"
          style={{ width: "100%" }}
          loading={loading}
          onClick={handleDeleteBooking}
        >
          {t("parking.Confirm")}
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteBookingModal;
