import { Card, Typography } from "antd";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";

const RightPanelGeneral = ({ userData }) => {
  const { colors } = useDesignTokens();

  const contact = userData.contact;

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "8px" }}>
      {!!contact && (
        <>
          {contact.email && (
            <Card>
              <Glyph name="mail" style={{ color: colors.secondary_base, marginRight: "10px" }} />
              <Typography.Text strong>{contact.email}</Typography.Text>
            </Card>
          )}
          {contact.mobilePhone && (
            <Card>
              <Glyph
                name="phone_iphone"
                style={{ color: colors.secondary_base, marginRight: "10px" }}
              />
              <Typography.Text strong>{contact.mobilePhone}</Typography.Text>
            </Card>
          )}
          {contact.phone && (
            <Card>
              <Glyph name="phone" style={{ color: colors.secondary_base, marginRight: "10px" }} />
              <Typography.Text strong>{contact.phone}</Typography.Text>
            </Card>
          )}
          {contact.businessAddress && (
            <Card>
              <Glyph name="place" style={{ color: colors.secondary_base, marginRight: "10px" }} />
              <Typography.Text strong>{contact.businessAddress}</Typography.Text>
            </Card>
          )}
          {contact.company && (
            <Card>
              <Glyph
                name="business"
                style={{ color: colors.secondary_base, marginRight: "10px" }}
              />
              <Typography.Text strong>{contact.company}</Typography.Text>
            </Card>
          )}
          {contact.entity && (
            <Card>
              <Glyph
                name="location_city"
                style={{ color: colors.secondary_base, marginRight: "10px" }}
              />
              <Typography.Text strong>{contact.entity}</Typography.Text>
            </Card>
          )}
        </>
      )}
    </div>
  );
};

export default RightPanelGeneral;
