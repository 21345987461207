import { Avatar, Card, Typography } from "antd";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import { isEmpty } from "ramda";
// import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Tile from "../../Common/Tile/Tile";
import { useKeycloak } from "@react-keycloak/web";
import { Link, useParams } from "react-router-dom";

const LeftPanel = ({ setGroupFilter, groupFilter, currentContact, userContactCategories }) => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { slug } = useParams();

  const userData = useSelector((state) => state.userWS.userData.profile);

  /*useEffect(() => {
    logger.log(
      "userContactCategories",
      userContactCategories,
      "userData",
      userData,
      "currentContact",
      currentContact,
      "groupFilter",
      groupFilter,
    );
  }, [userContactCategories, currentContact, groupFilter, userData]);*/

  return (
    <Card style={{ minWidth: "280px", width: "30%", display: "flex", gap: "8px" }}>
      {userData && (
        <Link to={`/contactListEnhanced/${userData.id}`} style={{ cursor: "auto" }}>
          <Tile
            style={{
              margin: 0,
              marginBottom: "32px",
              cursor: parseInt(slug) === userData.id ? "unset" : "pointer",
            }}
            selected={currentContact.id === userData.id}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Avatar size="medium" src={`${userData.photoUrl}?access_token=${keycloak.token}`}>
                  {userData.firstName?.charAt(0).toUpperCase()}
                  {userData.lastName?.charAt(0).toUpperCase()}
                </Avatar>
                <Typography.Text strong style={{ marginTop: "2px" }}>
                  {t("contactList.myProfile")}
                </Typography.Text>
              </div>
              <Glyph name="navigate_next" style={{ marginLeft: "0px", marginTop: "-1px" }} />
            </div>
          </Tile>
        </Link>
      )}

      <Typography.Title level={5}>{t("contactList.lists")}</Typography.Title>

      {userContactCategories?.top?.map((topId) => {
        const categorie = userContactCategories.lists?.find((cat) => cat.id === topId);

        return (
          <Link
            to={`/contactListEnhanced/${categorie.id}-${categorie.title
              .replace(/\s(.)/g, (_, match) => match.toUpperCase())
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}`}
            key={topId}
            style={{ cursor: "auto" }}
          >
            <Tile
              style={{
                margin: 0,
                marginBottom: "8px",
                cursor: parseInt(slug?.split("-")[0]) === categorie.id ? "unset" : "pointer",
              }}
              onClick={() => {
                setGroupFilter(categorie);
              }}
              selected={
                (groupFilter.id === categorie.id || (categorie.isAll && !!!slug)) && isNaN(slug)
              }
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Glyph
                    name={categorie?.icon}
                    style={{ color: colors.secondary_base, marginRight: "10px", marginTop: "-1px" }}
                  />
                  <Typography.Text strong>{categorie?.title}</Typography.Text>
                </div>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Typography.Text>{categorie?.count}</Typography.Text>
                  <Glyph name="navigate_next" style={{ marginLeft: "0px", marginTop: "-1px" }} />
                </div>
              </div>
            </Tile>
          </Link>
        );
      })}

      {!isEmpty(userContactCategories?.lists) && (
        <>
          {userContactCategories.lists?.map(
            (userContactCategory, index) =>
              !userContactCategories.top?.find((topId) => topId === userContactCategory.id) && (
                <Link
                  to={`/contactListEnhanced/${userContactCategory.id}-${userContactCategory.title
                    .replace(/\s(.)/g, (_, match) => match.toUpperCase())
                    .normalize("NFD")
                    .replace(/[\u0300-\u036f]/g, "")}`}
                  key={index}
                  style={{ cursor: "auto" }}
                >
                  <Tile
                    style={{
                      margin: "0",
                      marginBottom: "8px",
                      cursor:
                        parseInt(slug?.split("-")[0]) === userContactCategory.id
                          ? "unset"
                          : "pointer",
                    }}
                    onClick={() => {
                      setGroupFilter(userContactCategory);
                    }}
                    selected={groupFilter.id === userContactCategory.id && isNaN(slug)}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Typography.Text strong>{userContactCategory?.title}</Typography.Text>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        <Typography.Text>{userContactCategory?.count}</Typography.Text>
                        <Glyph
                          name="navigate_next"
                          style={{ marginLeft: "0px", marginTop: "-1px" }}
                        />
                      </div>
                    </div>
                  </Tile>
                </Link>
              ),
          )}
        </>
      )}
    </Card>
  );
};

export default LeftPanel;
