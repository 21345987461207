import { Card, Col, Row, Typography } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import UserList from "./UserList/UserList";

const VisitItem = ({ visit, onClick, setIsOpen }) => {
  const isVisitor = useSelector((state) => state.userWS.userData.profile.isVisitor);

  useEffect(() => {
    onClick(visit);
  }, [onClick, visit]);

  return (
    <Card
      onClick={() => {
        onClick(visit);
        setIsOpen(true);
      }}
      style={{ width: "100%", cursor: "pointer" }}
    >
      <Row>
        <Col flex={"auto"}>
          <Typography.Title level={4} style={{ cursor: "pointer" }}>
            {moment(visit.startDate).isSame(moment(), "w")
              ? moment(visit.startDate).format("dddd")
              : moment(visit.startDate).format("ddd DD MMM YYYY")}
          </Typography.Title>
        </Col>
        <Col>
          <Typography.Title level={4} type="secondary" style={{ cursor: "pointer" }}>
            {moment(visit.startDate).format("HH:mm")}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <UserList
            users={visit.visitors}
            descriptionKey={isVisitor ? "function" : "email"}
            maxSize={1}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default VisitItem;
