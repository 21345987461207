import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";
import moment from "moment";

const INITIAL_STATE = {
  bookings: [],
  vehicles: [],
  availability: [],
  history: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// PARKING ///////////////////////////////

export const getBookings = thunk(
  "parking/getBookings",
  async ({ siteId, from, to }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "parking");
    const bookings = await DWMConnector.getBookings(
      siteId,
      moment(from).format("YYYY-MM-DD"),
      moment(to).format("YYYY-MM-DD"),
      viewId.id,
      "asc",
    );
    return bookings;
  },
);

EXTRA_REDUCERS[getBookings.fulfilled] = (state, action) => {
  state.bookings = action.payload;
};

export const getHistory = thunk(
  "parking/getHistory",
  async ({ siteId, from, to }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "parking");
    const bookings = await DWMConnector.getBookings(
      siteId,
      moment(from).format("YYYY-MM-DD"),
      moment(to).format("YYYY-MM-DD"),
      viewId.id,
      "desc",
    );
    return bookings;
  },
);

EXTRA_REDUCERS[getHistory.fulfilled] = (state, action) => {
  state.history = action.payload;
};

export const createVehicle = thunk(
  "parking/createVehicle",
  async ({ vehicleData, siteId }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "parking");
    const vehicles = await DWMConnector.createVehicle(vehicleData, viewId.id, siteId);
    return vehicles;
  },
);

export const updateVehicle = thunk(
  "parking/updateVehicle",
  async ({ vehicleData, siteId }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "parking");
    const vehicles = await DWMConnector.updateVehicle(vehicleData, viewId.id, siteId);
    return vehicles;
  },
);

export const deleteVehicle = thunk(
  "parking/deleteVehicle",
  async ({ vehicleId, siteId }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "parking");
    const vehicles = await DWMConnector.deleteVehicle(vehicleId, viewId.id, siteId);
    return vehicles;
  },
);

export const getVehicles = thunk("parking/getVehicles", async ({ siteId }, { getState }) => {
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "parking");
  const vehicles = await DWMConnector.getVehicles(viewId.id, siteId);
  return vehicles;
});

EXTRA_REDUCERS[getVehicles.fulfilled] = (state, action) => {
  state.vehicles = action.payload.vehicles;
};

export const getZones = thunk(
  "parking/getZones",
  async ({ date, period, siteId }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "parking");
    const vehicles = await DWMConnector.getZones(viewId.id, date, period, siteId);
    return vehicles;
  },
);

EXTRA_REDUCERS[getZones.fulfilled] = (state, action) => {
  state.availability = action.payload.zones;
};

EXTRA_REDUCERS[getZones.rejected] = (state, action) => {
  state.availability = [];
};

export const createBooking = thunk(
  "parking/createBooking",
  async ({ date, period, zoneId, siteId }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "parking");
    const vehicles = await DWMConnector.createBooking(viewId.id, date, period, zoneId, siteId);
    return vehicles;
  },
);

EXTRA_REDUCERS[createBooking.fulfilled] = (state, action) => {};

export const deleteParkingBooking = thunk(
  "parking/deleteParkingBooking",
  async ({ bookingId, siteId, date }, { getState }) => {
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "parking");
    const vehicles = await DWMConnector.deleteParkingBooking(viewId.id, bookingId, siteId, date);
    return vehicles;
  },
);

EXTRA_REDUCERS[deleteParkingBooking.fulfilled] = (state, action) => {};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const parking = createSlice({
  name: "parking",
  initialState: INITIAL_STATE,
  reducers: {
    emptyZones(state) {
      state.availability = [];
    },
  },
  extraReducers: EXTRA_REDUCERS,
});
export const { emptyZones } = parking.actions;
export default parking.reducer;
