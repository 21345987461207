import { Button, Input, Modal, Typography } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addCommentToTicket,
  getTicketDetails,
  updateTicketByAgent,
  updateTicketByManager,
} from "../../../../../services/redux/services/TicketingV2WS";
import { set } from "js-cookie";

const MarkAsResolvedModal = ({
  showMarkAsResolvedModal,
  setShowMarkAsResolvedModal,
  siteId,
  locale,
  ticketDetails,
  viewIdByRights,
}) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const status = useSelector((state) => state.ticketingV2WS.status);

  const resolveFunction = async () => {
    try {
      setLoading(true);
      if (comment) {
        await dispatch(
          addCommentToTicket({
            siteId,
            ticketId: ticketDetails.id,
            data: { comment: comment },
          }),
        );
      }
      //! mettre en place coté back un param sur le status de cloture
      const updateTicketAsAgent = ticketDetails.isManager
        ? await dispatch(
            updateTicketByManager({
              data: {
                status: status.find((s) => !!s.isSolved)?.id,
                ticketIds: [ticketDetails.id],
              },
              siteId: siteId,
              viewId: viewIdByRights,
            }),
          )
        : await dispatch(
            updateTicketByAgent({
              data: {
                status: status.find((s) => !!s.isSolved)?.id,
                ticketIds: [ticketDetails.id],
              },
              siteId: siteId,
              viewId: viewIdByRights,
            }),
          );

      if (updateTicketAsAgent) {
        setShowMarkAsResolvedModal(false);
        await dispatch(
          getTicketDetails({
            siteId,
            ticketId: ticketDetails.id,
            locale,
          }),
        );
      }
      setLoading(false);
    } catch (error) {
      logger.error("Error during Set as resolved:", error);
    }
  };

  return (
    <Modal
      open={showMarkAsResolvedModal}
      width={450}
      onCancel={() => setShowMarkAsResolvedModal(false)}
      title={
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t("ticketingv2.MarkAsResolved")}
        </Typography.Text>
      }
      footer={
        <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
          <Button
            type="tertiary"
            onClick={() => setShowMarkAsResolvedModal(false)}
            style={{ width: 200 }}
          >
            {t("ticketingv2.Back")}
          </Button>
          <Button type="primary" loading={loading} onClick={resolveFunction} style={{ width: 200 }}>
            {t("ticketingv2.MarkAsResolved")}
          </Button>
        </div>
      }
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: 24,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 0 }}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t("ticketingv2.AddCommentSentToUser")}
        </Typography.Text>
        <Typography.Text type="secondary" style={{ fontSize: 12 }}>
          {t("ticketingv2.Optionnal")}
        </Typography.Text>
      </div>

      <Input.TextArea
        placeholder={t("ticketingv2.TypeComment")}
        style={{ height: 100 }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
    </Modal>
  );
};

export default MarkAsResolvedModal;
