import SwiziMap from "@swizi/swizi-map-sdk";
import { Result, Spin } from "antd";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectedCampus } from "../../../../services/redux/services/UserWS";

const MapboxContext = createContext();

export const MapboxProvider = ({ children, siteId, mode, padding }) => {
  const { i18n } = useTranslation();
  const locale = i18n.language.split("-")[0];

  const mapRef = useRef();

  const [container, setContainer] = useState();
  const [mapLoading, setMapLoading] = useState(false);
  const [mapReady, setMapReady] = useState(false);

  const [_floors, _setFloors] = useState([]);
  const [_floor, _setFloor] = useState();
  const [_selectedDesk, _setSelectedDesk] = useState();

  const currentSite = useSelector(selectedCampus);
  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  const _siteId = siteId || currentSite?.id;

  const registerMap = useCallback((node) => {
    if (!node) return;
    setContainer(node.id);
  }, []);

  useEffect(() => {
    if (mapRef.current || !container) return;

    setMapLoading(true);
    setMapReady(false);
    document.getElementById(container).style.transition = "none";
    document.getElementById(container).style.opacity = "0";

    const newMap = new SwiziMap(
      {
        baseURL: process.env.REACT_APP_DWM_URL,
        apiKey: process.env.REACT_APP_SWIZI_API_KEY,
        mode,
        locale,
        siteId: _siteId,
        padding,
        firebaseConfig: {
          apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
          authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
          databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
          projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
          storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
          appId: process.env.REACT_APP_FIREBASE_APPID,
          messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGID,
          measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
        },
      },
      {
        container,
        logoPosition: "bottom-left",
      },
    );

    newMap.once("ready", () => {
      mapRef.current = newMap;
      setMapLoading(false);
      setMapReady(true);
      document.getElementById(container).style.transition = "opacity 0.5s ease-in-out";
      document.getElementById(container).style.opacity = "1";

      const floors = newMap.getFloors();
      _setFloors(floors || []);
      _setFloor(newMap.getFloor());

      newMap.on("floorChange", ({ details: floor }) => {
        _setFloor(floor);
      });

      newMap.on("clickOnDesk", ({ details: desk }) => {
        _setSelectedDesk(desk);
      });
    });

    return () => {
      mapRef.current = null;
      newMap.remove();
    };
  }, [_siteId, container, locale, mode, padding]);

  useEffect(() => {
    if (!container) return;
    const resizeObserver = new ResizeObserver(() => {
      mapRef.current?.resize();
    });
    const div = document.getElementById(container);
    resizeObserver.observe(div);
    return () => {
      resizeObserver.disconnect();
    };
  }, [container]);

  /**
   *
   * FUNCTIONS
   *
   */

  const centerOnPlace = useCallback((id, applySelection, ease, opts) => {
    return mapRef.current?.centerOnPlace(id, applySelection, ease, opts);
  }, []);

  const setFloor = useCallback((id) => {
    return mapRef.current?.setFloor(id);
  }, []);

  const setDesksDisplay = useCallback(async (data) => {
    await mapRef.current?.setDesksDisplay(data);
  }, []);

  const zoomIn = useCallback(() => {
    return mapRef.current?.zoomIn();
  }, []);
  const zoomOut = useCallback(() => {
    return mapRef.current?.zoomOut();
  }, []);

  const selectedDesk = useMemo(() => {
    if (!_selectedDesk) return;
    return {
      ...mapData?.workplaces.find((i) => i.map?.findAndOrder.placeId === _selectedDesk?.id),
    };
  }, [_selectedDesk, mapData?.workplaces]);

  return (
    <MapboxContext.Provider
      value={{
        map: mapRef.current,
        registerMap,
        mapLoading,
        mapReady,
        floors: _floors,
        floor: _floor,
        centerOnPlace,
        setFloor,
        setDesksDisplay,
        zoomIn,
        zoomOut,
        selectedDesk,
      }}
    >
      {children}
    </MapboxContext.Provider>
  );
};

export const MapboxMap = ({ id, style }) => {
  const { t } = useTranslation();
  const { registerMap, mapReady } = useContext(MapboxContext);
  return (
    <div style={style}>
      {!mapReady && (
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            height: "100%",
            width: "100%",
            display: "grid",
            placeItems: "center",
          }}
        >
          <Result icon={<Spin spinning />} title={t("map.loading")} />
        </div>
      )}
      <div style={{ height: "100%", width: "100%" }} ref={registerMap} id={id} />
    </div>
  );
};

export const withMapboxInstance = (params) => (Component) => (props) => {
  return (
    <MapboxProvider {...params}>
      <Component {...props} />
    </MapboxProvider>
  );
};

export const useMapboxInstance = () => useContext(MapboxContext);
