import { Button, Card, Col, Image, Row, Tag, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import { isEmpty, isNil } from "ramda";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useDesignTokens from "../../../../hook/useDesignTokens";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import { patchFilter } from "../../../../services/redux/services/FastbookingWS";
import Checkbox from "../../../Common/Checkbox/Checkbox";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../Fastbooking.module.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { useEffect, useState } from "react";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const CardRoom = ({ setSelectedRoom, setModalDetails }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors, size } = useDesignTokens();
  const cardWidth = document.getElementById("RoomGrid")?.offsetWidth;

  const filters = useSelector((state) => state.fastbookingWS.filters);
  const campus = useSelector((state) => state.clientsWS.campus);
  const roomFastbooking = useSelector((state) => state.fastbookingWS.roomFastbooking);
  const [colWidth, setColWidth] = useState(0);

  const isLoading = useSelector(createLoadingSelector(["fastbooking/getRoomFastbooking"]));

  useEffect(() => {
    const colCount = Math.floor((cardWidth - 32) / 220);
    setColWidth((cardWidth - 32) / colCount - (26 - colCount));
  }, [cardWidth]);

  const roomsFilteredByFloor = isNil(filters.floor)
    ? roomFastbooking?.rooms
    : roomFastbooking?.rooms?.filter(
        (r) =>
          campus?.mapData?.floors.find((f) => f.reference === r?.resources?.floorReference)?.id ===
          filters.floor,
      );

  return (
    <Card
      title={
        <div
          style={{
            display: "flex",
            flexFlow: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography.Title style={{ margin: 0 }} level={5}>
            {t("SpaceAvailable")}
          </Typography.Title>
          <Button type="secondary" size="medium" icon={<Glyph name="maps" />}>
            <Link
              style={{ color: "white" }}
              to="/fastBookingV2/map"
              onClick={() => logEvent(analytics, "fast_booking_map")}
            >
              {t("spas.request.actions.map")}
            </Link>
          </Button>
        </div>
      }
      className={styles["cardRoom"]}
      style={{ overflow: "initial" }}
      headStyle={{
        display: "flex",
        alignItems: "center",
        minHeight: "50px",
        position: "sticky",
        top: "0",
        backgroundColor: "white",
        zIndex: "999",
      }}
      id="RoomGrid"
    >
      {campus?.mapData?.floors?.length > 1 && (
        <div style={{ width: "100%", position: "sticky", top: "0", zIndex: "200" }}>
          <div
            style={{
              overflowX: "auto",
              display: "flex",
              padding: "16px",
              backgroundColor: "white",
            }}
          >
            <Button
              style={{ fontSize: size.m }}
              className={
                isNil(filters.floor) || isEmpty(filters.floor) ? "active-allFilter" : "btnAllFilter"
              }
              onClick={() => {
                dispatch(patchFilter({ floor: null }));
              }}
            >
              <Glyph name="business" style={{ verticalAlign: "-2px", fontSize: size.l }} />
              {t("AllFloor")}
            </Button>
            {campus?.mapData?.floors.map((floor, index) => (
              <Checkbox
                key={index}
                value={floor.id}
                text={t("Floor") + " " + floor.displayName}
                onClick={(e) => {
                  dispatch(patchFilter({ floor: e.target.value }));
                }}
                checked={(filters?.floor && filters?.floor.includes(floor.id)) || isNil(filters)}
              />
            ))}
          </div>
        </div>
      )}

      <div style={{ padding: "0 16px" }}>
        <Typography.Text strong type="secondary">
          {roomsFilteredByFloor?.length} {t("Result")}
          {roomsFilteredByFloor?.length > 1 ? "s" : ""}
        </Typography.Text>
      </div>

      <Row gutter={[16, 16]} style={{ padding: "16px" }}>
        {[...(roomsFilteredByFloor || [])]
          .sort((a, b) => (a?.resources?.title > b?.resources?.title ? 1 : -1))
          .sort((a, b) => (a?.resources?.floorReference < b?.resources?.floorReference ? -1 : 1))
          ?.map((room, index) => (
            <Col style={{ position: "relative" }} key={index}>
              <Card
                style={{ cursor: "pointer", height: "100%", minWidth: colWidth }}
                onClick={() => {
                  setModalDetails(true);
                  setSelectedRoom(room);
                }}
                cover={
                  room?.resources?.photos && !isEmpty(room?.resources?.photos) ? (
                    <div style={{ boxShadow: "none" }} className={styles["roomPictures"]}>
                      <Image preview={false} src={room.resources.photos[0]} />
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "150px",
                        width: "100%",
                        backgroundColor: colors.grey_40,
                        opacity: 0.3,
                      }}
                    >
                      <Glyph
                        style={{ fontSize: "60px", color: "white", opacity: 0.8 }}
                        name={
                          campus?.mapData?.categories?.find(
                            (categorie) => categorie?.id === room?.resources?.categoryId,
                          )?.icon
                        }
                      />
                    </div>
                  )
                }
              >
                {room?.pending ? (
                  <Tag
                    style={{
                      position: "absolute",
                      zIndex: "80",
                      top: "10px",
                      right: "5px",
                    }}
                    color={colors.pending_light}
                  >
                    <Typography.Text strong style={{ color: "white" }}>
                      {t("Treatment")}
                    </Typography.Text>
                  </Tag>
                ) : (
                  room?.availableTime && (
                    <Tag
                      style={{
                        position: "absolute",
                        zIndex: "80",
                        top: "10px",
                        right: "5px",
                      }}
                      color={colors.success_light}
                    >
                      <Typography.Text strong style={{ color: "white" }}>
                        {room?.availableTime >= 60
                          ? `${t("Available")} > 1h`
                          : `${room?.availableTime}
                            mins`}
                      </Typography.Text>
                    </Tag>
                  )
                )}
                <Meta
                  title={
                    <Typography.Title
                      level={5}
                      style={{ overflow: "hidden", textOverflow: "ellipsis" }}
                    >
                      {room?.resources?.title}
                    </Typography.Title>
                  }
                  description={
                    <div>
                      <Typography.Paragraph>
                        {t("Floor")}{" "}
                        {
                          campus?.mapData?.floors?.find(
                            (floor) => floor.reference === room?.resources?.floorReference,
                          )?.displayName
                        }
                      </Typography.Paragraph>
                      <Typography.Paragraph type="secondary">
                        {
                          campus?.mapData?.categories?.find(
                            (categorie) => categorie.id === room?.resources?.categoryId,
                          )?.title
                        }
                        <br />
                        {`${room?.resources?.features?.seats} ${
                          t("fastbooking.place") + (room?.resources?.features?.seats > 1 ? "s" : "")
                        }`}
                      </Typography.Paragraph>
                    </div>
                  }
                />
              </Card>
            </Col>
          ))}
      </Row>
    </Card>
  );
};

export default CardRoom;
