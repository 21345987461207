import { Card, Select, Space, Typography } from "antd";
import clsx from "clsx";
import moment from "moment";
import { useMemo, useRef } from "react";
import Glyph from "../../Glyph/Glyph";
import styles from "./DatePickerInline.module.less";

const Header = ({ value, onChange, granularity = "week", min, max }) => {
  const minDate = useRef(min ? moment(min) : moment().subtract(1, "month"));
  const maxDate = useRef(max ? moment(max) : moment().add(6, "month"));

  const options = useMemo(() => {
    const options = [];
    const date = minDate.current.clone();
    while (date.isBetween(minDate.current, maxDate.current, "month", "[]")) {
      options.push({ label: date.format("MMMM YYYY"), value: date.format("MM-YYYY") });
      date.add(1, "month");
    }
    return options;
  }, []);

  const handleSelect = (dateISO) => {
    const val = moment(dateISO, "MM-YYYY");
    const date = moment(value).set({ month: val.month(), year: val.year() });
    if (date.isBefore(minDate.current)) onChange(minDate.current);
    else if (date.isAfter(maxDate.current)) onChange(maxDate.current);
    else onChange(date);
  };

  const handleWeek = (amount) => {
    const date = moment(value).add(amount, granularity);
    if (date.isBefore(minDate.current)) onChange(minDate.current);
    else if (date.isAfter(maxDate.current)) onChange(maxDate.current);
    else onChange(date);
  };

  return (
    <>
      <div style={{ width: "100%", display: "flex", gap: 8 }}>
        <Select
          className="ghost"
          size="middle"
          style={{ width: 200 }}
          options={options}
          value={value.format("MM-YYYY")}
          onChange={handleSelect}
        />
        <div style={{ flex: 1 }} />
        <Space size={"large"}>
          <Glyph
            style={{
              cursor: "pointer",
              pointerEvents: value.isSameOrBefore(minDate.current, "week") ? "none" : "all",
              opacity: value.isSameOrBefore(minDate.current, "week") ? ".3" : "initial",
            }}
            name="chevron_left"
            onClick={() => handleWeek(-1)}
          />
          <Glyph
            style={{
              cursor: "pointer",
              pointerEvents: value.isSameOrAfter(maxDate.current, "week") ? "none" : "all",
              opacity: value.isSameOrAfter(maxDate.current, "week") ? ".3" : "initial",
            }}
            name="chevron_right"
            onClick={() => handleWeek(1)}
          />
        </Space>
      </div>
    </>
  );
};

const DatePickerInline = ({ value, onChange, min, max }) => {
  const minDate = useRef(min ? moment(min) : moment().subtract(1, "month"));
  const maxDate = useRef(max ? moment(max) : moment().add(6, "month"));

  const days = useMemo(() => {
    const days = [];
    const start = value.clone().startOf("week");
    while (start.week() === value.week()) {
      days.push(start.clone());
      start.add(1, "day");
    }
    return days;
  }, [value]);

  const isBetween = (date) => {
    return date.isBetween(minDate.current, maxDate.current, "date", "[]");
  };

  return (
    <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: 8 }}>
      <Header min={min} max={max} value={value} onChange={onChange} />

      <div style={{ display: "grid", gridTemplateColumns: `repeat(${days.length}, 1fr)`, gap: 8 }}>
        {days.map((day) => (
          <Card
            style={{
              cursor: "pointer",
              pointerEvents: !isBetween(day) ? "none" : "all",
              opacity: !isBetween(day) ? ".3" : "initial",
            }}
            bodyStyle={{ padding: "0.5rem" }}
            bordered={false}
            onClick={() => onChange(day)}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 2,
              }}
            >
              <Typography.Text style={{ textTransform: "uppercase" }}>
                {day.format("ddd")}
              </Typography.Text>
              <Typography.Text
                className={clsx(styles.day, {
                  [styles.current]: day.isSame(moment(), "date"),
                  [styles.selected]: day.isSame(moment(value), "date"),
                })}
                strong
              >
                {day.format("DD")}
              </Typography.Text>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

DatePickerInline.Header = Header;
export default DatePickerInline;
