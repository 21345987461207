import { Button, Typography } from "antd";
import { ReactComponent as Error } from "../../../assets/svg/error.svg";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import { STEPS_BOOKING } from "./Booking";
import { useTranslation } from "react-i18next";

const ErrorBooking = ({ setStep }) => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Error />
      <Typography.Text strong style={{ marginTop: "16px" }}>
        <Glyph
          name="cancel"
          style={{
            fontWeight: "normal",
            color: colors.error_light,
            marginRight: "8px",
            verticalAlign: "-5px",
          }}
        />
        {t("Places.Booking.Error.Title")}
      </Typography.Text>
      <Typography.Text type="secondary" strong style={{ marginTop: "16px" }}>
        {t("Places.Booking.Error.Text")}
      </Typography.Text>
      <Button
        ghost
        style={{ width: "100%", marginTop: "32px" }}
        onClick={() => {
          setStep(STEPS_BOOKING.BOOKING);
        }}
      >
        {t("Back")}
      </Button>
    </div>
  );
};

export default ErrorBooking;
