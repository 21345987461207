import React from "react";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import SmartControl from "./Component/SmartControl/SmartControl";
import WidgetPage from "./Component/WidgetPage";

const DashboardMobile = () => {
  return (
    <>
      <Switch>
        <Route exact path="/smartControl">
          <SmartControl />
        </Route>
        <Route path="/">
          <WidgetPage />
        </Route>
      </Switch>
    </>
  );
};

export default DashboardMobile;
