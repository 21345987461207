import { Button, Spin, Typography } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useHistory } from "react-router-dom";
import useDidUpdateEffect from "../../../hook/useDidUpdate";
import useMap from "../../Common/Map/useMap";
import { TitleSource } from "../../Common/Teleporters/Title";
import FastbookingList from "./NoMap/FastbookingList";
import FastbookingMap from "./Map/FastbookingList";
import Filter from "./Filter";
import RoomFastbooking from "./Map/RoomFastbooking";
import Options from "../../Common/Options/Options";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

import styles from "../Dashboard/Dashboard.module.less";
import Glyph from "../../Common/Glyph/Glyph";
import moment from "moment";
import { getRoomFastbooking } from "../../../services/redux/services/FastbookingWS";
// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const Fastbooking = ({ mapLoaded }) => {
  const { t, i18n } = useTranslation();
  const { selectedOnMap } = useMap();
  const history = useHistory();
  const dispatch = useDispatch();

  const roomFastbooking = useSelector((state) => state.fastbookingWS.roomFastbooking);
  const planning = useSelector((state) => state.planningWS.planning);
  const filters = useSelector((state) => state.fastbookingWS.filters);

  const [isFloor, setIsFloor] = useState(true);
  const [during, setDuring] = useState(30);

  const filteredRoomCount = roomFastbooking?.rooms?.length;

  useDidUpdateEffect(() => {
    const item = roomFastbooking?.rooms?.find((r) => r.roomId === selectedOnMap?.id);
    const itemPlanning = planning?.slots?.items?.find((i) =>
      i?.resources.find((r) => r?.id === selectedOnMap?.id),
    );

    if (selectedOnMap?.map?.findAndOrder?.placeId)
      history.push({
        pathname: `/fastBookingV2/${selectedOnMap.map.findAndOrder.placeId}`,
        state: { room: item ? item : itemPlanning },
      });
  }, [selectedOnMap]);

  const fetch = async () => {
    await dispatch(
      getRoomFastbooking({
        locale: i18n.language.split("-")[0],
        ...filters,
        startDate: filters.startDate || moment().toISOString(),
      }),
    );
  };

  useDidUpdateEffect(() => {
    fetch();
  }, [dispatch, i18n.language]);

  return (
    <>
      <TitleSource>{t("fast_booking_title", { ns: "csv" })}</TitleSource>

      <Switch>
        <Route exact path="/fastBookingV2">
          <FastbookingList during={during} setDuring={setDuring} />
        </Route>
        <Route exact path="/fastBookingV2/map">
          {!mapLoaded ? (
            <div className={styles["loader-map"]}>
              <div>
                <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                <br />
                <Typography.Title level={3}>{t("LoadingMap")}</Typography.Title>
              </div>
            </div>
          ) : (
            <>
              <FastbookingMap />
              <Options
                type="fastbooking"
                isFloor={isFloor}
                setIsFloor={setIsFloor}
                filteredRoomCount={filteredRoomCount}
                fetch={fetch}
              />
              <div
                style={{
                  position: "absolute",
                  bottom: "50px",
                  right: "calc(((100% - 366px) / 2) - 125px)",
                  zIndex: "999",
                }}
              >
                <Button
                  type="secondary"
                  icon={<Glyph name="maps" />}
                  onClick={() => logEvent(analytics, "fast_booking_nomap")}
                >
                  <Link style={{ color: "white" }} to="/fastBookingV2">
                    {t("SeeSpaces")}
                  </Link>
                </Button>
              </div>
            </>
          )}
        </Route>
        <Route path="/fastBookingV2/filter" exact>
          <Filter />
        </Route>
        <Route path="/fastBookingV2/:id" exact>
          <RoomFastbooking during={during} setDuring={setDuring} />
        </Route>
      </Switch>
    </>
  );
};

export default Fastbooking;
