import { Avatar, Button, Card, Col, Input, message, Row, Space, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import useDebounce from "../../../hook/useDebounce";
import { useDispatch, useSelector } from "react-redux";

import { useTranslation } from "react-i18next";
import Glyph from "../../Common/Glyph/Glyph";
import { ReactComponent as Search } from "../../../assets/svg/searchPhoneBook.svg";
import useDidUpdateEffect from "../../../hook/useDidUpdate";
import { searchUsers, sendFollow } from "../../../services/redux/services/SpaceService";
import { isEmpty } from "ramda";
import { useKeycloak } from "@react-keycloak/web";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import useDesignTokens from "../../../hook/useDesignTokens";
import { PhoneBookTitleSource } from "./PhoneBookTitle";
// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const STEPS = {
  SEARCH: "SEARCH",
  RESULTS: "RESULTS",
};

const PhoneBook = ({ onBack }) => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const { colors } = useDesignTokens();
  const { t } = useTranslation();

  const users = useSelector((state) => state.spaceServiceWS.users);
  const followed = useSelector((state) => state.spaceServiceWS.followed);
  const isLoading = useSelector(createLoadingSelector(["spaceService/searchUsers"]));
  const userData = useSelector((state) => state.userWS.userData);

  const [step, setStep] = useState(STEPS.SEARCH);
  const [searchInput, setSearchInput] = useState("");
  const [userInfos, setUserInfos] = useState();

  const searchInputDebounced = useDebounce(searchInput, 500);
  useDidUpdateEffect(() => {
    if (!!searchInput && searchInput.length >= 3) {
      dispatch(
        searchUsers({
          search: searchInputDebounced,
          page: 1,
          size: 15,
        }),
      );
    }

    // Voluntarily omiting some deps to run the hook only with search input
  }, [searchInputDebounced]);

  useEffect(() => {
    return () => {
      setStep(STEPS.SEARCH);
    };
  }, []);

  return (
    <>
      <PhoneBookTitleSource>
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={step === STEPS.RESULTS ? () => setStep(STEPS.SEARCH) : onBack}
        >
          {step === STEPS.RESULTS ? (
            <>
              <div style={{ cursor: "pointer", marginRight: "8px" }}>
                <Glyph name="arrow_back_ios" />
              </div>
              <Typography.Text strong>{t("phoneBook.backToSearch")}</Typography.Text>
            </>
          ) : (
            <>
              {!!onBack && (
                <div style={{ cursor: "pointer", marginRight: "8px" }}>
                  <Glyph name="arrow_back_ios" />
                </div>
              )}
              <Typography.Text strong>{t("phoneBook.title")}</Typography.Text>
            </>
          )}
        </div>
      </PhoneBookTitleSource>
      <div
        style={{
          display: step === STEPS.SEARCH ? "block" : "none",
        }}
      >
        <Input
          style={{ width: "100%", margin: "16px 0" }}
          placeholder={t("phoneBook.search")}
          prefix={<Glyph name="search" />}
          onChange={(e) => setSearchInput(e.target.value)}
          value={searchInput}
        />
        <Spin spinning={isLoading}>
          {isEmpty(users) || searchInput.length < 3 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexFlow: "column",
                textAlign: "center",
              }}
            >
              <Search style={{ marginBottom: "16px" }} />
              <Typography.Paragraph strong>{t("phoneBook.seachPhoneBook")}</Typography.Paragraph>
              <Typography.Paragraph strong type="secondary">
                {t("phonebook.useSearch")}
              </Typography.Paragraph>
            </div>
          ) : (
            <>
              <Space
                direction="vertical"
                size="small"
                style={{
                  width: "100%",
                  height: "420px",
                  overflowY: "scroll",
                  padding: "10px",
                }}
              >
                {users?.items?.map((item, index) => (
                  <Card
                    style={{ cursor: "pointer" }}
                    key={index}
                    onClick={() => {
                      setStep(STEPS.RESULTS);
                      setUserInfos(item);
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar
                          src={`${item?.photoUrl}?access_token=${keycloak.token}`}
                          style={{ marginRight: "8px" }}
                        >
                          {`${item?.firstname} ${item?.lastname}`
                            .toUpperCase()
                            .split(" ")
                            .map((i) => i.charAt(0).toUpperCase())
                            .join("")}
                        </Avatar>
                        <div style={{ display: "flex", flexFlow: "column" }}>
                          <Typography.Paragraph
                            style={{ marginBottom: "0" }}
                            strong
                          >{`${item?.firstname} ${item?.lastname}`}</Typography.Paragraph>
                          <Typography.Paragraph style={{ marginBottom: "0" }}>
                            {item?.function}
                          </Typography.Paragraph>
                        </div>
                      </div>
                      {followed?.find((f) => f?.userId === item?.id) && (
                        <div>
                          <Glyph name="how_to_reg" style={{ color: colors.primary_base }} />
                        </div>
                      )}
                    </div>
                  </Card>
                ))}
              </Space>
              {users?.nbItems < users?.totalItems ? (
                <Typography.Paragraph
                  type="secondary"
                  strong
                  style={{ marginTop: "16px", width: "390px" }}
                >
                  {t("phoneBook.number.contact", {
                    nbItems: users?.nbItems,
                    totalItems: users?.totalItems,
                  })}
                </Typography.Paragraph>
              ) : null}
            </>
          )}
        </Spin>
      </div>
      <div style={{ display: step === STEPS.RESULTS ? "block" : "none" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexFlow: "column",
            marginTop: "16px",
            padding: "8px 0",
          }}
        >
          <Col>
            <Avatar size={64} src={`${userInfos?.photoUrl}?access_token=${keycloak.token}`}>
              {`${userInfos?.firstname} ${userInfos?.lastname}`
                .toUpperCase()
                .split(" ")
                .map((i) => i.charAt(0).toUpperCase())
                .join("")}
            </Avatar>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <Typography.Paragraph
              style={{ margin: "8px 0 0 0" }}
              strong
            >{`${userInfos?.firstname} ${userInfos?.lastname}`}</Typography.Paragraph>
            <Typography.Paragraph style={{ margin: "8px 0 0 0" }}>
              {userInfos?.function}
            </Typography.Paragraph>
          </Col>
        </div>
        <div style={{ marginBottom: "30px" }}>
          {userInfos?.email && (
            <Card style={{ marginBottom: "16px" }}>
              <Row gutter={[10, 0]}>
                <Col>
                  <Glyph name="mail" style={{ color: colors.secondary_base }} />
                </Col>
                <Col>
                  <Typography.Text strong>{userInfos?.email}</Typography.Text>
                </Col>
              </Row>
            </Card>
          )}

          {userInfos?.mobilePhone && (
            <Card style={{ marginBottom: "16px" }}>
              <Row gutter={[10, 0]}>
                <Col>
                  <Glyph name="phone_iphone" style={{ color: colors.secondary_base }} />
                </Col>
                <Col>
                  <Typography.Text strong>{userInfos?.mobilePhone}</Typography.Text>
                </Col>
              </Row>
            </Card>
          )}

          {userInfos?.phone && (
            <Card style={{ marginBottom: "16px" }}>
              <Row gutter={[10, 0]}>
                <Col>
                  <Glyph name="phone" style={{ color: colors.secondary_base }} />
                </Col>
                <Col>
                  <Typography.Text strong>{userInfos?.phone}</Typography.Text>
                </Col>
              </Row>
            </Card>
          )}

          {userInfos?.compagny && (
            <Card style={{ marginBottom: "16px" }}>
              <Row gutter={[10, 0]}>
                <Col>
                  <Glyph name="business" style={{ color: colors.secondary_base }} />
                </Col>
                <Col>
                  <Typography.Text strong>{userInfos?.function}</Typography.Text>
                </Col>
              </Row>
            </Card>
          )}
        </div>
        <div style={{ display: "flex", justifyContent: "center", marginBottom: "16px" }}>
          <Button
            type="primary"
            icon={<Glyph name="mail" />}
            disabled={
              followed?.find((f) => f?.userId === userInfos?.id) ||
              userData?.profile?.id === userInfos?.id
            }
            onClick={() => {
              logEvent(analytics, "spaas_send_invite");
              dispatch(sendFollow({ userId: userInfos?.id }))
                .unwrap()
                .then(() => {
                  message.success(t("phoneBook.success"));
                })
                .catch((error) => {
                  logger.error(error);
                  if (error.code === "409") {
                    message.error(t("phoneBook.error.alreadySend"));
                  } else {
                    message.error(t("phoneBook.error"));
                  }
                });
              setStep(STEPS.SEARCH);
              setSearchInput("");
            }}
          >
            {t("phoneBook.send.invitation")}
          </Button>
        </div>
      </div>
    </>
  );
};

export default PhoneBook;
