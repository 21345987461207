import { Button, Card, Checkbox, Popover, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom";
import useDesignTokens from "../../../../hook/useDesignTokens";
import {
  getAllAgentTickets,
  getAllFloors,
  getAllManagerTickets,
  getAllReferentialsAgent,
  getAllReferentialsManager,
  getAllStatus,
} from "../../../../services/redux/services/TicketingV2WS";
import Glyph from "../../../Common/Glyph/Glyph";
import BatchActionDrawer from "../Component/BatchActionDrawer";
import FilterDrawer from "../Component/FilterDrawer";
import CreateOrEditTicketModal from "../Component/Modals/CreateOrEditTicketModal";
import OrderSelector from "../Component/OrderSelector";
import SiteSelector from "../Component/SiteSelector";
import TicketView from "../Ticket/TicketView";
import priorities from "../Ticket/Component/priorities.json";

const ManagerMain = ({ section }) => {
  const { colors, symbols } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const dispatch = useDispatch();
  const { ticketId } = useParams();
  const { search } = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(search);
  const site = urlParams.get("site");

  const userSite = useSelector((state) => state.clientsWS?.campus?.mapData);
  const allTickets = useSelector((state) => state.ticketingV2WS.allTickets);
  const defaultSiteId = useSelector((state) => state.clientsWS.campus?.mapData?.id);
  const viewId = useSelector((state) => state.ticketingV2WS.currentViewId);
  const isManager = useSelector(
    (state) => state.userWS.userData.profile.rights.ticketingV2?.managers,
  )?.includes(viewId);
  const isAgent = useSelector(
    (state) => state.userWS.userData.profile.rights.ticketingV2?.agents,
  )?.includes(viewId);

  const [selectedSite, setSelectedSite] = useState(site ? site : userSite.id);
  const [showCreateOrEditTicketModal, setShowCreateOrEditTicketModal] = useState(false);
  const [showBatchActionDrawer, setShowBatchActionDrawer] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [data, setData] = useState([]);
  const [userColumns, setUserColumns] = useState(["id", "title", "creator", "createdAt", "status"]);
  const [displayedColumns, setDisplayedColumns] = useState([]);
  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [dir, setDir] = useState("desc");
  const [showAgentTickets, setShowAgentTickets] = useState(isAgent);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });
  const [columns, setColumns] = useState([
    {
      title: <span className="">ID</span>,
      dataIndex: "id",
      render: (id) => <span style={{ fontWeight: "bold" }}>{id}</span>,
      width: 80,
    },
    {
      title: <span className="">{t("ticketingv2.Object")}</span>,
      dataIndex: "title",
      ellipsis: true,
      width: `calc(calc(100% - 380px)  / calc(${userColumns.length} - 3))`,
      render: (title, ticket) => (
        <Link
          to={`/${section}/ticket/${selectedSite}/${ticket.id}`}
          style={{ fontWeight: "bold", textWrap: "balance" }}
        >
          {title}
        </Link>
      ),
    },
    {
      title: <span className="">{t("ticketingv2.Requester")}</span>,
      dataIndex: "creator",
      ellipsis: true,
      width: `calc(calc(100% - 380px)  / calc(${userColumns.length} - 3))`,
      render: (creator) => (
        <span style={{ fontWeight: "bold", textWrap: "no-wrap" }}>{creator}</span>
      ),
    },
    {
      title: <span className="">{t("ticketingv2.CreatedAt")}</span>,
      width: 140,
      dataIndex: "createdAt",
      render: (createdAt) => (
        <span style={{ fontWeight: "bold", textWrap: "no-wrap" }}>{createdAt}</span>
      ),
    },
    {
      title: <span className="">{t("ticketingv2.Status")}</span>,
      dataIndex: "status",
      width: 160,
      render: (status) => (
        <span>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              position: "relative",
              width: `calc(${status.label?.length} * 10px + 40px)`,
              minWidth: "100%",
            }}
          >
            <div
              style={{
                backgroundColor: "#" + status.color,
                width: "100%",
                height: 30,
                margin: "-10px 0",
                opacity: 0.5,
                borderRadius: 20,
              }}
            />
            <Typography.Text
              style={{
                position: "absolute",
                color: "#" + status.color,
              }}
              strong
            >
              {status.label}
            </Typography.Text>
          </div>
        </span>
      ),
    },
    {
      title: <span className="">{t("ticketingv2.Type")}</span>,
      dataIndex: "type",
      ellipsis: true,
      width: `calc(calc(100% - 380px)  / calc(${userColumns.length} - 3))`,
    },
    {
      title: <span className="">{t("ticketingv2.Cat")}</span>,
      dataIndex: "category",
      ellipsis: true,
      width: `calc(calc(100% - 380px)  / calc(${userColumns.length} - 3))`,
    },
    {
      title: <span className="">{t("ticketingv2.SubCat")}</span>,
      dataIndex: "subCategory",
      ellipsis: true,
      width: `calc(calc(100% - 380px)  / calc(${userColumns.length} - 3))`,
    },
    {
      title: <span className="">{t("ticketingv2.AssignedTo")}</span>,
      dataIndex: "assignedTo",
      ellipsis: true,
      width: `calc(calc(100% - 380px)  / calc(${userColumns.length} - 3))`,
      render: (assignedTo) => <span style={{ fontWeight: "bold" }}>{assignedTo}</span>,
    },
    {
      title: <span className="">{t("ticketingv2.Localisation")}</span>,
      dataIndex: "localization",
      ellipsis: true,
      width: `calc(calc(100% - 380px)  / calc(${userColumns.length} - 3))`,
      id: "localizationColumn",
    },
    {
      title: <span className="">{t("ticketingv2.Priority")}</span>,
      dataIndex: "priority",
      ellipsis: true,
      width: `calc(calc(100% - 380px)  / calc(${userColumns.length} - 3))`,
      id: "priorityColumn",
    },
  ]);

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  };

  const handleTableChange = (pagination) => {
    setTableParams({
      ...tableParams,
      pagination,
    });
  };

  useEffect(() => {
    logger.log(site, userSite.id);
    setSelectedSite(site ? site : userSite.id);
  }, [site, userSite.id]);

  useEffect(() => {
    logger.log("isManager", isManager, "isAgent", isAgent);
  }, [isManager, isAgent]);

  useEffect(() => {
    if (!site) {
      history.push({ search: `?site=${defaultSiteId}` });
    }
  }, [history, site, defaultSiteId]);

  useEffect(() => {
    //old setSelectedTickets(selectedRowKeys.map((key) => data.find((d) => d.key === key)?.id));
    // new : put the whole ticket object in selectedTickets
    setSelectedTickets(selectedRowKeys.map((key) => data.find((d) => d.key === key)));
  }, [selectedRowKeys, data]);

  // fetch ref for actions
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      logger.log("fetching data");
      try {
        Promise.all([
          dispatch(getAllStatus({ siteId: selectedSite, locale, viewId })),
          dispatch(getAllFloors({ locale, siteId: selectedSite })),
          isManager
            ? dispatch(getAllReferentialsManager({ siteId: selectedSite, locale, viewId }))
            : dispatch(getAllReferentialsAgent({ siteId: selectedSite, locale, viewId })),
        ]);
        setIsLoading(false);
      } catch (error) {
        logger.error("Error fetching data:", error);
      }
    };
    if (!!viewId) {
      logger.log("fetching data", !!viewId, viewId);
      fetchData();
    }
  }, [isAgent, dispatch, locale, selectedSite, viewId, isManager]);

  // set columns links
  useEffect(() => {
    setColumns((prev) =>
      prev.map((c) =>
        c.dataIndex === "title"
          ? {
              ...c,
              render: (data, ticket) => (
                <Link
                  to={`/${section}/ticket/${selectedSite}/${ticket.id}`}
                  style={{ fontWeight: "bold" }}
                >
                  {data}
                </Link>
              ),
            }
          : c,
      ),
    );
  }, [selectedSite, userColumns, section]);

  // set displayed columns
  useEffect(() => {
    let newDisplayedColumns = [];
    userColumns.forEach((c) => {
      newDisplayedColumns.push(columns.find((col) => col.dataIndex === c));
    });
    setDisplayedColumns(newDisplayedColumns);
  }, [columns, data, userColumns]);

  // move column filter to last th
  useEffect(() => {
    const lastTh = document.querySelector("thead");
    const columnFilter = document.getElementById("columnFilter");
    if (!lastTh || !columnFilter) return;
    lastTh.appendChild(columnFilter);
    columnFilter.style.height = `${lastTh.offsetHeight}px`;
  }, [data, userColumns]);

  const fetchData = async () =>
    showAgentTickets
      ? dispatch(
          getAllAgentTickets({
            siteId: selectedSite,
            locale,
            dir,
            page: tableParams.pagination.current,
            size: tableParams.pagination.pageSize,
            status: filters.status,
            priority: filters.priority,
            type: filters.type,
            category: filters.cat,
            subCategory: filters.subCategory,
            floor: null,
            viewId: viewId,
          }),
        )
      : dispatch(
          getAllManagerTickets({
            siteId: selectedSite,
            locale,
            dir,
            page: tableParams.pagination.current,
            size: tableParams.pagination.pageSize,
            status: filters.status,
            priority: filters.priority,
            type: filters.type,
            category: filters.cat,
            subCategory: filters.subCategory,
            agentId: filters.agentId || null,
            floor: null,
          }),
        );

  // pour l'attribution d'agent, il faut que les options soient des agents qui sont autorisés sur les refs de tous les tickets

  useEffect(() => {
    setIsLoading(true);

    !!viewId &&
      fetchData().then((data) => {
        setTableParams((previous) => ({
          ...previous,
          pagination: {
            ...previous.pagination,
            total: data.payload?.totalItems,
          },
        }));
      });
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    filters,
    locale,
    selectedSite,
    dir,
    tableParams.pagination.current,
    showAgentTickets,
  ]);

  useEffect(() => {
    logger.log("filters", filters);
    logger.log("tableParams", tableParams);
    logger.log("selectedTickets", selectedTickets);
  }, [filters, selectedTickets, tableParams]);

  // format date
  useEffect(() => {
    let newData = [];
    allTickets?.items?.forEach((ticket, i) => {
      newData.push({
        key: i,
        creator: ticket.requester?.firstname + " " + ticket.requester?.lastname,
        id: ticket.id,
        createdAt: moment(ticket.createdAt).format(
          locale === "fr" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY HH:mm",
        ),
        title: ticket.title || t("ticketingv2.NoTitle"),
        status: ticket.status || { label: t("ticketingv2.NoStatus"), color: "000000" },
        type: ticket.type?.label || t("ticketingv2.NoType"),
        category: ticket.category?.label || t("ticketingv2.NoCat"),
        subCategory: ticket.subCategory?.label || t("ticketingv2.NoSubCat"),
        assignedTo:
          ticket.agent?.firstname && ticket.agent?.lastname
            ? `${ticket.agent?.firstname} ${ticket.agent?.lastname}`
            : ticket.agent?.email
            ? ticket.agent?.email
            : ticket.agent?.id || t("ticketingv2.NoAgent"),
        localization: ticket.location
          ? ticket.location?.floor
            ? `${t("ticketingv2.Floor")} : ${ticket.location?.floor?.number} - ${
                ticket.location?.title
              }`
            : ticket.location?.title
          : t("ticketingv2.NoLocation"),
        priority:
          priorities.find((p) => p.name === ticket.priority)?.label[locale] ||
          t("ticketingv2.NoPriority"),
      });
    });
    setData(newData);
  }, [allTickets, locale, setColumns, t]);

  if (ticketId) {
    return <TicketView section={section} />;
  }

  if (isLoading) {
    return <div>{t("ticketingv2.Loading")}</div>;
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {/* Header buttons */}
        <div style={{ display: "flex", justifyContent: "end", margin: 16, gap: 8 }}>
          {isAgent && (
            <Tooltip title="Role: Agent">
              <Button type="primary" disabled={true} icon={<Glyph name="assignment" />} />
            </Tooltip>
          )}
          {isManager && (
            <Tooltip title="Role: Manager">
              <Button type="primary" disabled={true} icon={<Glyph name="assignment_ind" />} />
            </Tooltip>
          )}
          <Button
            type="secondary"
            onClick={() => setShowCreateOrEditTicketModal(true)}
            icon={<Glyph name="add" />}
          >
            {t("ticketingv2.NewTicket")}
          </Button>
          {/* <Button onClick={() => {}} icon={<Glyph name="ios_share" />}>
            Exporter
          </Button> */}
        </div>

        {/* Sort by date / Filters */}
        <div style={{ display: "flex", flexWrap: "wrap", gap: 10, margin: "8px 16px" }}>
          {!!selectedTickets.length ? (
            <Card
              bodyStyle={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: colors.primary_base,
                color: "white",
              }}
              onClick={() => setShowBatchActionDrawer(true)}
            >
              <Glyph name="edit" style={{ color: "white" }} />
              <Typography.Text strong style={{ marginLeft: "10px", color: "white" }}>
                {t("ticketingv2.Modify")}
              </Typography.Text>
            </Card>
          ) : (
            <SiteSelector width={244} />
          )}

          <OrderSelector dir={dir} setDir={setDir} />

          <Card
            onClick={() => setShowFilterDrawer(!showFilterDrawer)}
            style={{ cursor: "pointer" }}
          >
            <Glyph name="tune" className={"secondary"} />
            <Typography.Text strong style={{ color: "black", marginLeft: "10px" }}>
              {t("ticketingv2.AllFilters")}
            </Typography.Text>
          </Card>

          {isAgent && isManager && (
            <Card
              bodyStyle={{
                display: "flex",
                gap: 8,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Checkbox
                type="secondary"
                checked={showAgentTickets}
                onClick={() => setShowAgentTickets((p) => !p)}
              >
                <Typography.Text strong style={{ color: "black", marginLeft: "10px" }}>
                  {t("ticketingv2.AssignedToMe")}
                </Typography.Text>
              </Checkbox>
            </Card>
          )}
        </div>

        {/* Show/hide columns */}
        <div
          id="columnFilter"
          style={{
            height: "auto",
            position: "absolute",
            right: 0,
            top: 0,
            display: "flex",
            alignItems: "center",
            borderTopRightRadius: symbols.base_shape.radius,
            overflow: "hidden",
          }}
        >
          <Popover
            content={
              <div style={{ display: "flex", flexDirection: "column", gap: 18 }}>
                <Typography.Text strong>{t("ticketingv2.SelectColumnsToDisplay")}</Typography.Text>
                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                  {columns.map((c) => (
                    <div
                      key={c.dataIndex}
                      style={{ display: "flex", alignItems: "center", gap: 8 }}
                    >
                      <Checkbox
                        checked={userColumns.find((uc) => c.dataIndex === uc)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setUserColumns([...userColumns, c.dataIndex]);
                          } else {
                            setUserColumns(userColumns.filter((uc) => uc !== c.dataIndex));
                          }
                        }}
                      />
                      <Typography.Text>{c.title}</Typography.Text>
                    </div>
                  ))}
                </div>
              </div>
            }
            trigger="click"
            open={open}
            placement="bottomRight"
            onOpenChange={() => setOpen(!open)}
          >
            <div
              style={{
                padding: 8,
                backgroundColor: colors.primary_base,
                width: "fit-content",
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              <Glyph name="view_column" style={{ color: "white" }} />
            </div>
          </Popover>
        </div>

        {/* Tickets table */}
        <div style={{ display: "flex", gap: 8, height: "calc(100% - 180px)" }}>
          <div
            style={{ display: "flex", gap: 8, padding: "16px", overflowX: "auto", width: "100%" }}
          >
            <Table
              rowSelection={rowSelection}
              columns={displayedColumns}
              dataSource={data}
              pagination={tableParams.pagination}
              style={{ width: "100%" }}
              size="middle"
              onChange={handleTableChange}
            />
          </div>
        </div>
      </div>
      {/* New ticket Modal */}
      {showCreateOrEditTicketModal && (
        <CreateOrEditTicketModal
          showCreateOrEditTicketModal={showCreateOrEditTicketModal}
          setShowCreateOrEditTicketModal={setShowCreateOrEditTicketModal}
          site={selectedSite}
          locale={locale}
          filters={filters}
          section={section}
        />
      )}

      {/* Filter drawer */}
      <FilterDrawer
        showFilterDrawer={showFilterDrawer}
        setShowFilterDrawer={setShowFilterDrawer}
        rights="manager"
        siteId={selectedSite}
        setFilters={setFilters}
      />

      {/*  Batch Action Drawer */}
      {showBatchActionDrawer && (
        <BatchActionDrawer
          showBatchActionDrawer={showBatchActionDrawer}
          setShowBatchActionDrawer={setShowBatchActionDrawer}
          siteId={selectedSite}
          setFilters={setFilters}
          selectedTickets={selectedTickets}
          fetchData={fetchData}
        />
      )}
    </>
  );
};

export default ManagerMain;
