import { Typography } from "antd";
import { useEffect } from "react";
import { MapboxProvider, useMapboxInstance } from "../../../../Common/Map/Mapbox/useMapboxInstance";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { useTranslation } from "react-i18next";

const CreateStepWorkplaceMap = ({ roomId }) => {
  const { registerMap, mapReady, centerOnPlace, attributionPosition } = useMapboxInstance();
  const { colors } = useDesignTokens();
  const { t } = useTranslation();

  useEffect(() => {
    if (!mapReady) return;
    centerOnPlace(roomId, true, false);
  }, [attributionPosition, centerOnPlace, mapReady, roomId]);

  return (
    <>
      <div ref={registerMap} id="workplace_map" style={{ width: "100%", height: "100%" }} />
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: colors.light_background,
          opacity: mapReady ? 0 : 1,
          transition: mapReady ? "opacity .3s ease-in-out" : "none",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 10,
        }}
      >
        <Typography.Title level={4}>{t("ticketingv2.MapLoading")}</Typography.Title>
      </div>
    </>
  );
};

const Wrapper = ({ siteId, roomId }) => {
  return (
    <MapboxProvider siteId={siteId} attributionPosition="bottom-right">
      <CreateStepWorkplaceMap roomId={roomId} />
    </MapboxProvider>
  );
};

export default Wrapper;
