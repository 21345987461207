import { Card, Dropdown, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../../hook/useDesignTokens";
import {
  createTicketStatus,
  getAllAdminStatus,
  getAllGroups,
  getAllReferentialsAdmin,
  getAllSpaces,
  updateTicketStatus,
} from "../../../../services/redux/services/TicketingV2WS";
import Glyph from "../../../Common/Glyph/Glyph";
import CreateStatusModal from "../Component/Modals/CreateStatusModal";
import DeleteModal from "../Component/Modals/DeleteModal";
import SiteSelector from "../Component/SiteSelector";

const AdminMain = ({ section }) => {
  const { colors } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const dispatch = useDispatch();
  const history = useHistory();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const site = urlParams.get("site");

  const allStatus = useSelector((state) => state.ticketingV2WS.adminStatus);
  const userSite = useSelector((state) => state.clientsWS?.campus?.mapData);
  const referential = useSelector((state) => state.ticketingV2WS.referentialsAdmin);

  const [status, setStatus] = useState(allStatus);
  const [showCreateStatusModal, setShowCreateStatusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [statusToEdit, setStatusToEdit] = useState(null);

  useEffect(() => {
    if (!site) {
      history.push({ search: `?site=${userSite.id}` });
    }
  }, [history, site, userSite.id]);

  const refTypes =
    referential?.map((r) => ({
      key: r.id,
      label: r.label[locale],
      value: r.id,
      icon: r.icon,
    })) || [];

  /*useEffect(() => {
    const confirmLeave = (event) => {
      window.addEventListener("beforeunload", function (event) {
        event.preventDefault();
        event.returnValue = "";
        return "Êtes-vous sûr de vouloir quitter cette page ?";
      });

      window.addEventListener("beforeRemove", function (event) {
        if (!window.confirm("Êtes-vous sûr de vouloir revenir en arrière ?")) {
          event.preventDefault();
        }
      });
    };

    confirmLeave();

    return () => {
      window.removeEventListener("beforeunload", confirmLeave);
      window.removeEventListener("popstate", confirmLeave);
    };
  }, [history]);*/

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(getAllAdminStatus({ siteId: site, locale })),
          dispatch(getAllReferentialsAdmin({ siteId: site, locale })),
          dispatch(getAllGroups({ siteId: site })),
          dispatch(getAllSpaces({ siteId: site, locale })),
        ]);
      } catch (error) {
        logger.error("Error fetching data:", error);
      }
    };
    if (site) fetchData();
  }, [dispatch, locale, site]);

  const createFunction = async (statusData, statusId) => {
    try {
      const createStatus = await dispatch(
        statusId
          ? updateTicketStatus({
              siteId: site,
              data: { ...statusData, siteId: site, description: "" },
              statusId,
            })
          : createTicketStatus({
              siteId: site,
              data: { ...statusData, siteId: site, description: "" },
            }),
      );

      if (createStatus.meta.requestStatus === "fulfilled") {
        setShowCreateStatusModal(false);
        await dispatch(getAllAdminStatus({ siteId: site, locale }));
      }
    } catch (error) {
      logger.error("Error in status:", error);
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 40,
          padding: "0 24px",
          height: "100%",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 18,
            position: "relative",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text strong style={{ fontSize: 24 }}>
              {t("ticketingv2.referentialManagement")}
            </Typography.Text>
            <Typography.Text
              type="secondary"
              style={{ fontSize: 12, lineHeight: "16px", width: "50%" }}
            >
              {t("ticketingv2.referentialManagementComment")}
            </Typography.Text>
          </div>
          {/* <Button
            type="primary"
            disabled={!saveEnabled}
            style={{
              display: "flex",
              gap: 8,
              position: "absolute",
              padding: 12,
              right: 0,
            }}
          >
            <Glyph name="save" />
            {saveEnabled && "Enregistrer"}
          </Button> */}
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography.Text strong style={{ fontSize: 17 }}>
            {t("ticketingv2.siteSelector")}
          </Typography.Text>
          {site && <SiteSelector width={296} />}
        </div>

        {site && (
          <>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text strong style={{ fontSize: 17 }}>
                {t("ticketingv2.ticketTypes")}
              </Typography.Text>
              <Typography.Text
                type="secondary"
                style={{ fontSize: 12, lineHeight: "16px", marginBottom: 10, width: "50%" }}
              >
                {t("ticketingv2.ticketTypeComment")}
              </Typography.Text>
              <div style={{ display: "flex", flexWrap: "wrap", gap: 8 }}>
                <Link
                  to={`/${section}/referential/${site}${
                    !!referential?.length ? "/" + referential[0]?.id : ""
                  }`}
                >
                  <Card
                    style={{
                      width: 170,
                      height: 80,
                      cursor: "pointer",
                      backgroundColor: colors.primary_base,
                    }}
                    onClick={() => {}}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "start",
                        gap: 4,
                        padding: "8px 0px",
                      }}
                    >
                      <Glyph name="edit" style={{ fontSize: 24, color: "white" }} />
                      <Typography.Text
                        strong
                        style={{
                          fontSize: 14,
                          color: "white",
                          lineHeight: 1.2,
                        }}
                      >
                        {t("ticketingv2.Modify")}
                        <br />
                        {t("ticketingv2.theReferential")}
                      </Typography.Text>
                    </div>
                  </Card>
                </Link>
                {refTypes?.map((c, i) => (
                  <Card
                    key={i}
                    style={{
                      width: 170,
                      height: 80,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        textWrap: "balance",
                        gap: 8,
                      }}
                    >
                      <Glyph
                        name={c.icon || "search"}
                        style={{ fontSize: 24, color: colors.secondary_base }}
                      />
                      <Typography.Text strong style={{ fontSize: 12, lineHeight: 1.2 }}>
                        {c.label}
                      </Typography.Text>
                    </div>
                  </Card>
                ))}
              </div>
            </div>

            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography.Text strong style={{ fontSize: 17 }}>
                {t("ticketingv2.availableStatus")}
              </Typography.Text>
              <Typography.Text
                type="secondary"
                style={{ fontSize: 12, lineHeight: "16px", marginBottom: 10, width: "50%" }}
              >
                {t("ticketingv2.availableStatusComment")}
              </Typography.Text>
              {!!allStatus?.default?.length && (
                <>
                  <Typography.Text style={{ fontSize: 16 }}>
                    {t("ticketingv2.defaultStatus")}
                  </Typography.Text>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 8,
                      marginBottom: 20,
                    }}
                  >
                    {allStatus?.default?.map((s) => (
                      <Card
                        key={s.id}
                        style={{
                          width: 296,
                        }}
                        bodyStyle={{ padding: "15px 10px" }}
                      >
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 8,
                            padding: "0",
                          }}
                        >
                          <Glyph
                            name="fiber_manual_record"
                            style={{ fontSize: 24, color: s.color || colors.grey_40 }}
                          />
                          <Typography.Text strong style={{ fontSize: 14 }}>
                            {s.label[locale]}
                          </Typography.Text>
                        </div>
                      </Card>
                    ))}
                  </div>
                </>
              )}

              <Typography.Text style={{ fontSize: 16 }}>
                {t("ticketingv2.customStatus")}
              </Typography.Text>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 8,
                  marginBottom: 16,
                }}
              >
                <Card
                  style={{
                    width: 296,
                    cursor: "pointer",
                    backgroundColor: colors.primary_base,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  bodyStyle={{ padding: "15px 10px" }}
                  onClick={() => {
                    setStatus(null);
                    setStatusToEdit(null);
                    setShowCreateStatusModal(true);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 8,
                    }}
                  >
                    <Glyph name="add" style={{ fontSize: 24, color: "white" }} />
                    <Typography.Text strong style={{ fontSize: 14, color: "white" }}>
                      {t("ticketingv2.addStatus")}
                    </Typography.Text>
                  </div>
                </Card>
                {allStatus?.custom?.map((s) => (
                  <Card
                    key={s.id}
                    style={{
                      width: 296,
                      display: "flex",
                      justifyContent: "center",
                    }}
                    bodyStyle={{ padding: "15px 10px" }}
                  >
                    <div
                      style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                        }}
                      >
                        <Glyph
                          name="fiber_manual_record"
                          style={{
                            fontSize: 24,
                            color: s.color
                              ? s.color[0] === "#"
                                ? s.color
                                : colors[s.color]
                              : colors.grey_40,
                          }}
                        />
                        <Typography.Text strong style={{ fontSize: 14 }}>
                          {s.label[locale]}
                        </Typography.Text>
                      </div>
                      <Dropdown
                        trigger="click"
                        style={{ borderRadius: 0 }}
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: (
                                <div
                                  onClick={() => {
                                    setStatusToEdit(s);
                                    setShowCreateStatusModal(true);
                                  }}
                                >
                                  {t("ticketingv2.Modify")}
                                </div>
                              ),
                            },
                            {
                              key: "2",
                              danger: true,
                              label: (
                                <div
                                  onClick={() => {
                                    setStatus(s);
                                    setShowDeleteModal(true);
                                  }}
                                >
                                  {t("ticketingv2.Delete")}
                                </div>
                              ),
                            },
                          ],
                        }}
                      >
                        <Glyph
                          name="more_horiz"
                          style={{ fontSize: 24, color: colors.primary_dark, cursor: "pointer" }}
                        />
                      </Dropdown>
                    </div>
                  </Card>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
      {showCreateStatusModal && (
        <CreateStatusModal
          showCreateStatusModal={showCreateStatusModal}
          setShowCreateStatusModal={setShowCreateStatusModal}
          createFunction={createFunction}
          statusToEdit={statusToEdit}
        />
      )}
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          data={status}
          type="status"
          selectedSite={site}
        />
      )}
    </>
  );
};

export default AdminMain;
