const ConditionalWrapper = ({
  condition,
  renderIf = ({ children }) => children,
  renderElse = ({ children }) => children,
  children,
  ...rest
}) => {
  return condition ? renderIf({ children, ...rest }) : renderElse({ children, ...rest });
};

export default ConditionalWrapper;
