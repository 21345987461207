/* eslint-disable react-hooks/exhaustive-deps */
import { Button } from "antd";
import { isEmpty, isNil, pluck } from "ramda";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { useTranslation } from "react-i18next";

// redux
import { setFilter } from "../../../services/redux/services/FastbookingWS";
import { getFloorTemplates } from "../../../services/redux/services/RealTimeWS";

// import component
import Checkbox from "../Checkbox/Checkbox";
import useMap from "../Map/useMap";
import Glyph from "../Glyph/Glyph";
import "./Options.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";
import useDidUpdateEffect from "../../../hook/useDidUpdate";
import useDesignTokens from "../../../hook/useDesignTokens";

const Options = ({ type, setIsFloor, isFloor, filteredRoomCount, fetch }) => {
  const {
    selectedFloor,
    setFloorByReference,
    filterOnPlaceIds,
    templates,
    selectedContainerId,
    applyTemplate,
    MAP_PROVIDER,
  } = useMap();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { size } = useDesignTokens();

  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const allRoomFastbooking = useSelector((state) => state.fastbookingWS.allRoomFastbooking);
  const roomFastbooking = useSelector((state) => state.fastbookingWS.roomFastbooking);

  const showFloor = (e) => {
    setIsFloor(true);
    setFloorByReference(e.value);
    dispatch(
      setFilter({
        ...filters,
        floor: campus?.mapData?.floors?.find((floor) => floor.reference === e.value)?.id,
      }),
    );
    logEvent(analytics, "change_floor");
  };

  const options = campus?.mapData?.floors.map((floor) => ({
    value: floor.reference,
    label: (
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p style={{ margin: "0" }}>
          {t("Floor")} {floor.displayName}
        </p>
        {type === "fastbooking" && (
          <p style={{ margin: "0 5px" }}>
            {
              roomFastbooking?.rooms?.filter(
                (room) => room?.resources?.floorReference === floor?.reference,
              )?.length
            }
          </p>
        )}
      </div>
    ),
  }));

  const floorForSelect = campus?.mapData?.floors.find((f) => f.id === filters.floor);

  // Update filtre types quand on change le type
  const onClick = async (e) => {
    dispatch(setFilter({ ...filters, types: e.target.value, seats: 1 }));

    logEvent(analytics, "fast_booking_change_space_type", {
      space_type: campus?.mapData?.categories.find((c) => c.id === e.target.value)?.title,
    });
  };

  useEffect(() => {
    if (MAP_PROVIDER !== "findorder") return;
    // const { FnoMapManager } = window;
    // setFloorByReference(floorForSelect?.reference);
    dispatch(getFloorTemplates({ floor: floorForSelect?.reference }))
      .unwrap()
      .then((floorTemplates) => {
        for (let index = 0; index < floorTemplates.length; index++) {
          // FnoMapManager.sendEvent("setLocalTemplate", {
          //   container: floorTemplates[index].containerId,
          //   template: parseInt(floorTemplates[index].templateId),
          // });
          applyTemplate(
            floorTemplates[index].containerId,
            parseInt(floorTemplates[index].templateId),
          );
        }
        applyTemplate(
          selectedContainerId,
          templates.find((d) => d.name === "PrimarySelection​")?.id,
        );
        // FnoMapManager.sendEvent("setLocalTemplate", {
        //   container: selectedContainerId,
        //   template: templates.find((d) => d.name === "PrimarySelection​")?.id,
        // });
      });
  }, [dispatch, floorForSelect?.reference, selectedContainerId, templates]);

  useDidUpdateEffect(() => {
    if (!isFloor) return;
    if (selectedFloor?.reference === floorForSelect?.reference) return;
    setFloorByReference(floorForSelect?.reference);
  }, [setFloorByReference, floorForSelect?.reference]);

  // set floor by default
  useEffect(() => {
    if (isNil(filters.floor) && !isNil(campus?.mapData?.floors)) {
      dispatch(
        setFilter({
          ...filters,
          floor:
            campus?.mapData?.floors?.find((f) => f.default === true)?.id ||
            campus?.mapData?.floors[0]?.id ||
            null,
        }),
      );
    }
  }, [campus?.mapData?.floors, filters.floor, dispatch, filters]);

  useEffect(() => {
    const roomIds = (campus?.mapData?.resources || [])
      .filter((i) =>
        isNil(filters.types) || isEmpty(filters.types)
          ? true
          : filters.types.includes(i.categoryId),
      )
      .filter((i) =>
        type === "fastbooking"
          ? pluck("roomId", roomFastbooking?.rooms || []).includes(i.id)
          : true,
      )
      .map((i) => i.map?.findAndOrder?.placeId);
    filterOnPlaceIds(roomIds.length === campus?.mapData?.resources?.length ? null : roomIds);
  }, [
    campus?.mapData?.resources,
    filterOnPlaceIds,
    filters.types,
    roomFastbooking?.rooms,
    type,
    floorForSelect?.reference,
  ]);

  useEffect(() => {
    logger.log("filters", filters);
  }, [filters]);

  return (
    <section
      style={{
        marginLeft: "16px",
        display: "flex",
        height: "40px",
        width: "65%",
      }}
    >
      {options.length > 1 && (
        <Select
          className="select-options"
          classnameprefix="select-options"
          value={options?.find((o) => o.value === floorForSelect?.reference)}
          options={options}
          onChange={showFloor}
          isclearable="false"
          isrtl="false"
          issearchable="false"
        />
      )}

      {!!type && (
        <div
          style={{
            overflowX: "auto",
            zIndex: "999",
            display: "flex",
            height: "60px",
            paddingRight: "5px",
            gap: 8,
          }}
        >
          <Button
            style={{ fontSize: size.m }}
            className={isNil(filters.types) ? "active-allFilter" : "btnAllFilter"}
            onClick={() => {
              dispatch(setFilter({ ...filters, types: null }));
            }}
          >
            <Glyph name="group_work" style={{ verticalAlign: "-2px", fontSize: size.l }} />
            {t("AllSpaces")}
          </Button>
          {campus?.mapData?.categories
            .filter((c) => c.useAsFilter === true)
            .map((categorie) => (
              <Checkbox
                key={categorie.id}
                value={categorie.id}
                glyph={categorie.icon}
                text={categorie.title}
                onClick={onClick}
                checked={filters?.types === categorie.id}
                textStyle={{
                  lineHeight: "12px",
                  textAlign: "center",
                }}
              />
            ))}
        </div>
      )}
    </section>
  );
};

export default Options;
