import { Button, Card, Col, Form, Grid, Row, Typography } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";

// import component
import useSelectedRoom from "../../../../services/redux/useSelectedRoom";
import useMap, { useMapLayer } from "../../../Common/Map/useMap";
import Glyph from "../../../Common/Glyph/Glyph";
import RoomDetails from "../../../Common/RoomDetails/RoomDetails";
import useDesignTokens from "../../../../hook/useDesignTokens";

// import css
import Confirmation from "./Confirmation";

// import redux
import { addFavoris, removeFavoris } from "../../../../services/redux/services/ClientsWS";
import {
  getRoomFastbooking,
  setMakeBooking,
} from "../../../../services/redux/services/FastbookingWS";
import { TitleSource } from "../../../Common/Teleporters/Title";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";
import { useEffect } from "react";
import { getPlanning } from "../../../../services/redux/services/PlanningWS";
import { isEmpty, isNil } from "ramda";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

let time;
const mapOptions = {
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 358,
  },
};

const RoomFastbooking = ({ during, setDuring }) => {
  const { centerOnPlaceId } = useMap();
  useMapLayer(mapOptions);
  const location = useLocation();
  const dispatch = useDispatch();
  const selectedRoom = useSelectedRoom();
  const { colors, size } = useDesignTokens();
  const screens = Grid.useBreakpoint();

  const { t, i18n } = useTranslation();

  const [isVisibleConfirmation, setVisibleConfirmation] = useState(false);
  const [current, setCurrent] = useState(0);
  const [status, setStatus] = useState(null);
  const [form] = Form.useForm();

  const favoris = useSelector((state) => state.clientsWS.favoris);
  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const occupancy = useSelector((state) => state.occupancyWS.occupancy);
  const rooms = useSelector((state) => state.fastbookingWS.roomFastbooking.rooms);

  const roomFavoris = favoris.find((f) => f.id === selectedRoom.id);

  const addFav = () => {
    dispatch(addFavoris(selectedRoom));
  };

  const removeFav = () => {
    dispatch(removeFavoris(selectedRoom.id));
  };

  const handleSubmit = async () => {
    logEvent(analytics, "fast_booking_booking_duration", {
      duration: during,
    });
    time = setTimeout(() => {
      dispatch(
        setMakeBooking({
          roomId: selectedRoom?.id,
          startDate: filters.startDate ? filters.startDate : moment().toISOString(),
          duration: during,
        }),
      )
        .unwrap()
        .then(() => {
          logEvent(analytics, "fast_booking_book_confirmed");
          setCurrent(current + 1);
          setStatus("success");
          dispatch(
            getPlanning({
              locale: i18n.language.split("-")[0],
              startDate: filters.startDate ? filters.startDate : moment().toISOString(),
              endDate: moment().add(7, "days").toISOString(),
            }),
          );
        })
        .catch(() => {
          setCurrent(current);
          setCurrent(current + 2);
          setStatus("error");
        });
    }, 6000);
  };

  useEffect(() => {
    if (!isEmpty(filters) && !isNil(filters)) {
      dispatch(
        getRoomFastbooking({
          locale: i18n.language.split("-")[0],
          ...filters,
          startDate: filters.startDate ? filters.startDate : moment().toISOString(),
        }),
      );
    }
  }, [dispatch, filters, i18n.language]);

  useEffect(() => {
    if (!location?.state?.room) return;
    centerOnPlaceId(selectedRoom?.map?.findAndOrder?.placeId);
  }, [centerOnPlaceId, location?.state?.room, selectedRoom?.map?.findAndOrder?.placeId]);

  return (
    <>
      <TitleSource>{t("fast_booking_title", { ns: "csv" })}</TitleSource>
      <Card
        title={
          <>
            <Typography.Title level={5}>
              <NavLink
                to={{
                  pathname: location.state.back || "/fastBookingV2/map",
                  state: { state: true },
                }}
                onClick={() => centerOnPlaceId()}
              >
                <Glyph
                  name="arrow_back_ios"
                  style={{
                    verticalAlign: "-2px",
                    fontSize: size.xxl,
                    color: "black",
                    marginRight: "8px",
                  }}
                />
              </NavLink>
              {selectedRoom?.title}
            </Typography.Title>
            <Typography.Text strong style={{ fontSize: size.m, color: colors.pending_light }}>
              {!!selectedRoom?.features?.bookable && occupancy.status === "AVAILABLE"
                ? rooms.find((r) => r.roomId === selectedRoom.id)?.availableTime >= 60
                  ? `${t("Available")} > 1h - `
                  : `${rooms.find((r) => r.roomId === selectedRoom.id)?.availableTime} mins`
                : null}
              {t("Floor")}{" "}
              {
                campus?.mapData?.floors.find(
                  (floor) => floor.reference === selectedRoom.floorReference,
                )?.displayName
              }
            </Typography.Text>
          </>
        }
        extra={
          roomFavoris ? (
            <Button
              style={{ border: "none", background: "none", color: colors.secondary_base }}
              type="primary"
              icon={<Glyph name="star" />}
              onClick={removeFav}
            ></Button>
          ) : (
            <Button
              style={{ border: "none", background: "none", color: colors.grey_40 }}
              type="primary"
              icon={<Glyph name="star" />}
              onClick={addFav}
            ></Button>
          )
        }
        actions={[
          occupancy.status === "AVAILABLE" && selectedRoom?.features?.bookable === true ? (
            <Button
              type="primary"
              style={{ width: "318px" }}
              disabled={!during}
              onClick={() => {
                setVisibleConfirmation(true);
                form.submit();
              }}
            >
              {t("Book")}
            </Button>
          ) : (
            <Button
              type="primary"
              style={{ width: "318px" }}
              onClick={() => {
                logEvent(analytics, "fast_booking_itinerary");
              }}
            >
              <Link
                to={{
                  pathname: "/indoorMapping/itinerary",
                  state: {
                    back: `/fastBookingV2/${selectedRoom?.map?.findAndOrder?.placeId}`,
                    selectedRoom: selectedRoom,
                  },
                }}
                style={{ color: "white" }}
              >
                {t("SeeItinerary")}
              </Link>
            </Button>
          ),
        ]}
        style={{
          width: screens.xl ? "35%" : 350,
          minWidth: screens.xl ? "35%" : 350,
          marginLeft: 8,
          height: "fit-content",
        }}
      >
        <RoomDetails room={selectedRoom} withTitle={false} />
        {!!selectedRoom?.features?.bookable && (
          <Form
            form={form}
            style={{ marginTop: "30px" }}
            initialValues={{
              startDate: moment(),
            }}
            onFinish={handleSubmit}
          >
            <Row gutter={[10, 20]}>
              <Col span={24}>
                <Card>
                  <Row>
                    <Col flex={"auto"}>
                      <Typography.Text strong>
                        <Glyph
                          name="event"
                          style={{ color: colors.secondary_base, marginRight: "8px" }}
                        />
                        {t("BookThe")} {moment().format("DD MMMM YYYY")}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <Row>
                    <Col flex={"auto"}>
                      <Typography.Text strong>
                        <Glyph
                          name="schedule"
                          style={{ color: colors.secondary_base, marginRight: "8px" }}
                        />
                        {t("At")}{" "}
                        {filters.startDate
                          ? moment(filters.startDate).format("HH:mm")
                          : moment().format("HH:mm")}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col span={24}>
                <Card>
                  <Row>
                    <Col flex={"auto"}>
                      <Typography.Text strong>
                        <Glyph
                          name="timelapse"
                          style={{ color: colors.secondary_base, marginRight: "8px" }}
                        />
                        {t("When")}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          shape="circle"
                          size="small"
                          style={{ border: "none" }}
                          icon={<Glyph name="remove_circle" />}
                          onClick={() => {
                            if (during !== 30) setDuring(during - 15);
                          }}
                        ></Button>
                        <Typography.Text>
                          {during < 60
                            ? `${during} mins`
                            : moment().startOf("day").add(during, "minutes").format("hh[h]mm")}
                        </Typography.Text>
                        <Button
                          shape="circle"
                          size="small"
                          style={{ border: "none" }}
                          icon={<Glyph name="add_circle" />}
                          onClick={() => {
                            if (during !== 240) setDuring(during + 15);
                          }}
                        ></Button>
                      </div>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </Card>

      <Confirmation
        isVisible={isVisibleConfirmation}
        current={current}
        setCurrent={setCurrent}
        status={status}
        selectedRoom={selectedRoom}
        duration={during}
        onCancel={() => {
          setVisibleConfirmation(false);
          clearTimeout(time);
        }}
      />
    </>
  );
};

export default RoomFastbooking;
