import {
  Button,
  Card,
  Col,
  Input,
  Modal,
  Progress,
  Row,
  Spin,
  Typography,
  Upload,
  message,
} from "antd";
import Glyph from "../../../../Common/Glyph/Glyph";
import { useEffect, useState } from "react";
import styles from "../../News.module.less";
import { useDispatch, useSelector } from "react-redux";
import { addImagesNews, getImageNews } from "../../../../../services/redux/services/NewsWS";
import { useKeycloak } from "@react-keycloak/web";
import moment from "moment";
import clsx from "clsx";
import { isNil } from "ramda";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import { createLoadingSelector } from "../../../../../services/redux/managers/LoadingManager";

const { Meta } = Card;

const PicturesLibrary = ({ isModalOpen, onCancel, setSelectPictures }) => {
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();
  const { colors, size } = useDesignTokens();
  const { t } = useTranslation();

  const images = useSelector((state) => state.newsWS.images);
  const isLoadingImage = useSelector(createLoadingSelector(["news/getImageNews"]));

  const [activeIndex, setActiveIndex] = useState(null);
  const [moreImages, setMoreImages] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [searchImages, setSearchImages] = useState("");

  useEffect(() => {
    dispatch(getImageNews({ date: moment().toISOString(), init: true }));
  }, [dispatch]);

  const handleImageUpload = (fileList) => {
    const file = fileList[0].originFileObj;
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      const MAX_WIDTH = 1000; // Définissez la largeur maximale souhaitée pour l'image compressée
      const MAX_HEIGHT = 1000; // Définissez la hauteur maximale souhaitée pour l'image compressée

      let width = img.width;
      let height = img.height;

      // Réduisez les dimensions de l'image tout en conservant le rapport largeur/hauteur
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      // Définissez les dimensions du canvas
      canvas.width = width;
      canvas.height = height;

      // Dessinez l'image sur le canvas avec les nouvelles dimensions
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Obtenez l'image compressée au format JPEG (vous pouvez également utiliser PNG si nécessaire)
      const compressedImage = canvas.toDataURL("image/png");

      const response = await fetch(compressedImage);
      const blob = await response.blob();

      dispatch(addImagesNews({ image: blob, fileName: file.name }))
        .unwrap()
        .then(() => {
          dispatch(getImageNews({ date: moment().toISOString(), init: true }));
          message.success(t("news.editor.library.import.success"));
          setIsLoading(false);
        })
        .catch(() => message.error(t("news.editor.library.import.error")));
    };
  };

  const confirm = (fileList) => {
    Modal.confirm({
      title: t("news.editor.library.import.modal.title"),
      content: (
        <div>
          {t("news.editor.library.import.modal.p1")} <br />
          {t("news.editor.library.import.modal.p2")}
        </div>
      ),
      cancelText: <div block>{t("Cancel")}</div>,
      onOk: () => {
        handleImageUpload(fileList);
        setIsLoading(true);
      },
      okText: <div block>{t("Next")}</div>,
    });
  };

  const handleSearch = (evt) => {
    setSearchImages(evt.target.value);
  };
  const searchResult = images.filter((value) =>
    searchImages ? new RegExp(searchImages, "i").test(value?.filename) : true,
  );

  return (
    <Modal
      width={950}
      bodyStyle={{ height: "600px", overflowY: "scroll" }}
      title={
        <Typography.Text
          strong
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {t("news.editor.library.title")}
        </Typography.Text>
      }
      open={isModalOpen}
      onCancel={onCancel}
      footer={[
        <Button
          type="primary"
          disabled={isNil(activeIndex)}
          onClick={() => {
            setSelectPictures(images[activeIndex]);
            onCancel();
          }}
        >
          {t("news.editor.library.validation")}
        </Button>,
      ]}
    >
      <div
        style={{ display: "flex", width: "100%", justifyContent: "space-between" }}
        className={styles.upload}
      >
        <div style={{ display: "flex", width: "100%", flexFlow: "column" }}>
          <Typography.Text strong style={{ width: "500px" }}>
            {t("news.editor.library.content")}
          </Typography.Text>
          <Typography.Text type="secondary" style={{ width: "500px", fontSize: size.s }}>
            {t("news.editor.library.format")}
          </Typography.Text>
        </div>

        <div style={{ marginRight: "16px" }}>
          <Input
            placeholder={"Rechercher une image"}
            prefix={<Glyph name="search" />}
            style={{ height: "50px", width: "200px" }}
            onChange={handleSearch}
            value={searchImages}
          />
        </div>

        <Upload
          accept="image/*"
          maxCount={1}
          beforeUpload={() => false}
          onChange={({ fileList }) => confirm(fileList)}
        >
          <Button type="secondary" icon={<Glyph name="publish" />}>
            {t("news.editor.library.import")}
          </Button>
        </Upload>
      </div>
      <Spin
        spinning={isLoading || isLoadingImage}
        indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      >
        <Row style={{ width: "100%", marginTop: "16px" }} gutter={[10, 10]}>
          {searchResult?.map((image, index) => (
            <Col span={6} key={index}>
              <Card
                className={clsx(styles.cardPictures, {
                  [styles.activePictures]: activeIndex === index,
                })}
                onClick={() => {
                  setActiveIndex(activeIndex === index ? null : index);
                }}
                cover={
                  <div style={{ boxShadow: "none" }} className={styles["picturesHeader"]}>
                    {activeIndex === index && (
                      <Glyph
                        name="check_circle"
                        style={{
                          position: "absolute",
                          right: 5,
                          top: 5,
                          color: colors?.interactive_03,
                        }}
                      />
                    )}
                    <img alt="imageheader" src={`${image?.url}?access_token=${keycloak.token}`} />
                  </div>
                }
              >
                <Meta
                  title={
                    <Typography.Text strong style={{ fontSize: size.m }}>
                      {image?.filename}
                    </Typography.Text>
                  }
                  description={
                    <Typography.Text type="secondary" style={{ fontSize: size.s }}>
                      {moment(image?.createdAt).format("L")}
                    </Typography.Text>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </Spin>
      {(moreImages?.length === 12 || isNil(moreImages)) && (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
          <Button
            ghost
            size="medium"
            icon={<Glyph name="add" />}
            onClick={() => {
              dispatch(
                getImageNews({
                  date: moment(images.slice(-1)[0]?.createdAt).toISOString(),
                }),
              )
                .unwrap()
                .then((data) => setMoreImages(data));
            }}
          >
            {t("SeeMore")}
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default PicturesLibrary;
