import { Button, Card, Modal, Select, Typography } from "antd";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { useEffect, useState } from "react";
import WarningMessage from "../WarningMessage";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  deleteTicketReferential,
  deleteTicketStatus,
  getAllAdminStatus,
  getAllReferentialsAdmin,
  getAdminReferential,
  getAdminStatus,
} from "../../../../../services/redux/services/TicketingV2WS";
import Glyph from "../../../../Common/Glyph/Glyph";

const DeleteModal = ({ showDeleteModal, setShowDeleteModal, data, type, selectedSite }) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const { colors, symbols } = useDesignTokens();
  const [newStatus, setNewStatus] = useState(null);
  const dispatch = useDispatch();

  const statusTickets = useSelector((state) => state.ticketingV2WS.statusTickets);
  const adminStatus = useSelector((state) => state.ticketingV2WS.adminStatus);

  const allStatus = adminStatus?.default.concat(adminStatus?.custom);
  const statusOptions = allStatus?.map((s) => ({
    label: s.label[locale],
    value: s.id,
  }));

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(
        type === "status"
          ? getAdminStatus({ siteId: selectedSite, statusId: data.id })
          : getAdminReferential({ siteId: selectedSite, stuffId: data.id }),
      );
    };
    fetchData();
  }, [data, dispatch, selectedSite, type]);

  const deleteFunction = async () => {
    try {
      const del = await dispatch(
        type === "status"
          ? deleteTicketStatus({
              siteId: selectedSite,
              statusId: data.id,
              status: newStatus?.id,
            })
          : deleteTicketReferential({
              siteId: selectedSite,
              referentialId: data.id,
            }),
      );

      if (del.meta.requestStatus === "fulfilled") {
        setShowDeleteModal(false);
        await dispatch(
          type === "status"
            ? getAllAdminStatus({ siteId: selectedSite })
            : getAllReferentialsAdmin({ siteId: selectedSite, locale }),
        );
      }
    } catch (error) {
      logger.error("Error during deletion:", error);
    }
  };

  //! requalify tickets to another status

  return (
    <Modal
      open={showDeleteModal}
      width={620}
      onCancel={() => setShowDeleteModal(false)}
      title={null}
      footer={null}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: "16px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t("ticketingv2.Delete")}&nbsp;
          {type === "type" && t("ticketingv2.aType")}
          {type === "category" && t("ticketingv2.aCat")}
          {type === "subCategory" && t("ticketingv2.aSubCat")}
          {type === "status" && t("ticketingv2.aStatus")}
          <span style={{ color: colors.secondary_base }}>
            &nbsp;
            {type === "status"
              ? data?.label[locale][0]?.toUpperCase() + data?.label[locale]?.slice(1)
              : data?.label[locale][0]?.toUpperCase() + data?.label[locale]?.slice(1)}
          </span>
        </Typography.Text>

        <WarningMessage caption={t("ticketingv2.WarningIrreversibleAction")} />

        {/* requalify status */}
        {type === "status" && !!statusTickets?.countTickets && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: colors.light_background,
              padding: 8,
              borderRadius: symbols.base_shape.radius,
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <Typography.Text strong style={{ fontSize: 14 }}>
                {t("ticketingv2.RequalifyStatus")}
              </Typography.Text>
            </div>
            <Card style={{ height: 42 }}>
              <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                <Glyph
                  name="fiber_manual_record"
                  style={{
                    color: allStatus?.find((s) => s.id === newStatus)?.color || colors.grey_40,
                  }}
                />
                <Select
                  className="accent"
                  bordered={false}
                  size={"middle"}
                  style={{
                    width: "100%",
                    color: "black !important",
                  }}
                  options={statusOptions}
                  value={newStatus}
                  onChange={(value) => setNewStatus(value)}
                  suffixIcon={<Glyph name="arrow_drop_down" />}
                />
              </div>
            </Card>
          </div>
        )}

        {/* buttons */}
        <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
          <Button type="tertiary" onClick={() => setShowDeleteModal(false)} style={{ width: 200 }}>
            {t("ticketingv2.Cancel")}
          </Button>
          <Button
            type="primary"
            disabled={!!statusTickets?.countTickets && !newStatus}
            onClick={deleteFunction}
            style={{ width: 200 }}
          >
            {t("ticketingv2.Delete")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
