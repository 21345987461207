import { Button, Input, Modal, Rate, Typography } from "antd";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getTicketDetails, rateTicket } from "../../../../../services/redux/services/TicketingV2WS";

const RateModal = ({ showRateModal, setShowRateModal, locale, data }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  const [comment, setComment] = useState(
    data.history?.find((h) => h.action === "rating")?.comment.content || "",
  );
  const [rating, setRating] = useState(data.rating || 0);
  const dispatch = useDispatch();

  const rateFunction = async () => {
    try {
      const rate = await dispatch(
        rateTicket({
          siteId: data.siteId,
          ticketId: data.id,
          data: {
            rating,
            comment,
          },
        }),
      );

      if (rate.meta.requestStatus === "fulfilled") {
        setShowRateModal(false);
        await dispatch(
          getTicketDetails({
            siteId: data.siteId,
            ticketId: data.id,
            locale,
          }),
        );
      }
    } catch (error) {
      logger.error("Error during rating:", error);
    }
  };

  return (
    <Modal
      open={showRateModal}
      width={450}
      onCancel={() => setShowRateModal(false)}
      title={
        <Typography.Text strong style={{ fontSize: 16 }}>
          {data ? t("ticketingv2.TicketEvaluation") : t("ticketingv2.EvaluateTicketResolution")}
        </Typography.Text>
      }
      footer={
        <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
          <Button type="tertiary" onClick={() => setShowRateModal(false)} style={{ width: 200 }}>
            {t("ticketingv2.Back")}
          </Button>
          {!!!data.rating && (
            <Button
              type="primary"
              disabled={rating === "" || comment === ""}
              onClick={rateFunction}
              style={{ width: 200 }}
            >
              {t("ticketingv2.ValidateEvaluation")}
            </Button>
          )}
        </div>
      }
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: 38,
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t("ticketingv2.GlobalRating")}
        </Typography.Text>
        <div
          style={{
            display: "flex",
            gap: 8,
            justifyContent: "space-around",
            width: "100%",
            marginBottom: 32,
          }}
        >
          <Rate
            style={{
              color: colors.primary_base,
              display: "flex",
              gap: 20,
              fontSize: 32,
            }}
            value={rating}
            disabled={!!data.rating}
            onChange={(value) => setRating(value)}
          />
        </div>

        <Typography.Text strong style={{ fontSize: 16 }}>
          {t("ticketingv2.Comment")}
        </Typography.Text>
        {!!data.rating ? (
          <Typography.Text style={{ fontSize: 14 }}>{comment}</Typography.Text>
        ) : (
          <Input.TextArea
            placeholder={t("ticketingv2.TypeComment")}
            style={{ height: 100 }}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        )}
      </div>
    </Modal>
  );
};

export default RateModal;
