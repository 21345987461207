import React, { useEffect, useState } from "react";
import Glyph from "../../Common/Glyph/Glyph";
import ChannelListHeader from "@sendbird/uikit-react/ChannelList/components/ChannelListHeader";
import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context";
import ChannelPreview from "@sendbird/uikit-react/ChannelList/components/ChannelPreview";
import { Avatar, Dropdown, Grid, Typography } from "antd";
import Tile from "../../Common/Tile/Tile";
import UserListModal from "./UserListModal";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../hook/useDesignTokens";

function CustomChannelList({
  setCurrentChannel,
  setQuery,
  setShowLeaveChannelModal,
  showContactList,
  setShowContactList,
}) {
  const context = useChannelListContext();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { colors, size } = useDesignTokens();
  const userData = useSelector((state) => state.userWS.userData);
  const appHasContactList = userData?.views?.some((v) => v.type === "contactListEnhanced");
  const screens = Grid.useBreakpoint();

  const [visible, setVisible] = useState(false);
  const [overedChannel, setOveredChannel] = useState(null);
  const { fetchChannelList } = useChannelListContext();

  // infinite scrolling
  useEffect(() => {
    const fectchNext = async () => fetchChannelList();

    const container = document.getElementById("container");
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        fectchNext();
      }
    };
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [context, fetchChannelList]);

  /*useEffect(() => {
    logger.log("context", context);
  }, [context]);*/

  const avatarContent = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <Avatar
        size="medium"
        src={`${userData.profile.photoUrl}?access_token=${keycloak.token}`}
        style={{ border: "none", boxShadow: "none" }}
      >
        {userData.profile.firstName?.charAt(0).toUpperCase()}
        {userData.profile.lastName?.charAt(0).toUpperCase()}
      </Avatar>
      <Typography.Text strong style={{ marginTop: "2px" }}>
        {userData.profile.firstName} {userData.profile.lastName}
      </Typography.Text>
    </div>
  );

  return (
    <>
      <ChannelListHeader
        renderHeader={() => (
          <>
            <Tile
              style={{
                margin: 0,
                backgroundColor: "white",
                cursor: "auto",
                padding: "0 0 0 8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {showContactList ? (
                  appHasContactList ? (
                    <Link to={`/contactListEnhanced/${userData.profile.id}`}>{avatarContent}</Link>
                  ) : (
                    avatarContent
                  )
                ) : null}

                <div style={{ display: "flex", gap: "8px" }}>
                  <Glyph
                    name="add_comment"
                    className="sendbird-iconbutton"
                    style={{ fontSize: size.xxxxl, cursor: "pointer" }}
                    onClick={() => setVisible(true)}
                  />

                  {screens.lg ? null : showContactList ? (
                    <Glyph
                      name="vertical_align_top"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: size.xxxxl,
                        marginTop: "-2px",
                        transform: "rotate(-90deg)",
                        color: colors.secondary_base,
                      }}
                      onClick={() => setShowContactList((p) => !p)}
                    />
                  ) : (
                    <Glyph
                      name="vertical_align_top"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: size.xxxxl,
                        marginTop: "-2px",
                        transform: "rotate(90deg)",
                        color: colors.secondary_base,
                      }}
                      onClick={() => setShowContactList((p) => !p)}
                    />
                  )}
                </div>
              </div>
            </Tile>
            {/* Search channel : To be added in a future version
            <Input
              prefix={<Glyph name="search" style={{ color: colors.pending_light }} />}
              onChange={(e) => {
                setQuery({
                  channelListQuery: {
                    nicknameContainsFilter: e.target.value,
                  },
                });
                setSearchValue(e.target.value);
              }}
              placeholder="placeholder_search_channel"
              value={searchValue}
              suffix={function CustomChannelList({)}
              style={{ margin: "8px", width: "288px" }}
            />
            />*/}
          </>
        )}
      />

      <div
        style={{ height: "calc(100% - 65px)", overflowY: "auto", overflowX: "hidden" }}
        id="container"
      >
        {context.allChannels?.length > 0 &&
          context.allChannels.map((channel, index) => (
            <div
              onMouseEnter={() => setOveredChannel(channel.url)}
              onMouseLeave={() => setOveredChannel(null)}
              key={channel.url}
              onClick={() => setCurrentChannel(channel)}
              id={channel.url}
              className="custom-channel-preview"
              style={{ position: "relative" }}
            >
              {!showContactList && channel.unreadMessageCount > 0 ? (
                <div
                  style={{
                    position: "absolute",
                    top: 10,
                    right: 15,
                    backgroundColor: colors.secondary_base,
                    color: "white",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: 20,
                    minWidth: 20,
                    borderRadius: 10,
                    zIndex: 1,
                    fontSize: "12px",
                  }}
                >
                  {channel.unreadMessageCount}
                </div>
              ) : (
                ""
              )}

              <ChannelPreview
                channel={channel}
                tabIndex={index + 1}
                renderChannelAction={() => (
                  <div style={{ marginTop: "-5px", marginRight: "-10px" }}>
                    {overedChannel === channel.url && (
                      <Dropdown
                        menu={{
                          items: [
                            {
                              key: "1",
                              label: (
                                <div onClick={() => setShowLeaveChannelModal(true)}>
                                  {t("CHANNEL_SETTING__LEAVE_CHANNEL__TITLE")}
                                </div>
                              ),
                            },
                          ],
                        }}
                        trigger={["click"]}
                        placement="bottomRight"
                      >
                        <Glyph
                          name="more_vert"
                          style={{
                            fontSize: size.xxxxl,
                            marginRight: "3px",
                            marginBottom: "20px",
                          }}
                        />
                      </Dropdown>
                    )}
                  </div>
                )}
                isActive={channel.url === context.currentChannel?.url}
              />
            </div>
          ))}
      </div>
      {visible && (
        <UserListModal
          visible={visible}
          setVisible={setVisible}
          type="createChannel"
          setQuery={setQuery}
          setCurrentChannel={setCurrentChannel}
        />
      )}
    </>
  );
}

export default CustomChannelList;
