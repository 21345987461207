import { Button, Col, Form, Row, Segmented, Typography, message } from "antd";
import { useEffect, useState } from "react";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";
import Toolbar from "./component/Toolbar";
import OptionsEditor from "./component/OptionsEditor";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch, useSelector } from "react-redux";
import {
  createNews,
  getNewsItemEditor,
  updateNews,
} from "../../../../services/redux/services/NewsWS";
import { useTranslation } from "react-i18next";
import { isEmpty, isNil } from "ramda";
import moment from "moment";
import { getGroups } from "../../../../services/redux/services/GroupsWS";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";

const EditorNews = () => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const history = useHistory();

  const locales = useSelector((state) => state.userWS.userData.locales);
  const item = useSelector((state) => state.newsWS.itemEditor);
  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  const { id, sectionId } = useParams();
  const [value, setValue] = useState("Web");
  const [lang, setLang] = useState("fr");
  const [size, setSize] = useState(24);
  const [selectPictures, setSelectPictures] = useState(null);
  const [place, setPlaceGoogle] = useState();
  const userData = useSelector((state) => state.userWS.userData);

  const isLoading = useSelector(createLoadingSelector(["news/getNewsItemEditor"]));

  let date = moment().format("YYYY-MM-DDTHH:mm:ss.SSSSZ");

  useEffect(() => {
    setPlaceGoogle(item?.event?.place);
  }, [item]);

  const [contentNews, setContentNews] = useState(
    id && !isEmpty(item)
      ? item?.content
      : locales?.reduce((acc, locale) => {
          return { ...acc, [locale.title]: "" };
        }, {}),
  );

  useEffect(() => {
    if (id !== "new") {
      dispatch(getNewsItemEditor({ itemId: id, viewId: sectionId }));
    }
  }, [dispatch, id, sectionId]);

  const handleSubmit = async (values) => {
    if (id !== "new") {
      dispatch(
        updateNews({
          viewId: sectionId,
          itemId: item?.id,
          header:
            !isNil(selectPictures) || !isNil(item?.header)
              ? locales?.reduce((acc, locale) => {
                  return {
                    ...acc,
                    [locale.title]: { id: selectPictures?.id || item?.header[locale.title]?.id },
                  };
                }, {})
              : undefined,
          title: values?.title,
          subtitle: values?.subtitle || undefined,
          content: contentNews,
          topics: values?.topics,
          startDate:
            values?.startPublication && values?.publication
              ? moment(values?.startPublication)
                  .set({
                    second: 0,
                    millisecond: 0,
                  })
                  .toISOString()
              : moment().toISOString(),
          endDate:
            !isEmpty(values?.endPublication) && values?.stopPublication
              ? moment(values?.endPublication).toISOString()
              : undefined,
          groups: values?.groups,
          event: !isNil(values?.localisation)
            ? {
                title: values?.titleEvent,
                resource: {
                  id: form.isFieldTouched(["localisation"])
                    ? values?.localisation
                    : item?.event?.resource?.id,
                  siteId: item?.event?.resource?.siteId || mapData?.id,
                },
                startDate: values?.startEvent,
                endDate: values?.endEvent,
              }
            : !isNil(place)
            ? {
                title: values?.titleEvent,
                place,
                startDate: values?.startEvent,
                endDate: values?.endEvent,
              }
            : undefined,
          authorizeComment: values?.authorizeComment,
          sendNotification: values?.sendNotification,
        }),
      )
        .unwrap()
        .then(() => {
          history.push(`/news/${sectionId}/editor`);
          message.success(t("news.editor.update.success"));
        })
        .catch(() => {
          message.error(t("news.editor.update.error"));
        });
    } else {
      dispatch(
        createNews({
          viewId: sectionId,
          header: !isNil(selectPictures)
            ? locales?.reduce((acc, locale) => {
                return { ...acc, [locale.title]: { id: selectPictures?.id } };
              }, {})
            : undefined,
          title: values?.title,
          subtitle: values?.subtitle || undefined,
          content: contentNews,
          topics: values?.topics,
          startDate:
            values?.startPublication && values?.publication
              ? moment(values?.startPublication).toISOString()
              : moment().toISOString(),
          endDate:
            !isEmpty(values?.endPublication) && values?.stopPublication
              ? moment(values?.endPublication).toISOString()
              : undefined,
          groups: values?.groups,
          event: !isNil(values?.localisation)
            ? {
                title: values?.titleEvent,
                resource: { id: values?.localisation, siteId: mapData?.id },
                startDate: values?.startEvent,
                endDate: values?.endEvent,
              }
            : !isNil(place)
            ? {
                title: values?.titleEvent,
                place,
                startDate: values?.startEvent,
                endDate: values?.endEvent,
              }
            : undefined,
          authorizeComment: values?.authorizeComment,
          sendNotification: values?.sendNotification,
        }),
      )
        .unwrap()
        .then(() => {
          history.push(`/news/${sectionId}/editor`);
          message.success(t("news.editor.create.success"));
        })
        .catch(() => {
          message.error(t("news.editor.create.error"));
        });
    }
  };

  useEffect(() => {
    dispatch(getGroups({ viewId: sectionId }));
  }, [dispatch, sectionId]);

  useEffect(() => {
    form.resetFields();
    setContentNews(
      id && !isEmpty(item)
        ? item?.content
        : locales?.reduce((acc, locale) => {
            return { ...acc, [locale.title]: "" };
          }, {}),
    );
  }, [form, id, item, locales]);

  return (
    <div style={{ padding: "20px" }}>
      <Typography.Text
        strong
        style={{ cursor: "pointer" }}
        onClick={() => history.push(`/news/${sectionId}/editor`)}
      >
        <Glyph name="arrow_back_ios" /> {t("news.editor.BackToTheList")}
      </Typography.Text>
      <div
        style={{
          display: "flex",
          flexFlow: "row",
          justifyContent: "space-between",
          marginBottom: "20px",
          marginTop: "20px",
        }}
      >
        <Segmented
          size="large"
          value={value}
          style={{ width: "320px" }}
          options={[
            {
              label: (
                <p style={{ margin: "0" }}>
                  <Glyph
                    name="desktop_windows"
                    style={{
                      verticalAlign: "-4px",
                      marginRight: "8px",
                      color: colors.primary_base,
                    }}
                  />
                  {t("news.editor.web")}
                </p>
              ),
              value: "Web",
            },
            {
              label: (
                <p style={{ margin: "0" }}>
                  <Glyph
                    name="phone_iphone"
                    style={{
                      verticalAlign: "-4px",
                      marginRight: "8px",
                      color: colors.primary_base,
                    }}
                  />
                  {t("news.editor.mobile")}
                </p>
              ),
              value: "Mobile",
            },
          ]}
          onChange={(value) => {
            setValue(value);
            if (value === "Web") {
              setSize(24);
            } else {
              setSize(12);
            }
          }}
        />
        <Button
          style={{ width: "160px", textTransform: "uppercase" }}
          type="primary"
          icon={<Glyph name="publish" />}
          onClick={() => {
            form.submit();
          }}
        >
          {id !== "new" ? t("news.editor.update") : t("news.editor.publish")}
        </Button>
      </div>
      <Form
        form={form}
        initialValues={{
          ...item,
          subtitle: item?.subtitle || "",
          content: item?.content || "",
          startPublication: moment(item?.startDate),
          endPublication: moment(item?.endDate),
          publication: moment(item?.startDate).isAfter(moment(date, "YYYY-MM-DDTHH:mm:ss.SSSSZ")),
          stopPublication: !isNil(item?.endDate),
          authorizeComment: item?.authorizeComment || false,
          sendNotification: false,
          isEvent: isEmpty(item) || isNil(item?.event) ? false : true,
          titleEvent: item?.event?.title,
          localisation: item?.event?.title[lang],
          startEvent: moment(item?.event?.startDate),
          endEvent: moment(item?.event?.endDate),
        }}
        onFinish={handleSubmit}
        preserve={true}
      >
        <Row gutter={[16, 16]}>
          <Col span={16}>
            <Toolbar
              selectPictures={selectPictures}
              setSelectPictures={setSelectPictures}
              size={size}
              news={item}
              lang={lang}
              contentNews={contentNews}
              setContentNews={setContentNews}
            />
          </Col>
          <Col span={8}>
            {id !== "new" ? (
              !isLoading && (
                <OptionsEditor
                  news={item}
                  setLang={setLang}
                  lang={lang}
                  setPlaceGoogle={setPlaceGoogle}
                />
              )
            ) : (
              <OptionsEditor
                news={item}
                setLang={setLang}
                lang={lang}
                setPlaceGoogle={setPlaceGoogle}
              />
            )}
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default EditorNews;
