import { Dropdown, Typography } from "antd";
import { useMemo } from "react";
import ChipCard from "../../ChipCard/ChipCard";
import Glyph from "../../Glyph/Glyph";
import styles from "./Mapbox.module.less";
import { useMapboxInstance } from "./useMapboxInstance";

const FloorSelector = ({ allowedFloors, ...props }) => {
  const { floors, floor, setFloor } = useMapboxInstance();

  const items = useMemo(() => {
    return (floors || [])
      .map((i) => ({ label: i.name, key: i.id }))
      .filter((i) => {
        if (allowedFloors) return allowedFloors.includes(i.key);
        return true;
      });
  }, [allowedFloors, floors]);

  return (
    <Dropdown
      {...props}
      className={styles["floor-selector"]}
      trigger={["click"]}
      menu={{ items, onClick: ({ key }) => setFloor(key) }}
    >
      <ChipCard
        style={{ minWidth: 120, cursor: "pointer" }}
        icon={<Glyph className={"secondary"} name={"my_location"} />}
      >
        <Typography.Text strong>{floor?.name || "No selected"}</Typography.Text>
      </ChipCard>
    </Dropdown>
  );
};

export default FloorSelector;
