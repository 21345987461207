/* eslint-disable no-unused-vars */
import { Card, Grid, Space, Spin, Typography } from "antd";
import { NavLink, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetJourneysBetweenTwoPoints,
  addDetails,
  removeDetails,
  removeSearch,
} from "../../../../services/redux/services/TransportWS";
import Tile from "../../../Common/Tile/Tile";
import moment from "moment";
import ConvertTime from "../../../../hook/ConvertTime";
import SwitchIconTransport from "../../../../hook/SwitchIconTransport";
import { isNil } from "ramda";
import Details from "./Details";
import Routes from "./Routes";
import { LoadingOutlined } from "@ant-design/icons";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import Search from "../Component/Search";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const Journeys = ({ inputSearchFrom, inputSearchTo, setInputSearchFrom }) => {
  const { colors, symbols, size } = useDesignTokens();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let location = useLocation();
  const screens = Grid.useBreakpoint();

  const campus = useSelector((state) => state.clientsWS.campus);
  const journeys = useSelector((state) => state.transportWS.journeys);

  const [isDetails, setIsDetails] = useState(false);
  const [isJourney, setIsJourney] = useState(false);
  const [infos, setInfos] = useState(null);

  const isLoading = useSelector(createLoadingSelector(["transport/GetJourneysBetweenTwoPoints"]));

  useEffect(() => {
    if (!inputSearchFrom?.id) return;
    dispatch(
      GetJourneysBetweenTwoPoints({
        campusId: campus?.mapData?.id,
        from: inputSearchFrom?.id,
        to: inputSearchTo?.id,
      }),
    );
  }, [campus?.mapData?.id, dispatch, inputSearchTo?.id, inputSearchFrom?.id]);

  return (
    <Card
      style={{
        width: screens.xl ? "35%" : 350,
        minWidth: screens.xl ? "35%" : 350,
        height: "fit-content",
        overflow: "auto",
        maxHeight: "calc(100vh - 110px)",
      }}
      headStyle={{ minHeight: 90 }}
      title={
        <div style={{ display: "flex" }}>
          {!isJourney && !isDetails ? (
            <NavLink
              to={{ pathname: "/transport/stop", state: { building: location?.state?.building } }}
              style={{
                backgroundColor: colors?.grey_20,
                height: "30px",
                width: "30px",
                borderRadius: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "8px",
              }}
              onClick={() => {
                dispatch(removeSearch());
                setInputSearchFrom(null);
              }}
            >
              <Glyph
                name="arrow_back_ios"
                style={{
                  verticalAlign: "-2px",
                  fontSize: size.xl,
                  color: "black",
                }}
              />
            </NavLink>
          ) : (
            <div
              style={{
                backgroundColor: colors?.grey_20,
                height: "30px",
                width: "30px",
                borderRadius: "100px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginRight: "8px",
                cursor: "pointer",
              }}
              onClick={() => {
                if (isDetails) {
                  setIsDetails(false);
                  dispatch(removeDetails());
                } else {
                  setIsJourney(false);
                }
              }}
            >
              <Glyph
                name="arrow_back_ios"
                style={{
                  verticalAlign: "-2px",
                  fontSize: size.xl,
                  color: "black",
                }}
              />
            </div>
          )}

          <div>
            <Typography.Title level={5}>{t("Itinerary")}</Typography.Title>
            <Typography.Text strong type="secondary">
              {t("itinerary_sub")}
            </Typography.Text>
          </div>
        </div>
      }
      bodyStyle={{ padding: 0 }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}>
          {!isDetails && (
            <Search
              inputSearchTo={inputSearchTo}
              setInputSearchFrom={setInputSearchFrom}
              inputSearchFrom={inputSearchFrom}
              setIsJourney={setIsJourney}
            />
          )}

          {!isJourney && !isDetails && (
            <Routes setIsJourney={setIsJourney} setInputSearchFrom={setInputSearchFrom} />
          )}

          {isJourney && !isDetails && (
            <div style={{ overflowY: "auto", padding: "20px" }}>
              {journeys?.journeys?.length} résultat(s)
              <Space style={{ width: "100%" }} direction="vertical" size="small">
                {journeys?.journeys?.map((journey, index) => (
                  <Tile
                    style={{ margin: "0", height: "auto", padding: "10px" }}
                    key={index}
                    onClick={() => {
                      setIsDetails(true);
                      dispatch(addDetails(journey));
                      logEvent(analytics, `transport_itinerary_detail`);
                    }}
                  >
                    <div style={{ width: "70%" }}>
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "wrap",
                          marginBottom: "8px",
                          alignItems: "center",
                        }}
                      >
                        {journey?.sections?.map((section, index) => (
                          <div
                            key={index}
                            style={{ display: "flex", flexFlow: "row", alignItems: "center" }}
                          >
                            {SwitchIconTransport(section?.mode)}{" "}
                            {!isNil(section?.informations) && (
                              <Typography.Text
                                strong
                                style={{
                                  backgroundColor: section?.informations?.color,
                                  borderRadius: symbols?.base_shape?.radius,
                                  textAlign: "center",
                                  color: section?.informations?.textColor,
                                  minWidth: "30px",
                                  height: "30px",
                                  lineHeight: "30px",
                                  padding: "0 6px",
                                  marginRight: "4px",
                                }}
                              >
                                {section?.informations?.code}
                              </Typography.Text>
                            )}
                          </div>
                        ))}
                      </div>
                      <div style={{ display: "flex", flexFlow: "column" }}>
                        <Typography.Text style={{ fontSize: size.s }}>
                          {ConvertTime(journey?.durations?.walking)} {t("walking")}
                        </Typography.Text>
                        <Typography.Text>
                          {t("departure_from")} {journey?.sections[0]?.from?.name}
                        </Typography.Text>
                      </div>
                    </div>
                    <div style={{ display: "flex", flexFlow: "column", textAlign: "right" }}>
                      <Typography.Text strong style={{ fontSize: size.l }}>
                        {ConvertTime(journey?.durations?.total)}
                      </Typography.Text>
                      <Typography.Text style={{ fontSize: size.s }}>
                        {moment(journey?.departure).format("HH:mm")} -{" "}
                        {moment(journey?.arrival).format("HH:mm")}
                      </Typography.Text>
                    </div>
                  </Tile>
                ))}
              </Space>
            </div>
          )}

          {isDetails && <Details infos={infos} />}
        </Spin>
        <div style={{ display: "flex", justifyContent: "center", padding: "10px 0" }}>
          <Typography.Text type="secondary">{t("navitia_data")}</Typography.Text>
        </div>
      </div>
    </Card>
  );
};

export default Journeys;
