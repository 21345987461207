import { Layout, Typography } from "antd";
import { useEffect, useState } from "react";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import { TitleSource } from "../../Common/Teleporters/Title";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getContactCategories } from "../../../services/redux/services/ContactListWS";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ContactList = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { slug } = useParams();
  const { i18n, t } = useTranslation();

  const [groupFilter, setGroupFilter] = useState({});
  const [currentContact, setCurrentContact] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const userContactCategories = useSelector((state) => state.contactListWS.contactCategories);
  const campus = useSelector((state) => state.clientsWS.campus);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(
        getContactCategories({
          siteId: campus?.mapData?.id,
          locale: i18n.language,
        }),
      );
      setIsLoading(false);
    };
    fetchData();
    logger.log("getContactCategories - ContactList");
  }, [campus?.mapData?.id, dispatch, i18n.language]);

  useEffect(() => {
    // logger.log("slug", slug, typeof slug, Number(slug));

    if (slug) {
      const slugParts = slug.split("-");
      const filterId = slugParts[0];

      if (isNaN(Number(slug))) {
        // Si le slug n'est pas un nombre (potentiellement une catégorie)
        const matchingCategory = userContactCategories.lists?.find(
          (cat) =>
            slug ===
            `${cat.id}-${cat.title
              .replace(/\s(.)/g, (_, match) => match.toUpperCase())
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")}`,
        );

        if (slugParts.length > 1 && matchingCategory) {
          setGroupFilter(matchingCategory);
          setCurrentContact({});
        } else {
          history.push("/contactListEnhanced");
        }
      } else {
        // Si le slug est un nombre (potentiellement un ID)
        setCurrentContact({ id: parseInt(filterId) });
      }
    } else {
      // Si pas de slug
      setGroupFilter({});
      setCurrentContact({});
    }
  }, [userContactCategories, history, slug]);

  //! avant la prod, augmenter la size des requetes contacts ?
  //! valeures BIO / HOOBIES en dur (voir avec romain si le client peut rajouter des categories d'info)

  return (
    <>
      <TitleSource>{t("contact_list", { ns: "csv" })}</TitleSource>
      <Layout
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "10px",
          justifyContent: "center",
          paddingTop: "32px",
          paddingBottom: "32px",
          height: "100%",
          margin: "0 16px",
        }}
      >
        {!isLoading && (
          <>
            <LeftPanel
              groupFilter={groupFilter}
              setGroupFilter={setGroupFilter}
              currentContact={currentContact}
              userContactCategories={userContactCategories}
            />
            <RightPanel groupFilter={groupFilter} currentContact={currentContact} />
          </>
        )}
      </Layout>
    </>
  );
};

export default ContactList;
