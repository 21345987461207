import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Empty,
  Layout,
  Row,
  Select,
  Space,
  Spin,
  Typography,
} from "antd";
import clsx from "clsx";
import debounce from "lodash.debounce";
import moment from "moment";
import { identity, times } from "ramda";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as UndrawNoData } from "../../../assets/svg/undraw_no_data.svg";
import DWMConnector from "../../../services/api/DWMConnector";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import {
  hasFacilitiesFilters,
  patchFacilitiesFilters,
  resetFacilitiesFilters,
} from "../../../services/redux/managers/PreferencesManager";
import {
  filteredEvents,
  getConfiguration,
  listEvents,
  roomsFilterOptionsSelector,
  selectedEvent,
  selectEvent,
  setDate,
} from "../../../services/redux/services/FacilitiesWS";
import Glyph from "../../Common/Glyph/Glyph";
import DropdownSelect from "../../Common/Inputs/DropdownSelect/DropdownSelect";
import { TitleSource } from "../../Common/Teleporters/Title";
import styles from "./DashboardFacilities.module.less";
import EventItem from "./EventItem";
import EventModal from "./EventModal/EventModal";

const DashboardFacilities = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [userSearch, setUserSearch] = useState([]);

  const isLoading = useSelector(createLoadingSelector(["facilities/listEvents"]));
  const currentDate = useSelector((state) => state.facilitiesWS.date);
  const filters = useSelector((state) => state.PreferencesManager.facilities.filters);
  const hasFilters = useSelector(hasFacilitiesFilters);

  const roomFilterOptions = useSelector(roomsFilterOptionsSelector);
  const config = useSelector((state) => state.facilitiesWS.configuration);
  const events = useSelector(filteredEvents);
  const event = useSelector(selectedEvent);

  useEffect(() => {
    dispatch(getConfiguration());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listEvents());
  }, [currentDate, dispatch]);

  const setCurrentDate = (date) => {
    dispatch(setDate(moment(date).toISOString()));
  };

  const handleSearch = useMemo(
    () =>
      debounce(async (search) => {
        const users = await DWMConnector.searchUsers({ search, page: 1, size: 50 })
          .then((res) => res.items)
          .catch(() => []);
        setUserSearch(users);
      }, 500),
    [],
  );

  const roomsFilterOptionsMemoized = useMemo(() => {
    const toReturn = [];
    if (roomFilterOptions.categories.length > 1) {
      toReturn.push(
        { divider: true, title: t("facilities.filters.rooms.types") },
        ...roomFilterOptions.categories
          .map((r) => ({ label: r.title, value: r.id }))
          .sort((a, b) => a.label.localeCompare(b.label)),
        { divider: true, title: t("facilities.filters.rooms.rooms") },
      );
    }
    toReturn.push(
      ...roomFilterOptions.rooms
        .map((r) => ({ label: r.title, value: r.id }))
        .sort((a, b) => a.label.localeCompare(b.label)),
    );
    return toReturn;
  }, [roomFilterOptions.categories, roomFilterOptions.rooms, t]);

  const currentMoment = moment(currentDate, "YYYY-MM-DD");

  return (
    <Layout className={styles.dashboardContainer}>
      <Layout>
        <TitleSource>{t("dashboard_facilities", { ns: "csv" })}</TitleSource>

        <Row gutter={[20, 20]}>
          <Col flex={"12"} />
          <Col flex={"auto"}>
            <div className={styles.weekPagination}>
              <div
                className={clsx(styles.previousWeek)}
                onClick={() => setCurrentDate(currentMoment.subtract(1, "w"))}
              >
                <Glyph name={"navigate_before"} />
                <div>{t("facilities.weekPrevious")}</div>
              </div>
              <Divider type="vertical" />
              <Typography.Text strong>
                {t("facilities.week", { number: currentMoment.week() })}
              </Typography.Text>
              <Divider type="vertical" />
              <div
                className={clsx(styles.nextWeek)}
                onClick={() => setCurrentDate(currentMoment.add(1, "w"))}
              >
                <div>{t("facilities.weekNext")}</div>
                <Glyph name={"navigate_next"} />
              </div>
            </div>
          </Col>
          <Col flex={"12"}>
            <Button
              style={{ float: "right" }}
              type="ghost"
              icon={<Glyph name="event" />}
              onClick={() => setCurrentDate(moment())}
            >
              {t("Today")}
            </Button>
          </Col>
          <Col span={24}>
            <Row gutter={[20, 20]} className={styles.daySelector}>
              {times(identity, 7).map((i) => {
                const date = moment(currentMoment).isoWeekday(i + 1);
                return (
                  <Col flex={1}>
                    <Card
                      className={clsx({ [styles.selected]: date.isSame(currentDate, "day") })}
                      onClick={() => setCurrentDate(date)}
                    >
                      <Typography.Text type="secondary">{date.format("DD MMMM")}</Typography.Text>
                      <Typography.Title level={4}>{date.format("dddd")}</Typography.Title>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col flex="250px">
            <DropdownSelect
              style={{ width: "100%" }}
              icon={<Glyph name="local_bar" />}
              placeholder={t("facilities.filters.categories")}
              value={filters.categories}
              onChange={(val) => dispatch(patchFacilitiesFilters({ categories: val }))}
              options={(config?.categories || []).map((c) => ({
                label: c.title,
                value: c.id,
              }))}
            />
          </Col>
          <Col flex="250px">
            <DropdownSelect
              style={{ width: 250 }}
              searchable
              icon={<Glyph name="place" />}
              placeholder={t("facilities.filters.rooms")}
              value={filters.rooms}
              onChange={(val) => dispatch(patchFacilitiesFilters({ rooms: val }))}
              options={roomsFilterOptionsMemoized}
            />
          </Col>
          <Col flex="auto">
            <Select
              className="primary_light"
              showSearch
              allowClear
              labelInValue
              style={{ width: "100%" }}
              placeholder={t("facilities.filters.organizer")}
              value={filters.organizer}
              filterOption={false}
              onSearch={handleSearch}
              onChange={(val) => {
                dispatch(patchFacilitiesFilters({ organizer: val }));
              }}
              options={userSearch.map((u) => ({
                value: u.id,
                label: u.firstname + " " + u.lastname,
              }))}
            />
          </Col>
          <Col span={24}>
            <Space direction="horizontal">
              <Checkbox
                checked={filters.new}
                onChange={(e) => dispatch(patchFacilitiesFilters({ new: e.target.checked }))}
              >
                {t("facilities.filters.new")}
              </Checkbox>
              <Checkbox
                checked={filters.hidden}
                onChange={(e) => dispatch(patchFacilitiesFilters({ hidden: e.target.checked }))}
              >
                {t("facilities.filters.hidden")}
              </Checkbox>
              <Checkbox
                checked={filters.tasks}
                onChange={(e) => dispatch(patchFacilitiesFilters({ tasks: e.target.checked }))}
              >
                {t("facilities.filters.tasks")}
              </Checkbox>
            </Space>
          </Col>
          <Col span={24}>
            <Space
              style={{
                cursor: hasFilters ? "pointer" : "default",
                opacity: hasFilters ? 1 : 0,
                transition: "opacity .2s ease-in-out",
              }}
              onClick={hasFilters ? () => dispatch(resetFacilitiesFilters()) : () => null}
            >
              <Glyph name={"close"} />
              <Typography.Text style={{ textTransform: "uppercase", textDecoration: "underline" }}>
                {t("facilities.filters.erase")}
              </Typography.Text>
            </Space>
          </Col>
          {isLoading ? (
            <Col span={24}>
              <Spin />
            </Col>
          ) : events.length ? (
            <Col span={24}>
              <div style={{ display: "flex", alignItems: "flex-start", flexWrap: "wrap", gap: 20 }}>
                {events.map((e) => (
                  <EventItem event={e} />
                ))}
              </div>
            </Col>
          ) : (
            <Col span={24}>
              <Empty
                style={{ marginTop: 100 }}
                image={<UndrawNoData />}
                description={t("facilities.dashboard.noEvent")}
              />
            </Col>
          )}
        </Row>
      </Layout>

      <EventModal event={event} visible={!!event} onClose={() => dispatch(selectEvent(null))} />
    </Layout>
  );
};

export default DashboardFacilities;
