import React, { useEffect, useState } from "react";
import { Button, Card, Select, Typography } from "antd";
import Glyph from "../../../../Common/Glyph/Glyph";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  getAgentsWithCriteria,
  getAllReferentialsManager,
  getAllStatus,
  getTicketDetails,
  updateTicketByAgent,
  updateTicketByManager,
} from "../../../../../services/redux/services/TicketingV2WS";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import priorities from "./priorities.json";
import moment from "moment";

const TicketViewRight = ({ ticketDetails, rights, viewIdByRights, referential }) => {
  const { colors, symbols } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const dispatch = useDispatch();
  const { siteId } = useParams();

  const allStatus = useSelector((state) => state.ticketingV2WS.status);
  const allAgents = useSelector((state) => state.ticketingV2WS.agents);
  const allSpaces = useSelector((state) => state.clientsWS.campus?.mapData?.resources);

  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [updatingTicket, setUpdatingTicket] = useState(false);
  const [tempChanges, setTempChanges] = useState({
    agent: ticketDetails.agent?.id,
    status: ticketDetails.status?.id,
    priority: ticketDetails.priority,
    type: ticketDetails.type?.id,
    category: ticketDetails.category?.id,
    subCategory: ticketDetails.subCategory?.id,
    space: ticketDetails.space?.id,
  });

  const agentOptions = allAgents?.items?.map((a) => ({
    label: a.firstname && a.lastname ? `${a.firstname} ${a.lastname}` : a.email ? a.email : a.id,
    value: a.id,
  }));

  const statusOptions = allStatus?.map((s) => ({
    label: s.label,
    value: s.id,
  }));

  const spaceOptions = allSpaces?.map((s) => ({
    label: s.title,
    value: s.id,
  }));

  const prioritiesOptions = priorities.map((p) => ({
    label: p.label[locale],
    value: p.name,
    id: p.name,
  }));

  const typeOptions = referential?.map((t) => ({
    label: t.label,
    value: t.id,
  }));

  const categoriesOptions = referential
    ?.find((ref) => ref.id === tempChanges.type)
    ?.categories?.map((c) => ({
      label: c.label,
      value: c.id,
    }));

  const subCategoriesOptions = referential
    ?.find((ref) => ref.id === tempChanges.type)
    ?.categories?.find((cat) => cat.id === tempChanges.category)
    ?.subCategories?.map((c) => ({
      label: c.label,
      value: c.id,
    }));

  const hasChanged =
    tempChanges.agent === ticketDetails.agent?.id &&
    tempChanges.status === ticketDetails.status?.id &&
    tempChanges.priority === ticketDetails.priority &&
    tempChanges.type === ticketDetails.type?.id &&
    tempChanges.category === ticketDetails.category?.id &&
    tempChanges.subCategory === ticketDetails.subCategory?.id &&
    tempChanges.space === ticketDetails.space?.id;

  const updateTicket = async () => {
    const updatedData = { ticketIds: [ticketDetails?.id] };
    if (tempChanges?.status !== ticketDetails.status?.id) updatedData.status = tempChanges?.status;
    if (tempChanges?.priority !== ticketDetails?.priority)
      updatedData.priority = tempChanges?.priority;
    if (tempChanges?.type !== ticketDetails?.type?.id) updatedData.typeId = tempChanges?.type;
    if (tempChanges?.category !== ticketDetails?.category?.id)
      updatedData.categoryId = tempChanges?.category;
    if (tempChanges?.subCategory !== ticketDetails?.subCategory?.id)
      updatedData.subCategoryId = tempChanges?.subCategory;
    if (tempChanges?.agent !== ticketDetails.agent?.id) updatedData.agentId = tempChanges?.agent;

    setUpdatingTicket(true);

    ticketDetails.isManager
      ? await dispatch(
          updateTicketByManager({
            data: updatedData,
            siteId: siteId,
            viewId: viewIdByRights,
          }),
        )
      : await dispatch(
          updateTicketByAgent({
            data: updatedData,
            siteId: siteId,
            viewId: viewIdByRights,
          }),
        );

    await dispatch(
      getTicketDetails({
        siteId,
        locale: locale,
        ticketId: ticketDetails.id,
      }),
    );
    setUpdatingTicket(false);
  };

  const handlePopupScroll = (e) => {
    const target = e.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (!!allAgents.nextPage) {
        setLoading(true);
        setPage(page + 1);
        dispatch(
          getAgentsWithCriteria({
            ticketIds: [ticketDetails.id],
            siteId,
            page,
            size: 50,
            viewId: viewIdByRights,
          }),
        );
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        rights.manager &&
          (await Promise.all([
            logger.log("fetchData"),
            dispatch(
              getAgentsWithCriteria({
                ticketIds: [ticketDetails.id],
                siteId,
                page: 1,
                size: 50,
                viewId: viewIdByRights,
              }),
            ),
            dispatch(getAllStatus({ siteId, locale })),
            dispatch(getAllReferentialsManager({ siteId, locale, viewId: viewIdByRights })),
          ]));
      } catch (error) {
        logger.error("Error fetching data:", error);
      }
    };
    viewIdByRights && fetchData();
  }, [ticketDetails, dispatch, locale, rights, siteId, viewIdByRights]);

  useEffect(() => {
    logger.log("tempChanges", tempChanges);
  }, [tempChanges]);

  return (
    <Card style={{ width: "60%", height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 8,
          }}
        >
          <Typography.Text strong style={{ fontSize: 17 }}>
            {t("ticketingv2.TicketDetails")}
          </Typography.Text>

          {/* Création */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: colors.light_background,
              padding: 8,
              borderRadius: symbols.base_shape.radius,
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <Glyph name="receipt" style={{ fontSize: 16, color: colors.grey_60 }} />
              <Typography.Text strong style={{ fontSize: 14 }}>
                {t("ticketingv2.Creation")}
              </Typography.Text>
            </div>
            <Card style={{ height: 42 }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                  <Glyph name="person" style={{ color: colors.secondary_base, fontSize: 24 }} />
                  <Typography.Text strong>
                    {ticketDetails.requester?.firstname[0]?.toUpperCase() +
                      ticketDetails.requester?.firstname?.slice(1)}
                    &nbsp;
                    {ticketDetails.requester?.lastname[0]?.toUpperCase() +
                      ticketDetails.requester?.lastname?.slice(1)}
                  </Typography.Text>
                </div>
                <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                  <Typography.Text strong>
                    {moment(ticketDetails.createdAt).format(
                      locale === "fr" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY HH:mm",
                    )}
                  </Typography.Text>
                  <Glyph name="event" style={{ color: colors.secondary_base, fontSize: 24 }} />
                </div>
              </div>
            </Card>
            {/* <Card style={{ height: 42 }}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                  <Glyph name="event" style={{ color: colors.secondary_base, fontSize: 24 }} />
                  <Typography.Text strong>
                    {moment(ticketDetails.createdAt).format(
                      locale === "fr" ? "DD/MM/YYYY HH:mm" : "MM/DD/YYYY HH:mm",
                    )}
                  </Typography.Text>
                </div>
              </div>
            </Card> */}
          </div>

          {rights.user && (
            <div style={{ height: 1, backgroundColor: colors.grey_40, width: "100%" }} />
          )}

          {/* Attribué a */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: colors.light_background,
              padding: 8,
              borderRadius: symbols.base_shape.radius,
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <Glyph name="build" style={{ fontSize: 16, color: colors.grey_60 }} />
              <Typography.Text strong style={{ fontSize: 14 }}>
                {t("ticketingv2.AssignedTo")}
              </Typography.Text>
            </div>
            {rights.manager ? (
              <Card bodyStyle={{ height: 42 }}>
                <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                  <Glyph name="person" style={{ color: colors.secondary_base }} />
                  <Select
                    className="accent"
                    onPopupScroll={handlePopupScroll}
                    loading={loading}
                    bordered={false}
                    size={"middle"}
                    style={{
                      width: "100%",
                      color: "black !important",
                    }}
                    options={agentOptions}
                    value={tempChanges.agent}
                    onChange={(value) => setTempChanges({ ...tempChanges, agent: value })}
                    suffixIcon={<Glyph name="arrow_drop_down" />}
                  />
                </div>
              </Card>
            ) : (
              <Card style={{ height: 42 }}>
                <div style={{ display: "flex", gap: 8, alignItems: "center", height: "100%" }}>
                  <Glyph name="person" style={{ color: colors.secondary_base, fontSize: 24 }} />
                  <Typography.Text
                    strong
                    style={{ color: ticketDetails.agent ? "black" : colors.grey_40 }}
                  >
                    {ticketDetails.agent
                      ? ticketDetails.agent.firstname && ticketDetails.agent.lastname
                        ? `${ticketDetails.agent.firstname} ${ticketDetails.agent.lastname}`
                        : ticketDetails.agent.id
                      : t("ticketingv2.NotAssigned")}
                  </Typography.Text>
                </div>
              </Card>
            )}
          </div>

          {rights.user && (
            <div style={{ height: 1, backgroundColor: colors.grey_40, width: "100%" }} />
          )}

          {/* Status */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: colors.light_background,
              padding: 8,
              borderRadius: symbols.base_shape.radius,
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <Glyph name="fiber_manual_record" style={{ fontSize: 16, color: colors.grey_60 }} />
              <Typography.Text strong style={{ fontSize: 14 }}>
                {t("ticketingv2.Status")}
              </Typography.Text>
            </div>
            {rights.manager || rights.agent ? (
              <Card style={{ height: 42 }}>
                <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                  <Glyph
                    name="fiber_manual_record"
                    style={{ color: tempChanges.status?.color || colors.grey_40 }}
                  />
                  <Select
                    className="accent"
                    bordered={false}
                    size={"middle"}
                    style={{
                      width: "100%",
                      color: "black !important",
                    }}
                    options={statusOptions}
                    value={tempChanges.status}
                    onChange={(value) => setTempChanges({ ...tempChanges, status: value })}
                    suffixIcon={<Glyph name="arrow_drop_down" />}
                  />
                </div>
              </Card>
            ) : (
              <Card style={{ height: 42 }}>
                <div style={{ display: "flex", gap: 8, alignItems: "center", height: "100%" }}>
                  <Glyph
                    name="fiber_manual_record"
                    style={{ color: ticketDetails.status?.color || colors.grey_40 }}
                  />
                  <Typography.Text strong>{ticketDetails.status?.label}</Typography.Text>
                </div>
              </Card>
            )}
          </div>

          {/* Space */}
          {(rights.manager || rights.agent) && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: colors.light_background,
                padding: 8,
                borderRadius: symbols.base_shape.radius,
              }}
            >
              <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <Glyph name="place" style={{ fontSize: 16, color: colors.grey_60 }} />
                <Typography.Text strong style={{ fontSize: 14 }}>
                  {t("ticketingv2.Localisation")}
                </Typography.Text>
              </div>
              <Card style={{ height: 42 }}>
                <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                  <Glyph name="place" style={{ color: colors.secondary_base }} />
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
                    }
                    className="accent"
                    bordered={false}
                    size={"middle"}
                    style={{
                      width: "100%",
                      color: "black !important",
                    }}
                    options={spaceOptions}
                    value={tempChanges.space}
                    onChange={(value) => setTempChanges({ ...tempChanges, space: value })}
                    suffixIcon={<Glyph name="arrow_drop_down" />}
                  />
                </div>
              </Card>
            </div>
          )}

          {rights.user && (
            <div style={{ height: 1, backgroundColor: colors.grey_40, width: "100%" }} />
          )}

          {/* Priorité */}
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              backgroundColor: colors.light_background,
              padding: 8,
              borderRadius: symbols.base_shape.radius,
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <Glyph name="warningf" style={{ fontSize: 16, color: colors.grey_60 }} />
              <Typography.Text strong style={{ fontSize: 14 }}>
                {t("ticketingv2.Priority")}
              </Typography.Text>
            </div>
            {rights.manager || rights.agent ? (
              <Card style={{ height: 42 }}>
                <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                  <Glyph
                    name="fiber_manual_record"
                    style={{
                      color: priorities.find((s) => s.name === tempChanges.priority)?.color,
                    }}
                  />
                  <Select
                    className="accent"
                    bordered={false}
                    size={"middle"}
                    style={{
                      width: "100%",
                      color: "black !important",
                    }}
                    options={prioritiesOptions}
                    value={tempChanges.priority}
                    onChange={(value) => setTempChanges({ ...tempChanges, priority: value })}
                    suffixIcon={<Glyph name="arrow_drop_down" />}
                  />
                </div>
              </Card>
            ) : (
              <Card style={{ height: 42 }}>
                <div style={{ display: "flex", gap: 8, alignItems: "center", height: "100%" }}>
                  <Glyph
                    name="fiber_manual_record"
                    style={{
                      color: priorities.find((s) => s.name === ticketDetails.priority)?.color,
                    }}
                  />
                  <Typography.Text strong>
                    {priorities.find((s) => s.name === ticketDetails.priority)?.label[locale]}
                  </Typography.Text>
                </div>
              </Card>
            )}
          </div>

          {/* Catégorisation */}
          {rights.manager && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 8,
                backgroundColor: colors.light_background,
                padding: 8,
                borderRadius: symbols.base_shape.radius,
              }}
            >
              <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <Glyph name="line_weight" style={{ fontSize: 16, color: colors.grey_60 }} />
                <Typography.Text strong style={{ fontSize: 14 }}>
                  {t("ticketingv2.Categorisation")}
                </Typography.Text>
              </div>
              <Card style={{ height: 42 }}>
                <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                  <Glyph
                    name="horizontal_split"
                    style={{ color: colors.secondary_base, transform: "rotate(180deg)" }}
                  />
                  <Select
                    className="accent"
                    bordered={false}
                    size={"middle"}
                    style={{
                      width: "100%",
                      color: "black !important",
                    }}
                    options={typeOptions}
                    value={tempChanges.type}
                    onChange={(value) =>
                      setTempChanges({
                        ...tempChanges,
                        type: value,
                        category: null,
                        subCategory: null,
                      })
                    }
                    suffixIcon={<Glyph name="arrow_drop_down" />}
                  />
                </div>
              </Card>
              <Card style={{ height: 42 }}>
                <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                  <Glyph name="calendar_view_day" style={{ color: colors.secondary_base }} />
                  <Select
                    className="accent"
                    bordered={false}
                    size={"middle"}
                    style={{
                      width: "100%",
                      color: "black !important",
                    }}
                    options={categoriesOptions}
                    value={tempChanges.category}
                    onChange={(value) =>
                      setTempChanges({ ...tempChanges, category: value, subCategory: null })
                    }
                    suffixIcon={<Glyph name="arrow_drop_down" />}
                  />
                </div>
              </Card>
              {tempChanges.category && !!subCategoriesOptions?.length && (
                <Card style={{ height: 42 }}>
                  <div style={{ display: "flex", height: "100%", alignItems: "center" }}>
                    <Glyph name="horizontal_split" style={{ color: colors.secondary_base }} />
                    <Select
                      className="accent"
                      bordered={false}
                      size={"middle"}
                      style={{
                        width: "100%",
                        color: "black !important",
                      }}
                      options={subCategoriesOptions}
                      value={tempChanges.subCategory}
                      onChange={(value) => setTempChanges({ ...tempChanges, subCategory: value })}
                      suffixIcon={<Glyph name="arrow_drop_down" />}
                    />
                  </div>
                </Card>
              )}
            </div>
          )}
        </div>

        {(rights.manager || rights.agent) && (
          <div style={{ display: "flex", gap: 8 }}>
            <Button
              type="ghost"
              style={{ width: "50%" }}
              disabled={hasChanged}
              onClick={() => {
                setTempChanges({
                  agent: ticketDetails.agent?.id,
                  status: ticketDetails.status?.id,
                  priority: ticketDetails.priority,
                  type: ticketDetails.type?.id,
                  category: ticketDetails.category?.id,
                  subCategory: ticketDetails.subCategory?.id,
                });
              }}
            >
              {t("ticketingv2.Reset")}
            </Button>
            <Button
              type="primary"
              style={{ width: "50%" }}
              disabled={hasChanged}
              onClick={updateTicket}
              loading={updatingTicket}
            >
              {t("ticketingv2.Apply")}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};

export default TicketViewRight;
