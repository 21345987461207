import { Button } from "antd";
import cx from "clsx";
import Glyph from "../../Glyph/Glyph";
import styles from "./CollapseRadioButton.module.less";

const CollapseRadioButton = ({ options, value, onChange, ...rest }) => {
  return (
    <div className={styles["collapse-radio-button-group"]}>
      {options.map((option) => (
        <Button
          {...rest}
          className={cx(styles["collapse-radio-button"], {
            [styles["collapse-radio-button--selected"]]: value === option.value,
          })}
          type={value === option.value ? "secondary" : "default"}
          ghost
          shape={"round"}
          icon={<Glyph style={{ fontWeight: "normal" }} name={option.icon} />}
          onClick={() => onChange(option.value)}
        >
          {value === option.value && <span>{option.title}</span>}
        </Button>
      ))}
    </div>
  );
};

export default CollapseRadioButton;
