import Logo from "@tokens/Assets/web/img/logo/02_light.png";
import { Card, Image, Typography } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../../hook/useDesignTokens";
import { getAccessCode } from "../../../../services/redux/services/QrCodeWS";
import Glyph from "../../../Common/Glyph/Glyph";

const QrCodeWidget = ({ fullScreen, setFullScreen }) => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userWS?.userData);
  const accessCode = useSelector((state) => state.qrCodeWS?.accessCode);
  const siteId = useSelector((state) => state.clientsWS.campus?.mapData?.id);

  useEffect(() => {
    siteId && dispatch(getAccessCode({ siteId }));
  }, [dispatch, siteId]);

  return (
    <Card
      style={{ height: "fit-content" }}
      bodyStyle={{ overflow: "hidden", position: "relative", display: "flex" }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          height: 100,
          padding: 16,
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: "rgb(8, 29, 41)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography.Text strong style={{ color: "white", fontSize: "19px" }}>
            {userData.profile.firstName} {userData.profile.lastName}
          </Typography.Text>
          <Typography.Text
            type="secondary"
            style={{ fontSize: "14px", color: colors.grey_60, lineHeight: "initial" }}
          >
            {userData.profile.function
              ? userData.profile.function
              : userData.campus.find((c) => c.id === userData.profile.campusId)?.title}
          </Typography.Text>
        </div>
        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Image src={Logo} width={75} preview={false} />
        </div>
      </div>

      {!!accessCode?.qrCode ? (
        <div
          style={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
            marginTop: 100,
          }}
        >
          <Glyph
            name={fullScreen ? "zoom_out" : "zoom_in"}
            style={{
              position: "absolute",
              fontSize: 24,
              color: colors.grey_60,
              top: 0,
              right: 0,
              cursor: "pointer",
            }}
            onClick={() => setFullScreen(!fullScreen)}
          />

          <img
            src={accessCode?.qrCode}
            alt="qrCode"
            style={{
              width: fullScreen ? "calc(100vw - 32px)" : "calc(50vw - 32px)",
              aspectRatio: "square",
              transition: "width 0.15s ease",
            }}
          />
          <Typography.Text strong style={{ width: "100%", textAlign: "left", marginTop: 8 }}>
            {t("mobile.YourAccessBadge")}
          </Typography.Text>
          {accessCode?.expiresAt && accessCode?.expiresAt !== null && (
            <div style={{ display: "flex", gap: 8, width: "100%" }}>
              <Typography.Text strong>{t("mobile.ExpireOn_")}</Typography.Text>

              <Typography.Text type="secondary">
                {moment(accessCode?.expiresAt).format("DD/MM/YYYY - HH:mm")}
              </Typography.Text>
            </div>
          )}
        </div>
      ) : (
        <div
          style={{
            position: "relative",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: 100,
          }}
        >
          <Typography.Text type="secondary" strong style={{ textAlign: "center" }}>
            {t("mobile.NoAccessCode")}
          </Typography.Text>
        </div>
      )}
    </Card>
  );
};

export default QrCodeWidget;
