import React, { useEffect, useState } from "react";
import { Select, Switch, Tag, Typography } from "antd";
import Glyph from "../../../Common/Glyph/Glyph";
import useDesignTokens from "../../../../hook/useDesignTokens";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const GroupAndSpaceLimiter = ({ tempData, setTempData }) => {
  const { colors, symbols } = useDesignTokens();
  const { t } = useTranslation();

  const referential = useSelector((state) => state.ticketingV2WS.referentialsAdmin);
  const groups = useSelector((state) => state.ticketingV2WS.groups);
  const spaces = useSelector((state) => state.ticketingV2WS.allSpaces);
  const parentRef =
    tempData?.type !== "type"
      ? tempData?.type === "category"
        ? referential?.find((t) => t.id === tempData?.parentTypeId)
        : referential
            ?.find((t) => t.id === tempData?.parentTypeId)
            .categories?.find((c) => c.id === tempData?.parentCategoryId)
      : null;

  const [groupOptions, setGroupOptions] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [checked, setChecked] = useState({
    user: tempData?.groups?.user?.length !== parentRef?.groups?.user?.ids?.length,
    agent: tempData?.groups?.agent?.length !== parentRef?.groups?.agent?.ids?.length,
    manager: tempData?.groups?.manager?.length !== parentRef?.groups?.manager?.ids?.length,
    spaceTypes: tempData?.spaceTypes?.length !== parentRef?.spaceTypes?.ids?.length,
  });

  useEffect(() => {
    logger.log("parentRef", parentRef);
    logger.log("tempData", tempData);
    logger.log("checked", checked);
    logger.log("groupOptions", groupOptions);
  }, [parentRef, tempData, checked, groupOptions]);
  //! quand on vient de subCat vers cat, les checked ne sont pas mis à jour

  const tagRender = ({ label, closable, onClose }) => {
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        visible={true}
        closeIcon={<Glyph name="cancel" style={{ fontSize: 16, color: colors.primary_base }} />}
        onClose={onClose}
        style={{
          margin: 3,
          backgroundColor: closable ? "white" : "#f5f5f5",
          borderRadius: symbols.base_shape.radius,
          color: closable ? colors.primary_base : colors.pending_light,
          border: closable
            ? `2px solid ${colors.primary_base}`
            : `2px solid ${colors.pending_light}`,
          fontWeight: "bold",
        }}
      >
        {label}
        {closable ? "  " : ""}
      </Tag>
    );
  };

  // groupOptions
  useEffect(() => {
    setGroupOptions({
      groups: {
        user:
          tempData?.type === "type"
            ? groups?.map((g) => ({ label: g.label, value: g.id })) || [] // tous les groupes si on est sur un type
            : groups // sinon on affiche que les groupes du parent
                .filter((g) => parentRef?.groups?.user?.ids?.includes(g.id))
                ?.map((g) => ({ label: g.label, value: g.id })) || [],
        agent:
          tempData?.type === "type"
            ? groups?.map((g) => ({ label: g.label, value: g.id })) || []
            : groups
                .filter((g) => parentRef?.groups?.agent?.ids?.includes(g.id))
                ?.map((g) => ({ label: g.label, value: g.id })) || [],
        manager:
          tempData?.type === "type"
            ? groups?.map((g) => ({ label: g.label, value: g.id })) || []
            : groups
                .filter((g) => parentRef?.groups?.manager?.ids?.includes(g.id))
                ?.map((g) => ({ label: g.label, value: g.id })) || [],
      },
      spaceTypes:
        tempData?.type === "type" || parentRef?.spaceTypes?.ids?.length === 0
          ? spaces?.map((s) => ({ label: s.title, value: s.id })) || []
          : spaces
              .filter((s) => parentRef?.spaceTypes?.ids?.includes(s.id))
              ?.map((s) => ({ label: s.title, value: s.id })) || [],
    });
    setIsLoading(false);
  }, [groups, parentRef, spaces, tempData]);

  useEffect(() => {
    setChecked((c) => ({
      ...c,
      user: tempData?.groups?.user?.length !== parentRef?.groups?.user?.ids?.length,
    }));
  }, [tempData?.groups?.user, parentRef]);

  useEffect(() => {
    setChecked((c) => ({
      ...c,
      agent: tempData?.groups?.agent?.length !== parentRef?.groups?.agent?.ids?.length,
    }));
  }, [tempData?.groups?.agent, parentRef]);

  useEffect(() => {
    setChecked((c) => ({
      ...c,
      manager: tempData?.groups?.manager?.length !== parentRef?.groups?.manager?.ids?.length,
    }));
  }, [tempData?.groups?.manager, parentRef]);

  useEffect(() => {
    setChecked((c) => ({
      ...c,
      spaceTypes: tempData?.spaceTypes?.length !== parentRef?.spaceTypes?.ids.length,
    }));
  }, [tempData?.spaceTypes, parentRef]);

  if (isLoading) {
    return <div>{t("ticketingv2.Loading")}</div>;
  }

  return (
    <>
      {/* groupe utilisateurs */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          borderRadius: symbols.base_shape.radius,
          backgroundColor: colors.light_background,
          padding: 10,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {tempData?.type === "type"
              ? t("ticketingv2.UsersGroup")
              : t("ticketingv2.LimitUsersGroups")}
          </Typography.Text>
          {tempData?.type !== "type" && (
            <Switch
              size="small"
              checked={checked?.user}
              onChange={(v) => {
                setChecked({ ...checked, user: v });
                !v &&
                  setTempData({
                    ...tempData,
                    groups: { ...tempData?.groups, user: [...parentRef?.groups.user.ids] },
                  });
              }}
            />
          )}
        </div>
        <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
          {tempData?.type === "type"
            ? t("ticketingv2.TypeUsersGroupComment")
            : tempData?.type === "category"
            ? t("ticketingv2.CatUsersGroupComment")
            : t("ticketingv2.SubCatUsersGroupComment")}
        </Typography.Text>
        <Select
          disabled={!checked?.user && tempData?.type !== "type"}
          mode="multiple"
          tagRender={tagRender}
          style={{
            width: "100%",
            height: "fit-content",
          }}
          placeholder={t("ticketingv2.SearchAndAddUserGroups")}
          suffixIcon={<Glyph name="search" style={{ color: colors.grey_40 }} />}
          dropdownStyle={{
            borderRadius: symbols.base_shape.radius,
          }}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={tempData?.groups?.user}
          options={groupOptions.groups?.user}
          onChange={(value) =>
            !!!value.length
              ? setTempData({
                  ...tempData,
                  groups: { ...tempData?.groups, user: [...parentRef?.groups.user.ids] },
                })
              : setTempData({ ...tempData, groups: { ...tempData?.groups, user: value } })
          }
        />
      </div>

      {/* groupe agents */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          borderRadius: symbols.base_shape.radius,
          backgroundColor: colors.light_background,
          padding: 10,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {tempData?.type === "type"
              ? t("ticketingv2.AgentsGroup")
              : t("ticketingv2.LimitAgentsGroups")}
          </Typography.Text>
          {tempData?.type !== "type" && (
            <Switch
              size="small"
              checked={checked.agent}
              onChange={(v) => {
                setChecked({ ...checked, agent: v });
                !v &&
                  setTempData({
                    ...tempData,
                    groups: { ...tempData?.groups, agent: [...parentRef?.groups.agent.ids] },
                  });
              }}
            />
          )}
        </div>
        <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
          {tempData?.type === "type"
            ? t("ticketingv2.TypeAgentsGroupComment")
            : tempData?.type === "category"
            ? t("ticketingv2.CatAgentsGroupComment")
            : t("ticketingv2.SubCatAgentsGroupComment")}
        </Typography.Text>
        <Select
          disabled={!checked?.agent && tempData?.type !== "type"}
          mode="multiple"
          tagRender={tagRender}
          style={{
            width: "100%",
            height: "fit-content",
          }}
          placeholder={t("ticketingv2.SearchAndAddAgentGroups")}
          suffixIcon={<Glyph name="search" style={{ color: colors.grey_40 }} />}
          dropdownStyle={{
            borderRadius: symbols.base_shape.radius,
          }}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={tempData?.groups?.agent}
          options={groupOptions.groups?.agent}
          onChange={(value) =>
            setTempData({ ...tempData, groups: { ...tempData?.groups, agent: value } })
          }
        />
      </div>

      {/* groupe manager */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          borderRadius: symbols.base_shape.radius,
          backgroundColor: colors.light_background,
          padding: 10,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {tempData?.type === "type"
              ? t("ticketingv2.ManagersGroup")
              : t("ticketingv2.LimitManagersGroups")}
          </Typography.Text>
          {tempData?.type !== "type" && (
            <Switch
              size="small"
              checked={checked.manager}
              onChange={(v) => {
                setChecked({ ...checked, manager: v });
                !v &&
                  setTempData({
                    ...tempData,
                    groups: { ...tempData?.groups, manager: [...parentRef?.groups.manager.ids] },
                  });
              }}
            />
          )}
        </div>
        <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
          {tempData?.type === "type"
            ? t("ticketingv2.TypeManagersGroupComment")
            : tempData?.type === "category"
            ? t("ticketingv2.CatManagersGroupComment")
            : t("ticketingv2.SubCatManagersGroupComment")}
        </Typography.Text>
        <Select
          disabled={!checked.manager && tempData?.type !== "type"}
          mode="multiple"
          tagRender={tagRender}
          style={{
            width: "100%",
          }}
          placeholder={t("ticketingv2.SearchAndAddManagerGroups")}
          suffixIcon={<Glyph name="search" style={{ color: colors.grey_40 }} />}
          dropdownStyle={{
            borderRadius: symbols.base_shape.radius,
          }}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={tempData?.groups?.manager}
          options={groupOptions.groups?.manager}
          onChange={(value) =>
            setTempData({ ...tempData, groups: { ...tempData?.groups, manager: value } })
          }
        />
      </div>

      {/* groupe espaces concernés */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          borderRadius: symbols.base_shape.radius,
          backgroundColor: colors.light_background,
          padding: 10,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Text strong style={{ fontSize: 16 }}>
            {tempData?.type === "type" ? t("ticketingv2.Spaces") : t("ticketingv2.LimitSpaces")}
          </Typography.Text>
          {tempData?.type !== "type" && (
            <Switch
              size="small"
              checked={checked.spaceTypes}
              onChange={(v) => {
                setChecked({ ...checked, spaceTypes: v });
                !v &&
                  setTempData({
                    ...tempData,
                    spaceTypes: [...parentRef?.spaceTypes.ids],
                  });
              }}
            />
          )}
        </div>
        <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
          {tempData?.type === "type"
            ? t("ticketingv2.TypeSpacesComment")
            : tempData?.type === "category"
            ? t("ticketingv2.CatSpacesComment")
            : t("ticketingv2.SubCatSpacesComment")}
        </Typography.Text>
        <Select
          disabled={!checked.spaceTypes && tempData?.type !== "type"}
          mode="multiple"
          tagRender={tagRender}
          style={{
            width: "100%",
          }}
          placeholder={t("ticketingv2.SearchAndAddSpace")}
          suffixIcon={<Glyph name="search" style={{ color: colors.grey_40 }} />}
          dropdownStyle={{
            borderRadius: symbols.base_shape.radius,
          }}
          filterOption={(input, option) =>
            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          value={tempData?.spaceTypes}
          options={groupOptions.spaceTypes}
          onChange={(value) => setTempData({ ...tempData, spaceTypes: value })}
        />
      </div>
    </>
  );
};

export default GroupAndSpaceLimiter;
