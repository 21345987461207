import { Button, Segmented, Tooltip, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as Unknown } from "../../../../../assets/svg/undraw_searching.svg";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import Glyph from "../../../../Common/Glyph/Glyph";
import GroupAndSpaceLimiter from "../../Component/GroupAndSpaceLimiter";
import DeleteModal from "../../Component/Modals/DeleteModal";
import SegmentedLanguageSelector from "../../Component/SegmentedLanguageSelector";
import WarningMessage from "../../Component/WarningMessage";
import EditReferentialRightForm from "./EditReferentialRightForm";

const EditReferentialRight = ({ tempData, setTempData, referential, updateReferential }) => {
  const { colors } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const { siteId } = useParams();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [segment, setSegment] = useState("params");
  const [displayForm, setDisplayForm] = useState(!!tempData?.form?.items?.length);

  const adminRef = useSelector((state) => state.ticketingV2WS.adminRef);

  const hasFormInParentCategory = !!referential
    ?.find((type) => type.id === tempData?.parentTypeId)
    ?.categories?.find((cat) => cat.id === tempData?.parentCategoryId)?.form?.items?.length;

  const hasFormInSubCategory = !!tempData?.subCategories?.some((sc) => !!sc.form?.items?.length);

  logger.log("tempData", tempData);

  const breadCrumb =
    tempData?.type === "type"
      ? tempData?.name
      : tempData?.type === "category"
      ? `${referential?.find((type) => type.id === tempData?.parentTypeId)?.name} > ${
          tempData.name
        }`
      : `${referential?.find((type) => type.id === tempData?.parentTypeId)?.name} > ${
          referential
            ?.find((type) => type.id === tempData?.parentTypeId)
            ?.categories?.find((cat) => cat.id === tempData?.parentCategoryId)?.name
        } > ${tempData.name}`;

  useEffect(() => {
    setDisplayForm(!!tempData?.form?.items);
  }, [tempData]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          height: "fit-content",
          position: "relative",
        }}
      >
        {!tempData?.id ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              gap: 16,
            }}
          >
            <Unknown />
            <Typography.Title level={3}>{t("ticketingv2.emptyRef")}</Typography.Title>
          </div>
        ) : (
          <>
            {/* header */}
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography.Text strong style={{ fontSize: 16, color: colors.secondary_base }}>
                  {tempData?.label?.[locale] || Object.values(tempData?.label)[0]}
                </Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 12 }}>
                  {tempData?.type === "type" && t("ticketingv2.Type")}
                  {tempData?.type === "category" && t("ticketingv2.Cat")}
                  {tempData?.type === "subCategory" && t("ticketingv2.SubCat")}
                </Typography.Text>
              </div>
              <div style={{ display: "flex", gap: 8 }}>
                <Tooltip
                  title={t("ticketingv2.CantDeleteRefWithTickets")}
                  zIndex={!!adminRef?.countTickets ? 10 : -10}
                >
                  <Button
                    type="tertiary"
                    size="middle"
                    style={{ padding: 10 }}
                    icon={<Glyph name="delete" style={{ fontSize: 20 }} />}
                    onClick={() => setShowDeleteModal(true)}
                    disabled={!!adminRef?.countTickets}
                  >
                    {!!!adminRef?.countTickets && t("ticketingv2.Delete")}
                  </Button>
                </Tooltip>
                <Button
                  type="secondary"
                  size="middle"
                  style={{ padding: 10 }}
                  icon={
                    <Glyph
                      name={tempData?.enabled ? "visibility_off" : "visibility"}
                      style={{ fontSize: 20 }}
                    />
                  }
                  onClick={() => updateReferential("active")}
                >
                  {tempData?.enabled ? t("ticketingv2.Desactivate") : t("ticketingv2.Activate")}
                </Button>
              </div>
            </div>

            {(tempData?.type === "category" || tempData?.type === "subCategory") && (
              <Segmented
                size="large"
                style={{
                  marginBottom: 12,
                  backgroundColor: colors.grey_20,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
                value={segment}
                options={[
                  {
                    label: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                          justifyContent: "center",
                        }}
                      >
                        <Glyph
                          name="settings_applications"
                          style={{ color: colors.primary_base, fontSize: 20 }}
                        />
                        <Typography.Text
                          strong={segment === "params" ? true : false}
                          style={{
                            margin: "0",
                            fontSize: 12,
                            color: colors.primary_dark,
                          }}
                        >
                          {t("ticketingv2.Params")}
                        </Typography.Text>
                      </div>
                    ),
                    value: "params",
                  },
                  {
                    label: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: 8,
                          justifyContent: "center",
                        }}
                      >
                        <Glyph name="note" style={{ color: colors.primary_base, fontSize: 20 }} />
                        <Typography.Text
                          strong={segment === "form" ? true : false}
                          style={{
                            margin: "0",
                            fontSize: 12,
                            color: colors.primary_dark,
                          }}
                        >
                          {t("ticketingv2.Form")}
                        </Typography.Text>
                      </div>
                    ),
                    value: "form",
                  },
                ]}
                onChange={(value) => {
                  setSegment(value);
                }}
              />
            )}

            {(tempData?.type === "type" || (tempData?.type !== "type" && segment === "params")) && (
              <>
                {tempData?.type === "type" && !!!tempData?.categories?.length && (
                  <WarningMessage caption={t("ticketingv2.CantUseNoCat")} />
                )}
                {(!!!tempData?.groups?.user?.length ||
                  !!!tempData?.groups?.agent?.length ||
                  !!!tempData?.groups?.manager?.length) && (
                  <WarningMessage
                    caption={`${t("ticketingv2.TypeInvisible")}
                    ${!!!tempData?.groups?.user?.length ? t("ticketingv2.Users") : ""}
                    ${
                      !!!tempData?.groups?.user?.length &&
                      !!!tempData?.groups?.agent?.length &&
                      !!!tempData?.groups?.manager?.length
                        ? `, `
                        : !!!tempData?.groups?.user?.length &&
                          (!!!tempData?.groups?.agent?.length ||
                            !!!tempData?.groups?.manager?.length)
                        ? t("ticketingv2.and")
                        : ""
                    }
                    ${!!!tempData?.groups?.agent?.length ? "Agents" : ""}
                    ${
                      !!!tempData?.groups?.agent?.length && !!!tempData?.groups?.manager?.length
                        ? t("ticketingv2.and")
                        : ""
                    }
                    ${!!!tempData?.groups?.manager?.length ? "Managers" : ""}
                    `}
                  />
                )}
                <SegmentedLanguageSelector
                  tempData={tempData}
                  setTempData={setTempData}
                  icon={tempData?.type === "type"}
                  caption={`${t("ticketingv2.Name")}${
                    tempData?.type === "type" ? t("ticketingv2.andIcon") : ""
                  }`}
                  type={tempData?.type}
                />

                <GroupAndSpaceLimiter
                  tempData={tempData}
                  setTempData={setTempData}
                  type={tempData?.type}
                />
              </>
            )}

            {tempData?.type !== "type" && segment === "form" && (
              <>
                {!displayForm ? (
                  <>
                    {tempData?.type === "category" && hasFormInSubCategory ? (
                      <>
                        <WarningMessage
                          caption={`${t("ticketingv2.ExistingForm")}${
                            tempData.subCategories?.filter((sc) => !!sc.form?.items?.length)?.length
                          } ${
                            tempData.subCategories?.filter((sc) => !!sc.form?.items?.length)
                              ?.length > 1
                              ? t("ticketingv2.subCats")
                              : t("ticketingv2.subCat")
                          }`}
                        />
                        <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
                          {t("ticketingv2.ToAddaFormOnCat")}
                          {tempData.label[locale]}
                          {t("ticketingv2.youMustDeleteForm")}
                          {tempData.subCategories
                            ?.filter((sc) => !!sc.form?.items?.length)
                            ?.map((d, index, array) => (
                              <span key={d.id}>
                                {index === 0 && t("ticketingv2.fromSubCat") + d.label?.[locale]}
                                {" »"}
                                {index < array.length - 1 && ", "}
                              </span>
                            ))}
                          .
                        </Typography.Text>
                      </>
                    ) : tempData?.type === "subCategory" && hasFormInParentCategory ? (
                      <>
                        <WarningMessage
                          caption={`${t("ticketingv2.FormExistOnParentCat")}${
                            referential
                              ?.find((type) => type.id === tempData?.parentTypeId)
                              ?.categories?.find((cat) => cat.id === tempData?.parentCategoryId)
                              ?.label?.[locale]
                          } »`}
                        />
                        <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
                          {`${t("ticketingv2.ToAddFormOnSubCat")}${tempData.label?.[locale]}${t(
                            "ticketingv2.youMustDeleteFormOnParentCat",
                          )}${
                            referential
                              ?.find((type) => type.id === tempData?.parentTypeId)
                              ?.categories?.find((cat) => cat.id === tempData?.parentCategoryId)
                              ?.label?.[locale]
                          } ».`}
                        </Typography.Text>
                      </>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 80,
                          alignItems: "center",
                          marginTop: 80,
                        }}
                      >
                        <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
                          {t("ticketingv2.FormExplanationStart")}
                          {breadCrumb}
                          .<br />
                          {t("ticketingv2.FormExplanationEnd")}
                        </Typography.Text>
                        <Button
                          type="tertiary"
                          size="large"
                          style={{ display: "flex", gap: 8, width: 250 }}
                          onClick={() => setDisplayForm(true)}
                        >
                          <Glyph name="add" style={{ fontSize: 20 }} />
                          {t("ticketingv2.CreateForm")}
                        </Button>
                      </div>
                    )}
                  </>
                ) : null}

                {displayForm && (
                  <EditReferentialRightForm tempData={tempData} setTempData={setTempData} />
                )}
              </>
            )}
          </>
        )}
      </div>
      {showDeleteModal && (
        <DeleteModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          data={tempData}
          referential={referential}
          type={tempData?.type}
          selectedSite={siteId}
        />
      )}
    </>
  );
};

export default EditReferentialRight;
