import { Button, Card, Input, Modal, Tooltip, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../../hook/useDesignTokens";
import {
  createVehicle,
  deleteVehicle,
  getVehicles,
  updateVehicle,
} from "../../../../services/redux/services/ParkingWS";
import Glyph from "../../../Common/Glyph/Glyph";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const ManageVehiclesModal = ({ showManageVehiclesModal, setShowManageVehiclesModal }) => {
  const { t } = useTranslation();
  const { colors, symbols } = useDesignTokens();
  const dispatch = useDispatch();

  const vehicles = useSelector((state) => state.parkingWS.vehicles);
  const [tempVehicle, setTempVehicle] = useState(vehicles);
  const [formView, setFormView] = useState(!!vehicles?.length);
  const [fetching, setFetching] = useState(false);
  const [type, setType] = useState(!!vehicles?.length ? "manage" : "add");
  const [referenceVehicle, setReferenceVehicle] = useState(null);
  const [vehicleToDelete, setVehicleToDelete] = useState({});

  const campus = useSelector((state) => state.clientsWS.campus);
  const siteId = campus.mapData.id;

  const update = (vehicle) => {
    setType("update");
    setReferenceVehicle(vehicle);
    setTempVehicle(vehicle);
    setFormView(false);
  };

  const add = () => {
    setType("add");
    setFormView(false);
    setTempVehicle({});
  };

  const handleCreateVehicle = async () => {
    try {
      setFetching(true);

      const result = await dispatch(
        createVehicle({
          vehicleData: tempVehicle,
          siteId,
        }),
      );

      if (result) {
        logEvent(analytics, "parking_add_vehicle_done");
        await dispatch(getVehicles({ siteId }));

        setFetching(false);
        setFormView(true);
        setType("manage");

        message.success(t("parking.VehicleAddedWithSuccess"));
      } else {
        message.error(t("parking.VehicleError"));
      }
    } catch (error) {
      logger.error("An error occurred while creating vehicle:", error);
      setFetching(false);

      message.error(t("parking.VehicleError"));
    }
  };

  const handleUpdateVehicle = async () => {
    try {
      setFetching(true);

      const vehicleData = {
        ...tempVehicle,
        plate: vehicleTypes?.find((v) => v.type === tempVehicle?.type)?.plate
          ? tempVehicle.plate
          : "",
      };

      const result = await dispatch(
        updateVehicle({
          vehicleData,
          siteId,
        }),
      );

      if (result) {
        // logEvent(analytics, "parking_modify_vehicle_done");
        await dispatch(getVehicles({ siteId }));

        setFetching(false);
        setFormView(true);
        setType("manage");

        message.success(t("parking.VehicleUpdatedWithSuccess"));
      } else {
        message.error(t("parking.UpdateVehicleError"));
      }
    } catch (error) {
      logger.error("An error occurred while updating vehicle:", error);
      setFetching(false);

      message.error(t("parking.UpdateVehicleError"));
    }
  };

  const handleDeleteVehicle = async (vehicle) => {
    try {
      setFetching(true);

      const result = await dispatch(
        deleteVehicle({
          vehicleId: vehicle.id,
          siteId,
        }),
      );

      if (result) {
        //logEvent(analytics, "parking_delete_vehicle_done");
        dispatch(getVehicles({ siteId }));
        vehicles.length === 1 && setShowManageVehiclesModal(false);
        setFetching(false);

        message.success(t("parking.VehicleDeletedWithSuccess"));
      } else {
        message.error(t("parking.DeleteVehicleError"));
      }
    } catch (error) {
      logger.error("An error occurred while deleting vehicle:", error);
      setFetching(false);

      message.error(t("parking.DeleteVehicleError"));
    }
  };

  const vehicleTypes = [
    { type: "CAR", icon: "directions_car", name: t("parking.Car"), plate: true },
    { type: "MOTORCYCLE", icon: "motorcycle", name: t("parking.Moto"), plate: true },
    { type: "ELECTRIC_CAR", icon: "electric_car", name: t("parking.ElectricCar"), plate: true },
    { type: "BIKE", icon: "directions_bike", name: t("parking.Bike"), plate: false },
  ];

  // add enter key validation
  const handleEnterKey = (e) => {
    if (e.key === "Enter") {
      if (type === "add") {
        if (
          referenceVehicle?.name
            ? (tempVehicle?.name === referenceVehicle?.name &&
                tempVehicle?.plate === referenceVehicle?.plate &&
                tempVehicle?.type === referenceVehicle?.type) ||
              !!!tempVehicle.type ||
              !!!tempVehicle.name ||
              (tempVehicle?.type !== "BIKE" ? !!!tempVehicle.plate : false)
            : !tempVehicle?.type ||
              (tempVehicle?.type !== "BIKE" && !tempVehicle?.plate) ||
              (tempVehicle?.type === "BIKE" && !tempVehicle?.name)
        ) {
          return;
        } else {
          handleCreateVehicle();
        }
      } else {
        if (
          referenceVehicle?.name
            ? (tempVehicle?.name === referenceVehicle?.name &&
                tempVehicle?.plate === referenceVehicle?.plate &&
                tempVehicle?.type === referenceVehicle?.type) ||
              !!!tempVehicle.type ||
              !!!tempVehicle.name ||
              (tempVehicle?.type !== "BIKE" ? !!!tempVehicle.plate : false)
            : !tempVehicle?.type ||
              (tempVehicle?.type !== "BIKE" && !tempVehicle?.plate) ||
              (tempVehicle?.type === "BIKE" && !tempVehicle?.name)
        ) {
          return;
        } else {
          handleUpdateVehicle();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", handleEnterKey);
    return () => {
      document.removeEventListener("keydown", handleEnterKey);
    };
  });

  return (
    <>
      <Modal
        open={showManageVehiclesModal}
        width={formView ? "550px" : "900px"}
        onCancel={() => setShowManageVehiclesModal(false)}
        title={t("parking.manageVehicles")}
        footer={null}
        closable={!fetching}
        maskClosable={!fetching}
        bodyStyle={{
          display: "flex",
          flexDirection: "column",
          gap: 16,
          padding: 16,
        }}
      >
        {!!!vehicles?.length || type === "update" || type === "add" ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
              <div style={{ width: "50%", display: "flex", flexDirection: "column" }}>
                <Typography.Text strong>{t("parking.SelectAVehicleType")}</Typography.Text>
                <Typography.Text type="secondary">{t("parking.WhatTypeOfVehicle")}</Typography.Text>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    backgroundColor: colors.light_background,
                    borderRadius: symbols.base_shape.radius,
                    padding: 16,
                  }}
                >
                  {vehicleTypes.map((vehicleType) => (
                    <Card
                      style={{ width: "100%", cursor: "pointer" }}
                      key={vehicleType.name}
                      onClick={() => setTempVehicle({ ...tempVehicle, type: vehicleType.type })}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                          <Glyph
                            name={vehicleType.icon}
                            size={24}
                            style={{ color: colors.secondary_base }}
                          />
                          <Typography.Text strong>{vehicleType.name}</Typography.Text>
                        </div>
                        <Glyph
                          name={
                            tempVehicle?.type === vehicleType.type
                              ? "radio_button_checked"
                              : "radio_button_unchecked"
                          }
                          size={24}
                          style={{
                            color:
                              tempVehicle?.type === vehicleType.type
                                ? colors.secondary_base
                                : colors.grey_40,
                          }}
                        />
                      </div>
                    </Card>
                  ))}
                </div>
              </div>

              <div
                style={{
                  height: 320,
                  width: 2,
                  backgroundColor: colors.light_background,
                  margin: "0 8px",
                  gap: 8,
                }}
              />

              <div style={{ width: "50%", display: "flex", flexDirection: "column", gap: 16 }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography.Text strong>{t("parking.AddNameToYourVehicle")}</Typography.Text>
                  <Typography.Text type="secondary">
                    {t("parking.WhatNameOfVehicle")}
                  </Typography.Text>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 8,
                      backgroundColor: colors.light_background,
                      borderRadius: symbols.base_shape.radius,
                      padding: 16,
                    }}
                  >
                    <Input
                      placeholder={t("parking.VehicleName")}
                      prefix={<Glyph name="edit" style={{ marginRight: 8 }} />}
                      value={tempVehicle?.name ? tempVehicle.name : ""}
                      onChange={(e) => setTempVehicle({ ...tempVehicle, name: e.target.value })}
                    />
                  </div>
                </div>
                {tempVehicle?.type &&
                  vehicleTypes?.find((v) => v.type === tempVehicle?.type)?.plate && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography.Text strong>{t("parking.AddPlate")}</Typography.Text>
                      <Typography.Text type="secondary">
                        {t("parking.WhatIsYourVehiclePlate")}
                      </Typography.Text>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: 8,
                          backgroundColor: colors.light_background,
                          borderRadius: symbols.base_shape.radius,
                          padding: 16,
                        }}
                      >
                        <Input
                          placeholder={t("parking.PlatePlaceholder")}
                          prefix={<Glyph name="edit" style={{ marginRight: 8 }} />}
                          value={tempVehicle?.plate ? tempVehicle.plate : ""}
                          onChange={(e) =>
                            setTempVehicle({ ...tempVehicle, plate: e.target.value.toUpperCase() })
                          }
                        />
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div style={{ display: "flex", gap: 8, justifyContent: "center", marginTop: "10%" }}>
              <Button
                type={fetching ? "primary" : "tertiary"}
                disabled={fetching}
                onClick={() => {
                  if (type === "update" || !!vehicles?.length) {
                    // back to the list of vehicles
                    setFormView(true);
                    setType("manage");
                  } else {
                    // close the modal
                    setShowManageVehiclesModal(false);
                  }
                }}
                style={{ width: "100%", maxWidth: 300 }}
              >
                {t("parking.Back")}
              </Button>
              <Button
                type="primary"
                disabled={
                  referenceVehicle?.name // update case
                    ? (tempVehicle?.name === referenceVehicle?.name &&
                        tempVehicle?.plate === referenceVehicle?.plate &&
                        tempVehicle?.type === referenceVehicle?.type) ||
                      !!!tempVehicle.type ||
                      !!!tempVehicle.name ||
                      (vehicleTypes?.find((v) => v.type === tempVehicle?.type)?.plate &&
                        !!!tempVehicle.plate)
                    : !tempVehicle?.type || // add case
                      !tempVehicle.name ||
                      (tempVehicle?.type !== "BIKE" && !tempVehicle?.plate) ||
                      (tempVehicle?.type === "BIKE" && !tempVehicle?.name)
                }
                onClick={type === "add" ? handleCreateVehicle : handleUpdateVehicle}
                icon={type === "add" ? <Glyph name="add" /> : <Glyph name="edit" />}
                style={{ width: "100%", maxWidth: 300 }}
                loading={fetching}
              >
                {type === "add" ? t("parking.AddVehicle") : t("parking.UpdateVehicle")}
              </Button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              padding: 16,
              backgroundColor: colors.light_background,
              borderRadius: symbols.base_shape.radius,
            }}
          >
            <Typography.Text strong style={{}}>
              {t("parking.RegistredVehiclesSummary")}
            </Typography.Text>
            <div style={{ padding: 8, display: "flex", gap: 8, flexDirection: "column" }}>
              {vehicles?.map((vehicle, i) => (
                <Card style={{ width: "100%" }} key={i}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                      <Glyph
                        name={
                          vehicle.type === "CAR"
                            ? "directions_car"
                            : vehicle.type === "BIKE"
                            ? "directions_bike"
                            : vehicle.type === "MOTORCYCLE"
                            ? "motorcycle"
                            : "electric_car"
                        }
                        size={24}
                        style={{ color: colors.secondary_base }}
                      />
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography.Text strong>
                          {vehicle.type === "CAR"
                            ? t("parking.Car")
                            : vehicle.type === "BIKE"
                            ? t("parking.Bike")
                            : vehicle.type === "MOTORCYCLE"
                            ? t("parking.Moto")
                            : t("parking.ElectricCar")}
                        </Typography.Text>
                        <Typography.Text>
                          {vehicle.name ? vehicle.name : ""}
                          {vehicle.name && vehicle.plate ? " | " : ""}
                          {vehicle.plate}
                        </Typography.Text>
                      </div>
                    </div>
                    {vehicleToDelete?.id === vehicle.id ? (
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          width: 230,
                          justifyContent: "end",
                        }}
                      >
                        <Typography.Text type="danger" strong style={{ marginTop: 2 }}>
                          {t("parking.DeleteVehicle")}
                        </Typography.Text>
                        <div style={{ display: "flex", gap: 8 }}>
                          <Button
                            type="quaternary"
                            size="small"
                            loading={fetching}
                            onClick={() => handleDeleteVehicle(vehicle)}
                            icon={<Glyph name="check" />}
                          />
                          <Button
                            type={fetching ? "primary" : "tertiary"}
                            size="small"
                            disabled={fetching}
                            onClick={() => setVehicleToDelete({})}
                            icon={<Glyph name="clear" />}
                          />
                        </div>
                      </div>
                    ) : (
                      <div style={{ display: "flex", gap: 8 }}>
                        <Tooltip title={t("parking.Modify")}>
                          <Button
                            type="primary"
                            size="small"
                            onClick={() => update(vehicle)}
                            icon={<Glyph name="edit" style={{ marginLeft: 2 }} />}
                          />
                        </Tooltip>
                        <Tooltip title={t("parking.Delete")}>
                          <Button
                            type="secondary"
                            size="small"
                            onClick={() => setVehicleToDelete(vehicle)}
                            icon={<Glyph name="delete" style={{ marginLeft: 1 }} />}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                </Card>
              ))}
            </div>
            <Button
              type="primary"
              onClick={() => add()}
              style={{ margin: "0 10%", marginTop: "10%" }}
              icon={<Glyph name="add" />}
            >
              {t("parking.AddVehicle")}
            </Button>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ManageVehiclesModal;
