import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  contactInfo: {},
  contactCategories: {},
  contactDetails: {},
  contacts: {},
  favorite: false,
  userData: {},
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// CONTACT LIST ///////////////////////////////
export const getUserData = thunk("contactList/getUserData", async ({ locale, siteId }) => {
  const userData = await DWMConnector.getUserData(locale, siteId);
  return userData;
});

EXTRA_REDUCERS[getUserData.fulfilled] = (state, action) => {
  state.userData = action.payload;
};

export const getContactCategories = thunk(
  "contactList/getContactCategories",
  async ({ siteId, locale }, { getState }) => {
    const contactCategories = await DWMConnector.getContactCategories(siteId, locale);
    return contactCategories;
  },
);

EXTRA_REDUCERS[getContactCategories.fulfilled] = (state, action) => {
  state.contactCategories = action.payload;
};

export const getContactDetails = thunk(
  "contactList/getContactDetails",
  async ({ siteId, userId, locale }, { getState }) => {
    const contactDetails = await DWMConnector.getContactDetails(siteId, userId, locale);
    return contactDetails;
  },
);

EXTRA_REDUCERS[getContactDetails.fulfilled] = (state, action) => {
  state.contactDetails = action.payload;
  state.contactDetails.contact.lists.find((contact) => contact.isFavorite === true)
    ? (state.favorite = true)
    : (state.favorite = false);
};

export const addFavoriteContact = thunk(
  "contactList/addFavoriteContact",
  async ({ userId }, { getState }) => {
    const contactFavorite = await DWMConnector.addFavoriteContact(userId);
    return contactFavorite;
  },
);

EXTRA_REDUCERS[addFavoriteContact.fulfilled] = (state, action) => {
  state.favorite = true;
};

export const removeFavoriteContact = thunk(
  "contactList/removeFavoriteContact",
  async ({ userId }, { getState }) => {
    const contactFavorite = await DWMConnector.removeFavoriteContact(userId);
    return contactFavorite;
  },
);

EXTRA_REDUCERS[removeFavoriteContact.fulfilled] = (state, action) => {
  state.favorite = false;
};

export const getContacts = thunk(
  "contactList/getContacts",
  async ({ siteId, search, page, size, id, groupId }, { getState }) => {
    const contacts = await DWMConnector.getContacts(siteId, search, page, size, id, groupId);
    return contacts;
  },
);

EXTRA_REDUCERS[getContacts.fulfilled] = (state, action) => {
  //logger.log("action.payload", action.payload, "state.contacts", state.contacts);

  if (action.payload.currentPage === 1) {
    // Si pas de contacts dans le state
    state.contacts = action.payload;
  } else {
    // Si des contacts dans le state
    if (state.contacts.currentPage !== action.payload.currentPage) {
      state.contacts = {
        ...state.contacts,
        items: [...state.contacts.items, ...action.payload.items],
        currentPage: action.payload.currentPage,
        nextPage: action.payload.nextPage,
        previousPage: action.payload.previousPage,
        totalPages: action.payload.totalPages,
      };
    }
  }
};

export const updateProfile = thunk(
  "contactList/updateProfile",
  async ({ aboutMe }, { getState }) => {
    const updateUser = await DWMConnector.updateProfile(aboutMe);
    return updateUser;
  },
);

EXTRA_REDUCERS[updateProfile.fulfilled] = (state, action) => {};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const contactListSlice = createSlice({
  name: "contactList",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});
export default contactListSlice.reducer;
