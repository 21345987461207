import {
  Calendar,
  Card,
  Col,
  Form,
  Radio,
  Row,
  Segmented,
  Switch,
  Tag,
  Tooltip,
  Typography,
} from "antd";
import moment from "moment";
import { cond, equals } from "ramda";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useDesignTokens from "../../../../../../hook/useDesignTokens";
import SpasConnector from "../../../../../../services/api/DWM/SpasConnector";
import { selectedCampus } from "../../../../../../services/redux/services/UserWS";
import Glyph from "../../../../../Common/Glyph/Glyph";
import CardDatePicker from "../../../../../Common/Inputs/CardDatePicker/CardDatePicker";
import DateSelector from "../../../../../Common/Inputs/DateSelector/DateSelector";
import { RECURRENCE } from "../../../SpaceBooking";
import { DEFAULT_WORKING_DAYS, PERIODS } from "../../../SpaceBookingResident";

const makeDateRange = (start, end, granularity, interval) => {
  const dates = [];
  let date = moment(start);
  while (date.isSameOrBefore(end, "date")) {
    dates.push(date.clone());
    date = date.add(interval, granularity);
  }
  return dates;
};

const getRecurrenceDates = (startDate, endDate, recurrence) => {
  const start = moment(startDate);
  const end = moment(endDate);
  let dates = [];
  switch (recurrence) {
    case RECURRENCE.EVERY_DAY:
      dates = makeDateRange(start, end, "day", 1);
      break;
    case RECURRENCE.EVERY_WEEK:
      dates = makeDateRange(start, end, "week", 1);
      break;
    case RECURRENCE.EVERY_2_WEEK:
      dates = makeDateRange(start, end, "week", 2);
      break;
    case RECURRENCE.EVERY_MONTH:
      dates = makeDateRange(start, end, "month", 1);
      break;
    default:
      break;
  }
  return dates;
};

const RecurrenceSelector = ({ value, onChange, options }) => {
  return (
    <Row gutter={[0, 10]}>
      {options.map((i) => (
        <Col key={i.value} span={24}>
          <Card style={{ cursor: "pointer" }} onClick={() => onChange(i.value)}>
            <div style={{ display: "flex", alignItems: "center", gap: 12 }}>
              <Typography.Text strong>{i.label}</Typography.Text>
              <div style={{ flexGrow: 1 }} />
              <Radio style={{ transform: "scale(1.2)" }} checked={value === i.value} />
            </div>
          </Card>
        </Col>
      ))}
    </Row>
  );
};

const CalendarMultiSelect = ({
  value = [],
  onChange,
  onViewChange,
  min,
  max,
  occupiedDates,
  disabledDate,
  ...rest
}) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  const form = Form.useFormInstance();

  const _min = useMemo(() => moment(min), [min]);
  const _max = useMemo(() => moment(max), [max]);

  // Cheating so the date is not selected when changing month with <DateSelector />
  const changedMonth = useRef(false);

  const [currentDate, setCurrentDate] = useState(value?.[0]);

  const recurrence = Form.useWatch("recurrence", form);
  const endDate = Form.useWatch("endDate", form);
  const recurrenceDates = useMemo(() => {
    if (!endDate) return [];
    const dates = getRecurrenceDates(value?.[0], endDate, recurrence);
    return dates.filter((i) => !occupiedDates(i) && !disabledDate(i));
  }, [disabledDate, endDate, occupiedDates, recurrence, value]);

  const handleAddDate = (date) => {
    if (changedMonth.current) {
      changedMonth.current = false;
      return;
    }
    if (date.isBefore(_min, "date") || date.isAfter(_max, "date")) return;
    if (value.some((d) => d.isSame(date, "date"))) return handleRemoveDate(date);
    onChange([...value, date].sort((a, b) => (a.isSameOrAfter(b) ? 1 : -1)));
  };
  const handleRemoveDate = (date) => {
    onChange(value.filter((d) => !d.isSame(date, "date")));
  };

  return (
    <Card
      bordered={false}
      style={{ overflow: "visible" }}
      bodyStyle={{ padding: 0, overflow: "visible" }}
    >
      <Typography.Paragraph type="secondary">{t("spas.request.dates.helper")}</Typography.Paragraph>
      <br />
      <Calendar
        {...rest}
        className="grey"
        fullscreen={false}
        value={currentDate}
        onSelect={handleAddDate}
        disabledDate={disabledDate}
        onChange={(date) => {
          onViewChange?.(date);
          setCurrentDate(date);
        }}
        headerRender={({ value, onChange }) => (
          <DateSelector
            style={{ marginBottom: 10 }}
            min={_min}
            max={_max}
            value={value}
            onChange={(date) => {
              changedMonth.current = true;
              onChange(date);
            }}
          />
        )}
        dateFullCellRender={(date) => {
          const isSelected = value.some((d) => d.isSame(date, "date"));
          const isInRecurrence = recurrenceDates.some((d) => d.isSame(date, "date"));
          const isOccupied = occupiedDates(date);
          return (
            <div
              style={{
                position: "relative",
                color: isOccupied ? "hsl(0 0% 85%)" : "black",
              }}
            >
              {date.format("DD")}
              {(isSelected || isInRecurrence) && (
                <div
                  style={{
                    position: "absolute",
                    bottom: -5,
                    left: "calc(50% - 3px)",
                    margin: "auto",
                    width: 6,
                    height: 6,
                    borderRadius: "50%",
                    background: colors.primary_base,
                    opacity: isSelected ? 1 : 0.3,
                  }}
                />
              )}
            </div>
          );
        }}
      />
      <br />
      <Typography.Paragraph type="secondary">
        {t("spas.request.dates.helper2")}
      </Typography.Paragraph>
      {[...value]
        .sort((a, b) => (a.isSameOrAfter(b) ? 1 : -1))
        .map((date) => {
          const isOccupied = occupiedDates(date);
          return (
            <Tooltip
              key={date.valueOf()}
              open={isOccupied ? undefined : false}
              title={t("spas.request.dates.dateOccupied")}
            >
              <Tag
                style={{
                  marginRight: 5,
                  marginBottom: 5,
                  fontWeight: "bold",
                  opacity: isOccupied ? 0.3 : 1,
                }}
                closable
                onClose={() => handleRemoveDate(date)}
              >
                {date.format("DD MMM YYYY")}
              </Tag>
            </Tooltip>
          );
        })}
      {!!recurrenceDates.length && (
        <>
          <Glyph
            style={{ color: colors.primary_base, margin: "0px 0.5rem", verticalAlign: "-6px" }}
            name="chevron_right"
          />
          <Tag
            style={{
              marginRight: 5,
              marginBottom: 5,
              fontWeight: "bold",
              opacity: 0.3,
            }}
          >
            {recurrenceDates[recurrenceDates.length - 1].format("DD MMM YYYY")}
          </Tag>
        </>
      )}
    </Card>
  );
};

const CreateStepsDate = ({ mode, form, initialValues }) => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();

  const startEndOffset = useRef(0);
  const leftPanelRef = useRef();
  const rightPanelRef = useRef();

  const site = useSelector((state) => selectedCampus(state, initialValues.siteId));

  const [date, setDate] = useState(initialValues?.dates?.[0]);
  const [useRecurrence, setUseRecurrence] = useState(!!initialValues.recurrence);
  const [slots, setSlots] = useState([]);

  const dates = Form.useWatch("dates", form);
  const period = Form.useWatch("period", form);

  useEffect(() => {
    if (useRecurrence) {
      form.setFieldsValue({ recurrence: RECURRENCE.EVERY_DAY });
    } else {
      form.setFieldsValue({ recurrence: null, endDate: null });
    }
  }, [form, useRecurrence]);

  useLayoutEffect(() => {
    form.validateFields(["dates"]);
  }, [form, period]);

  const { startMonth, startYear, endMonth, endYear } = useMemo(() => {
    const allDates = [...(dates || []), date]
      .filter(Boolean)
      .sort((a, b) => (a.isSameOrAfter(b) ? 1 : -1));
    return {
      startMonth: allDates[0]?.month(),
      startYear: allDates[0]?.year(),
      endMonth: allDates[allDates.length - 1]?.month(),
      endYear: allDates[allDates.length - 1]?.year(),
    };
  }, [date, dates]);

  useEffect(() => {
    const startDate = moment()
      .set({ month: startMonth, year: startYear })
      .subtract(1, "month")
      .startOf("month")
      .format("YYYY-MM-DD");
    const endDate = moment()
      .set({ month: endMonth, year: endYear })
      .add(1, "month")
      .endOf("month")
      .format("YYYY-MM-DD");

    logger.log("listSpaasSlots", { startDate, endDate });
    SpasConnector.listSpaasSlots({ startDate, endDate }).then((res) => {
      setSlots(res);
    });
  }, [endMonth, endYear, startMonth, startYear]);

  const slotsByDate = useMemo(() => {
    const slotsByDate = {};
    slots.forEach((slot) => {
      if (!slotsByDate[slot.date]) slotsByDate[slot.date] = [];
      slotsByDate[slot.date].push(slot);
    });
    return slotsByDate;
  }, [slots]);

  const occupiedDates = (date) => {
    if (mode === "team") return false;
    const slots = slotsByDate[date.format("YYYY-MM-DD")] || [];
    const isOccupied = slots.some((slot) =>
      cond([
        [equals(PERIODS.DAY), () => Object.values(PERIODS)],
        [equals(PERIODS.MORNING), () => [PERIODS.MORNING, PERIODS.DAY]],
        [equals(PERIODS.AFTERNOON), () => [PERIODS.AFTERNOON, PERIODS.DAY]],
      ])(slot.request.period).includes(period),
    );
    return isOccupied;
  };

  const dateMin = moment();
  const dateMax = moment().add(site.spaceBooking.maxRecurrenceMonths || 6, "month");

  const workingDays = site?.spaceBooking?.workingDays || DEFAULT_WORKING_DAYS;
  const holidays = site?.holidays || [];

  return (
    <Form
      style={{ display: "flex", height: "100%" }}
      form={form}
      name={"dates"}
      initialValues={initialValues}
      onValuesChange={(changed, all) => {
        if (changed.endDate) {
          startEndOffset.current = moment(changed.endDate).diff(
            moment(all.dates?.[0]),
            "milliseconds",
          );
        }

        const startDate = changed.dates?.[0];
        const endDate = form.getFieldValue(["endDate"]);
        if (startDate && endDate && startDate.isAfter(endDate)) {
          form.setFieldsValue({
            endDate: moment(startDate).add(startEndOffset.current, "milliseconds"),
          });
        }
      }}
      onFinishFailed={({ errorFields }) => {
        const fields = errorFields.map((i) => i.name.join("."));
        logger.log("onFinishFailed", fields);

        if (fields.includes("dates")) {
          const el = leftPanelRef.current.querySelector(".ant-card-body");
          logger.log("leftPanelRef", el, el.scrollHeight);
          el.scroll({
            top: el.scrollHeight,
            behavior: "smooth",
          });
        }

        if (fields.includes("endDate")) {
          const el = rightPanelRef.current.querySelector(".ant-card-body");
          logger.log("rightPanelRef", el, el.scrollHeight);
          el.scroll({
            top: el.scrollHeight,
            behavior: "smooth",
          });
        }
      }}
    >
      <div
        style={{
          padding: "0px 20px",
          flexBasis: "50%",
          overflowY: "auto",
          borderRight: "1px solid #ececec",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={4}>{t("spas.request.dates.title")}</Typography.Title>
        <Card ref={leftPanelRef} bordered={false} style={{ flexGrow: 1 }}>
          <Form.Item noStyle shouldUpdate={(pre, cur) => pre.period !== cur.period}>
            {() => (
              <Form.Item
                name={["dates"]}
                rules={[
                  { type: "array", min: 1, message: t("spas.request.dates.validator.min") },
                  {
                    validator: (_, value) => {
                      if (value.length && !value.filter((i) => !occupiedDates(i)).length) {
                        return Promise.reject(t("spas.request.dates.validator.occupied"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <CalendarMultiSelect
                  min={dateMin}
                  max={dateMax}
                  onViewChange={setDate}
                  occupiedDates={occupiedDates}
                  disabledDate={(date) => {
                    const day = date.format("YYYY-MM-DD");
                    const isBefore = date.isBefore(moment(), "date");
                    const isAfter = date.isAfter(dateMax, "date");
                    const inWorkingDays = workingDays.includes(date.isoWeekday());
                    const inHolidays = holidays.includes(day);
                    return isBefore || isAfter || !inWorkingDays || inHolidays;
                  }}
                />
              </Form.Item>
            )}
          </Form.Item>
        </Card>
      </div>
      <div
        style={{
          padding: "0px 20px",
          flexBasis: "50%",
          overflowY: "auto",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography.Title level={4}>{t("spas.request.period.helper")}</Typography.Title>
        <Card ref={rightPanelRef} bordered={false} style={{ flexGrow: 1 }}>
          <Row gutter={[0, 20]}>
            <Col span={24}>
              <Form.Item noStyle name={["period"]}>
                <Segmented
                  style={{ width: "100%" }}
                  size="large"
                  options={[
                    { label: t("spas.period.DAY"), value: "DAY" },
                    { label: t("spas.period.MORNING"), value: "MORNING" },
                    { label: t("spas.period.AFTERNOON"), value: "AFTERNOON" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Form.Item noStyle shouldUpdate={(pre, cur) => pre.dates !== cur.dates}>
              {({ getFieldValue }) => {
                const enableRecurrence = getFieldValue(["dates"])?.length <= 1;
                return (
                  <>
                    <Col span={24}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography.Text>{t("spas.request.recurrence.helper")}</Typography.Text>
                        <Switch
                          disabled={!enableRecurrence}
                          checked={!enableRecurrence ? false : useRecurrence}
                          onChange={(checked) => setUseRecurrence(checked)}
                        />
                      </div>
                    </Col>
                    {!enableRecurrence && (
                      <Col span={24}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: 20,
                          }}
                        >
                          <Glyph style={{ color: colors.error_light }} name="warning" />
                          <Typography.Text type="secondary">
                            {t("spas.request.recurrence.multi")}
                          </Typography.Text>
                        </div>
                      </Col>
                    )}

                    {enableRecurrence && useRecurrence && (
                      <>
                        <Col span={24}>
                          <Typography.Paragraph>
                            {t("spas.request.recurrence")}
                          </Typography.Paragraph>
                          <Form.Item
                            noStyle
                            name={["recurrence"]}
                            rules={[
                              {
                                validator: (_, value) => {
                                  logger.log("validator", value);
                                  if (!value && useRecurrence) return Promise.reject();
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <RecurrenceSelector
                              options={Object.values(RECURRENCE).map((i) => ({
                                value: i,
                                label: t(`spas.recurrence.${i}`, {
                                  day: moment(getFieldValue(["dates"])?.[0]).format("dddd"),
                                }),
                              }))}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={24}>
                          <Typography.Paragraph>
                            {t("spas.request.recurrence.end")}
                          </Typography.Paragraph>
                          <Form.Item
                            noStyle
                            name={["endDate"]}
                            rules={[
                              {
                                validator: (_, value) => {
                                  if (!value && useRecurrence) return Promise.reject();
                                  return Promise.resolve();
                                },
                              },
                            ]}
                          >
                            <CardDatePicker
                              label={
                                <Typography.Text strong>
                                  {t("spas.request.endDate", { date: "" })}
                                </Typography.Text>
                              }
                              pickerProps={{
                                defaultPickerValue: moment(
                                  initialValues.endDate || initialValues.dates?.[0],
                                ),
                                disabledDate: (date) => {
                                  const day = date.format("YYYY-MM-DD");
                                  const min = moment(getFieldValue(["dates"])?.[0]);
                                  const isBefore = date.isBefore(min, "date");
                                  const isAfter = date.isAfter(dateMax, "date");
                                  const inWorkingDays = workingDays.includes(date.isoWeekday());
                                  const inHolidays = holidays.includes(day);
                                  return isBefore || isAfter || !inWorkingDays || inHolidays;
                                },
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </>
                    )}
                  </>
                );
              }}
            </Form.Item>
          </Row>
        </Card>
      </div>
    </Form>
  );
};

export default CreateStepsDate;
