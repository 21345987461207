import { useKeycloak } from "@react-keycloak/web";
import { Card, Col, Image, Row, Spin, Tag, Typography } from "antd";
import Meta from "antd/lib/card/Meta";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import styles from "./News.module.less";
import { isEmpty, isNil } from "ramda";
import { getAnalytics, logEvent } from "firebase/analytics";
import app from "../../../services/api/Firebase/Firebase";
import { useEffect } from "react";
import { getNewsByTopics } from "../../../services/redux/services/NewsWS";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import { LoadingOutlined } from "@ant-design/icons";
import { ReactComponent as NoNews } from "../../../assets/svg/noNews.svg";

const analytics = getAnalytics(app);

const NewsList = ({ single }) => {
  const { t, i18n } = useTranslation();
  const { colors, size } = useDesignTokens();
  const { keycloak } = useKeycloak();
  const { sectionId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const news = useSelector((state) => state.newsWS.newsByTopics);
  const userData = useSelector((state) => state.userWS.userData);

  const isLoading = useSelector(createLoadingSelector(["news/getNewsByTopics"]));

  useEffect(() => {
    const fetchNews = async () => {
      const news = await dispatch(
        getNewsByTopics({
          locale: i18n.language.split("-")[0],
          viewId: sectionId,
        }),
      );

      const singleNews = !!news.payload?.news?.length ? news.payload.news[0] : null;
      if (single && singleNews) {
        history.push(`${sectionId}/${singleNews?.topics[0]}/${singleNews?.id}`);
      }
    };
    fetchNews();
  }, [dispatch, i18n.language, sectionId, single, history]);

  if (single) return null;

  return (
    <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}>
      {!isEmpty(news?.news) ? (
        <>
          {!isEmpty(news) && !userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold && (
            <>
              <Typography.Title level={4} style={{ color: colors.secondary_base }}>
                {t("news.spotlight")}
              </Typography.Title>
              <Card bodyStyle={{ display: "flex", flexFlow: "row", minHeight: "136px" }}>
                {!isNil(news?.news[0]?.header) && (
                  <div className={styles["pictures"]}>
                    <Image
                      preview={false}
                      src={`${news?.news[0]?.header?.url}?access_token=${keycloak.token}`}
                    />
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    marginLeft: "16px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography.Text type="secondary" style={{ fontSize: size.s }}>
                      <Glyph name="schedule" style={{ marginRight: "8px" }} />
                      {moment(news?.news[0]?.publishedAt).format("L")}
                    </Typography.Text>
                    <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
                      {news?.news[0]?.authorizeComment && (
                        <>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: size.s, marginRight: "8px" }}
                          >
                            <Glyph name="comment" style={{ marginRight: "4px" }} />
                            {news?.news[0]?.nbComments}
                          </Typography.Text>

                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: size.s, marginRight: "8px" }}
                          >
                            {news?.news[0]?.isLiked ? (
                              <Glyph
                                name="favorite"
                                style={{ marginRight: "4px", color: colors?.error_light }}
                              />
                            ) : (
                              <Glyph name="favorite_border" style={{ marginRight: "4px" }} />
                            )}

                            {news?.news[0]?.nbLikes}
                          </Typography.Text>
                          <Typography.Text type="secondary" style={{ fontSize: size.s }}>
                            <Glyph name="bar_chart" style={{ marginRight: "4px" }} />
                            {news?.news[0]?.nbViews}
                          </Typography.Text>
                        </>
                      )}
                    </div>
                  </div>

                  <Typography.Text strong>
                    {news?.news[0]?.title}{" "}
                    <NavLink
                      to={{
                        pathname: `${sectionId}/${news?.news[0]?.topics[0]}/${news?.news[0]?.id}`,
                      }}
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        logEvent(analytics, `news_highlight_news`);
                        logEvent(analytics, `news_select_details`, {
                          from: "highlight",
                          news_name: news?.news[0]?.title,
                        });
                      }}
                    >
                      {t("news.read.article")}
                    </NavLink>
                  </Typography.Text>
                  <div style={{ display: "flex", flexFlow: "row" }}>
                    {news?.topics
                      .filter((t) => t.newsIds.includes(news?.news[0]?.id))
                      .map((t, index) => (
                        <Typography.Text
                          type="secondary"
                          style={{ marginRight: "8px" }}
                          key={index}
                        >
                          {t.label}
                        </Typography.Text>
                      ))}
                  </div>
                </div>
              </Card>
            </>
          )}

          {news?.topics
            ?.filter((t) => !isEmpty(t?.newsIds))
            .map((topic, index) => (
              <div style={{ marginTop: "16px" }} key={index}>
                {topic?.newsIds.length !== 0 && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginBottom: "8px",
                    }}
                  >
                    <Typography.Text style={{ fontSize: size.xl }}>{topic?.label}</Typography.Text>
                    {topic.hasMore && (
                      <NavLink
                        to={`/news/${sectionId}/${topic.id}`}
                        onClick={() => {
                          logEvent(analytics, `news_access_topic_list`, {
                            topic_name: topic?.label,
                          });
                        }}
                      >
                        {t("news.all.article")} {">"}{" "}
                      </NavLink>
                    )}
                  </div>
                )}

                <div style={{ width: "100%", overflowX: "auto", padding: 16 }}>
                  <div
                    style={{
                      display: "flex",
                      gap: 16,
                      width: "fit-content",
                      position: "relative",
                    }}
                  >
                    {news?.news
                      ?.filter((i) => i.topics.includes(topic.id))
                      .slice(0, 3)
                      .map((item, index) => (
                        <div key={index}>
                          {item?.isNew && (
                            <Tag
                              style={{
                                position: "absolute",
                                zIndex: "900",
                                top: "10px",
                                left: "16px",
                              }}
                              color={colors.primary_light}
                            >
                              <Typography.Text strong style={{ color: "white" }}>
                                {t("New")}
                              </Typography.Text>
                            </Tag>
                          )}

                          <Card
                            onClick={() => {
                              history.push(`${sectionId}/${topic?.id}/${item?.id}`);
                              logEvent(analytics, `news_select_details`, {
                                from: "news",
                                news_name: item?.title,
                              });
                            }}
                            style={{ cursor: "pointer", minWidth: 280, width: 280 }}
                            cover={
                              !isNil(item?.header) ? (
                                <div
                                  style={{ boxShadow: "none" }}
                                  className={styles["newsListPictures"]}
                                >
                                  <Image
                                    preview={false}
                                    src={`${item?.header?.url}?access_token=${keycloak.token}`}
                                  />
                                </div>
                              ) : (
                                <div
                                  className={styles["noPictures"]}
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    height: "200px",
                                    width: "100%",
                                    backgroundColor: colors.grey_40,
                                    opacity: 0.3,
                                    zIndex: 1,
                                  }}
                                >
                                  <Glyph
                                    style={{ fontSize: "60px", color: "white", opacity: 0.8 }}
                                    name="article"
                                  />
                                </div>
                              )
                            }
                          >
                            <Meta
                              title={<Typography.Title level={5}>{item?.title}</Typography.Title>}
                              description={
                                <div
                                  style={{
                                    display: "flex",
                                    flexFlow: "row",
                                    justifyContent: "space-between",
                                    width: "100%",
                                  }}
                                >
                                  <Typography.Text type="secondary">
                                    <Glyph name="schedule" style={{ marginRight: "8px" }} />
                                    {moment(item.publishedAt).format("DD/MM/YY")}
                                  </Typography.Text>
                                  <div
                                    style={{
                                      display: "flex",
                                      flexFlow: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    {item?.authorizeComment && (
                                      <>
                                        <Typography.Text
                                          type="secondary"
                                          style={{ fontSize: size.s, marginRight: "8px" }}
                                        >
                                          <Glyph name="comment" style={{ marginRight: "4px" }} />
                                          {item?.nbComments}
                                        </Typography.Text>

                                        <Typography.Text
                                          type="secondary"
                                          style={{ fontSize: size.s, marginRight: "8px" }}
                                        >
                                          {item?.isLiked ? (
                                            <Glyph
                                              name="favorite"
                                              style={{
                                                marginRight: "4px",
                                                color: colors?.error_light,
                                              }}
                                            />
                                          ) : (
                                            <Glyph
                                              name="favorite_border"
                                              style={{ marginRight: "4px" }}
                                            />
                                          )}

                                          {item?.nbLikes}
                                        </Typography.Text>
                                        <Typography.Text
                                          type="secondary"
                                          style={{ fontSize: size.s }}
                                        >
                                          <Glyph name="bar_chart" style={{ marginRight: "4px" }} />
                                          {item?.nbViews}
                                        </Typography.Text>
                                      </>
                                    )}
                                  </div>
                                </div>
                              }
                            />
                          </Card>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            ))}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <NoNews style={{ width: 500, height: "auto" }} />
          <Typography.Text strong style={{ marginTop: "30px" }}>
            {t("news.detail.noNews")}
          </Typography.Text>
        </div>
      )}
    </Spin>
  );
};

export default NewsList;
