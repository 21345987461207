import React, { useEffect, useState } from "react";
import useDesignTokens from "../../../hook/useDesignTokens";
import { Button, Modal, Input, Typography, List, Avatar, Checkbox } from "antd";
import Glyph from "../../Common/Glyph/Glyph";
import { CreateChannelProvider } from "@sendbird/uikit-react/CreateChannel/context";
import { useDispatch, useSelector } from "react-redux";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { getContactsUsingChat } from "../../../services/redux/services/InstantMessagingWS";
import { sendbirdSelectors, useSendbirdStateContext } from "@sendbird/uikit-react";
import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context";

const UserListModal = ({ visible, setVisible, type, setQuery, setCurrentChannel }) => {
  const dispatch = useDispatch();
  const { colors } = useDesignTokens();
  const [filter, setFilter] = useState("");
  const { t, i18n } = useTranslation();
  const { keycloak } = useKeycloak();
  const globalStore = useSendbirdStateContext();
  const sdk = sendbirdSelectors.getSdk(globalStore);
  const userId = globalStore.stores.userStore.user.userId;
  const channelListContext = useChannelListContext();

  const [page, setPage] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const campus = useSelector((state) => state.clientsWS.campus);
  const contacts = useSelector((state) => state.instantMessagingWS.contacts);

  const handleAddUser = async () => {
    await channelListContext.currentChannel.inviteWithUserIds(selectedUsers);
    setCurrentChannel(channelListContext.currentChannel);
    setVisible(false);
  };

  const handleCreateChannel = async () => {
    const selectedUserList = selectedUsers.length > 0 ? selectedUsers : [userId];
    const params = {
      invitedUserIds: selectedUserList,
      isDistinct: true,
      operatorUserIds: [userId],
      name: "",
    };
    const newChannel = await sdk.groupChannel.createChannel(params);
    setCurrentChannel(newChannel);
    setVisible(false);
  };

  // get contacts
  useEffect(() => {
    dispatch(
      getContactsUsingChat({
        siteId: campus?.mapData?.id,
        search: searchValue,
        page: page,
        id: null,
        size: 50,
        groupId: null,
      }),
    );
  }, [campus?.mapData?.id, filter, dispatch, i18n.language, page, searchValue]);

  // pagination get contacts
  useEffect(() => {
    setPage(1);
  }, [filter]);

  // infinite scrolling
  useEffect(() => {
    const container = document.getElementById("container");
    const handleScroll = () => {
      const { scrollTop, clientHeight, scrollHeight } = container;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        if (contacts.currentPage !== contacts.totalPages) {
          setPage(contacts.nextPage);
        }
      }
    };
    container?.addEventListener("scroll", handleScroll);
    return () => {
      container?.removeEventListener("scroll", handleScroll);
    };
  }, [contacts]);

  return (
    <CreateChannelProvider>
      <Modal open={visible} onCancel={() => setVisible(false)} footer={null}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "10px",
          }}
        >
          <Typography.Title level={4}>
            {type === "createChannel"
              ? t("MODAL__CREATE_CHANNEL__TITLE")
              : t("CHANNEL_SETTING__MEMBERS__INVITE_MEMBER")}
          </Typography.Title>

          <Input
            prefix={<Glyph name="search" style={{ color: colors.grey_40 }} />}
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            placeholder={t("MODAL__INVITE_SEARCH_MEMBER")}
            value={searchValue}
            suffix={
              !!filter && (
                <Glyph
                  name="cancel"
                  style={{ cursor: "pointer", color: colors.grey_40 }}
                  onClick={() => setFilter("")}
                />
              )
            }
          />
          <Typography.Text type="secondary">
            {selectedUsers.length
              ? `${selectedUsers.length} ${
                  selectedUsers.length > 1
                    ? t("CHANNEL_SETTING__MEMBERS__TITLE")
                    : t("CHANNEL_SETTING__MEMBER__TITLE")
                } ${t("MODAL__INVITE_MEMBER__SELECTED")}${
                  selectedUsers.length > 1 && i18n.language === "fr" ? "s" : ""
                }`
              : t("MODAL__INVITE_NO_MEMBER_SELECTED")}
          </Typography.Text>
          <div
            id="container"
            style={{
              maxHeight: "40vh",
              overflowY: "auto",
              paddingRight: "10px",
            }}
          >
            {contacts && (
              <List
                dataSource={contacts.items}
                renderItem={(item) => (
                  <List.Item key={item.email}>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          src={`${item.photoUrl}?access_token=${keycloak.token}`}
                          style={{
                            border: "none",
                            boxShadow: "none",
                            backgroundColor: "#bdbdbd",
                          }}
                        >
                          {item.lastname?.charAt(0).toUpperCase()}
                          {item.firstname?.charAt(0).toUpperCase()}
                        </Avatar>
                      }
                      title={`${item.firstname} ${item.lastname}`}
                    />
                    <Checkbox
                      name={item.id}
                      disabled={
                        !item.chatId ||
                        (type === "userList" &&
                          channelListContext.currentChannel?.members?.find(
                            (member) => member.userId === item.chatId,
                          ))
                      }
                      checked={
                        (type === "userList" &&
                          channelListContext.currentChannel?.members?.find(
                            (member) => member.userId === item.chatId,
                          )) ||
                        selectedUsers.find((id) => id === item.chatId)
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedUsers([...selectedUsers, item.chatId]);
                        } else {
                          setSelectedUsers(selectedUsers.filter((id) => id !== item.chatId));
                        }
                      }}
                    />
                  </List.Item>
                )}
              />
            )}

            {contacts.currentPage !== contacts.totalPages && contacts.totalPages > 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                <Typography.Text
                  id="showmore"
                  style={{
                    cursor: "pointer",
                    color: colors.pending_light,
                    marginTop: "10px",
                  }}
                  onClick={() => setPage(contacts.nextPage)}
                >
                  {t("SHOW_MORE_CONTACTS")}
                </Typography.Text>
              </div>
            )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              gap: "10px",
              justifyContent: "center",
            }}
          >
            <Button
              type={!!selectedUsers.length && "primary"}
              style={{ width: "200px" }}
              onClick={type === "createChannel" ? handleCreateChannel : handleAddUser}
              disabled={!!!selectedUsers.length}
            >
              {type === "createChannel" ? t("BUTTON__CREATE") : t("BUTTON__INVITE")}
            </Button>
            <Button
              type="ghost"
              style={{
                width: "200px",
              }}
              onClick={() => setVisible(false)}
            >
              {t("BUTTON__CANCEL")}
            </Button>
          </div>
        </div>
      </Modal>
    </CreateChannelProvider>
  );
};

export default UserListModal;
