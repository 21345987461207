import React, { useEffect } from "react";
import Glyph from "../../../../Common/Glyph/Glyph";
import {
  Card,
  DatePicker,
  Input,
  InputNumber,
  Popover,
  TimePicker,
  Typography,
  notification,
} from "antd";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import { useTranslation } from "react-i18next";
import FormDefault from "./FormDefault";
import moment from "moment";
import { useSelector } from "react-redux";
import { range } from "ramda";

const CreateTicketForm = ({ newTicket, setNewTicket, attachements, setAttachements, site }) => {
  const { colors, symbols, shadows } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];

  const campus = useSelector((state) => state.userWS.userData.campus);
  const holidays = campus?.find((c) => c.id === site)?.holidays;

  const handleAddAttachement = (e, type) => {
    const params = {};
    params.file = e.target.files[0];

    const allowed =
      type === "files"
        ? ["image", "audio", "video", "application/pdf"].includes(params.file.type.split("/")[0])
        : ["image", "video"].includes(params.file.type.split("/")[0]);

    if (!allowed) {
      e.target.value = "";
      notification.open({
        message: t("FILE_TYPE_NOT_ALLOWED"),
      });
      return;
    }

    if (params.file.size > 25000000) {
      e.target.value = "";
      notification.open({
        message: t("FILE_TOO_BIG"),
      });
      return;
    }

    setAttachements({
      ...attachements,
      [type]: [...attachements[type], params.file],
    });
    /*setAttachements({
      ...attachements,
      [f.type]: [...attachements[f.type], e.target.files[0]],
    });*/
  };

  useEffect(() => {
    logger.log("newTicket", newTicket);
  }, [newTicket]);

  const handleAnswer = (e, index, key) => {
    logger.log("time", e, index, key);

    const isDate =
      newTicket.form.items[index]?.type === "datepicker" ||
      newTicket.form.items[index]?.type === "delivery";
    const isDateTime = isDate && newTicket.form.items[index]?.hasTime;
    const answer = isDate
      ? isDateTime
        ? moment(e).format().toString()
        : moment(e).format("YYYY-MM-DD").toString()
      : e;

    setNewTicket((prevNewTicket) => ({
      ...prevNewTicket,
      form: {
        items: prevNewTicket.form.items.map((item, i) => {
          if (i === index) {
            if (key) {
              return { ...item, answer: { ...item.answer, [key]: answer } };
            } else {
              return { ...item, answer: answer };
            }
          } else {
            return item;
          }
        }),
      },
    }));
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 28,
        alignItems: "start",
        width: "100%",
      }}
    >
      {!!newTicket?.form?.items?.length ? (
        newTicket.form.items.map((f, index) => (
          <div
            key={f.name}
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
              gap: f.type === "radio" || f.type === "checkbox" ? 4 : 0,
              backgroundColor:
                f.type === "radio" || f.type === "checkbox" ? "white" : colors.light_background,
              padding: f.type === "radio" || f.type === "checkbox" ? 12 : 0,
              borderRadius: symbols.base_shape.radius,
              boxShadow:
                f.type === "radio" || f.type === "checkbox"
                  ? `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`
                  : "none",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", marginBottom: 8 }}>
              <Typography.Text strong style={{ fontSize: 17 }}>
                {f.title[locale] || Object.values(f.title)[0]}
              </Typography.Text>
              {f.caption && (
                <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
                  {f.caption[locale] || Object.values(f.caption)[0]}
                </Typography.Text>
              )}
            </div>

            {(f.type === "files" || f.type === "pictures") && (
              <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                <input
                  type="file"
                  accept={
                    f.type === "files"
                      ? "application/pdf, image/*, video/*, audio/*"
                      : "image/*, video/*"
                  }
                  disabled={
                    f.type === "files"
                      ? attachements.files.length >= 3
                      : attachements.pictures.length >= 3
                  }
                  onChange={(e) => {
                    handleAddAttachement(e, f.type);
                    e.target.value = null;
                  }}
                  style={{ display: "none" }}
                  id={`${f.type}_${index}`}
                />
                <label htmlFor={`${f.type}_${index}`}>
                  <Card
                    style={{
                      width: 137,
                      height: 112,
                      cursor: attachements[f.type]?.length >= 3 ? "default" : "pointer",
                      //border: attachements.typeId === type.id ? `2px solid ${colors.secondary_base}` : "",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        height: "100%",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 8,
                      }}
                    >
                      <Glyph
                        name={f.type === "files" ? "attach_file" : "photo"}
                        style={{
                          fontSize: 28,
                          color:
                            attachements[f.type]?.length >= 3
                              ? colors.grey_40
                              : colors.secondary_base,
                        }}
                      />
                      <Typography.Text
                        strong
                        style={{
                          fontSize: 12,
                          textAlign: "center",
                          color: attachements[f.type]?.length >= 3 && colors.grey_40,
                        }}
                      >
                        {f.type === "files" ? t("ticketingv2.AddFile") : t("ticketingv2.AddPhoto")}
                      </Typography.Text>
                    </div>
                  </Card>
                </label>
                {attachements[f.type]?.map((fileOrImage, index) => (
                  <Popover content={fileOrImage.name} trigger="hover" key={fileOrImage}>
                    <Card
                      style={{
                        width: 136,
                        height: 112,
                        position: "relative",
                        overflow: "visible",
                      }}
                      bodyStyle={{
                        padding: 0,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {fileOrImage.type.split("/")[0] === "image" ? (
                        <img
                          src={URL.createObjectURL(fileOrImage)}
                          alt="uploaded pic"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            borderRadius: symbols.base_shape.radius,
                          }}
                        />
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            gap: 8,
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            padding: 8,
                          }}
                        >
                          <Glyph
                            name="insert_drive_file"
                            style={{
                              fontSize: 28,
                              color: colors.grey_60,
                            }}
                          />
                          <Typography.Text
                            strong
                            style={{ fontSize: 12, textAlign: "center", lineHeight: 1.2 }}
                          >
                            {fileOrImage.name}
                          </Typography.Text>
                        </div>
                      )}

                      <div
                        style={{
                          position: "absolute",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: "white",
                          top: -5,
                          right: -5,
                          cursor: "pointer",
                          width: 18,
                          height: 18,
                          borderRadius: "50%",
                        }}
                      >
                        <Glyph
                          name="cancel"
                          onClick={() =>
                            setAttachements({
                              ...attachements,
                              [f.type]: attachements[f.type]?.filter((_, i) => i !== index),
                            })
                          }
                          style={{
                            color: colors.primary_base,
                            fontSize: 16,
                            position: "absolute",
                            cursor: "pointer",
                          }}
                        />
                      </div>
                    </Card>
                  </Popover>
                ))}
              </div>
            )}

            {(f.type === "textarea" || f.type === "text") &&
              (f.type === "textarea" ? (
                <Input.TextArea
                  rows={4}
                  allowClear
                  value={f.answer}
                  placeholder={t("ticketingv2.AddDescription")}
                  onChange={(e) => handleAnswer(e.target.value, index)}
                  style={{ fontSize: 14 }}
                />
              ) : (
                <Input
                  type={f.isNumber ? "number" : "text"}
                  controls={false}
                  allowClear
                  onChange={(e) =>
                    handleAnswer(f.isNumber ? parseInt(e.target.value) : e.target.value, index)
                  }
                  placeholder={t("ticketingv2.TypeAnswser")}
                  value={f.answer}
                  prefix={
                    <Glyph name="edit" style={{ fontSize: 16, color: colors.pending_light }} />
                  }
                  style={{ fontSize: 14 }}
                />
              ))}

            {(f.type === "radio" || f.type === "checkbox") && (
              <div
                style={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "start",
                  gap: 8,
                }}
              >
                {f.value && !!f.value.length && (
                  <div style={{ display: "flex", flexDirection: "column", gap: 8, width: "100%" }}>
                    {f.value.map((input) => (
                      <div
                        key={input.value}
                        onClick={() => {
                          f.type === "radio"
                            ? handleAnswer(input.value, index)
                            : f.answer && Array.isArray(f.answer) && f.answer?.includes(input.value)
                            ? handleAnswer(
                                f.answer?.filter((a) => a !== input.value),
                                index,
                              )
                            : Array.isArray(f.answer)
                            ? handleAnswer([...f.answer, input.value], index)
                            : handleAnswer([input.value], index);
                        }}
                        style={{
                          padding: 8,
                          backgroundColor: colors.light_background,
                          borderRadius: symbols.base_shape.radius,
                          width: "100%",
                          display: "flex",
                          flexGrow: 1,
                          justifyContent: "space-between",
                          border: "none !important",
                          cursor: "pointer",
                        }}
                      >
                        <Typography.Text strong style={{ fontSize: 14 }}>
                          {input.title[locale]}
                        </Typography.Text>
                        <Glyph
                          name={
                            f.type === "radio"
                              ? f.answer === input.value
                                ? "radio_button_checked"
                                : "radio_button_unchecked"
                              : f.answer?.includes(input.value)
                              ? "check_box"
                              : "check_box_outline_blank"
                          }
                          style={{
                            fontSize: 20,
                            color:
                              f.type === "radio"
                                ? f.answer === input.value
                                  ? colors.secondary_base
                                  : colors.grey_60
                                : f.answer?.includes(input.value)
                                ? colors.secondary_base
                                : colors.grey_60,
                          }}
                        />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}

            {f.type === "datepicker" && (
              <Card bodyStyle={{ padding: "8px 12px" }}>
                <div
                  style={{
                    display: "flex",
                    gap: 8,
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                    <Glyph name="schedule" style={{ color: colors.secondary_base, fontSize: 24 }} />
                    <Typography.Text strong style={{ fontSize: 14 }}>
                      {t("ticketingv2.ChooseADate")}
                      {f.hasTime ? t("ticketingv2._andATime") : ""}
                    </Typography.Text>
                  </div>
                  <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                    <DatePicker
                      size="middle"
                      placeholder="Date"
                      value={moment(f.answer)}
                      onChange={(e) => handleAnswer(e, index)}
                      format={locale === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                    />
                    {f.hasTime && f.answer && (
                      <TimePicker
                        size="middle"
                        hideDisabledOptions={true}
                        minuteStep={15}
                        showNow={false}
                        value={moment(f.answer)}
                        placeholder={t("ticketingv2.Time")}
                        onChange={(e) => handleAnswer(e, index)}
                        format={"HH:mm"}
                      />
                    )}
                  </div>
                </div>
              </Card>
            )}

            {f.type === "delivery" && (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: 12,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: symbols.base_shape.radius,
                    border: `2px solid ${colors.grey_40}`,
                    marginBottom: 8,
                  }}
                >
                  <Typography.Text>
                    {t("ticketingv2.OrderMustBe_")}
                    <b>
                      {f.orderBefore}
                      {t("ticketingv2._hoursBeforeDelivery")}
                    </b>
                  </Typography.Text>
                  <Typography.Text>
                    {t("ticketingv2.OrderCanBeCanceled_")}
                    <b>
                      {f.cancelBefore}
                      {t("ticketingv2._hours_")}
                      {f.cancelBefore === "before"
                        ? t("ticketingv2.beforeDelivery")
                        : t("ticketingv2.afterOrdering")}
                    </b>
                  </Typography.Text>
                  <Typography.Text>
                    {t("ticketingv2.WeekendsAndHolidays_")}
                    <b>
                      {f.countRules ? t("ticketingv2.are_") : t("ticketingv2.areNot_")}
                      {t("ticketingv2._countInDelays")}
                    </b>
                  </Typography.Text>
                  <Typography.Text>
                    {t("ticketingv2.DeliveriesArePossibleFrom_")}
                    <b>
                      {moment(f.deliveryStart).format("HH:mm")}
                      {t("ticketingv2._to_")}
                      {moment(f.deliveryEnd).format("HH:mm")}
                    </b>
                  </Typography.Text>
                </div>
                <Card bodyStyle={{ padding: "8px 12px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                      <Glyph
                        name="schedule"
                        style={{ color: colors.secondary_base, fontSize: 24 }}
                      />
                      <Typography.Text strong style={{ fontSize: 14 }}>
                        {t("ticketingv2.ChooseADate")}
                        {f.hasTime ? t("ticketingv2._andATime") : ""}
                      </Typography.Text>
                    </div>
                    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                      <DatePicker
                        inputReadOnly
                        size="middle"
                        disabledDate={(current) => {
                          const currentWithDeliveryEnd = moment(current)
                            .hours(moment(f.deliveryEnd).hours())
                            .minutes(moment(f.deliveryEnd).minutes());

                          // disabled si la date est avant la date actuelle
                          if (moment().add(f.orderBefore, "hours").isAfter(currentWithDeliveryEnd))
                            return true;

                          // disabled si la date est un jour ferié
                          if (holidays && holidays.includes(current.format("YYYY-MM-DD")))
                            return true;

                          // disabled si la date est un weekend
                          if (current.day() === 0 || current.day() === 6) return true;

                          return false;
                        }}
                        placeholder="Date"
                        onChange={(e) => handleAnswer(e, index)}
                        value={moment(f.answer)}
                        format={locale === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY"}
                      />
                      {f.hasTime && f.answer && (
                        <TimePicker
                          inputReadOnly
                          size="middle"
                          hideDisabledOptions={true}
                          minuteStep={5}
                          showNow={false}
                          disabledTime={(now) => {
                            //! on rajoute le filtre f.orderBefore uniquement si il est présent
                            const orderDateWithDelay = moment().add(f.orderBefore, "hours");
                            const deliveryOnEdgeDay = orderDateWithDelay.isSame(
                              f.answer.date,
                              "day",
                            );
                            const disabledBeforeDeliveryHour = moment(f.deliveryStart).hours();
                            const disabledBeforeOrderHour = deliveryOnEdgeDay
                              ? orderDateWithDelay.hours()
                              : disabledBeforeDeliveryHour;
                            const disabledAfterOrderHour = orderDateWithDelay
                              ? orderDateWithDelay.hours() + 1
                              : 24;

                            const disabledAfterDeliveryHour = moment(f.deliveryEnd).hours();

                            const disabledHours = Array.from({ length: 24 }, (_, i) => i)
                              .filter(
                                (hour) =>
                                  hour <= disabledBeforeOrderHour ||
                                  hour >= disabledAfterDeliveryHour,
                              )
                              .filter(
                                (hour) =>
                                  hour < disabledBeforeOrderHour || hour >= disabledAfterOrderHour,
                              );

                            return {
                              disabledHours: () => disabledHours,
                              //! a tester
                              disabledMinutes: (selectedHour) => {
                                if (moment(f.answer.date).isSame(moment(), "day")) {
                                  const currentHour = moment().hour();
                                  if (selectedHour < currentHour) {
                                    return [...range(0, 60)];
                                  } else if (selectedHour === currentHour) {
                                    return [...range(0, moment().minutes())];
                                  }
                                } else {
                                  if (selectedHour === moment(f.deliveryStart).hours()) {
                                    return [...range(0, moment(f.deliveryStart).minutes())];
                                  } else if (selectedHour === moment(f.deliveryEnd).hours()) {
                                    return [...range(moment(f.deliveryEnd).minutes() + 1, 60)];
                                  }
                                }
                                return [];
                              },
                            };
                          }}
                          placeholder={t("ticketingv2.Time")}
                          onChange={(e) => handleAnswer(e, index)}
                          format={"HH:mm"}
                          value={moment(f.answer)}
                        />
                      )}
                    </div>
                  </div>
                </Card>
              </>
            )}

            {f.type === "inputNumber" && (
              <div
                style={{
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  gap: 8,
                  alignItems: "start",
                }}
              >
                {!!f.answer?.quantity && f.price && (
                  <div style={{ position: "absolute", top: -28, right: 0, textAlign: "right" }}>
                    <Typography.Text strong style={{ fontSize: 16, color: colors.secondary_base }}>
                      {f.price?.toString().includes(".")
                        ? parseFloat(f.answer?.quantity * f.price).toFixed(2)
                        : f.answer?.quantity * f.price}
                      {` ${f.devise === "EUR" ? "€" : f.devise === "USD" ? "$" : "£"}`}
                    </Typography.Text>
                  </div>
                )}
                <Card style={{ width: "100%", padding: 0 }} bodyStyle={{ padding: "0 12px" }}>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography.Text strong style={{ fontSize: 14 }}>
                      {f.productName || f.description}
                    </Typography.Text>
                    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                      <Glyph
                        name="remove_circle"
                        style={{
                          fontSize: 16,
                          cursor: "pointer",
                          color: colors.grey_40,
                        }}
                        onClick={() => {
                          f.answer?.quantity > 0 &&
                            handleAnswer({ ...f.answer, quantity: f.answer?.quantity - 1 }, index);
                        }}
                      />
                      <InputNumber
                        min={0}
                        bordered={false}
                        controls={false}
                        defaultValue={0}
                        value={f.answer?.quantity}
                        readOnly={true}
                        onChange={(e) =>
                          e.target?.value && handleAnswer(e.target.value, index, "quantity")
                        }
                        style={{
                          width: `calc(22px + 1ch * ${
                            f.answer?.quantity?.toString()?.length || 1
                          })`,
                        }}
                      />
                      <Glyph
                        name="add_circle"
                        style={{
                          fontSize: 16,
                          cursor: "pointer",
                          color: colors.grey_40,
                        }}
                        onClick={() => {
                          handleAnswer(
                            {
                              ...f.answer,
                              quantity: !!f.answer?.quantity ? f.answer?.quantity + 1 : 1,
                            },
                            index,
                          );
                        }}
                      />
                    </div>
                  </div>
                </Card>
                <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                  {f.productName && f.description}
                </Typography.Text>
              </div>
            )}
          </div>
        ))
      ) : (
        <FormDefault
          newTicket={newTicket}
          setNewTicket={setNewTicket}
          attachements={attachements}
          setAttachements={setAttachements}
        />
      )}
    </div>
  );
};

export default CreateTicketForm;

/*
  <div
    style={{
      display: "flex",
      width: "100%",
      padding: 16,
      marginTop: 8,
      flexDirection: "column",
      alignItems: "center",
      borderRadius: symbols.base_shape.radius,
      border: `2px solid ${colors.grey_40}`,
      cursor: "pointer",
    }}
  >
    <Glyph name="attach_file" style={{ fontSize: 24, color: colors.grey_60 }} />
    <Typography.Text style={{ fontSize: 14, color: colors.pending_light }}>
      {t("ticketingv2.ImportFileHere")}
    </Typography.Text>
    <Typography.Text style={{ fontSize: 12, color: colors.grey_60 }}>
      {t("ticketingv2.FileSizeAndTypeLimitation")}
    </Typography.Text>
  </div>

  <div
    style={{
      display: "flex",
      width: "100%",
      padding: 16,
      marginTop: 8,
      flexDirection: "column",
      alignItems: "center",
      borderRadius: symbols.base_shape.radius,
      border: `2px solid ${colors.grey_40}`,
      cursor: "pointer",
    }}
  >
    <Glyph name="photo" style={{ fontSize: 24, color: colors.grey_60 }} />
    <Typography.Text style={{ fontSize: 14, color: colors.pending_light }}>
      {t("ticketingv2.ImportPhotoHere")}
    </Typography.Text>
    <Typography.Text style={{ fontSize: 12, color: colors.grey_60 }}>
      {t("ticketingv2.FileSizeAndTypeLimitation")}
    </Typography.Text>
  </div>
*/
