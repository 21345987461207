import { Typography } from "antd";
import useDesignTokens from "../../../../hook/useDesignTokens";
import SwitchIconTransport from "../../../../hook/SwitchIconTransport";
import { isNil } from "ramda";
import ConvertTime from "../../../../hook/ConvertTime";
import moment from "moment";
import styles from "../Transport.module.less";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const Details = () => {
  const { shadows, symbols, size } = useDesignTokens();
  const { t } = useTranslation();

  const infos = useSelector((state) => state.transportWS.detailsJourney);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          boxShadow: `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`,
          padding: "20px",
          justifyContent: "space-between",
        }}
      >
        <div style={{ width: "70%" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "wrap",
              marginBottom: "8px",
              alignItems: "center",
            }}
          >
            {infos?.sections?.map((section, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  flexFlow: "row",
                  alignItems: "center",
                  marginBottom: "8px",
                }}
              >
                {SwitchIconTransport(section?.mode)}{" "}
                {!isNil(section?.informations) && (
                  <Typography.Text
                    strong
                    style={{
                      backgroundColor: section?.informations?.color,
                      borderRadius: symbols?.base_shape?.radius,
                      textAlign: "center",
                      color: section?.informations?.textColor,
                      minWidth: "30px",
                      height: "30px",
                      lineHeight: "30px",
                      padding: "0 6px",
                      marginRight: "4px",
                    }}
                  >
                    {section?.informations?.code}
                  </Typography.Text>
                )}
              </div>
            ))}
          </div>
          <div style={{ display: "flex", flexFlow: "column" }}>
            <Typography.Text style={{ fontSize: size.s }}>
              {ConvertTime(infos?.durations?.walking)} {t("walking")}
            </Typography.Text>
            <Typography.Text>
              {t("departure_from")} {infos?.sections[0]?.from?.name}
            </Typography.Text>
          </div>
        </div>

        <div style={{ display: "flex", flexFlow: "column", textAlign: "right" }}>
          <Typography.Text strong style={{ fontSize: size.l }}>
            {ConvertTime(infos?.durations?.total)}
          </Typography.Text>
          <Typography.Text style={{ fontSize: size.s }}>
            {moment(infos?.departure).format("HH:mm")} - {moment(infos?.arrival).format("HH:mm")}
          </Typography.Text>
        </div>
      </div>

      <div style={{ padding: "20px", height: "fit-content", overflowY: "auto" }}>
        <div style={{ marginTop: "20px" }}>
          {infos?.sections
            ?.filter((s) => s?.type !== "waiting")
            ?.map((section, index) => (
              <div className={styles["step_detail"]}>
                <div
                  key={index}
                  style={{ display: "flex", flexFlow: "column", alignItems: "center" }}
                >
                  {SwitchIconTransport(section?.mode)}
                  {section?.mode === "walking" ? (
                    <div className={styles["direction_walking"]} style={{ margin: "8px 0" }}>
                      <div className={styles["div-point"]}></div>
                      <div className={styles["div-point"]}></div>
                      <div className={styles["div-point"]}></div>
                      <div className={styles["div-point"]}></div>
                    </div>
                  ) : (
                    <div className={styles["direction_transport"]} style={{ margin: "8px 0" }}>
                      <div className={styles["div-point"]}></div>
                      <div className={styles["div-line"]}></div>
                      <div className={styles["div-point"]}></div>
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    marginLeft: "16px",
                  }}
                >
                  {section?.mode === "walking" ? (
                    <Typography.Text style={{ marginBottom: "5px" }}>
                      {t("walk_to")}
                    </Typography.Text>
                  ) : (
                    <div>
                      <Typography.Text
                        strong
                        style={{
                          backgroundColor: section?.informations?.color,
                          borderRadius: symbols?.base_shape?.radius,
                          textAlign: "center",
                          color: section?.informations?.textColor,
                          minWidth: "30px",
                          height: "30px",
                          lineHeight: "30px",
                          padding: "0 6px",
                          marginRight: "4px",
                          marginBottom: "5px",
                          display: "inline-block",
                        }}
                      >
                        {section?.informations?.code}
                      </Typography.Text>
                    </div>
                  )}
                  {section?.mode === "walking" ? (
                    <Typography.Text strong style={{ marginBottom: "5px" }}>
                      {section?.to?.name}
                    </Typography.Text>
                  ) : (
                    <Typography.Text style={{ fontSize: size.s, marginBottom: "5px" }}>
                      {section?.informations?.stops} {t("stop")}
                    </Typography.Text>
                  )}

                  {section?.mode === "walking" ? (
                    <Typography.Text style={{ fontSize: size.s }}>
                      {ConvertTime(section?.duration)} {t("walking")}
                    </Typography.Text>
                  ) : (
                    <Typography.Text strong>{section?.to?.name}</Typography.Text>
                  )}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default Details;
