import { Card } from "antd";
import styles from "./ChipCard.module.less";

const ChipCard = ({ icon, children, ...rest }) => {
  return (
    <Card {...rest} className={styles["chip-card"]}>
      {icon}
      {children}
    </Card>
  );
};

export default ChipCard;
