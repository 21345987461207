import { useKeycloak } from "@react-keycloak/web";
import { Avatar, Button, Dropdown, Select, Typography } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom/cjs/react-router-dom.min";
import packageJson from "../../../../../package.json";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";

// Firebase

const { Option } = Select;

const Header = ({ allowReturn, isZone, setIsZone, selectedRoom }) => {
  const { t, i18n } = useTranslation();
  const { symbols, shadows } = useDesignTokens();
  const { keycloak } = useKeycloak();

  const userData = useSelector((state) => state.userWS.userData);
  const campus = useSelector((state) => state.clientsWS.campus);

  const popup = (
    <div
      style={{
        backgroundColor: "white",
        boxShadow: `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`,
        borderRadius: symbols?.base_shape?.radius,
        padding: "16px 16px 8px 16px",
        display: "flex",
        flexDirection: "column",
        gap: 16,
        width: "100%",
      }}
    >
      <div style={{ display: "flex", gap: 16 }}>
        <Avatar
          src={`${userData?.profile?.photoUrl}?access_token=${keycloak?.token}`}
          style={{
            height: "40px",
            minWidth: "40px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            fontSize: "20px",
          }}
        >
          {`${userData?.profile?.firstName} ${userData?.profile?.lastName}`
            .toUpperCase()
            .split(" ")
            .map((i) => i.charAt(0).toUpperCase())
            .join("")}
        </Avatar>
        <div style={{ display: "flex", flexFlow: "column", gap: 8, width: "100%" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ fontSize: "14px", fontWeight: "bold", wordBreak: "break-all" }}>
              {userData?.profile?.firstName} {userData?.profile?.lastName}
            </div>
            <div style={{ fontSize: "14px", wordBreak: "break-all" }}>
              {userData?.profile?.email}
            </div>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            {userData?.campus?.length > 1 && (
              <Select
                //className="basic-single"
                //classnameprefix="select"
                value={userData?.campus?.find((c) => c?.id === campus?.mapData?.id)?.title}
                isclearable="false"
                isrtl="false"
                issearchable="false"
                name="select-campus"
                size="medium"
                style={{ width: "100%" }}
              >
                {userData?.campus?.map((c, index) => (
                  <Option key={index}>
                    <a
                      href={`?siteId=${c.id}`}
                      style={{ width: "fit-content", height: "100%", display: "block" }}
                    >
                      {c?.title}
                    </a>
                  </Option>
                ))}
              </Select>
            )}
            <Select
              size={"medium"}
              style={{ width: "fit-content", textAlign: "center" }}
              onChange={(value) => i18n.changeLanguage(value)}
              defaultValue={i18n.language.split("-")[0]}
            >
              {userData?.locales?.map((language, index) => (
                <Option key={index} value={language?.title} style={{ textAlign: "center" }}>
                  {language?.icon}
                </Option>
              ))}
            </Select>
          </div>

          <Button ghost size="middle" onClick={() => keycloak.logout()}>
            <Glyph name="logout" style={{ verticalAlign: "-3px", marginRight: "8px" }} />
            {t("mobile.logout")}
          </Button>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
        }}
      >
        <Typography.Text style={{ fontSize: "10px" }}>v.{packageJson.version}</Typography.Text>
        <div>
          <Link
            style={{ textDecoration: "underline", marginRight: "16px", fontSize: "12px" }}
            to="/public/cgu"
            target="_blank"
          >
            {t("CGU")}
          </Link>
          <Link
            style={{ textDecoration: "underline", fontSize: "12px" }}
            to="/public/privacyPolicy"
            target="_blank"
          >
            {t("PrivacyPolicy")}
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <div
      style={{
        zIndex: "100",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 16,
      }}
    >
      {allowReturn ? (
        isZone ? (
          <div
            onClick={() => setIsZone(false)}
            style={{ display: "flex", gap: 8, alignItems: "center", marginLeft: -12 }}
          >
            <Glyph name="chevron_left" style={{ fontSize: "32px" }} />
            <Typography.Title level={4} style={{ margin: 0, lineHeight: "initial" }}>
              {selectedRoom.title}
            </Typography.Title>
          </div>
        ) : (
          <NavLink
            to="/"
            style={{ display: "flex", gap: 8, alignItems: "center", marginLeft: -12 }}
          >
            <Glyph name="chevron_left" style={{ fontSize: "32px" }} />
            <Typography.Title level={4} style={{ margin: 0, lineHeight: "initial" }}>
              {t("Home")}
            </Typography.Title>
          </NavLink>
        )
      ) : (
        <Typography.Title level={4} style={{ margin: 0, lineHeight: "initial" }}>
          {process.env.REACT_APP_ONGLET}
        </Typography.Title>
      )}

      <Dropdown
        overlay={popup}
        trigger={["click"]}
        overlayStyle={{ margin: 16, width: "calc(100% - 32px)" }}
      >
        <Avatar
          src={`${userData?.profile?.photoUrl}?access_token=${keycloak.token}`}
          style={{
            height: "40px",
            width: "40px",
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
          }}
        >
          {`${userData?.profile?.firstName} ${userData?.profile?.lastName}`
            .toUpperCase()
            .split(" ")
            .map((i) => i.charAt(0).toUpperCase())
            .join("")}
        </Avatar>
      </Dropdown>
    </div>
  );
};

export default Header;
