import { Card, Typography } from "antd";
import TicketCard from "./TicketCard";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getAllMyTickets } from "../../../../../services/redux/services/TicketingV2WS";
import useDesignTokens from "../../../../../hook/useDesignTokens";

const TicketColumn = ({ ticketStatus, selectedSite, filters, dir, section }) => {
  const { i18n } = useTranslation();
  const { colors } = useDesignTokens();
  const locale = i18n.language.split("-")[0];
  const dispatch = useDispatch();
  //const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(1);

  const myTickets = useSelector((state) => state.ticketingV2WS.allMyTickets);
  const ticketColumn = myTickets?.find((column) => column.statusId === ticketStatus.id);

  useEffect(() => {
    const fetchData = async () => {
      //setIsLoading(true);
      try {
        await dispatch(
          getAllMyTickets({
            siteId: selectedSite,
            dir,
            locale,
            page,
            size: 7,
            status: ticketStatus.id,
            type: filters.type,
            category: filters.cat,
            subCategory: filters.subCategory,
            agent: null,
            floor: filters.floor,
          }),
        );
        //setIsLoading(false);
      } catch (error) {
        logger.error("Error fetching data:", error);
      }
    };

    logger.log("fetching page", page);
    //if (!ticketColumn || !!ticketColumn?.nextPage) {
    fetchData();
    //}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, filters, locale, page, selectedSite, ticketStatus, dir]);

  useEffect(() => {
    setPage(1);
  }, [filters, locale, selectedSite, ticketStatus, dir]);

  useEffect(() => {
    const container = document
      .getElementById(`ticket_column_${ticketStatus.id}`)
      .querySelector(".ant-card-body");

    const handleScroll = () => {
      if (container.scrollHeight - container.scrollTop === container.clientHeight) {
        setPage(ticketColumn.nextPage);
      }
    };
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container?.removeEventListener("scroll", handleScroll);
      }
    };
  }, [ticketColumn, ticketStatus.id]);

  return (
    <Card
      style={{ width: "320px", minWidth: "320px", minHeight: "100%", height: "100%" }}
      id={`ticket_column_${ticketStatus.id}`}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 8,
        }}
      >
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: ticketStatus.color
                ? ticketStatus.color[0] === "#"
                  ? ticketStatus.color
                  : colors[ticketStatus.color]
                : colors.grey_40,
              borderRadius: "100%",
              overflow: "hidden",
              marginBottom: "4px",
            }}
          />
          <Typography.Text style={{ fontSize: "16px", margin: "0" }}>
            {ticketStatus.label?.toUpperCase()}
          </Typography.Text>
        </div>
        <Typography.Text style={{ margin: "0" }}>{ticketColumn?.totalItems || 0}</Typography.Text>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        {!!ticketColumn?.items?.length &&
          ticketColumn.items.map(
            (t) =>
              t.status?.id === ticketStatus.id && (
                <div key={t.id}>
                  <TicketCard data={t} selectedSite={selectedSite} section={section} />
                </div>
              ),
          )}
      </div>
    </Card>
  );
};

export default TicketColumn;
