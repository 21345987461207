import { Card, Tooltip } from "antd";
import styles from "./Helper.module.less";
import Glyph from "../Glyph/Glyph";

const Helper = ({ title, ...rest }) => {
  return (
    <Tooltip
      trigger={"click"}
      overlayClassName={styles["helper-tooltip"]}
      title={title}
      placement="bottom"
    >
      <Card {...rest} className={styles["helper-card"]}>
        <div>
          <Glyph name={"help"} />
        </div>
      </Card>
    </Tooltip>
  );
};

export default Helper;
