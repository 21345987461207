import { Typography } from "antd";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import Glyph from "../../../../Common/Glyph/Glyph";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useTranslation } from "react-i18next";
import moment from "moment";

const TicketCard = ({ data, selectedSite, key, section }) => {
  const { colors } = useDesignTokens();
  const { i18n, t } = useTranslation();

  const locale = i18n.language.split("-")[0];

  return (
    <Link to={`/${section}/ticket/${selectedSite}/${data.id}`} key={key}>
      <div
        style={{
          backgroundColor: colors.light_background,
          padding: "16px",
          borderRadius: "12px",
          display: "flex",
          flexDirection: "column",
          gap: "8x",
          overflowY: "auto",
        }}
        className="ticket_card"
      >
        <div
          style={{
            display: "flex",
            gap: 8,
            justifyContent: "space-between",
            alignItems: "baseline",
          }}
        >
          <div style={{ display: "flex", gap: 8, alignItems: "baseline", width: 172 }}>
            <Glyph name="receipt" style={{ fontSize: 16, color: colors.secondary_base }} />
            <Typography.Title level={5}>{data.title}</Typography.Title>
          </div>
          <Typography.Text style={{ width: 70, textAlign: "right", fontSize: 12 }}>
            {moment(data.createdAt).format(locale === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY")}
          </Typography.Text>
        </div>
        <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
          <div
            style={{
              width: "10px",
              height: "10px",
              backgroundColor: data.status?.color
                ? data.status?.color[0] === "#"
                  ? data.status?.color
                  : colors[data.status?.color]
                : colors.grey_40,
              borderRadius: "100%",
              overflow: "hidden",
              marginBottom: "4px",
            }}
          />
          <Typography.Text
            strong
            style={{
              fontSize: "14px",
              color: data.status?.color
                ? data.status?.color[0] === "#"
                  ? data.status?.color
                  : colors[data.status?.color]
                : colors.grey_40,
            }}
          >
            {data.status?.label}
          </Typography.Text>
        </div>
        <div
          style={{ width: "100%", height: "1px", backgroundColor: colors.grey_40, margin: "8px 0" }}
        />
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          <Glyph name="format_list_bulleted" style={{ fontSize: 16, color: "black" }} />
          <Typography.Text strong>{data.type?.label}</Typography.Text>
        </div>
        <Typography.Text style={{ hyphens: "auto" }}>
          {t("ticketingv2.TicketId_")}
          {data.id}
        </Typography.Text>
      </div>
    </Link>
  );
};

export default TicketCard;
