import { useKeycloak } from "@react-keycloak/web";
import { Card, Image, Space, Typography } from "antd";
import moment from "moment";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";
import { getNewsHome } from "../../../services/redux/services/NewsWS";
import { isNil } from "ramda";

// import component
import Glyph from "../../Common/Glyph/Glyph";
import Tile from "../../Common/Tile/Tile";
import styles from "./News.module.less";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { getAnalytics, logEvent } from "firebase/analytics";
import app from "../../../services/api/Firebase/Firebase";

const analytics = getAnalytics(app);

const Widget = () => {
  const { t, i18n } = useTranslation();
  const { colors, size } = useDesignTokens();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const news = useSelector((state) => state.newsWS.newsHome);
  const campus = useSelector((state) => state.clientsWS.campus);
  const userData = useSelector((state) => state.userWS.userData);

  useEffect(() => {
    dispatch(
      getNewsHome({
        locale: i18n.language.split("-")[0],
        siteId: campus?.mapData?.id,
      }),
    );
  }, [campus?.mapData?.id, dispatch, i18n.language]);

  return (
    <Card style={{ overflow: "auto" }}>
      <Typography.Title level={5} style={{ textTransform: "uppercase", color: colors.grey_40 }}>
        <Glyph
          name="article"
          style={{
            verticalAlign: "-5px",
            fontSize: "30px",
            marginRight: "8px",
            color: colors.interactive_03,
          }}
        />
        {t("long_news", { ns: "csv" })}
      </Typography.Title>
      <div>
        <Space style={{ width: "100%" }} direction="vertical" size="small">
          {news
            ?.filter((n) => !userData?.views?.find((u) => u?.id === n?.viewId)?.cold)
            ?.map((n, index) => (
              <Tile
                key={index}
                href={`news/${n?.viewId}/${n?.topics[0]?.id}/${n?.id}`}
                style={{
                  minHeight: "120px",
                  padding: "8px",
                  justifyContent: "flex-start",
                }}
              >
                {!isNil(n?.header) && (
                  <div className={styles["pictures"]}>
                    <Image
                      preview={false}
                      src={`${n?.header?.url}?access_token=${keycloak.token}`}
                    />
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    marginLeft: "16px",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography.Text type="secondary" style={{ fontSize: size.s }}>
                      <Glyph name="schedule" style={{ marginRight: "8px" }} />
                      {moment(n?.publishedAt).format("L")}
                    </Typography.Text>
                    <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
                      {n?.authorizeComment && (
                        <>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: size.s, marginRight: "8px" }}
                          >
                            <Glyph name="comment" style={{ marginRight: "4px" }} />
                            {n?.nbComments}
                          </Typography.Text>
                          <Typography.Text
                            type="secondary"
                            style={{ fontSize: size.s, marginRight: "8px" }}
                          >
                            {n?.isLiked ? (
                              <Glyph
                                name="favorite"
                                style={{ marginRight: "4px", color: colors?.error_light }}
                              />
                            ) : (
                              <Glyph name="favorite_border" style={{ marginRight: "4px" }} />
                            )}

                            {n?.nbLikes}
                          </Typography.Text>
                          <Typography.Text type="secondary" style={{ fontSize: size.s }}>
                            <Glyph name="bar_chart" style={{ marginRight: "4px" }} />
                            {n?.nbViews}
                          </Typography.Text>
                        </>
                      )}
                    </div>
                  </div>
                  <Typography.Text strong>
                    {n?.title}{" "}
                    <NavLink
                      to={{ pathname: `news/${n?.viewId}/${n?.topics[0]?.id}/${n?.id}` }}
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        logEvent(analytics, `news_select_details`, {
                          from: "tile",
                          news_name: n?.title,
                        });
                      }}
                    >
                      {t("news.read.article")}
                    </NavLink>
                  </Typography.Text>
                  <div style={{ display: "flex", flexFlow: "row" }}>
                    {n?.topics.map((t, index) => (
                      <Typography.Text key={index} type="secondary" style={{ marginRight: "8px" }}>
                        {t.label}
                      </Typography.Text>
                    ))}
                  </div>
                </div>
              </Tile>
            ))}
        </Space>
      </div>
    </Card>
  );
};

export default Widget;
