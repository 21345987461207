import React from "react";
import { useChannelSettingsContext } from "@sendbird/uikit-react/ChannelSettings/context";
import Glyph from "../../Common/Glyph/Glyph";
import CustomMemberList from "./CustomMemberList";
import { useTranslation } from "react-i18next";
//import ChannelProfile from "@sendbird/uikit-react/ChannelSettings/components/ChannelProfile";
import CustomChannelProfile from "./CustomChannelProfile";
//import UserPanel from "@sendbird/uikit-react/ChannelSettings/components/UserPanel";

function CustomChannelSettings({
  setShowSettings,
  setCurrentChannel,
  setShowLeaveChannelModal,
  setQuery,
}) {
  const context = useChannelSettingsContext();
  const { t } = useTranslation();

  return (
    <>
      <div className="sendbird-channel-settings__header">
        <span className="sendbird-label sendbird-label--h-2 sendbird-label--color-onbackground-1">
          {t("MODAL__CHANNEL_INFORMATION__TITLE")}
        </span>
        <div className="sendbird-channel-settings__header-icon">
          <button
            className=" sendbird-iconbutton "
            type="button"
            style={{ height: "32px", width: "32px" }}
          >
            <span className="sendbird-iconbutton__inner" onClick={() => setShowSettings(false)}>
              <div
                className="sendbird-channel-settings__close-icon sendbird-icon sendbird-icon-close "
                style={{ width: "22px", minWidth: "22px", height: "22px", minHeight: "22px" }}
              >
                <Glyph name="close" />
              </div>
            </span>
          </button>
        </div>
      </div>

      {/* uikit : <ChannelProfile /> */}
      <CustomChannelProfile />

      {/* uikit : <UserPanel /> */}
      <CustomMemberList
        currentChannel={context.channel}
        setCurrentChannel={setCurrentChannel}
        setQuery={setQuery}
      />

      <div
        className="sendbird-channel-settings__panel-item sendbird-channel-settings__leave-channel"
        onClick={() => setShowLeaveChannelModal(true)}
      >
        <div className="sendbird-channel-settings__panel-icon-left sendbird-channel-settings__panel-icon__leave sendbird-icon sendbird-icon-leave sendbird-icon-color--error">
          <Glyph name="logout" style={{ color: "red" }} />
        </div>
        <span className="sendbird-label sendbird-label--subtitle-1 sendbird-label--color-onbackground-1">
          {t("CHANNEL_PREVIEW_MOBILE_LEAVE")}
        </span>
      </div>
    </>
  );
}

export default CustomChannelSettings;
