import { Input, Space, Typography } from "antd";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { setFilter } from "../../../../services/redux/services/FastbookingWS";
import useMap from "../../../Common/Map/useMap";
import Glyph from "../../../Common/Glyph/Glyph";
import { TitleSource } from "../../../Common/Teleporters/Title";
import Tile from "../../../Common/Tile/Tile";
// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const SearchRoom = ({ setSelectedRoom, selectedRoom, setIsZone }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const { selectedOnMap, centerOnPlaceId } = useMap();

  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const allRoomFastbooking = useSelector((state) => state.fastbookingWS.allRoomFastbooking);

  const [searchRoom, setSearch] = useState("");

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
    if (evt.target.value.length === 1) {
      logEvent(analytics, "smart_control_search_poi");
    }
  };

  const searchResult = searchRoom
    ? campus?.mapData?.resources
        .filter((r) => !!r.features?.comfort)
        .filter((value) => {
          if (searchRoom === "") {
            return value;
          } else {
            return value.title.toLowerCase().includes(searchRoom.toLowerCase());
          }
        })
        .sort((a, b) => (a.title > b.title ? 1 : -1))
    : null;

  // Filtre par défaut
  useEffect(() => {
    if (isEmpty(filters)) {
      const floor = allRoomFastbooking?.rooms?.reduce(
        (acc, r) =>
          !acc
            ? campus?.mapData?.floors.find((f) => f.reference === r.resources.floorReference)
            : acc,
        null,
      );
      dispatch(
        setFilter({
          floor: floor?.id,
        }),
      );
    }
  }, [allRoomFastbooking, campus?.mapData?.floors, dispatch, filters]);

  const goRoom = (value) => {
    centerOnPlaceId(value?.map?.findAndOrder?.placeId);
    logEvent(analytics, "smart_control_select_poi_from_list");
    dispatch(
      setFilter({
        ...filters,
        floor: campus.mapData.floors.find((floor) => floor.reference === value.floorReference)?.id,
      }),
    );
  };

  useEffect(() => {
    if (selectedOnMap) history.push(`/smartControl/${selectedOnMap.id}`);
  }, [history, selectedOnMap]);

  const Row = ({ _, index }) => (
    <Tile
      key={index}
      href={
        process.env.REACT_APP_ID_MAP !== "-" ? `smartControl/${searchResult[index].id}` : undefined
      }
      onClick={() => {
        if (process.env.REACT_APP_ID_MAP !== "-") {
          goRoom(searchResult[index]);
        } else {
          setSelectedRoom(searchResult[index]);
          setIsZone(false);
        }
      }}
      style={{
        width: "100%",
        marginBottom: 8,
        marginLeft: 0,
      }}
    >
      <Typography.Text
        strong
        style={{
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        }}
      >
        {searchResult[index].title}
      </Typography.Text>
      <span
        style={{
          width: "fit-content",
          whiteSpace: "nowrap",
        }}
      >
        {t("Stage")}{" "}
        {
          campus?.mapData?.floors.find(
            (floor) => floor.reference === searchResult[index].floorReference,
          )?.displayName
        }
        <Glyph name="navigate_next" />
      </span>
    </Tile>
  );

  return (
    <>
      <TitleSource>{t("smart_control", { ns: "csv" })}</TitleSource>

      <Space style={{ width: "100%", padding: 0 }} direction="vertical" size="middle">
        <Input
          onChange={handleSearch}
          value={searchRoom}
          placeholder={t("SearchSpace")}
          prefix={<Glyph name="search" />}
          allowClear={true}
        />

        {!searchResult ? (
          <Typography.Text type="secondary">{t("UseCard")}</Typography.Text>
        ) : (
          <>
            <Typography.Title level={5} style={{ margin: 0 }}>
              {t("ResultSearch")}
            </Typography.Title>

            {searchResult && (
              <div
                style={{
                  height: "fit-content",
                  paddingRight: 8,
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                {!searchResult?.length ? (
                  <Typography.Text type="secondary">{t("NoResult")}</Typography.Text>
                ) : (
                  searchResult.map((r, index) => <Row key={r.title} index={index} />)
                )}
              </div>
            )}
          </>
        )}
      </Space>
    </>
  );
};

export default SearchRoom;
