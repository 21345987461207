/* eslint-disable jsx-a11y/iframe-has-title */
import { Button, Checkbox, Space, Typography } from "antd";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { acceptCgu } from "../../../services/redux/services/ContractPolicyWS";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getUserData } from "../../../services/redux/services/UserWS";
import { useKeycloak } from "@react-keycloak/web";
import Tile from "../../Common/Tile/Tile";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../hook/useDesignTokens";

const Cgu = ({ isModalOpen, onCancel, setAccept }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { size } = useDesignTokens();

  const [readCgu, setReadCgu] = useState(false);
  const [readPolicy, setReadPolicy] = useState(false);

  let siteId = localStorage.getItem("siteId");

  return (
    <Modal
      closable={false}
      width={600}
      open={isModalOpen}
      style={{ height: "700px" }}
      bodyStyle={{ padding: "35px 10px" }}
      title={
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography.Title level={4}>{t("validCGU")}</Typography.Title>
        </div>
      }
      footer={[
        <Button ghost style={{ width: "170px" }} onClick={() => keycloak.logout()}>
          {t("notAccept")}
        </Button>,
        <Button
          type="primary"
          style={{ width: "170px" }}
          disabled={readCgu === true && readPolicy === true ? false : true}
          onClick={() =>
            dispatch(acceptCgu())
              .unwrap()
              .then(() => {
                onCancel();
                dispatch(
                  getUserData({
                    locale: i18n.language.split("-")[0],
                    siteId: siteId ? siteId : "",
                  }),
                );
              })
          }
        >
          {t("accept")}
        </Button>,
      ]}
    >
      <div style={{ padding: "8px" }}>
        <Typography.Text strong>{t("pleaseRead")}</Typography.Text>
        <Space style={{ width: "100%", marginTop: "8px" }} direction="vertical" size="small">
          <Tile style={{ cursor: "default", width: "100%", margin: "0" }}>
            <Typography.Text>
              {t("readthe")}{" "}
              <Link
                target="_blank"
                style={{ textDecoration: "underline", marginRight: "16px", fontSize: size.s }}
                to="/public/cgu"
                onClick={() => setReadCgu(true)}
              >
                {t("CGU")}
              </Link>
            </Typography.Text>
            <Checkbox checked={readCgu} disabled style={{ cursor: "default" }} />
          </Tile>

          <Tile style={{ cursor: "default", width: "100%", margin: "0" }}>
            <Typography.Text>
              {t("readthe2")}{" "}
              <Link
                target="_blank"
                style={{ textDecoration: "underline", marginRight: "16px", fontSize: size.s }}
                to="/public/privacyPolicy"
                onClick={() => setReadPolicy(true)}
              >
                {t("PrivacyPolicy")}
              </Link>
            </Typography.Text>
            <Checkbox disabled checked={readPolicy} />
          </Tile>
        </Space>
      </div>
    </Modal>
  );
};

export default Cgu;
