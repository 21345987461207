import { Button, Modal, Typography } from "antd";
import WarningMessage from "../WarningMessage";
import { useDispatch } from "react-redux";
import { updateTicketV2 } from "../../../../../services/redux/services/TicketingV2WS";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useTranslation } from "react-i18next";

const DeleteTicketModal = ({
  ticketData,
  setShowDeleteTicketModal,
  showDeleteTicketModal,
  section,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const deleteFunction = async () => {
    try {
      const del = await dispatch(
        updateTicketV2({
          siteId: ticketData.siteId,
          ticketId: ticketData.id,
          data: {
            close: true,
            //statusId: allStatus?.find((s) => s.label === "Annulé" || s.label === "Canceled")?.id,
          },
        }),
      );

      if (del.meta.requestStatus === "fulfilled") {
        setShowDeleteTicketModal(false);
        history.push(`/${section}/myTickets`);
      }
    } catch (error) {
      logger.error("Error during deletion:", error);
    }
  };

  return (
    <Modal
      open={showDeleteTicketModal}
      width={620}
      onCancel={() => setShowDeleteTicketModal(false)}
      title={null}
      footer={null}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: "16px",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t("ticketingv2.SetTicketStatusToCancelled")}
        </Typography.Text>
        {/* <Typography.Text style={{ fontSize: 14 }}>
          Passer le statut du ticket à "Annulé" ? Cette action empêchera toute modification
        </Typography.Text> */}

        <WarningMessage caption={t("ticketingv2.WarningIrreversibleAction")} />

        {/* buttons */}
        <div style={{ display: "flex", gap: 8, justifyContent: "center" }}>
          <Button
            type="tertiary"
            onClick={() => setShowDeleteTicketModal(false)}
            style={{ width: 200 }}
          >
            {t("ticketingv2.Cancel")}
          </Button>
          <Button type="primary" onClick={deleteFunction} style={{ width: 200 }}>
            {t("ticketingv2.Confirm")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteTicketModal;
