import React from "react";
import Glyph from "../../../../Common/Glyph/Glyph";
import { Form, Input, Select, Switch, TimePicker, Typography } from "antd";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import FormHeader from "./FormHeader";
import { useTranslation } from "react-i18next";
import moment from "moment";

const FormPart = ({ tempForm, setTempForm, lang, position, type }) => {
  const { colors, symbols } = useDesignTokens();
  const { t } = useTranslation();

  const handleChangeCaption = (e) => {
    setTempForm((prevTempForm) => {
      const updatedTempForm = [...prevTempForm];

      updatedTempForm[position] = {
        ...updatedTempForm[position],
        caption: { ...updatedTempForm[position].caption, [lang]: e.target?.value || "" },
      };
      return updatedTempForm;
    });
  };

  const handleSwitchChange = (v, key) => {
    setTempForm((prevTempForm) => {
      const updatedTempForm = [...prevTempForm];

      updatedTempForm[position] = {
        ...updatedTempForm[position],
        [key]: v,
      };
      return updatedTempForm;
    });
  };

  const handleChangeInput = (index, value) => {
    setTempForm((prevTempForm) => {
      const updatedTempForm = [...prevTempForm];

      updatedTempForm[position] = {
        ...updatedTempForm[position],
        value: updatedTempForm[position].value.map((item, i) =>
          i === index ? { ...item, title: { ...item.title, [lang]: value } } : item,
        ),
      };

      return updatedTempForm;
    });
  };

  const addEmptyInput = () => {
    setTempForm((prevTempForm) => {
      const updatedTempForm = prevTempForm?.map((item, index) => {
        if (index === position && item.type === type) {
          return {
            ...item,
            value: [
              ...(item.value || []),
              {
                title: {
                  [lang]: "",
                },
                value: `custom_value_${(item.value || []).length + 1}`,
              },
            ],
          };
        }
        return item;
      });

      return updatedTempForm;
    });
  };

  const deleteInput = (index) => {
    setTempForm((prevTempForm) => {
      const updatedTempForm = [...prevTempForm];
      updatedTempForm[position]?.value?.splice(index, 1);
      return updatedTempForm;
    });
  };

  const devisesOptions = [
    {
      value: "EUR",
      label: "Euros",
    },
    {
      value: "USD",
      label: "Dollars",
    },
    {
      value: "GBP",
      label: t("ticketingv2.Gbp"),
    },
  ];

  const countRulesOptions = [
    {
      value: true,
      label: t("ticketingv2.are_"),
    },
    {
      value: false,
      label: t("ticketingv2.areNot_"),
    },
  ];

  const cancelRulesOptions = [
    {
      value: "before",
      label: t("ticketingv2.beforeDelivery"),
    },
    {
      value: "after",
      label: t("ticketingv2.afterOrdering"),
    },
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        padding: 8,
        gap: 8,
        alignItems: "start",
        width: "100%",
      }}
    >
      <div style={{ width: "100%" }}>
        <FormHeader tempForm={tempForm} setTempForm={setTempForm} lang={lang} position={position} />
      </div>
      <div style={{ display: "flex", flexDirection: "column", paddingLeft: 26, width: "100%" }}>
        <Input
          bordered={false}
          style={{
            color: colors.grey_60,
            fontSize: 14,
            borderRadius: 0,
            width: "100%",
            padding: 0,
            wordBreak: "break-word",
          }}
          value={
            tempForm[position]?.caption && tempForm[position]?.caption[lang]
              ? tempForm[position].caption[lang]
              : ""
          }
          onChange={(e) => handleChangeCaption(e)}
          placeholder={t("ticketingv2.FormPartCaption")}
        />
        {(type === "files" || type === "pictures") && (
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: 16,
              marginTop: 8,
              flexDirection: "column",
              alignItems: "center",
              borderRadius: symbols.base_shape.radius,
              border: `2px solid ${colors.grey_40}`,
            }}
          >
            <Glyph
              name={type === "files" ? "attach_file" : "photo"}
              style={{ fontSize: 24, color: colors.grey_60 }}
            />
            <Typography.Text style={{ fontSize: 14, color: colors.pending_light }}>
              {type === "files"
                ? t("ticketingv2.UserImportFileHere")
                : t("ticketingv2.UserImportPhotoHere")}
            </Typography.Text>
            <Typography.Text style={{ fontSize: 12, color: colors.grey_60 }}>
              {t("ticketingv2.FileSizeAndTypeLimitation")}
            </Typography.Text>
          </div>
        )}
        {(type === "textarea" || type === "text") && (
          <>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: type === "textarea" ? 140 : 42,
                padding: 8,
                gap: 8,
                marginTop: 8,
                alignItems: "start",
                borderRadius: symbols.base_shape.radius,
                border: `2px solid ${colors.grey_40}`,
              }}
            >
              <Glyph name="edit" style={{ fontSize: 20, color: colors.grey_60 }} />
              <Typography.Text
                style={{ fontSize: 14, color: colors.pending_light, cursor: "default" }}
              >
                {t("ticketingv2.UserTypeAnswer")}
              </Typography.Text>
            </div>
            {type === "text" && (
              <div style={{ display: "flex", gap: 8, alignItems: "center", margin: "8px 0" }}>
                <Switch
                  size="small"
                  checked={tempForm[position].isNumber}
                  onChange={(v) => handleSwitchChange(v, "isNumber")}
                />
                <Typography.Text style={{ fontSize: 14 }}>
                  {t("ticketingv2.IsANumber")}
                </Typography.Text>
              </div>
            )}
          </>
        )}
        {(type === "radio" || type === "checkbox") && (
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              alignItems: "start",
            }}
          >
            {!!tempForm[position]?.value?.length &&
              tempForm[position].value.map((option, index) => (
                <div
                  style={{ display: "flex", alignItems: "center", gap: 8, width: "fit-content" }}
                  key={index}
                >
                  {type === "radio" ? (
                    <Glyph name="radio_button_unchecked" style={{ fontSize: 12 }} />
                  ) : (
                    <Glyph name="check_box_outline_blank" style={{ fontSize: 12 }} />
                  )}
                  <Input
                    bordered={false}
                    suffix={
                      <Glyph
                        name="close"
                        style={{ fontSize: 12, color: colors.grey_40, cursor: "pointer" }}
                        onClick={() => deleteInput(index)}
                      />
                    }
                    style={{
                      borderBottom: `1px solid ${colors.grey_40}`,
                      fontSize: 14,
                      borderRadius: 0,
                      width: "100%",
                    }}
                    value={option.title[lang]}
                    onChange={(e) => handleChangeInput(index, e.target.value)}
                    placeholder={`Option ${index + 1}`}
                  />
                </div>
              ))}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 8,
                width: "100%",
                cursor: "pointer",
                paddingTop: 8,
              }}
              onClick={() => addEmptyInput()}
            >
              <Glyph name="add" style={{ fontSize: 14 }} />
              <Typography.Text style={{ fontSize: 14 }}>
                {t("ticketingv2.AddOption")}
              </Typography.Text>
            </div>
          </div>
        )}
        {(type === "datepicker" || type === "delivery") && (
          <>
            {type === "delivery" && (
              <div style={{ display: "flex", flexDirection: "column", gap: 8, width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    padding: 16,
                    marginTop: 8,
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: symbols.base_shape.radius,
                    border: `2px solid ${colors.grey_40}`,
                  }}
                >
                  <Form
                    name="control-ref"
                    style={{
                      display: "flex",
                      marginTop: 8,
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography.Text style={{ width: "fit-content" }}>
                      {t("ticketingv2.OrderMustBe_")}
                    </Typography.Text>
                    <Input
                      bordered={false}
                      style={{
                        borderBottom: `1px solid ${colors.grey_40}`,
                        fontSize: 14,
                        borderRadius: 0,
                        width: 30,
                        padding: 1,
                        textAlign: "center",
                      }}
                      value={
                        isNaN(tempForm[position].orderBefore) ? "" : tempForm[position].orderBefore
                      }
                      onChange={(e) => {
                        const updatedTempForm = [...tempForm];
                        updatedTempForm[position] = {
                          ...updatedTempForm[position],
                          orderBefore: parseInt(e.target.value),
                        };
                        setTempForm(updatedTempForm);
                      }}
                    />
                    <Typography.Text>{t("ticketingv2._hoursBeforeDelivery")}</Typography.Text>
                  </Form>
                  <Form
                    name="control-ref"
                    style={{
                      display: "flex",
                      marginTop: 8,
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography.Text style={{ width: "fit-content" }}>
                      {t("ticketingv2.OrderCanBeCanceled_")}
                    </Typography.Text>
                    <Input
                      bordered={false}
                      style={{
                        borderBottom: `1px solid ${colors.grey_40}`,
                        fontSize: 14,
                        borderRadius: 0,
                        width: 30,
                        padding: 1,
                        textAlign: "center",
                      }}
                      value={
                        isNaN(tempForm[position].cancelBefore)
                          ? ""
                          : tempForm[position].cancelBefore
                      }
                      onChange={(e) => {
                        const updatedTempForm = [...tempForm];
                        updatedTempForm[position] = {
                          ...updatedTempForm[position],
                          cancelBefore: parseInt(e.target.value),
                        };
                        setTempForm(updatedTempForm);
                      }}
                    />
                    <Typography.Text style={{ width: "fit-content" }}>
                      {t("ticketingv2._hours_")}
                    </Typography.Text>
                    <Select
                      style={{
                        width: 160,
                        boxShadow: "none",
                        borderBottom: `1px solid ${colors.grey_40}`,
                        borderRadius: 0,
                        padding: 0,
                      }}
                      options={cancelRulesOptions}
                      size={"small"}
                      bordered={false}
                      onChange={(type) => {
                        const updatedTempForm = [...tempForm];
                        updatedTempForm[position] = {
                          ...updatedTempForm[position],
                          cancelRules: type,
                        };
                        setTempForm(updatedTempForm);
                      }}
                      value={tempForm[position].cancelRules}
                    />
                  </Form>
                  <Form
                    name="control-ref"
                    style={{
                      display: "flex",
                      marginTop: 8,
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography.Text style={{ width: "fit-content" }}>
                      {t("ticketingv2.WeekendsAndHolidays_")}
                    </Typography.Text>

                    <Select
                      style={{
                        width: 120,
                        boxShadow: "none",
                        borderBottom: `1px solid ${colors.grey_40}`,
                        borderRadius: 0,
                        padding: 0,
                      }}
                      options={countRulesOptions}
                      size={"small"}
                      bordered={false}
                      onChange={(type) => {
                        const updatedTempForm = [...tempForm];
                        updatedTempForm[position] = {
                          ...updatedTempForm[position],
                          countRules: type,
                        };
                        setTempForm(updatedTempForm);
                      }}
                      placeholder={t("ticketingv2.AllTypes")}
                      value={tempForm[position].countRules}
                    />
                    <Typography.Text style={{ width: "fit-content" }}>
                      {t("ticketingv2._countInDelays")}
                    </Typography.Text>
                  </Form>
                  <Form
                    name="control-ref"
                    style={{
                      display: "flex",
                      marginTop: 8,
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "center",
                      flexWrap: "wrap",
                    }}
                  >
                    <Typography.Text style={{ width: "fit-content" }}>
                      {t("ticketingv2.DeliveriesArePossibleFrom_")}
                    </Typography.Text>

                    <div style={{ borderBottom: `1px solid ${colors.grey_40}` }}>
                      <TimePicker
                        size="small"
                        bordered={false}
                        suffixIcon={null}
                        format={"HH:mm"}
                        showNow={false}
                        allowClear={false}
                        minuteStep={15}
                        style={{
                          width: "4.5ch",
                          padding: 0,
                          backgroundColor: "white",
                        }}
                        onChange={(e) => {
                          const updatedTempForm = [...tempForm];
                          updatedTempForm[position] = {
                            ...updatedTempForm[position],
                            deliveryStart: e.format("HH:mm:ss").toString(),
                          };
                          setTempForm(updatedTempForm);
                        }}
                        value={
                          tempForm[position]?.deliveryStart
                            ? moment(tempForm[position]?.deliveryStart, "HH:mm")
                            : moment("00:00", "HH:mm")
                        }
                      />
                    </div>
                    <Typography.Text style={{ width: "fit-content" }}>
                      {t("ticketingv2._to_")}
                    </Typography.Text>
                    <div style={{ borderBottom: `1px solid ${colors.grey_40}` }}>
                      <TimePicker
                        size="small"
                        bordered={false}
                        suffixIcon={null}
                        format={"HH:mm"}
                        showNow={false}
                        allowClear={false}
                        minuteStep={15}
                        style={{
                          width: "4.5ch",
                          padding: 0,
                          backgroundColor: "white",
                        }}
                        onChange={(e) => {
                          const updatedTempForm = [...tempForm];
                          updatedTempForm[position] = {
                            ...updatedTempForm[position],
                            deliveryEnd: e.format("HH:mm:ss").toString(),
                          };
                          setTempForm(updatedTempForm);
                        }}
                        value={
                          tempForm[position]?.deliveryEnd
                            ? moment(tempForm[position]?.deliveryEnd, "HH:mm")
                            : moment("00:00", "HH:mm")
                        }
                      />
                    </div>
                  </Form>
                </div>
              </div>
            )}

            <div style={{ display: "flex", gap: 8, alignItems: "center", marginTop: 20 }}>
              <Switch
                size="small"
                checked={tempForm[position].hasTime}
                onChange={(v) => handleSwitchChange(v, "hasTime")}
              />
              <Typography.Text style={{ fontSize: 14 }}>{t("ticketingv2.AskTime")}</Typography.Text>
            </div>
          </>
        )}
        {type === "inputNumber" && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 8,
              alignItems: "start",
              margin: "8px 0",
            }}
          >
            <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
              <Switch
                size="small"
                checked={tempForm[position].isProduct}
                onChange={(v) => handleSwitchChange(v, "isProduct")}
              />
              <Typography.Text style={{ fontSize: 14 }}>
                {t("ticketingv2.IsAProduct")}
              </Typography.Text>
            </div>
            {tempForm[position].isProduct ? (
              <Form
                name="control-ref"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 16,
                  marginTop: 8,
                }}
              >
                <div style={{ display: "flex", gap: 16, width: "100%" }}>
                  <Form.Item name="type" style={{ width: "100%", marginBottom: 0 }}>
                    <>
                      <Typography.Text>{t("ticketingv2.ProductKey")}</Typography.Text>
                      <Input
                        allowClear
                        style={{ marginTop: 5, border: `2px solid ${colors.grey_40}` }}
                        onChange={(e) => {
                          const updatedTempForm = [...tempForm];
                          updatedTempForm[position] = {
                            ...updatedTempForm[position],
                            productKey: e.target.value,
                          };
                          setTempForm(updatedTempForm);
                        }}
                        placeholder={t("ticketingv2.AddProductCode")}
                        value={tempForm[position].productKey}
                        prefix={
                          <Glyph name="edit" style={{ fontSize: 20, color: colors.grey_40 }} />
                        }
                      />
                    </>
                  </Form.Item>
                  <Form.Item name="type" style={{ width: "100%", marginBottom: 0 }}>
                    <>
                      <Typography.Text>{t("ticketingv2.Price")}</Typography.Text>
                      <Input
                        allowClear
                        style={{ marginTop: 5, border: `2px solid ${colors.grey_40}` }}
                        onChange={(e) => {
                          let value = e.target.value;
                          value = value.replace(",", ".");
                          const updatedTempForm = [...tempForm];
                          updatedTempForm[position] = {
                            ...updatedTempForm[position],
                            price:
                              !!value.split(".")[1]?.length || !value.includes(".")
                                ? parseFloat(value) || 0
                                : value,
                          };
                          setTempForm(updatedTempForm);
                        }}
                        placeholder={t("ticketingv2.SetAUnitPrice")}
                        value={
                          tempForm[position].price !== undefined ? tempForm[position].price : ""
                        }
                        prefix={
                          <Glyph
                            name="monetization_on"
                            style={{ fontSize: 20, color: colors.grey_40 }}
                          />
                        }
                      />
                    </>
                  </Form.Item>
                  <Form.Item name="type" style={{ width: "fit-content", marginBottom: 0 }}>
                    <>
                      <Typography.Text>{t("ticketingv2.Currency")}</Typography.Text>
                      <div
                        style={{
                          backgroundColor: colors.light_background,
                          display: "flex",
                          padding: 10,
                          borderRadius: symbols.base_shape.radius,
                          marginTop: 4,
                          alignItems: "center",
                          width: "fit-content",
                        }}
                      >
                        <Glyph name="monetization_on" style={{ color: colors.grey_60 }} />
                        <Form.Item noStyle name={["siteId"]} rules={[{ required: true }]}>
                          <Select
                            className="accent"
                            bordered={false}
                            size={"middle"}
                            style={{
                              width: 100,
                              color: colors.secondary_base + " !important",
                            }}
                            options={devisesOptions}
                            onChange={(e) => {
                              const updatedTempForm = [...tempForm];
                              updatedTempForm[position] = {
                                ...updatedTempForm[position],
                                devise: e,
                              };
                              setTempForm(updatedTempForm);
                            }}
                          />
                        </Form.Item>
                      </div>
                    </>
                  </Form.Item>
                </div>
                <Form.Item name="type" style={{ width: "100%", marginBottom: 0 }}>
                  <>
                    <Typography.Text>{t("ticketingv2.ProductName")}</Typography.Text>
                    <Input
                      allowClear
                      style={{ marginTop: 5, border: `2px solid ${colors.grey_40}` }}
                      onChange={(e) => {
                        const updatedTempForm = [...tempForm];
                        updatedTempForm[position] = {
                          ...updatedTempForm[position],
                          productName: e.target.value,
                        };
                        setTempForm(updatedTempForm);
                      }}
                      placeholder={t("ticketingv2.AddProductName")}
                      value={tempForm[position].productName}
                      prefix={<Glyph name="edit" style={{ fontSize: 20, color: colors.grey_40 }} />}
                    />
                  </>
                </Form.Item>
                <Form.Item name="type" style={{ width: "100%", marginBottom: 0 }}>
                  <>
                    <Typography.Text>{t("ticketingv2.Description")}</Typography.Text>
                    <Input
                      allowClear
                      style={{ marginTop: 5, border: `2px solid ${colors.grey_40}` }}
                      onChange={(e) => {
                        const updatedTempForm = [...tempForm];
                        updatedTempForm[position] = {
                          ...updatedTempForm[position],
                          description: e.target.value,
                        };
                        setTempForm(updatedTempForm);
                      }}
                      placeholder={t("ticketingv2.AddDescription")}
                      value={tempForm[position].description}
                      prefix={<Glyph name="edit" style={{ fontSize: 20, color: colors.grey_40 }} />}
                    />
                  </>
                </Form.Item>
              </Form>
            ) : (
              <Form
                name="control-ref"
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  gap: 16,
                  marginTop: 8,
                }}
              >
                <Form.Item name="type" style={{ width: "100%", marginBottom: 0 }}>
                  <>
                    <Typography.Text>{t("ticketingv2.Description")}</Typography.Text>
                    <Input
                      allowClear
                      style={{ marginTop: 5, border: `2px solid ${colors.grey_40}` }}
                      onChange={(e) => {
                        const updatedTempForm = [...tempForm];
                        updatedTempForm[position] = {
                          ...updatedTempForm[position],
                          description: e.target.value,
                        };
                        setTempForm(updatedTempForm);
                      }}
                      placeholder={t("ticketingv2.AddDescription")}
                      value={tempForm[position].description}
                      prefix={<Glyph name="edit" style={{ fontSize: 20, color: colors.grey_40 }} />}
                    />
                  </>
                </Form.Item>
              </Form>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default FormPart;
