import React, { useEffect, useState } from "react";
import Glyph from "../../Common/Glyph/Glyph";
import { sendbirdSelectors, useSendbirdStateContext } from "@sendbird/uikit-react";
import { Avatar, Button, Dropdown, Typography } from "antd";
import UserListModal from "./UserListModal";
import { useChannelSettingsContext } from "@sendbird/uikit-react/ChannelSettings/context";
import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context";
import { useKeycloak } from "@react-keycloak/web";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";

function CustomMemberList({ setQuery, setCurrentChannel, currentChannel }) {
  const globalStore = useSendbirdStateContext();
  const sdk = sendbirdSelectors.getSdk(globalStore);
  const channelSettingsContext = useChannelSettingsContext();
  const channelListContext = useChannelListContext();
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();
  const { colors, size } = useDesignTokens();

  const userData = useSelector((state) => state.userWS.userData);
  const appHasContactList = userData?.views?.some((v) => v.type === "contactListEnhanced");

  const [toggleMemberList, setToggleMemberList] = useState(false);
  const [overedMember, setOveredMember] = useState(null);
  const [isOperator, setIsOperator] = useState(false);
  const [visible, setVisible] = useState(false);

  const userMenuItems = (member) => {
    const items = [];
    if (appHasContactList) {
      items.push({
        key: "1",
        label: (
          <Link to={`/contactListEnhanced/${member.userId}`}>
            {t("CHANNEL_SETTING__MEMBER__PROFIL")}
          </Link>
        ),
      });
    }
    if (isOperator) {
      items.push({
        key: appHasContactList ? "2" : "1",
        label: (
          <div onClick={() => handleOperators(member)}>
            {member.role === "operator"
              ? t("CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR")
              : t("CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR")}
          </div>
        ),
      });
      items.push({
        key: appHasContactList ? "3" : "2",
        label: (
          <div onClick={() => handleMuteUser(member)}>
            {member.isMuted
              ? t("CHANNEL_SETTING__MODERATION__UNMUTE")
              : t("CHANNEL_SETTING__MODERATION__MUTE")}
          </div>
        ),
      });
      items.push({
        key: appHasContactList ? "4" : "3",
        label: (
          <div onClick={() => handleRemoveUserFromGroup(member.userId)} style={{ color: "red" }}>
            {t("CHANNEL_SETTING__MEMBER__REMOVE")}
          </div>
        ),
      });
    }
    return items;
  };

  useEffect(() => {
    setIsOperator(
      channelSettingsContext.channel?.members?.find(
        (member) => member.role === "operator" && member.userId === sdk?.currentUser?.userId,
      ),
    );
  }, [channelSettingsContext.channel?.members, sdk?.currentUser?.userId]);

  const handleRemoveUserFromGroup = async (userId) => {
    await channelSettingsContext.channel.banUserWithUserId(
      userId,
      0,
      "You have been removed from the group",
    );
    setCurrentChannel(channelListContext.currentChannel);
    channelListContext.currentChannel.updateChannel();
  };

  const handleOperators = async (member) => {
    member.role === "operator"
      ? await channelSettingsContext.channel.removeOperators([member.userId])
      : await channelSettingsContext.channel.addOperators([member.userId]);

    channelSettingsContext.forceUpdateUI();
  };

  const handleMuteUser = async (member) => {
    member.isMuted
      ? await channelSettingsContext.channel.unmuteUser(member)
      : await channelSettingsContext.channel.muteUser(member);

    channelSettingsContext.forceUpdateUI();
  };

  return (
    <>
      <div
        style={{ cursor: "pointer", padding: "12px 17px", borderBottom: "1px solid #e0e0e0" }}
        onClick={() => setToggleMemberList(!toggleMemberList)}
      >
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ display: "flex", gap: "16px" }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 64 64" style={{ height: 24 }}>
              <path
                className="icon-members_svg__fill"
                d="M34.667 37.333c7.17 0 13.018 5.66 13.32 12.755l.013.579V56a2.667 2.667 0 01-5.315.311L42.667 56v-5.333c0-4.26-3.33-7.743-7.53-7.987l-.47-.013H13.333a8 8 0 00-7.986 7.53l-.014.47V56a2.667 2.667 0 01-5.316.311L0 56v-5.333c0-7.17 5.66-13.019 12.755-13.321l.578-.013h21.334zM54 37.765a13.333 13.333 0 019.986 12.297l.014.605V56a2.667 2.667 0 01-5.315.311L58.667 56v-5.331a8 8 0 00-6-7.74A2.667 2.667 0 1154 37.765zM24 5.333c7.364 0 13.333 5.97 13.333 13.334C37.333 26.03 31.363 32 24 32c-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.334 13.333-13.334zm19.328.43a13.333 13.333 0 010 25.834 2.667 2.667 0 11-1.323-5.167 8 8 0 000-15.5 2.667 2.667 0 111.323-5.167zM24 10.667a8 8 0 100 16 8 8 0 000-16z"
                fill={colors.secondary_base}
                fillRule="evenodd"
              />
            </svg>

            <Typography.Text style={{ fontSize: size.l }}>
              {channelSettingsContext.channel?.members?.length > 0
                ? t("CHANNEL_SETTING__MEMBERS__TITLE")
                : t("CHANNEL_SETTING__MEMBER__TITLE")}
            </Typography.Text>
          </div>
          <div style={{ display: "flex", gap: "6px", alignItems: "center" }}>
            {channelSettingsContext.channel?.members?.length > 0 && (
              <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                <div
                  className="sendbird-badge"
                  style={{ position: "absolute", backgroundColor: "#e0e0e0" }}
                />
                <Typography.Text style={{ zIndex: "10" }}>
                  {channelSettingsContext.channel?.members?.length}
                </Typography.Text>
              </div>
            )}
            {toggleMemberList ? (
              <Glyph name="expand_more" style={{ fontSize: size.xxxxl }} />
            ) : (
              <Glyph name="navigate_next" style={{ fontSize: size.xxxxl }} />
            )}
          </div>
        </div>
      </div>
      {toggleMemberList && channelSettingsContext.channel?.members?.length > 0 && (
        <div
          style={{
            borderBottom: "1px solid #e0e0e0",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {channelSettingsContext.channel?.members?.map((member) => (
            <div
              key={member.userId}
              onMouseEnter={() => setOveredMember(member.userId)}
              onMouseLeave={() => setOveredMember(null)}
              className="sendbird-user-list-item--small"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "16px",
                  marginLeft: "12px",
                  marginRight: "12px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  {member.isMuted && (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          backgroundColor: colors.secondary_base,
                          opacity: "0.5",
                          borderRadius: "100%",
                          width: 24,
                          height: 24,
                          zIndex: "5",
                        }}
                      />

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 64 64"
                        style={{ position: "absolute", zIndex: "10", height: "16px" }}
                      >
                        <path
                          className="icon-mute_svg__fill"
                          d="M55.62 19.616l.067.123A26.552 26.552 0 0158.667 32c0 4.326-1.03 8.41-2.864 12.025-1.012 2.726-1.235 4.847-.67 6.363.71 1.903 1.598 3.49 2.662 4.758 1.54 1.586 1.039 2.696-1.506 3.33-2.09.521-5.716-.027-10.879-1.646l-.488-.155-1.594-.527A26.56 26.56 0 0132 58.667a26.55 26.55 0 01-12.326-3.014l-.059-.03 4-4A21.24 21.24 0 0032 53.333c2.993 0 5.89-.614 8.562-1.786l.498-.226 1.925-.905 3.613 1.196.695.219c.728.225 1.414.423 2.054.595l.472.125.485.121-.167-.42-.2-.594c-.814-2.685-.484-5.681.713-9.065l.154-.425.106-.284.528-1.084a21.188 21.188 0 001.895-8.8 21.24 21.24 0 00-1.71-8.385l3.997-3.999zm2.266-13.502a2.668 2.668 0 01.221 3.52l-.221.252-48 48a2.668 2.668 0 01-3.993-3.52l.221-.252 5.238-5.237a26.563 26.563 0 01-6.015-16.412L5.333 32C5.333 17.272 17.273 5.333 32 5.333a26.55 26.55 0 0116.877 6.02l5.237-5.239a2.668 2.668 0 013.772 0zM32 10.667c-11.782 0-21.333 9.55-21.333 21.333 0 4.836 1.614 9.401 4.48 13.084l29.936-29.938A21.248 21.248 0 0032 10.666z"
                          fill="#fff"
                          fillRule="evenodd"
                        />
                      </svg>
                    </>
                  )}
                  <Avatar
                    size="small"
                    src={`${member.plainProfileUrl}?access_token=${keycloak.token}`}
                    style={{
                      border: "none",
                      boxShadow: "none",
                      backgroundColor: "#bdbdbd",
                    }}
                  >
                    {member.nickname?.charAt(0).toUpperCase()}
                    {member.nickname?.split(" ")[1]?.charAt(0).toUpperCase()}
                  </Avatar>
                </div>
                <div>
                  <Typography.Text style={{ fontSize: size.m }}>{member.nickname}</Typography.Text>
                  {member.userId === sdk?.currentUser?.userId && (
                    <Typography.Text type="secondary">
                      &nbsp;{t("CHANNEL_SETTING__MEMBERS__YOU")}
                    </Typography.Text>
                  )}
                </div>
              </div>

              {member.role === "operator" && overedMember !== member.userId && (
                <Typography.Text type="secondary" style={{ marginRight: "24px" }}>
                  {t("CHANNEL_SETTING__MEMBERS__OPERATOR")}
                </Typography.Text>
              )}

              {(appHasContactList || member.role === "operator") &&
                overedMember === member.userId &&
                member.userId !== sdk?.currentUser?.userId && (
                  <Dropdown
                    menu={{ items: userMenuItems(member) }}
                    trigger={["click"]}
                    placement="bottomRight"
                    style={{ cursor: "pointer" }}
                  >
                    <Glyph
                      name="more_vert"
                      style={{ fontSize: size.xxxxl, marginRight: "12px", cursor: "pointer" }}
                    />
                  </Dropdown>
                )}
            </div>
          ))}
          <Button
            type="quaternary"
            size="medium"
            style={{
              margin: "12px",
              marginBottom: "12px",
              padding: "0 24px",
            }}
            onClick={() => setVisible(true)}
          >
            {t("BUTTON__INVITE")}
          </Button>
        </div>
      )}
      {visible && (
        <UserListModal
          visible={visible}
          setVisible={setVisible}
          currentChannel={channelSettingsContext.channel}
          type="userList"
          setQuery={setQuery}
          setCurrentChannel={setCurrentChannel}
        />
      )}
    </>
  );
}

export default CustomMemberList;
