import { Button, Card, Col, DatePicker, Form, Modal, Row, Select, Switch, Typography } from "antd";
import { logEvent } from "firebase/analytics";
import moment from "moment";
import { append, range, update } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { analytics } from "../../../services/api/Firebase/Firebase";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import { cancelVisit, createVisit, updateVisit } from "../../../services/redux/services/VisitorsWS";
import Glyph from "../../Common/Glyph/Glyph";
import EditUser from "./EditUser";
import UserInput from "./UserInput/UserInput";
import UserList from "./UserList/UserList";

const deserialize = (visit) => {
  const date = moment(visit?.startDate);
  date.minutes(Math.ceil(date.minutes() / 15) * 15);

  return {
    date,
    duration: visit?.durationInDay || 1,
    resident: visit?.resident,
    visitors: visit?.visitors?.map((r) => r) || [],
  };
};

const EditVisit = ({ visit, open, onDismiss }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [useCustomResident, setUseCustomResident] = useState(false);
  const [editingUser, setEditingUser] = useState(null);

  const config = useSelector((state) => state.visitorsWS.config);
  const isSaving = useSelector(
    createLoadingSelector(["visitors/createVisit", "visitors/updateVisit"]),
  );
  const isCanceling = useSelector(createLoadingSelector(["visitors/cancelVisit"]));
  const isVisitor = useSelector((state) => state.userWS.userData.profile.isVisitor);
  const profile = useSelector((state) => state.userWS.userData.profile);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    setUseCustomResident(visit?.creatorId !== visit?.resident?.id);
  }, [visit?.creatorId, visit?.resident?.id]);

  useEffect(() => {
    if (visit && open) form.setFieldsValue(deserialize(visit));
    return () => {
      form.resetFields();
    };
  }, [form, visit, open]);

  const handleAddVisitor = (user) => {
    if (!user) return;
    const visitors = form.getFieldValue(["visitors"]) || [];
    const idx = visitors.findIndex((v) => v.id === user.id);
    if (idx === -1) form.setFieldsValue({ visitors: append(user, visitors) });
    else form.setFieldsValue({ visitors: update(idx, user, visitors) });
    setIsDisabled(false);
  };

  const handleDeleteUser = (user) => {
    if (user === "resident") {
      form.setFieldsValue({ resident: null });
    } else {
      form.setFieldsValue({
        visitors: form.getFieldValue(["visitors"]).filter((v) => v.id !== user.id),
      });
    }

    if (form.getFieldValue(["visitors"]).length === 0) {
      setIsDisabled(true);
    }
  };

  const handleSubmit = ({ date, duration, resident, visitors }) => {
    const rounded = moment(date).set({ second: 0, millisecond: 0 });
    const visitData = {
      id: visit?.id,
      startDate: moment(rounded),
      endDate:
        duration > 1 ? moment(rounded).add(duration, "days") : moment(rounded).add(2, "hours"),
      duration,
      resident: resident ? parseInt(resident.id) : profile.id,
      visitors: visitors.map((user) => {
        return user.id;
      }),
    };

    const operation = !visit?.id
      ? dispatch(createVisit(visitData))
      : dispatch(updateVisit(visitData));

    operation.unwrap().then(() => {
      onDismiss();
    });
  };
  const handleCancel = () => {
    dispatch(cancelVisit(visit))
      .unwrap()
      .then(() => {
        onDismiss();
      });
  };

  return (
    <>
      <Modal
        destroyOnClose
        open={open}
        onCancel={onDismiss}
        footer={
          !isVisitor && (
            <Row gutter={[20, 20]} style={{ width: "100%" }}>
              {!visit?.id ? (
                <Col span={24}>
                  <Button
                    type="primary"
                    block
                    loading={isSaving}
                    disabled={isSaving || isDisabled}
                    onClick={() => {
                      form.submit();
                      logEvent(analytics, "visitors_new_visit");
                    }}
                  >
                    {t("visitors.forms.visit.create")}
                  </Button>
                </Col>
              ) : (
                <>
                  <Col span={12}>
                    <Button
                      type="primary"
                      block
                      loading={isSaving}
                      disabled={isSaving || isCanceling}
                      onClick={() => {
                        form.submit();
                        logEvent(analytics, "visitors_update_visit");
                      }}
                    >
                      {t("visitors.forms.visit.update")}
                    </Button>
                  </Col>
                  <Col span={12}>
                    <Button
                      block
                      loading={isCanceling}
                      disabled={
                        isSaving || isCanceling || moment(visit?.endDate).isBefore(moment())
                      }
                      onClick={() => {
                        handleCancel();
                        logEvent(analytics, "visitors_delete_visit");
                      }}
                    >
                      {t("visitors.forms.visit.delete")}
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          )
        }
      >
        <Form form={form} onFinish={handleSubmit}>
          <Row gutter={[20, 20]} align="middle">
            {isVisitor ? (
              <Col>
                <Typography.Title level={3}>{t("visitors.forms.visit.detail")}</Typography.Title>
              </Col>
            ) : !visit?.id ? (
              <Col>
                <Typography.Title level={3}>{t("visitors.forms.visit.create")}</Typography.Title>
              </Col>
            ) : (
              <Col>
                <Typography.Title level={3}>{t("visitors.forms.visit.update")}</Typography.Title>
              </Col>
            )}

            <Col span={24}>
              <Card bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
                <div style={{ display: "flex", gap: 10, alignItems: "center", height: 54 }}>
                  <Glyph name="event" className="secondary" />
                  <Form.Item noStyle name={["date"]}>
                    <DatePicker
                      disabled={isVisitor}
                      bordered={false}
                      style={{ width: "100%" }}
                      suffixIcon={null}
                      format="LLLL"
                      showTime={{
                        format: "HH:mm",
                        minuteStep: 15,
                        use12Hours: false,
                      }}
                      disabledDate={(current) =>
                        current && moment(current).isBefore(moment(), "date")
                      }
                      disabledTime={(current) => ({
                        disabledHours: () =>
                          current.isSame(moment(), "date") ? range(0, moment().hours()) : [],
                        disabledMinutes: () =>
                          current.isSame(moment(), "hour") ? range(0, moment().minutes()) : [],
                      })}
                    />
                  </Form.Item>
                </div>
              </Card>
            </Col>

            {config?.maxVisitDuration > 1 && (
              <Col span={24}>
                <Card bodyStyle={{ paddingTop: 5, paddingBottom: 5 }}>
                  <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                    <Glyph name={"repeat"} className="secondary" />
                    <Form.Item noStyle name={["duration"]}>
                      <Select
                        bordered={false}
                        style={{ width: "100%" }}
                        options={Array.from({ length: config.maxVisitDuration }, (_, i) => ({
                          label: t("visitors.forms.visit.fields.duration.day", { count: i + 1 }),
                          value: i + 1,
                        }))}
                      />
                    </Form.Item>
                  </div>
                </Card>
              </Col>
            )}

            {(!visit?.id || (!!visit?.id && visit?.creatorId !== visit?.resident?.id)) && (
              <>
                <Col flex={"auto"}>
                  <Typography.Text>{t("visitors.forms.visit.resident")}</Typography.Text>
                </Col>
                <Col>
                  <Switch
                    disabled={!!visit?.id}
                    checked={useCustomResident}
                    onChange={setUseCustomResident}
                  />
                </Col>
              </>
            )}
            {!!useCustomResident && (
              <Col span={24}>
                <Form.Item noStyle name={["resident"]}>
                  {visit?.id ? (
                    <Card bodyStyle={{ paddingTop: 0, paddingBottom: 0 }}>
                      <UserList users={[visit.resident]} descriptionKey="function" />
                    </Card>
                  ) : (
                    <UserInput
                      placeholder={t("visitors.forms.visit.fields.resident")}
                      type="resident"
                      onChange={() => logEvent(analytics, "visitors_delegation")}
                    />
                  )}
                </Form.Item>
              </Col>
            )}

            {!isVisitor && (
              <Col span={24}>
                <Typography.Paragraph type="secondary">
                  {t("visitors.forms.visit.fields.visitors.helper")}
                </Typography.Paragraph>

                <UserInput
                  allowCreation
                  placeholder={t("visitors.forms.visit.fields.visitors")}
                  type="visitor"
                  value={null}
                  onChange={handleAddVisitor}
                />
              </Col>
            )}
            <Col span={24}>
              <Form.Item noStyle name={["visitors"]} />
              <Form.Item noStyle shouldUpdate>
                {({ getFieldValue }) => (
                  <>
                    <UserList
                      style={{ cursor: "pointer" }}
                      users={getFieldValue(["visitors"])}
                      descriptionKey="email"
                      onDelete={handleDeleteUser}
                      onClick={(user) => setEditingUser(user)}
                    />
                  </>
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>

      {!isVisitor && (
        <EditUser
          open={!!editingUser}
          user={editingUser}
          onDismiss={(user) => {
            setEditingUser(null);
            if (user) handleAddVisitor(user);
          }}
        />
      )}
    </>
  );
};

export default EditVisit;
