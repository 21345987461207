import { Button, Layout, notification } from "antd";
import moment from "moment";
import { isEmpty } from "ramda";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../../hook/useDesignTokens";
import {
  checkBookingPending,
  getRoomFastbooking,
  resetBookedRoom,
  setFilter,
} from "../../../../services/redux/services/FastbookingWS";
import Glyph from "../../../Common/Glyph/Glyph";
import styles from "../Fastbooking.module.less";
import { useEffect } from "react";
import ModalDetails from "./ModalDetails";
import Booking from "./Booking/Booking";
import { getPlanning } from "../../../../services/redux/services/PlanningWS";
import CardBooking from "./CardBooking";
import CardFilter from "./CardFilter";
import CardRoom from "./CardRoom";
import ModalListBooking from "./ModalListBooking";
import useDidUpdateEffect from "../../../../hook/useDidUpdate";

const FastbookingList = ({ during, setDuring }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { colors } = useDesignTokens();
  const locale = i18n.language.split("-")[0];
  const startDate = moment().toISOString();

  const [modalDetails, setModalDetails] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState();
  const [visible, setVisible] = useState(false);
  const [modalBooking, setModalBooking] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const planning = useSelector((state) => state.planningWS.planning);
  const bookedRoom = useSelector((state) => state.fastbookingWS.bookedRoom);

  const roomBookedByMe = planning?.slots?.items?.filter(
    (f) => !!f.locations?.length && !!f.isOrganizer,
  );

  const refresh = async () => {
    try {
      await Promise.all([
        dispatch(
          getRoomFastbooking({
            ...filters,
            locale,
            startDate: filters?.startDate ? filters.startDate : moment().toISOString(),
          }),
        ),
        dispatch(
          getPlanning({
            locale,
            startDate,
            endDate: moment().add(7, "days").toISOString(),
          }),
        ),
      ]);
    } catch (error) {
      logger.error(error);
    }
  };

  useDidUpdateEffect(() => {
    refresh();

    const intervalId = setInterval(refresh, 10000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters]);

  useEffect(() => {
    const bookingCheckRoutine = async () => {
      if (bookedRoom === null) {
        clearInterval(createBookingCheckRoutine);
        refresh();
      } else {
        dispatch(
          checkBookingPending({
            filters: {
              startDate: filters.startDate ? filters.startDate : moment().toISOString(),
              locale: "fr",
            },
          }),
        );
      }
    };

    const createBookingCheckRoutine = setInterval(() => bookingCheckRoutine(bookedRoom), 3000);

    return () => clearInterval(createBookingCheckRoutine);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookedRoom, filters, dispatch]);

  const openNotification = () => {
    const args = {
      message: "Attention",
      icon: <Glyph name="warning" style={{ color: colors.error_light }} />,
      description: t("AdvertisementMessage"),
      duration: 5,
    };
    notification.open(args);
  };

  useEffect(() => {
    dispatch(resetBookedRoom());
    dispatch(setFilter({ ...filters, seats: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (document.getElementById("view")) {
      const handleScroll = () => {
        if (document.getElementById("view").scrollTop > 100) {
          setShowScrollButton(true);
        } else {
          setShowScrollButton(false);
        }
      };

      document.getElementById("view").addEventListener("scroll", handleScroll);
    }
  }, [dispatch]);

  return (
    <>
      <Layout className={styles.dashboardContainer}>
        <Layout id="view">
          {!isEmpty(roomBookedByMe) &&
            !isEmpty(
              roomBookedByMe?.filter((r) =>
                r?.locations?.find((l) => l.siteId === campus?.mapData?.id),
              ),
            ) && <CardBooking setModalBooking={setModalBooking} />}

          <CardFilter setVisible={setVisible} openNotification={openNotification} />
          <CardRoom setModalDetails={setModalDetails} setSelectedRoom={setSelectedRoom} />
          {visible && (
            <Booking
              isVisible={visible}
              onCancel={() => setVisible(false)}
              during={during}
              setDuring={setDuring}
            />
          )}
        </Layout>
        {showScrollButton && (
          <Button
            icon={<Glyph name="arrow_upward" />}
            type="secondary"
            style={{ position: "fixed", right: "100px", bottom: "50px", zIndex: "9999" }}
            onClick={() => document.getElementById("view").scrollTo({ top: 0, behavior: "smooth" })}
          >
            {t("backToFilter")}
          </Button>
        )}
      </Layout>

      <ModalDetails
        startDate={filters?.startDate ? filters?.startDate : moment()}
        isVisible={modalDetails}
        onCancel={() => setModalDetails(false)}
        selectedRoom={selectedRoom}
        setModalBooking={setModalBooking}
        bookingClose={() => {
          setVisible(false);
        }}
        during={during}
        setDuring={setDuring}
      />

      <ModalListBooking modalBooking={modalBooking} setModalBooking={setModalBooking} />
    </>
  );
};

export default FastbookingList;
