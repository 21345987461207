import { Col, Row, Typography } from "antd";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import styles from "../Dashboard/Dashboard.module.less";

import { filteredTickets, getListTicket } from "../../../services/redux/services/TicketingWS";
import { isEmpty } from "ramda";

const Widget = ({ view }) => {
  const { t, i18n } = useTranslation();
  const { colors } = useDesignTokens();
  const dispatch = useDispatch();

  const ticketing = useSelector(filteredTickets);
  const userData = useSelector((state) => state.userWS.userData);

  useEffect(() => {
    !isEmpty(userData) &&
      dispatch(getListTicket({ locale: i18n.language.split("-")[0], page: 1, size: 100 }));
  }, [dispatch, i18n.language, userData]);

  return (
    <NavLink to={`/ticketing?group=${view.ticketing?.groupType}`}>
      <Row gutter={[8, 8]}>
        <Col flex="auto">
          <div className={styles["number-typeTicket"]}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px 0px 16px",
                alignItems: "center",
              }}
            >
              <Glyph name="hourglass_full" style={{ color: colors.pending_light }} />
              <Typography.Title
                style={{ fontSize: "34px", margin: "0", color: colors.pending_light }}
              >
                {ticketing?.items?.filter((i) => i?.statusId === 0).length}
              </Typography.Title>
            </div>

            <Typography.Text style={{ margin: "0", padding: "0px 16px 8px 16px" }}>
              {t("New")}
            </Typography.Text>
          </div>
        </Col>
        <Col flex="auto" span="12">
          <div className={styles["number-typeTicket"]} style={{ marginBottom: 4 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "8px 16px 0px 16px",
                alignItems: "center",
              }}
            >
              <Glyph name="thumb_up_alt" style={{ color: colors.highlight_light }} />
              <Typography.Title
                style={{ color: colors.highlight_light, fontSize: "34px", margin: "0" }}
              >
                {ticketing?.items?.filter((i) => i?.status === "Pris en compte").length}
              </Typography.Title>
            </div>

            <Typography.Text
              style={{ margin: "0", padding: "0px 16px 8px 16px", display: "block" }}
            >
              {t("TakenIntoAccount")}
            </Typography.Text>
          </div>
        </Col>
      </Row>
    </NavLink>
  );
};

export default Widget;
