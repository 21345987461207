import { sendbirdSelectors, useSendbirdStateContext } from "@sendbird/uikit-react";
import { useEffect, useState } from "react";
import { useChannelListContext } from "@sendbird/uikit-react/ChannelList/context";
import Glyph from "../../Common/Glyph/Glyph";
import { Input, notification } from "antd";
import useDesignTokens from "../../../hook/useDesignTokens";
import { useTranslation } from "react-i18next";

function CustomInput() {
  const { t } = useTranslation();
  const { colors, symbols } = useDesignTokens();
  const globalStore = useSendbirdStateContext();
  const channelListContext = useChannelListContext();

  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue("");
  }, [channelListContext?.currentChannel]);

  //SEND FILE
  const handleSendFileMessage = (e) => {
    const params = {};
    params.file = e.target.files[0];

    if (
      !(
        ["image", "audio", "video"].includes(params.file.type.split("/")[0]) ||
        params.file.type === "application/pdf"
      )
    ) {
      e.target.value = "";
      notification.open({
        message: t("FILE_TYPE_NOT_ALLOWED"),
      });
      return;
    }

    if (params.file.size > 25000000) {
      e.target.value = "";
      notification.open({
        message: t("FILE_TOO_BIG"),
      });
      return;
    }

    const sendFileMessage = sendbirdSelectors.getSendFileMessage(globalStore);
    sendFileMessage(channelListContext?.currentChannel, params)
      .onPending((message) => {
        //logger.log("onPending", message);
      })
      .onFailed((error, message) => {
        logger.log("onFailed", error, message);
      })
      .onSucceeded((message) => {
        //logger.log("onSucceeded", message);
        e.target.value = "";
      });
  };

  // TYPING INDICATOR
  useEffect(() => {
    if (inputValue) {
      channelListContext?.currentChannel.startTyping();
      return () => {
        channelListContext?.currentChannel.endTyping();
      };
    }
  }, [channelListContext?.currentChannel, inputValue]);

  //SEND MESSAGE
  const handleSendMessage = () => {
    if (inputValue) {
      const sendUserMessage = sendbirdSelectors.getSendUserMessage(globalStore);
      sendUserMessage(channelListContext?.currentChannel, { message: inputValue })
        .onPending((message) => {
          //logger.log("onPending", message);
        })
        .onFailed((error, message) => {
          logger.log("onFailed", error, message);
        })
        .onSucceeded((message) => {
          //logger.log("onSucceeded", message);
          setInputValue("");
        });
    }
  };

  const handleKeyPress = (event) => {
    logger.log(event.key, event.shiftKey);
    if (event.key === "Enter" && event.shiftKey) {
      setInputValue((prevValue) => prevValue + "\n");
      event.preventDefault();
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div style={{ padding: "0px 24px" }}>
      <div style={{ display: "flex", position: "relative" }}>
        <Input.TextArea
          type="primary"
          autoSize
          style={{ width: "100%", borderRadius: 4, borderWidth: 1, paddingRight: 42 }}
          value={inputValue}
          placeholder={t("MESSAGE_INPUT__PLACE_HOLDER")}
          onChange={(event) => setInputValue(event.target.value)}
          onKeyDown={handleKeyPress}
        />
        <div style={{ position: "absolute", right: 12, top: 12 }}>
          {inputValue ? (
            <Glyph
              name={"send"}
              onClick={handleSendMessage}
              style={{
                color: colors.secondary_base,
                cursor: "pointer",
                height: "fit-content",
              }}
            />
          ) : (
            <label htmlFor="fileInput">
              <Glyph
                name="attach_file"
                style={{
                  color: colors.secondary_base,
                  cursor: "pointer",
                  height: "fit-content",
                  transform: "rotate(-90deg)",
                }}
              />
            </label>
          )}
        </div>
      </div>
      <input
        type="file"
        accept="image/*, audio/*, video/*, application/pdf"
        onChange={(e) => {
          handleSendFileMessage(e);
          e.target.value = "";
        }}
        style={{ display: "none" }}
        id="fileInput"
      />
    </div>
  );
}

export default CustomInput;
