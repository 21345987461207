import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  referential: [],
  category: null,
  attachment: [],
  selectedTicketId: null,
  groupFilter: null,
  floors: null,
  referentialsAdmin: null,
  referentialsManager: null,
  referentialsAgent: null,
  groups: null,
  status: null,
  adminStatus: null,
  allTickets: [],
  allMyTickets: [],
  ticketDetails: null,
  agents: null,
  adminRef: null,
  statusTickets: null,
  allSpaces: null,
  commentAssets: {},
  currentViewId: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// TICKETING ///////////////////////////////
export const getAllFloors = thunk("ticketingV2/getAllFloors", async ({ locale, siteId }) => {
  return DWMConnector.getAllFloors(locale, siteId);
});

EXTRA_REDUCERS[getAllFloors.fulfilled] = (state, action) => {
  state.floors = action.payload;
};

export const getAllGroups = thunk("ticketingV2/getAllGroups", async ({ siteId }, { getState }) => {
  const state = getState();
  // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
  return DWMConnector.getAllGroups(state.ticketingV2WS.currentViewId, siteId);
});

EXTRA_REDUCERS[getAllGroups.fulfilled] = (state, action) => {
  state.groups = action.payload;
};

export const getAgents = thunk(
  "ticketingV2/getAgents",
  async ({ siteId, page, size, viewId }, { getState }) => {
    //const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Agent");
    return DWMConnector.getAgents(viewId, siteId, page, size);
  },
);

EXTRA_REDUCERS[getAgents.fulfilled] = (state, action) => {
  if (action.meta.arg.page > 1) {
    state.agents = {
      ...action.payload,
      items: [...state.agents.items, ...action.payload.items],
    };
  } else {
    state.agents = action.payload;
  }
};

export const getAgentsWithCriteria = thunk(
  "ticketingV2/getAgentsWithCriteria",
  async ({ ticketIds, siteId, page, size, viewId }, { getState }) => {
    //const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Agent");
    return DWMConnector.getAgentsWithCriteria(viewId, ticketIds, siteId, page, size);
  },
);

EXTRA_REDUCERS[getAgentsWithCriteria.fulfilled] = (state, action) => {
  if (action.meta.arg.page > 1) {
    state.agents = {
      ...action.payload,
      items: [...state.agents.items, ...action.payload.items],
    };
  } else {
    state.agents = action.payload;
  }
};

export const getAllSpaces = thunk(
  "ticketingV2/getAllSpaces",
  async ({ siteId, locale }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.getAllSpaces(state.ticketingV2WS.currentViewId, siteId, locale);
  },
);

EXTRA_REDUCERS[getAllSpaces.fulfilled] = (state, action) => {
  state.allSpaces = action.payload;
};

// STATUS

export const getAllStatus = thunk(
  "ticketingV2/getAllStatus",
  async ({ siteId, locale }, { getState }) => {
    const state = getState();
    //const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.getAllStatus(state.ticketingV2WS.currentViewId, siteId, locale);
  },
);

EXTRA_REDUCERS[getAllStatus.fulfilled] = (state, action) => {
  state.status = action.payload;
};

export const getAllAdminStatus = thunk(
  "ticketingV2/getAllAdminStatus",
  async ({ siteId, locale }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.getAllAdminStatus(state.ticketingV2WS.currentViewId, siteId, locale);
  },
);

EXTRA_REDUCERS[getAllAdminStatus.fulfilled] = (state, action) => {
  state.adminStatus = action.payload;
};

export const getAdminStatus = thunk(
  "ticketingV2/getAdminStatus",
  async ({ siteId, statusId }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.getAdminStatus(state.ticketingV2WS.currentViewId, siteId, statusId);
  },
);

EXTRA_REDUCERS[getAdminStatus.fulfilled] = (state, action) => {
  state.statusTickets = action.payload;
};

export const createTicketStatus = thunk(
  "ticketingV2/createTicketStatus",
  async ({ siteId, data }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.createTicketStatus(state.ticketingV2WS.currentViewId, siteId, data);
  },
);

export const updateTicketStatus = thunk(
  "ticketingV2/updateTicketStatus",
  async ({ siteId, data, statusId }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.updateTicketStatus(
      state.ticketingV2WS.currentViewId,
      siteId,
      data,
      statusId,
    );
  },
);

export const deleteTicketStatus = thunk(
  "ticketingV2/deleteTicketStatus",
  async ({ siteId, statusId, option }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.deleteTicketStatus(
      state.ticketingV2WS.currentViewId,
      siteId,
      statusId,
      option,
    );
  },
);

// REFERENTIAL

export const getAllReferential = thunk(
  "ticketingV2/getAllReferential",
  async ({ siteId, locale, space, viewId }, { getState }) => {
    //const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.getAllReferential(viewId, siteId, locale, space);
  },
);

EXTRA_REDUCERS[getAllReferential.fulfilled] = (state, action) => {
  state.referential = action.payload;
};

export const getAllReferentialsAdmin = thunk(
  "ticketingV2/getAllReferentialsAdmin",
  async ({ siteId, locale }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.getAllReferentialsAdmin(state.ticketingV2WS.currentViewId, siteId, locale);
  },
);

EXTRA_REDUCERS[getAllReferentialsAdmin.fulfilled] = (state, action) => {
  state.referentialsAdmin = action.payload;
};

export const getAdminReferential = thunk(
  "ticketingV2/getAdminReferential",
  async ({ siteId, stuffId }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.getAdminReferential(state.ticketingV2WS.currentViewId, siteId, stuffId);
  },
);

EXTRA_REDUCERS[getAdminReferential.fulfilled] = (state, action) => {
  state.adminRef = action.payload;
};

export const getAllReferentialsManager = thunk(
  "ticketingV2/getAllReferentialsManager",
  async ({ siteId, locale, viewId }, { getState }) => {
    //const state = getState();
    //const viewId = state.userWS.userData.views.find((v) => v.type === "ticketingV2Agent");
    return DWMConnector.getAllReferentialsManager(
      //state.ticketingV2WS.currentViewId,
      viewId,
      siteId,
      locale,
    );
  },
);

EXTRA_REDUCERS[getAllReferentialsManager.fulfilled] = (state, action) => {
  state.referentialsManager = action.payload;
};

export const getAllReferentialsAgent = thunk(
  "ticketingV2/getAllReferentialsAgent",
  async ({ siteId, locale }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Agent");
    return DWMConnector.getAllReferentialsAgent(state.ticketingV2WS.currentViewId, siteId, locale);
  },
);

EXTRA_REDUCERS[getAllReferentialsAgent.fulfilled] = (state, action) => {
  state.referentialsAgent = action.payload;
};

export const createTicketReferential = thunk(
  "ticketingV2/createTicketReferential",
  async ({ siteId, data }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.createTicketReferential(state.ticketingV2WS.currentViewId, siteId, data);
  },
);

export const updateTicketReferential = thunk(
  "ticketingV2/updateTicketReferential",
  async ({ siteId, data, stuffId }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.updateTicketReferential(
      state.ticketingV2WS.currentViewId,
      siteId,
      data,
      stuffId,
    );
  },
);

export const deleteTicketReferential = thunk(
  "ticketingV2/deleteTicketReferential",
  async ({ siteId, referentialId }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Admin");
    return DWMConnector.deleteTicketReferential(
      state.ticketingV2WS.currentViewId,
      siteId,
      referentialId,
    );
  },
);

// TICKETS
export const getAllAgentTickets = thunk(
  "ticketingV2/getAllAgentTickets",
  async (
    { siteId, locale, dir, page, size, status, priority, type, category, subCategory, floor },
    { getState },
  ) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Agent");
    return DWMConnector.getAllAgentTickets(
      state.ticketingV2WS.currentViewId,
      siteId,
      locale,
      dir,
      page,
      size,
      status,
      priority,
      type,
      category,
      subCategory,
      floor,
    );
  },
);

EXTRA_REDUCERS[getAllAgentTickets.fulfilled] = (state, action) => {
  state.allTickets = action.payload;
};

export const getAllManagerTickets = thunk(
  "ticketingV2/getAllManagerTickets",
  async (
    {
      siteId,
      locale,
      dir,
      page,
      size,
      status,
      priority,
      type,
      category,
      subCategory,
      agentId,
      floor,
    },
    { getState },
  ) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Agent");
    return DWMConnector.getAllManagerTickets(
      state.ticketingV2WS.currentViewId,
      siteId,
      locale,
      dir,
      page,
      size,
      status,
      priority,
      type,
      category,
      subCategory,
      agentId,
      floor,
    );
  },
);

EXTRA_REDUCERS[getAllManagerTickets.fulfilled] = (state, action) => {
  state.allTickets = action.payload;
};

export const getAllMyTickets = thunk(
  "ticketingV2/getAllMyTickets",
  async (
    { siteId, dir, locale, page, size, status, type, category, subCategory, agent, floor },
    { getState },
  ) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.getAllMyTickets(
      state.ticketingV2WS.currentViewId,
      siteId,
      locale,
      dir,
      page,
      size,
      status,
      type,
      category,
      subCategory,
      agent,
      floor,
    );
  },
);

EXTRA_REDUCERS[getAllMyTickets.fulfilled] = (state, action) => {
  const { status, page } = action.meta.arg;

  const existingColumnIndex = state.allMyTickets.findIndex((column) => column.statusId === status);

  if (existingColumnIndex !== -1) {
    // La colonne existe
    const updatedColumn = {
      ...action.payload,
      statusId: status,
      items:
        page === 1
          ? action.payload.items
          : state.allMyTickets[existingColumnIndex].items.concat(action.payload.items),
    };

    return {
      ...state,
      allMyTickets: [
        ...state.allMyTickets.slice(0, existingColumnIndex),
        updatedColumn,
        ...state.allMyTickets.slice(existingColumnIndex + 1),
      ],
    };
  } else {
    // La colonne n'existe pas
    return {
      ...state,
      allMyTickets: [...state.allMyTickets, { ...action.payload, statusId: status }],
    };
  }
};

export const getTicketDetails = thunk(
  "ticketingV2/getTicketDetails",
  async ({ siteId, locale, ticketId }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.getTicketDetails(
      state.ticketingV2WS.currentViewId,
      siteId,
      locale,
      ticketId,
    );
  },
);

EXTRA_REDUCERS[getTicketDetails.fulfilled] = (state, action) => {
  state.ticketDetails = action.payload;
};

export const createTicketV2 = thunk(
  "ticketingV2/createTicketV2",
  async ({ siteId, data }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.createTicketV2(state.ticketingV2WS.currentViewId, siteId, data);
  },
);

EXTRA_REDUCERS[createTicketV2.fulfilled] = (state, action) => {
  state.ticketDetails = action.payload;
};

export const updateTicketV2 = thunk(
  "ticketingV2/updateTicketV2",
  async ({ siteId, ticketId, data }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.updateTicketV2(state.ticketingV2WS.currentViewId, siteId, ticketId, data);
  },
);

EXTRA_REDUCERS[updateTicketV2.fulfilled] = (state, action) => {
  state.ticketDetails = action.payload;
};

export const updateTicketByAgent = thunk(
  "ticketingV2/updateTicketByAgent",
  async ({ siteId, data, viewId }, { getState }) => {
    // const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Agent");
    return DWMConnector.updateTicketByAgent(viewId, siteId, data);
  },
);

export const updateTicketByManager = thunk(
  "ticketingV2/updateTicketByManager",
  async ({ siteId, data, viewId }, { getState }) => {
    //const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2Agent");
    return DWMConnector.updateTicketByManager(viewId, siteId, data);
  },
);

export const addCommentToTicket = thunk(
  "ticketingV2/addCommentToTicket",
  async ({ siteId, ticketId, data }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.addCommentToTicket(
      state.ticketingV2WS.currentViewId,
      siteId,
      ticketId,
      data,
    );
  },
);

export const deleteCommentToTicket = thunk(
  "ticketingV2/deleteCommentToTicket",
  async ({ siteId, ticketId, commentId }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.deleteCommentToTicket(
      state.ticketingV2WS.currentViewId,
      siteId,
      ticketId,
      commentId,
    );
  },
);

export const addAssetToTicketComment = thunk(
  "ticketingV2/addAssetToTicketComment",
  async ({ siteId, ticketId, commentId, formData }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.addAssetToTicketComment(
      state.ticketingV2WS.currentViewId,
      siteId,
      ticketId,
      commentId,
      formData,
    );
  },
);

export const addAssetToTicket = thunk(
  "ticketingV2/addAssetToTicket",
  async ({ siteId, ticketId, formData }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.addAssetToTicket(
      state.ticketingV2WS.currentViewId,
      siteId,
      ticketId,
      formData,
    );
  },
);

export const rateTicket = thunk(
  "ticketingV2/rateTicket",
  async ({ siteId, ticketId, data }, { getState }) => {
    const state = getState();
    // const view-Id = state.userWS.userData.views.find((v) => v.type === "ticketingV2");
    return DWMConnector.rateTicket(state.ticketingV2WS.currentViewId, siteId, ticketId, data);
  },
);

const ticketingSlice = createSlice({
  name: "ticketing",
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentViewId: (state, action) => {
      state.currentViewId = action.payload;
    },
  },
  extraReducers: EXTRA_REDUCERS,
});
export const { setCurrentViewId } = ticketingSlice.actions;

export default ticketingSlice.reducer;
