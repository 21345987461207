import { Grid, Tabs, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { TitleSource } from "../../Common/Teleporters/Title";
import CreateOrModifyBookingModal from "./Modals/CreateOrModifyBookingModal";
import ManageVehiclesModal from "./Modals/ManageVehiclesModal";
import ParkingTabBooking from "./ParkingTabBooking";
import ParkingTabHistory from "./ParkingTabHistory";
import DeleteBookingModal from "./Modals/DeleteBookingModal";
import styles from "./parking.module.less";
import { useDispatch, useSelector } from "react-redux";
import { getVehicles } from "../../../services/redux/services/ParkingWS";
import moment from "moment";
import useDidUpdateEffect from "../../../hook/useDidUpdate";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const Parking = () => {
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();
  const dispatch = useDispatch();

  const [showManageVehiclesModal, setShowManageVehiclesModal] = useState(false);
  const [showCreateOrModifyBookingModal, setShowCreateOrModifyBookingModal] = useState(false);
  const [showDeleteBookingModal, setShowDeleteBookingModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [selectedMonth, setSelectedMonth] = useState(moment().format("YYYY-MM"));
  const [selectedBooking, setSelectedBooking] = useState(null);
  const siteId = useSelector((state) => state.clientsWS.campus.mapData.id);

  useEffect(() => {
    dispatch(getVehicles({ siteId }));
  }, [dispatch, siteId]);

  useDidUpdateEffect(() => {
    logEvent(analytics, "parking_access");
  }, []);

  return (
    <div className={styles.parking}>
      <TitleSource>
        <div style={{ display: "flex" }}>{t("Parking", { ns: "csv" })}</div>
      </TitleSource>
      <div
        style={{
          padding: "0 16px",
          display: "flex",
          flexDirection: "column",
          gap: 16,
          height: "100%",
        }}
      >
        <Tabs defaultActiveKey="1" style={{ height: "100%" }} destroyInactiveTabPane={true}>
          <Tabs.TabPane tab={t("parking.MyBookings")} key="1">
            <div style={{ height: "100%", padding: `0` }}>
              <ParkingTabBooking
                setShowManageVehiclesModal={setShowManageVehiclesModal}
                setShowCreateOrModifyBookingModal={setShowCreateOrModifyBookingModal}
                setSelectedDate={setSelectedDate}
                selectedDate={selectedDate}
                setSelectedBooking={setSelectedBooking}
                setShowDeleteBookingModal={setShowDeleteBookingModal}
              />
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("parking.BookingHistory")} key="2">
            <div style={{ height: "100%", padding: `0 ${screens.xl ? "8%" : 0}` }}>
              <ParkingTabHistory
                setShowManageVehiclesModal={setShowManageVehiclesModal}
                setShowCreateOrModifyBookingModal={setShowCreateOrModifyBookingModal}
              />
            </div>
          </Tabs.TabPane>
        </Tabs>
      </div>
      {/* ManageVehicles */}
      {showManageVehiclesModal && (
        <ManageVehiclesModal
          showManageVehiclesModal={showManageVehiclesModal}
          setShowManageVehiclesModal={setShowManageVehiclesModal}
        />
      )}
      {/* Create or modify booking modal */}
      {showCreateOrModifyBookingModal && (
        <CreateOrModifyBookingModal
          showCreateOrModifyBookingModal={showCreateOrModifyBookingModal}
          setShowCreateOrModifyBookingModal={setShowCreateOrModifyBookingModal}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          selectedBooking={selectedBooking}
          setSelectedBooking={setSelectedBooking}
        />
      )}
      {/* Delete booking modal */}
      {showDeleteBookingModal && (
        <DeleteBookingModal
          setShowDeleteBookingModal={setShowDeleteBookingModal}
          showDeleteBookingModal={showDeleteBookingModal}
          selectedMonth={selectedMonth}
          selectedBooking={selectedBooking}
          setSelectedBooking={setSelectedBooking}
        />
      )}
    </div>
  );
};

export default Parking;
