import { useKeycloak } from "@react-keycloak/web";
import { Breadcrumb, Button, Card, Image, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink, useParams } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../hook/useDesignTokens";
import { getNews } from "../../../services/redux/services/NewsWS";
import Glyph from "../../Common/Glyph/Glyph";
import styles from "./News.module.less";
import { isNil } from "ramda";
import { getAnalytics, logEvent } from "firebase/analytics";
import app from "../../../services/api/Firebase/Firebase";

const analytics = getAnalytics(app);

const TopicsList = () => {
  const { t, i18n } = useTranslation();
  const { colors, size } = useDesignTokens();
  const { keycloak } = useKeycloak();
  const { sectionId, topicId } = useParams();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userWS.userData);
  const topics = useSelector((state) => state.newsWS.newsByTopics.topics);
  const news = useSelector((state) => state.newsWS.news);

  const [moreNews, setMoreNews] = useState();

  useEffect(() => {
    dispatch(
      getNews({
        locale: i18n.language.split("-")[0],
        topic: topicId,
        date: moment().toISOString(),
        init: true,
        viewId: sectionId,
      }),
    );
  }, [dispatch, i18n.language, sectionId, topicId]);

  return (
    <>
      <Breadcrumb separator=">" style={{ marginBottom: "16px" }}>
        <Breadcrumb.Item>
          <Link to={`/news/${sectionId}`}>
            {t(userData?.views?.find((view) => view.id === parseInt(sectionId))?.title, {
              ns: "csv",
            })}
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ backgroundColor: "transparent" }}>
          {topics?.find((topic) => topic.id === parseInt(topicId))?.label}
        </Breadcrumb.Item>
      </Breadcrumb>
      {news?.map((n, index) => (
        <Card bodyStyle={{ display: "flex", flexFlow: "row", minHeight: "120px" }} key={index}>
          {!isNil(n?.header) && (
            <div className={styles["pictures"]}>
              <Image preview={false} src={`${n?.header?.url}?access_token=${keycloak.token}`} />
            </div>
          )}

          <div
            style={{
              display: "flex",
              flexFlow: "column",
              marginLeft: "16px",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div
              style={{
                display: "flex",
                flexFlow: "row",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography.Text type="secondary" style={{ fontSize: size.s }}>
                <Glyph name="schedule" style={{ marginRight: "8px" }} />
                {moment(n?.publishedAt).format("L")}
              </Typography.Text>
              <div style={{ display: "flex", flexFlow: "row", alignItems: "center" }}>
                {n?.authorizeComment && (
                  <>
                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: size.s, marginRight: "8px" }}
                    >
                      <Glyph name="comment" style={{ marginRight: "4px" }} />
                      {n?.nbComments}
                    </Typography.Text>

                    <Typography.Text
                      type="secondary"
                      style={{ fontSize: size.s, marginRight: "8px" }}
                    >
                      {n?.isLiked ? (
                        <Glyph
                          name="favorite"
                          style={{ marginRight: "4px", color: colors?.error_light }}
                        />
                      ) : (
                        <Glyph name="favorite_border" style={{ marginRight: "4px" }} />
                      )}

                      {n?.nbLikes}
                    </Typography.Text>
                    <Typography.Text type="secondary" style={{ fontSize: size.s }}>
                      <Glyph name="bar_chart" style={{ marginRight: "4px" }} />
                      {n?.nbViews}
                    </Typography.Text>
                  </>
                )}
              </div>
            </div>

            <Typography.Text strong>
              {n?.title}{" "}
              <NavLink
                to={{ pathname: `${topicId}/${n?.id}` }}
                style={{ textDecoration: "underline" }}
                onClick={() => {
                  logEvent(analytics, `news_select_details`, {
                    from: "topics",
                    news_name: n?.title,
                  });
                }}
              >
                {t("news.read.article")}
              </NavLink>
            </Typography.Text>
            <div style={{ display: "flex", flexFlow: "row" }}>
              {topics
                .filter((t) => n.topics.includes(t?.id))
                .map((t, index) => (
                  <Typography.Text type="secondary" style={{ marginRight: "8px" }} key={index}>
                    {t.label}
                  </Typography.Text>
                ))}
            </div>
          </div>
        </Card>
      ))}
      {(moreNews?.news.length === 12 || isNil(moreNews?.news)) && news?.length === 12 && (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
          <Button
            ghost
            icon={<Glyph name="add" />}
            style={{ width: "300px" }}
            onClick={() => {
              dispatch(
                getNews({
                  locale: i18n.language.split("-")[0],
                  topic: topicId,
                  date: moment(news.slice(-1)[0]?.publishedAt).toISOString(),
                  viewId: sectionId,
                }),
              )
                .unwrap()
                .then((data) => setMoreNews(data));
            }}
          >
            {t("SeeMore")}
          </Button>
        </div>
      )}
    </>
  );
};

export default TopicsList;
