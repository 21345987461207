import colors from "@tokens/web/colors.json";
import shadows from "@tokens/web/shadows.json";
import symbols from "@tokens/web/symbols.json";
import size from "../styles/size.json";

const useDesignTokens = () => {
  return { colors, shadows, symbols, size };
};

export default useDesignTokens;
