import { Form, Select } from "antd";
import styles from "./SelectHours.module.less";
import moment from "moment";

const SelectHours = ({ diff, date, nameProps, hour }) => {
  const hours = Array.from({ length: 24 }, (_, i) => i);
  const minutes = Array.from({ length: 4 }, (_, i) => i * diff);
  const now = date;
  const hourNow = hour;

  const options = hours
    .map((hours) => {
      return minutes.map((minute, index) => {
        const hoursFormat = hours.toString().padStart(2, "0");
        const minuteFormat = minute.toString().padStart(2, "0");
        const label = `${hoursFormat}:${minuteFormat}`;
        const value = `${hoursFormat}:${minuteFormat}`;
        return { value: value, label: label };
      });
    })
    .flat();

  const filteredOptions = options.filter((option) => {
    const [hour, minute] = option.value.split(":").map(Number);
    const optionTime = moment().hours(hour).minutes(minute);
    return hourNow ? optionTime.isAfter(hourNow) : optionTime.isAfter(moment());
  });

  return (
    <Form.Item noStyle name={[nameProps]} trigger="onSelect">
      <Select
        showSearch
        className={styles["selectHour"]}
        classnameprefix={styles["selectHour"]}
        options={moment(now).isAfter(moment(), "date") ? options : filteredOptions}
        isclearable="false"
        isrtl="false"
        issearchable="false"
        size="middle"
      />
    </Form.Item>
  );
};
export default SelectHours;
