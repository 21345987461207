import { Button, message } from "antd";
import React from "react";
import { useDispatch } from "react-redux";

// import redux
import { setBlinds } from "../../../../../services/redux/services/ConfortWS";
import useSelectedRoom from "../../../../../services/redux/useSelectedRoom";

// import component
import Glyph from "../../../../Common/Glyph/Glyph";

// import css
import styles from "../SettingsUI.module.less";

import { useTranslation } from "react-i18next";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../services/api/Firebase/Firebase";
import useDesignTokens from "../../../../../hook/useDesignTokens";

const BlindUpDown = ({ setIsLoading, setStatus, zone, selectedRoomNomap }) => {
  const { t } = useTranslation();
  const selectedRoom = useSelectedRoom();
  const dispatch = useDispatch();
  const { size } = useDesignTokens();

  const dispatchBlind = (command) => {
    setIsLoading(true);
    logEvent(analytics, "smart_control_command_blind", {
      zone: zone !== undefined ? "zone" : "total",
      type_blind: "up&down",
    });
    dispatch(
      setBlinds({
        roomId: selectedRoomNomap?.id || selectedRoom?.id,
        zone: zone ? parseInt(zone.id) : 0,
        command: command,
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .catch(() => {
        message.error(t("ErrorSetBlind"), 5);
        setStatus("error");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const BlindStop = () => {
    setIsLoading(true);
    logEvent(analytics, "smart_control_command_blind", {
      zone: zone !== undefined ? "zone" : "total",
      type_blind: "up&down",
    });
    dispatch(
      setBlinds({
        roomId: selectedRoomNomap?.id || selectedRoom?.id,
        zone: zone ? parseInt(zone.id) : 0,
        command: "BS_STOP",
      }),
    )
      .unwrap()
      .then(() => {
        setStatus("success");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .catch(() => {
        message.error(t("ErrorSetBlind"), 5);
        setStatus("error");
        setTimeout(() => {
          setStatus(null);
        }, 2000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
      <Button
        icon={<Glyph name="power_settings_new" />}
        size="default"
        type="danger"
        onClick={() => {
          BlindStop();
        }}
        style={{
          borderRadius: "100%",
          marginRight: "16px",
          padding: "0",
          height: "48px",
          width: "48px",
          minWidth: "48px",
          fontWeight: "bold",
          marginTop: "15px",
        }}
      ></Button>
      <div className={styles["SettingsUI__content"]}>
        <Button
          onClick={() => {
            dispatchBlind("BS_DW");
          }}
          type="ghost"
          style={{ width: "120px", padding: "0", marginRight: "8px" }}
          icon={
            <Glyph
              name="keyboard_arrow_down"
              style={{ fontSize: size.xxxxl, fontWeight: "bold" }}
            />
          }
        ></Button>
        <Button
          onClick={() => {
            dispatchBlind("BS_UP");
          }}
          type="ghost"
          style={{ width: "120px", padding: "0" }}
          icon={
            <Glyph name="keyboard_arrow_up" style={{ fontSize: size.xxxxl, fontWeight: "bold" }} />
          }
        ></Button>
      </div>
    </div>
  );
};

export default BlindUpDown;
