export const Translations = (t, you) => {
  return {
    /*CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE: t(
      "CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE",
    ),
    MESSAGE_STATUS__YESTERDAY: t("MESSAGE_STATUS__YESTERDAY"),
    CHANNEL__MESSAGE_LIST__NOTIFICATION__ON: t("CHANNEL__MESSAGE_LIST__NOTIFICATION__ON"),
    CHANNEL_PREVIEW_MOBILE_LEAVE: t("CHANNEL_PREVIEW_MOBILE_LEAVE"),
    CHANNEL_SETTING__HEADER__TITLE: t("CHANNEL_SETTING__HEADER__TITLE"),
    CHANNEL_SETTING__PROFILE__EDIT: t("CHANNEL_SETTING__PROFILE__EDIT"),
    CHANNEL_SETTING__MEMBERS__TITLE: t("CHANNEL_SETTING__MEMBERS__TITLE"),
    CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS: t("CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS"),
    CHANNEL_SETTING__MEMBERS__INVITE_MEMBER: t("CHANNEL_SETTING__MEMBERS__INVITE_MEMBER"),
    CHANNEL_SETTING__MEMBERS__YOU: t("CHANNEL_SETTING__MEMBERS__YOU"),
    CHANNEL_SETTING__MEMBERS__SELECT_TITLE: t("CHANNEL_SETTING__MEMBERS__SELECT_TITLE"),
    CHANNEL_SETTING__MEMBERS__OPERATOR: t("CHANNEL_SETTING__MEMBERS__OPERATOR"),
    CHANNEL_SETTING__LEAVE_CHANNEL__TITLE: t("CHANNEL_SETTING__LEAVE_CHANNEL__TITLE"),
    CHANNEL_SETTING__OPERATORS__TITLE: t("CHANNEL_SETTING__OPERATORS__TITLE"),
    CHANNEL_SETTING__OPERATORS__TITLE_ALL: t("CHANNEL_SETTING__OPERATORS__TITLE_ALL"),
    CHANNEL_SETTING__OPERATORS__TITLE_ADD: t("CHANNEL_SETTING__OPERATORS__TITLE_ADD"),
    CHANNEL_SETTING__OPERATORS__ADD_BUTTON: t("CHANNEL_SETTING__OPERATORS__ADD_BUTTON"),
    CHANNEL_SETTING__MUTED_MEMBERS__TITLE: t("CHANNEL_SETTING__MUTED_MEMBERS__TITLE"),
    CHANNEL_SETTING__MUTED_MEMBERS__TITLE_ALL: t("CHANNEL_SETTING__MUTED_MEMBERS__TITLE_ALL"),
    CHANNEL_SETTING__NO_UNMUTED: t("CHANNEL_SETTING__NO_UNMUTED"),
    CHANNEL_SETTING__BANNED_MEMBERS__TITLE: t("CHANNEL_SETTING__BANNED_MEMBERS__TITLE"),
    CHANNEL_SETTING__FREEZE_CHANNEL: t("CHANNEL_SETTING__FREEZE_CHANNEL"),
    CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR: t(
      "CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR",
    ),
    CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR: t(
      "CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR",
    ),
    CHANNEL_SETTING__MODERATION__MUTE: t("CHANNEL_SETTING__MODERATION__MUTE"),
    CHANNEL_SETTING__MODERATION__UNMUTE: t("CHANNEL_SETTING__MODERATION__UNMUTE"),
    CHANNEL_SETTING__MODERATION__BAN: t("CHANNEL_SETTING__MODERATION__BAN"),
    CHANNEL_SETTING__MODERATION__UNBAN: t("CHANNEL_SETTING__MODERATION__UNBAN"),
    CHANNEL_SETTING__MODERATION__EMPTY_BAN: t("CHANNEL_SETTING__MODERATION__EMPTY_BAN"),
    CHANNEL_SETTING__MODERATION__ALL_BAN: t("CHANNEL_SETTING__MODERATION__ALL_BAN"),
    OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS: t(
      "OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS",
    ),
    OPEN_CHANNEL_CONVERSATION__SELECT_PARTICIPANTS: t(
      "OPEN_CHANNEL_CONVERSATION__SELECT_PARTICIPANTS",
    ),
    OPEN_CHANNEL_LIST__TITLE: t("OPEN_CHANNEL_LIST__TITLE"),
    CREATE_OPEN_CHANNEL_LIST__TITLE: t("CREATE_OPEN_CHANNEL_LIST__TITLE"),
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION: t(
      "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION",
    ),
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_UPLOAD: t(
      "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_UPLOAD",
    ),
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_SECTION: t(
      "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_SECTION",
    ),
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_PLACE_HOLDER: t(
      "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_PLACE_HOLDER",
    ),
    CREATE_OPEN_CHANNEL_LIST__SUBMIT: t("CREATE_OPEN_CHANNEL_LIST__SUBMIT"),
    OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE: t("OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE"),
    OPEN_CHANNEL_SETTINGS__OPERATOR_URL: t("OPEN_CHANNEL_SETTINGS__OPERATOR_URL"),
    OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE: t(
      "OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE",
    ),
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL: t("OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL"),
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE: t("OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE"),
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_CONTEXT: t(
      "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_CONTEXT",
    ),
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT: t("OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT"),
    OPEN_CHANNEL_SETTINGS__OPERATORS_TITLE: t("OPEN_CHANNEL_SETTINGS__OPERATORS_TITLE"),
    OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ADD: t("OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ADD"),
    OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ALL: t("OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ALL"),
    OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE: t("OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE"),
    OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE_ALL: t(
      "OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE_ALL",
    ),
    OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__NO_ONE: t("OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__NO_ONE"),
    OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE: t("OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE"),
    OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE_ALL: t(
      "OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE_ALL",
    ),
    OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__NO_ONE: t(
      "OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__NO_ONE",
    ),
    OPEN_CHANNEL_SETTINGS__MEMBERS__YOU: t("OPEN_CHANNEL_SETTINGS__MEMBERS__YOU"),
    OPEN_CHANNEL_SETTINGS__MEMBERS__OPERATOR: t("OPEN_CHANNEL_SETTINGS__MEMBERS__OPERATOR"),
    OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE: t("OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE"),
    OPEN_CHANNEL_SETTINGS__EMPTY_LIST: t("OPEN_CHANNEL_SETTINGS__EMPTY_LIST"),
    OPEN_CHANNEL_SETTINGS__SEE_ALL: t("OPEN_CHANNEL_SETTINGS__SEE_ALL"),
    OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE: t(
      "OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE",
    ),
    OPEN_CHANNEL_SETTINGS__NO_TITLE: t("OPEN_CHANNEL_SETTINGS__NO_TITLE"),
    OPEN_CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR: t(
      "OPEN_CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR",
    ),
    OPEN_CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR: t(
      "OPEN_CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR",
    ),
    OPEN_CHANNEL_SETTING__MODERATION__MUTE: t("OPEN_CHANNEL_SETTING__MODERATION__MUTE"),
    OPEN_CHANNEL_SETTING__MODERATION__UNMUTE: t("OPEN_CHANNEL_SETTING__MODERATION__UNMUTE"),
    OPEN_CHANNEL_SETTING__MODERATION__BAN: t("OPEN_CHANNEL_SETTING__MODERATION__BAN"),
    OPEN_CHANNEL_SETTING__MODERATION__UNBAN: t("OPEN_CHANNEL_SETTING__MODERATION__UNBAN"),
    TRYING_TO_CONNECT: t("TRYING_TO_CONNECT"),
    TYPING_INDICATOR__IS_TYPING: t("TYPING_INDICATOR__IS_TYPING"),
    TYPING_INDICATOR__AND: t("TYPING_INDICATOR__AND"),
    TYPING_INDICATOR__ARE_TYPING: t("TYPING_INDICATOR__ARE_TYPING"),
    TYPING_INDICATOR__MULTIPLE_TYPING: t("TYPING_INDICATOR__MULTIPLE_TYPING"),
    CHANNEL_FROZEN: t("CHANNEL_FROZEN"),
    PLACE_HOLDER__NO_CHANNEL: t("PLACE_HOLDER__NO_CHANNEL"),
    PLACE_HOLDER__WRONG: t("PLACE_HOLDER__WRONG"),
    PLACE_HOLDER__RETRY_TO_CONNECT: t("PLACE_HOLDER__RETRY_TO_CONNECT"),
    PLACE_HOLDER__NO_MESSAGES: t("PLACE_HOLDER__NO_MESSAGES"),
    TOOLTIP__AND_YOU: t("TOOLTIP__AND_YOU"),*/
    MESSAGE_STATUS__YESTERDAY: t("MESSAGE_STATUS__YESTERDAY"),
    CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE: t(
      "CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE",
    ),
    CHANNEL__MESSAGE_LIST__NOTIFICATION__ON: t("CHANNEL__MESSAGE_LIST__NOTIFICATION__ON"),
    CHANNEL_PREVIEW_MOBILE_LEAVE: t("CHANNEL_PREVIEW_MOBILE_LEAVE"),
    CHANNEL_SETTING__HEADER__TITLE: t("CHANNEL_SETTING__HEADER__TITLE"),
    CHANNEL_SETTING__PROFILE__EDIT: t("CHANNEL_SETTING__PROFILE__EDIT"),
    CHANNEL_SETTING__MEMBER__TITLE: t("CHANNEL_SETTING__MEMBER__TITLE"),
    CHANNEL_SETTING__MEMBERS__TITLE: t("CHANNEL_SETTING__MEMBERS__TITLE"),
    CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS: t("CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS"),
    CHANNEL_SETTING__MEMBERS__INVITE_MEMBER: t("CHANNEL_SETTING__MEMBERS__INVITE_MEMBER"),
    CHANNEL_SETTING__MEMBERS__YOU: t("CHANNEL_SETTING__MEMBERS__YOU"),
    CHANNEL_SETTING__MEMBERS__SELECT_TITLE: t("CHANNEL_SETTING__MEMBERS__SELECT_TITLE"),
    CHANNEL_SETTING__MEMBERS__OPERATOR: t("CHANNEL_SETTING__MEMBERS__OPERATOR"),
    CHANNEL_SETTING__LEAVE_CHANNEL__TITLE: t("CHANNEL_SETTING__LEAVE_CHANNEL__TITLE"),
    CHANNEL_SETTING__OPERATORS__TITLE: t("CHANNEL_SETTING__OPERATORS__TITLE"),
    CHANNEL_SETTING__OPERATORS__TITLE_ALL: t("CHANNEL_SETTING__OPERATORS__TITLE_ALL"),
    CHANNEL_SETTING__OPERATORS__TITLE_ADD: t("CHANNEL_SETTING__OPERATORS__TITLE_ADD"),
    CHANNEL_SETTING__OPERATORS__ADD_BUTTON: t("CHANNEL_SETTING__OPERATORS__ADD_BUTTON"),
    CHANNEL_SETTING__MUTED_MEMBERS__TITLE: t("CHANNEL_SETTING__MUTED_MEMBERS__TITLE"),
    CHANNEL_SETTING__MUTED_MEMBERS__TITLE_ALL: t("CHANNEL_SETTING__MUTED_MEMBERS__TITLE_ALL"),
    CHANNEL_SETTING__NO_UNMUTED: t("CHANNEL_SETTING__NO_UNMUTED"),
    CHANNEL_SETTING__BANNED_MEMBERS__TITLE: t("CHANNEL_SETTING__BANNED_MEMBERS__TITLE"),
    CHANNEL_SETTING__FREEZE_CHANNEL: t("CHANNEL_SETTING__FREEZE_CHANNEL"),
    CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR: t(
      "CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR",
    ),
    CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR: t(
      "CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR",
    ),
    CHANNEL_SETTING__MODERATION__MUTE: t("CHANNEL_SETTING__MODERATION__MUTE"),
    CHANNEL_SETTING__MODERATION__UNMUTE: t("CHANNEL_SETTING__MODERATION__UNMUTE"),
    CHANNEL_SETTING__MODERATION__BAN: t("CHANNEL_SETTING__MODERATION__BAN"),
    CHANNEL_SETTING__MODERATION__UNBAN: t("CHANNEL_SETTING__MODERATION__UNBAN"),
    CHANNEL_SETTING__MODERATION__EMPTY_BAN: t("CHANNEL_SETTING__MODERATION__EMPTY_BAN"),
    CHANNEL_SETTING__MODERATION__ALL_BAN: t("CHANNEL_SETTING__MODERATION__ALL_BAN"),
    OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS: t(
      "OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS",
    ),
    OPEN_CHANNEL_CONVERSATION__SELECT_PARTICIPANTS: t(
      "OPEN_CHANNEL_CONVERSATION__SELECT_PARTICIPANTS",
    ),
    OPEN_CHANNEL_LIST__TITLE: t("OPEN_CHANNEL_LIST__TITLE"),
    CREATE_OPEN_CHANNEL_LIST__TITLE: t("CREATE_OPEN_CHANNEL_LIST__TITLE"),
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION: t(
      "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION",
    ),
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_UPLOAD: t(
      "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_UPLOAD",
    ),
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_SECTION: t(
      "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_SECTION",
    ),
    CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_PLACE_HOLDER: t(
      "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_PLACE_HOLDER",
    ),
    CREATE_OPEN_CHANNEL_LIST__SUBMIT: t("CREATE_OPEN_CHANNEL_LIST__SUBMIT"),
    OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE: t("OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE"),
    OPEN_CHANNEL_SETTINGS__OPERATOR_URL: t("OPEN_CHANNEL_SETTINGS__OPERATOR_URL"),
    OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE: t(
      "OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE",
    ),
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL: t("OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL"),
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE: t("OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE"),
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_CONTEXT: t(
      "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_CONTEXT",
    ),
    OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT: t("OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT"),
    OPEN_CHANNEL_SETTINGS__OPERATORS_TITLE: t("OPEN_CHANNEL_SETTINGS__OPERATORS_TITLE"),
    OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ADD: t("OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ADD"),
    OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ALL: t("OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ALL"),
    OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE: t("OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE"),
    OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE_ALL: t(
      "OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE_ALL",
    ),
    OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__NO_ONE: t("OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__NO_ONE"),
    OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE: t("OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE"),
    OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE_ALL: t(
      "OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE_ALL",
    ),
    OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__NO_ONE: t(
      "OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__NO_ONE",
    ),
    OPEN_CHANNEL_SETTINGS__MEMBERS__YOU: t("OPEN_CHANNEL_SETTINGS__MEMBERS__YOU"),
    OPEN_CHANNEL_SETTINGS__MEMBERS__OPERATOR: t("OPEN_CHANNEL_SETTINGS__MEMBERS__OPERATOR"),
    OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE: t("OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE"),
    OPEN_CHANNEL_SETTINGS__EMPTY_LIST: t("OPEN_CHANNEL_SETTINGS__EMPTY_LIST"),
    OPEN_CHANNEL_SETTINGS__SEE_ALL: t("OPEN_CHANNEL_SETTINGS__SEE_ALL"),
    OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE: t(
      "OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE",
    ),
    OPEN_CHANNEL_SETTINGS__NO_TITLE: t("OPEN_CHANNEL_SETTINGS__NO_TITLE"),
    OPEN_CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR: t(
      "OPEN_CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR",
    ),
    OPEN_CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR: t(
      "OPEN_CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR",
    ),
    OPEN_CHANNEL_SETTING__MODERATION__MUTE: t("OPEN_CHANNEL_SETTING__MODERATION__MUTE"),
    OPEN_CHANNEL_SETTING__MODERATION__UNMUTE: t("OPEN_CHANNEL_SETTING__MODERATION__UNMUTE"),
    OPEN_CHANNEL_SETTING__MODERATION__BAN: t("OPEN_CHANNEL_SETTING__MODERATION__BAN"),
    OPEN_CHANNEL_SETTING__MODERATION__UNBAN: t("OPEN_CHANNEL_SETTING__MODERATION__UNBAN"),
    TRYING_TO_CONNECT: t("TRYING_TO_CONNECT"),
    TYPING_INDICATOR__IS_TYPING: t("TYPING_INDICATOR__IS_TYPING"),
    TYPING_INDICATOR__AND: t("TYPING_INDICATOR__AND"),
    TYPING_INDICATOR__ARE_TYPING: t("TYPING_INDICATOR__ARE_TYPING"),
    TYPING_INDICATOR__MULTIPLE_TYPING: t("TYPING_INDICATOR__MULTIPLE_TYPING"),
    CHANNEL_FROZEN: t("CHANNEL_FROZEN"),
    PLACE_HOLDER__NO_CHANNEL: t("PLACE_HOLDER__NO_CHANNEL"),
    PLACE_HOLDER__WRONG: t("PLACE_HOLDER__WRONG"),
    PLACE_HOLDER__RETRY_TO_CONNECT: t("PLACE_HOLDER__RETRY_TO_CONNECT"),
    PLACE_HOLDER__NO_MESSAGES: t("PLACE_HOLDER__NO_MESSAGES"),
    TOOLTIP__AND_YOU: t("TOOLTIP__AND_YOU"),
    TOOLTIP__YOU: t("TOOLTIP__YOU"),
    TOOLTIP__UNKNOWN_USER: t("TOOLTIP__UNKNOWN_USER"),
    UNKNOWN__UNKNOWN_MESSAGE_TYPE: t("UNKNOWN__UNKNOWN_MESSAGE_TYPE"),
    UNKNOWN__CANNOT_READ_MESSAGE: t("UNKNOWN__CANNOT_READ_MESSAGE"),
    MESSAGE_EDITED: t("MESSAGE_EDITED"),
    MODAL__DELETE_MESSAGE__TITLE: t("MODAL__DELETE_MESSAGE__TITLE"),
    MODAL__CHANNEL_INFORMATION__TITLE: t("MODAL__CHANNEL_INFORMATION__TITLE"),
    MODAL__CHANNEL_INFORMATION__CHANNEL_IMAGE: t("MODAL__CHANNEL_INFORMATION__CHANNEL_IMAGE"),
    MODAL__CHANNEL_INFORMATION__UPLOAD: t("MODAL__CHANNEL_INFORMATION__UPLOAD"),
    MODAL__CHANNEL_INFORMATION__CHANNEL_NAME: t("MODAL__CHANNEL_INFORMATION__CHANNEL_NAME"),
    MODAL__CHANNEL_INFORMATION__INPUT__PLACE_HOLDER: t(
      "MODAL__CHANNEL_INFORMATION__INPUT__PLACE_HOLDER",
    ),
    MODAL__INVITE_MEMBER__TITLE: t("MODAL__INVITE_MEMBER__TITLE"),
    MODAL__INVITE_MEMBER__SELECTED: t("MODAL__INVITE_MEMBER__SELECTED"),
    MODAL__CHOOSE_CHANNEL_TYPE__TITLE: t("MODAL__CHOOSE_CHANNEL_TYPE__TITLE"),
    MODAL__CHOOSE_CHANNEL_TYPE__GROUP: t("MODAL__CHOOSE_CHANNEL_TYPE__GROUP"),
    MODAL__CHOOSE_CHANNEL_TYPE__SUPER_GROUP: t("MODAL__CHOOSE_CHANNEL_TYPE__SUPER_GROUP"),
    MODAL__CHOOSE_CHANNEL_TYPE__BROADCAST: t("MODAL__CHOOSE_CHANNEL_TYPE__BROADCAST"),
    MODAL__CREATE_CHANNEL__TITLE: t("MODAL__CREATE_CHANNEL__TITLE"),
    MODAL__CREATE_CHANNEL__GROUP: t("MODAL__CREATE_CHANNEL__GROUP"),
    MODAL__CREATE_CHANNEL__SUPER: t("MODAL__CREATE_CHANNEL__SUPER"),
    MODAL__CREATE_CHANNEL__BROADCAST: t("MODAL__CREATE_CHANNEL__BROADCAST"),
    MODAL__CREATE_CHANNEL__SELECTED: t("MODAL__CREATE_CHANNEL__SELECTED"),
    MODAL__LEAVE_CHANNEL__TITLE: t("MODAL__LEAVE_CHANNEL__TITLE"),
    MODAL__LEAVE_CHANNEL__FOOTER: t("MODAL__LEAVE_CHANNEL__FOOTER"),
    MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_MUTED: t(
      "MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_MUTED",
    ),
    MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_FROZEN: t(
      "MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_FROZEN",
    ),
    USER_PROFILE__MESSAGE: t("USER_PROFILE__MESSAGE"),
    USER_PROFILE__USER_ID: t("USER_PROFILE__USER_ID"),
    EDIT_PROFILE__TITLE: t("EDIT_PROFILE__TITLE"),
    EDIT_PROFILE__IMAGE_LABEL: t("EDIT_PROFILE__IMAGE_LABEL"),
    EDIT_PROFILE__IMAGE_UPLOAD: t("EDIT_PROFILE__IMAGE_UPLOAD"),
    EDIT_PROFILE__NICKNAME_LABEL: t("EDIT_PROFILE__NICKNAME_LABEL"),
    EDIT_PROFILE__NICKNAME_PLACEHOLDER: t("EDIT_PROFILE__NICKNAME_PLACEHOLDER"),
    EDIT_PROFILE__USERID_LABEL: t("EDIT_PROFILE__USERID_LABEL"),
    EDIT_PROFILE__THEME_LABEL: t("EDIT_PROFILE__THEME_LABEL"),
    MESSAGE_INPUT__PLACE_HOLDER: t("MESSAGE_INPUT__PLACE_HOLDER"),
    MESSAGE_INPUT__PLACE_HOLDER__DISABLED: t("MESSAGE_INPUT__PLACE_HOLDER__DISABLED"),
    MESSAGE_INPUT__PLACE_HOLDER__MUTED: t("MESSAGE_INPUT__PLACE_HOLDER__MUTED"),
    MESSAGE_INPUT__PLACE_HOLDER__MUTED_SHORT: t("MESSAGE_INPUT__PLACE_HOLDER__MUTED_SHORT"),
    MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER: t("MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER"),
    BUTTON__CANCEL: t("BUTTON__CANCEL"),
    BUTTON__DELETE: t("BUTTON__DELETE"),
    BUTTON__SAVE: t("BUTTON__SAVE"),
    BUTTON__CREATE: t("BUTTON__CREATE"),
    BUTTON__INVITE: t("BUTTON__INVITE"),
    BUTTON__OK: t("BUTTON__OK"),
    BADGE__OVER: t("BADGE__OVER"),
    NO_TITLE: t("NO_TITLE"),
    NO_NAME: t("NO_NAME"),
    NO_MEMBERS: t("NO_MEMBERS"),
    MESSAGE_MENU__COPY: t("MESSAGE_MENU__COPY"),
    MESSAGE_MENU__REPLY: t("MESSAGE_MENU__REPLY"),
    MESSAGE_MENU__THREAD: t("MESSAGE_MENU__THREAD"),
    MESSAGE_MENU__OPEN_IN_CHANNEL: t("MESSAGE_MENU__OPEN_IN_CHANNEL"),
    MESSAGE_MENU__EDIT: t("MESSAGE_MENU__EDIT"),
    MESSAGE_MENU__RESEND: t("MESSAGE_MENU__RESEND"),
    MESSAGE_MENU__DELETE: t("MESSAGE_MENU__DELETE"),
    MESSAGE_MENU__SAVE: t("MESSAGE_MENU__SAVE"),
    SEARCH: t("SEARCH"),
    SEARCH_IN_CHANNEL: t("SEARCH_IN_CHANNEL"),
    SEARCH_IN: t("SEARCH_IN"),
    SEARCHING: t("SEARCHING"),
    NO_SEARCHED_MESSAGE: t("NO_SEARCHED_MESSAGE"),
    QUOTE_MESSAGE_INPUT__REPLY_TO: t("QUOTE_MESSAGE_INPUT__REPLY_TO"),
    QUOTE_MESSAGE_INPUT__FILE_TYPE_IMAGE: t("QUOTE_MESSAGE_INPUT__FILE_TYPE_IMAGE"),
    QUOTE_MESSAGE_INPUT__FILE_TYPE_GIF: t("QUOTE_MESSAGE_INPUT__FILE_TYPE_GIF"),
    QUOTE_MESSAGE_INPUT__FILE_TYPE__VIDEO: t("QUOTE_MESSAGE_INPUT__FILE_TYPE__VIDEO"),
    QUOTED_MESSAGE__REPLIED_TO: t("QUOTED_MESSAGE__REPLIED_TO"),
    QUOTED_MESSAGE__CURRENT_USER: you, //t("QUOTED_MESSAGE__CURRENT_USER")
    QUOTED_MESSAGE__UNAVAILABLE: t("QUOTED_MESSAGE__UNAVAILABLE"),
    THREAD__HEADER_TITLE: t("THREAD__HEADER_TITLE"),
    CHANNEL__THREAD_REPLY: t("CHANNEL__THREAD_REPLY"),
    CHANNEL__THREAD_REPLIES: t("CHANNEL__THREAD_REPLIES"),
    CHANNEL__THREAD_OVER_MAX: t("CHANNEL__THREAD_OVER_MAX"),
    THREAD__THREAD_REPLY: t("THREAD__THREAD_REPLY"),
    THREAD__THREAD_REPLIES: t("THREAD__THREAD_REPLIES"),
    THREAD__INPUT__REPLY_TO_THREAD: t("THREAD__INPUT__REPLY_TO_THREAD"),
    THREAD__INPUT__REPLY_IN_THREAD: t("THREAD__INPUT__REPLY_IN_THREAD"),
    MENTION_NAME__NO_NAME: t("MENTION_NAME__NO_NAME"),
    MENTION_COUNT__OVER_LIMIT: t("MENTION_COUNT__OVER_LIMIT"),
    UI__FILE_VIEWER__UNSUPPORT: t("UI__FILE_VIEWER__UNSUPPORT"),
    VOICE_MESSAGE: t("VOICE_MESSAGE"),
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GIF: t("CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GIF"),
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_PHOTO: t("CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_PHOTO"),
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VIDEO: t("CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VIDEO"),
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_AUDIO: t("CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_AUDIO"),
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VOICE_MESSAGE: t(
      "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VOICE_MESSAGE",
    ),
    CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GENERAL: t(
      "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GENERAL",
    ),
  };
};
