import { Button, Card, Grid, Input, Space, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { FixedSizeList as List } from "react-window";
import useDesignTokens from "../../../hook/useDesignTokens";
import useDidUpdateEffect from "../../../hook/useDidUpdate";
import Glyph from "../../Common/Glyph/Glyph";
import useMap from "../../Common/Map/useMap";
import { TitleSource } from "../../Common/Teleporters/Title";
import Tile from "../../Common/Tile/Tile";
import styles from "./GuidMe.module.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";
import { setFilter } from "../../../services/redux/services/FastbookingWS";

const GUTTER_SIZE = 8;

const Itinerary = () => {
  const {
    startItinerary,
    stopItinerary,
    selectedOnMap,
    centerOnPlaceId,
    selectedFloor,
    setSelectecdFloorId,
  } = useMap();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let location = useLocation();
  const { colors, size } = useDesignTokens();
  const screens = Grid.useBreakpoint();

  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);

  const [focusingStart, setFocusingStart] = useState(true);
  const [inputSearchFrom, setInputSearchFrom] = useState("");
  const [inputSearchTo, setInputSearchTo] = useState(location?.state?.selectedRoom?.title || "");
  const [from, setFrom] = useState();
  const [to, setTo] = useState(location?.state?.selectedRoom);

  const [showItinerary, setShowItinerary] = useState(false);
  const [itineraryDistance, setItineraryDistance] = useState();
  const [itineraryDuration, setItineraryDuration] = useState(null);

  const initialRoomId = location?.state?.selectedRoom?.map?.findAndOrder?.placeId;
  useEffect(() => {
    centerOnPlaceId(initialRoomId, false);
  }, [centerOnPlaceId, initialRoomId]);

  useEffect(() => {
    return () => {
      stopItinerary();
    };
  }, [stopItinerary]);

  useDidUpdateEffect(() => {
    if (selectedFloor?.id)
      dispatch(
        setFilter({
          ...filters,
          floor: selectedFloor?.id,
        }),
      );
  }, [dispatch, selectedFloor?.id]);

  useEffect(() => {
    const makeItinerary = async () => {
      const data = await startItinerary(
        from?.map?.findAndOrder?.placeId,
        to?.map?.findAndOrder?.placeId,
      );
      if (data) {
        setItineraryDistance(data.distance);
        setItineraryDuration(data.duration);
      }
      logEvent(analytics, "map_button_start_route", {
        destination_poi: from?.title,
        start_poi: to?.title,
      });
    };

    if (from && to && from.id !== to.id) makeItinerary();
  }, [to, from, startItinerary]);

  useDidUpdateEffect(() => {
    if (!selectedOnMap) return;
    const setterRoom = focusingStart ? setFrom : setTo;
    const setterSearch = focusingStart ? setInputSearchFrom : setInputSearchTo;
    setterRoom(selectedOnMap);
    setterSearch(selectedOnMap?.title);
    // React only to changes in selectedOnMap to imitate a "map clicked" event
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOnMap]);

  const searchString = focusingStart ? inputSearchFrom : inputSearchTo;
  const searchResults = campus.mapData.resources
    .filter((r) => r.features.searchable && new RegExp(searchString, "i").test(r?.title))
    .sort((a, b) => (a?.title > b?.title ? 1 : -1));

  const goRoom = (value) => {
    centerOnPlaceId(value?.map?.findAndOrder?.placeId);

    dispatch(
      setFilter({
        ...filters,
        floor: campus.mapData.floors.find((floor) => floor.reference === value.floorReference)?.id,
      }),
    );
  };

  const Row2 = ({ style, index }) => {
    const room = searchResults[index];

    const setterRoom = focusingStart ? setFrom : setTo;
    const setterSearch = focusingStart ? setInputSearchFrom : setInputSearchTo;

    return (
      <Tile
        className={styles["tile--room"]}
        style={{
          ...style,
          margin: "0",
          height: style.height - GUTTER_SIZE,
          display: "flex",
          gap: 8,
        }}
        onClick={() => {
          setterSearch(room?.title);
          setterRoom(room);
          setShowItinerary(true);
          goRoom(room);
          setSelectecdFloorId(
            campus?.mapData?.floors?.find(
              (f) => f.reference === searchResults[index].floorReference,
            )?.id,
          );
        }}
      >
        <Typography.Text
          strong
          style={{
            width: "100%",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
          }}
        >
          <Glyph
            name={
              campus?.mapData?.categories?.find((m) => m.id === searchResults[index]?.categoryId)
                ?.icon
            }
            style={{
              fontWeight: "normal",
              marginRight: "8px",
              verticalAlign: "-4px",
              color: colors.secondary_base,
            }}
          />
          {searchResults[index]?.title}
        </Typography.Text>
        <Typography.Text style={{ minWidth: "fit-content" }}>
          {t("Floor")}{" "}
          {
            campus?.mapData?.floors.find(
              (floor) => floor?.reference === searchResults[index]?.floorReference,
            )?.displayName
          }
          <Glyph name="navigate_next" style={{ verticalAlign: "-4px" }} />
        </Typography.Text>
      </Tile>
    );
  };

  return (
    <>
      <TitleSource>{t("Itinerary")}</TitleSource>
      <Card
        title={
          <>
            <Typography.Title level={5}>
              <NavLink to={{ pathname: location.state.back, state: { state: true } }}>
                <Glyph
                  name="arrow_back_ios"
                  style={{
                    verticalAlign: "-2px",
                    fontSize: size.xxl,
                    color: "black",
                    marginRight: "8px",
                  }}
                />
              </NavLink>
              {t("Itinerary")}
            </Typography.Title>
          </>
        }
        style={{
          width: screens.xl ? "35%" : 350,
          minWidth: screens.xl ? "35%" : 350,
          height: "fit-content",
        }}
        bodyStyle={{ overflowY: "unset" }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <div
            style={{
              display: "flex",
              flexFlow: "column",
              alignItems: "center",
              marginRight: "8px",
            }}
          >
            <Glyph name="adjust" style={{ fontSize: size.l, color: colors.pending_light }} />
            <div className={styles["direction"]}></div>
            <Glyph name="place" style={{ fontSize: size.l, color: colors.secondary_base }} />
          </div>
          <Space style={{ width: "100%" }} direction="vertical" size="small">
            <Input
              onChange={(e) => setInputSearchFrom(e.target.value)}
              value={inputSearchFrom}
              placeholder={t("Start")}
              onFocus={() => {
                setFocusingStart(true);
                setShowItinerary(false);
              }}
            />
            <Input
              placeholder={t("End")}
              onChange={(e) => setInputSearchTo(e.target.value)}
              value={inputSearchTo}
              onFocus={() => {
                setFocusingStart(false);
                setShowItinerary(false);
              }}
            />
          </Space>

          <Button
            onClick={() => {
              setFrom(to);
              setTo(from);
              setInputSearchFrom(inputSearchTo);
              setInputSearchTo(inputSearchFrom);
            }}
            icon={<Glyph name="swap_vert" />}
            style={{ border: "none" }}
          />
        </div>

        <section style={{ marginTop: "16px", height: "auto", maxHeight: "450px" }}>
          <Space style={{ width: "100%" }} direction="vertical" size="small">
            {!searchResults ? null : (
              <>
                {searchResults.length === 0 ? (
                  <Typography.Text type="secondary">{t("NoResult")}</Typography.Text>
                ) : (
                  <List
                    height={200}
                    itemCount={searchResults.length}
                    itemSize={50}
                    style={{ display: showItinerary ? "none" : "flex" }}
                  >
                    {Row2}
                  </List>
                )}
              </>
            )}
          </Space>
        </section>

        <section
          style={{
            marginTop: "16px",
            display: showItinerary ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <article
            style={{
              display: "flex",
              flexFlow: "column",
              borderRight: "1px solid #88989C",
              alignItems: "center",
              padding: "0 8px",
              minWidth: "105px",
            }}
          >
            <Typography.Text
              strong
              style={{ textTransform: "uppercase", fontSize: size.xs, color: colors.pending_light }}
            >
              Distance
            </Typography.Text>
            <Typography.Text strong style={{ fontSize: "34px" }}>
              {itineraryDistance}
              <span style={{ fontSize: size.l, fontWeight: "normal" }}> m</span>
            </Typography.Text>
          </article>
          <article
            style={{
              display: "flex",
              flexFlow: "column",
              padding: "0 8px",
              minWidth: "105px",
              alignItems: "center",
            }}
          >
            <Typography.Text
              strong
              style={{ textTransform: "uppercase", fontSize: size.xs, color: colors.pending_light }}
            >
              {t("Duration")}
            </Typography.Text>
            <Typography.Text strong style={{ fontSize: "34px" }}>
              {itineraryDuration}
              <span style={{ fontSize: size.l, fontWeight: "normal" }}> min</span>
            </Typography.Text>
          </article>
        </section>
      </Card>
    </>
  );
};

export default Itinerary;
