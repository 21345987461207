import { Card, Spin, Typography } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getHistory } from "../../../services/redux/services/ParkingWS";
import HeaderButtons from "./Component/HeaderButtons";
import useDesignTokens from "../../../hook/useDesignTokens";
import { ReactComponent as UndrawWebSearch } from "../../../assets/svg/undraw_web_search.svg";
import { LoadingOutlined } from "@ant-design/icons";
import useDidUpdateEffect from "../../../hook/useDidUpdate";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const ParkingTabHistory = ({ setShowManageVehiclesModal, setShowCreateOrModifyBookingModal }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { symbols, colors, shadows, size } = useDesignTokens();

  const campus = useSelector((state) => state.clientsWS.campus);
  const bookings = useSelector((state) => state.parkingWS.history.reservations);
  const isMultiSite = !!useSelector((state) => state.userWS.userData.campus)?.length;

  const [loading, setLoading] = useState(false);

  useDidUpdateEffect(() => {
    logEvent(analytics, "parking_history");
  }, []);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      await dispatch(
        getHistory({
          siteId: campus?.mapData?.id,
          from: moment().subtract(1, "month"),
          to: moment(),
        }),
      );
      setLoading(false);
    };
    fetchData();
  }, [campus?.mapData?.id, dispatch]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        height: "100%",
      }}
    >
      <HeaderButtons
        setShowManageVehiclesModal={setShowManageVehiclesModal}
        setShowCreateOrModifyBookingModal={setShowCreateOrModifyBookingModal}
      />

      <div
        style={{
          maxWidth: 745,
          margin: "0 auto",
          height: "calc(100% - 64px)",
          padding: "0 15px",
          //paddingBottom: 60,
          width: "100%",
          overflowY: "hidden",
        }}
      >
        <div
          style={{
            overflow: "hidden",
            padding: "10px 10px 0 10px",
            marginLeft: -10,
            zIndex: 10,
            position: "relative",
          }}
        >
          <div
            style={{
              width: 200,
              height: 38,
              padding: "10px 16px",
              backgroundColor: "white",
              borderRadius: symbols.base_shape.radius,
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              boxShadow: `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography.Title
                level={5}
                style={{ margin: 0, display: "flex", flexDirection: "column" }}
                id="tabName"
              >
                {t("parking.30LastDays")}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    backgroundColor: colors.secondary_base,
                    height: 4,
                    borderRadius: 4,
                    width: "100%",
                  }}
                />
              </Typography.Title>
              <Typography.Text>{bookings?.length}</Typography.Text>
            </div>
          </div>
        </div>
        <Card
          //loading={loading}
          tab={t("parking.BookingHistory")}
          key="2"
          style={{ borderTopLeftRadius: 0, height: "inherit" }}
          bodyStyle={{ overflow: "hidden", height: "100%", padding: 0 }}
        >
          <table
            style={{
              width: "100%",
              height: "100%",
              display: "block",
            }}
            id="historyTable"
          >
            <tr
              style={{
                borderBottom: `1px solid ${colors.grey_60}`,
                backgroundColor: "white",
                marginBottom: 0,
                textTransform: "uppercase",
                marginTop: 10,
              }}
            >
              <td style={{ minWidth: 100, width: 100 }}>{t("parking.Date")}</td>
              {isMultiSite && <td style={{ minWidth: 100, width: 100 }}>{t("parking.Site")}</td>}
              <td>{t("parking.Zone")}</td>
              <td style={{ minWidth: 80, width: 80 }}>{t("parking.Duration")}</td>
            </tr>
            <tbody
              style={{ height: "100%", overflowY: "auto", display: "block", paddingBottom: 44 }}
            >
              {loading ? (
                <Spin
                  spinning={loading}
                  style={{
                    height: "100%",
                    verticalAlign: "center",
                    width: "100%",
                    paddingTop: "20%",
                  }}
                  indicator={<LoadingOutlined style={{ fontSize: size.xxxxl }} />}
                />
              ) : !!bookings?.length ? (
                bookings.map((data) => (
                  <tr key={data.id}>
                    <td style={{ minWidth: 100, width: 100 }}>
                      {moment(data.from).format("DD/MM/YYYY")}
                    </td>
                    {isMultiSite && (
                      <td style={{ minWidth: 100, width: 100 }}>{data.site?.title}</td>
                    )}
                    <td>{data.zone?.name}</td>
                    <td style={{ minWidth: 80, width: 80 }}>
                      {data.period === "AM"
                        ? t("parking.Am")
                        : data.period === "PM"
                        ? t("parking.Pm")
                        : t("parking.Day")}
                    </td>
                  </tr>
                ))
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                    gap: 16,
                    padding: "10%",
                  }}
                >
                  <UndrawWebSearch />
                  <Typography.Text strong type="secondary" style={{ fontSize: size.m }}>
                    {t("parking.NoBookings")}
                  </Typography.Text>
                </div>
              )}
            </tbody>
          </table>
        </Card>
      </div>
    </div>
  );
};

export default ParkingTabHistory;
