import React from "react";
import { createTeleporter } from "react-teleporter";

const Title = createTeleporter();

export function TitleTarget() {
  return (
    <Title.Target style={{ overflow: "hidden", textOverflow: "ellipsis", textWrap: "nowrap" }} />
  );
}

export function TitleSource({ children }) {
  return <Title.Source>{children}</Title.Source>;
}
