import { Input, Space } from "antd";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";
import { useTranslation } from "react-i18next";
import styles from "../Transport.module.less";
import useDebounce from "../../../../hook/useDebounce";
import useDidUpdateEffect from "../../../../hook/useDidUpdate";
import { useDispatch, useSelector } from "react-redux";
import {
  GetAutocompletePlaces,
  removeSearch,
} from "../../../../services/redux/services/TransportWS";

const Search = ({ inputSearchFrom, setInputSearchFrom, inputSearchTo, setIsJourney }) => {
  const { colors, shadows, size } = useDesignTokens();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const searchInputDebounced = useDebounce(inputSearchFrom, 500);
  const campus = useSelector((state) => state.clientsWS.campus);

  useDidUpdateEffect(() => {
    if (!!inputSearchFrom && inputSearchFrom.length >= 3) {
      dispatch(
        GetAutocompletePlaces({
          campusId: campus?.mapData?.id,
          q: searchInputDebounced,
        }),
      );
    } else if (inputSearchFrom.length === 0) {
      dispatch(removeSearch());
    }
  }, [searchInputDebounced]);

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        boxShadow: `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`,
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          flexFlow: "column",
          alignItems: "center",
          marginRight: "8px",
        }}
      >
        <Glyph name="adjust" style={{ fontSize: size.l, color: colors.pending_light }} />
        <div className={styles["direction"]}></div>
        <Glyph name="place" style={{ fontSize: size.l, color: colors.secondary_base }} />
      </div>
      <Space style={{ width: "100%" }} direction="vertical" size="small">
        <Input
          onChange={(e) => {
            setInputSearchFrom(e.target.value);
            setIsJourney(false);
          }}
          value={inputSearchFrom?.name || inputSearchFrom}
          placeholder={t("Start")}
        />
        <Input placeholder={t("End")} value={inputSearchTo?.name} disabled />
      </Space>
    </div>
  );
};

export default Search;
