import React, { useEffect, useState } from "react";
import { useChannelSettingsContext } from "@sendbird/uikit-react/ChannelSettings/context";
import EditDetailsModal from "@sendbird/uikit-react/ChannelSettings/components/EditDetailsModal";
import { Avatar, Typography } from "antd";
import { useTranslation } from "react-i18next";
import ChannelSettingsModal from "./ChannelSettingsModal";
// import ChannelSettingsModal from "./ChannelSettingsModal";
import { useKeycloak } from "@react-keycloak/web";
import useDesignTokens from "../../../hook/useDesignTokens";
import useSendbirdStateContext from "@sendbird/uikit-react/useSendbirdStateContext";
import sendbirdSelectors from "@sendbird/uikit-react/sendbirdSelectors";
import Glyph from "../../Common/Glyph/Glyph";

function CustomChannelProfile() {
  const [showEditDetailsModal, setShowEditDetailsModal] = useState(false);
  const context = useChannelSettingsContext();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { colors, size } = useDesignTokens();
  const globalStore = useSendbirdStateContext();
  const sdkInstance = sendbirdSelectors.getSdk(globalStore);

  useEffect(() => {
    logger.log("useChannelSettingsContext", context, "sdkInstance", sdkInstance);
  }, [context, sdkInstance]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "24px 40px 20px 40px",
          borderBottom: `1px solid rgb(224, 224, 224)`,
          gap: 16,
          position: "relative",
          textAlign: "center",
        }}
      >
        {context.channel?.members?.length > 1 ? (
          <Avatar.Group maxCount={3}>
            {context.channel?.members
              ?.filter((member) => member.userId !== sdkInstance.groupChannel._sdkState.userId)
              .map((member) => (
                <Avatar
                  key={member.userId}
                  size={70}
                  src={`${member.profileUrl}?access_token=${keycloak.token}`}
                  style={{
                    backgroundColor: "#bdbdbd",
                    border: "none",
                    boxShadow: "none",
                  }}
                >
                  {member.nickname?.charAt(0).toUpperCase()}
                  {member.nickname?.split(" ")[1]?.charAt(0).toUpperCase()}
                </Avatar>
              ))}
          </Avatar.Group>
        ) : (
          <img
            src={context.channel?.coverUrl}
            alt="cover"
            style={{
              height: "70px",
              borderRadius: "100%",
              overflow: "none",
              width: "70px",
              objectFit: "cover",
            }}
          />
        )}

        <span>
          {context.channel?.members?.length > 1 ? (
            context.channel.members
              .filter((member) => member.userId !== sdkInstance.groupChannel._sdkState.userId)
              .map((member, index) => (
                <Typography.Text key={member.userId}>
                  {index !== 0 && " "}
                  {member.nickname}
                  {index < context.channel?.members.length - 2 && ","}
                </Typography.Text>
              ))
          ) : (
            <Typography.Text>{t("NO_MEMBERS")}</Typography.Text>
          )}
        </span>
        {context.channel?.myRole === "operator" && (
          <div style={{ top: "18px", right: "18px", position: "absolute" }}>
            {/*<Button
                type="link"
                onClick={() => setShowEditDetailsModal(true)}
                style={{ padding: "1Opx", color: colors.secondary_base }}
              >
                {t("CHANNEL_SETTING__PROFILE__EDIT")}
          </Button>*/}
            <Glyph
              name="edit"
              className="sendbird-iconbutton"
              onClick={() => setShowEditDetailsModal(true)}
              style={{ color: colors.secondary_base, cursor: "pointer", fontSize: size.xxl }}
            />
          </div>
        )}
      </div>
      {showEditDetailsModal && <EditDetailsModal onCancel={() => setShowEditDetailsModal(false)} />}
      {/*showEditDetailsModal && (
        <ChannelSettingsModal
          setShowEditDetailsModal={setShowEditDetailsModal}
          showEditDetailsModal={showEditDetailsModal}
        />
      )*/}
    </>
  );
}

export default CustomChannelProfile;
