import { Button, Typography } from "antd";
import { ReactComponent as Confirm } from "../../../assets/svg/confirmation.svg";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import { STEPS } from "./Places";
import moment from "moment";
import { useTranslation } from "react-i18next";

const SuccessBooking = ({ setStep, date, seats, setInfoBooking, infoBooking }) => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Confirm />
      <Typography.Text strong style={{ marginTop: "16px" }}>
        <Glyph
          name="check_circle"
          style={{
            fontWeight: "normal",
            color: colors.success_light,
            marginRight: "8px",
            verticalAlign: "-5px",
          }}
        />
        {t("Places.Booking.Success.Title")}
      </Typography.Text>
      <Typography.Text strong type="secondary" style={{ marginTop: "16px" }}>
        {moment(date).format("LL")}
      </Typography.Text>

      <Typography.Text strong type="secondary">
        {seats} pers
      </Typography.Text>

      <Typography.Text strong type="secondary">
        {infoBooking?.zoneName} {infoBooking?.startHour.split(":").slice(0, 2).join(":")} -{" "}
        {infoBooking?.endHour.split(":").slice(0, 2).join(":")}
      </Typography.Text>
      <Button
        type="primary"
        style={{ width: "100%", marginTop: "32px" }}
        onClick={() => {
          setStep(STEPS.CANCELBOOKING);
          setInfoBooking(infoBooking);
        }}
      >
        {t("Places.Booking.Success.View")}
      </Button>
      <Button
        ghost
        style={{ width: "100%", marginTop: "8px" }}
        onClick={() => {
          setStep(STEPS.DETAILS);
        }}
      >
        {t("Back")}
      </Button>
    </div>
  );
};

export default SuccessBooking;
