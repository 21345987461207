import { Badge, Card, Col, Grid, Row, Space, Typography } from "antd";
import Tile from "../../Common/Tile/Tile";
import Glyph from "../../Common/Glyph/Glyph";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GetSchedulesForStop } from "../../../services/redux/services/TransportWS";
import { NavLink, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../hook/useDesignTokens";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { isEmpty } from "ramda";
import styles from "./Transport.module.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const ListLines = ({ stop, setLine, setStop }) => {
  const dispatch = useDispatch();
  const { colors, symbols, size } = useDesignTokens();
  const { i18n, t } = useTranslation();
  let location = useLocation();
  const screens = Grid.useBreakpoint();

  const campus = useSelector((state) => state.clientsWS.campus);
  const listLines = useSelector((state) => state.transportWS.listLines);
  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  useEffect(() => {
    if (!location?.state?.building) {
      dispatch(
        GetSchedulesForStop({
          campusId: campus?.mapData?.id,
          stopId: location?.state?.stop?.id || stop?.id,
        }),
      );
    }
  }, [campus?.mapData?.id, dispatch, location?.state, stop?.id]);

  moment.defineLocale(i18n.language.split("-")[0], {
    relativeTime: {
      future: "dans %s",
      s: "à l'approche",
      m: "1 min",
      mm: "%d mins",
      h: "%d mins",
      hh: "%dh",
      d: "1 j",
      dd: "%d j",
    },
  });

  return (
    <Card
      style={{
        width: screens.xl ? "35%" : 350,
        minWidth: screens.xl ? "35%" : 350,
        maxHeight: "calc(100vh - 110px)",
        overflow: "auto",
        height: "fit-content",
      }}
      bodyStyle={{ padding: "0" }}
      headStyle={{ minHeight: 90, maxHeight: 90 }}
      title={
        <div style={{ display: "flex" }}>
          <NavLink
            to="/transport"
            style={{
              backgroundColor: colors?.grey_20,
              height: "30px",
              width: "30px",
              borderRadius: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "8px",
            }}
            onClick={() => setStop()}
          >
            <Glyph
              name="arrow_back_ios"
              style={{
                verticalAlign: "-2px",
                fontSize: size.xl,
                color: "black",
              }}
            />
          </NavLink>
          <div>
            <Typography.Title level={5}>
              {!location?.state?.building && !isEmpty(listLines)
                ? listLines?.stopSchedules[0]?.stopName
                : sites?.find((site) => site?.id === campus?.mapData?.id)?.title}
            </Typography.Title>
            {!location?.state?.building && (
              <Typography.Text strong type="secondary">
                {t("select_a_line")}
              </Typography.Text>
            )}
          </div>
        </div>
      }
    >
      <div
        style={{
          overflowY: !location?.state?.building ? "scroll" : "initial",
          height: !location?.state?.building ? "fit-content" : "auto",
          padding: "15px",
        }}
      >
        <Row gutter={[8, 8]} style={{ marginBottom: "16px" }}>
          <Col span={!location?.state?.building ? 12 : 24}>
            <Tile
              style={{
                margin: "0",
                height: "auto",
                padding: "10px",
                justifyContent: "center",
                flexFlow: "column",
              }}
              href={{
                pathname: "/transport/journeys",
                state: {
                  building: !location?.state?.building ? false : true,
                },
              }}
            >
              <Glyph name="near_me" style={{ color: colors?.primary_base }} />
              <Typography.Text strong>{t("go_to")}</Typography.Text>
            </Tile>
          </Col>
          {!location?.state?.building && (
            <Col span={12}>
              <Badge count={listLines?.disruptions?.length} className={styles["badge"]}>
                <Tile
                  style={{
                    margin: "0",
                    height: "100%",
                    padding: "10px",
                    justifyContent: "center",
                    flexFlow: "column",
                    width: "100%",
                  }}
                  onClick={() => logEvent(analytics, `transport_traffic_info`)}
                  href="/transport/stop/trafic"
                >
                  <Glyph name="warning" style={{ color: colors?.primary_base }} />
                  <Typography.Text strong>{t("traffic_info")}</Typography.Text>
                </Tile>
              </Badge>
            </Col>
          )}
        </Row>
        {!location?.state?.building ? (
          <>
            <Typography.Text>{t("all_lines")}</Typography.Text>
            <Space style={{ width: "100%" }} direction="vertical" size="small">
              {listLines?.stopSchedules?.map((list, index) => (
                <Tile
                  key={index}
                  style={{ margin: "0", height: "auto", padding: "10px" }}
                  href="/transport/stop/line"
                  onClick={() => {
                    setLine(list);
                    logEvent(analytics, `transport_line_view`);
                  }}
                >
                  <div style={{ display: "flex", flexFlow: "column" }}>
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "row",
                        marginBottom: "8px",
                        alignItems: "center",
                      }}
                    >
                      <Glyph
                        name={
                          list?.modes?.find((m) => m?.id === "metro") ? "subway" : "directions_bus"
                        }
                        style={{ marginRight: "8px", fontSize: "30px" }}
                      />
                      <Typography.Text
                        strong
                        style={{
                          backgroundColor: list?.color,
                          borderRadius: symbols?.base_shape?.radius,
                          textAlign: "center",
                          color: list?.textColor,
                          minWidth: "30px",
                          height: "30px",
                          lineHeight: "30px",
                          padding: "0 6px",
                          marginRight: "4px",
                        }}
                      >
                        {list?.code}
                      </Typography.Text>
                    </div>
                    <Typography.Text strong>{list?.direction}</Typography.Text>
                    <div style={{ display: "flex", flexFlow: "row" }}>
                      {!isEmpty(list?.departures) && (
                        <Typography.Text style={{ marginRight: "8px" }}>{t("In")}</Typography.Text>
                      )}

                      {list?.departures?.slice(0, 3)?.map((departure, index) => (
                        <Typography.Text key={index} style={{ marginRight: "8px" }}>
                          {moment(departure).fromNow(true)} |
                        </Typography.Text>
                      ))}
                    </div>
                  </div>

                  <Glyph name="navigate_next" />
                </Tile>
              ))}
            </Space>
          </>
        ) : (
          <div>
            <Glyph name="place" style={{ color: colors?.secondary_base }} />
            <Typography.Text strong>
              {sites?.find((c) => c?.id === campus?.mapData?.id)?.address}
            </Typography.Text>
          </div>
        )}
      </div>
    </Card>
  );
};

export default ListLines;
