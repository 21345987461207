import { LoadingOutlined } from "@ant-design/icons";
import { Col, Grid, Layout, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import { slugify } from "../../helpers/utils";
import useSendbirdPolling from "../../hook/useSendbirdPolling";

// import redux
import { createLoadingSelector } from "../../services/redux/managers/LoadingManager";
import { getCampusData, removeLastView } from "../../services/redux/services/ClientsWS";
import { getUserData } from "../../services/redux/services/UserWS";
import { listVisits } from "../../services/redux/services/VisitorsWS";

// import component
import Map from "../Common/Map/Map";
import Options from "../Common/Options/Options";
import AroundMe from "./AroundMe/AroundMe";
import Catering from "./Catering/Catering";
import Comfort from "./Confort/Comfort";
import Dashboard from "./Dashboard/Dashboard";
import DashboardFacilities from "./DashboardFacilities/DashboardFacilities";
import FacilitiesResident from "./DashboardFacilities/FacilitiesResident";
import Fastbooking from "./FastBooking/Fastbooking";
import FastbookingV2 from "./FastBookingV2/Fastbooking";
import GuidMe from "./GuidMe/GuidMe";
import Header from "./Header/Header";
import ManageExternalUser from "./ManageExternalUser/ManageExternalUser";
import News from "./News/News";
import NewsV2 from "./NewsV2/News";
import Onboarding from "./Onboarding/Onboarding";
import PowerBi from "./PowerBi/PowerBi";
import QrCode from "./QrCode/QrCode";
import Sider from "./Sider/Sider";
import SpaceBooking from "./SpaceBooking/SpaceBooking";
import Ticketing from "./Ticketing/Ticketing";
import Transfert from "./Transfert/Transfert";
import Transport from "./Transport/Transport";
import Visitors from "./Visitors/Visitors";
import Wifi from "./Wifi/Wifi";
import InstantMessaging from "./InstantMessaging/InstantMessaging";
import ContactList from "./ContactList/ContactList";
import Parking from "./Parking/Parking";
import TicketingEvol from "./TicketingEvol/TicketingEvol";

// import css
import styles from "./Dashboard/Dashboard.module.less";
import DashboardMobile from "./Mobile/DashboardMobile";

const { Content } = Layout;

const Home = ({ isMobile }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const screens = Grid.useBreakpoint();

  const [mapLoaded, setMapLoaded] = useState(false);
  const [isFloor, setIsFloor] = useState(true);
  const [isOnboarding, setIsOnboarding] = useState(false);

  const campus = useSelector((state) => state.clientsWS.campus);
  const userData = useSelector((state) => state.userWS.userData);
  const user = useSelector((state) => state.userWS.userData?.profile);
  const view = useSelector((state) => state.userWS.userData?.views || []);
  const viewType = useSelector((state) => state.clientsWS.lastView);

  const isLoading = useSelector(createLoadingSelector(["users/getUserData"]));

  let siteId = localStorage.getItem("siteId");

  const isMobileAllowed = isMobile && process.env.REACT_APP_ISMOBILEALLOWED === "true";

  useEffect(() => {
    dispatch(
      getUserData({
        locale: i18n.language.split("-")[0],
        siteId: siteId ? siteId : "",
        isMobile: isMobileAllowed,
      }),
    )
      .unwrap()
      .then(() => {
        dispatch(
          getCampusData({
            locale: i18n.language.split("-")[0],
            siteId: siteId ? siteId : userData?.profile?.campusId,
          }),
        );
      });
  }, [dispatch, i18n.language, siteId, userData?.profile?.campusId]);

  useEffect(() => {
    if (process.env.REACT_APP_ID_MAP === "-") {
      setMapLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (view?.length === 1 && !isMobile) {
      history.push(`/${view[0]?.type}`);
    }
  }, [history, view, isMobile]);

  let spaceBookingArray = null;

  for (const key in ONBOARDINGS) {
    if (key.toUpperCase().includes(viewType?.toUpperCase())) {
      spaceBookingArray = ONBOARDINGS[key];
      break;
    }
  }

  useSendbirdPolling(view);

  // useEffect(() => {
  //   if (!isNil(spaceBookingArray) && view?.find((v) => v.type === viewType)?.onboarding) {
  //     setIsOnboarding(true);
  //   }
  // }, [spaceBookingArray, view, viewType]);

  useEffect(() => {
    dispatch(removeLastView());
  }, [dispatch]);

  useEffect(() => {
    if (!user?.id) return;
    dispatch(listVisits());
  }, [dispatch, user?.id]);

  return isMobileAllowed ? (
    <DashboardMobile />
  ) : (
    <Layout>
      {!isLoading && !!campus && <Sider setIsOnboarding={setIsOnboarding} />}
      <Layout style={{ marginLeft: isMobileAllowed ? 0 : !screens.xxl ? 80 : 0 }}>
        {!isLoading && !!campus && <Header setIsOnboarding={setIsOnboarding} />}
        <Layout style={{ position: "relative", overflow: "hidden" }}>
          {!isLoading && !!campus && (
            <>
              {process.env.REACT_APP_ID_MAP === "-" ? null : (
                <Map setMapLoaded={setMapLoaded} mapLoaded={mapLoaded} />
              )}
            </>
          )}

          {!isLoading && !!campus && (
            <Switch>
              <Route path="/" exact>
                <Content
                  style={{ padding: "16px 16px 0px 16px" }}
                  className={styles["content--dashboard"]}
                >
                  {isMobileAllowed ? <DashboardMobile /> : <Dashboard />}
                </Content>
              </Route>

              {/** FASTBOOKING */}
              <Route path="/fastBooking">
                <Content style={{ padding: "16px 16px 30px 16px", display: "flex" }}>
                  {!mapLoaded ? (
                    <div className={styles["loader-map"]}>
                      <div>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                        <br />
                        <Typography.Title level={3}>{t("LoadingMap")}</Typography.Title>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Fastbooking />
                      <Options type="fastbooking" isFloor={isFloor} setIsFloor={setIsFloor} />
                    </>
                  )}
                </Content>
              </Route>
              <Route path="/fastBookingV2">
                <Content style={{ padding: "8px 0 0 0", display: "flex" }}>
                  <FastbookingV2 mapLoaded={mapLoaded} />
                </Content>
              </Route>

              {/** TICKETING */}
              <Route path="/ticketing">
                <Content style={{ padding: "16px 16px 30px 16px", overflow: "auto" }}>
                  <Ticketing />
                </Content>
              </Route>

              {/** GUIDE ME */}
              <Route path="/indoorMapping">
                <Content style={{ padding: "16px 16px 30px 16px", display: "flex" }}>
                  {!mapLoaded ? (
                    <div className={styles["loader-map"]}>
                      <div>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                        <br />
                        <Typography.Title level={3}>{t("LoadingMap")}</Typography.Title>
                      </div>
                    </div>
                  ) : (
                    <>
                      <GuidMe setIsFloor={setIsFloor} />
                      <Options isFloor={isFloor} setIsFloor={setIsFloor} />
                    </>
                  )}
                </Content>
              </Route>

              {/** NEWS */}
              <Route path="/news/:sectionId/editor">
                <Content style={{ padding: "8px 16px 0 16px", overflow: "auto" }}>
                  {!!(view || []).find((v) => v.type === "socialnews") ? <NewsV2 /> : <News />}
                </Content>
              </Route>
              <Route path="/news/:sectionId">
                <div
                  className="ant-layout-content"
                  style={{ padding: "16px 16px 30px 16px", overflow: "auto" }}
                >
                  {!!(view || []).find((v) => v.type === "socialnews") ? <NewsV2 /> : <News />}
                </div>
              </Route>

              {/** SMARTCONTROL */}
              <Route path="/smartControl">
                <Content style={{ padding: "16px 16px 30px 16px", display: "flex" }}>
                  {!mapLoaded ? (
                    <div className={styles["loader-map"]}>
                      <div>
                        <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                        <br />
                        <Typography.Title level={3}>{t("LoadingMap")}</Typography.Title>
                      </div>
                    </div>
                  ) : (
                    <>
                      <Comfort />
                      {process.env.REACT_APP_ID_MAP !== "-" && <Options setIsFloor={setIsFloor} />}
                    </>
                  )}
                </Content>
              </Route>

              {/** VISITORS */}
              <Route path="/visitors">
                <Visitors />
              </Route>

              {/** WIFI */}
              <Route path="/wifi">
                <Wifi />
              </Route>

              {/** POWERBI */}
              <Route path="/powerBi">
                <PowerBi />
              </Route>

              {/* SPACE AS A SERVICE */}
              <Route path="/spaceBooking">
                <SpaceBooking />
              </Route>

              {/* MODULE TRANSFERT */}
              <Route path="/transfert">
                <Content style={{ padding: "60px 16px 30px 16px" }}>
                  <Transfert />
                </Content>
              </Route>

              {/* FACILITIES */}
              <Route path="/facilities">
                <FacilitiesResident />
              </Route>
              <Route path="/dashboard_facilities">
                <DashboardFacilities />
              </Route>

              {/* MODULE MANAGE EXTERNAL USER */}
              <Route path="/manageExternal">
                <Content style={{ padding: "60px 16px 30px 16px" }}>
                  <ManageExternalUser />
                </Content>
              </Route>

              {/* CATERING */}
              <Route path="/catering">
                <Catering />
              </Route>

              {/* TRANSPORT */}
              <Route path="/transport">
                <Content>
                  <Transport />
                </Content>
              </Route>

              {/* AROUND ME */}
              <Route path="/aroundMe">
                <Content>
                  <AroundMe />
                </Content>
              </Route>

              {/* INSTANT MESSAGING */}
              <Route path="/instantMessaging/:userId?">
                <Content>
                  <InstantMessaging />
                </Content>
              </Route>

              {/* CONTACT LIST */}
              <Route path={"/contactListEnhanced/:slug?"}>
                <Content>
                  <ContactList />
                </Content>
              </Route>

              {/* PARKING */}
              <Route path={"/parking"}>
                <Content>
                  <Parking />
                </Content>
              </Route>

              {/* TICKETING V2 */}
              {view
                ?.filter((v) => v.type.includes("ticketingV2"))
                ?.map((ticketView, i) => (
                  <Route key={i} path={`/${slugify(ticketView.title)}/:action?/:siteId?/:stuffId?`}>
                    <Content>
                      <TicketingEvol view={ticketView} />
                    </Content>
                  </Route>
                ))}
            </Switch>
          )}

          {spaceBookingArray && (
            <Onboarding
              open={isOnboarding}
              onCancel={() => {
                setIsOnboarding(false);
              }}
            />
          )}

          {view?.length === 1 && process.env.REACT_APP_QRCODE === "true" && (
            <Row gutter={[10, 10]} style={{ display: "flex", justifyContent: "flex-end" }}>
              <Col xs={14} sm={14} md={8} lg={8} xl={8} xxl={6}>
                {process.env.REACT_APP_QRCODE === "true" && <QrCode />}
              </Col>
            </Row>
          )}
        </Layout>
      </Layout>
    </Layout>
  );
};

export default Home;
