import { Card, Select } from "antd";
import moment from "moment";
import { useEffect, useMemo } from "react";
import Glyph from "../../Glyph/Glyph";

const DateSelector = ({ value, onChange, min, max, style }) => {
  const _now = useMemo(() => moment(), []);
  const _min = useMemo(() => moment(min) || _now.subtract(1, "month"), [_now, min]);
  const _max = useMemo(() => moment(max) || _now.add(6, "month"), [_now, max]);

  useEffect(() => {
    if (value.isBefore(_min, "date")) {
      onChange(moment(_min));
    } else if (value.isAfter(_max, "date")) {
      onChange(moment(_max));
    }
  }, [_max, _min, onChange, value]);

  const monthOptions = useMemo(() => {
    let options = [];

    if (_min && _max && value.isSame(_min, "year") && value.isSame(_max, "year")) {
      const start = _min.month();
      const end = _max.month() + 1;
      options = Array.from({ length: end - start }, (_, index) => start + index);
    } else if (_min && value.isSame(_min, "year")) {
      const start = _min.month();
      options = Array.from({ length: 12 - start }, (_, index) => start + index);
    } else if (_max && value.isSame(_max, "year")) {
      const end = _max.month() + 1;
      options = Array.from({ length: end }, (_, index) => index);
    } else {
      options = Array.from({ length: 12 }, (_, index) => index);
    }

    return options.map((option) => ({
      value: option,
      label: _now.month(option).format("MMMM"),
    }));
  }, [_max, _min, _now, value]);

  const yearOptions = useMemo(() => {
    let options = [];

    if (_min && _max) {
      const start = _min.year();
      const end = _max.year();
      options = Array.from({ length: end - start + 1 }, (_, index) => start + index);
    } else if (_min) {
      const start = _min.year();
      options = Array.from({ length: _now.year() - start + 1 }, (_, index) => start + index);
    } else if (_max) {
      const end = _max.year();
      options = Array.from({ length: end - 2000 + 1 }, (_, index) => 2000 + index);
    } else {
      options = Array.from({ length: 21 }, (_, index) => 2000 + index);
    }

    return options.map((option) => ({
      value: option,
      label: option,
    }));
  }, [_max, _min, _now]);

  const handleMonth = (v) => {
    onChange(moment(value).month(v));
  };
  const handleYear = (v) => {
    onChange(moment(value).year(v));
  };

  return (
    <div style={Object.assign({ display: "flex", alignItems: "center", gap: 8 }, style)}>
      <Select
        style={{ width: 100 }}
        className="card"
        size={"middle"}
        options={monthOptions}
        value={value?.month()}
        onChange={handleMonth}
      />
      {yearOptions.length > 1 && (
        <Select
          style={{ width: 100 }}
          className="card"
          size={"middle"}
          options={yearOptions}
          value={value?.year()}
          onChange={handleYear}
        />
      )}
      <Card
        style={{ height: 32, width: 70, cursor: "pointer" }}
        bodyStyle={{ padding: 0, display: "flex", alignItems: "center", justifyContent: "center" }}
        onClick={() => {
          if (value.isAfter(_min, "month")) onChange(moment(value).subtract(1, "month"));
          else logger.log("min");
        }}
      >
        <Glyph name={"chevron_left"} />
      </Card>
      <Card
        style={{ height: 32, width: 70, cursor: "pointer" }}
        bodyStyle={{ padding: 0, display: "flex", alignItems: "center", justifyContent: "center" }}
        onClick={() => {
          if (value.isBefore(_max, "month")) onChange(moment(value).add(1, "month"));
          else logger.log("max");
        }}
      >
        <Glyph name={"chevron_right"} />
      </Card>
    </div>
  );
};

export default DateSelector;
