import { Card, Grid, Typography } from "antd";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import useMap from "../../../Common/Map/useMap";
import Glyph from "../../../Common/Glyph/Glyph";
import Room from "./Room";
import styles from "./Room.module.less";

const RoomMap = ({ isAuto, setIsAuto }) => {
  const { selectedOnMap, centerOnPlaceId } = useMap();
  const screens = Grid.useBreakpoint();

  return (
    <Card
      style={{ width: screens.xl ? "35%" : 350, minWidth: screens.xl ? "35%" : 350 }}
      bodyStyle={{ padding: 0 }}
      title={
        <Typography.Title level={5} style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <NavLink
            to="/smartControl"
            className={styles["Room__back"]}
            onClick={() => {
              setIsAuto(false);
              centerOnPlaceId();
            }}
          >
            <Glyph name="arrow_back_ios" />
          </NavLink>
          {selectedOnMap?.title}
        </Typography.Title>
      }
    >
      <Room setIsAuto={setIsAuto} isAuto={isAuto} />
    </Card>
  );
};

export default RoomMap;
