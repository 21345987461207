import { LoadingOutlined } from "@ant-design/icons";
import { Button, Card, Spin, Typography, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import Glyph from "../../../../Common/Glyph/Glyph";
import SettingsBlind from "../../../Confort/SettingsUI/SettingsBlind";
import SettingsDegrees from "../../../Confort/SettingsUI/SettingsDegrees";
import SettingsFanSpeed from "../../../Confort/SettingsUI/SettingsFanSpeed";
import SettingsLight from "../../../Confort/SettingsUI/SettingsLight";

// import redux
import {
  getComfortAcFanSpeed,
  getComfortAcOffset,
  getComfortBlinds,
  getComfortBlindsAngle,
  getComfortLighting,
  setModeAuto,
} from "../../../../../services/redux/services/ConfortWS";

const Room = ({ isAuto, setIsAuto, selectedRoom, setIsZone, setEquipment }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const room = selectedRoom;

  const [isLoadingBlind, setIsLoadingBlind] = useState(false);
  const [isLoadingLight, setIsLoadingLight] = useState(false);
  const [isLoadingHeat, setIsLoadingHeat] = useState(false);
  const [isLoadingFanSpeed, setIsLoadingFanSpeed] = useState(false);
  logger.log("room", room);

  useEffect(() => {
    if (room) {
      if (room?.features?.comfort?.blind?.options?.includes("dimmer")) {
        setIsLoadingBlind(true);
      }
      if (room?.features?.comfort?.light?.options?.includes("dimmer")) {
        setIsLoadingLight(true);
      }

      setIsLoadingHeat(true);
      setIsLoadingFanSpeed(true);
      Promise.allSettled([
        room?.features?.comfort?.ac?.options?.includes("heatOffset")
          ? dispatch(getComfortAcOffset({ roomId: room.id }))
              .unwrap()
              .finally(() => setIsLoadingHeat(false))
          : null,
        room?.features?.comfort?.ac?.options?.includes("fanSpeed")
          ? dispatch(getComfortAcFanSpeed({ roomId: room.id }))
              .unwrap()
              .finally(() => setIsLoadingFanSpeed(false))
          : null,
        room?.features?.comfort?.light?.options?.includes("dimmer")
          ? dispatch(getComfortLighting({ roomId: room.id, zone: 0 }))
              .unwrap()
              .finally(() => setIsLoadingLight(false))
          : null,
        room?.features?.comfort?.blind?.options?.includes("dimmer")
          ? dispatch(getComfortBlinds({ roomId: room.id, zone: 0 }))
              .unwrap()
              .finally(() => setIsLoadingBlind(false))
          : null,
        room?.features?.comfort?.blind?.options?.includes("tilt")
          ? dispatch(getComfortBlindsAngle({ roomId: room.id, zone: 0 })).unwrap()
          : null,
      ]).then((results) => {
        if (results.find((r) => r.status === "rejected")) {
          message.error(t("ErrorGetComfort"), 5);
        }
      });
    }
  }, [dispatch, room, t]);

  const modeAuto = () => {
    if (room?.features?.comfort?.auto?.enabled === true) {
      setIsAuto(true);

      dispatch(setModeAuto({ roomId: room.id }))
        .unwrap()
        .catch(() => {
          message.error(t("ErrorModeAuto"), 5);
        });
    }
  };

  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8, paddingBottom: 16 }}>
      {!!room?.photos?.length && (
        <Card
          style={{
            padding: 0,
            margin: "0 15px",
            height: 150,
            overflow: "hidden",
            display: "flex",
            alignItems: "center",
          }}
          bodyStyle={{
            display: "flex",
            alignItems: "center",
            overflow: "hidden",
            position: "relative",
            padding: 0,
          }}
        >
          <img alt="roomImg" src={room?.photos[0]} style={{ width: "100%" }} />
          <div style={{ position: "absolute", width: "100%", bottom: 0 }}>
            <Typography.Title
              level={4}
              style={{
                position: "absolute",
                color: "white",
                zIndex: 2,
                bottom: 6,
                left: 14,
                margin: 0,
              }}
            >
              {room?.title}
            </Typography.Title>
            <div
              style={{
                background: "linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5))",
                width: "100%",
                position: "absolute",
                zIndex: 1,
                bottom: 0,
                height: 50,
              }}
            />
          </div>
        </Card>
      )}

      {room?.features?.comfort?.auto?.enabled === true && (
        <Button
          icon={isAuto ? <Glyph name="check_circle" /> : <Glyph name="auto_fix_high" />}
          onClick={modeAuto}
        >
          Mode AUTO
        </Button>
      )}

      {room?.features?.comfort?.light && (
        <Spin
          spinning={isLoadingLight}
          indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
          style={{ width: "12px" }}
        >
          <SettingsLight
            comfort={room?.features?.comfort}
            isAuto={isAuto}
            setIsAuto={setIsAuto}
            extra={
              room?.features?.comfort?.light?.zones?.length > 1 && (
                <div
                  onClick={() => {
                    setIsZone(true);
                    setEquipment("Light");
                  }}
                  style={{ cursor: "pointer", display: "flex", alignItems: "start" }}
                >
                  {room?.features?.comfort?.light?.zones?.length} zones
                  <Glyph name="chevron_right" />
                </div>
              )
            }
            selectedRoom={room}
          />
        </Spin>
      )}

      {room?.features?.comfort?.blind && (
        <Spin
          spinning={isLoadingBlind}
          indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
        >
          <SettingsBlind
            comfort={room?.features?.comfort}
            isAuto={isAuto}
            setIsAuto={setIsAuto}
            extra={
              room?.features?.comfort?.blind?.zones?.length > 1 && (
                <div
                  onClick={() => {
                    setIsZone(true);
                    setEquipment("Blind");
                  }}
                  style={{ cursor: "pointer", display: "flex", alignItems: "start" }}
                >
                  {room?.features?.comfort?.blind?.zones?.length} zones
                  <Glyph name="chevron_right" />
                </div>
              )
            }
            selectedRoom={room}
          />
        </Spin>
      )}

      {room?.features?.comfort?.ac?.options?.includes("heatOffset") && (
        <Spin
          spinning={isLoadingHeat}
          indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
        >
          <SettingsDegrees
            extra={
              room?.features?.comfort?.ac?.zones?.length > 1 && (
                <div
                  onClick={() => {
                    setIsZone(true);
                    setEquipment("Temperature");
                  }}
                  style={{ cursor: "pointer", display: "flex", alignItems: "start" }}
                >
                  {room?.features?.comfort?.ac?.zones?.length} zones
                  <Glyph name="chevron_right" />
                </div>
              )
            }
            comfort={room?.features?.comfort}
            setIsAuto={setIsAuto}
            selectedRoom={room}
          />
        </Spin>
      )}

      {room?.features?.comfort?.ac?.options?.includes("fanSpeed") && (
        <Spin
          spinning={isLoadingFanSpeed}
          indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
        >
          <SettingsFanSpeed
            comfort={room?.features?.comfort}
            isAuto={isAuto}
            setIsAuto={setIsAuto}
            extra={
              room?.features?.comfort?.ac?.zones?.length > 1 && (
                <div
                  onClick={() => {
                    setIsZone(true);
                    setEquipment("Ventilation");
                  }}
                  style={{ cursor: "pointer", display: "flex", alignItems: "start" }}
                >
                  {room?.features?.comfort?.ac?.zones?.length} zones
                  <Glyph name="chevron_right" />
                </div>
              )
            }
            selectedRoom={room}
          />
        </Spin>
      )}
    </div>
  );
};

export default Room;
