import { CaretRightOutlined } from "@ant-design/icons";
import {
  Avatar,
  Badge,
  Button,
  Calendar,
  Card,
  Col,
  Collapse,
  Drawer,
  Grid,
  Input,
  Layout,
  Row,
  Space,
  Spin,
  Tabs,
  Typography,
} from "antd";
import { logEvent } from "firebase/analytics";
import moment from "moment";
import { isEmpty } from "ramda";
import {
  Fragment,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Cell, Pie, PieChart } from "recharts";
import { ReactComponent as UDBooking } from "../../../assets/svg/undraw_booking.svg";
import { normalizeString } from "../../../helpers/utils";
import useDebounce from "../../../hook/useDebounce";
import useDesignTokens from "../../../hook/useDesignTokens";
import useDimensions from "../../../hook/useDimensions";
import useQuery from "../../../hook/useQuery";
import { analytics } from "../../../services/api/Firebase/Firebase";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import {
  getFollowedDayCounts,
  getFollowedDayList,
  getTeamDayCounts,
  getTeamDayList,
  getTeleworkingLimit,
  listFollowed,
  listSlots,
  slotsWithSitesSelector,
} from "../../../services/redux/services/SpaceService";
import { selectedCampus } from "../../../services/redux/services/UserWS";
import ConditionalWrapper from "../../Common/ConditionalWrapper";
import Glyph from "../../Common/Glyph/Glyph";
import CollapseRadioButton from "../../Common/Inputs/CollapseRadioButton/CollapseRadioButton";
import DateSelector from "../../Common/Inputs/DateSelector/DateSelector";
import ModalCancel from "./ModalCancel";
import NoSites from "./NoSites";
import CreateRequestModal from "./Spaas/CreateRequestModal/CreateRequestModal";
import ManagePeopleModal from "./Spaas/ManagePeopleModal";
import RequestModalPresence from "./Spaas/RequestDetailsModal/RequestModalPresence";
import { baseName } from "./SpaceBooking";
import styles from "./SpaceBooking.module.less";

export const DEFAULT_WORKING_DAYS = [1, 2, 3, 4, 5];

export const STEPS = {
  DAY_TYPE: "DAY_TYPE",
  DATES: "DATES",
  VALIDATION: "VALIDATION",
  PEOPLE: "PEOPLE",
  PHONEBOOK: "PHONEBOOK",
  DETAILS: "DETAILS",
  UPDATE: "UPDATE",
  WORKPLACE: "WORKPLACE",
  ROOM: "ROOM",
  TEAM_MEMBERS: "TEAM_MEMBERS",
  REVIEW: "REVIEW",
};

export const DAY_TYPES = {
  ON_SITE: "ON_SITE",
  TELEWORKING: "TELEWORKING",
  TRAVEL: "TRAVEL",
  DAY_OFF: "DAY_OFF",
  NOT_DECLARED: "NOT_DECLARED",
};
export const DAY_TYPES_COLORS = {
  ON_SITE: "secondary_base",
  TELEWORKING: "secondary_dark",
  TRAVEL: "primary_base",
  DAY_OFF: "interactive_02",
  NOT_DECLARED: "grey_80",
};
export const DAY_TYPES_ICONS = {
  ON_SITE: "business",
  TELEWORKING: "home",
  TRAVEL: "logout",
  DAY_OFF: "power_settings_new",
  NOT_DECLARED: "schedule",
};

export const PERIODS = {
  DAY: "DAY",
  MORNING: "MORNING",
  AFTERNOON: "AFTERNOON",
};

const TABS = {
  MY_PLANNING: "MY_PLANNING",
  MY_TEAM: "MY_TEAM",
  MY_CONTACTS: "MY_CONTACTS",
};

export const morningFirst = (a, b) => {
  if (a.request.period === PERIODS.MORNING && b.request.period === PERIODS.AFTERNOON) return -1;
  if (a.request.period === PERIODS.AFTERNOON && b.request.period === PERIODS.MORNING) return 1;
  return 0;
};

const DEFAULT_MORNING_HOURS = ["08:00:00", "12:59:59"];
const DEFAULT_AFTERNOON_HOURS = ["13:00:00", "18:00:00"];

const slotOnCurrentWorkingHours = (slot, spaceBookingConfig) => {
  const morningHours = spaceBookingConfig?.morningHours || DEFAULT_MORNING_HOURS;
  const afternoonHours = spaceBookingConfig?.afternoonHours || DEFAULT_AFTERNOON_HOURS;
  const now = moment();
  const date = moment(slot.date);
  const period = slot.request.period;

  if (!now.isSame(date, "date")) return false;

  const morning = [moment(morningHours[0], "HH:mm:ss"), moment(morningHours[1], "HH:mm:ss")];
  const afternoon = [moment(afternoonHours[0], "HH:mm:ss"), moment(afternoonHours[1], "HH:mm:ss")];

  switch (period) {
    case PERIODS.DAY:
      return now.isBetween(morning[0], afternoon[1]);
    case PERIODS.MORNING:
      return now.isBetween(morning[0], morning[1]);
    case PERIODS.AFTERNOON:
      return now.isBetween(afternoon[0], afternoon[1]);
    default:
      return false;
  }
};

const slotBeforeCurrentWorkingHours = (slot, spaceBookingConfig) => {
  const morningHours = spaceBookingConfig?.morningHours || DEFAULT_MORNING_HOURS;
  const afternoonHours = spaceBookingConfig?.afternoonHours || DEFAULT_AFTERNOON_HOURS;
  const now = moment();
  const date = moment(slot.date);
  const period = slot.request.period;

  if (now.isBefore(date, "date")) return true;
  if (now.isAfter(date, "date")) return false;

  const morningEnd = moment(morningHours[1], "HH:mm:ss");
  const afternoonEnd = moment(afternoonHours[1], "HH:mm:ss");

  switch (period) {
    case PERIODS.DAY:
    case PERIODS.AFTERNOON:
      return now.isBefore(afternoonEnd);
    case PERIODS.MORNING:
      return now.isBefore(morningEnd);
    default:
      return false;
  }
};

const LIST_PAGINATION = process.env.NODE_ENV === "development" ? 2 : 5;
const SpaasStats = forwardRef(({ date, tab, ...rest }, ref) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { colors } = useDesignTokens();

  const [search, setSearch] = useState("");

  const searchDebounced = useDebounce(search, 500);

  const isLoading = useSelector(createLoadingSelector(["spaceService/getTeamDayList"]));
  const planningDayList = useSelector((state) => state.spaceServiceWS.planningDayList);
  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  const LIST = planningDayList?.list;

  const day = moment(date).format("YYYY-MM-DD");

  const refreshTeamDayList = useCallback(
    (params) => {
      dispatch(getTeamDayList(params));
    },
    [dispatch],
  );
  const refreshFollowedDayList = useCallback(() => {
    dispatch(getFollowedDayList({ date: day }));
  }, [day, dispatch]);

  useEffect(() => {
    if (tab !== TABS.MY_TEAM) return;
    const params = { search: searchDebounced, page: 1, size: LIST_PAGINATION, date: day };
    refreshTeamDayList(params);
  }, [day, refreshTeamDayList, searchDebounced, tab]);

  useEffect(() => {
    if (tab !== TABS.MY_CONTACTS) return;
    const params = { date: day };
    refreshFollowedDayList(params);
  }, [day, refreshFollowedDayList, tab]);

  useImperativeHandle(
    ref,
    () => ({
      refresh() {
        if (tab === TABS.MY_TEAM) {
          const params = { search: searchDebounced, page: 1, size: LIST_PAGINATION, date: day };
          refreshTeamDayList(params);
        } else if (tab === TABS.MY_CONTACTS) {
          const params = { date: day };
          refreshFollowedDayList(params);
        }
      },
    }),
    [day, refreshFollowedDayList, refreshTeamDayList, searchDebounced, tab],
  );

  const fetchMore = (list) => {
    dispatch(
      getTeamDayList({
        search: searchDebounced,
        type: list.type,
        siteId: list.site?.id,
        page: list.nextPage,
        size: LIST_PAGINATION,
        date: day,
      }),
    );
  };

  const filtered = useMemo(() => {
    if (tab === TABS.MY_TEAM) return LIST;

    const regex = new RegExp(normalizeString(search), "i");

    return LIST?.map((list) => {
      const items = list.items.filter((i) => {
        const user = i.user || i;
        const displayName = `${user.firstname || ""} ${user.lastname || ""}`.trim();
        return regex.test(normalizeString(displayName));
      });
      return { ...list, items };
    }).filter((l) => l.items.length);
  }, [LIST, search, tab]);

  const nbDeclaration =
    filtered
      ?.filter((i) => i.type !== DAY_TYPES.NOT_DECLARED)
      .reduce((acc, curr) => {
        return acc + curr.totalItems;
      }, 0) || 0;
  const nbOnSite =
    filtered
      ?.filter((i) => i.type === DAY_TYPES.ON_SITE)
      .reduce((acc, curr) => {
        return acc + curr.totalItems;
      }, 0) || 0;

  const PIE_DATA = useMemo(
    () => [
      { name: DAY_TYPES.ON_SITE, value: nbOnSite },
      {
        name: DAY_TYPES.TELEWORKING,
        value: filtered?.find((i) => i.type === DAY_TYPES.TELEWORKING)?.totalItems || 0,
      },
      {
        name: DAY_TYPES.TRAVEL,
        value: filtered?.find((i) => i.type === DAY_TYPES.TRAVEL)?.totalItems || 0,
      },
      {
        name: DAY_TYPES.DAY_OFF,
        value: filtered?.find((i) => i.type === DAY_TYPES.DAY_OFF)?.totalItems || 0,
      },
      {
        name: DAY_TYPES.NOT_DECLARED,
        value: filtered?.find((i) => i.type === DAY_TYPES.NOT_DECLARED)?.totalItems || 0,
      },
    ],
    [filtered, nbOnSite],
  );

  const renderCollapsePanel = (list) => {
    const count = list.totalItems || 0;

    const siteId = list.site?.id;
    const spaceBookingType = sites.find((s) => s.id === siteId)?.spaceBooking?.type;

    if (!count) return null;
    return (
      <Col span={24}>
        <Collapse
          className="secondary"
          bordered={false}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          defaultActiveKey={["1"]}
        >
          <Collapse.Panel
            header={
              <Space>
                <Glyph className={"secondary"} name={DAY_TYPES_ICONS[list.type]} />
                <Typography.Text strong>
                  {list.type === DAY_TYPES.ON_SITE ? list.site.title : t(`spas.type.${list.type}`)}
                </Typography.Text>
              </Space>
            }
            key="1"
            extra={<Typography.Text strong>{count}</Typography.Text>}
          >
            <Row gutter={[0, 10]}>
              {list.items.map((item) => {
                const user = item.user ? item.user : item;
                const displayName = `${user.firstname || ""} ${user.lastname || ""}`.trim();
                return (
                  <Col span={24}>
                    <Space size="middle">
                      <Avatar src={user.photoUrl}>{displayName.charAt(0).toUpperCase()}</Avatar>
                      {list.type === DAY_TYPES.ON_SITE && spaceBookingType === 3 ? (
                        <div>
                          <Typography.Paragraph strong style={{ margin: 0 }}>
                            {displayName}
                          </Typography.Paragraph>
                          <Space size={5}>
                            <Typography.Text type="secondary">{item.sector?.title}</Typography.Text>
                            <Typography.Text type="secondary">{"\u2022"}</Typography.Text>
                            <Typography.Text type="secondary">{item.room?.title}</Typography.Text>
                            <Typography.Text type="secondary">{"\u2022"}</Typography.Text>
                            <Typography.Text type="secondary">
                              {t("Floor")} {item.floor?.title}
                            </Typography.Text>
                          </Space>
                        </div>
                      ) : (
                        <Typography.Text strong>{displayName}</Typography.Text>
                      )}
                    </Space>
                  </Col>
                );
              })}
              {!!list.nextPage && (
                <Col span={24} style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button size={"middle"} block type={"text"} onClick={() => fetchMore(list)}>
                    {t("SeeMore")}
                  </Button>
                </Col>
              )}
            </Row>
          </Collapse.Panel>
        </Collapse>
      </Col>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Row gutter={[20, 20]}>
        <Col span={24} style={{ textAlign: "center" }}>
          <Typography.Title style={{ textTransform: "capitalize", margin: 0 }} level={3}>
            {moment(date).format("dddd DD MMMM")}
          </Typography.Title>
          <Typography.Text type="secondary">
            {t("spas.collabs.xDeclared", { count: nbDeclaration })}
          </Typography.Text>
        </Col>
        <Col span={24}>
          <Input
            placeholder="Chercher un collaborateur"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Col>
        <Col span={24}>
          <Card bordered={false}>
            <Row gutter={[10, 10]}>
              <Col span={24}>
                <Space>
                  <Typography.Text type={"secondary"}>
                    <Glyph name={"bar_chart"} />
                  </Typography.Text>
                  <Typography.Text type={"secondary"} style={{ textTransform: "uppercase" }}>
                    {t("spas.collabs.presence")}
                  </Typography.Text>
                </Space>
              </Col>
              <Col span={24}>
                <Space>
                  <Row gutter={[10, 10]}>
                    <Col span={12}>
                      <Card style={{ boxShadow: "none" }} bodyStyle={{ padding: 8 }}>
                        <div style={{ display: "flex", gap: 8, width: "100%", overflow: "hidden" }}>
                          <Glyph
                            style={{ color: colors[DAY_TYPES_COLORS[DAY_TYPES.ON_SITE]] }}
                            name={DAY_TYPES_ICONS[DAY_TYPES.ON_SITE]}
                          />
                          <Typography.Text>{nbOnSite}</Typography.Text>
                          <Typography.Text ellipsis>{t("spas.type.ON_SITE")}</Typography.Text>
                        </div>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card style={{ boxShadow: "none" }} bodyStyle={{ padding: 8 }}>
                        <div style={{ display: "flex", gap: 8, width: "100%", overflow: "hidden" }}>
                          <Glyph
                            style={{ color: colors[DAY_TYPES_COLORS[DAY_TYPES.TELEWORKING]] }}
                            name={DAY_TYPES_ICONS[DAY_TYPES.TELEWORKING]}
                          />
                          <Typography.Text>
                            {filtered?.find((i) => i.type === DAY_TYPES.TELEWORKING)?.totalItems ||
                              0}
                          </Typography.Text>
                          <Typography.Text ellipsis>{t("spas.type.TELEWORKING")}</Typography.Text>
                        </div>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card
                        style={{ boxShadow: "none" }}
                        bodyStyle={{ padding: 8, overflow: "hidden" }}
                      >
                        <div style={{ display: "flex", gap: 8, width: "100%", overflow: "hidden" }}>
                          <Glyph
                            style={{ color: colors[DAY_TYPES_COLORS[DAY_TYPES.TRAVEL]] }}
                            name={DAY_TYPES_ICONS[DAY_TYPES.TRAVEL]}
                          />
                          <Typography.Text>
                            {filtered?.find((i) => i.type === DAY_TYPES.TRAVEL)?.totalItems || 0}
                          </Typography.Text>
                          <Typography.Text ellipsis>{t("spas.type.TRAVEL")}</Typography.Text>
                        </div>
                      </Card>
                    </Col>
                    <Col span={12}>
                      <Card style={{ boxShadow: "none" }} bodyStyle={{ padding: 8 }}>
                        <div style={{ display: "flex", gap: 8, width: "100%", overflow: "hidden" }}>
                          <Glyph
                            style={{ color: colors[DAY_TYPES_COLORS[DAY_TYPES.DAY_OFF]] }}
                            name={DAY_TYPES_ICONS[DAY_TYPES.DAY_OFF]}
                          />
                          <Typography.Text>
                            {filtered?.find((i) => i.type === DAY_TYPES.DAY_OFF)?.totalItems || 0}
                          </Typography.Text>
                          <Typography.Text ellipsis>{t("spas.type.DAY_OFF")}</Typography.Text>
                        </div>
                      </Card>
                    </Col>
                  </Row>
                  <PieChart width={100} height={100}>
                    <Pie data={PIE_DATA} innerRadius={20} fill="#8884d8" dataKey="value">
                      {PIE_DATA.map((entry) => (
                        <Cell
                          key={`cell-${entry.name}`}
                          fill={
                            colors[DAY_TYPES_COLORS[entry.name]] || DAY_TYPES_COLORS[entry.name]
                          }
                        />
                      ))}
                    </Pie>
                  </PieChart>
                </Space>
              </Col>
            </Row>
          </Card>
        </Col>
        {filtered?.map((list) => renderCollapsePanel(list))}
      </Row>
    </Spin>
  );
});

const MyPlanning = ({ date, onRequestConfirmPresence, onRequestCancel, onSeeMap, ...rest }) => {
  const { t } = useTranslation();
  const { colors, size } = useDesignTokens();

  const slots = useSelector(slotsWithSitesSelector);

  const todays = useMemo(() => {
    return (slots || []).filter((s) => moment(s.date).isSame(date, "date")).sort(morningFirst);
  }, [date, slots]);

  const canConfirmPresence = (slot) => {
    if (!slot.site?.spaceBooking) return false;
    const hasETag = !!slot.site?.spaceBooking?.electronicTag;
    const isOnSite = slot.request.type === DAY_TYPES.ON_SITE;
    const isSpas3 = slot.site.spaceBooking.type === 3;
    const isConfirmed = !!slot.confirmed;
    const isOnCurrentWorkingHours = slotOnCurrentWorkingHours(slot, slot.site.spaceBooking);
    return hasETag && isOnSite && isSpas3 && !isConfirmed && isOnCurrentWorkingHours;
  };
  const canCancel = (slot) => {
    return slotBeforeCurrentWorkingHours(slot, slot.site?.spaceBooking);
  };

  return (
    <div style={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <Typography.Title
        level={3}
        style={{ textTransform: "capitalize", margin: 0, alignSelf: "center" }}
      >
        {moment(date).format("dddd DD MMMM")}
      </Typography.Title>
      {todays.length > 0 ? (
        <>
          <Typography.Paragraph type={"secondary"} style={{ alignSelf: "center" }}>
            {t("spas.declarations", { count: todays.length })}
          </Typography.Paragraph>
          <br />
          <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
            {todays.map((slot) => (
              <Fragment key={slot.id}>
                <Col span={24}>
                  <Card bordered={false} bodyStyle={{ padding: 10 }}>
                    <Row gutter={[10, 10]}>
                      {slot.request.type !== DAY_TYPES.ON_SITE && (
                        <Col span={24}>
                          <Card style={{ height: "100%" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                              <Glyph
                                className={"secondary"}
                                name={DAY_TYPES_ICONS[slot.request.type]}
                              />
                              <Typography.Text strong>
                                {t(`spas.type.${slot.request.type}`)}
                              </Typography.Text>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {!!slot.site && !!slot.space ? (
                        <>
                          <Col style={{ width: "fit-content" }}>
                            <Card style={{ height: "100%" }}>
                              <div
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <Glyph className={"secondary"} name={"business"} />
                                <Typography.Text strong>{slot.site?.title}</Typography.Text>
                              </div>
                            </Card>
                          </Col>
                          <Col flex={"auto"}>
                            <Card
                              style={{ height: "100%", cursor: "pointer" }}
                              onClick={() => onSeeMap(slot)}
                            >
                              <div style={{ display: "flex", alignItems: "flex-start", gap: 16 }}>
                                <Glyph className={"secondary"} name={"desk"} />
                                <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                  <div
                                    style={{
                                      display: "flex",
                                      alignItems: "baseline",
                                      gap: 8,
                                    }}
                                  >
                                    <Typography.Text strong>{slot.space.title}</Typography.Text>
                                    {slot?.confirmed && (
                                      <Glyph
                                        name="check_circle"
                                        style={{ color: colors.success_light, fontSize: "1rem" }}
                                      />
                                    )}
                                  </div>
                                  <Space>
                                    <Typography.Text
                                      style={{ textWrap: "balance", display: "block" }}
                                    >
                                      {slot.room.title}
                                    </Typography.Text>
                                    {"\u2022"}
                                    <Typography.Text style={{ textWrap: "nowrap" }}>
                                      {t("Floor")} {slot.floor.title}
                                    </Typography.Text>
                                  </Space>
                                </div>
                                <Glyph name={"navigate_next"} />
                              </div>
                            </Card>
                          </Col>
                        </>
                      ) : !!slot.site ? (
                        <Col span={24}>
                          <Card style={{ height: "100%" }}>
                            <div
                              style={{
                                display: "flex",
                                alignItems:
                                  slot.site.spaceBooking.type !== 1 ? "flex-start" : "center",
                                gap: 16,
                              }}
                            >
                              <Glyph className={"secondary"} name={"business"} />
                              <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
                                <Typography.Text strong>{slot.site.title}</Typography.Text>
                                {slot.site.spaceBooking.type !== 1 && (
                                  <Typography.Text>{slot.sector.title}</Typography.Text>
                                )}
                              </div>
                            </div>
                          </Card>
                        </Col>
                      ) : null}
                      <Col span={24}>
                        <Card style={{ height: "100%" }}>
                          <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                            <Glyph className={"secondary"} name={"schedule"} />
                            <Typography.Text strong>
                              {t(`spas.period.${slot.request.period}`)}
                            </Typography.Text>
                          </div>
                        </Card>
                      </Col>
                      {(slot.request.recurrence || "NONE") !== "NONE" && (
                        <Col span={24}>
                          <Card style={{ height: "100%" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                              <Glyph className={"secondary"} name={"sync"} />
                              <Typography.Text strong>
                                {t(`spas.recurrence.${slot.request.recurrence}`, {
                                  day: moment(slot.date).format("dddd"),
                                })}
                              </Typography.Text>
                            </div>
                          </Card>
                        </Col>
                      )}
                      {slot.request.from?.mySelf === false && (
                        <Col span={24}>
                          <Card style={{ height: "100%" }}>
                            <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
                              <Glyph className={"secondary"} name={"person"} />
                              <Typography.Text strong>
                                {t(`spas.request.from`, { user: slot.request.from.displayName })}
                              </Typography.Text>
                            </div>
                          </Card>
                        </Col>
                      )}
                    </Row>
                  </Card>
                </Col>
                <Col offset={2} span={20}>
                  <Row gutter={[10, 10]}>
                    {canConfirmPresence(slot) && (
                      <Col span={24}>
                        <Button
                          block
                          type="secondary"
                          icon={<Glyph name={"done"} />}
                          onClick={() => onRequestConfirmPresence(slot)}
                        >
                          {t("spas.request.confirm.presence")}
                        </Button>
                      </Col>
                    )}
                    {canCancel(slot) && (
                      <Col span={24}>
                        <Button
                          block
                          ghost
                          icon={<Glyph name={"cancel"} />}
                          onClick={() => onRequestCancel(slot)}
                        >
                          {t("spas.request.actions.cancel")}
                        </Button>
                      </Col>
                    )}
                  </Row>
                </Col>
              </Fragment>
            ))}
          </Row>
        </>
      ) : (
        <div
          style={{
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            gap: 80,
            padding: 40,
          }}
        >
          <UDBooking style={{ width: "70%", height: "auto" }} />
          <Typography.Text strong type={"secondary"} style={{ fontSize: size.l }}>
            {t("spas.request.none")}
          </Typography.Text>
        </div>
      )}
    </div>
  );
};

const MyPlanningViewCalendarItem = ({ slots }) => {
  const { colors } = useDesignTokens();
  const { t } = useTranslation();

  if (isEmpty(slots)) return null;
  return slots.map((slot, idx) => (
    <Card
      bordered={false}
      style={{ marginTop: idx > 0 ? 8 : 0 }}
      bodyStyle={{ padding: "4px 12px 4px 12px" }}
    >
      <Row style={{ overflow: "hidden", whiteSpace: "nowrap" }}>
        <Col span={24}>
          <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: 6 }}>
            <Typography.Text strong ellipsis>
              {slot.request.type === DAY_TYPES.ON_SITE
                ? slot.site?.title || ""
                : t(`spas.type.${slot.request.type}`)}
            </Typography.Text>
            {slot?.confirmed && (
              <Glyph
                name="check_circle"
                style={{ color: colors.success_light, fontSize: "1rem" }}
              />
            )}
          </div>
        </Col>
        {slots.length === 1 && (
          <Col span={24}>
            <div style={{ display: "flex", alignItems: "center", gap: 6, flexWrap: "wrap" }}>
              <Glyph name="schedule" className={"secondary"} style={{ fontSize: "1rem" }} />
              <Typography.Text ellipsis>{t(`spas.period.${slot.request.period}`)}</Typography.Text>
            </div>
          </Col>
        )}
      </Row>
    </Card>
  ));
};

const MyTeamViewCalendarItem = ({ type, count }) => {
  const { colors, symbols } = useDesignTokens();

  return (
    <Card bordered={false} bodyStyle={{ padding: 4, overflow: "hidden" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          gap: "0.2rem",
          overflow: "hidden",
          whiteSpace: "nowrap",
          flexWrap: "wrap-reverse",
          direction: "rtl",
        }}
      >
        <div
          style={{
            backgroundColor: colors.secondary_base,
            color: "white",
            height: 25,
            width: 25,
            lineHeight: "25px",
            borderRadius: symbols.base_shape.radius / 2,
            textAlign: "center",
            flexShrink: 0,
          }}
        >
          {count}
        </div>
        <Typography.Text style={{ direction: "initial" }}>Collabs.</Typography.Text>
        <Glyph
          className={"secondary"}
          name={DAY_TYPES_ICONS[type]}
          style={{ fontSize: "1.2rem" }}
        />
      </div>
    </Card>
  );
};

const SpaceBookingResident = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const screens = Grid.useBreakpoint();

  useEffect(() => {
    logger.log(
      "screen",
      Object.entries(screens)
        .filter(([key, value]) => value)
        .map(([key]) => key),
    );
  }, [screens]);

  const isSmallScreen = !screens.xl;

  const spaasStatsRef = useRef();

  const routeurStateDate = history.location.state?.date;
  const shouldCreate = !!routeurStateDate && history.action !== "POP";

  const query = useQuery();
  const _tab = query.get("tab") || TABS.MY_PLANNING;
  const _date = moment(routeurStateDate || query.get("date") || new Date());

  const [calendarPanelRef, calendarPanelDimensions] = useDimensions();

  const site = useSelector(selectedCampus);
  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const profile = useSelector((state) => state.userWS.userData?.profile);
  const pending = useSelector((state) => state.spaceServiceWS.followedPending);
  const slots = useSelector(slotsWithSitesSelector);
  const planningDayCounts = useSelector((state) => state.spaceServiceWS.planningDayCounts);

  const [type, setType] = useState(DAY_TYPES.ON_SITE);

  const [showManagePeopleModal, setShowManagePeopleModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(shouldCreate ? routeurStateDate : false);
  const [slotToConfirm, setSlotToConfirm] = useState(null);
  const [slotToCancel, setSlotToCancel] = useState(null);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState(false);

  const month = _date.month();
  const year = _date.year();

  useEffect(() => {
    if (!isSmallScreen) setOpenDetailsDrawer(false);
  }, [isSmallScreen]);

  useEffect(() => {
    dispatch(getTeleworkingLimit());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listFollowed());
  }, [dispatch]);

  const refreshTeamDayCounts = useCallback(
    ({ type, startDate, endDate }) => {
      if (!profile?.rights?.isManager) return;
      dispatch(getTeamDayCounts({ type, startDate, endDate }));
    },
    [dispatch, profile?.rights?.isManager],
  );

  useEffect(() => {
    const date = moment().set({ month, year });

    if (_tab === TABS.MY_PLANNING) {
      dispatch(
        listSlots({
          startDate: date.clone().subtract(1, "month").startOf("month").toISOString(),
          endDate: date.clone().add(6, "month").endOf("month").toISOString(),
        }),
      );
    } else if (_tab === TABS.MY_TEAM) {
      refreshTeamDayCounts({
        type,
        startDate: date.clone().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
        endDate: date.clone().add(1, "month").endOf("month").format("YYYY-MM-DD"),
      });
    } else if (_tab === TABS.MY_CONTACTS) {
      dispatch(
        getFollowedDayCounts({
          type,
          startDate: date.clone().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
          endDate: date.clone().add(1, "month").endOf("month").format("YYYY-MM-DD"),
        }),
      );
    }
  }, [_tab, dispatch, month, refreshTeamDayCounts, type, year]);

  const navigate = ({ date = _date, tab = _tab } = {}) => {
    const params = new URLSearchParams();
    params.append("tab", tab);
    params.append("date", moment(date).format("YYYY-MM-DD"));
    history.replace(`${baseName}?${params.toString()}`);
  };

  const hasNoSite = useMemo(() => {
    if (!sites?.length) return true;
    const availableSites = sites?.filter((s) => !!s.spaceBooking)?.filter((s) => s.sectors?.length);
    if (!availableSites?.length) return true;
    return false;
  }, [sites]);

  const dateMin = moment().subtract(1, "month").startOf("month");
  const dateMax = moment().add(site?.spaceBooking?.maxRecurrenceMonths || 6, "month");
  const workingDays = site?.spaceBooking?.workingDays || DEFAULT_WORKING_DAYS;
  const holidays = site?.holidays || [];

  if (hasNoSite) {
    return <NoSites />;
  }
  return (
    <>
      <Layout className={styles.dashboardContainer}>
        <Tabs
          activeKey={_tab}
          onChange={(tab) => navigate({ tab })}
          style={{ paddingLeft: 16, paddingRight: 16 }}
          tabBarStyle={{ margin: 0 }}
        >
          <Tabs.TabPane tab={t("spas.tabs.MY_PLANNING")} key={TABS.MY_PLANNING} />
          {profile?.rights?.isManager && (
            <Tabs.TabPane tab={t("spas.tabs.MY_TEAM")} key={TABS.MY_TEAM} />
          )}
          <Tabs.TabPane tab={t("spas.tabs.MY_CONTACTS")} key={TABS.MY_CONTACTS} />
        </Tabs>

        <Layout.Content>
          <Layout.Content style={{ maxWidth: 1500, margin: "auto" }}>
            <Row style={{ marginBottom: 20 }}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Space>
                  {_tab !== TABS.MY_CONTACTS && (
                    <Button
                      block
                      type="primary"
                      icon={<Glyph name="add" />}
                      onClick={() => setShowCreateModal(_date.toISOString())}
                    >
                      {t(`spas.request.new${_tab === TABS.MY_TEAM ? ".team" : ""}`)}
                    </Button>
                  )}
                  {_tab !== TABS.MY_PLANNING && site.spaceBooking?.type === 3 && (
                    <Button
                      block
                      type="secondary"
                      icon={<Glyph name="zoom_in" />}
                      onClick={() => {
                        const query = new URLSearchParams();
                        query.append("date", _date.format("YYYY-MM-DD"));
                        query.append("view", _tab === TABS.MY_TEAM ? "team" : "contacts");
                        return history.push(`${baseName}/map?${query.toString()}`);
                      }}
                    >
                      {t("spas.request.actions.map")}
                    </Button>
                  )}
                  <Badge count={pending?.length}>
                    <Button
                      shape="circle"
                      ghost
                      icon={<Glyph name="manage_accounts" />}
                      onClick={() => setShowManagePeopleModal(true)}
                    ></Button>
                  </Badge>
                </Space>
              </Col>
            </Row>

            <div style={{ display: "flex", alignItems: "flex-start", gap: 16 }}>
              <div ref={calendarPanelRef} style={{ flexGrow: 1, flexBasis: "calc(65% - 10px)" }}>
                <Row gutter={[10, 10]}>
                  <Col flex={"auto"}>
                    <DateSelector
                      min={dateMin}
                      max={dateMax}
                      value={_date}
                      onChange={(date) => navigate({ date })}
                    />
                  </Col>
                  {_tab !== TABS.MY_PLANNING && (
                    <Col>
                      <CollapseRadioButton
                        size={"middle"}
                        value={type}
                        onChange={setType}
                        options={[
                          {
                            icon: "business",
                            title: t(`spas.type.${DAY_TYPES.ON_SITE}`),
                            value: DAY_TYPES.ON_SITE,
                          },
                          {
                            icon: "home",
                            title: t(`spas.type.${DAY_TYPES.TELEWORKING}`),
                            value: DAY_TYPES.TELEWORKING,
                          },
                          {
                            icon: "logout",
                            title: t(`spas.type.${DAY_TYPES.TRAVEL}`),
                            value: DAY_TYPES.TRAVEL,
                          },
                          {
                            icon: "power_settings_new",
                            title: t(`spas.type.${DAY_TYPES.DAY_OFF}`),
                            value: DAY_TYPES.DAY_OFF,
                          },
                        ]}
                      />
                    </Col>
                  )}
                  <Col span={24}>
                    <Card bodyStyle={{ padding: 0 }}>
                      <Calendar
                        value={_date}
                        onSelect={(date) => {
                          const day = date.format("YYYY-MM-DD");
                          const hasSlot =
                            _tab === TABS.MY_PLANNING ? slots?.some((s) => s.date === day) : true;

                          if (isSmallScreen && hasSlot) setOpenDetailsDrawer(true);
                          navigate({ date });
                        }}
                        onChange={() => logEvent(analytics, "spaas_select_date_current_week")}
                        validRange={[dateMin, dateMax]}
                        disabledDate={(date) => {
                          const day = date.format("YYYY-MM-DD");
                          const isBefore = date.isBefore(moment(), "date");
                          const isAfter = date.isAfter(dateMax, "date");
                          const inWorkingDays = workingDays.includes(date.isoWeekday());
                          const inHolidays = holidays.includes(day);

                          const hasSlot =
                            _tab === TABS.MY_PLANNING
                              ? slots?.some((s) => s.date === day)
                              : planningDayCounts?.[day];

                          return (!hasSlot && isBefore) || isAfter || !inWorkingDays || inHolidays;
                        }}
                        headerRender={() => null}
                        className="spass-calendar"
                        dateCellRender={(date) => {
                          const todaySlots = slots.filter((s) => {
                            return moment(s.date).isSame(date, "date");
                          });
                          return (
                            <div
                              style={{
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "end",
                                paddingBottom: 8,
                              }}
                            >
                              {_tab === TABS.MY_PLANNING ? (
                                <MyPlanningViewCalendarItem
                                  slots={todaySlots.sort(morningFirst)}
                                  key={date.format("YYYY-MM-DD")}
                                />
                              ) : (_tab === TABS.MY_TEAM || _tab === TABS.MY_CONTACTS) &&
                                !!planningDayCounts?.[date.format("YYYY-MM-DD")] ? (
                                <MyTeamViewCalendarItem
                                  type={type}
                                  count={planningDayCounts?.[date.format("YYYY-MM-DD")]}
                                />
                              ) : null}
                            </div>
                          );
                        }}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>

              <ConditionalWrapper
                condition={isSmallScreen}
                renderIf={({ children }) => (
                  <Drawer
                    width={500}
                    placement="right"
                    open={openDetailsDrawer}
                    onClose={() => setOpenDetailsDrawer(false)}
                  >
                    {children}
                  </Drawer>
                )}
                renderElse={({ children }) => (
                  <div style={{ flexGrow: 1, flexBasis: "calc(35% - 10px)", minWidth: 450 }}>
                    {children}
                  </div>
                )}
              >
                <ConditionalWrapper
                  condition={isSmallScreen}
                  renderElse={({ children }) => (
                    <Card style={{ height: calendarPanelDimensions.height || 0 }}>{children}</Card>
                  )}
                >
                  {_tab === TABS.MY_TEAM || _tab === TABS.MY_CONTACTS ? (
                    <SpaasStats
                      isSmallScreen={isSmallScreen}
                      ref={spaasStatsRef}
                      date={_date}
                      tab={_tab}
                    />
                  ) : (
                    <MyPlanning
                      isSmallScreen={isSmallScreen}
                      date={_date}
                      onRequestConfirmPresence={setSlotToConfirm}
                      onRequestCancel={setSlotToCancel}
                      onSeeMap={(slot) => {
                        const query = new URLSearchParams();
                        query.append("siteId", slot.request.siteId);
                        query.append("date", slot.date);
                        query.append("view", "contacts");
                        return history.push({
                          pathname: `${baseName}/map`,
                          search: query.toString(),
                          state: { spaceId: slot.spaceId },
                        });
                      }}
                    />
                  )}
                </ConditionalWrapper>
              </ConditionalWrapper>
            </div>
          </Layout.Content>
        </Layout.Content>
      </Layout>

      {!!showCreateModal && (
        <CreateRequestModal
          mode={_tab === TABS.MY_TEAM ? "team" : "self"}
          visible={showCreateModal}
          onFinish={() => {
            const date = moment().set({ month, year });
            refreshTeamDayCounts({
              type,
              startDate: date.clone().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
              endDate: date.clone().add(1, "month").endOf("month").format("YYYY-MM-DD"),
            });
            spaasStatsRef.current?.refresh();
            setShowCreateModal(false);
          }}
          onCancel={() => {
            setShowCreateModal(false);
          }}
        />
      )}

      <ManagePeopleModal
        visible={showManagePeopleModal}
        onCancel={() => setShowManagePeopleModal(false)}
      />

      <RequestModalPresence slot={slotToConfirm} onCancel={() => setSlotToConfirm(null)} />

      <ModalCancel slot={slotToCancel} onClose={() => setSlotToCancel(null)} />
    </>
  );
};

export default SpaceBookingResident;
