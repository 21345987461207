import React, { useState } from "react";
import Settings from "../../../Confort/Settings/Settings";
import Header from "../Header";
import Room from "./Room";
import SearchRoom from "./SearchRoom";
import styles from "./SmartControl.module.less";

const SmartControl = () => {
  const [selectedRoom, setSelectedRoom] = useState(null);
  const [isZone, setIsZone] = useState(false);
  const [equipment, setEquipment] = useState(null);
  const [isAuto, setIsAuto] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [backDrop, setBackDrop] = useState(false);
  return (
    <>
      {/* backdrop */}
      <div
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.3)",
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: 900,
          display: backDrop ? "block" : "none",
        }}
        onClick={() => setBackDrop(false)}
      />

      <Header
        allowReturn={true}
        isZone={isZone}
        setIsZone={setIsZone}
        selectedRoom={selectedRoom}
      />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          minHeight: "100%",
          gap: 16,
        }}
        className={styles.SmartControl}
      >
        <SearchRoom
          selectedRoom={selectedRoom}
          setSelectedRoom={setSelectedRoom}
          setIsZone={setIsZone}
          setBackDrop={setBackDrop}
        />
        {!isZone ? (
          <Room
            selectedRoom={selectedRoom}
            setIsAuto={setIsAuto}
            isAuto={isAuto}
            setIsZone={setIsZone}
            setEquipment={setEquipment}
          />
        ) : (
          <Settings
            setIsAuto={setIsAuto}
            room={selectedRoom}
            nameEquipment={equipment}
            setIsLoading={setIsLoading}
          />
        )}
      </div>
    </>
  );
};

export default SmartControl;
