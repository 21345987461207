import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import Logo from "@tokens/Assets/web/img/logo/01_standard.png";
import { Grid, Image, Layout, Menu } from "antd";
import { isNil } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation } from "react-router-dom";
import { getViewURL } from "../../../hook/useView";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";

// Firebase
import { getAnalytics, logEvent } from "firebase/analytics";
import app from "../../../services/api/Firebase/Firebase";
import { addLastView, removeLastView } from "../../../services/redux/services/ClientsWS";
import { removeFilter } from "../../../services/redux/services/FastbookingWS";
import { getListReport, getReport } from "../../../services/redux/services/PowerBiWS";
import Places from "../Places/Places";

import keycloak from "../../../keycloak";

const analytics = getAnalytics(app);

const getTokenQueryParam = () => {
  try {
    return `access_token=${keycloak.token}`;
  } catch (error) {
    return "";
  }
};

const Sider = ({ setIsOnboarding }) => {
  const { i18n, t } = useTranslation();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const location = useLocation();
  const { size } = useDesignTokens();

  const [collapsed, setCollapsed] = useState(false);
  const [current, setCurrent] = useState(location.pathname);
  const [visible, setVisible] = useState(false);
  const [viewId, setViewId] = useState(null);
  const [deskUrl, setDeskUrl] = useState(null);
  const userData = useSelector((state) => state.userWS.userData);
  const campus = useSelector((state) => state.clientsWS.campus);
  const listReports = useSelector((state) => state.powerBiWS.listReport);
  const sendbirdUnreadCount = useSelector((state) => state.instantMessagingWS.unreadCount);

  const onCollapse = () => setCollapsed(!collapsed);

  function handleClick(e) {
    if (e.key.split("/")[1] !== "places") {
      setCurrent(e.key);
    }
  }

  useEffect(() => {
    const pathname = location.pathname.split("?")[0].split("/")[1];
    if (pathname !== "news") {
      setCurrent(pathname || "/");
    }
  }, [location]);

  useEffect(() => {
    if (userData) {
      const url = `${process.env.REACT_APP_HELPDESK_URL}${
        userData.campus?.find((c) => c.id === campus?.mapData?.id)?.slug
      }`;
      setDeskUrl(url);
    }
  }, [userData, campus]);

  useEffect(() => {
    dispatch(getListReport());
  }, [dispatch]);

  const getItem = (label, key, icon, children, type) => {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  };

  const firstMenu = [
    getItem(
      <NavLink
        to="/"
        exact
        onClick={() => {
          dispatch(removeFilter());
          dispatch(removeLastView());
        }}
      >
        {t("dashboard", { ns: "csv" })}
      </NavLink>,
      "/",
      <Glyph name="dashboard" />,
    ),
    ...((userData &&
      userData?.views
        ?.filter((v) => !!v.layout?.menu)
        .sort((a, b) => a.layout.menu.position - b.layout.menu.position)
        .map((view) => {
          if (view?.type === "webview") {
            return getItem(
              <a
                href={view?.url}
                rel="noreferrer"
                target="_blank"
                onClick={() => {
                  dispatch(removeFilter());
                  logEvent(analytics, "links_details", {
                    link_name: `${view.url}`,
                    tile_name: `${view.title}`,
                  });
                }}
              >
                {t(view.title, { ns: "csv" })}
              </a>,
              getViewURL(view),
              <Glyph name={view.icon} />,
            );
          } else if (view?.type === "links") {
            return getItem(t(view.title, { ns: "csv" }), view?.id, <Glyph name={view.icon} />, [
              ...((view &&
                view?.views?.map((view) =>
                  getItem(
                    view?.type === "webview" ? (
                      <a
                        rel="noreferrer"
                        href={view?.url}
                        target="_blank"
                        onClick={() => {
                          dispatch(removeFilter());
                          logEvent(analytics, "links_details", {
                            link_name: `${view.url}`,
                            tile_name: `${view.title}`,
                          });
                        }}
                      >
                        {t(view.title, { ns: "csv" })}
                      </a>
                    ) : view?.type === "places" ? (
                      <div>
                        <div
                          style={{
                            position: "absolute",
                            width: "100%",
                            height: 50,
                            margin: "-20px 0 0 -30px",
                          }}
                          onClick={() => {
                            setViewId(view?.id);
                            setVisible(true);
                          }}
                        />
                        <div style={{ width: "fit-content" }}>{t(view.title, { ns: "csv" })}</div>
                      </div>
                    ) : (
                      <NavLink
                        onClick={() => {
                          dispatch(removeFilter());
                          logEvent(analytics, `${view.title}_access`, {
                            from: view.title,
                          });
                        }}
                        to={getViewURL(view)}
                      >
                        {t(view.title, { ns: "csv" })}
                      </NavLink>
                    ),
                    getViewURL(view),
                    <Glyph name={view.icon} />,
                  ),
                )) ||
                []),
            ]);
          } else if (view?.type === "reception") {
            return getItem(
              <a
                rel="noreferrer"
                href={view?.url}
                target="_blank"
                onClick={() => dispatch(removeFilter())}
              >
                {t(view.title, { ns: "csv" })}
              </a>,
              getViewURL(view),
              <Glyph name={view.icon} />,
            );
          } else if (view?.type === "instantMessaging") {
            return {
              ...getItem(
                <NavLink
                  onClick={() => {
                    dispatch(addLastView(view.type));
                    logEvent(analytics, `${view.title}_access`, {
                      from: view.title,
                    });
                    setIsOnboarding(view?.onboarding);
                  }}
                  to={getViewURL(view)}
                >
                  {t(view.title, { ns: "csv" })}
                </NavLink>,
                getViewURL(view),
                <div>
                  <Glyph
                    name="fiber_manual_record"
                    style={{
                      position: "absolute",
                      left: 4,
                      fontSize: 10,
                      top: 17,
                      color: "red",
                      display: sendbirdUnreadCount > 0 ? "block" : "none",
                    }}
                  />
                  <Glyph name={view.icon} style={{ fontSize: "16px" }} />
                </div>,
              ),
              key: "instantMessaging",
            };
          } else {
            const menuItem = {
              ...getItem(
                <NavLink
                  onClick={() => {
                    dispatch(addLastView(view.type));
                    dispatch(removeFilter());
                    logEvent(analytics, `${view.title}_access`, {
                      from: view.title,
                    });
                    setIsOnboarding(view?.onboarding);
                  }}
                  to={getViewURL(view)}
                >
                  {t(view.title, { ns: "csv" })}
                </NavLink>,
                getViewURL(view),
                <Glyph name={view.icon} />,
              ),
              key:
                view.type === "socialnews" || view.type === "news"
                  ? view.type + "_" + view.id
                  : view.type,
            };
            return menuItem;
          }
        })) ||
      []),
    {
      type: "divider",
    },

    userData?.profile?.rights?.communication &&
      getItem(t("CreateActu"), "sub2", <Glyph name="article" />, [
        ...((userData &&
          userData?.views
            ?.filter((v) => v.type === "socialnews" || v.type === "news")
            ?.map((view, index) =>
              getItem(
                <NavLink
                  key={index}
                  to={`/news/${view.id}/editor`}
                  onClick={() => dispatch(removeFilter())}
                >
                  {t(view.title, { ns: "csv" })}
                </NavLink>,
                `${index}`,
                <Glyph name={view.icon} />,
              ),
            )) ||
          []),
      ]),

    userData?.views?.some((v) => v.type === "facilities") &&
      userData?.profile?.rights?.facility &&
      getItem(
        <NavLink to={`/dashboard_facilities`} onClick={() => dispatch(removeFilter())}>
          {t("dashboard_facilities", { ns: "csv" })}
        </NavLink>,
        "dashboard_facilities",
        <Glyph name="event" />,
      ),
    userData?.profile?.rights?.ticketing &&
      getItem(
        <a
          href={deskUrl + `?${getTokenQueryParam()}`}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            dispatch(removeFilter());
            logEvent(analytics, "links_details", {
              link_name: `${process.env.REACT_APP_HELPDESK_URL}${
                userData?.campus?.find((c) => c.id === campus?.mapData?.id)?.slug
              }`,
              tile_name: "HelpDesk",
            });
          }}
        >
          {t("helpDesk", { ns: "csv" })}
        </a>,
        "sub4",
        <Glyph name="warning" />,
      ),

    userData?.views?.some((v) => v.type === "reception")?.url &&
      userData?.profile?.rights?.accueil &&
      isNil(userData?.views?.find((v) => v.type === "reception")?.layout) &&
      getItem(
        <a
          href={userData?.views?.find((v) => v.type === "reception")?.url}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            dispatch(removeFilter());
            logEvent(analytics, "links_details", {
              link_name: userData?.views?.find((v) => v.type === "reception")?.url,
              tile_name: "WelcomeDesk",
            });
          }}
        >
          {t("visitor_reception", { ns: "csv" })}
        </a>,
        "sub5",
        <Glyph name={userData?.views?.find((v) => v.type === "reception")?.icon} />,
      ),

    userData?.profile?.rights?.analytics &&
      getItem("Analytics", "sub6", <Glyph name="bar_chart" />, [
        ...((listReports &&
          listReports?.map((listReport, index) =>
            getItem(
              <NavLink
                key={index}
                to="/powerBi"
                exact
                onClick={() => {
                  dispatch(removeFilter());
                  dispatch(getReport(listReport?.reportId));
                }}
              >
                {
                  listReport?.report?.title?.find((l) => l.locale === i18n.language.split("-")[0])
                    ?.label
                }
              </NavLink>,
              `${index}`,
            ),
          )) ||
          []),
        userData?.views?.find((v) => v.type === "analytics") &&
          getItem(
            <a
              rel="noreferrer"
              href={userData?.views?.find((v) => v.type === "analytics")?.url}
              target="_blank"
            >
              {t(userData?.views?.find((v) => v.type === "analytics")?.title, { ns: "csv" })}
            </a>,
          ),
      ]),

    userData?.profile?.rights?.manageExt &&
      getItem(
        <NavLink to="/manageExternal" exact>
          {t("manageExt", { ns: "csv" })}
        </NavLink>,
        <Glyph name="dashboard" />,
      ),
  ];

  return (
    userData?.views?.length !== 1 && (
      <>
        <Layout.Sider
          breakpoint={"xxl"}
          collapsible
          collapsed={collapsed}
          onCollapse={onCollapse}
          width="250"
          trigger={
            collapsed ? (
              <MenuUnfoldOutlined style={{ fontSize: size.xxl }} />
            ) : (
              <MenuFoldOutlined style={{ fontSize: size.xxl }} />
            )
          }
          style={{
            zIndex: "999",
            height: "100vh",
            overflow: "auto",
            position: !screens.xxl ? "fixed" : undefined,
          }}
        >
          <div
            className="logo"
            style={{
              height: "80px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={Logo} width={104} preview={false} />
          </div>
          <Menu
            onClick={handleClick}
            items={firstMenu}
            defaultSelectedKeys={["/"]}
            mode="vertical"
            style={{ height: "calc(100% - 80px)", overflowY: "auto" }}
            selectedKeys={[current]}
          />
        </Layout.Sider>
        {visible && (
          <Places
            visible={visible}
            viewId={viewId}
            onCancel={() => setVisible(false)}
            setVisible={setVisible}
          />
        )}
      </>
    )
  );
};

export default Sider;
