import { Avatar, Card, Col, Row, Tag, Typography } from "antd";
import moment from "moment";
import { cond, equals } from "ramda";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as UDOff } from "../../../../../../assets/svg/undraw_a_day_off.svg";
import { ReactComponent as UDTravel } from "../../../../../../assets/svg/undraw_businesswoman.svg";
import { ReactComponent as UDTeleworking } from "../../../../../../assets/svg/undraw_home_settings.svg";
import { ReactComponent as UDOnSite } from "../../../../../../assets/svg/undraw_in_the_office.svg";
import Glyph from "../../../../../Common/Glyph/Glyph";
import RoomDetails from "../../../../../Common/RoomDetails/RoomDetails";
import { DAY_TYPES, DAY_TYPES_ICONS } from "../../../SpaceBookingResident";

const UNDRAW_STYLES = {
  padding: 20,
  height: 200,
  width: "auto",
  display: "block",
  margin: "auto",
};

const CreateStepReview = ({ hasStepSpace, values }) => {
  const { t } = useTranslation();

  logger.log("values", values);

  const { siteId, spaceId } = values;

  const mapData = useSelector((state) => state.mapDataWS[siteId]?.mapData);

  const desk = useMemo(() => {
    return mapData?.workplaces.find((r) => r.id === spaceId);
  }, [mapData?.workplaces, spaceId]);

  const rooms = useMemo(() => {
    const roomIds = [...(values.roomIds || []), desk?.roomId].filter(Boolean);
    return roomIds.map((roomId) => mapData?.resources.find((r) => r.id === roomId));
  }, [desk?.roomId, mapData?.resources, values.roomIds]);

  logger.log("rooms", hasStepSpace, rooms);

  const room = rooms.length === 1 ? rooms[0] : null;

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <div
        style={{
          padding: "0px 20px",
          flexBasis: "50%",
          overflowY: "auto",
          borderRight: "1px solid #ececec",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card bordered={false} style={{ flexGrow: 1 }}>
          <Row gutter={[10, 10]}>
            {(!hasStepSpace || rooms.length > 1) && (
              <>
                <Col span={24}>
                  {cond([
                    [equals(DAY_TYPES.ON_SITE), () => <UDOnSite style={UNDRAW_STYLES} />],
                    [equals(DAY_TYPES.DAY_OFF), () => <UDOff style={UNDRAW_STYLES} />],
                    [equals(DAY_TYPES.TELEWORKING), () => <UDTeleworking style={UNDRAW_STYLES} />],
                    [equals(DAY_TYPES.TRAVEL), () => <UDTravel style={UNDRAW_STYLES} />],
                  ])(values.type)}
                </Col>
                <Col span={24}>
                  <Card>
                    <Glyph
                      style={{ marginRight: 8 }}
                      className={"secondary"}
                      name={DAY_TYPES_ICONS[values.type]}
                    />
                    <Typography.Text strong>{t(`spas.type.${values.type}`)}</Typography.Text>
                  </Card>
                </Col>
                {rooms.length > 1 && (
                  <Col span={24}>
                    <Card>
                      <Glyph style={{ marginRight: 8 }} className={"secondary"} name={"desk"} />
                      <Typography.Text strong>{t("Dans les salles")}</Typography.Text>
                    </Card>
                    {rooms.map((room, index) => (
                      <Tag key={index} style={{ marginTop: 8 }}>
                        {room.title}
                      </Tag>
                    ))}
                  </Col>
                )}
              </>
            )}
            {hasStepSpace && !!room && (
              <>
                <Col span={24}>
                  <RoomDetails.Photos room={room} />
                </Col>
                <Col span={24}>
                  <RoomDetails.Equipments room={room} />
                </Col>
                <Col flex={"auto"}>
                  <RoomDetails.Title room={room} />
                </Col>
                <Col>
                  <RoomDetails.Floor className={"secondary"} room={room} />
                </Col>
                {desk && (
                  <Col span={24}>
                    <Card>
                      <Row>
                        <Glyph style={{ marginRight: 8 }} name="desk" className={"secondary"} />
                        <Typography.Text strong>
                          {desk?.title || desk?.map?.findAndOrder?.placeId}
                        </Typography.Text>
                      </Row>
                    </Card>
                  </Col>
                )}
              </>
            )}
          </Row>
        </Card>
      </div>
      <div
        style={{
          padding: "0px 20px",
          flexBasis: "50%",
          overflowY: "auto",
          height: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Card bordered={false} style={{ flexGrow: 1 }}>
          <Row gutter={[10, 10]}>
            {!!values.startDate && (
              <Col span={24}>
                <Card>
                  <Row>
                    <Col flex={"auto"}>
                      <Glyph style={{ marginRight: 8 }} name="event" className={"secondary"} />
                      <Typography.Text strong>{t("spas.request.startDate.helper")}</Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        {moment(values.startDate).format("DD MMM YYYY")}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )}
            <Col span={24}>
              <Card>
                <Glyph style={{ marginRight: 8 }} name={"schedule"} className={"secondary"} />
                <Typography.Text strong>{t(`spas.period.${values.period}`)}</Typography.Text>
              </Card>
            </Col>
            {!!values.users && (
              <Col span={24}>
                <Card>
                  <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
                    <Glyph name="groups" className={"secondary"} />
                    <Typography.Text style={{ flex: 1 }} strong>
                      {t("spas.request.members.title")}
                    </Typography.Text>
                    <Avatar.Group
                      maxCount={3}
                      style={{ margin: "-5px 0" }}
                      maxPopoverTrigger={"focus"}
                    >
                      {values.users.map((user) => (
                        <Avatar src={user.photoUrl}>
                          {user.firstname.charAt(0).toUpperCase()}
                        </Avatar>
                      ))}
                    </Avatar.Group>
                  </div>
                </Card>
              </Col>
            )}
            {!!values.dates?.length && (
              <Col span={24} style={{ marginBottom: 20 }}>
                <Card>
                  <Glyph style={{ marginRight: 8 }} name={"schedule"} className={"secondary"} />
                  <Typography.Text strong>{t("spas.request.dates")}</Typography.Text>
                </Card>
                {values.dates.map((date, index) => (
                  <Tag key={index} style={{ marginTop: 8 }}>
                    {moment(date).format("DD MMM YYYY")}
                  </Tag>
                ))}
              </Col>
            )}
            {!!values.startDate && (
              <Col span={24}>
                <Card>
                  <Glyph style={{ marginRight: 8 }} name={"sync"} className={"secondary"} />
                  <Typography.Text strong>
                    {t(`spas.recurrence.${values.recurrence || "NONE"}`, {
                      day: moment(values.startDate).format("dddd"),
                    })}
                  </Typography.Text>
                </Card>
              </Col>
            )}
            {!!values.startDate && (values.recurrence || "NONE") !== "NONE" && (
              <Col span={24}>
                <Card>
                  <Row>
                    <Col flex={"auto"}>
                      <Glyph style={{ marginRight: 8 }} name="event" className={"secondary"} />
                      <Typography.Text strong>
                        {t("spas.request.endDate", { date: "" })}
                      </Typography.Text>
                    </Col>
                    <Col>
                      <Typography.Text>
                        {moment(values.endDate).format("DD MMM YYYY")}
                      </Typography.Text>
                    </Col>
                  </Row>
                </Card>
              </Col>
            )}
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default CreateStepReview;
