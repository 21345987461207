import { Alert, Button, Card, Col, Row, Table, Typography, Upload } from "antd";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../hook/useDesignTokens";
import { addFilesExternalUser } from "../../../services/redux/services/ManageExternalWS";
import Glyph from "../../Common/Glyph/Glyph";
import { TitleSource } from "../../Common/Teleporters/Title";

const ManageExternalUser = () => {
  const { t, i18n } = useTranslation();
  const { colors, size } = useDesignTokens();
  const dispatch = useDispatch();

  const campus = useSelector((state) => state.clientsWS.campus);
  const filesInfos = useSelector((state) => state.manageExternalWS.filesInfos);

  const props = {
    onChange: ({ fileList }) => {
      dispatch(
        addFilesExternalUser({
          file: fileList.map((file) => file.originFileObj),
          siteId: campus?.mapData?.id,
          locale: i18n.language.split("-")[0],
        }),
      );
    },
    beforeUpload: () => {
      return false;
    },
  };

  const columns = [
    {
      title: t("transfert.row"),
      dataIndex: "row",
      key: "row",
    },
    {
      title: t("transfert.errors"),
      dataIndex: "errors",
      key: "errors",
    },
  ];

  const data = filesInfos?.errors?.map((error, index) => {
    return {
      key: index,
      row: error.row,
      errors: error.errors.join(" / "),
    };
  });

  return (
    <>
      <TitleSource>{t("manageExt", { ns: "csv" })}</TitleSource>

      <Row gutter={[10, 10]} style={{ justifyContent: "center" }}>
        <Col xl={20} xxl={20}>
          <Row gutter={[10, 10]} style={{ justifyContent: "center" }}>
            <Col span={14}>
              <Card
                title={
                  <Typography.Title level={5} style={{ margin: "0", textAlign: "center" }}>
                    {t("transfert.addAndUpdateFile")}
                  </Typography.Title>
                }
              >
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Upload {...props} className="upload-sider" maxCount={1}>
                    <Button
                      size="large"
                      type="primary"
                      icon={
                        <Glyph
                          name="note_add"
                          style={{ fontWeight: "normal", fontSize: size.xxxxl }}
                        />
                      }
                    >
                      <Typography.Text style={{ color: "white" }}>
                        {t("transfert.addFile")}
                      </Typography.Text>
                    </Button>
                  </Upload>
                </div>
                {filesInfos?.isSuccess === false && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "16px",
                      flexFlow: "column",
                    }}
                  >
                    <Alert message={t("transfert.error")} type="error" />
                    <Table pagination={false} columns={columns} dataSource={data} />
                  </div>
                )}

                {filesInfos?.isSuccess === true && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "16px",
                      flexFlow: "column",
                    }}
                  >
                    <Alert message={t("transfert.success")} type="success" />
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
                      <Typography.Text
                        strong
                        style={{ marginRight: "8px", color: colors?.success_light }}
                      >
                        {t("transfert.created")} : {filesInfos?.created}
                      </Typography.Text>
                      <Typography.Text
                        strong
                        style={{ marginRight: "8px", color: colors?.pending_light }}
                      >
                        {t("transfert.updated")} : {filesInfos?.updated}
                      </Typography.Text>
                      <Typography.Text strong style={{ color: colors?.error_light }}>
                        {t("transfert.deleted")} : {filesInfos?.deleted}
                      </Typography.Text>
                    </div>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default ManageExternalUser;
