import { Input, Space, Typography } from "antd";
import Glyph from "../../Common/Glyph/Glyph";
import { useTranslation } from "react-i18next";
import Tile from "../../Common/Tile/Tile";
import { useState } from "react";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";

const Search = ({ categorie, setPlace }) => {
  const { t } = useTranslation();

  const [searchPlaces, setSearchPlaces] = useState("");

  const handleSearch = (evt) => {
    setSearchPlaces(evt.target.value);
  };

  const searchResult = categorie?.places
    ?.filter((value) => (searchPlaces ? new RegExp(searchPlaces, "i").test(value.title) : true))
    .sort((a, b) => (a.title > b.title ? 1 : -1));

  return (
    <>
      <Input
        id="pac-input"
        placeholder={t("around_me_search")}
        prefix={<Glyph name="search" />}
        onChange={handleSearch}
        value={searchPlaces}
      />
      <Space
        style={{ width: "100%", padding: "15px 0", gap: "5px" }}
        direction="vertical"
        size="small"
      >
        {searchResult?.map((cat, index) => (
          <Tile
            key={index}
            style={{ width: "100%", margin: "0" }}
            onClick={() => {
              setPlace(cat);
              logEvent(analytics, "around_me_select_poi_from_list");
            }}
          >
            <Typography.Text strong>{cat?.title}</Typography.Text>
            <Glyph name="navigate_next" />
          </Tile>
        ))}
      </Space>
    </>
  );
};

export default Search;
