import { Button, Card, Input, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import { useDispatch, useSelector } from "react-redux";
import { getContactDetails, updateProfile } from "../../../services/redux/services/ContactListWS";

const RightPanelMiscEdit = ({ userData }) => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { colors } = useDesignTokens();
  const bioSection = userData.aboutMe.find((section) => section.key === "BIO");
  const hobbiesSection = userData.aboutMe.find((section) => section.key === "HOBBIES");

  const [isModified, setIsModified] = useState(false);
  const [bioInput, setBioInput] = useState(bioSection.content);
  const [hobbies, setHobbies] = useState(hobbiesSection.items);
  const [newHobbyInput, setNewHobbyInput] = useState("");

  const campus = useSelector((state) => state.clientsWS.campus);

  const handleRemoveListItem = (index) => {
    const updatedItems = hobbies.slice();
    updatedItems.splice(index, 1);

    setIsModified(true);
    setHobbies(updatedItems);
  };

  const handleAddListItem = () => {
    if (newHobbyInput === "") return;
    setIsModified(true);
    setHobbies(hobbies.concat(newHobbyInput));
    setNewHobbyInput("");
  };

  const handleBioChange = (value) => {
    setIsModified(true);
    setBioInput(value);
  };

  const handleSave = async () => {
    const fullHobbies = newHobbyInput === "" ? hobbies : hobbies.concat(newHobbyInput);
    const update = await dispatch(
      updateProfile({
        aboutMe: [
          { key: "BIO", title: "Biographie", icon: "bio", type: "TEXT", content: bioInput },
          { key: "HOBBIES", title: "Hobbies", icon: "hobbies", type: "LIST", items: fullHobbies },
        ],
      }),
    );
    // logger.log(update);

    dispatch(
      getContactDetails({
        siteId: campus?.mapData?.id,
        userId: userData.contact.id,
        locale: i18n.language,
      }),
    );
    setHobbies(update.meta.arg.aboutMe[1].items);

    setNewHobbyInput("");
    setIsModified(false);
  };

  useEffect(() => {
    const isBioModified = bioSection.content !== bioInput;
    const isHobbiesModified =
      hobbiesSection.items.join() !== hobbies.join() || newHobbyInput !== "";

    if (isBioModified || isHobbiesModified) {
      setIsModified(true);
    } else {
      setIsModified(false);
    }
  }, [
    bioInput,
    bioSection.content,
    hobbies,
    hobbiesSection.items,
    newHobbyInput,
    userData.aboutMe,
  ]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        paddingBottom: "16px",
      }}
    >
      <div style={{ width: "100%" }}>
        <div style={{ marginBottom: "32px", display: "flex", flexDirection: "column", gap: "8px" }}>
          <Typography.Text strong>{bioSection.title}</Typography.Text>
          <Input
            value={bioInput}
            onChange={(e) => handleBioChange(e.target.value)}
            prefix={<Glyph name="edit" style={{ color: colors.grey_40 }} />}
            placeholder={t("contactList.bioPlaceholder")}
            onPressEnter={(e) => {
              e.target.blur();
            }}
          />

          <Typography.Text strong style={{ marginTop: "10px" }}>
            {hobbiesSection.title}
          </Typography.Text>

          {hobbies.map((item, index) => (
            <Card key={index}>
              <div
                style={
                  userData.profile && {
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                  }
                }
              >
                <Typography.Text>{item}</Typography.Text>
                {userData.profile && (
                  <Glyph
                    name="cancel"
                    style={{ cursor: "pointer", color: colors.grey_40 }}
                    onClick={() => handleRemoveListItem(index)}
                  />
                )}
              </div>
            </Card>
          ))}

          <Input
            value={newHobbyInput}
            onChange={(e) => setNewHobbyInput(e.target.value)}
            prefix={<Glyph name="edit" style={{ color: colors.grey_40 }} />}
            placeholder={t("contactList.addHobby")}
            suffix={
              <Glyph
                name="add"
                onClick={handleAddListItem}
                style={{ color: colors.grey_40, cursor: "pointer", marginRight: "8px" }}
              />
            }
            onPressEnter={() => handleAddListItem()}
          />
        </div>
      </div>
      <Button
        block
        type="primary"
        icon={<Glyph name="save" />}
        onClick={() => handleSave()}
        style={{ width: "320px" }}
        disabled={!isModified}
      >
        {t("contactList.save")}
      </Button>
    </div>
  );
};

export default RightPanelMiscEdit;
