import { Divider, Segmented, Typography } from "antd";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import { useEffect, useRef, useState } from "react";
import styles from "./QrCode.module.less";
import clsx from "clsx";
import QRCodeStyling from "qr-code-styling";
import { useTranslation } from "react-i18next";

const QrCode = () => {
  const { colors, shadows, symbols } = useDesignTokens();
  const { t } = useTranslation();

  const ref = useRef(null);
  const qrCode = useRef(
    new QRCodeStyling({
      width: 150,
      height: 150,
      dotsOptions: {
        color: colors.primary_base,
        type: "rounded",
      },
      backgroundOptions: {
        color: "transparent",
      },
      imageOptions: {
        imageSize: 1,
        hideBackgroundDots: false,
      },
      qrOptions: {
        errorCorrectionLevel: "L",
      },
    }),
  );

  const [os, setOs] = useState("ios");
  const [active, setActive] = useState(false);

  useEffect(() => {
    qrCode.current.append(ref.current);
  }, []);

  useEffect(() => {
    qrCode.current.update({
      data: os === "ios" ? process.env.REACT_APP_STORE_IOS : process.env.REACT_APP_STORE_ANDROID,
    });
  }, [os]);

  return (
    <div
      style={{
        backgroundColor: "white",
        padding: "10px",
        position: "absolute",
        bottom: 0,
        right: 16,
        borderRadius: `${symbols?.base_shape?.radius}px ${symbols?.base_shape?.radius}px 0 0`,
        boxShadow: `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`,
        cursor: "pointer",
        width: 320,
        zIndex: 999,
      }}
      onClick={() => setActive(!active)}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <Glyph name="lightbulb" style={{ color: colors?.grey_40, marginRight: "8px" }} />
        <Typography.Text strong>
          {t("qrcode.title", { app: process.env.REACT_APP_ONGLET })}
        </Typography.Text>
        <Glyph name={!active ? "expand_less" : "expand_more"} style={{ marginLeft: "8px" }} />
      </div>

      <div
        style={{
          transition: "height 0.3s ease",
          overflow: "hidden",
          height: active ? 285 : 0,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 8,
        }}
      >
        <Divider style={{ margin: "12px 0 4px 0" }} />

        <Segmented
          size="large"
          value={os}
          options={[
            {
              label: <p style={{ margin: "0" }}>iOS</p>,
              value: "ios",
            },
            {
              label: <p style={{ margin: "0" }}>Android</p>,
              value: "android",
            },
          ]}
          onChange={(value) => {
            setOs(value);
          }}
        />

        <Typography.Text type="secondary" style={{ textWrap: "balance", textAlign: "center" }}>
          {t("qrcode.content")}
        </Typography.Text>

        <div className={styles["qrcode"]} ref={ref} />
      </div>
    </div>
  );
};

export default QrCode;
