import { useKeycloak } from "@react-keycloak/web";
import { Card, Grid, Image, Popover, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import Glyph from "../../../../Common/Glyph/Glyph";
import CreateStepWorkplaceMap from "../Component/CreateStepWorkplaceMap";
import MapModal from "./MapModal";
import TicketTabGeneralForm from "./TicketTabGeneralForm";

const TicketTabGeneral = ({ data, showCreateOrEditTicketModal }) => {
  const { colors, symbols } = useDesignTokens();
  const { t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { siteId, stuffId } = useParams();
  const screens = Grid.useBreakpoint();

  const userData = useSelector((state) => state.userWS.userData);
  //const viewId = userData.views.find((v) => v.type === "ticketingV2")?.id;
  const currentViewId = useSelector((state) => state.ticketingV2WS.currentViewId);
  const [showMapModal, setShowMapModal] = useState(false);

  const floorReference = useSelector((state) => state.ticketingV2WS.floors)
    ?.mapData?.find((f) => f.id === siteId)
    ?.resources?.find((r) => r.id === data?.location)?.floorReference;

  useEffect(() => {
    logger.log("userData", userData);
    logger.log("data", data);
    logger.log("floorReference", floorReference);
    logger.log("siteId", siteId);
  }, [userData, data, floorReference, siteId]);

  useEffect(() => {
    logger.log(document.querySelector(".mapboxgl-ctrl-top-right"));
    document.querySelector(".mapboxgl-control-container").style.display = "none !important";
  }, [userData]);

  return (
    <>
      <div
        style={{
          padding: "0px 16px",
          display: "flex",
          flexDirection: "column",
          gap: 24,
          width: "100%",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography.Text strong style={{ fontSize: 17 }}>
            {t("ticketingv2.Description")}
          </Typography.Text>
          <Typography.Text style={{ fontSize: 14 }}>{data.description}</Typography.Text>
        </div>

        <div style={{ display: "flex", flexDirection: "column" }}>
          <Typography.Text strong style={{ fontSize: 17 }}>
            {t("ticketingv2.GeneralInformations")}
          </Typography.Text>
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: 16,
            }}
          >
            <div style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Typography.Text type="secondary" style={{ fontSize: 17 }}>
                {t("ticketingv2.TicketCategory")}
              </Typography.Text>
              <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: colors.light_background,
                    borderRadius: symbols.base_shape.radius,
                    overflow: "hidden",
                    padding: 16,
                  }}
                >
                  <Typography.Text strong>{t("ticketingv2.Type")}</Typography.Text>
                  <Typography.Text>{data.type?.label}</Typography.Text>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    backgroundColor: colors.light_background,
                    borderRadius: symbols.base_shape.radius,
                    overflow: "hidden",
                    padding: 16,
                  }}
                >
                  <Typography.Text strong>{t("ticketingv2.Cat")}</Typography.Text>
                  <Typography.Text>{data.category?.label}</Typography.Text>
                </div>
                {data.subCategory?.label && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      backgroundColor: colors.light_background,
                      borderRadius: symbols.base_shape.radius,
                      overflow: "hidden",
                      padding: 16,
                    }}
                  >
                    <Typography.Text strong>{t("ticketingv2.SubCat")}</Typography.Text>
                    <Typography.Text>{data.subCategory?.label}</Typography.Text>
                  </div>
                )}
              </div>
            </div>

            {data.location && (
              <div style={{ flex: 1 }}>
                <Typography.Text type="secondary" style={{ fontSize: 17 }}>
                  {t("ticketingv2.Localisation")}
                </Typography.Text>
                <div style={{ position: "relative", width: "100%", height: 178 }}>
                  <Card
                    style={{
                      position: "absolute",
                      bottom: 0,
                      width: "100%",
                      borderRadius: symbols.base_shape.radius,
                      overflow: "hidden",
                      zIndex: 3,
                      backgroundColor: "white",
                      height: 42,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        cursor: "pointer",
                        alignItems: "center",
                        height: "100%",
                      }}
                      onClick={() => setShowMapModal(true)}
                    >
                      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        <Glyph
                          name="place"
                          style={{ color: colors.secondary_base, fontSize: 24 }}
                        />
                        <Typography.Text strong>{data.location?.title}</Typography.Text>
                      </div>
                      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        <Typography.Text strong>
                          {t("ticketingv2.Floor_")}
                          {data.location?.floor?.number}
                        </Typography.Text>
                        <Glyph
                          name="navigate_next"
                          style={{ color: colors.primary_dark, fontSize: 24 }}
                        />
                      </div>
                    </div>
                  </Card>
                  <div
                    style={{
                      backgroundColor: colors.light_background,
                      borderRadius: symbols.base_shape.radius,
                      overflow: "hidden",
                      height: "100%",
                      position: "relative",
                    }}
                  >
                    {!showCreateOrEditTicketModal && !showMapModal && (
                      <CreateStepWorkplaceMap siteId={data.siteId} roomId={data.location?.id} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        {(!!data.attachments?.document?.length ||
          !!data.attachments?.picture?.length ||
          !!data.form?.items?.length) && (
          <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
            <Typography.Text strong style={{ fontSize: 17 }}>
              {t("ticketingv2.DetailedInformations")}
            </Typography.Text>
            {data.attachments && (
              <div style={{ display: "flex", width: "100%", gap: 16 }}>
                {!!data.attachments?.picture?.length && (
                  <div style={{ flex: 1 }}>
                    <Typography.Text type="secondary" style={{ fontSize: 17 }}>
                      {t("ticketingv2.Photos")}
                    </Typography.Text>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: screens.xxl
                          ? "repeat(3, 1fr)"
                          : screens.lg
                          ? "repeat(2, 1fr)"
                          : "1fr",
                        gap: 8,
                      }}
                    >
                      {data.attachments?.picture?.map((attachment, i) => (
                        <Popover content={attachment.name} trigger="hover" key={i}>
                          <Image
                            preview={{
                              mask: (
                                <div
                                  style={{
                                    width: "100%",
                                    backgroundColor: colors.secondary_base,
                                    opacity: 0,
                                    height: "100%",
                                    borderRadius: symbols.base_shape.radius,
                                    overflow: "hidden",
                                  }}
                                />
                              ),
                            }}
                            src={`${process.env.REACT_APP_DWM_URL}/api/mobile/apps/${process.env.REACT_APP_ID}/views/${currentViewId}/ticketingV2/tickets/${stuffId}/attachment/${attachment.id}?siteId=${siteId}&access_token=${keycloak.token}`}
                            key={i}
                            style={{
                              width: "100%",
                              aspectRatio: "1.2",
                              objectFit: "cover",
                            }}
                            alt="ticket img"
                            /*mask={
                              <div
                                style={{
                                  backgroundColor: "red",
                                  opacity: 0.5,
                                  width: "100%",
                                  height: "100%",
                                }}
                              />
                            }*/
                          />
                        </Popover>
                      ))}
                    </div>
                  </div>
                )}
                {data.attachments && !!data.attachments?.document?.length && (
                  <div style={{ flex: 1, height: "100%" }}>
                    <Typography.Text type="secondary" style={{ fontSize: 17 }}>
                      {t("ticketingv2.Files")}
                    </Typography.Text>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: screens.xxl
                          ? "repeat(3, 1fr)"
                          : screens.lg
                          ? "repeat(2, 1fr)"
                          : "1fr",
                        gap: 8,
                      }}
                    >
                      {data.attachments.document?.map((document, i) => (
                        <Popover content={document.name} trigger="hover" key={i}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: colors.light_background,
                              borderRadius: symbols.base_shape.radius,
                              overflow: "hidden",
                              width: "100%",
                              aspectRatio: "1.2",
                              height: "100%",
                              cursor: "pointer",
                              gap: 4,
                              padding: 8,
                              lineHeight: 1.2,
                              textAlign: "center",
                            }}
                            onClick={() =>
                              window.open(
                                `${process.env.REACT_APP_DWM_URL}/api/mobile/apps/${process.env.REACT_APP_ID}/views/${currentViewId}/ticketingV2/tickets/${stuffId}/attachment/${document.id}?siteId=${siteId}&access_token=${keycloak.token}`,
                                "_blank",
                              )
                            }
                          >
                            <Glyph name="description" style={{ color: colors.grey_40 }} />
                            <Typography.Text
                              ellipsis
                              style={{ wordBreak: "break-all", fontSize: 12 }}
                            >
                              {document.name}
                            </Typography.Text>
                          </div>
                        </Popover>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
            {!!data.form?.items?.filter((i) => i.type !== "files" && i.type !== "pictures")
              .length && <TicketTabGeneralForm data={data} />}
          </div>
        )}
      </div>
      {showMapModal && (
        <MapModal
          roomId={data.location?.id}
          siteId={data.siteId}
          setShowMapModal={setShowMapModal}
          showMapModal={showMapModal}
        />
      )}
    </>
  );
};

export default TicketTabGeneral;
