import { useKeycloak } from "@react-keycloak/web";
import { Button, Card, Col, Modal, Progress, Row, Space, Tag, Typography } from "antd";
import moment from "moment";
import { isEmpty, isNil } from "ramda";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import useDesignTokens from "../../../hook/useDesignTokens";
import { getPlacesData, getPlacesInfos } from "../../../services/redux/services/PlacesInfosWS";
import Glyph from "../../Common/Glyph/Glyph";
import Booking from "./Booking";
import CancelBooking from "./CancelBooking";
import Cards from "./Cards";
import DetailsMenu from "./DetailsMenu";
import Menu from "./Menu";
import styles from "./Places.module.less";

export const STEPS = {
  DETAILS: "DETAILS",
  BOOKING: "BOOKING",
  MENU: "MENU",
  DETAILSMENU: "DETAILSMENU",
  CARDS: "CARDS",
  CANCELBOOKING: "CANCELBOOKING",
};

const Places = ({ setVisible, visible, onCancel, viewId }) => {
  const { i18n, t } = useTranslation();
  const { colors, shadows, size } = useDesignTokens();
  const history = useHistory();
  const dispatch = useDispatch();
  const { keycloak } = useKeycloak();

  const placeInfo = useSelector(
    (state) => state.placesInfosWS[viewId]?.placesInfos?.contents[0] || [],
  );

  const goSpace = (value) => {
    history.push({ pathname: `/indoorMapping/${value?.location?.placeId}`, state: value });
  };

  const [step, setStep] = useState(STEPS.DETAILS);
  const [data, setData] = useState();
  const [infoBooking, setInfoBooking] = useState();
  const [contentMenu, setContentMenu] = useState();

  const restaurantSlot = placeInfo?.extras?.find(
    (extra) => extra?.type === "restaurant-slots",
  )?.data;

  const placeData = useSelector(
    (state) =>
      state.placesInfosWS[placeInfo?.extras?.find((e) => e?.type === "restaurant-bookings")?.type]
        ?.placesData,
  );
  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);

  useEffect(() => {
    if (isNil(placeInfo?.extras)) return;
    dispatch(
      getPlacesData({
        viewId,
        campusId: mapData?.id,
        date: moment().toISOString(),
        extraType: placeInfo?.extras?.find((e) => e?.type === "restaurant-bookings")?.type,
        extraId: placeInfo?.extras?.find((e) => e?.type === "restaurant-bookings")?.data[0]?.id,
        contentId: placeInfo?.id,
      }),
    );
  }, [dispatch, mapData?.id, placeInfo?.extras, placeInfo?.id, viewId]);

  useEffect(() => {
    dispatch(
      getPlacesInfos({
        viewId,
        locale: i18n.language.split("-")[0],
      }),
    );
  }, [dispatch, i18n.language, viewId]);

  return (
    <Modal
      destroyOnClose={true}
      className={styles["modal_places"]}
      style={{ height: "fit-content" }}
      bodyStyle={{
        display: "block",
        justifyContent: "center",
        minHeight: "100px",
      }}
      width={500}
      title={
        <div style={{ position: "relative", height: step === STEPS.DETAILS ? 32 : 65 }}>
          <div
            style={{
              backgroundColor: "white",
              boxShadow: `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`,
              borderRadius: "100px",
              cursor: "pointer",
              marginRight: "8px",
              position: "absolute",
              top: "20px",
              left: "10px",
              zIndex: "999",
              width: "30px",
              height: "30px",
              alignItems: "center",
              justifyContent: "center",
              display: step === STEPS.DETAILS ? "none" : "flex",
            }}
            onClick={() => {
              setStep(step === STEPS.DETAILSMENU ? STEPS.MENU : STEPS.DETAILS);
            }}
          >
            <Glyph name="arrow_back_ios" style={{ fontSize: size.xl }} />
          </div>

          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {step === STEPS.DETAILS ? (
              !isNil(placeInfo?.extras) && !isEmpty(placeInfo?.extras) ? (
                <>
                  {!!restaurantSlot ? (
                    restaurantSlot.map((e, index) => (
                      <Button
                        key={index}
                        type="primary"
                        icon={<Glyph name={e?.icon} />}
                        onClick={() => {
                          setStep(STEPS.BOOKING);
                          setData(e);
                        }}
                      >
                        {t("Places.Title.Booking")}
                      </Button>
                    ))
                  ) : (
                    <Typography.Title level={5} style={{ margin: "0" }}>
                      {placeInfo?.name}
                    </Typography.Title>
                  )}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <Typography.Title level={5} style={{ margin: "0" }}>
                    {placeInfo?.name}
                  </Typography.Title>
                </div>
              )
            ) : (
              <Typography.Text strong>
                {step === STEPS.BOOKING
                  ? t("Places.Title.Booking")
                  : step === STEPS.CARDS
                  ? t("Places.Title.Solde")
                  : step === STEPS.CANCELBOOKING
                  ? t("Places.Title.Cancel")
                  : t("Places.Title.Menu")}
              </Typography.Text>
            )}
          </div>
        </div>
      }
      open={visible}
      onOk={visible}
      onCancel={() => {
        onCancel();
        setStep(STEPS.DETAILS);
      }}
      footer={null}
    >
      {step === STEPS.DETAILS && (
        <div style={{ display: "flex", flexDirection: "column", gap: 24 }}>
          {placeInfo?.imageUrl && (
            <img
              src={`${placeInfo.imageUrl}?access_token=${keycloak.token}`}
              alt="cover"
              style={{
                width: "calc(100% + 71px)",
                margin: "-15px -35px 0px -36px",
              }}
            />
          )}

          {!isEmpty(placeData?.data) && !isNil(placeData?.data) && (
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Typography.Text strong>{t("Places.Booking.Next")}</Typography.Text>
              <Row gutter={[10, 10]}>
                {placeData?.data?.slice(0, 2)?.map((d, index) => (
                  <Col span={12}>
                    <div style={{ marginBottom: "24px" }} key={index}>
                      <Card
                        bodyStyle={{ display: "flex", flexFlow: "column" }}
                        style={{ marginTop: "10px", cursor: "pointer" }}
                        onClick={() => {
                          setStep(STEPS.CANCELBOOKING);
                          setInfoBooking(d);
                        }}
                      >
                        <Typography.Text strong>{moment(d?.date).format("LL")}</Typography.Text>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "8px",
                          }}
                        >
                          <Typography.Text>
                            {d?.party} pers / {d?.startHour.split(":").slice(0, 2).join(":")} -{" "}
                            {d?.endHour.split(":").slice(0, 2).join(":")}
                          </Typography.Text>
                          <Glyph name="chevron_right" />
                        </div>
                      </Card>
                    </div>
                  </Col>
                ))}
              </Row>
            </div>
          )}

          {!isNil(placeInfo?.affluence) && (
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Typography.Text strong>{t("Places.Frequentation")}</Typography.Text>
              <Card>
                <div style={{ display: "flex", flexFlow: "column" }}>
                  <Typography.Text strong style={{ marginBottom: "8px" }}>
                    <Glyph
                      name="schedule"
                      style={{ marginRight: "8px", color: colors.secondary_base }}
                    />
                    {t("Places.Now")}
                  </Typography.Text>
                  <Progress
                    style={{ textAlign: "center" }}
                    type="circle"
                    percent={
                      isNil(placeInfo?.affluence?.currentForecast?.occupancy)
                        ? 0
                        : placeInfo?.affluence?.currentForecast?.occupancy
                    }
                    trailColor={colors.grey_20}
                    strokeColor={colors.secondary_base}
                    strokeWidth="15"
                    strokeLinecap="square"
                    width={80}
                    format={(percent) => `${percent}%`}
                    status="normal"
                  />
                  <Typography.Text type="secondary" style={{ fontSize: size.s, marginTop: "8px" }}>
                    {t("Places.Frequentation.Content")}
                  </Typography.Text>
                </div>
              </Card>
            </div>
          )}

          {(placeInfo?.phone ||
            placeInfo?.email ||
            !!placeInfo?.openingHours?.length ||
            placeInfo?.location) && (
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Typography.Text strong style={{}}>
                {t("Places.Informations")}
              </Typography.Text>

              {!!placeInfo?.openingHours?.length && (
                <Card
                  style={{
                    alignItems: "center",
                    width: "100%",
                  }}
                  bodyStyle={{ width: "100%" }}
                >
                  <div style={{ display: "flex", flexFlow: "column", width: "100%" }}>
                    <Typography.Text strong>{t("Places.hours")}</Typography.Text>
                    {placeInfo?.openingHours?.map((hour, i) => (
                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography.Text>
                          {moment()
                            .isoWeekday(i + 1)
                            .format("dddd")}
                        </Typography.Text>
                        {isEmpty(hour)
                          ? t("Places.closed")
                          : hour?.map((h) => <Typography.Text>{h.join(" - ")}</Typography.Text>)}
                      </div>
                    ))}
                  </div>
                </Card>
              )}

              {!!placeInfo?.phone && (
                <Card
                  style={{
                    alignItems: "center",
                    width: "100%",
                  }}
                  bodyStyle={{ width: "100%" }}
                >
                  <div style={{ display: "flex", gap: 8, width: "100%" }}>
                    <Typography.Text strong>{t("Places.phone")}</Typography.Text>
                    <Typography.Text>{placeInfo.phone}</Typography.Text>
                  </div>
                </Card>
              )}

              {!!placeInfo?.email && (
                <Card
                  style={{
                    alignItems: "center",
                    width: "100%",
                  }}
                  bodyStyle={{ width: "100%" }}
                >
                  <div style={{ display: "flex", gap: 8, width: "100%" }}>
                    <Typography.Text strong>{t("Places.email")}</Typography.Text>
                    <Link
                      to="#"
                      onClick={(e) => {
                        window.location.href = `mailto:${placeInfo.email}`;
                        e.preventDefault();
                      }}
                    >
                      {placeInfo.email}
                    </Link>
                  </div>
                </Card>
              )}

              {!!placeInfo?.location && (
                <Card
                  onClick={() => {
                    setVisible(false);
                    goSpace(placeInfo);
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <div style={{ justifyContent: "space-between", display: "flex" }}>
                    <Typography.Text strong>
                      <Glyph name="place" style={{ color: colors.secondary_base }} />
                      {t("Places.Location")}
                    </Typography.Text>
                    <Glyph name="navigate_next" />
                  </div>
                </Card>
              )}
            </div>
          )}

          {!!placeInfo?.extras?.length &&
            placeInfo?.extras
              ?.filter((extra) => extra?.type === "menu" || extra?.type === "restaurant-balance")
              .map((e, index) => (
                <div key={index} style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                  <Typography.Text strong>{e?.title}</Typography.Text>
                  {e?.data.map((d, index) => (
                    <Card
                      key={index}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setStep(e?.type === "menu" ? STEPS.MENU : STEPS.CARDS);
                        setData(d);
                      }}
                    >
                      <div
                        style={{
                          justifyContent: "space-between",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Typography.Text strong>
                          <Glyph
                            name={d?.icon}
                            style={{
                              color: colors.secondary_base,
                              marginRight: "8px",
                              verticalAlign: "-5px",
                            }}
                          />
                          {d?.title}
                        </Typography.Text>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Glyph name="navigate_next" />
                        </div>
                      </div>
                    </Card>
                  ))}
                </div>
              ))}

          {!!placeInfo?.equipments?.length && (
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Typography.Text strong>{t("Places.Equipments")}</Typography.Text>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  overflowY: "auto",
                }}
              >
                {placeInfo?.equipments?.map((equipment, index) => (
                  <Tag
                    key={index}
                    style={{ marginBottom: "8px" }}
                    icon={<Glyph name={equipment?.icon} />}
                  >
                    {equipment?.title}
                  </Tag>
                ))}
              </div>
            </div>
          )}

          {!!placeInfo?.services?.length && (
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Typography.Text strong>{t("Places.Services")}</Typography.Text>

              {placeInfo?.services?.map((service, index) => (
                <a key={index} target="_blank" rel="noreferrer" href={service?.url}>
                  <Card
                    bodyStyle={{
                      display: "flex",
                      flexFlow: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ width: "100%" }}>
                      <Glyph
                        name="credit_card"
                        style={{ color: colors?.secondary_base, marginRight: "8px" }}
                      />
                      <Typography.Text strong>{service?.name}</Typography.Text>
                    </div>
                    <Glyph name="navigate_next" />
                  </Card>
                </a>
              ))}
            </div>
          )}

          {!!placeInfo?.others && (
            <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
              <Typography.Text strong>{t("Places.others")}</Typography.Text>
              <p
                dangerouslySetInnerHTML={{
                  __html: placeInfo?.others,
                }}
              />
            </div>
          )}
        </div>
      )}

      {step === STEPS.BOOKING && (
        <Booking
          menu={placeInfo?.extras?.find((e) => e.type === "restaurant-slots")}
          view={viewId}
          placeInfo={placeInfo}
          data={data}
          setStepPlaces={setStep}
          setInfoBooking={setInfoBooking}
          infoBooking={infoBooking}
        />
      )}

      {step === STEPS.MENU && (
        <Menu
          setStep={setStep}
          menu={placeInfo?.extras?.find((e) => e.type === "menu")}
          view={viewId}
          placeInfo={placeInfo}
          data={data}
          setContentMenu={setContentMenu}
        />
      )}

      {step === STEPS.DETAILSMENU && <DetailsMenu contentMenu={contentMenu} />}

      {step === STEPS.CARDS && (
        <div style={{ width: "100%" }}>
          <Cards
            menu={placeInfo?.extras?.find((e) => e.type === "restaurant-balance")}
            view={viewId}
            placeInfo={placeInfo}
            data={data}
          />
        </div>
      )}

      {step === STEPS.CANCELBOOKING && (
        <CancelBooking data={infoBooking} placeInfo={placeInfo} view={viewId} setStep={setStep} />
      )}
    </Modal>
  );
};

export default Places;
