import { LoadingOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import { Avatar, Button, Grid, Spin, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactComponent as NoVehicleRegistrated } from "../../../../assets/svg/woman_on_bike.svg";
import { ReactComponent as NoBookings } from "../../../../assets/svg/woman_search.svg";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";

const CalendarRightPanel = ({
  setShowCreateOrModifyBookingModal,
  selectedDate,
  setSelectedBooking,
  setShowDeleteBookingModal,
  loading,
  setShowManageVehiclesModal,
}) => {
  const { i18n, t } = useTranslation();
  const { keycloak } = useKeycloak();
  const { colors, symbols, size } = useDesignTokens();

  const locale = i18n.language.split("-")[0];
  const screens = Grid.useBreakpoint();

  const userData = useSelector((state) => state.userWS.userData.profile);
  const bookings = useSelector((state) => state.parkingWS.bookings);
  const vehicles = useSelector((state) => state.parkingWS.vehicles);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          marginBottom: 16,
        }}
      >
        <Avatar
          size={56}
          src={`${userData?.photoUrl}?access_token=${keycloak.token}`}
          style={{ border: "0", boxShadow: "none", marginBottom: "10px" }}
        >
          {(userData.firstName || "").charAt(0).toUpperCase()}
          {(userData.lastName || "").charAt(0).toUpperCase()}
        </Avatar>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography.Text strong style={{ fontSize: size.l }}>
            {userData.firstName} {userData.lastName?.toUpperCase()}
          </Typography.Text>
          <Typography.Text
            style={{
              fontSize: size.xs,
              marginBottom: "10px",
              letterSpacing: "2px",
              textTransform: "uppercase",
            }}
          >
            {moment(selectedDate).isSame(moment(), "date")
              ? t("parking.MyBooking")
              : moment(selectedDate).isAfter(moment())
              ? t("parking.NextBooking")
              : t("parking.PreviousBooking")}
          </Typography.Text>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          height: "100%",
          overflowY: "auto",
          padding: "0 8px",
        }}
      >
        {loading ? (
          <Spin
            spinning={loading}
            style={{ marginTop: 24 }}
            indicator={<LoadingOutlined style={{ fontSize: size.xxxxl }} />}
          />
        ) : !!vehicles?.length ? (
          !!bookings?.reservations?.filter((s) => moment(s.from).isSame(selectedDate, "date"))
            ?.length ? (
            <>
              {bookings?.reservations
                ?.filter((s) => moment(s.from).isSame(selectedDate, "date"))
                ?.map((booking) => (
                  <div
                    key={booking.id}
                    style={{
                      display: "flex",
                      gap: 8,
                      flexDirection: "column",
                      marginBottom: 16,
                    }}
                  >
                    <Typography.Text strong style={{ fontSize: size.m }}>
                      {moment(booking.date).isSame(moment(), "date")
                        ? t("parking.Today")
                        : moment(booking.date).format(
                            locale === "fr" ? "DD MMM YYYY" : "MM/DD/YYYY",
                          )}
                    </Typography.Text>

                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "end",
                        backgroundColor: colors.light_background,
                        padding: 12,
                        borderRadius: symbols.base_shape.radius,
                      }}
                    >
                      <Glyph name="schedule" style={{ color: colors.grey_40 }} />
                      <Typography.Text strong style={{ fontSize: size.m }}>
                        {booking.period === "AM"
                          ? t("parking.Am")
                          : booking.period === "PM"
                          ? t("parking.Pm")
                          : t("parking.Day")}
                      </Typography.Text>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "end",
                        backgroundColor: colors.light_background,
                        padding: 12,
                        borderRadius: symbols.base_shape.radius,
                      }}
                    >
                      <Glyph name="local_parking" style={{ color: colors.grey_40 }} />
                      <Typography.Text strong style={{ fontSize: size.m }}>
                        {/* siteName */}
                        {booking.site ? `${booking.site.title} - ` : ""}
                        {booking.zone.name}
                      </Typography.Text>
                    </div>
                    {moment(booking.to).isAfter(moment()) && (
                      <div style={{ display: "flex", gap: 8 }}>
                        <Button
                          type="tertiary"
                          style={{ width: "100%" }}
                          onClick={() => {
                            /*logEvent(analytics, "parking_cancel_booking");*/
                            setSelectedBooking(booking);
                            setShowDeleteBookingModal(true);
                          }}
                          icon={screens.lg && <Glyph name="cancel" />}
                        >
                          {t("parking.Cancel")}
                        </Button>

                        {moment(booking.from).subtract(1, "minutes").isAfter(moment()) && (
                          <Button
                            type="primary"
                            style={{ width: "100%" }}
                            onClick={() => {
                              /*logEvent(analytics, "parking_modify_booking")*/
                              setSelectedBooking(booking);
                              setShowCreateOrModifyBookingModal(true);
                            }}
                            icon={screens.lg && <Glyph name="edit" />}
                          >
                            {t("parking.Modify")}
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                ))}
            </>
          ) : (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: 16,
                }}
              >
                <NoBookings style={{ width: "80%", height: "100%" }} />
                <Typography.Text strong style={{ fontSize: size.l, textAlign: "center" }}>
                  {t("parking.NoBookingToday")}
                </Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: size.m, textAlign: "center" }}>
                  {t("parking.MakeABookingToAccessList")}
                </Typography.Text>
              </div>
            </div>
          )
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              padding: 16,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: 16,
              }}
            >
              <NoVehicleRegistrated style={{ width: "80%", height: "100%" }} />
              <Typography.Text strong style={{ fontSize: size.l, textAlign: "center" }}>
                {t("parking.NoVehicleRegistered")}
              </Typography.Text>
              <Typography.Text type="secondary" style={{ fontSize: size.m, textAlign: "center" }}>
                {t("parking.AddVehicleToMakeBooking")}
              </Typography.Text>
              <Button
                type="primary"
                style={{ width: "100%" }}
                icon={<Glyph name="add" />}
                onClick={() => setShowManageVehiclesModal((p) => !p)}
              >
                {t("parking.AddVehicle")}
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CalendarRightPanel;
