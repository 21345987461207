import { Card, Form, Image, Radio, Result, Space, Spin, Tag, Typography } from "antd";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useDesignTokens from "../../../../../../hook/useDesignTokens";
import { createLoadingSelector } from "../../../../../../services/redux/managers/LoadingManager";
import { mapDataSelector } from "../../../../../../services/redux/services/MapDataWS";
import {
  desksAvailabilitySelector,
  listDeskAvailability,
} from "../../../../../../services/redux/services/SpaceService";
import Glyph from "../../../../../Common/Glyph/Glyph";
import FloorSelector from "../../../../../Common/Map/Mapbox/FloorSelector";
import ZoomInOut from "../../../../../Common/Map/Mapbox/ZoomInOut";
import {
  MapboxProvider,
  useMapboxInstance,
} from "../../../../../Common/Map/Mapbox/useMapboxInstance";
import styles from "../../../SpaceBooking.module.less";

const CreateStepRoomContent = ({ form, initialValues }) => {
  const { colors, size } = useDesignTokens();
  const { t } = useTranslation();
  const { map, registerMap, mapReady, setDesksDisplay, centerOnPlace } = useMapboxInstance();

  const { siteId, sectorId } = initialValues;

  const mapData = useSelector((state) => mapDataSelector(state, siteId));
  const availability = useSelector((state) => desksAvailabilitySelector(state, siteId));
  const isLoading = useSelector(createLoadingSelector([listDeskAvailability.typePrefix]));

  const onChange = useCallback(
    (roomId) => {
      const roomIds = form.getFieldValue("roomIds") || [];
      if (!roomIds.includes(roomId)) {
        roomIds.push(roomId);
      } else {
        roomIds.splice(roomIds.indexOf(roomId), 1);
      }
      form.setFieldsValue({ roomIds });
    },
    [form],
  );

  const deskDisplay = useMemo(() => {
    if (!mapData?.resources) return;
    return {
      rooms: mapData.resources
        .filter((i) => i.sectorId === sectorId)
        .map((room) => ({
          roomUid: room.id,
          availableDeskUids: (availability || [])
            .filter((w) => w?.workplace?.roomId === room.id)
            .map((w) => w.id),
        })),
      users: [],
    };
  }, [availability, mapData?.resources, sectorId]);

  useEffect(() => {
    if (!mapReady) return;
    setDesksDisplay(deskDisplay);
    if (deskDisplay?.rooms.length) {
      centerOnPlace(deskDisplay.rooms[0].roomUid, false, false, { zoom: 20 });
    }
  }, [centerOnPlace, mapReady, deskDisplay, setDesksDisplay]);

  useEffect(() => {
    if (!mapReady || !map || !mapData) return;

    const handler = ({ details: { id } }) => {
      const room = mapData.resources.find((i) => i.map?.findAndOrder.placeId === id);
      if (room) {
        document.getElementById(`room-${room.id}`).scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
      }
    };

    map.on("clickOnRoom", handler);
    return () => {
      map.off("clickOnRoom", handler);
    };
  }, [map, mapData, mapReady]);

  const roomsWithDesksCounts = useMemo(() => {
    return mapData?.resources
      ?.filter((i) => i.sectorId === sectorId)
      .map((room) => ({
        ...room,
        floor: mapData?.floors?.find((f) => f.reference === room.floorReference),
        workplaces: {
          total: mapData?.workplaces.filter((w) => w?.roomId === room.id).length || 0,
          available: availability?.filter((w) => w?.workplace?.roomId === room.id)?.length || 0,
        },
      }))
      .sort((a, b) => a?.title.localeCompare(b?.title));
  }, [availability, mapData?.floors, mapData?.resources, mapData?.workplaces, sectorId]);

  useEffect(() => {
    if (!roomsWithDesksCounts?.length || !!form.getFieldValue("roomIds")?.length) return;
    const available =
      roomsWithDesksCounts.find((i) => i.workplaces.available >= initialValues.users?.length)?.id ||
      roomsWithDesksCounts.find((i) => i.workplaces.available)?.id;
    if (!available) return;
    form.setFieldsValue({
      roomIds: [available],
    });
  }, [form, initialValues.users?.length, roomsWithDesksCounts]);

  return (
    <Form
      style={{ height: "100%", display: "flex", flexDirection: "column" }}
      form={form}
      name={"rooms"}
    >
      <div style={{ display: "flex", flexGrow: 1 }}>
        <Form.Item name={"roomIds"} noStyle hidden rules={[{ required: true }]} />
        <div
          style={{
            padding: "0px 20px",
            flexBasis: "40%",
            borderRight: "1px solid #ececec",
            height: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography.Paragraph strong>{t("spas.request.roomIds.title")}</Typography.Paragraph>
          <Card bordered={false} style={{ flexGrow: 1, overflowY: "auto" }}>
            {!isLoading ? (
              <div style={{ display: "flex", flexDirection: "column", gap: ".8rem" }}>
                {[...roomsWithDesksCounts].map((room) => (
                  <Card
                    id={`room-${room.id}`}
                    key={room.id}
                    style={{ cursor: "pointer" }}
                    bodyStyle={{ padding: 10 }}
                    onClick={() => {
                      if (!room.workplaces.available) return;
                      onChange(room.id);
                      centerOnPlace(room.id, false, true, { zoom: 20 });
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Card.Meta
                        avatar={
                          <Image
                            width={60}
                            height={60}
                            preview={false}
                            src={room?.photos?.[0]}
                            placeholder={
                              <div className={styles["picture_icon"]}>
                                <Glyph
                                  style={{ fontSize: "30px", color: "white", opacity: 0.8 }}
                                  name="desk"
                                />
                              </div>
                            }
                          />
                        }
                        title={
                          <div style={{ display: "flex", flexDirection: "column" }}>
                            <Typography.Text style={{ fontSize: size.s }} strong>
                              {room?.title}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: size.s }}>
                              {t("Floor")} {room?.floor?.displayName}
                            </Typography.Text>
                            <Typography.Text style={{ fontSize: size.s }} type="secondary">
                              {t("spas.room.nbDesk", { count: room.workplaces.total })}
                            </Typography.Text>
                          </div>
                        }
                      />
                      <Space size={"small"} direction="vertical" align="end">
                        <Tag
                          color={room.workplaces.available ? colors.primary_base : colors.type_06}
                          style={{
                            opacity: room.workplaces.available ? 1 : 0.7,
                            margin: 0,
                            height: "auto",
                            lineHeight: "100%",
                            fontWeight: "bold",
                            padding: ".2rem .4rem",
                          }}
                        >
                          {`${room.workplaces.available}/${room.workplaces.total}`}
                        </Tag>
                        <Form.Item noStyle shouldUpdate={(pre, cur) => pre.roomIds !== cur.roomIds}>
                          {({ getFieldValue }) => (
                            <Radio
                              style={{ margin: 0, opacity: room.workplaces.available ? 1 : 0 }}
                              disabled={!room.workplaces.available}
                              checked={getFieldValue("roomIds")?.includes(room.id)}
                            />
                          )}
                        </Form.Item>
                      </Space>
                    </div>
                  </Card>
                ))}
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Result
                  icon={<Spin />}
                  title={t("spas.workplace.loading.title")}
                  subTitle={t("spas.workplace.loading.subtitle")}
                />
              </div>
            )}
          </Card>
        </div>
        <div
          style={{
            padding: "0px 20px",
            flexBasis: "60%",
            height: "100%",
          }}
        >
          <Card bordered={false} style={{ height: "100%" }} bodyStyle={{ padding: 0 }}>
            <div ref={registerMap} id="workplace_map" style={{ width: "100%", height: "100%" }} />
            <div style={{ position: "absolute", top: 0, left: 0, padding: 10 }}>
              <FloorSelector />
            </div>
            <div style={{ position: "absolute", bottom: 0, right: 0, padding: 10 }}>
              <ZoomInOut />
            </div>
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "white",
                opacity: mapReady ? 0 : 1,
                transition: mapReady ? "opacity .3s ease-in-out" : "none",
                pointerEvents: "none",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography.Title level={3}>{t("map.loading")}...</Typography.Title>
            </div>
          </Card>
        </div>
      </div>
      <Form.Item noStyle shouldUpdate={(pre, cur) => pre.roomIds !== cur.roomIds}>
        {({ getFieldValue }) => {
          const roomIds = getFieldValue("roomIds") || [];
          const rooms = roomsWithDesksCounts.filter((r) => roomIds.includes(r.id));
          const total = rooms.reduce((acc, cur) => acc + cur.workplaces.available, 0);
          return (
            <div
              style={{
                padding: "10px 20px",
                textAlign: "center",
                opacity: total < initialValues.users.length ? 1 : 0,
              }}
            >
              <Typography.Text type="danger" strong>
                {t("spas.request.roomIds.warning")}
              </Typography.Text>
            </div>
          );
        }}
      </Form.Item>
    </Form>
  );
};

const CreateStepRoom = (props) => {
  const { siteId } = props.initialValues;
  return (
    <MapboxProvider siteId={siteId} mode={"2D"}>
      <CreateStepRoomContent {...props} />
    </MapboxProvider>
  );
};

export default CreateStepRoom;
