import axios from "axios";
import moment from "moment";

const errorData = (err) => ({
  status: err?.response?.status,
  code: err?.response?.status + "",
  message: err.message,
  data: err?.response?.data,
  stack: err.stack,
});

class DWMConnector {
  constructor() {
    this.baseURL = process.env.REACT_APP_DWM_URL;
    this.apiKey = undefined;

    this.axiosInst = axios.create({
      baseURL: `${this.baseURL}/api`,
    });

    this.axiosInstNoAuth = axios.create({
      baseURL: `${this.baseURL}/api`,
    });

    // Add interceptors
    this.axiosInst.interceptors.request.use((config) => {
      let authData = localStorage.getItem("auth_data_dwm");
      authData = JSON.parse(authData) || {};
      config.headers.Authorization = `Bearer ${authData.token}`;
      // config.headers["client-apikey"] = this.apiKey;
      return config;
    });
  }

  init({ apiKey }) {
    this.apiKey = apiKey;
  }

  /**
  |--------------------------------------------------
  | USER DATA
  |--------------------------------------------------
  */
  async userData(locale, siteId, isMobile) {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/appData`, {
        params: {
          locale,
          siteId,
          askedVersion: process.env.REACT_APP_VERSION,
          forMobile: isMobile,
        },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | CAMPUS
  |--------------------------------------------------
  */
  async campusData(locale, siteId) {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/mapData`, {
        params: { locale, siteId },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | USERS
  |--------------------------------------------------
  */
  async searchUsers({ search, page, size }) {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/user`, {
        params: { search, page, size },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | FASTBOOKING
  |--------------------------------------------------
  */
  async fastbooking(viewId, filters, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/fastbooking`,
        {
          params: {
            ...filters,
            equipments: filters?.equipments?.join(","),
            services: filters?.services?.join(","),
            types: typeof filters?.types === Array ? filters?.types?.join(",") : filters?.types,
            siteId,
          },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async setMakeBooking(viewId, roomId, startDate, duration, endDate, siteId) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/fastbooking`,
        {
          roomId: roomId,
          startDate: startDate,
          duration: duration,
          endDate: endDate,
        },
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async setRemoveBooking(viewId, slotId, roomId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/fastbooking`,
        {
          params: { slotId, roomId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async fastbookingSlots(viewId, slotStartTime, slotEndTime, day, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/fastbooking/availability`,
        {
          params: {
            slotStartTime,
            slotEndTime,
            day,
            siteId,
          },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | PLANNING
  |--------------------------------------------------
  */
  async getPlanning(planningId, locale, startDate, endDate) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${planningId}/planning`,
        {
          params: { locale, startDate, endDate, withDetails: true },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | NEWS
  |--------------------------------------------------
  */
  async getNewsHome(locale, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/socialnews/latest`,
        {
          params: { locale, siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getNewsByTopics(viewId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/byTopics`,
        {
          params: { locale, size: 3 },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getNews(viewId, locale, topic, date) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews`,
        {
          params: { locale, topic, date, size: 12 },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getNewsItem(viewId, itemId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/${itemId}`,
        {
          params: { locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async listCommentNews(viewId, itemId, date, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/${itemId}/comments`,
        { params: { locale, date } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addCommentNews(viewId, itemId, comment, locale) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/${itemId}/comments`,
        { comment: comment },
        { params: { locale } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deleteCommentnews(viewId, itemId, commentId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/${itemId}/comments/${commentId}`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async likesNews(viewId, itemId, like) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/${itemId}/likes`,
        { like: like },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async likesComments(viewId, itemId, commentId, like) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/${itemId}/comments/${commentId}/likes`,
        { like: like },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async readNews(viewId, itemId) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/${itemId}/views`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  async getNewsEditor(viewId, topic, date) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/items`,
        {
          params: { topic, date, size: 12 },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getNewsItemEditor(viewId, itemId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/${itemId}`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deleteNews(viewId, itemId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/${itemId}`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createNews(
    viewId,
    header,
    title,
    subtitle,
    content,
    topics,
    startDate,
    endDate,
    groups,
    event,
    authorizeComment,
    sendNotification,
  ) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/items`,
        {
          header: header,
          title: title,
          subtitle: subtitle,
          content: content,
          topics: topics,
          startDate: startDate,
          endDate: endDate,
          groups: groups,
          event: event,
          authorizeComment: authorizeComment,
          sendNotification: sendNotification,
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateNews(
    viewId,
    itemId,
    header,
    title,
    subtitle,
    content,
    topics,
    startDate,
    endDate,
    groups,
    authorizeComment,
    sendNotification,
    event,
  ) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/${itemId}`,
        {
          header: header,
          title: title,
          subtitle: subtitle,
          content: content,
          topics: topics,
          startDate: startDate,
          endDate: endDate,
          groups: groups,
          authorizeComment: authorizeComment,
          sendNotification: sendNotification,
          event: event,
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async hideNews(viewId, itemId, hide) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/${itemId}/hide`,
        { hide: hide },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getTopics(viewId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/topics`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createTopics(viewId, label, position) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/topics`,
        { label: label, position: position },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deleteTopics(viewId, topicId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/topics/${topicId}`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateTopics(viewId, topicId, label, position) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/topics/${topicId}`,
        { label: label, position: position },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  async getGroups(viewId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/socialnews/editor/listGroups`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getImageNews(date) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/socialnews/image`,
        {
          params: { size: 8, date },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addImagesNews(image, fileName) {
    try {
      const formData = new FormData();
      formData.append("image", image, fileName);
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/socialnews/image`,
        formData,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addImagesContentNews(image, fileName, siteId) {
    try {
      const formData = new FormData();
      formData.append("file", image, fileName);
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/assets`,
        formData,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  /**
  |--------------------------------------------------
  | OCCUPANCY
  |--------------------------------------------------
  */

  async getRoomOccupancy(viewId, roomId, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/occupancy`,
        {
          params: { roomId, siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | TICKETING
  |--------------------------------------------------
  */
  async getListTicket(viewId, locale, page, size) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketing/tickets`,
        {
          params: { locale, page, size, inprogress: true },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getListCategory(viewId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketing/configuration`,
        {
          params: { locale, withForms: true },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createTicket(viewId, typeId, categoryId, description, resourceId) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketing/tickets`,
        {
          typeId: typeId,
          categoryId: categoryId,
          description: description,
          resourceId: resourceId,
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addAttachment(viewId, ticketId, files) {
    try {
      const formData = new FormData();
      files.forEach((file, key) => {
        formData.append("file" + (key + 1), file, file.name);
      });
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketing/tickets/${ticketId}/attachments`,
        formData,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addComment(viewId, ticketId, comment, locale) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketing/tickets/${ticketId}/comment`,
        { comment: comment },
        { params: { locale } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | RELEASE ROOM
  |--------------------------------------------------
  */
  async releaseRoom(action, token) {
    try {
      const res = await this.axiosInstNoAuth.post(`/confirms/releaseRoom`, undefined, {
        params: { action },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  /**
  |--------------------------------------------------
  | REALTIME
  |--------------------------------------------------
  */
  async getFloorTemplates(campusId, floor) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/floorTemplates`,
        {
          params: { campusId, floor },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | SPACE AS A SERVICE
  | ---
  | SEE: src/services/api/DWM/SpasConnector.js
  |--------------------------------------------------
  */

  /**
  |--------------------------------------------------
  | WIFI
  |--------------------------------------------------
  */
  async getWifiInfos() {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/wifi`);
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | CREATE PASSWORD
  |--------------------------------------------------
  */
  async getCreatePassword(login, code, newPassword) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/public/apps/${process.env.REACT_APP_ID}/user/changePassword`,
        {
          login: login,
          code: code,
          newPassword: newPassword,
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async confirmPassword(login, code) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/public/apps/${process.env.REACT_APP_ID}/user/isCodeActive`,
        {
          login: login,
          code: code,
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | CGU PRIVACY POLICY
  |--------------------------------------------------
  */

  // PUBLIC
  async getCgu(os, locale) {
    try {
      const res = await this.axiosInst.get(`/mobile/public/apps/${process.env.REACT_APP_ID}/cgu`, {
        params: { os, locale },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getPrivacyPolicy(os, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/public/apps/${process.env.REACT_APP_ID}/privacyPolicy`,
        {
          params: { os, locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  // PRIVATE

  async acceptCgu() {
    try {
      const res = await this.axiosInst.post(`/mobile/apps/${process.env.REACT_APP_ID}/cgu/accept`);
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | POWERBI
  |--------------------------------------------------
  */

  async getListReport(siteId) {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/bi/reports`, {
        params: { siteId },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getReport(reportId, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/bi/reports/${reportId}/auth`,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | AGORA
  |--------------------------------------------------
  */
  async getPlacesInfos(viewId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/places`,
        {
          params: { locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getPlacesData(viewId, contentId, campusId, date, extraType, extraId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/contents/${contentId}/places`,
        {
          params: { campusId, date, extraType, extraId, ignoreErrors: true },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async postPlacesData(
    viewId,
    contentId,
    campusId,
    date,
    extraType,
    extraId,
    zoneId,
    slotId,
    party,
  ) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/contents/${contentId}/places`,
        {
          date: date,
          zoneId: zoneId,
          slotId: slotId,
          party: party,
        },
        {
          params: { campusId, extraType, extraId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deleteBooking(viewId, contentId, campusId, extraType, extraId, action, data) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/contents/${contentId}/places`,
        {
          action: action,
          data: data,
        },
        {
          params: { campusId, extraType, extraId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | TRANSFERT
  |--------------------------------------------------
  */

  async getTransfertInfos() {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/transfert`);
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateCorrespondant({ id, understood }) {
    try {
      const res = await this.axiosInst.put(`/mobile/apps/${process.env.REACT_APP_ID}/transfert`, {
        id: id,
        understood: understood,
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addFiles(siteId, transfertFile, locale) {
    try {
      const formData = new FormData();
      transfertFile.forEach((file, key) => {
        formData.append("transfertFile", file, file.name);
      });
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/transfert`,
        formData,
        { params: { locale, siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | FACILITIES
  |--------------------------------------------------
  */
  async getFacilitiesConfig(siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/configuration`,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateFacilitiesCategories(siteId, categories) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/categories`,
        categories,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async listFacilitiesEvents(siteId, filters) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events`,
        { params: { ...filters, siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateFacilitiesEvent(siteId, eventId, data) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}`,
        data,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createFacilitiesEventTab(siteId, eventId, data) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/tabs`,
        data,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  async updateFacilitiesEventTab(siteId, eventId, tabId, data) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/tabs`,
        data,
        { params: { siteId, tabId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  async deleteFacilitiesEventTab(siteId, eventId, tabId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/tabs`,
        { params: { siteId, tabId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  async subscribeFacilitiesEventTab(siteId, eventId, tabId, subscribe) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/subscriptions`,
        { subscribe },
        { params: { siteId, tabId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createFacilitiesEventSection(siteId, eventId, tabId, data) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/sections`,
        data,
        { params: { siteId, tabId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  async fillFacilitiesEventSectionValue(siteId, eventId, tabId, sectionId, data) {
    try {
      const res = await this.axiosInst.patch(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/sections`,
        data,
        { params: { siteId, tabId, sectionId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  async updateFacilitiesEventSection(siteId, eventId, tabId, sectionId, data) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/sections`,
        data,
        { params: { siteId, tabId, sectionId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  async deleteFacilitiesEventSection(siteId, eventId, tabId, sectionId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/sections`,
        { params: { siteId, tabId, sectionId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async postFacilitiesEventComment(siteId, eventId, tabId, data) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/comments`,
        data,
        { params: { siteId, tabId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async uploadFacilitiesEventFiles(siteId, eventId, tabId, sectionId, files, onUploadProgress) {
    try {
      const formData = new FormData();
      for (const file of files) formData.append(file.name, file, file.name);

      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/files`,
        formData,
        {
          params: { siteId, tabId, sectionId },
          onUploadProgress,
          maxContentLength: Infinity,
          maxBodyLength: Infinity,
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
  async deleteFacilitiesEventFile(siteId, eventId, tabId, sectionId, fileName) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/facilities/events/${eventId}/files`,
        {
          params: { tabId, sectionId, fileName },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | MANAGE EXTERNAL USER
  |--------------------------------------------------
  */
  async addFilesExternalUser(siteId, file, locale) {
    try {
      const formData = new FormData();
      file.forEach((file, key) => {
        formData.append("file", file, file.name);
      });
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/user/import`,
        formData,
        { params: { locale, siteId, sendMails: true } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | CATERING
  |--------------------------------------------------
  */
  async AllOrders(viewId, page, size, inprogress) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/catering/orders`,
        {
          params: { page, size, inprogress },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async servicesCatering(viewId, roomId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/catering/${roomId}/services`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async allServicesCatering(viewId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/catering/services`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createPresation(
    viewId,
    roomId,
    ticketType,
    categoryId,
    startDate,
    endDate,
    values,
    no_Attendees,
    total,
  ) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/catering/${roomId}/orders`,
        { ticketType, categoryId, formProps: { startDate, endDate, values, no_Attendees, total } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deletePrestation(viewId, ticketId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/catering/orders/${ticketId}`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updatePrestation(
    viewId,
    ticketId,
    roomId,
    startDate,
    endDate,
    values,
    no_Attendees,
    total,
  ) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/catering/orders/${ticketId}`,
        { roomId, formProps: { startDate, endDate, values, no_Attendees, total } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | VISITORS
  |--------------------------------------------------
  */
  async getConfig(viewId, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/visitors/config`,
        { params: { siteId } },
      );
      return res.data;
    } catch (err) {
      logger.error("Failed on getConfig: " + err.message, {
        error: errorData(err),
      });
      throw err;
    }
  }

  async listVisits(viewId, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/visitors/visits`,
        {
          params: {
            siteId,
            page: 1,
            size: 50,
            from: moment().startOf("day").toISOString(),
            to: moment().startOf("day").add(1, "y").toISOString(),
            hideCanceled: true,
          },
        },
      );
      return res.data;
    } catch (err) {
      logger.error("Failed on listVisits: " + err.message, {
        error: errorData(err),
      });
      throw err;
    }
  }

  async createVisit(viewId, visit, siteId) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/visitors/visits`,
        visit,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (err) {
      logger.error("Failed on createVisit: " + err.message, {
        error: errorData(err),
      });
      throw err;
    }
  }

  async updateVisit(viewId, visit, siteId) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/visitors/${visit.id}`,
        visit,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (err) {
      logger.error("Failed on updateVisit: " + err.message, {
        error: errorData(err),
      });
      throw err;
    }
  }

  async cancelVisit(viewId, visit, siteId) {
    try {
      const res = await this.axiosInst.patch(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/visitors/${visit.id}`,
        {},
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (err) {
      logger.error("Failed on cancelVisit: " + err.message, {
        error: errorData(err),
      });
      throw err;
    }
  }

  async searchVisitors(viewId, search, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/visitors/visitors`,
        {
          params: { siteId, search, page: 1, size: 50 },
        },
      );
      return res.data;
    } catch (err) {
      logger.error("Failed on cancelVisit: " + err.message, {
        error: errorData(err),
      });
      throw err;
    }
  }

  async searchResidents(viewId, search, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/visitors/residents`,
        {
          params: { siteId, search, page: 1, size: 50 },
        },
      );
      return res.data;
    } catch (err) {
      logger.error("Failed on cancelVisit: " + err.message, {
        error: errorData(err),
      });
      throw err;
    }
  }

  async createUser(viewId, user, siteId) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/visitors/visitors`,
        user,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (err) {
      logger.error("Failed on createUser: " + err.message, {
        error: errorData(err),
      });
      throw err;
    }
  }

  async updateUser(viewId, user, siteId) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/visitors/visitors`,
        user,
        {
          params: { siteId, userId: parseInt(user.id) },
        },
      );
      return res.data;
    } catch (err) {
      logger.error("Failed on updateUser: " + err.message, {
        error: errorData(err),
      });
      throw err;
    }
  }
  /**
  |--------------------------------------------------
  | ONBOARDING
  |--------------------------------------------------
  */
  async updateOnboarding(viewId, onboarding) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/onboarding`,
        { onboarding },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | TRANSPORT
  |--------------------------------------------------
  */

  async GetStopsNearLocation(campusId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/mobility/stops`,
        { params: { distance: 500, campusId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async GetSchedulesForStop(campusId, stopId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/mobility/stop/${stopId}/schedules`,
        { params: { campusId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async GetAutocompletePlaces(campusId, q) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/mobility/places`,
        { params: { campusId, q } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async GetJourneysBetweenTwoPoints(campusId, from, to) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/mobility/journeys`,
        { params: { campusId, from, to } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | AROUNDME
  |--------------------------------------------------
  */
  async getAroundMe(viewId, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/aroundMe`,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | CONTACT LIST ENHANCED
  |--------------------------------------------------
  */
  async getContactCategories(siteId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/contactList/info`,
        {
          params: { siteId, locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getContactDetails(siteId, userId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/contactList/user/${userId}`,
        {
          params: { siteId, locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateProfile(aboutMe) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/contactList/profile`,
        {
          aboutMe,
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addFavoriteContact(userId) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/contactList/favorite/${userId}`,
        { params: {} },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async removeFavoriteContact(userId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/contactList/favorite/${userId}`,
        { params: {} },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getContacts(siteId, search, page, size, id, groupId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/contactList/users`,
        {
          params: { siteId, search, page, size, id, groupId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getUserData(locale, siteId) {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/appData`, {
        params: { locale, siteId, askedVersion: process.env.REACT_APP_VERSION },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | GET LANG CLIENTS
  |--------------------------------------------------
  */
  async getLang() {
    try {
      const res = await this.axiosInst.get(
        `/mobile/public/apps/${process.env.REACT_APP_ID}/locales`,
      );
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | INSTANT MESSAGING
  |--------------------------------------------------
  */
  async getSendbirdAccessToken() {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/chat/token`);
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getSendbirdUnreadCount() {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/chat/unreadMessageCount`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getContactsUsingChat(siteId, search, page, size, id, groupId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/contactList/users?withChat=true`,
        {
          params: { siteId, search, page, size, id, groupId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | QR CODE
  |--------------------------------------------------
  */
  async getAccessCode(siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/qrCode/access`,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | PARKING
  |--------------------------------------------------
  */
  async getBookings(siteId, from, to, viewId, dir) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/parking/reservations`,
        {
          params: { siteId, from, to, dir, locale: "fr" },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getVehicles(viewId, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/parking/vehicles`,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
    }
  }

  async updateVehicle(vehicleData, viewId, siteId) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/parking/vehicles/${vehicleData.id}`,
        vehicleData,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
    }
  }

  async createVehicle(vehicleData, viewId, siteId) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/parking/vehicles`,
        vehicleData,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deleteVehicle(vehicleId, viewId, siteId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/parking/vehicles/${vehicleId}`,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getZones(viewId, date, period, siteId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/parking/zones`,
        { params: { siteId, date, period } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createBooking(viewId, date, period, zoneId, siteId) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/parking/reservations`,
        { date, period, zoneId },
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deleteParkingBooking(viewId, bookingId, siteId, date) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/parking/reservations/${bookingId}`,
        { params: { siteId, date } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /**
  |--------------------------------------------------
  | TICKETING V2
  |--------------------------------------------------
  */

  async getAllFloors(locale, siteId) {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/mapData`, {
        params: { locale, siteId },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAllGroups(viewId, siteId) {
    try {
      const res = await this.axiosInst.get(`/mobile/apps/${process.env.REACT_APP_ID}/groups`, {
        params: { siteId },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAgents(viewId, siteId, page, size) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/manager/agents`,
        {
          params: { siteId, page, size },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAgentsWithCriteria(viewId, ticketIds, siteId, page, size) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/manager/agents`,
        { ticketIds },
        {
          params: { siteId, page, size },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAllSpaces(viewId, siteId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/spaceTypes`,
        {
          params: { siteId, locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  // STATUS

  async getAllStatus(viewId, siteId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/status`,
        {
          params: { siteId, locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAllAdminStatus(viewId, siteId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/status`,
        {
          params: { siteId, locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAdminStatus(viewId, siteId, statusId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/status/${statusId}`,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createTicketStatus(viewId, siteId, data) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/status`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateTicketStatus(viewId, siteId, data, statusId) {
    try {
      const res = await this.axiosInst.put(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/status/${statusId}`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deleteTicketStatus(viewId, siteId, statusId, option) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/status/${statusId}`,
        { data: { newStatusId: option }, params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  // REFERENTIAL

  async getAllReferential(viewId, siteId, locale, space) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/referential`,
        {
          params: { siteId, locale, spaceTypeId: space },
        },
      );
      return res.data.referential;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAllReferentialsAdmin(viewId, siteId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/referential`,
        {
          params: { siteId, locale },
        },
      );
      return res.data.referential;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAdminReferential(viewId, siteId, stuffId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/referential/${stuffId}`,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAllReferentialsManager(viewId, siteId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/manager/referential`,
        {
          params: { siteId, locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAllReferentialsAgent(viewId, siteId, locale) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/agent/referential`,
        {
          params: { siteId, locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createTicketReferential(viewId, siteId, data) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/referential`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateTicketReferential(viewId, siteId, data, stuffId) {
    try {
      const res = await this.axiosInst.patch(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/referential/${stuffId}`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deleteTicketReferential(viewId, siteId, referentialId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/admin/referential/${referentialId}`,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  // TICKETS
  async getAllAgentTickets(
    viewId,
    siteId,
    locale,
    dir,
    page,
    size,
    statusId,
    priority,
    typeId,
    categoryId,
    subCategoryId,
    floor,
  ) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/agent/tickets`,
        {
          params: {
            siteId,
            locale,
            dir,
            page,
            size,
            statusId,
            priority,
            typeId,
            categoryId,
            subCategoryId,
            floor,
          },
        },
      );
      return res.data.tickets;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAllManagerTickets(
    viewId,
    siteId,
    locale,
    dir,
    page,
    size,
    statusId,
    priority,
    typeId,
    categoryId,
    subCategoryId,
    agentId,
    floor,
  ) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/manager/tickets`,
        {
          params: {
            siteId,
            locale,
            dir,
            page,
            size,
            statusId,
            priority,
            typeId,
            categoryId,
            subCategoryId,
            agentId,
            floor,
          },
        },
      );
      return res.data.tickets;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getAllMyTickets(
    viewId,
    siteId,
    locale,
    dir,
    page,
    size,
    statusId,
    typeId,
    categoryId,
    subCategoryId,
    agentId,
    floorId,
  ) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/tickets`,
        {
          params: {
            siteId,
            dir,
            locale,
            page,
            size,
            statusId,
            typeId,
            categoryId,
            subCategoryId,
            agentId,
            floorId,
          },
        },
      );
      return res.data.tickets;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getTicketDetails(viewId, siteId, locale, ticketId) {
    try {
      const res = await this.axiosInst.get(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/tickets/${ticketId}`,
        {
          params: { siteId, locale },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createTicketV2(viewId, siteId, data) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/tickets`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateTicketV2(viewId, siteId, ticketId, data) {
    try {
      const res = await this.axiosInst.patch(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/tickets/${ticketId}`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateTicketByAgent(viewId, siteId, data) {
    try {
      const res = await this.axiosInst.patch(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/agent/tickets`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async updateTicketByManager(viewId, siteId, data) {
    try {
      const res = await this.axiosInst.patch(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/manager/tickets`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addCommentToTicket(viewId, siteId, ticketId, data) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/tickets/${ticketId}/comment`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deleteCommentToTicket(viewId, siteId, ticketId, commentId) {
    try {
      const res = await this.axiosInst.delete(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/tickets/${ticketId}/comment/${commentId}`,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addAssetToTicketComment(viewId, siteId, ticketId, commentId, formData) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/tickets/${ticketId}/attachment`,
        formData,
        {
          params: { siteId, commentId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addAssetToTicket(viewId, siteId, ticketId, formData) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/tickets/${ticketId}/attachment`,
        formData,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async rateTicket(viewId, siteId, ticketId, data) {
    try {
      const res = await this.axiosInst.post(
        `/mobile/apps/${process.env.REACT_APP_ID}/views/${viewId}/ticketingV2/tickets/${ticketId}/rating`,
        data,
        {
          params: { siteId },
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
}

export default new DWMConnector();
