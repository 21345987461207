import { Button, Card, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import {
  getAllFloors,
  getAllReferential,
  getAllStatus,
} from "../../../../services/redux/services/TicketingV2WS";
import Glyph from "../../../Common/Glyph/Glyph";
import FilterDrawer from "../Component/FilterDrawer";
import CreateOrEditTicketModal from "../Component/Modals/CreateOrEditTicketModal";
import OrderSelector from "../Component/OrderSelector";
import SiteSelector from "../Component/SiteSelector";
import TicketColumn from "./Component/TicketColumn";

const ResidentMain = ({ section }) => {
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const dispatch = useDispatch();
  const { search } = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(search);
  const siteId = parseInt(urlParams.get("site"));

  const allStatus = useSelector((state) => state.ticketingV2WS.status);
  const defaultSiteId = useSelector((state) => state.clientsWS.campus?.mapData?.id);
  const viewId = useSelector((state) => state.ticketingV2WS.currentViewId);

  useEffect(() => {
    if (!siteId) {
      history.push({ search: `?site=${defaultSiteId}` });
    }
  }, [history, siteId, defaultSiteId]);

  const [showFilterDrawer, setShowFilterDrawer] = useState(false);
  const [showCreateOrEditTicketModal, setShowCreateOrEditTicketModal] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [dir, setDir] = useState("desc");

  useEffect(() => {
    logger.log("siteId", siteId, !!siteId, "viewId", viewId);
    const fetchData = async () => {
      setIsLoading(true);
      try {
        Promise.all([
          dispatch(getAllStatus({ siteId, locale })),
          dispatch(getAllFloors({ siteId, locale })),
          dispatch(getAllReferential({ siteId, locale, viewId })),
        ]);
      } catch (error) {
        logger.error("Error fetching data:", error);
      }
      setIsLoading(false);
    };
    if (!!siteId) fetchData();
  }, [dispatch, locale, siteId, viewId]);

  useEffect(() => {
    logger.log("filters", filters);
  }, [filters]);

  if (isLoading) {
    return <div>{t("ticketingv2.Loading")}</div>;
  }

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <div style={{ display: "flex", justifyContent: "end", margin: 16 }}>
          <Button
            type="secondary"
            onClick={() => setShowCreateOrEditTicketModal(true)}
            icon={<Glyph name="add" />}
          >
            {t("ticketingv2.NewTicket")}
          </Button>
        </div>
        <Typography.Text strong style={{ fontSize: 17, marginLeft: "16px" }}>
          {t("ticketingv2.MyTickets")}
        </Typography.Text>
        <div style={{ display: "flex", gap: 10, margin: "8px 16px" }}>
          <SiteSelector width={245} />
          <OrderSelector dir={dir} setDir={setDir} />
          <Card
            onClick={() => setShowFilterDrawer(!showFilterDrawer)}
            style={{ cursor: "pointer" }}
          >
            <Glyph name="tune" className={"secondary"} />
            <Typography.Text strong style={{ color: "black", marginLeft: "10px" }}>
              {t("ticketingv2.AllFilters")}
            </Typography.Text>
          </Card>
        </div>
        <div style={{ display: "flex", gap: 8, height: "calc(100% - 180px)" }}>
          <div style={{ display: "flex", gap: 8, padding: "16px", overflowX: "auto" }}>
            {!!allStatus?.length &&
              allStatus.map((s) => (
                <div key={s.id} style={{ height: "100%" }}>
                  <TicketColumn
                    ticketStatus={s}
                    selectedSite={siteId}
                    filters={filters}
                    dir={dir}
                    section={section}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* New ticket Modal */}
      {showCreateOrEditTicketModal && (
        <CreateOrEditTicketModal
          showCreateOrEditTicketModal={showCreateOrEditTicketModal}
          setShowCreateOrEditTicketModal={setShowCreateOrEditTicketModal}
          siteId={defaultSiteId}
          locale={locale}
          filters={filters}
          section={section}
        />
      )}

      {/* Filter drawer */}
      <FilterDrawer
        showFilterDrawer={showFilterDrawer}
        setShowFilterDrawer={setShowFilterDrawer}
        rights="resident"
        setFilters={setFilters}
        filters={filters}
      />
    </>
  );
};

export default ResidentMain;
