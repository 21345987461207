/* eslint-disable import/no-anonymous-default-export */
import DWMTrads from "@trad/DWMProduct.json";
import CommonTrads from "./Common.json";

const trads = DWMTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.en };
}, {});

const common = CommonTrads.reduce((acc, trad) => {
  return { ...acc, [trad.KEY]: trad.en };
}, {});

export default {
  translation: {
    //COMMON
    "NoResult": "No result",
    "Available": "Available",
    "NotAvailable": "Not available",
    "In": "In",
    "SeeItinerary": "See the itinerary",
    "Today": "Today",
    "Filters": "Filters",
    "Back": "Back",
    "Next": "Next",
    "Finish": "Finish",
    "Save": "Save",
    "Floor": "Floor",
    "Cancel": "Cancel",
    "Infos": "Infos",
    "SeeMore": "See more",
    "Full": "full",
    "Confirm": "Confirm",
    "SelectAll": "Select all",
    "All": "All",
    "PrivacyPolicy": "Privacy policy",
    "CreateActu": "Manage news",
    "CreateActuPermanent": "Manage permanent content",
    "Hidden": "Hidden",
    "ErrorOccured": "An error occured",
    "Add": "Add",
    "Refresh": "Refresh",
    "Yes": "Yes",
    "Guests": "Guests & External",
    "Collaborators": "Collaborators",
    "cancel": "Cancel",
    "accept": "Accept",
    "notAccept": "Do not accept",
    "pleaseRead": "Please read the T&Cs and Privacy Policy to continue",
    "readthe": "Read the",
    "CGU": "T&Cs",
    "validCGU": "Validate the T&Cs and Privacy Policy",
    "Next_onboarding": "Next",
    "Skip": "Skip",
    "toStart": "Start",
    "Closed": "Closed",

    "Languages": "Languages",
    "French": "French",
    "English": "English",
    "Dutch": "Dutch",
    "LanguageChangeWarnTitle": "Restart the application ?",
    "LanguageChangeWarnExplain": "To apply the language change, the application need to be restarted. Are you sure you want to continue ?",

    "OCCUPIED": "Occupied",
    "AVAILABLE": "Available",

    // LOGIN
    "Welcome": "Welcome",
    "OnDashboard": "On your portal, register to continue.",
    "Login": "Login",

    // LOADER
    "Loading": "Loading",
    "LoadingMap": "Loading map...",
    "LoadingPlanning": "Loading planning",

    //HEADER

    //DASHBOARD
    "booking": "Booking in progress",
    "bookings": "Booking in progress",
    "WelcomeDesk": "Welcome Desk",

    //FastBooking
    "SpaceAvailable": "Available spaces",
    "CurrentReservation": "My bookings",
    "Book": "Book",
    "BookDuring": "Book during",
    "CancelBooking": "Cancel the reservation",
    "BackHome": "Back to home page",
    "BookingArea": "Space booked",
    "BookingDontWork": "The reservation has not been made",
    "TryAgain": "Try reserving the room again.",
    "Erase": "Delete filters",
    "Apply": "Apply",
    "SpacesAvailable": "Show spaces coming soon",
    "ReservationDetail": "Reservation details",
    "Slots": "Time slot",
    "numberPeople": "Number of people",
    "AllSpaces": "All spaces",
    "NoResultFloor": "No results on this floor",
    "TrySearching": "Try searching on another floor or remove filters.",
    "AvailbleFloor": "Availability on other floors",
    "ResultFloor": "result(s) on this floor",
    "Equipements": "Equipment",

    //fastbooking evo
    "SeeSpaces": "See the list of spaces",
    "BookThe": "Book the",
    "From": "From",
    "From_": "from",
    "When": "For",
    "MyDay": "My day",
    "Consultbook": "Click here to view your reservations",
    "ApplyFilter": "Apply filters",
    "AvancedSearch": "Advanced search",
    "AllFloor": "All floors",
    "Services": "Services",
    "Result": "Result",
    "Treatment": "In progress",
    "Places": "seat(s)",
    "BookCanceled": "Booking canceled",
    "ErrorCancelBook": "Impossible to cancel the booking",
    "Spaces": "Spaces",
    "SelectTheSpace": "Select the space",
    "Of": "of",
    "NumberPlaces": "Number of people",
    "None": "None",
    "SearchByName": "Search by name",
    "Until": "Until",
    "To": "to",
    "Started": "Start at",
    "StartedThe": "Start the",
    "AdvertisementMessage": "Attention: After this date, self-service spaces will not be displayed.",
    "fastbooking.sureCanceled": "Are you sure you want to cancel the reservation?",
    "fastbooking.rooms.types": "Room types",
    "fastbooking.updateSite": "Change site",
    "fastbooking.redirectSite": "You will be redirected to the {{site}} site portal. Once logged in, click on {{fastbooking_name}} to book a space",
    "backToFilter": "Back to filters",
    "fastbooking.place": "seat",
    "fastbooking.The_": "On ",
    "fastbooking._from_": " from ",
    "fastbooking._to_": " to ",
    "fastbooking.Today": "Today",

    //WebOnMobile
    "mobile.downloadApp": "Download mobile app",
    "mobile.ExpireOn_": "Expires on : ",
    "mobile.AccessService": "Access the service",
    "mobile.YourAccessBadge": "Your access badge",
    "mobile.searchResult": "Search results",
    "mobile.NoAccessCode": "No access code",
    "mobile.logout": "Logout",
    "mobile.Home": "Home",

    //GuidMe
    "FindSpace": "Enter space name",
    "LastSearch": "My latest research",
    "Favoris": "My favorites",
    "Itinerary": "Itinerary",
    "Duration": "Duration",
    "Start": " Find a starting point",
    "End": "Find an arrival point",

    //Ticketing
    "Requests": "My tickets",
    "NewTicket": "New Ticket",
    "New": "New",
    "TakenIntoAccount": "Acknowledged",
    "Suspended": "Pending",
    "Close": "Closed",
    "NoWaiting": "No new ticket",
    "NoTaken": "No acknowledged ticket",
    "NoSuspended": "No pending ticket",
    "NoClose": "No closed ticket",
    "Status": "Status",
    "Location": "Location",
    "Pictures": "Pictures",
    "By": "By",
    "Category": "Category",
    "SelectCategory": "Select a category",
    "ChooseLocation": "Specify a location",
    "WriteSomething": "Add details about your request and the location",
    "Adds": "Add documents",
    "YourRequest": "Your request is taken into account",
    "Informed": "You will be informed of the progress of your application",
    "UseSearch": "Use the search field to find a space.",
    "closed": "Closed",
    "CreateTicket": "Create a ticket",
    "AddComment": "Add a comment",
    "AllRequest": "All my tickets",
    "Summary": "Summary",
    "Followed": "Followed",
    "Discussion": "Discussion",
    "Subcategory": "Sub-category",
    "MoreDetails": "Enter a subcategory",
    "Description": "Comment",
    "errorTicket": "Error creating ticket",
    "errorDoc": "You have reached the limit of the number of documents allowed",
    "refresh": "refresh",

    /**
     * VISITORS
     */
    "visitors.delegated": "My delegated visits",
    "visitors.forms.visit.create": "Create a new visit",
    "visitors.forms.visit.update": "Update the visit",
    "visitors.forms.visit.delete": "Cancel the visit",
    "visitors.forms.visit.fields.duration.day": "{{count}} day",
    "visitors.forms.visit.fields.duration.day_other": "{{count}} days",
    "visitors.forms.visit.resident": "Invite for someone else",
    "visitors.forms.visit.fields.resident": "Search for a resident",
    "visitors.forms.visit.fields.visitors": "Add guests",
    "visitors.forms.visit.fields.visitors.helper": "Enter the name of the person you want to add to the invitation. If you can't find this person in the repository, just enter their email address.",
    "visitors.forms.user.create": "Create a visitor",
    "visitors.forms.user.update": "Update a visitor",
    "visitors.forms.user.fields.firstname": "Firstname",
    "visitors.forms.user.fields.lastname": "Lastname",
    "visitors.forms.user.fields.login": "Email address",
    "visitors.forms.user.fields.login.forbidden": "This domain name is not allowed to create a new visitor",
    "visitors.forms.user.fields.company": "Company",
    "visitors.forms.user.fields.function": "Function",
    "visitors.forms.visit.detail": "Details of your visit",

    /**
     * WIFI
     */
    "wifi.ssid": "Network name",
    "wifi.password": "Password",
    "wifi.helper.title": "Save some time !",
    "wifi.helper.description": "Scan this QR Code with your smartphone to automatically connect to the Wifi network",

    /**
     * MAP
     */
    "map.loading": "Loading map...",

    /**
     * SPACE AS A SERVICE
     */
    "spas": "Space As a Service",
    "spas.status.PENDING": "Pending",
    "spas.status.PENDING_other": "Pending",
    "spas.status.UNASSIGNED": "Unaffected",
    "spas.status.UNASSIGNED_other": "Unaffected",
    "spas.status.APPROVED": "Declaration accepted",
    "spas.status.APPROVED_other": "Declaration accepted",
    "spas.status.DECLINED": "Refused",
    "spas.status.DECLINED_other": "Refused",
    "spas.status.CANCELLED": "Canceled",
    "spas.status.CANCELLED_other": "Canceled",
    "spas.period.MORNING": "Morning",
    "spas.period.AFTERNOON": "Afternoon",
    "spas.period.DAY": "Whole day",
    "spas.type.ON_SITE": "On site",
    "spas.type.TELEWORKING": "Teleworking",
    "spas.type.TRAVEL": "Travel",
    "spas.type.DAY_OFF": "Day off",
    "spas.type.NOT_DECLARED": "Not declared",
    "spas.recurrence.NONE": "None",
    "spas.recurrence.RECURRENCE": "Recurrency:",
    "spas.recurrence.EVERY_DAY": "Every day",
    "spas.recurrence.EVERY_WEEK": "Every {{day}}",
    "spas.recurrence.EVERY_MONTH": "Every month",
    "spas.recurrence.EVERY_2_WEEK": "Every other {{day}}",
    "spas.teleworking.limit.WEEK": "You have reached the {{count}} days of teleworking allowed this week.",
    "spas.teleworking.limit.MONTH": "You have reached the {{count}} days of teleworking allowed this month.",
    "spas.tabs.MY_PLANNING": "My planning",
    "spas.tabs.MY_TEAM": "My team",
    "spas.tabs.MY_CONTACTS": "My contacts",
    "spas.steps.DATES": "Step {{count}} : Dates",
    "spas.steps.TEAM_MEMBERS": "Step {{count}} : Team members",
    "spas.steps.WORKPLACE": "Step {{count}} : Workplace",
    "spas.steps.ROOM": "Step {{count}} : Room",
    "spas.steps.REVIEW": "Step {{count}} : Review",
    "spas.history.createRequest": "The request has been created",
    "spas.history.approve": "A date has been accepted",
    "spas.history.approveAll": "All dates have been accepted",
    "spas.history.decline": "A date has been canceled",
    "spas.history.declineAll": "All dates have been canceled",
    "spas.history.cancel": "A date has been canceled",
    "spas.history.cancelAll": "All dates have been canceleds",
    "spas.history.overrided": "Dates have been canceled to free up space",
    "spas.planning": "Planning",
    "spas.request": "Request",
    "spas.request.none": 'You have no declaration today. To make one, go to the "New declaration" button at the top of your screen.',
    "spas.request.details": "Request Details",
    "spas.request.update": "Updating declaration",
    "spas.request.new": "New declaration",
    "spas.request.new.team": "New declaration for my team",
    "spas.request.cancel": "Cancel declaration",
    "spas.request.comments": "Comments",
    "spas.request.id.title": "ID request {{id}}",
    "spas.request.site": "Where do you want to work today?",
    "spas.request.isSerie": "This reservation has an occurrence",
    "spas.request.type.title": "What type of declaration do you want to make ?",
    "spas.request.site.title": "Where do you want to work ?",
    "spas.request.dates": "Dates",
    "spas.request.dates.title": "When do you want to work ?",
    "spas.request.dates.helper": "Select the date(s) on which you want to work",
    "spas.request.dates.helper2": "The declaration will be made for the selected date(s).",
    "spas.request.dates.dateOccupied": "This date is occupied on the selected period. It will be ignored.",
    "spas.request.dates.validator.min": "You must select at least one date.",
    "spas.request.dates.validator.occupied": "All dates are occupied on the selected period. Please select other dates.",
    "spas.request.startDate.title": "Start date",
    "spas.request.startDate.helper": "start from",
    "spas.request.period.title": "Duration",
    "spas.request.period.helper": "Select a duration",
    "spas.request.recurrence": "Recurrence",
    "spas.request.recurrence.helper": "Select a recurrence",
    "spas.request.recurrence.multi": "You can't select a recurrence with multiple dates",
    "spas.request.recurrence.end": "End of recurrence",
    "spas.request.members.title": "Request for",
    "spas.request.members.search": "Search in my team",
    "spas.request.members.selected": "Selected",
    "spas.request.members.noAccess": "Has no access to the selected sector",
    "spas.request.members.warning.availability": "Some users have not been added because they are not available on the selected date(s).",
    "spas.request.members.warning.access": "Some users have not been added because they do not have access to the selected sector.",
    "spas.request.roomIds.title": "Select one or more spaces",
    "spas.request.roomIds.warning": "Please note: There are not enough spaces available for the number of people selected. Please choose a second space or one with sufficient room.",
    "spas.request.endDate": "Ends on {{date}}",
    "spas.request.forceEndDate": "Force end date",
    "spas.request.from": "From : {{user}}",
    "spas.request.comment.title": "Comments",
    "spas.request.comment.helper": "Add a comment (required)",
    "spas.request.actions.confirmBooking": "Confirm booking",
    "spas.request.actions.book": "Next",
    "spas.request.actions.map": "See on the map",
    "spas.request.actions.workplaces": "See the list",
    "spas.request.actions.approve": "Assign a seat",
    "spas.request.actions.approve_other": "Assign seats",
    "spas.request.actions.decline": "Refuse the request",
    "spas.request.actions.decline_other": "Refuse requests",
    "spas.request.actions.cancel": "Cancel declaration",
    "spas.request.actions.updateReservation": "Update declaration",
    "spas.request.actions.saveReservation": "Save Changes",
    "spas.request.actions.updateSpace": "Change Assignment",
    "spas.request.actions.updateSpace_other": "Change assignments",
    "spas.request.actions.removeSpace": "Delete Assignment",
    "spas.request.actions.removeSpace_other": "Delete Assignments",
    "spas.request.actions.occurence": "Only this day",
    "spas.request.actions.serie": "The whole series",
    "spas.request.actions.assignValidate": "Validate assignment",
    "spas.request.actions.assignIn": "Assign in {{desk}}",
    "spas.request.actions.assignAuto": "Automatically assign",
    "spas.request.actions.assignAuto.selectMore": "You must select more rooms to reach a sufficient number of places",
    "spas.request.actions.assignAuto.helper": "Select in which space(s) to assign {{count}} person",
    "spas.request.actions.assignAuto.helper_other": "Select in which space(s) to assign {{count}} people",
    "spas.request.backCalendar": "Back to calendar",
    "spas.request.seeDay": "see my day",
    "spas.request.reservation": "Registration in progress",
    "spas.request.resaAccepted": "Declaration accepted",
    "spas.request.resaRefused": "Registration has failed",
    "spas.request.tryAgain": "Try Again",
    "spas.conflict": "Declaration could not be made on the following days",
    "spas.conflict.other": "The desk you selected is no longer available",
    "spas.conflict.recurrence.other": "The desk you selected was no longer available",
    "spas.conflict.recurrence.self": "You already had another declaration",
    "spas.admin.header.week": "Week {{number}}",
    "spas.admin.header.weekPrevious": "Previous week",
    "spas.admin.header.weekNext": "Next week",
    "spas.admin.header.pending": "Pending requests",
    "spas.admin.header.approved": "Validated requests",
    "spas.admin.header.estimated": "Estimated occupancy",
    "spas.admin.header.available": "{{count}} / {{total}} available",
    "spas.admin.header.available_other": "{{count}} / {{total}} available",
    "spas.admin.header.occupied": "{{count}} / {{total}} occupied place",
    "spas.admin.header.occupied_other": "{{count}} / {{total}} occupied place",
    "spas.admin.header.map": "See the building plan",
    "spas.admin.tabs.search": "Find a person",
    "spas.admin.tabs.selected": "{{count}} selected",
    "spas.admin.tabs.selected_other": "{{count}} selected",
    "spas.admin.tabs.resident": "Resident name",
    "spas.admin.tabs.manager": "Manager name",
    "spas.admin.tabs.from": "Author request",
    "spas.admin.tabs.createdAt": "date of the request",
    "spas.admin.tabs.recurrence": "Recurrence",
    "spas.admin.tabs.space": "Location",
    "spas.admin.tabs.affectedAt": "Date of assignment",
    "spas.admin.tabs.declinedAt": "Date refused",
    "spas.admin.map.search.title": "Select a space",
    "spas.admin.map.search.helper": "Use the map or search field to select a space to assign.",
    "spas.admin.map.search": "Find a space",
    "spas.widget.noResa": "No declaration today",
    "spas.manage.followingPerson": "Followed people",
    "spas.manage.waiting.invitation": "pending invite(s)",
    "spas.manage.lengthPerson": "Tap here to view your pending invites",
    "spas.manage.noResult": "No results in your people followed.",
    "spas.manage.addPerson": "Add people",
    "spas.manage.stopFollow": "stop flollowing",
    "spas.manage.noFollower": "You are not currently following anyone.",
    "spas.manage.searchFollower": "Find someone in your organization's directory and send an invitation.",
    "spas.manage.received.invitation": "Invitations received",
    "spas.manage.shareNotebook": "Tap an invite to share your calendar.",
    "spas.manage.accepted": "Accept the invitation",
    "spas.manage.ignored": "Ignore the invitation",
    "spas.messages.create.error": "Unable to update the declaration",
    "spas.messages.cancel.error": "Unable to cancel declaration",
    "spas.messages.decline.error": "Unable to deny the request",
    "spas.messages.removeSpace.error": "Unable to remove assignment",
    "spas.messages.conflicts.error": "Unable to check space availability",
    "spas.people.present": "Who will be present on site ?",
    "spas.people.whoResa": "Find out who will be present that day",
    "spas.people.noResult": "No results in your people followed for this day.",
    "spas.people.find": "Search followed people",
    "spas.messages.cancel.success": "The cancellation request has been taken into account.",
    "spas.request.confirm.presence": "Confirmed my presence",
    "spas.request.validate": "Validate",
    "spas.presence.confirmed": "Presence confirmed",
    "spas.presence.present": "Your presence has already been confirmed",
    "spas.presence.error": "An error has occurred, your presence could not be confirmed. Please try again.",
    "spas.presence.today": "Your presence could not be confirmed. You can confirm your presence only during the corresponding booking period.",
    "spas.presence.success": "You have confirmed your presence",
    "spas.presence.code": "Enter the 6-digit code located on the office label",
    "spas.place.available": "Available seats",
    "spas.workplace.title": "Workplace",
    "spas.workplace.subtitle": "Select a workplace",
    "spas.workplace.multiple_dates": "Several days are selected, the display of followed people is not available",
    "spas.workplace.loading.title": "Just a moment",
    "spas.workplace.loading.subtitle": "We are looking for available workspaces for you.",
    "spas.workplace.your": "Your workspace",
    "spas.workplace.free": "available seat(s)",
    "spas.workplacemap.helper": "Select an available desk by clicking on the map.",
    "spas.room.nbDesk": "{{count}} desks",
    "spas.prsence.alreadyConfirmed": "Already confirmed",
    "spas.presence.noConfirmed": "You cannot confirm your presence on a reservation that has not started",
    "spas.request.error.sectors": "No sector assigned",
    "spas.request.error.sectors.content": "No sector is assigned to you on this site.",
    "spas.request.error.date": "Start date greater than end date",
    "spas.collabs.xDeclared": "{{count}} declared collaborator(s)",
    "spas.collabs.presence": "Presence",
    "spas.declarations": "{{count}} declaration(s)",

    /**
     * PHONE BOOK
     */

    "phoneBook.seachPhoneBook": "Find someone in your organization's directory",
    "phonebook.useSearch": "Use the input field located at the top of the screen to find a person by writing the first 3 characters of their name or surname.",
    "phoneBook.backToSearch": "Back to search",
    "phoneBook.title": "Contact List",
    "phoneBook.send.invitation": "Send an invitation",
    "phoneBook.success": "Invitation sent",
    "phoneBook.error": "The invitation has not been sent",
    "phoneBook.error.alreadySend": "The invitation has already been sent",
    "phoneBook.number.contact": "Displaying {{nbItems}}/{{totalItems}} contacts. Refine your search to find the person you are looking for.",
    "phoneBook.search": "Find a contact",

    /**
     * CONFORT
     */

    "Stage": "Floor",
    "Temperature": "Temperature",
    "Lighting": "Lighting",
    "Ventilation": "Ventilation",
    "Blind": "Blind",
    "Blinds": "All blinds",
    "Light": "Light",
    "ControlEquipment": "Control the comfort equipment in a space by selecting it on the left of the screen.",

    // SEARCH ROOM
    "SelectSpace": "Select a space",
    "UseCard": "Use the search box to find a space to pilot.",
    "ResultSearch": "Result of the research",
    "SearchSpace": "Search for a space...",

    // ERROR
    "ErrorSetLight": "An error has occured on the lights",
    "ErrorSetBlind": "An error has occured on the blinds",
    "ErrorSetBlindAngle": "An error has occured on the blinds' angle",
    "ErrorSetTemperatures": "An error has occured on the temperature",
    "ErrorSetVentilation": "An error has occured on the ventilation",

    "ErrorModeAuto": "Cannot start auto mode",

    "ErrorGetLight": "Something went wrong on the lights. Please try a new search, or refresh the page.",
    "ErrorGetBlind": "Something went wrong on the blinds. Please try a new search, or refresh the page.",
    "ErrorGetTemperatures": "Something went wrong on the temperature. Please try a new search, or refresh the page.",
    "ErrorGetVentilation": "Something went wrong on the ventilation. Please try a new search, or refresh the page.",
    "ErrorGetComfort": "Sorry, we cannot reach the comfort of your room. Please try a new search, or refresh the page.",

    // POWERBI
    "TypeDuration": "Choose here to display the results for the whole day or only for half a day.",
    "IntervalDate": "Select a pre-selected date interval or define a custom interval.",
    "Interval": "Interval",

    // AGORA
    "Places.Frequentation": "Attendance",
    "Places.Now": "Now",
    "Places.Informations": "Informations",
    "Places.hours": "Opening hours",
    "Places.Location": "See location",
    "Places.Equipments": "Equipments",
    "Places.others": "Others",
    "Places.Open": "Open",
    "Places.Closed": "Closed",
    "Places.Services": "Services",
    "Places.Clicked": "Click here",
    "Places.email": "Email",
    "Places.closed": "Closed",
    "Places.phone": "Phone",

    "Places.Frequentation.Content": "The real-time occupancy rate is obtained using sensors that can count the number of people present in this space.",
    "Places.Booking.Details.Title": "Booking details",
    "Places.Booking.Details.Date": "Booking date",
    "Places.Booking.Details.Hours": "Schedule",
    "Places.Booking.Details.available": "available spot(s)",
    "Places.Booking.Success.Title": "Booking saved",
    "Places.Booking.Success.View": "See booking",
    "Places.Booking.Cancel.Title": "Details of my booking",
    "Places.Booking.Cancel.Party": "Booking for {{party}} people",
    "Places.Booking.Cancel.Button": "Cancel my booking",
    "Places.Solde.Text": "Cash balance",
    "Places.Solde.Link": "Recharge my account",
    "Places.Booking.Recap.Title": "Booking",
    "Places.Booking.Recap.seats": "For {{seats}} people",
    "Places.Booking.Error.Title": "The reservation has not been made",
    "Places.Booking.Error.Text": "Try to book again.",
    "Places.Menu.date": "Choose a date",
    "Places.Title.Booking": "Book slot",
    "Places.Title.Solde": "My balance",
    "Places.Title.Cancel": "Details of my booking",
    "Places.Title.Menu": "Menu",
    "Places.Booking.Next": "My next 2 reservations",

    // FACILITIES
    "facilities.week": "Week {{number}}",
    "facilities.weekPrevious": "Previous week",
    "facilities.weekNext": "Next week",
    "facilities.dashboard.noEvent": "No event scheduled this day",
    "facilities.resident.helper": "facilities.resident.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.resident.noEvent.title": "You have no scheduled event.",
    "facilities.resident.noEvent.subtitle": "Book a space in your Outlook calendar so that your event can be organized by the facilities team.",
    "facilities.filters.categories": "Categories",
    "facilities.filters.rooms": "Rooms",
    "facilities.filters.rooms.rooms": "Room",
    "facilities.filters.rooms.types": "Room types",
    "facilities.filters.organizer": "Organizer",
    "facilities.filters.hidden": "Display hidden events",
    "facilities.filters.new": "Hide unprocessed events",
    "facilities.filters.tasks": "Hide events without current action",
    "facilities.filters.erase": "Remove filters",
    "facilities.event.new": "New event",
    "facilities.event.notHandled": "Unprocessed",
    "facilities.event.noCategory": "No category",
    "facilities.event.tasks.tooltip": "{{completed}} actions out of {{total}}",
    "facilities.event.subscribed": "Alert emails are activated",
    "facilities.event.notSubscribed": "Alert emails aren't activated",
    "facilities.event.helper": "facilities.event.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.tab.new": "New tab",
    "facilities.event.tab.helper": "facilities.event.tab.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.tab.general": "General",
    "facilities.event.tab.description": "Notes",
    "facilities.event.section.TEXT": "Text area",
    "facilities.event.section.TEXT.helper": "facilities.event.section.TEXT.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.TEXT.new": "New text area",
    "facilities.event.section.RADIO": "Choice list",
    "facilities.event.section.RADIO.helper": "facilities.event.section.RADIO.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.RADIO.new": "New choice list",
    "facilities.event.section.LIST": "Bulleted list",
    "facilities.event.section.LIST.helper": "facilities.event.section.LIST.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.LIST.new": "New bulleted list",
    "facilities.event.section.CHECKBOX": "Action list",
    "facilities.event.section.CHECKBOX.helper": "facilities.event.section.CHECKBOX.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.CHECKBOX.new": "New action list",
    "facilities.event.section.FILE": "Attachments",
    "facilities.event.section.FILE.helper": "facilities.event.section.FILE.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.section.FILE.new": "New attachment area",
    "facilities.event.section.add": "Add a section of your choice",
    "facilities.event.hide": "Hide this event",
    "facilities.event.hide_off": "Unhide this event",
    "facilities.event.date": "Date",
    "facilities.event.startDate": "Start on",
    "facilities.event.endDate": "End on",
    "facilities.event.location": "Location",
    "facilities.event.description": "Description",
    "facilities.event.category": "Category",
    "facilities.event.organizer": "Organizer",
    "facilities.event.save_cancel": "Cancel changes",
    "facilities.event.save": "Save",
    "facilities.event.contributors": "Contributors",
    "facilities.event.contributors.add": "Add a contributor",
    "facilities.event.contributors.remove": "Remove",
    "facilities.event.contributors.helper": "facilities.event.contributors.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.externals": "Externals",
    "facilities.event.externals.add": "Add an external email",
    "facilities.event.externals.remove": "Remove",
    "facilities.event.externals.helper": "facilities.event.externals.helper Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    "facilities.event.edit.open": "Edit this tab",
    "facilities.event.edit.close": "Exit edition mode",
    "facilities.event.delete": "Delete this tab",
    "facilities.event.comments": "Comments",
    "facilities.event.comments.add": "Add a comment",
    "facilities.event.comments.send": "Send",
    "facilities.categories.new": "Create a category",
    "facilities.files.status.NEW": "New",
    "facilities.files.status.PENDING": "Pending",
    "facilities.files.status.VALIDATED": "Validated",
    "facilities.files.status.DECLINED": "Refused",
    "facilities.files.status.ABANDONED": "Abandoned",
    "facilities.files.remove": "Remove",
    "facilities.files.upload.title": "Click or drag n drop inside this area to add files",
    "facilities.files.upload.save": "Save those files",

    //PUBLIC
    "Public.activated": "Your account is activated!",
    "Public.connected": "Please connect now on the application and on the web portal with your login",
    "Public.connected2": "Please connect now on the application with your login",
    "Public.upload": "Download the mobile app from the app stores or go to the web portal using the links below.",
    "Public.upload2": "Download the mobile app from the app stores using the links below.",
    "Public.error.activated": "Your account is already activated",
    "Public.password.verification": "User verification",
    "Public.password.activated": "Activate your account",
    "Public.password.experience": "To enjoy the experience on our mobile application and on the web portal, please activate your guest account by choosing a password.",
    "Public.password.identifiant": "Login",
    "Public.password.email": "Use the email address from which you received your invitation email.",
    "Public.password.password": "Choose a password",
    "Public.password.obligatory": "The password field is mandatory",
    "Public.password.strongPassword": "Choose a strong password according to the following requirements.",
    "Public.password.eight": "8 characters minimum",
    "Public.password.one": "1 special character",
    "Public.password.maj": "1 uppercase",
    "Public.password.number": "1 figure",
    "Public.password.min": "1 lowercase",
    "Public.password.confirm": "Confirm your password",
    "Public.password.same": "Same passwords",
    "Public.password.active": "Activate my account",
    "Public.expired.finish": "Your booking is over",
    "Public.expired.free": "You cannot release / keep your space",
    "Public.failure.request": "Your request could not be processed.",
    "Public.failure.spaces": "Your request to book the space {{title}} from {{startHour}} to {{endHour}} could not be processed due to an error.",
    "Public.failure.excuse": "We apologise for the inconvenience and invite you to try again later.",
    "Public.success.free": "The space has been freed up",
    "Public.success.spaces": "The booking of the space {{title}} from {{startHour}} to {{endHour}} has been cancelled.",
    "Public.success.nowFree": "This space is now available for other bookings.",
    "Public.keep.kept": "Your booking is kept",
    "Public.keep.noAlert": "You will no longer receive any alerts for this booking.",
    "public.keep.spaces": "The booking of the space {{title}} from {{startHour}} to {{endHour}} is kept.",
    "Public.password.error": "Error while creating the password",

    // TRANSFERT
    "transfert.myDeparture": "My departure",
    "transfert.site": "Place of departure",
    "transfert.building": "Building",
    "transfert.boxes": "Boxes deposit",
    "transfert.move": "Moving",
    "transfert.myMoveIn": "My move-in",
    "transfert.moveIn": "Planned move-in",
    "transfert.zone": "Arrival area",
    "transfert.help": "Need help ?",
    "transfert.question": "A question about your move ?",
    "transfert.contact": "Contact your correspondent who can help you.",
    "transfert.myMove": "My move",
    "transfert.welcome": "Welcome to the Transfer feature !",
    "transfert.findInfo": "Find all the information related to your move here.",
    "transfert.understand": "I understand",
    "transfert.noMove": "You have no move in progress",
    "transfert.addAndUpdateFile": "Add or modify a file",
    "transfert.addFile": "Add file",
    "transfert.error": "Error when adding the file",
    "transfert.success": "File add successfully",
    "transfert.created": "Created",
    "transfert.updated": "Edited",
    "transfert.deleted": "Deleted",
    "transfert.row": "Row",
    "transfert.errors": "Error(s)",

    // CATERING
    "catering.prestationAvailable": "Available services",
    "catering.prestationNoAvailable": "Unavailable services",
    "catering.orderOutOfTime": "Your order is out of time, it is too late to book the services below.",
    "catering.order": "Order a service",
    "catering.newOrder": "New request",
    "catering.orderFor": "Order for the",
    "catering.prestation": "My services in progress",
    "catering.outlook": "Ordered from Outlook",
    "catering.newPresta": "New service",
    "catering.thisSpace": "Choose this space",
    "catering.addPresta": "Add the service",
    "catering.saveOrder": "Register the order",
    "catering.askSpace": "In which space would you like to order the service?",
    "catering.orderThe": "Order the",
    "catering.addAPrestra": "Add a service",
    "catering.selectRubrique": "Select an item",
    "Total": "Total",
    "catering.resume": "Summary of the service",
    "catering.canceled": "Cancel the order",
    "catering.sureCanceled": "Are you sure you want to cancel the order?",
    "No": "No",
    "catring.successUpdate": "Successful change",
    "catering.errorUpdate": "Error when editing",
    "catering.update": "Edit the order",
    "catering.noOrder": "You can no longer edit the order",
    "amount": "Quantity",
    "obligatory": "Required field",
    "canceled": "Canceled",
    "catering.request": "Request",
    "catering.date": "Date:",

    // NEWS
    "BackTo": "Back to {{topic}}",
    "Sure_Leave": "You are about to exit the content editor. You will lose the unpublished work in progress. Are you sure you want to continue ?",
    "Yes_Leave": "Yes, leave",
    "Select_A_Group": "Select at least one group to be able to publish",
    "Content_Editing": "Content editing",
    "Notif_Editing": "Notification editing",
    "Publish": "Publish",
    "Update": "Update",
    "Upload": "Add",
    "Edit_Internationalized": "Select the language in which you want to edit your publication.",
    "Image_Library": "Image library",
    "Add_Image": "Upload an image",
    "Select_Image": "Select an image from the library",
    "Title": "Title",
    "Subtitle": "Subtitle",
    "Notif_Trigger": "Notification trigger",
    "Choose_Title": "Write a title",
    "Choose_Subtitle": "Write a subtitle",
    "Publication": "Publication",
    "Content_To_Be_Shawn": "The content will be visible in the application from",
    "Hide_Content": "Hide content before publication",
    "Send_Notif": "Send a notification",
    "Access": "Visibility restriction",
    "Target": "Target",
    "Select_Group": "To limit the visibility of this publication, select the user groups that are allowed to view it. (By default, the publication will be visible by all groups)",
    "Select_Group2": "Select recipients of the notification",
    "No_Group_Found": "No group found",
    "Topics": "Topics",
    "Select_Topics": "Select one or many topics for the news",
    "No_Topic": "No topic available",
    "Attach_To_Place": "If it is an event, you can add a location to your publication.",
    "Attach_To_Place2": "Attach this content to a place",
    "No_Stage_Found": "No stage found",
    "Select_A_Room": "Select a space",
    "Select_A_Stage": "Select a stage",
    "No_Room_Found": "No space found",
    "Room": "Space",
    "Name": "Name",
    "Enter_Name_Place": "Enter the name of the place/room",
    "Publication_Preview": "Preview in the app",
    "Add_News": "Create a news",
    "Add_Notif": "Create a notification",
    "Add_Topic": "Create a topic",
    "Update_Topic": "Update a topic",
    "Manage_Topic": "Manage topics",
    "Trendings": "Spotlight",
    "Published_On": "Published on",
    "Created_On": "Created on",
    "Header_Image": "Header image",
    "At": "at",
    "Get_details": "Retrieving section details...",
    "Get_Content": "Fetching contents...",
    "Get_Content_Error": "Error while fetching contents",
    "Get_Groups": "Fetching Swizi groups...",
    "Get_Groups_Error": "Error while fetching Swizi groups",
    "Get_Topcis_Error": "Error while fetching topics",
    "Get_Media": "Fetching Swizi medias...",
    "Get_Media_Error": "Error while fetching Swizi medias",
    "Get_Resource": "Fetching Swizi resources....",
    "Get_Resource_Error": "Error while Fetching Swizi resources",
    "Show_HTML": "HTML view",
    "Show_Editor": "Editor view",
    "Format_Code": "Format HTML code",
    "ShowMore": "Show more >",
    "Published": "Published {{date}}",
    "PublishedAt": "Published {{date}}",
    "News": "News",
    "InTheSameTopic": "In the same topic",
    "Event": "Event",
    "Event_description": "Create an event to get it on the news",
    "Event_startDate": "Start",
    "Event_endDate": "End",
    "Event_date_place": "Date and place of the event",
    "Event_from": "From",
    "Event_to": "To",
    "noFloorSelect": "No floor selected",

    // NEWS V2
    "news.read.article": "Read this article",
    "news.spotlight": "Top news",
    "news.all.article": "View all articles",
    "news.event.title": "Date and place of the event",
    "news.event.agence": "Agency of",
    "news.article.like": "I like this article",
    "news.article.share": "Share this article",
    "news.article.same": "Similar articles",
    "news.comments": "Comments",
    "news.comment.write": "Write your commet here",
    "news.comment.send": "Send",
    "news.editor.confirm.title": "Delete the news",
    "news.editor.cnfirm.content": "Are you sure you want to delete the news",
    "news.editor.new": "New publication",
    "news.editor.new.topic": "Manage topics",
    "news.edior.all.topics": "All topics",
    "news.editor.published.topics": "Published in",
    "news.editor.update": "Modify",
    "news.editor.hide": "Hide",
    "news.editor.noHide": "Unhide",
    "news.editor.delete": "Delete",
    "news.editor.publish": "Publish",
    "news.editor.hide.title": "Hide the publication",
    "news.editor.noHide.title": "Unhide the publication",
    "news.editor.hide.content": "This publication will be hidden and will not be visible to users. Its content will not be deleted.",
    "news.editor.noHide.content": "This publication will be unhidden and visible to users",
    "news.editor.hide.success": "News hidden",
    "news.editor.noHide.success": "News unhidden",
    "news.editor.hide.error": "The news has not been hidden",
    "news.editor.topics.create.success": "Successful creation of the topic",
    "news.editor.topics.create.error": "Topic creation failed",
    "news.editor.topics.update.success": "Topic modified successfully",
    "news.editor.topics.update.error": "Topic modification failed",
    "news.editor.topics.delete": "Topic deleted",
    "news.editor.topics.use": "Already used",
    "news.editor.topics.add": "Add a topic",
    "news.editor.topics.new": "New topic",
    "news.editor.topics.update": "Modify the topic",
    "news.editor.topics.create": "Create the topic",
    "news.editor.topics.list": "Topics list",
    "news.editor.topics.name": "Topic name",
    "news.editor.update.success": "Successful modification",
    "news.editor.update.error": "Modification failed",
    "news.editor.create.success": "Successful publication",
    "news.editor.create.error": "Publication failed",
    "news.editor.create.error.pictures": "Please add a header image",
    "news.editor.web": "Web",
    "news.editor.mobile": "Mobile",
    "news.editor.title": "Click to add a title",
    "news.editor.subtitle": "Click to add a subtitle",
    "news.editor.header": "Click to add a header image",
    "news.editor.content": "Click to enter the publication body",
    "news.editor.options.languages": "Select the language and edit the content of this publication on the left of the screen.",
    "news.editor.options.topics": "Select one or more topics in which to add the publication.",
    "news.editor.options.notification.title": "Send a notification",
    "news.editor.options.notification.content": "Select this option if you want users to receive a notification when this publication goes online.",
    "news.editor.options.publication.title": "Schedule the publication",
    "news.editor.options.publication.content": "Select this option and specify the date and time when this publication will be automatically uploaded.",
    "news.editor.options.hide.title": "Hide automatically",
    "news.editor.options.hide.content": "Select this option and specify the date and time when this publication will be hidden from users.",
    "news.editor.options.event.title": "Add an event",
    "news.editor.options.event.placeholder": "Add a name to the event",
    "news.editor.options.event.content": "If it's an event you can add a location to your publication.",
    "news.editor.options.event.name": "Event name",
    "news.editor.options.event.start": "Start of the event",
    "news.editor.options.event.end": "End of the event",
    "news.editor.options.event.localisation": "Location",
    "news.editor.options.event.building": "Building",
    "news.editor.options.event.outBuilding": "Outside building",
    "news.editor.options.event.findAdress": "Search an address",
    "news.editor.options.groups.title": "Limit visibility",
    "news.editor.options.groups.content": "To limit the visibility of this publication, select the user groups authorised to view it (By default, the publication will be visible to all groups).",
    "news.editor.options.groups.select": "Select a group",
    "news.editor.options.comments.title": "Allow comments",
    "news.editor.options.comments.content": "Select this option to allow users to add a comment to the publication.",
    "news.editor.iframe.title": "HTML integration",
    "news.editor.iframe.content": "Copy the HTML code of the site you want to integrate.",
    "news.editor.iframe.placeholder": "Insert the HTML code here",
    "news.editor.library.import.error": "Error when adding an image",
    "news.editor.library.title": "Insert an image",
    "news.editor.library.validation": "Select this image",
    "news.editor.library.content": "Select an existing image from the library below or import a new image from your computer.",
    "news.editor.library.format": "Supported formats: png, jpeg Recommended header size: 1000x300px",
    "news.editor.library.import": "Import",
    "news.editor.pictures.title": "Integrate an image tag",
    "news.editor.library.import.modal.title": "Confirm import ?",
    "news.editor.library.import.modal.p1": "The import of your image will begin.",
    "news.editor.library.import.modal.p2": "It will be compressed and available in a few moments.",
    "news.editor.library.import.success": "Successful import",
    "news.details.start.event": "From",
    "news.details.end.event": "until",
    "news.detail.noNews": "No publication for the moment, back later",
    "news.editor.find": "Find a news",
    "news.editor.library.search": "Find a picture",
    "news.editor.NewsDeleted": "News deleted",
    "news.editor.BackToTheList": "Back to the list",
    "news.editor.success": "Success",

    // TRANSPORT
    "transport.title": "Public transport",
    "transport.subtitle": "Select a stop",
    "transport.segmented.allStop": "All stops",
    "transport.segmented.fav": "My favorites",
    "transport_no_favorite": "Browse stops and bookmark lines to find them here.",
    "select_a_line": "Select a line",
    "go_to": "Go to",
    "traffic_info": "Traffic info",
    "all_lines": "All lines",
    "favorite": "Favorite",
    "next_departures": "Next departures",
    "no_next_departures": "No next departures",
    "navitia_data": "Data provided by Navitia",
    "transport_no_alerts": "Everything is fine, no alerts for the moment.",
    "my_latest_research": "My last research",
    "itinerary_sub": "Indicate a starting and ending point",
    "walking": "walking",
    "departure_from": "Departure from",
    "walk_to": "Walk to",
    "stop": "stops",

    // QRCODE
    "qrcode.title": "Do more with the {{app}} mobile app",
    "qrcode.content": "Scan this QR Code to download the application",
    // AROUNDME
    "aroundMe.select.categorie": "Select a category",
    "phone_number": "Phone",
    "web_site": "Web site",
    "around_me_search": "Search",
    "restaurants": "Restaurants",
    "bars": "Bars",
    "hotels": "Hotels",
    "banks": "Banks",
    "parking": "Parking",
    "other_place": "Other place",
    "pharmacy": "Pharmacy",
    "bakery": "Bakery",
    "shopping": "Shops",
    "meeting_room": "Meeting room",
    "restaurants_sing": "Restaurant",
    "bars_sing": "Bar",
    "hotels_sing": "Hotel",
    "banks_sing": "Bank",
    "parking_sing": "Parking",
    "other_place_sing": "Other place",
    "pharmacy_sing": "Pharmacy",
    "bakery_sing": "Bakery",
    "shopping_sing": "Shops",
    "meeting_room_sing": "Meeting room",
    "outstanding_locations": "Outstanding locations",
    "bars_restaurants": "Bars & Restaurants",
    "walks": "Walks",
    "services": "Services",

    // INSTANT MESSAGING
    "MESSAGE_STATUS__YESTERDAY": "Yesterday",
    "CHANNEL__MESSAGE_LIST__NOTIFICATION__NEW_MESSAGE": "new message(s) since",
    "CHANNEL__MESSAGE_LIST__NOTIFICATION__ON": "on",
    "CHANNEL_PREVIEW_MOBILE_LEAVE": "Leave channel",
    "CHANNEL_SETTING__HEADER__TITLE": "Channel information",
    "CHANNEL_SETTING__PROFILE__EDIT": "Edit",
    "CHANNEL_SETTING__MEMBERS__TITLE": "Members",
    "CHANNEL_SETTING__MEMBER__TITLE": "Member",
    "CHANNEL_SETTING__MEMBER__REMOVE": "Remove from channel",
    "CHANNEL_SETTING__MEMBERS__SEE_ALL_MEMBERS": "All members",
    "CHANNEL_SETTING__MEMBERS__INVITE_MEMBER": "Invite users",
    "CHANNEL_SETTING__MEMBERS__YOU": "(You)",
    "CHANNEL_SETTING__MEMBERS__SELECT_TITLE": "Select members",
    "CHANNEL_SETTING__MEMBERS__OPERATOR": "Admin",
    "CHANNEL_SETTING__LEAVE_CHANNEL__TITLE": "Leave channel",
    "CHANNEL_SETTING__OPERATORS__TITLE": "Operators",
    "CHANNEL_SETTING__OPERATORS__TITLE_ALL": "All operators",
    "CHANNEL_SETTING__OPERATORS__TITLE_ADD": "Add operator",
    "CHANNEL_SETTING__OPERATORS__ADD_BUTTON": "Add",
    "CHANNEL_SETTING__MUTED_MEMBERS__TITLE": "Muted members",
    "CHANNEL_SETTING__MUTED_MEMBERS__TITLE_ALL": "All muted members",
    "CHANNEL_SETTING__NO_UNMUTED": "No muted members yet",
    "CHANNEL_SETTING__BANNED_MEMBERS__TITLE": "Banned users",
    "CHANNEL_SETTING__FREEZE_CHANNEL": "Freeze Channel",
    "CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR": "Register as operator",
    "CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR": "Unregister operator",
    "CHANNEL_SETTING__MODERATION__MUTE": "Mute",
    "CHANNEL_SETTING__MODERATION__UNMUTE": "Unmute",
    "CHANNEL_SETTING__MODERATION__BAN": "Ban",
    "CHANNEL_SETTING__MODERATION__UNBAN": "Unban",
    "CHANNEL_SETTING__MODERATION__EMPTY_BAN": "No banned members yet",
    "CHANNEL_SETTING__MODERATION__ALL_BAN": "All banned members",
    "OPEN_CHANNEL_CONVERSATION__TITLE_PARTICIPANTS": "participants",
    "OPEN_CHANNEL_CONVERSATION__SELECT_PARTICIPANTS": "Select participants",
    "OPEN_CHANNEL_LIST__TITLE": "Channels",
    "CREATE_OPEN_CHANNEL_LIST__TITLE": "New channel profile",
    "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION": "Channel image",
    "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_UPLOAD": "Upload",
    "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_SECTION": "Channel name",
    "CREATE_OPEN_CHANNEL_LIST__SUBTITLE__TEXT_PLACE_HOLDER": "Enter channel name",
    "CREATE_OPEN_CHANNEL_LIST__SUBMIT": "Create",
    "OPEN_CHANNEL_SETTINGS__OPERATOR_TITLE": "Channel Information",
    "OPEN_CHANNEL_SETTINGS__OPERATOR_URL": "URL",
    "OPEN_CHANNEL_SETTINGS__PARTICIPANTS_ACCORDION_TITLE": "Participants",
    "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_PANEL": "Delete channel",
    "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_TITLE": "Delete channel?",
    "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_CONTEXT": "Once deleted, this channel can't be restored.",
    "OPEN_CHANNEL_SETTINGS__DELETE_CHANNEL_SUBMIT": "Delete",
    "OPEN_CHANNEL_SETTINGS__OPERATORS_TITLE": "Admin",
    "OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ADD": "Add operator",
    "OPEN_CHANNEL_SETTINGS__OPERATORS__TITLE_ALL": "All operators",
    "OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE": "Muted participants",
    "OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__TITLE_ALL": "All muted participants",
    "OPEN_CHANNEL_SETTINGS__MUTED_MEMBERS__NO_ONE": "No muted participants yet",
    "OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE": "Banned users",
    "OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__TITLE_ALL": "All banned users",
    "OPEN_CHANNEL_SETTINGS__BANNED_MEMBERS__NO_ONE": "No banned users yet",
    "OPEN_CHANNEL_SETTINGS__MEMBERS__YOU": "(You)",
    "OPEN_CHANNEL_SETTINGS__MEMBERS__OPERATOR": "Admin",
    "OPEN_CHANNEL_SETTINGS__PARTICIPANTS_TITLE": "Participants",
    "OPEN_CHANNEL_SETTINGS__EMPTY_LIST": "No participants yet",
    "OPEN_CHANNEL_SETTINGS__SEE_ALL": "See all participants",
    "OPEN_CHANNEL_SETTINGS__ALL_PARTICIPANTS_TITLE": "All participants",
    "OPEN_CHANNEL_SETTINGS__NO_TITLE": "(No title)",
    "OPEN_CHANNEL_SETTING__MODERATION__REGISTER_AS_OPERATOR": "Register as operator",
    "OPEN_CHANNEL_SETTING__MODERATION__UNREGISTER_OPERATOR": "Unregister operator",
    "OPEN_CHANNEL_SETTING__MODERATION__MUTE": "Mute",
    "OPEN_CHANNEL_SETTING__MODERATION__UNMUTE": "Unmute",
    "OPEN_CHANNEL_SETTING__MODERATION__BAN": "Ban",
    "OPEN_CHANNEL_SETTING__MODERATION__UNBAN": "Unban",
    "TRYING_TO_CONNECT": "Trying to connect…",
    "TYPING_INDICATOR__IS_TYPING": "is typing...",
    "TYPING_INDICATOR__AND": "and",
    "TYPING_INDICATOR__ARE_TYPING": "are typing...",
    "TYPING_INDICATOR__MULTIPLE_TYPING": "Several people are typing...",
    "CHANNEL_FROZEN": "Channel frozen",
    "PLACE_HOLDER__NO_CHANNEL": "No channels",
    "PLACE_HOLDER__WRONG": "Something went wrong",
    "PLACE_HOLDER__RETRY_TO_CONNECT": "Retry",
    "PLACE_HOLDER__NO_MESSAGES": "No messages",
    "TOOLTIP__AND_YOU": ", and you",
    "TOOLTIP__YOU": "you",
    "TOOLTIP__UNKNOWN_USER": "(no name)",
    "UNKNOWN__UNKNOWN_MESSAGE_TYPE": "(Unknown message type)",
    "UNKNOWN__CANNOT_READ_MESSAGE": "Cannot read this message.",
    "MESSAGE_EDITED": "(edited)",
    "MODAL__DELETE_MESSAGE__TITLE": "Delete this message?",
    "MODAL__CHANNEL_INFORMATION__TITLE": "Edit channel information",
    "MODAL__CHANNEL_INFORMATION__CHANNEL_IMAGE": "Channel image",
    "MODAL__CHANNEL_INFORMATION__UPLOAD": "Upload",
    "MODAL__CHANNEL_INFORMATION__CHANNEL_NAME": "Channel name",
    "MODAL__CHANNEL_INFORMATION__INPUT__PLACE_HOLDER": "Enter name",
    "MODAL__INVITE_MEMBER__TITLE": "Invite member",
    "MODAL__INVITE_NO_MEMBER_SELECTED": "No member selected",
    "MODAL__INVITE_SEARCH_MEMBER": "Search member",
    "MODAL__INVITE_MEMBER__SELECTED": "selected",
    "MODAL__CHOOSE_CHANNEL_TYPE__TITLE": "New channel",
    "MODAL__CHOOSE_CHANNEL_TYPE__GROUP": "Group",
    "MODAL__CHOOSE_CHANNEL_TYPE__SUPER_GROUP": "Super group",
    "MODAL__CHOOSE_CHANNEL_TYPE__BROADCAST": "Broadcast",
    "MODAL__CREATE_CHANNEL__TITLE": "New channel",
    "MODAL__CREATE_CHANNEL__GROUP": "Group",
    "MODAL__CREATE_CHANNEL__SUPER": "Super group",
    "MODAL__CREATE_CHANNEL__BROADCAST": "Broadcast",
    "MODAL__CREATE_CHANNEL__SELECTED": "selected",
    "MODAL__LEAVE_CHANNEL__TITLE": "Leave this channel?",
    "MODAL__LEAVE_CHANNEL__FOOTER": "Leave",
    "MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_MUTED": "You're muted by the operator.",
    "MODAL__VOICE_MESSAGE_INPUT_DISABLED__TITLE_FROZEN": "Channel is frozen.",
    "USER_PROFILE__MESSAGE": "Message",
    "USER_PROFILE__USER_ID": "User ID",
    "EDIT_PROFILE__TITLE": "My profile",
    "EDIT_PROFILE__IMAGE_LABEL": "Profile image",
    "EDIT_PROFILE__IMAGE_UPLOAD": "Upload",
    "EDIT_PROFILE__NICKNAME_LABEL": "Nickname",
    "EDIT_PROFILE__NICKNAME_PLACEHOLDER": "Enter your nickname",
    "EDIT_PROFILE__USERID_LABEL": "User ID",
    "EDIT_PROFILE__THEME_LABEL": "Dark theme",
    "MESSAGE_INPUT__PLACE_HOLDER": "Enter message",
    "MESSAGE_INPUT__PLACE_HOLDER__DISABLED": "Chat is unavailable in this channel",
    "MESSAGE_INPUT__PLACE_HOLDER__MUTED": "Chat is unavailable because you're muted",
    "MESSAGE_INPUT__PLACE_HOLDER__MUTED_SHORT": "You're muted",
    "MESSAGE_INPUT__QUOTE_REPLY__PLACE_HOLDER": "Reply to message",
    "BUTTON__CANCEL": "Cancel",
    "BUTTON__DELETE": "Delete",
    "BUTTON__SAVE": "Save",
    "BUTTON__CREATE": "Create",
    "BUTTON__INVITE": "Invite",
    "BUTTON__OK": "OK",
    "BADGE__OVER": "plus",
    "NO_TITLE": "No title",
    "NO_NAME": "(No name)",
    "NO_MEMBERS": "(No members)",
    "MESSAGE_MENU__COPY": "Copy",
    "MESSAGE_MENU__REPLY": "Reply",
    "MESSAGE_MENU__THREAD": "Reply in thread",
    "MESSAGE_MENU__OPEN_IN_CHANNEL": "Open in channel",
    "MESSAGE_MENU__EDIT": "Edit",
    "MESSAGE_MENU__RESEND": "Resend",
    "MESSAGE_MENU__DELETE": "Delete",
    "MESSAGE_MENU__SAVE": "Save",
    "SEARCH": "Search",
    "SEARCH_IN_CHANNEL": "Search in channel",
    "SEARCH_IN": "Search in",
    "SEARCHING": "Searching for messages...",
    "NO_SEARCHED_MESSAGE": "No results found.",
    "QUOTE_MESSAGE_INPUT__REPLY_TO": "Reply to",
    "QUOTE_MESSAGE_INPUT__FILE_TYPE_IMAGE": "Photo",
    "QUOTE_MESSAGE_INPUT__FILE_TYPE_GIF": "GIF",
    "QUOTE_MESSAGE_INPUT__FILE_TYPE__VIDEO": "Video",
    "QUOTED_MESSAGE__REPLIED_TO": "replied to",
    "QUOTED_MESSAGE__CURRENT_USER": "You",
    "QUOTED_MESSAGE__UNAVAILABLE": "Message unavailable",
    "THREAD__HEADER_TITLE": "Thread",
    "CHANNEL__THREAD_REPLY": "reply",
    "CHANNEL__THREAD_REPLIES": "replies",
    "CHANNEL__THREAD_OVER_MAX": "99+",
    "THREAD__THREAD_REPLY": "reply",
    "THREAD__THREAD_REPLIES": "replies",
    "THREAD__INPUT__REPLY_TO_THREAD": "Reply to thread",
    "THREAD__INPUT__REPLY_IN_THREAD": "Reply in thread",
    "MENTION_NAME__NO_NAME": "(No name)",
    "MENTION_COUNT__OVER_LIMIT": "You can mention up to %d times per message.",
    "UI__FILE_VIEWER__UNSUPPORT": "Unsupported message",
    "VOICE_MESSAGE": "Voice Message",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GIF": "GIF",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_PHOTO": "Photo",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VIDEO": "Video",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_AUDIO": "Audio",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_VOICE_MESSAGE": "Voice message",
    "CHANNEL_PREVIEW_LAST_MESSAGE_FILE_TYPE_GENERAL": "File",
    "instantMessaging": "Instant Messaging",
    "tokenError": "Error while receiving token",
    "CHANNEL_SETTING__MEMBER__PROFIL": "View user profile",
    "SHOW_MORE_CONTACTS": "Show more contacts",
    "FILE_TYPE_NOT_ALLOWED": "Error: File type not allowed",
    "FILE_TOO_BIG": "Error: File too big",
    "MESSAGE_UNREAD": "unread",

    // CONTACTLIST
    "contactList.inputPlaceholder": "Search for a contact in this group",
    "contactList.noResult0": "No result for this search in this group.",
    "contactList.noResult1": "Check spelling or try in another group.",
    "contactList.lists": "Groups",
    "contactList.all": "All",
    "contactList.loading": "Loading ...",
    "contactList.noData": "The user has not completed this section",
    "contactList.segmentedContact": "Contact",
    "contactList.segmentedAboutMe": "About me",
    "contactList.favorite": "Favorite",
    "contactList.onSite": "On site",
    "contactList.showMore": "Show more",
    "contactList.biography": "Biography :",
    "contactList.hobbies": "Hobbies :",
    "contactList.save": "Save",
    "contactList.addHobby": "Add a hobby",
    "contactList.bioPlaceholder": "Write a few words about yourself",
    "contactList.myProfile": "My profile",
    "contactList.unknownUser": "Unknown user",
    "contactList.newChat": "New chat",
    "contactList.favoriteAdd": "Add to favorites",
    "contactList.favoriteRemove": "Remove from favorites",

    // PARKING
    "parking.MyBookings": "My reservations",
    "parking.BookingHistory": "Reservations history",
    "parking.NewBooking": "New reservation",
    "parking.ManageMyVehicles": "Manage my vehicles",
    "parking.MyBooking": "My reservation",
    "parking.Today": "Today",
    "parking.Day": "Day",
    "parking.Am": "Morning",
    "parking.Pm": "Afternoon",
    "parking.ModifyMyBooking": "Modify my reservation",
    "parking.CancelMyBooking": "Cancel my reservation",
    "parking.NoBookingToday": "You have not made any reservations",
    "parking.MakeABookingToAccessList": "Please make a reservation to access your list.",
    "parking.NoVehicleRegistered": "You have not registered any vehicle",
    "parking.AddVehicleToMakeBooking": "Please add a vehicle to make a reservation.",
    "parking.AddVehicle": "Add a vehicle",
    "parking.NextBooking": "Reservations to come",
    "parking.PreviousBooking": "Previous reservation",
    "parking.manageVehicles": "Manage my vehicles",
    "parking.RegistredVehiclesSummary": "Registered vehicles summary",
    "parking.SelectAVehicleType": "Select a vehicle type",
    "parking.WhatTypeOfVehicle": "What type of vehicle do you want to register?",
    "parking.Car": "Car",
    "parking.Bike": "Bike",
    "parking.Moto": "Motorcycle",
    "parking.ElectricCar": "Electric car",
    "parking.Back": "Back",
    "parking.AddNameToYourVehicle": "Add a name to your vehicle",
    "parking.WhatNameOfVehicle": "What name do you want to give to your vehicle?",
    "parking.VehicleName": "Vehicle name",
    "parking.AddPlate": "Add a license plate to your vehicle",
    "parking.WhatIsYourVehiclePlate": "What is the license plate of your vehicle?",
    "parking.PlatePlaceholder": "License plate",
    "parking.All": "All",
    "parking.Date": "Date",
    "parking.Zone": "Zone",
    "parking.Duration": "Duration",
    "parking.UpdateVehicle": "Update vehicle",
    "parking.ParkingBooking": "Parking space reservation",
    "parking.SelectDate": "Select a date",
    "parking.SelectZone": "Select a zone",
    "parking.Confirmation": "Confirmation",
    "parking.SelectDateAndDurationBeforeZone": "Select a date and duration before choosing a zone",
    "parking.Continue": "Continue",
    "parking.DeleteVehicle": "Delete vehicle ?",
    "parking.Yes": "Yes",
    "parking.No": "No",
    "parking.SelectDuration": "Select duration",
    "parking.CancelBooking": "Cancel booking",
    "parking.DoYouWantToCancelBookingOn_": "Do you want to cancel the reservation on ",
    "parking.ThisActionIsIrreversible": "This action is irreversible",
    "parking.NoBookings": "You have not made any reservations for the moment.",
    "parking.NoZonesAvailable": "No zones available for this date.",
    "parking.Book": "Create a reservation",
    "parking.Finish": "Finish",
    "parking.BookingRecap": "Reservation summary",
    "parking.Modify": "Modify",
    "parking.Cancel": "Cancel",
    "parking.ModifyBooking": "Modify reservation",
    "parking.BookingDate": "Reservation date",
    "parking.Delete": "Delete",
    "parking.30LastDays": "Last 30 days",
    "parking.DoYouWantToCancelBooking": "Do you want to cancel the reservation?",
    "parking.Confirm": "Confirm",
    "parking.SelectSite": "On which site do you want to make a reservation?",
    "parking.Site": "Site",
    "parking.BookingCreatedWithSuccess": "Reservation created successfully",
    "parking.BookingUpdatedWithSuccess": "Reservation updated successfully",
    "parking.BookingCanceledWithSuccess": "Reservation canceled successfully",
    "parking.BookingError": "Error while creating the reservation",
    "parking.UpdateBookingError": "Error while updating the reservation",
    "parking.CancelBookingError": "Error while canceling the reservation",
    "parking.VehicleAddedWithSuccess": "Vehicle added successfully",
    "parking.VehicleUpdatedWithSuccess": "Vehicle updated successfully",
    "parking.VehicleDeletedWithSuccess": "Vehicle deleted successfully",
    "parking.VehicleError": "Error while adding the vehicle",
    "parking.UpdateVehicleError": "Error while updating the vehicle",
    "parking.DeleteVehicleError": "Error while deleting the vehicle",
    "parking.ThereAreBookingsOnThisPeriod": "There already is a reservation on this period",
    "parking.NoOtherZonesAvailable": "No other zones available for this date",

    // TICKETING V2
    "ticketingv2.referential": "Référential",
    "ticketingv2.save": "Save",
    "ticketingv2.referentialComment": "Navigate through the ticket tree to view and modify the types and categories of tickets available for declaration.",
    "ticketingv2.referentialManagement": "Referential management",
    "ticketingv2.referentialManagementComment": "This ticketing management portal allows you to modify the types and categories of tickets available for declaration and to assign rights to them.",
    "ticketingv2.siteSelector": "Choose a site",
    "ticketingv2.ticketTypes": "Ticket types",
    "ticketingv2.ticketTypeComment": "Modify the referential of tickets available for declaration.",
    "ticketingv2.Modify": "Modify",
    "ticketingv2.theReferential": "the referential",
    "ticketingv2.availableStatus": "Available status",
    "ticketingv2.availableStatusComment": "Modify or add available status.",
    "ticketingv2.defaultStatus": "Default status",
    "ticketingv2.customStatus": "Custom status",
    "ticketingv2.addStatus": "Add a status",
    "ticketingv2.Delete": "Delete",
    "ticketingv2.changesNotSaved": "Your changes have not been saved",
    "ticketingv2.changeRefConfirm": "Are you sure you want to change the referential ?",
    "ticketingv2.newSubCat": "New sub-category",
    "ticketingv2.newCat": "New category",
    "ticketingv2.newType": "New type",
    "ticketingv2.emptyRef": "The referential is empty",
    "ticketingv2.Type": "Type",
    "ticketingv2.Cat": "Category",
    "ticketingv2.SubCat": "Sub-category",
    "ticketingv2.subCat": "sub-category",
    "ticketingv2.subCats": "sub-categories",
    "ticketingv2.aType": "a type",
    "ticketingv2.aCat": "a category",
    "ticketingv2.aSubCat": "a sub-category",
    "ticketingv2.aStatus": "a status",
    "ticketingv2.CantDeleteRefWithTickets": "You can't delete a referential with tickets",
    "ticketingv2.Desactivate": "Desactivate",
    "ticketingv2.Activate": "Activate",
    "ticketingv2.CantUseNoCat": "This type can't be used because it has no category",
    "ticketingv2.TypeInvisible": "This type is invisible from : ",
    "ticketingv2.and": " and ",
    "ticketingv2.users": "users",
    "ticketingv2.Users": "Users",
    "ticketingv2.andIcon": " and icon",
    "ticketingv2.Name": "Name",
    "ticketingv2.ExistingForm": "Existing form on ",
    "ticketingv2.ToAddaFormOnCat": "To add a form on category « ",
    "ticketingv2.youMustDeleteForm": " », you must delete the form ",
    "ticketingv2.fromSubCat": " from the sub-category « ",
    "ticketingv2.FormExistOnParentCat": "A form already exists on the parent category « ",
    "ticketingv2.ToAddFormOnSubCat": "To add a form on the sub-category « ",
    "ticketingv2.youMustDeleteFormOnParentCat": " », you must delete the form on the parent category « ",
    "ticketingv2.FormExplanationStart": "You can create a form that will be displayed when a user creates a ticket in : ",
    "ticketingv2.FormExplanationEnd": "A form allows you to collect additional informations from the user to help you to process the ticket.",
    "ticketingv2.CreateForm": "Create a form",
    "ticketingv2.Params": "Parameters",
    "ticketingv2.Form": "Form",
    "ticketingv2.AddFormPart": "Add a form part",
    "ticketingv2.AddFormPartComment": "Add a new part of you choice to the form",
    "ticketingv2.DeleteForm": "Delete form",
    "ticketingv2.TypeName": "Type name",
    "ticketingv2.CatName": "Category name",
    "ticketingv2.SubCatName": "Sub-category name",
    "ticketingv2.StatusName": "Status name",
    "ticketingv2.Add_": "Add ",
    "ticketingv2.NameAndIcon": "Name and icon",
    "ticketingv2.Cancel": "Cancel",
    "ticketingv2.Continue": "Continue",
    "ticketingv2.AddStatus": "Add a status",
    "ticketingv2.IsClosingState": "Correspond to a closing state",
    "ticketingv2.Loading": "Loading...",
    "ticketingv2.WarningIrreversibleAction": "Warning, this action is irreversible",
    "ticketingv2.UserImportFileHere": "User will import a document here",
    "ticketingv2.UserImportPhotoHere": "User will import a photo here",
    "ticketingv2.ImportFileHere": "Import a document",
    "ticketingv2.ImportPhotoHere": "Import a image",
    "ticketingv2.FileSizeAndTypeLimitation": "Maximum file size : 100M, file formats accepted : png, jpeg, mp4, wav",
    "ticketingv2.UserTypeAnswer": "User will type an answer here",
    "ticketingv2.IsANumber": "Is a number",
    "ticketingv2.AddOption": "Add an option",
    "ticketingv2.OrderMustBe_": "The order must be made ",
    "ticketingv2._hoursBeforeDelivery_": " hours before delivery.",
    "ticketingv2.OrderCanBeCanceled_": "The order can be canceled until",
    "ticketingv2._hours_": "hours",
    "ticketingv2.WeekendsAndHolidays_": "Weekends and public holidays",
    "ticketingv2._countInDelays": "count in delays.",
    "ticketingv2.AskTime": "Ask for a time",
    "ticketingv2.IsAProduct": "Is a product",
    "ticketingv2.Price": "Price",
    "ticketingv2.SetAUnitPrice": "Set a unit price",
    "ticketingv2.AddProductCode": "Add a product code",
    "ticketingv2.AllTypes": "All types",
    "ticketingv2.FormPartCaption": "Caption",
    "ticketingv2.Currency": "Currency",
    "ticketingv2.ProductKey": "Product key or code",
    "ticketingv2.ProductName": "Product name",
    "ticketingv2.Description": "Description",
    "ticketingv2.AddDescription": "Add a description",
    "ticketingv2.AddProductName": "Add a product name",
    "ticketingv2.Gbp": "Pounds",
    "ticketingv2.are_": "are ",
    "ticketingv2.areNot_": "are not ",
    "ticketingv2.beforeDelivery": "before delivery.",
    "ticketingv2.afterOrdering": "after ordering.",
    "ticketingv2.Previous": "Previous",
    "ticketingv2.Next": "Next",
    "ticketingv2.CreateTicket": "Create ticket",
    "ticketingv2.ModifyTicket": "Modify ticket",
    "ticketingv2.Localization": "Localization",
    "ticketingv2.Categorization": "Categorization",
    "ticketingv2.MyRequestSubject": "My request subject",
    "ticketingv2.ChangeTypeResetForm": "Changing the type will reset the form",
    "ticketingv2.ChangeCatResetForm": "Changing the category will reset the form",
    "ticketingv2.ChangeSubCatResetForm": "Changing the sub-category will reset the form",
    "ticketingv2.ticketTitle": "Title of the ticket",
    "ticketingv2.AllSubCats": "All sub-categories",
    "ticketingv2.AllCats": "All categories",
    "ticketingv2.AddTitle": "Add a title",
    "ticketingv2.Asc": "Ascending",
    "ticketingv2.Desc": "Descending",
    "ticketingv2.Date": "Date",
    "ticketingv2.RequalifyStatus": "Requalify tickets on this status",
    "ticketingv2.NameAndColor": "Name and color",
    "ticketingv2.EvaluateTicketResolution": "Evaluate the resolution of the ticket",
    "ticketingv2.Back": "Back",
    "ticketingv2.ValidateEvaluation": "Validate my evaluation",
    "ticketingv2.GlobalRating": "Global rating",
    "ticketingv2.Comment": "Comment",
    "ticketingv2.TypeComment": "Type a comment",
    "ticketingv2.SetTicketStatusToCancelled": "Set the ticket status to Cancelled ?",
    "ticketingv2.Confirm": "Confirm",
    "ticketingv2.AllFilters": "All filters",
    "ticketingv2.Status": "Status",
    "ticketingv2.AllStatus": "All status",
    "ticketingv2.Priority": "Priority",
    "ticketingv2.AllPriorities": "All priorities",
    "ticketingv2.AssignedTo": "Assigned to",
    "ticketingv2.AllAgents": "All agents",
    "ticketingv2.Floor": "Floor",
    "ticketingv2.AllFloors": "All floors",
    "ticketingv2.DeleteAll": "Delete all",
    "ticketingv2.ApplyFilters": "Apply filters",
    "ticketingv2.TicketId_": "Ticket ID: ",
    "ticketingv2.NewTicket": "New ticket",
    "ticketingv2.MyTickets": "My tickets",
    "ticketingv2.MapLoading": "Map loading...",
    "ticketingv2.UsersGroup": "Users group",
    "ticketingv2.LimitUsersGroups": "Limit users groups",
    "ticketingv2.TypeUsersGroupComment": "Add one or more user groups that can create tickets of selected type. By default, all users are allowed.",
    "ticketingv2.CatUsersGroupComment": "By default, the category inherits the list of user groups defined for the type. By activating this option, you can further limit the groups concerned for this category.",
    "ticketingv2.SubCatUsersGroupComment": "By default, the sub-category inherits the list of user groups defined for the category. By activating this option, you can further limit the groups concerned for this sub-category.",
    "ticketingv2.SearchAndAddUserGroups": "Search and add a group as user",
    "ticketingv2.AgentsGroup": "Agents group",
    "ticketingv2.LimitAgentsGroups": "Limit agents groups",
    "ticketingv2.TypeAgentsGroupComment": "Add one or more agent groups that can intervene on tickets. By default, all users are allowed.",
    "ticketingv2.CatAgentsGroupComment": "By default, the category inherits the list of agent groups defined for the type. By activating this option, you can further limit the groups concerned for this category.",
    "ticketingv2.SubCatAgentsGroupComment": "By default, the sub-category inherits the list of agent groups defined for the category. By activating this option, you can further limit the groups concerned for this sub-category.",
    "ticketingv2.SearchAndAddAgentGroups": "Search and add a group as agent",
    "ticketingv2.ManagersGroup": "Managers group",
    "ticketingv2.LimitManagersGroups": "Limit managers groups",
    "ticketingv2.TypeManagersGroupComment": "Add one or more manager groups that can assign tickets to agents. By default, all users are allowed.",
    "ticketingv2.CatManagersGroupComment": "By default, the category inherits the list of manager groups defined for the type. By activating this option, you can further limit the groups concerned for this category.",
    "ticketingv2.SubCatManagersGroupComment": "By default, the sub-category inherits the list of manager groups defined for the category. By activating this option, you can further limit the groups concerned for this sub-category.",
    "ticketingv2.SearchAndAddManagerGroups": "Search and add a group as manager",
    "ticketingv2.Spaces": "Spaces",
    "ticketingv2.LimitSpaces": "Limit spaces",
    "ticketingv2.TypeSpacesComment": "Select the types of spaces concerned. By default, all spaces are concerned.",
    "ticketingv2.CatSpacesComment": "By default, the category inherits the list of spaces defined for the type. By activating this option, you can further limit the spaces concerned for this category.",
    "ticketingv2.SubCatSpacesComment": "By default, the sub-category inherits the list of spaces defined for the category. By activating this option, you can further limit the spaces concerned for this sub-category.",
    "ticketingv2.SearchAndAddSpace": "Search and add a space in limitation list",
    "ticketingv2.Pass": "Pass",
    "ticketingv2.TicketEvaluation": "Ticket evaluation",
    "ticketingv2.NoRequestSubject": "It is not possible to create a ticket on this space",
    "ticketingv2.ChooseADate": "Choose a date",
    "ticketingv2._andATime": " and a time",
    "ticketingv2.GeneralInformations": "General informations",
    "ticketingv2.TicketCategory": "Ticket category",
    "ticketingv2.Localisation": "Localisation",
    "ticketingv2.Floor_": "Floor ",
    "ticketingv2.DetailedInformations": "Detailed informations",
    "ticketingv2.CancelTicket": "Cancel the ticket",
    "ticketingv2.Evaluate": "Evaluate",
    "ticketingv2.Summary": "Summary",
    "ticketingv2.Conversation": "Conversation",
    "ticketingv2.TicketCreated": "Ticket created",
    "ticketingv2.TicketPriorityChangedFrom_": "Ticket priority changed from ",
    "ticketingv2._to_": " to ",
    "ticketingv2.TicketStatusChangedFrom_": "Ticket status changed from ",
    "ticketingv2.TicketQualificationModifiedFrom_": "Ticket qualification modified from ",
    "ticketingv2.TicketHasBeenRated_": "Ticket has been rated ",
    "ticketingv2.TicketHasBeenTransferredFrom_": "Ticket has been transferred from ",
    "ticketingv2.TicketHasBeenAssignedTo_": "Ticket has been assigned to ",
    "ticketingv2.By_": "By: ",
    "ticketingv2.You": "You",
    "ticketingv2.InternalNote": "Internal note",
    "ticketingv2.TicketIsNowClose": "Ticket is now close",
    "ticketingv2.TicketHasBeenReopenned": "Ticket has been reopenned",
    "ticketingv2.StartingHour": "From",
    "ticketingv2.EndingHour": "To",
    "ticketingv2.DeliveriesArePossibleFrom_": "Deliveries are possible from ",
    "ticketingv2.Time": "Time",
    "ticketingv2.NoDateSelected": "No date selected",
    "ticketingv2.NoTimeSelected": "No time selected",
    "ticketingv2.AddFile": "Add a file",
    "ticketingv2.AddPhoto": "Add a photo",
    "ticketingv2.Photos": "Photos",
    "ticketingv2.AddPhotoCaption": "If necessary, select up to three photos or videos to illustrate your ticket.",
    "ticketingv2.Files": "Files",
    "ticketingv2.FilesCaption": "If necessary, select up to three documents to associate with your ticket.",
    "ticketingv2.AssignedToMe": "Assigned to me",
    "ticketingv2.SelectColumnsToDisplay": "Select columns to display",
    "ticketingv2.TicketDetails": "Ticket details",
    "ticketingv2.Creation": "Creation",
    "ticketingv2.NotAssigned": "Not assigned",
    "ticketingv2.Categorisation": "Categorisation",
    "ticketingv2.Reset": "Reset",
    "ticketingv2.Apply": "Apply",
    "ticketingv2.Object": "Object",
    "ticketingv2.Requester": "Requester",
    "ticketingv2.CreatedAt": "Created at",
    "ticketingv2.NoStatus": "No status",
    "ticketingv2.NoType": "No type",
    "ticketingv2.NoCat": "No category",
    "ticketingv2.NoSubCat": "-",
    "ticketingv2.NoTitle": "No title",
    "ticketingv2.NoAgent": "No agent",
    "ticketingv2.NoLocation": "-",
    "ticketingv2.TypeAnswser": "Type an answer",
    "ticketingv2.ChangeLocation": "Change location",
    "ticketingv2.ChangeLocationWillResetForm": "Changing the location will reset the form",
    "ticketingv2.NoAnswer": "No answer",
    "ticketingv2.MarkAsResolved": "Mark as resolved",
    "ticketingv2.AddCommentSentToUser": "Add a comment for the user",
    "ticketingv2.Optionnal": "Optionnal",
    "ticketingv2.NoPriority": "Not set",
    "ticketingv2.InvalidFileType": "Invalid file type",
    "ticketingv2.FileTooLarge": "File too large",
    "ticketingv2.xxx": "xxx",
  },
  csv: trads,
  common: common,
};
