import { createSlice, createAsyncThunk as thunk } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";
import { selectedCampus } from "./UserWS";

const INITIAL_STATE = {
  report: null,
  listReport: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// POWERBI ///////////////////////////////
export const getListReport = thunk("powerBi/getListReport", async (_, { getState }) => {
  const state = getState();
  const siteId = selectedCampus(state)?.id;
  const listReport = await DWMConnector.getListReport(siteId);
  return listReport;
});

EXTRA_REDUCERS[getListReport.fulfilled] = (state, action) => {
  state.listReport = action.payload;
};

export const getReport = thunk("powerBi/getReport", async (reportId, { getState }) => {
  const state = getState();
  const siteId = selectedCampus(state)?.id;
  const report = await DWMConnector.getReport(reportId, siteId);
  return report;
});

EXTRA_REDUCERS[getReport.fulfilled] = (state, action) => {
  state.report = action.payload;
};

const powerBiSlice = createSlice({
  name: "powerBi",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default powerBiSlice.reducer;
