import React, { useState } from "react";
import { Button, Modal, Typography } from "antd";
import { useChannelSettingsContext } from "@sendbird/uikit-react/ChannelSettings/context";
import { useTranslation } from "react-i18next";
import Glyph from "../../Common/Glyph/Glyph";

const ChannelSettingsModal = ({ showEditDetailsModal, setShowEditDetailsModal }) => {
  const context = useChannelSettingsContext();
  const { t } = useTranslation();

  const [currentTitle, setCurrentTitle] = useState("");
  const [currentImg, setCurrentImg] = useState("");

  const handleEditChannel = async () => {
    const channel = context.channel;
    context.channel
      .updateChannel({
        coverImage: currentImg,
        name: currentTitle,
        data: (channel === null || channel === void 0 ? void 0 : channel.data) || "",
      })
      .then(function (groupChannel) {
        // logger.log("groupChannel", groupChannel);
        setShowEditDetailsModal(false);
      });
  };

  const handleChangeName = () => {
    setCurrentTitle(context.channel.name);
    setCurrentImg(context.channel.coverUrl);
  };

  return (
    <Modal
      open={showEditDetailsModal}
      onCancel={() => setShowEditDetailsModal(false)}
      footer={null}
    >
      <Typography.Title level={4}>{t("CHANNEL_SETTING__HEADER__TITLE")}</Typography.Title>
      {/* <Typography.Text style={{ fontSize: size.m }}>
          {t("CREATE_OPEN_CHANNEL_LIST__SUBTITLE__IMG_SECTION")}
        </Typography.Text> */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "18px",
          marginTop: "40px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px", position: "relative" }}>
          <div
            style={{
              borderRadius: "100%",
              overflow: "hidden",
              height: 100,
              width: 100,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "#bdbdbd",
              cursor: "pointer",
            }}
          >
            <img
              src={context.channel.coverUrl}
              alt="channel cover preview"
              style={{ width: "100%", aspectRatio: "auto" }}
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
                position: "absolute",
              }}
            >
              <div
                style={{
                  borderRadius: "100%",
                  position: "absolute",
                  opacity: 0.5,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "white",
                  zIndex: 10,
                }}
              />
              <Glyph
                name="add_a_photo"
                style={{ fontSize: 34, color: "white", position: "absolute", zIndex: 20 }}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            gap: "10px",
            justifyContent: "center",
          }}
        >
          <Button type="primary" style={{ width: "200px" }} onClick={handleEditChannel}>
            {t("BUTTON__SAVE")}
          </Button>
          <Button
            type="quaternary"
            style={{
              width: "200px",
            }}
            onClick={() => setShowEditDetailsModal(false)}
          >
            {t("BUTTON__CANCEL")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ChannelSettingsModal;
