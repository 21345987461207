import { Typography, Card, Layout, Row, Col, Grid } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import { useMapLayer } from "../../Common/Map/useMap";
import { TitleSource } from "../../Common/Teleporters/Title";

import SearchRoom from "./SearchRoom/SearchRoom";
import RoomMap from "./Room/RoomMap";
import Room from "./Room/Room";
import Settings from "./Settings/Settings";
import SettingsMap from "./Settings/SettingsMap";
import styles from "./Confort.module.less";
import useDesignTokens from "../../../hook/useDesignTokens";
import { ReactComponent as House } from "../../../assets/svg/house.svg";
import { isNil } from "ramda";
import Glyph from "../../Common/Glyph/Glyph";

const mapOptions = {
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 358,
  },
};

const Comfort = () => {
  useMapLayer(mapOptions);
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  const screens = Grid.useBreakpoint();

  const [isAuto, setIsAuto] = useState(false);
  const [selectedRoom, setSelectedRoom] = useState();
  const [isZone, setIsZone] = useState(false);
  const [equipment, setEquipment] = useState();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <>
      <TitleSource>{t("smart_control", { ns: "csv" })}</TitleSource>

      {process.env.REACT_APP_ID_MAP !== "-" ? (
        <Switch>
          <Route exact path="/smartControl">
            <Card
              title={<Typography.Title level={5}>{t("SelectSpace")}</Typography.Title>}
              style={{
                width: screens.xl ? "35%" : 350,
                minWidth: screens.xl ? "35%" : 350,
                height: "fit-content",
                maxHeight: "100%",
              }}
            >
              <SearchRoom />
            </Card>
          </Route>
          <Route exact path="/smartControl/:id">
            <RoomMap setIsAuto={setIsAuto} isAuto={isAuto} />
          </Route>
          <Route exact path="/smartControl/:id/settings">
            <SettingsMap setIsAuto={setIsAuto} setIsLoading={setIsLoading} isLoading={isLoading} />
          </Route>
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/smartControl">
            <Layout className={styles.dashboardContainer}>
              <Layout>
                <Card style={{ height: "700px" }}>
                  <Row gutter={[16, 16]} style={{ height: "100%" }}>
                    <Col span={11} style={{ borderRight: `2px solid ${colors.grey_60}` }}>
                      <Typography.Title level={5}>{t("SelectSpace")}</Typography.Title>
                      <SearchRoom
                        setSelectedRoom={setSelectedRoom}
                        selectedRoom={selectedRoom}
                        setIsZone={setIsZone}
                      />
                    </Col>
                    <Col span={13} style={{ padding: "0 16px" }}>
                      {isNil(selectedRoom) ? (
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <House />
                          <Typography.Text
                            type="secondary"
                            strong
                            style={{ marginTop: "16px", width: "450px" }}
                          >
                            {t("ControlEquipment")}
                          </Typography.Text>
                        </div>
                      ) : !isZone ? (
                        <>
                          <div style={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography.Title level={5}>{selectedRoom?.title}</Typography.Title>
                            <div onClick={() => setSelectedRoom()} style={{ cursor: "pointer" }}>
                              <Glyph name="close" />
                            </div>
                          </div>

                          <Room
                            selectedRoom={selectedRoom}
                            setIsAuto={setIsAuto}
                            isAuto={isAuto}
                            setIsZone={setIsZone}
                            setEquipment={setEquipment}
                          />
                        </>
                      ) : (
                        <Settings
                          setIsAuto={setIsAuto}
                          room={selectedRoom}
                          nameEquipment={equipment}
                          setIsLoading={setIsLoading}
                        />
                      )}
                    </Col>
                  </Row>
                </Card>
              </Layout>
            </Layout>
          </Route>
        </Switch>
      )}
    </>
  );
};

export default Comfort;
