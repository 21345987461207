import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { slugify } from "../../../helpers/utils";
import { setCurrentViewId } from "../../../services/redux/services/TicketingV2WS";
import { TitleSource } from "../../Common/Teleporters/Title";
import AdminMain from "./Admin/AdminMain";
import AdminReferential from "./Admin/Referential/AdminReferential";
import ManagerMain from "./Manager/ManagerMain";
import ResidentMain from "./Resident/ResidentMain";
import TicketView from "./Ticket/TicketView";

import styles from "./ticket.module.less";

const TicketingEvol = ({ view }) => {
  const { action } = useParams();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const display =
    view.type === "ticketingV2Agent"
      ? "table"
      : view.type === "ticketingV2Admin"
      ? "admin"
      : "column";

  const section = slugify(view.title);

  useEffect(() => {
    const changeViewId = async () => {
      setLoading(true);
      await dispatch(setCurrentViewId(view.id));
      setLoading(false);
    };
    changeViewId();
  }, [dispatch, view]);

  useEffect(() => {
    logger.log("CurrentViewId", view.id);
  }, [view]);

  return (
    !loading && (
      <div className={styles.ticket} style={{ height: "100%", width: "100%" }}>
        <TitleSource>{view.title}</TitleSource>

        {action === "ticket" ? (
          <TicketView section={section} />
        ) : (
          <>
            {display === "table" && <ManagerMain section={section} />}
            {display === "column" && <ResidentMain section={section} />}
            {display === "admin" &&
              (action === "referential" ? (
                <AdminReferential section={section} />
              ) : (
                <AdminMain section={section} />
              ))}
          </>
        )}
      </div>
    )
  );
};

export default TicketingEvol;
