import {
  Button,
  DatePicker,
  Form,
  Grid,
  Input,
  InputNumber,
  Modal,
  Select,
  Table,
  TimePicker,
  Typography,
} from "antd";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../../../hook/useDesignTokens";

import Glyph from "../../../../Common/Glyph/Glyph";
import styles from "./Booking.module.less";

import { intersection, isEmpty, range } from "ramda";
import { createLoadingSelector } from "../../../../../services/redux/managers/LoadingManager";
import {
  getFastbookingSlots,
  patchFilter,
} from "../../../../../services/redux/services/FastbookingWS";

import ModalDetails from "../ModalDetails";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../../services/api/Firebase/Firebase";
import SelectHours from "../../../../Common/SelectHours/SelectHours";

const { Option } = Select;
const moment = extendMoment(Moment);

const Booking = ({ onCancel, isVisible, during, setDuring }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  const screens = Grid.useBreakpoint();

  const [form] = Form.useForm();

  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const fastbookingSlots = useSelector((state) => state.fastbookingWS.fastbookingSlots);

  const [searchRoom, setSearch] = useState("");
  const [slots, setSlots] = useState([
    {
      startDate: filters?.startDate
        ? moment(filters?.startDate).toISOString()
        : moment().toISOString(),
      endDate:
        filters?.startDate && !filters?.endDate
          ? moment(filters?.startDate).add(1, "hours").toISOString()
          : filters?.endDate
          ? moment(filters?.endDate).toISOString()
          : moment().add(1, "hours").toISOString(),
    },
  ]);
  const [date, setDate] = useState(filters?.startDate ? moment(filters?.startDate) : moment());
  const [selectedRows, setSelectedRows] = useState();
  const [isDisabled, setIsDisabled] = useState(true);
  const [modalDetails, setModalDetails] = useState(false);

  const isLoading = useSelector(createLoadingSelector(["fastbooking/getFastbookingSlots"]));

  useEffect(() => {
    dispatch(
      getFastbookingSlots({
        slotStartTime: !filters?.startDate
          ? moment().toISOString()
          : moment(filters?.startDate).toISOString(),
        slotEndTime: !filters?.endDate
          ? moment().add(1, "hours").toISOString()
          : moment(filters?.endDate).toISOString(),
        day: !filters?.startDate
          ? moment().toISOString()
          : moment(filters?.startDate).toISOString(),
      }),
    );
  }, [dispatch, filters?.endDate, filters?.startDate]);

  const columns = [
    {
      title: t("Spaces"),
      dataIndex: "espace",
      width: 150,
      fixed: "left",
      onCell: (text, record) => ({
        className: "background-cellLeft",
      }),
    },
    {
      title: t("Places"),
      dataIndex: "places",
      width: 60,
      fixed: "left",
      // sorter: (a, b) => a.places - b.places,
      onCell: (text, record) => ({
        className: "background-cellLeft",
      }),
    },
    {
      title: t("Floor"),
      dataIndex: "etage",
      width: 70,
      fixed: "left",
      // sorter: (a, b) => a.etage - b.etage,
      onCell: (text, record) => ({
        className: "background-cellLeft",
      }),
    },
  ];

  const start = moment("00:00:00", "HH:mm:ss");
  const end = moment("23:59:59", "HH:mm:ss");

  columns.push({
    title: start.format("HH:mm"),
    width: 70,
    dataIndex: "hours",
    onCell: (_, index) => ({
      colSpan: 24,
      className: "cellHour",
    }),
  });

  while (start.isBefore(end)) {
    columns.push({
      title: start.add(60, "m").format("HH:mm"),
      width: 50,
      dataIndex: "hours",
      onCell: (_, index) => ({
        colSpan: 0,
        className: "cellHour",
      }),
    });
  }

  const searchResult = (fastbookingSlots || [])
    .filter((s) =>
      !isEmpty(filters?.seats) && filters?.seats
        ? s?.resources?.features?.seats >= filters?.seats
        : fastbookingSlots,
    )
    .filter((f) =>
      !isEmpty(filters?.floor) && filters?.floor
        ? f?.resources?.floorReference ===
          campus?.mapData?.floors?.find((f) => f?.id === filters?.floor)?.reference
        : fastbookingSlots,
    )
    .filter((e) =>
      !isEmpty(filters?.equipments) && filters?.equipments
        ? filters?.equipments.length ===
          intersection(filters?.equipments, e?.resources?.equipments).length
        : fastbookingSlots,
    )
    .filter((s) =>
      !isEmpty(filters?.services) && filters?.services
        ? filters?.services?.length ===
          intersection(filters?.services, s?.resources?.services).length
        : fastbookingSlots,
    )
    .filter((t) =>
      !isEmpty(filters?.types) && filters?.types
        ? filters?.types?.includes(t?.resources?.categoryId)
        : fastbookingSlots,
    )
    .filter((value) =>
      searchRoom ? new RegExp(searchRoom, "i").test(value?.resources?.title) : true,
    );

  const slotToPoucentageBlock = (slot) => {
    const day = moment(date).startOf("day");
    const start = moment(slot.startDate);
    const end = moment(slot.endDate);
    return {
      offset: (start.diff(day, "h", true) / 24) * 100,
      size: (end.diff(start, "h", true) / 24) * 100,
    };
  };

  const data = [];

  searchResult
    .sort((a, b) => (a?.resources?.title > b?.resources?.title ? 1 : -1))
    .sort((a, b) => (a?.resources?.floorReference > b?.resources?.floorReference ? -1 : 1))
    .map((room, index) =>
      data.push({
        key: index,
        espace: (
          <p
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              margin: "0",
            }}
          >
            {room?.resources?.title}
          </p>
        ),
        places: room?.resources?.features?.seats,
        etage: (
          <p
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              margin: "0",
            }}
          >
            {
              campus?.mapData?.floors.find(
                (floor) => floor.reference === room?.resources?.floorReference,
              )?.displayName
            }
          </p>
        ),
        id: room?.resources?.id,
        hours: (
          <div style={{ position: "relative", height: "40px" }}>
            {slots.map((slot, index) => {
              const pourcentageBlock = slotToPoucentageBlock(slot);
              return (
                <div
                  key={index}
                  style={{
                    width: `${pourcentageBlock.size}%`,
                    left: `${pourcentageBlock.offset}%`,
                  }}
                  className="hourSelected"
                ></div>
              );
            })}
            {room?.slotsInfos?.map((datas, index) => {
              const pourcentageBlock = slotToPoucentageBlock(datas);
              return (
                <div
                  key={index}
                  style={{
                    width: `${pourcentageBlock.size}%`,
                    left: `${pourcentageBlock.offset}%`,
                  }}
                  className={styles.slot}
                ></div>
              );
            })}
          </div>
        ),
      }),
    );

  const changeFloor = (value) => {
    dispatch(
      patchFilter({
        floor: value,
      }),
    );
  };

  const changeSeats = (value) => {
    dispatch(
      patchFilter({
        seats: value,
      }),
    );
  };

  const changeEquipements = (value) => {
    dispatch(
      patchFilter({
        equipments: value,
      }),
    );
  };

  const changeServices = (value) => {
    dispatch(
      patchFilter({
        services: value,
      }),
    );
  };

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  };

  const tableRef = useRef();

  useEffect(() => {
    if (tableRef.current) {
      const el = tableRef.current.querySelector(".hourSelected");
      el?.scrollIntoView({ block: "end", inline: "center" });
    }
  }, []);

  useEffect(() => {
    if (!!filters?.seats) {
      logEvent(analytics, "fast_booking_search_advanced_filter_capacity", {
        selected_capacity: filters?.seats,
      });
    }
  }, [filters?.seats]);

  useEffect(() => {
    if (!!filters?.startDate) {
      logEvent(analytics, "fast_booking_search_advanced_filter_time");
    }
  }, [filters?.startDate]);

  useEffect(() => {
    if (!!filters?.equipments) {
      logEvent(analytics, "fast_booking_search_advanced_filter_devices", {
        equipment_type: filters?.equipments
          ?.map((equipment) => {
            return campus?.mapData?.equipments.find((e) => e.id === equipment)?.title;
          })
          .join(","),
      });
    }
  }, [campus?.mapData?.equipments, filters?.equipments]);

  useEffect(() => {
    if (!!filters?.services) {
      logEvent(analytics, "fast_booking_search_advanced_filter_services", {
        service_type: filters?.services
          ?.map((service) => {
            return campus?.mapData?.services.find((e) => e.id === service)?.title;
          })
          .join(","),
      });
    }
  }, [campus?.mapData?.services, filters?.services]);

  useEffect(() => {
    if (!!filters?.floor) {
      logEvent(analytics, "change_floor");
    }
  }, [filters?.floor]);

  if (!isVisible) return null;
  return (
    <>
      <Modal
        destroyOnClose
        title={t("AvancedSearch")}
        width={1380}
        open={isVisible}
        onCancel={() => {
          onCancel();
          setIsDisabled(true);
          form.resetFields();
        }}
        footer={[
          <Button
            style={{ width: "200px" }}
            type="default"
            onClick={() => {
              onCancel();
            }}
          >
            {t("Cancel")}
          </Button>,
          <Button
            style={{ width: "200px" }}
            type="primary"
            disabled={isDisabled}
            onClick={() => {
              setModalDetails(true);
              // onCancel();
            }}
          >
            {t("SelectTheSpace")}
          </Button>,
        ]}
        className={styles["modal"]}
        style={{ height: "fit-content" }}
        bodyStyle={{ padding: "35px 35px 0 35px" }}
      >
        <Form
          form={form}
          initialValues={{
            startDate: filters?.startDate ? moment(filters?.startDate) : moment(),
            endDate:
              filters?.startDate && !filters?.endDate
                ? moment(filters?.startDate).add(during || 60, "minutes")
                : filters?.endDate
                ? moment(filters?.endDate)
                : moment().add(1, "hours"),
          }}
          onValuesChange={(changedValues) => {
            if (changedValues.startDate) {
              setDate(changedValues.startDate);
              form.setFieldsValue({ endDate: moment(changedValues.startDate).add(1, "hours") });
              setSlots([
                {
                  startDate: moment(changedValues.startDate).toISOString(),
                  endDate: moment(changedValues.startDate).add(1, "hours").toISOString(),
                },
              ]);
              dispatch(
                patchFilter({
                  startDate: moment(changedValues.startDate).toISOString(),
                  endDate: moment(changedValues.startDate).add(1, "hours").toISOString(),
                }),
              );
            }
            if (changedValues.endDate) {
              setSlots([
                {
                  startDate: moment(form.getFieldValue("startDate")).toISOString(),
                  endDate: moment(changedValues.endDate).toISOString(),
                },
              ]);
              dispatch(
                patchFilter({
                  startDate: moment(form.getFieldValue("startDate")).toISOString(),
                  endDate: moment(changedValues.endDate).toISOString(),
                }),
              );
            }
          }}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
            gap: 8,
            marginBottom: 24,
          }}
        >
          <div
            className="WhiteCardWithShadow"
            style={{
              display: "flex",
              //gap: 32,
              alignItems: "center",
              justifyContent: "space-around",
              gridColumn: "1 / 5",
            }}
          >
            <Typography.Text style={{ wordBreak: "normal" }}>{t("Event_from")}</Typography.Text>
            <Form.Item name="startDate" trigger="onSelect" style={{ margin: "0 8px 0 8px" }}>
              <DatePicker
                format="DD MMMM YYYY"
                style={{ margin: "0 0px 0 8px", width: "170px" }}
                disabledDate={(current) => current && current < moment().startOf("day")}
              />
            </Form.Item>

            <Typography.Text style={{ wordBreak: "normal" }}>{t("Of")}</Typography.Text>

            <Form.Item name="startDate" trigger="onSelect" style={{ margin: "0 8px 0 8px" }}>
              <TimePicker
                style={{ width: 85 }}
                minuteStep={15}
                showNow={false}
                disabledHours={() =>
                  moment(filters.startDate).format("DD MM YYYY") ===
                    moment().format("DD MM YYYY") && range(0, moment().hour())
                }
                disabledMinutes={(selectedHour) =>
                  moment().hour() === selectedHour &&
                  moment(filters.startDate).format("DD MM YYYY") === moment().format("DD MM YYYY")
                    ? range(0, moment().minutes())
                    : []
                }
                format="HH:mm"
              />
            </Form.Item>

            <Typography.Text style={{ wordBreak: "normal" }}>{t("At")}</Typography.Text>

            <Form.Item shouldUpdate noStyle>
              {({ getFieldValue }) => (
                <Form.Item name="endDate" trigger="onSelect" style={{ margin: "0 8px 0 8px" }}>
                  <TimePicker
                    style={{ width: 85 }}
                    minuteStep={15}
                    showNow={false}
                    disabledHours={() => range(0, moment(filters?.startDate).hour())}
                    disabledMinutes={(selectedHour) =>
                      moment(getFieldValue("startDate")).hour() === selectedHour
                        ? range(0, moment(getFieldValue("startDate")).minutes())
                        : []
                    }
                    format="HH:mm"
                  />
                </Form.Item>
              )}
            </Form.Item>

            <Input
              style={{ width: "100%" }}
              placeholder={t("SearchByName")}
              prefix={<Glyph name="search" />}
              size="middle"
              onChange={handleSearch}
              value={searchRoom}
            />
          </div>

          <div className="WhiteCardWithShadow" style={{ gridColumn: screens.xl ? "1/2" : "1/3" }}>
            <div className="WhiteCardLabel">
              <Glyph name="group" style={{ color: colors.secondary_base }} />
              <Typography.Text strong>{t("NumberPlaces")}</Typography.Text>
            </div>
            <Form.Item shouldUpdate noStyle name="seatCount">
              <InputNumber
                size={"middle"}
                onChange={changeSeats}
                min={1}
                max={100}
                defaultValue={1}
                style={{ width: 100 }}
              />
            </Form.Item>
          </div>

          <div className="WhiteCardWithShadow" style={{ gridColumn: screens.xl ? "2/3" : "3/5" }}>
            <div className="WhiteCardLabel">
              <Glyph name="filter_none" style={{ color: colors.secondary_base }} />
              <Typography.Text strong>{t("Floor")}</Typography.Text>
            </div>
            <Form.Item shouldUpdate noStyle name="floor">
              <Select
                defaultValue={filters?.floor}
                size={"middle"}
                style={{ width: "100%" }}
                onChange={changeFloor}
              >
                <Option>{t("AllFloor")}</Option>
                {campus?.mapData?.floors?.map((floor, index) => (
                  <Option key={floor?.id} value={floor?.id}>
                    {floor?.displayName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="WhiteCardWithShadow" style={{ gridColumn: screens.xl ? "3/4" : "1/3" }}>
            <div className="WhiteCardLabel">
              <Glyph name="devices" style={{ color: colors.secondary_base }} />
              <Typography.Text strong>{t("Equipements")}</Typography.Text>
            </div>
            <Form.Item shouldUpdate noStyle name="equipment">
              <Select
                defaultValue={filters?.equipments}
                mode="multiple"
                size={"middle"}
                style={{ width: "100%" }}
                onChange={changeEquipements}
                placeholder={t("None")}
                maxTagCount="responsive"
              >
                {campus?.mapData?.equipments?.map((equipment, index) => (
                  <Option key={equipment?.id} value={equipment?.id}>
                    {equipment?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="WhiteCardWithShadow" style={{ gridColumn: screens.xl ? "4/5" : "3/5" }}>
            <div className="WhiteCardLabel">
              <Glyph name="devices" style={{ color: colors.secondary_base }} />
              <Typography.Text strong>{t("Services")}</Typography.Text>
            </div>
            <Form.Item shouldUpdate noStyle name="services">
              <Select
                defaultValue={filters?.services}
                size={"middle"}
                mode="multiple"
                maxTagCount="responsive"
                style={{ width: "100%" }}
                placeholder={t("None")}
                onChange={changeServices}
              >
                {campus?.mapData?.services?.map((service, index) => (
                  <Option key={service?.id} value={service?.id}>
                    {service?.title}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
        </Form>
        <div ref={tableRef}>
          <Table
            loading={isLoading}
            size="small"
            showHeader={true}
            style={{ marginTop: "16px" }}
            rowSelection={{
              type: "radio",
              onChange: (_, value) => {
                setSelectedRows(value);
                setIsDisabled(false);
              },
            }}
            dataSource={data}
            columns={columns}
            scroll={{
              x: 2200,
              y: 330,
            }}
            pagination={{
              pageSize: 15,
              showSizeChanger: false,
            }}
          />
        </div>
      </Modal>
      {selectedRows && (
        <ModalDetails
          startDate={date}
          isVisible={modalDetails}
          onCancel={() => setModalDetails(false)}
          selectedRoom={fastbookingSlots?.find((r) => r.uid === selectedRows[0]?.id)}
          slots={slots}
          bookingClose={onCancel}
          during={during}
          setDuring={setDuring}
        />
      )}
    </>
  );
};

export default Booking;
