import { Card, Select, Typography } from "antd";
import Glyph from "../../../Common/Glyph/Glyph";
import { useTranslation } from "react-i18next";

const OrderSelector = ({ dir, setDir }) => {
  const { t } = useTranslation();
  return (
    <Card bodyStyle={{ display: "flex", alignItems: "center" }}>
      <Glyph name="schedule" className={"secondary"} />
      <Typography.Text strong style={{ color: "black", marginLeft: "10px" }}>
        {t("ticketingv2.Date")}
      </Typography.Text>
      <Select
        className=""
        bordered={false}
        size={"middle"}
        style={{
          width: "150px",
          marginTop: -8,
          marginBottom: -8,
          color: "black",
          verticalAlign: "right",
        }}
        options={[
          {
            label: t("ticketingv2.Asc"),
            value: "asc",
          },
          {
            label: t("ticketingv2.Desc"),
            value: "desc",
          },
        ]}
        onChange={(value) => setDir(value)}
        value={dir}
      />
    </Card>
  );
};

export default OrderSelector;
