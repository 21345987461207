import { Card, Divider, Spin, Switch, Typography } from "antd";
import useDesignTokens from "../../../hook/useDesignTokens";
import Glyph from "../../Common/Glyph/Glyph";
import { useEffect } from "react";
import { isNil } from "ramda";
import { getPlacesData } from "../../../services/redux/services/PlacesInfosWS";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { LoadingOutlined } from "@ant-design/icons";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import { useTranslation } from "react-i18next";

const Cards = ({ placeInfo, view, data, menu }) => {
  const { colors } = useDesignTokens();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);
  const isLoading = useSelector(createLoadingSelector(["agora/getPlacesData"]));

  const placeData = useSelector(
    (state) =>
      state.placesInfosWS[placeInfo?.extras?.find((e) => e?.type === "restaurant-balance")?.type]
        ?.placesData?.data,
  );

  useEffect(() => {
    if (isNil(placeInfo?.extras)) return;
    dispatch(
      getPlacesData({
        viewId: view?.id,
        campusId: mapData?.id,
        date: moment().toISOString(),
        extraType: menu?.type,
        extraId: data?.id,
        contentId: placeInfo?.id,
      }),
    );
  }, [data?.id, dispatch, mapData?.id, menu?.type, placeInfo?.extras, placeInfo?.id, view?.id]);

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        position: "relative",
      }}
    >
      <Spin
        spinning={isLoading}
        style={{ position: "absolute", top: 10 }}
        indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      />
      {!isLoading &&
        placeData?.map((p) => (
          <div style={{ width: "100%" }}>
            {p?.balance?.amount ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexFlow: "column",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: colors.secondary_base,
                    padding: "5px",
                    borderRadius: "100px",
                    width: "170px",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Typography.Text strong style={{ color: "white", fontSize: "36px" }}>
                    {p.balance.amount}
                    {p.balance.currencySymbol}
                  </Typography.Text>
                </div>
                <Typography.Text
                  type="secondary"
                  style={{ textTransform: "uppercase", marginTop: "8px" }}
                >
                  {t("Places.Solde.Text")}
                </Typography.Text>
                <Divider />
              </div>
            ) : (
              <>
                <Typography.Text
                  type="secondary"
                  style={{
                    display: "flex",
                    textTransform: "uppercase",
                    width: "fit-content",
                    margin: "8px auto 24px auto",
                  }}
                >
                  {t("Places.Solde.NoData")}
                </Typography.Text>
              </>
            )}

            <a target="_blank" rel="noreferrer" href={p?.badgeUrl}>
              <Card style={{ display: "flex", justifyContent: "space-between" }}>
                <div
                  style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}
                >
                  <Typography.Text strong>
                    <Glyph
                      name="credit_card"
                      style={{
                        color: colors.secondary_base,
                        marginRight: "8px",
                        fontWeight: "normal",
                      }}
                    />
                    {t("Places.Solde.Link")}
                  </Typography.Text>
                  <Glyph name="navigate_next" />
                </div>
              </Card>
            </a>
            {/* <Card style={{ margin: "8px 0 16px 0" }}>
            <div style={{ justifyContent: "space-between", display: "flex", alignItems: "center" }}>
              <Typography.Text strong>
                <Glyph
                  name="notifications"
                  style={{ color: colors.secondary_base, marginRight: "8px", fontWeight: "normal" }}
                />
                Alerte solde faible
              </Typography.Text>
              <Switch />
            </div>
          </Card>
          <Typography.Text type="secondary">
            Recevez une alerte lorsque votre solde passe en dessous de 8,00€
          </Typography.Text> */}
          </div>
        ))}
    </div>
  );
};

export default Cards;
