import { Button, Progress, Typography } from "antd";
import { useEffect, useState } from "react";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import moment from "moment";
import { useSelector } from "react-redux";
import { STEPS_BOOKING } from "./Booking";
import { useTranslation } from "react-i18next";

const AnimatedProgress = ({ status }) => {
  const [percent, setPercent] = useState(0);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    let interval;
    if (ready) {
      interval = setInterval(() => {
        setPercent(percent + 20);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [ready, percent]);

  useEffect(() => {
    if (!ready) setReady(true);
  }, [ready]);

  return (
    <Progress
      type="circle"
      percent={percent}
      style={{ display: "flex", justifyContent: "end" }}
      width={80}
      showInfo={percent >= 100}
      status={status === "error" ? "exception" : status === "error" ? "success" : "normal"}
    />
  );
};

const DetailsBooking = ({
  date,
  seats,
  zoneId,
  selectedSlot,
  status,
  onCancel,
  setStep,
  placeInfo,
}) => {
  const { colors, size } = useDesignTokens();
  const { t } = useTranslation();

  const placeData = useSelector(
    (state) =>
      state.placesInfosWS[placeInfo?.extras?.find((e) => e?.type === "restaurant-slots")?.type]
        ?.placesData?.data,
  );

  return (
    <div
      style={{
        width: "100%",
        flexFlow: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography.Text strong style={{ fontSize: size.xxxxl, margin: "24px 0" }}>
          {t("Places.Booking.Recap.Title")} …
        </Typography.Text>
        <AnimatedProgress status={status} />
      </div>

      <div
        style={{
          display: "flex",
          flexFlow: "column",
        }}
      >
        <Typography.Paragraph strong style={{ fontSize: size.l, margin: "16px 0" }}>
          <Glyph
            name="check_circle"
            style={{
              marginRight: "8px",
              fontSize: size.xxl,
              color: colors.success_light,
              verticalAlign: "-4px",
            }}
          />
          {moment(date).format("LL")}
        </Typography.Paragraph>

        <Typography.Paragraph strong style={{ fontSize: size.l, margin: "16px 0" }}>
          <Glyph
            name="check_circle"
            style={{
              marginRight: "8px",
              fontSize: size.xxl,
              color: colors.success_light,
              verticalAlign: "-4px",
            }}
          />
          {t("Places.Booking.Recap.seats", { seats: seats })}
        </Typography.Paragraph>

        <Typography.Paragraph strong style={{ fontSize: size.l, margin: "16px 0" }}>
          <Glyph
            name="check_circle"
            style={{
              marginRight: "8px",
              fontSize: size.xxl,
              color: colors.success_light,
              verticalAlign: "-4px",
            }}
          />
          {placeData?.find((d) => d.zoneId === zoneId)?.zoneName}{" "}
          {placeData
            ?.find((d) => d.zoneId === zoneId)
            ?.slots.find((s) => s.slotId === selectedSlot)
            ?.startHour.split(":")
            .slice(0, 2)
            .join(":")}{" "}
          -{" "}
          {placeData
            ?.find((d) => d.zoneId === zoneId)
            ?.slots.find((s) => s.slotId === selectedSlot)
            ?.endHour.split(":")
            .slice(0, 2)
            .join(":")}
        </Typography.Paragraph>
      </div>
      <Button
        ghost
        style={{ width: "100%", marginTop: "32px" }}
        onClick={() => {
          onCancel();
          setStep(STEPS_BOOKING.BOOKING);
        }}
      >
        {t("Cancel")}
      </Button>
    </div>
  );
};

export default DetailsBooking;
