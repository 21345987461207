import { TitleSource } from "../../Common/Teleporters/Title";
import { Typography } from "antd";
import { useTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";
import ListStop from "./ListStop";
import ListLines from "./ListLines";
import { useEffect, useState } from "react";
import Trafic from "./Trafic";
import Line from "./Line";
import Journeys from "./Journeys/Journeys";
import MapsGoogle from "../../Common/Map/GoogleMap/MapsGoogle";
import { GetStopsNearLocation } from "../../../services/redux/services/TransportWS";
import { useDispatch, useSelector } from "react-redux";

const Transport = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [stop, setStop] = useState();
  const [line, setLine] = useState();
  const [inputSearchFrom, setInputSearchFrom] = useState("");
  const [inputSearchTo, setInputSearchTo] = useState(null);

  const campus = useSelector((state) => state.clientsWS.campus);

  useEffect(() => {
    dispatch(GetStopsNearLocation({ campusId: campus?.mapData?.id }));
  }, [campus?.mapData?.id, dispatch]);

  return (
    <>
      <TitleSource>{t("transport", { ns: "csv" })}</TitleSource>
      <MapsGoogle setInputSearchTo={setInputSearchTo} stop={stop} />

      <Switch>
        <div style={{ padding: "16px 16px 0px 16px", display: "flex" }}>
          <Route exact path="/transport">
            <ListStop setStop={setStop} setLine={setLine} setInputSearchTo={setInputSearchTo} />
          </Route>
          <Route exact path="/transport/stop">
            <ListLines stop={stop} setLine={setLine} setStop={setStop} />
          </Route>
          <Route exact path="/transport/stop/trafic">
            <Trafic />
          </Route>
          <Route exact path="/transport/stop/line">
            <Line line={line} />
          </Route>
          <Route exact path="/transport/journeys">
            <Journeys
              inputSearchFrom={inputSearchFrom}
              setInputSearchFrom={setInputSearchFrom}
              inputSearchTo={inputSearchTo}
            />
          </Route>
        </div>
      </Switch>
    </>
  );
};

export default Transport;
