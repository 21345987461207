import { ReactKeycloakProvider, useKeycloak } from "@react-keycloak/web";
import { useState } from "react";
import { Route, Switch } from "react-router-dom";
import keycloak from "./keycloak";
import Home from "./views/Home/Home";
import Login from "./views/Login/Login";
import Public from "./views/Public/Public";
import { LoadScript } from "@react-google-maps/api";

const GOOGLE_MAP_API_KEY =
  process.env.REACT_APP_GOOGLE_API_KEY || process.env.REACT_APP_FIREBASE_API_KEY;

const HomeSwitch = ({ isMobile }) => {
  const { keycloak } = useKeycloak();
  return keycloak.authenticated ? <Home isMobile={isMobile} /> : <Login isMobile={isMobile} />;
};

const Routes = ({ isMobile }) => {
  const [isReady, setIsReady] = useState(false);

  const eventLogger = (event, error) => {
    keycloak.updateToken(300).catch((error) => {
      logger.log("keycloak.updateToken", error);
    });
  };

  const tokenLogger = (tokens) => {
    localStorage.setItem("auth_data_dwm", JSON.stringify(tokens));
    setIsReady(true);
  };

  return (
    <Switch>
      <Route path="/public">
        <Public />
      </Route>
      <Route path="/">
        <ReactKeycloakProvider
          authClient={keycloak}
          onEvent={eventLogger}
          onTokens={tokenLogger}
          initOptions={{
            onLoad: process.env.REACT_APP_KEYCLOAK_ONLOAD || "check-sso",
            flow: "standard",
            checkLoginIframe: false,
            redirectUri: `${process.env.REACT_APP_DWM_URL}/api/redirectAuth?appId=${process.env.REACT_APP_ID}&type=${process.env.REACT_APP_TYPE}`,
          }}
          loginOptions={{ scope: "offline_access" }}
        >
          <LoadScript googleMapsApiKey={GOOGLE_MAP_API_KEY} libraries={["places"]}>
            {isReady && <HomeSwitch isMobile={isMobile()} />}
          </LoadScript>
        </ReactKeycloakProvider>
      </Route>
    </Switch>
  );
};

export default Routes;
