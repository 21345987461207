import { Button, Card, Col, Image, message, Modal, Row, Tag, Typography } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import useDesignTokens from "../../../../hook/useDesignTokens";
import {
  getRoomFastbooking,
  setRemoveBooking,
} from "../../../../services/redux/services/FastbookingWS";
import { getPlanning } from "../../../../services/redux/services/PlanningWS";
import useMap from "../../../Common/Map/useMap";
import Glyph from "../../../Common/Glyph/Glyph";
import clsx from "clsx";
import styles from "../Fastbooking.module.less";
import { isEmpty } from "ramda";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const { Meta } = Card;

const ModalListBooking = ({ setModalBooking, modalBooking }) => {
  const history = useHistory();
  const { centerOnPlaceId } = useMap();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { colors, size } = useDesignTokens();
  const locale = i18n.language.split("-")[0];

  const campus = useSelector((state) => state.clientsWS.campus);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const planning = useSelector((state) => state.planningWS.planning);

  const bookedRooms = planning?.slots?.items
    ?.filter((f) => f.locations.length !== 0)
    ?.filter((f) => f.locations[0].siteId === campus?.mapData?.id)
    ?.filter((r) => r.isOrganizer === true);

  const confirm = (r, item) => {
    Modal.confirm({
      title: t("CancelBooking"),
      content: t("fastbooking.sureCanceled"),
      okText: <div block="true">{t("Yes")}</div>,
      cancelText: <div block="true">{t("No")}</div>,
      onOk: () => {
        dispatch(
          setRemoveBooking({
            slotId: item?.id,
            roomId: r?.id,
          }),
        )
          .unwrap()
          .then(() => {
            logEvent(analytics, "fast_booking_book_cancelled");
            message.success(t("BookCanceled"));
            history.push("/fastBookingV2");
            dispatch(
              getPlanning({
                locale: i18n.language.split("-")[0],
                startDate: moment().toISOString(),
                endDate: moment().add(7, "days").toISOString(),
              }),
            );
            dispatch(
              getRoomFastbooking({
                floor: filters.floor === "all" ? null : filters.floor,
                locale: i18n.language.split("-")[0],
                startDate: moment().toISOString(),
              }),
            );
            if (bookedRooms.length === 1) setModalBooking(false);
          })
          .catch(() => {
            message.error(t("ErrorCancelBook"));
          });
      },
    });
  };

  return (
    <Modal
      title={
        <Typography.Title level={5} style={{ margin: "0" }}>
          {t("CurrentReservation")}
        </Typography.Title>
      }
      open={modalBooking}
      onCancel={() => setModalBooking(false)}
      footer={null}
      className={clsx(styles.modal)}
      style={{ height: "fit-content" }}
    >
      <Row gutter={[8, 16]}>
        {planning?.slots &&
          bookedRooms?.map((item, index) => (
            <Col span={24} style={{ position: "relative" }} key={index}>
              {item?.resources?.map((r, index) => (
                <div key={index}>
                  <Tag
                    style={{
                      position: "absolute",
                      zIndex: "900",
                      top: "10px",
                      right: "16px",
                    }}
                    color={colors.success_light}
                  >
                    <Typography.Text strong style={{ color: "white" }}>
                      {moment(item?.startDate) > moment() ? (
                        <Typography.Text style={{ fontSize: size.m, color: "white" }}>
                          {moment(item?.startDate).isSame(moment(), "date")
                            ? t("fastbooking.Today")
                            : `${t("fastbooking.The_")}
                        ${moment(item?.startDate).format(
                          locale === "en" ? "MMMM Do YYYY" : "DD MMM YYYY",
                        )}`}
                          {t("fastbooking._from_")}
                          {moment(item?.startDate).format("HH:mm")}
                          {t("fastbooking._to_")}
                          {moment(item?.endDate).format("HH:mm")}
                        </Typography.Text>
                      ) : (
                        <Typography.Text style={{ fontSize: size.m, color: "white" }}>
                          {moment(item.endDate).diff(moment(), "minutes") < 60
                            ? `${moment
                                .duration(moment(item.endDate).diff(moment(), "minutes"), "minutes")
                                .minutes()} mins`
                            : moment()
                                .startOf("day")
                                .add(moment(item.endDate).diff(moment(), "minutes"), "minutes")
                                .format("hh[h]mm")}
                        </Typography.Text>
                      )}
                    </Typography.Text>
                  </Tag>
                  <Card
                    cover={
                      r?.photos && !isEmpty(r?.photos) ? (
                        <div className={styles["roomPictures"]}>
                          <Image preview={false} src={r?.photos[0]} />
                        </div>
                      ) : (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "150px",
                            width: "100%",
                            backgroundColor: colors.grey_40,
                            opacity: 0.3,
                          }}
                        >
                          <Glyph
                            style={{ fontSize: "60px", color: "white", opacity: 0.8 }}
                            name={
                              campus?.mapData?.categories?.find(
                                (categorie) => categorie?.id === r?.categoryId,
                              )?.icon
                            }
                          />
                        </div>
                      )
                    }
                    actions={[
                      <div style={{ display: "flex", flexFlow: "row", justifyContent: "center" }}>
                        <Button ghost style={{ width: "200px" }} onClick={() => confirm(r, item)}>
                          {t("CancelBooking")}
                        </Button>
                        <Button
                          onClick={() => {
                            centerOnPlaceId(r?.map?.findAndOrder?.placeId);
                            logEvent(analytics, "fast_booking_itinerary");
                          }}
                          type="primary"
                          style={{ width: "200px", marginLeft: "8px" }}
                        >
                          <Link
                            to={{
                              pathname: "/indoorMapping/itinerary",
                              state: {
                                back: `/fastBookingV2`,
                                selectedRoom: r,
                              },
                            }}
                            style={{ color: "white" }}
                          >
                            {t("SeeItinerary")}
                          </Link>
                        </Button>
                      </div>,
                    ]}
                  >
                    <Meta
                      title={<Typography.Title level={5}>{r?.title}</Typography.Title>}
                      description={
                        <div>
                          <Typography.Paragraph>
                            {t("Floor")}{" "}
                            {
                              campus?.mapData?.floors?.find(
                                (floor) => floor.reference === r?.floorReference,
                              )?.displayName
                            }
                          </Typography.Paragraph>
                          <Typography.Paragraph type="secondary">
                            {
                              campus?.mapData?.categories?.find(
                                (categorie) => categorie.id === r?.categoryId,
                              )?.title
                            }{" "}
                            - {r?.features?.seats} {t("place")}
                            {r?.features?.seats > 1 ? "s" : ""}
                          </Typography.Paragraph>
                        </div>
                      }
                    />
                  </Card>
                </div>
              ))}
            </Col>
          ))}
      </Row>
    </Modal>
  );
};

export default ModalListBooking;
