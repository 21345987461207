import { Result } from "antd";
import { useTranslation } from "react-i18next";
import { ReactComponent as UDError } from "../../../assets/svg/error.svg";

const NoSites = () => {
  const { t } = useTranslation();

  return (
    <div style={{ margin: "auto" }}>
      <Result
        icon={<UDError />}
        title={t("spas.request.error.sectors")}
        subTitle={t("spas.request.error.sectors.content")}
      />
    </div>
  );
};

export default NoSites;
