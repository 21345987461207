import { Card, Col, DatePicker, Form, Row, Spin, Typography } from "antd";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import { STEPS } from "./Places";
import { useDispatch, useSelector } from "react-redux";
import { getPlacesData } from "../../../services/redux/services/PlacesInfosWS";
import { useEffect, useState } from "react";
import moment from "moment";
import { isNil } from "ramda";
import { LoadingOutlined } from "@ant-design/icons";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import styles from "./Places.module.less";
import { useTranslation } from "react-i18next";

const Menu = ({ setStep, menu, view, placeInfo, data, setContentMenu }) => {
  const { colors, size } = useDesignTokens();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);
  const isLoading = useSelector(createLoadingSelector(["agora/getPlacesData"]));
  const menuDetails = useSelector(
    (state) =>
      state.placesInfosWS[placeInfo?.extras?.find((e) => e?.type === "menu")?.type]?.placesData
        ?.data,
  );

  const [day, setDay] = useState(moment());

  useEffect(() => {
    if (isNil(placeInfo?.extras)) return;
    dispatch(
      getPlacesData({
        viewId: view?.id,
        campusId: mapData?.id,
        date: moment(day).toISOString(),
        extraType: menu?.type,
        extraId: data?.id,
        contentId: placeInfo?.id,
      }),
    );
  }, [
    data?.id,
    day,
    dispatch,
    mapData?.id,
    menu?.type,
    placeInfo?.extras,
    placeInfo?.id,
    view?.id,
  ]);

  return (
    <Spin
      wrapperClassName={styles["spinner"]}
      spinning={isLoading}
      indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
    >
      <Card style={{ marginBottom: "16px" }}>
        <Row>
          <Col flex={"auto"}>
            <Typography.Text strong>{t("Places.Menu.date")}</Typography.Text>
          </Col>
          <Col>
            <DatePicker
              style={{ margin: -8, fontSize: size.s }}
              format="DD MMM YYYY"
              defaultValue={day}
              onChange={(values) => setDay(values)}
              disabledDate={(date) =>
                date.isBefore(moment().startOf("week"), "date") ||
                date.isAfter(moment().endOf("week"), "date")
              }
            />
          </Col>
        </Row>
      </Card>
      {menuDetails?.map((menuDetail) => (
        <>
          <Typography.Text strong>{menuDetail?.category}</Typography.Text>
          {menuDetail?.content?.map((c) => (
            <Card
              style={{ marginTop: "8px", marginBottom: "26px" }}
              bodyStyle={{ display: "flex", justifyContent: "space-between", flexFlow: "row" }}
            >
              <div style={{ display: "flex", flexFlow: "column", width: "100%" }}>
                <Typography.Text strong>{c?.title}</Typography.Text>
                <Typography.Text>{c?.subtitle}</Typography.Text>
              </div>
              <div
                onClick={() => {
                  setStep(STEPS.DETAILSMENU);
                  setContentMenu(c?.content);
                }}
              >
                <Glyph name="info" style={{ color: colors.grey_40, cursor: "pointer" }} />
              </div>
            </Card>
          ))}
        </>
      ))}
    </Spin>
  );
};

export default Menu;
