import axios from "axios";
import moment from "moment";
import { isNil, reject } from "ramda";
import keycloak from "../../../keycloak";

const errorData = (err) => ({
  status: err?.response?.status,
  code: err?.response?.status + "",
  message: err.message,
  data: err?.response?.data,
  stack: err.stack,
});

const controllerStore = {};

const withAbort = (id) => {
  controllerStore[id]?.abort();
  controllerStore[id] = new AbortController();
  return controllerStore[id];
};

class SpasConnector {
  constructor() {
    this.baseURL = process.env.REACT_APP_DWM_URL.replace(/\/$/, "");
    this.appId = +process.env.REACT_APP_ID;
    this.viewId = null;

    this.AXIOS = axios.create({
      baseURL: `${this.baseURL}/api`,
    });

    // Add interceptors
    this.AXIOS.interceptors.request.use(async (config) => {
      if (config.unique) config.signal = withAbort(config.url).signal;

      const viewId = await this.getViewId();
      if (viewId) {
        config.params = config.params || {};
        config.params["viewId"] = viewId;
      }
      if (keycloak.token) config.headers["Authorization"] = `Bearer ${keycloak.token}`;
      return config;
    });
  }

  async getViewId() {
    if (this.viewId) return this.viewId;
    logger.log("SpasConnector.getViewId");
    const store = (await import("../../redux/configureStore"))?.default;
    const state = store?.getState() || {};
    const viewId = state.userWS?.userData?.views?.find((i) => i.type === "spaceBooking")?.id;
    this.viewId = viewId;
    return viewId;
  }

  /**
  |--------------------------------------------------
  | SPACE AS A SERVICE
  |--------------------------------------------------
  */

  async listSpaasSlots({ startDate, endDate, forUserId }) {
    try {
      const res = await this.AXIOS.get(`/mobile/apps/${this.appId}/spacebooking/slots`, {
        params: reject(isNil, { startDate, endDate, forUserId }),
        unique: true,
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createSpaasRequest({
    type,
    siteId,
    sectorId,
    dates,
    startDate,
    endDate,
    recurrence = "NONE",
    period,
    comment,
    forUserId,
    spaceId,
  }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/requests`,
        {
          type,
          dates: dates ? dates.map((date) => moment(date).format("YYYY-MM-DD")) : undefined,
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : undefined,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : undefined,
          recurrence,
          period,
          comment,
          forUserId,
          spaceId,
        },
        { params: { siteId, sectorId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createTeamRequest({
    siteId,
    sectorId,
    type,
    startDate,
    endDate,
    recurrence,
    dates,
    period,
    comment,
    roomIds,
    forUserIds,
  }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/teamRequests`,
        reject(isNil, {
          type,
          startDate,
          endDate,
          recurrence,
          dates,
          period,
          comment,
          roomIds,
          forUserIds,
        }),
        { params: { siteId, sectorId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async cancelSpaasSlot(id, { dateOnly, comment }) {
    try {
      const res = await this.AXIOS.delete(`/mobile/apps/${this.appId}/spacebooking/slots/${id}`, {
        data: { dateOnly, comment },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getSpasTeam({ siteId }) {
    try {
      const res = await this.AXIOS.get(`/mobile/apps/${this.appId}/spacebooking/manager/team`, {
        params: { siteId },
        unique: true,
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async addSpasTeam({ siteId, userId }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/manager/team/${userId}`,
        undefined,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async removeSpasTeam({ siteId, userId }) {
    try {
      const res = await this.AXIOS.delete(
        `/mobile/apps/${this.appId}/spacebooking/manager/team/${userId}`,
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async listFollowed() {
    try {
      const res = await this.AXIOS.get(`/mobile/apps/${this.appId}/spacebooking/followed/list`, {
        unique: true,
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async sendFollow({ userId }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/followed/requests/${userId}`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async listPresence({ siteId, date }) {
    try {
      const res = await this.AXIOS.get(
        `/mobile/apps/${this.appId}/spacebooking/followed/presence`,
        { params: { siteId, date }, unique: true },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async revokeFollow({ followId }) {
    try {
      const res = await this.AXIOS.delete(
        `/mobile/apps/${this.appId}/spacebooking/followed/${followId}`,
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async answerFollow({ followId, accepted }) {
    try {
      const res = await this.AXIOS.put(
        `/mobile/apps/${this.appId}/spacebooking/followed/${followId}`,
        { accepted },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async deskCount({ siteId, date }) {
    try {
      const res = await this.AXIOS.get(
        `/mobile/apps/${this.appId}/spacebooking/availability/count`,
        { params: { siteId, date }, unique: true },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async listDeskAvailability({ siteId, sectorId, period, dates, startDate, endDate, recurrence }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/availability/listWorkplacesForDates`,
        reject(isNil, {
          sectorId,
          period,
          dates,
          startDate,
          endDate,
          recurrence,
        }),
        { params: { siteId }, unique: true },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async confirmPresence({ slotId, siteId, code }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/slots/${slotId}/confirmPresence`,
        { code },
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getTeleworkingLimit({ date }) {
    try {
      const res = await this.AXIOS.get(
        `/mobile/apps/${this.appId}/spacebooking/requests/getTeleworkingLimit`,
        { params: { date }, unique: true },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getTeamDayList({ siteId, page, size, search, date, type, period }) {
    try {
      const res = await this.AXIOS.get(
        `/mobile/apps/${this.appId}/spacebooking/slots/team/dayList`,
        { params: reject(isNil, { siteId, page, size, search, date, type, period }), unique: true },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getTeamDayCounts({ type, startDate, endDate }) {
    try {
      const res = await this.AXIOS.get(`/mobile/apps/${this.appId}/spacebooking/slots/team/count`, {
        params: reject(isNil, { type, startDate, endDate }),
        unique: true,
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getTeamMapList({ siteId, date, floor, search, period }) {
    try {
      const res = await this.AXIOS.get(
        `/mobile/apps/${this.appId}/spacebooking/slots/team/mapList`,
        { params: reject(isNil, { siteId, date, floor, search, period }), unique: true },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getFollowedDayList({ date }) {
    try {
      const res = await this.AXIOS.get(
        `/mobile/apps/${this.appId}/spacebooking/slots/followed/list`,
        {
          params: reject(isNil, { date }),
          unique: true,
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getFollowedDayCounts({ type, startDate, endDate }) {
    try {
      const res = await this.AXIOS.get(
        `/mobile/apps/${this.appId}/spacebooking/slots/followed/count`,
        {
          params: reject(isNil, { type, startDate, endDate }),
          unique: true,
        },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async listTeamAvailability({
    siteId,
    sectorId,
    page,
    size,
    search,
    period,
    dates,
    startDate,
    endDate,
    recurrence,
  }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/slots/team/availability`,
        reject(isNil, { sectorId, search, period, dates, startDate, endDate, recurrence }),
        { params: { siteId, page, size }, unique: true },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  /////////////////////////////////////////
  // SPACE SERVICE ADMIN [LEGACY]
  /////////////////////////////////////////

  async listSpaceServiceRequests({
    siteId,
    sectorId,
    date = new Date().toISOString(),
    page = 1,
    size = 10,
    status,
    search,
    sortDir,
    sortField,
  }) {
    try {
      const res = await this.AXIOS.get(`/mobile/apps/${this.appId}/spacebooking/admin/slots`, {
        params: { siteId, sectorId, date, page, size, status, search, sortDir, sortField },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async searchSpaceServiceSpace({ siteId, sectorId, search, period, date }) {
    try {
      const res = await this.AXIOS.get(`/mobile/apps/${this.appId}/spacebooking/admin/rooms`, {
        params: { siteId, sectorId, search, period, date },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async checkSpaceServiceConflicts({
    siteId,
    sectorId,
    spaceId,
    startDate,
    endDate,
    period,
    recurrence,
  }) {
    try {
      const res = await this.AXIOS.get(`/mobile/apps/${this.appId}/spacebooking/admin/conflicts`, {
        params: {
          siteId,
          sectorId,
          spaceId,
          startDate,
          endDate,
          period,
          recurrence,
        },
      });
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async assignSpaceServiceSpace(
    slotId,
    { siteId, sectorId, spaceId, comment, allOccurences, forceAssign },
  ) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/admin/slots/${slotId}/assign`,
        { slotId, spaceId, comment, allOccurences, forceAssign },
        { params: { siteId, sectorId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async assignSpaceServiceSpaceAuto({ siteId, slotIds, roomIds, endDate, comment, allOccurences }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/admin/slots/auto`,
        { slotIds, roomIds, endDate, comment, allOccurences },
        { params: { siteId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createSpaceServiceRequest({
    siteId,
    sectorId,
    startDate,
    endDate,
    period,
    recurrence,
    forUserId,
    spaceId,
    comment,
    allOccurences,
    forceAssign,
  }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/admin/requests`,
        {
          startDate,
          endDate,
          period,
          recurrence,
          forUserId,
          spaceId,
          comment,
          allOccurences,
          forceAssign,
        },
        { params: { siteId, sectorId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async createSpaceServiceRequestAuto({
    siteId,
    sectorId,
    startDate,
    endDate,
    forceEndDate,
    period,
    recurrence,
    comment,
    allOccurences,
    forUserIds,
    roomIds,
  }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/admin/requests/auto`,
        {
          startDate,
          endDate,
          forceEndDate,
          period,
          recurrence,
          comment,
          allOccurences,
          forUserIds,
          roomIds,
        },
        { params: { siteId, sectorId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async declineSpasSlots({ siteId, sectorId, slotIds, comment, allOccurences }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/admin/slots/decline`,
        { slotIds, comment, allOccurences },
        { params: { siteId, sectorId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async pendingSpasSlots({ siteId, sectorId, slotIds, comment, allOccurences }) {
    try {
      const res = await this.AXIOS.post(
        `/mobile/apps/${this.appId}/spacebooking/admin/slots/pending`,
        { slotIds, comment, allOccurences },
        { params: { siteId, sectorId } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }

  async getSpaceServiceOccupancyRates({ siteId, sectorId, startDate, endDate }) {
    try {
      const res = await this.AXIOS.get(
        `/mobile/apps/${this.appId}/spacebooking/admin/occupancyRates`,
        { params: { siteId, sectorId, startDate, endDate } },
      );
      return res.data;
    } catch (error) {
      logger.error(error);
      throw errorData(error);
    }
  }
}

export default new SpasConnector();
