import { Spin, Typography } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TitleSource } from "../../Common/Teleporters/Title";
import SendbirdProvider from "@sendbird/uikit-react/SendbirdProvider";
import { getSendbirdAccessToken } from "../../../services/redux/services/InstantMessagingWS";
import { useTranslation } from "react-i18next";
import { Translations } from "./misc/Translations";
import ImApp from "./ImApp";
import { LoadingOutlined } from "@ant-design/icons";
import fr from "date-fns/locale/fr";
import en from "date-fns/locale/en-US";
import { ChannelListProvider } from "@sendbird/uikit-react/ChannelList/context";
import "@sendbird/uikit-react/dist/index.css";
import styles from "./misc/ImApp.module.less";

const InstantMessaging = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const sendbirdModalRootRef = useRef(null);

  const userData = useSelector((state) => state.userWS.userData);
  const accessToken = useSelector((state) => state.instantMessagingWS.accessToken);
  const [isLoading, setIsLoading] = useState(true);
  const [query, setQuery] = useState({
    channelListQuery: {
      includeEmpty: true,
      channelNameContainsFilter: "",
      limit: 20,
    },
  });
  const stringSet = Translations(t, `${userData.profile?.firstName} ${userData.profile?.lastName}`);

  useEffect(() => {
    const fetchAccessToken = async () => {
      await dispatch(getSendbirdAccessToken());
      setIsLoading(false);
    };
    fetchAccessToken();
  }, [dispatch, userData]);

  useEffect(() => {
    const sendbirdModalRoot = document.getElementById("sendbird-modal-root");
    if (sendbirdModalRoot) {
      sendbirdModalRootRef.current = sendbirdModalRoot;
      sendbirdModalRoot.style.zIndex = 1000;
    }
  }, [isLoading]);

  return (
    <>
      <TitleSource>{t("instantMessaging")}</TitleSource>
      {!isLoading ? (
        accessToken.token ? (
          <div className={styles.ImApp} style={{ width: "100%", height: "100%" }}>
            <SendbirdProvider
              appId={process.env.REACT_APP_SENDBIRD_APP_ID}
              userId={userData.profile.id.toString()}
              accessToken={accessToken.token}
              stringSet={{
                ...stringSet,
                DATE_FORMAT__MESSAGE_LIST__NOTIFICATION__UNREAD_SINCE:
                  i18n.language.split("-")[0] === "fr" ? "p 'le' dd MMM" : "p 'on' MMM dd",
                DATE_FORMAT__MESSAGE_LIST__DATE_SEPARATOR:
                  i18n.language.split("-")[0] === "fr" ? "dd MMM yyyy" : "MMM dd, yyyy",
                DATE_FORMAT__THREAD_LIST__DATE_SEPARATOR:
                  i18n.language.split("-")[0] === "fr" ? "dd MMM yyyy" : "MMM dd, yyyy",
              }}
              dateLocale={i18n.language.split("-")[0] === "fr" ? fr : en}
            >
              <ChannelListProvider queries={query} className="fullwidth">
                <ImApp setQuery={setQuery} />
              </ChannelListProvider>
            </SendbirdProvider>
          </div>
        ) : (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography.Title level={3}>{t("tokenError")}</Typography.Title>
          </div>
        )
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
            <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
            <br />
            <Typography.Title level={3}>{t("Loading")}...</Typography.Title>
          </div>
        </div>
      )}
    </>
  );
};

export default InstantMessaging;
