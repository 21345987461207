import { Button, Modal, Progress, Typography, Upload, message } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Glyph from "../../../../Common/Glyph/Glyph";
import { useDispatch, useSelector } from "react-redux";
import { addImagesContentNews } from "../../../../../services/redux/services/NewsWS";
import styles from "../../News.module.less";

let time;

const AddPicture = ({ isPicture, onCancel, reactQuillRef, cursorPos }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [url, setUrl] = useState();
  const [disabled, setDisabled] = useState(true);
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);

  const mapData = useSelector((state) => state.clientsWS.campus.mapData);

  useEffect(() => {
    let interval;

    if (isLoading) {
      interval = setInterval(() => {
        setProgress(progress + 20);
      }, 1000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [isLoading, progress]);

  const handleImageUpload = (fileList) => {
    const file = fileList[0].originFileObj;
    const img = new Image();
    img.src = URL.createObjectURL(file);
    img.onload = async () => {
      const canvas = document.createElement("canvas");
      const MAX_WIDTH = 1000; // Définissez la largeur maximale souhaitée pour l'image compressée
      const MAX_HEIGHT = 1000; // Définissez la hauteur maximale souhaitée pour l'image compressée

      let width = img.width;
      let height = img.height;

      // Réduisez les dimensions de l'image tout en conservant le rapport largeur/hauteur
      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
        }
      }

      // Définissez les dimensions du canvas
      canvas.width = width;
      canvas.height = height;

      // Dessinez l'image sur le canvas avec les nouvelles dimensions
      const ctx = canvas.getContext("2d");
      ctx.drawImage(img, 0, 0, width, height);

      // Obtenez l'image compressée au format JPEG (vous pouvez également utiliser PNG si nécessaire)
      const compressedImage = canvas.toDataURL("image/png");

      const response = await fetch(compressedImage);
      const blob = await response.blob();
      time = setTimeout(() => {
        dispatch(addImagesContentNews({ image: blob, fileName: file.name, siteId: mapData?.id }))
          .unwrap()
          .then((data) => {
            setDisabled(false);
            message.success(t("news.editor.success"));
            setUrl(`<Image width="100%" src="${data.uri}" />`);
            setIsLoading(false);
            setStatus("success");
            setProgress(0);
          })
          .catch(() => {
            message.error(t("news.editor.library.import.error"));
            setStatus("error");
            setProgress(0);
          });
      }, 6000);
    };
  };

  const props = {
    listType: "picture-card",
    accept: "image/*",
    maxCount: "1",
    beforeUpload: () => {
      return false;
    },
    onChange: ({ fileList }) => {
      setIsLoading(true);
      handleImageUpload(fileList);
    },
    showUploadList: {
      showPreviewIcon: false,
      showRemoveIcon: false,
    },
    className: styles["custom-upload"],
  };

  return (
    <Modal
      destroyOnClose
      width={600}
      title={
        <Typography.Text
          strong
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          {t("news.editor.pictures.title")}
        </Typography.Text>
      }
      bodyStyle={{ position: "relative" }}
      open={isPicture}
      onCancel={onCancel}
      footer={[
        <Button
          ghost
          style={{ width: "170px" }}
          onClick={() => {
            onCancel();
            clearTimeout(time);
            setIsLoading(false);
            setProgress(0);
            setStatus(null);
          }}
        >
          {t("Cancel")}
        </Button>,
        <Button
          disabled={disabled}
          type="primary"
          style={{ width: "170px" }}
          onClick={() => {
            const parser = new DOMParser();
            const el = parser.parseFromString(url, "text/html").getElementsByTagName("img")[0];
            reactQuillRef.current
              .getEditor()
              .clipboard.dangerouslyPasteHTML(cursorPos.index, el.outerHTML);
            onCancel();
            setDisabled(true);
          }}
        >
          {t("Next")}
        </Button>,
      ]}
    >
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Upload {...props}>
          <div>
            <Glyph name="publish" />
            <div
              style={{
                marginTop: 8,
              }}
            >
              {t("news.editor.library.import")}
            </div>
          </div>
        </Upload>
      </div>
      {isLoading && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            padding: "15px",
            backgroundColor: "white",
          }}
        >
          <Progress
            style={{
              display: "flex",
              height: "100%",
              alignItems: "center",
            }}
            percent={progress}
            status={status === "error" ? "exception" : status === "success" ? "success" : "normal"}
          />
        </div>
      )}
    </Modal>
  );
};

export default AddPicture;
