import { Button, Card, DatePicker, Form, Grid, Modal, Select, Typography } from "antd";
import moment from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import useDesignTokens from "../../../../hook/useDesignTokens";
import { patchFilter } from "../../../../services/redux/services/FastbookingWS";
import { createLoadingSelector } from "../../../../services/redux/managers/LoadingManager";
import Glyph from "../../../Common/Glyph/Glyph";
import SelectHours from "../../../Common/SelectHours/SelectHours";
import styles from "../Fastbooking.module.less";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const { Option } = Select;

const CardFilter = ({ setVisible, openNotification }) => {
  const { t, i18n } = useTranslation();
  const { colors, size } = useDesignTokens();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const screens = Grid.useBreakpoint();
  const locale = i18n.language.split("-")[0];

  const sites = useSelector((state) => state.userWS.userData?.campus || []);
  const filters = useSelector((state) => state.fastbookingWS.filters);
  const campus = useSelector((state) => state.clientsWS.campus);

  const isLoading = useSelector(createLoadingSelector(["fastbooking/getRoomFastbooking"]));

  const handleSubmit = async (values) => {
    try {
      dispatch(
        patchFilter({
          ...values,
          types: values?.types || null,
          startDate: moment(values?.startDate).toISOString(),
          //endDate: moment(values?.startDate).add(1, "hours").toISOString(),
        }),
      );
    } catch (error) {
      logger.error("error", error);
    }
  };

  const confirm = (s) => {
    Modal.confirm({
      title: t("fastbooking.updateSite"),
      content: t("fastbooking.redirectSite", {
        site: s?.title,
        fastbooking_name: t("fast_booking_title", { ns: "csv" }),
      }),
      okText: (
        <a block href={`?siteId=${s.id}`}>
          {t("Next")}
        </a>
      ),
      onOk: () => {
        logEvent(analytics, "fast_booking_filter_site", {
          campus_name: s?.title,
        });
      },
      cancelText: (
        <div
          block
          onClick={() => {
            form.resetFields();
          }}
        >
          {t("Cancel")}
        </div>
      ),
    });
  };

  useEffect(() => {
    if (!!filters?.startDate) {
      logEvent(analytics, "fast_booking_filter_time");
    }
  }, [filters?.startDate]);

  useEffect(() => {
    if (!!filters?.equipments) {
      logEvent(analytics, "fast_booking_filter_devices", {
        equipment_type: filters?.equipments
          ?.map((equipment) => {
            return campus?.mapData?.equipments.find((e) => e.id === equipment)?.title;
          })
          .join(","),
      });
    }
  }, [campus?.mapData?.equipments, filters?.equipments]);

  useEffect(() => {
    if (!!filters?.services) {
      logEvent(analytics, "fast_booking_filter_services", {
        service_type: filters?.services
          ?.map((service) => {
            return campus?.mapData?.services.find((e) => e.id === service)?.title;
          })
          .join(","),
      });
    }
  }, [campus?.mapData?.services, filters?.services]);

  useEffect(() => {
    if (!!filters?.floor) {
      logEvent(analytics, "change_floor");
    }
  }, [filters?.floor]);

  useEffect(() => {
    if (!!filters?.types) {
      logEvent(analytics, "fast_booking_change_space_type", {
        space_type: campus?.mapData?.categories.find((c) => filters?.types?.includes(c.id))?.title,
      });
    }
  }, [campus?.mapData?.categories, filters?.types]);

  useEffect(() => {
    logger.log("filters", filters);
  }, [filters]);

  return (
    <Card
      className={styles["cardRoom"]}
      style={{ overflow: "initial" }}
      headStyle={{
        display: "flex",
        alignItems: "center",
        minHeight: "50px",
        position: "sticky",
        top: "0",
        backgroundColor: "white",
        zIndex: "999",
      }}
      bodyStyle={{ padding: 8 }}
      title={<Typography.Title level={5}>{t("spas.admin.map.search")}</Typography.Title>}
      actions={[
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 16,
          }}
        >
          <Button
            type="default"
            style={{ minWidth: "fit-content", maxWidth: 300, width: "100%" }}
            icon={<Glyph name="travel_explore" />}
            loading={false}
            onClick={() => {
              setVisible(true);
              logEvent(analytics, "fast_booking_search_advanced");
              dispatch(
                patchFilter({
                  startDate: moment().toISOString(),
                  endDate: moment().add(1, "hour").toISOString(),
                  floor: null,
                  equipments: [],
                  services: [],
                  types: null,
                  seats: null,
                }),
              );
            }}
          >
            {t("AvancedSearch")}
          </Button>
          <Button
            icon={<Glyph name="search" />}
            type="primary"
            style={{ minWidth: "fit-content", maxWidth: 300, width: "100%" }}
            onClick={() => {
              form.submit();
            }}
            loading={false}
          >
            {t("ApplyFilter")}
          </Button>
          <div
            style={{
              textDecoration: "underline",
              color: colors.primary_dark,
              textWrap: screens.lg ? "nowrap" : "wrap",
            }}
            onClick={() => {
              dispatch(
                patchFilter({
                  startDate: moment().toISOString(),
                  endDate: moment().add(1, "hour").toISOString(),
                  floor: null,
                  equipments: [],
                  services: [],
                  types: null,
                  seats: null,
                }),
              );
              form.resetFields();
              form.submit();
            }}
          >
            {t("Erase")}
          </div>
        </div>,
      ]}
    >
      {/* <Row gutter={[16, 16]}>
          <Col span={8}>
            <Card>
              <Row gutter={[20, 0]}>
                <Col>
                  <Glyph name="business" className={"secondary"} />
                </Col>
                <Col flex={"auto"}>
                  <Form.Item noStyle name={["siteId"]} rules={[{ required: true }]}>
                    <Select
                      className="accent"
                      bordered={false}
                      size={"middle"}
                      isclearable="false"
                      isrtl="false"
                      issearchable="false"
                      style={{
                        width: "100%",
                        marginTop: -8,
                        marginBottom: -8,
                        backgroundColor: colors.secondary_base.replace(/(.{2})$/i, "33"),
                      }}
                    >
                      {sites.map((s, index) => (
                        <Option value={s?.id} key={index}>
                          {s?.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row>
                <Col flex={"auto"}>
                  <Typography.Text strong style={{ fontSize: size.s }}>
                    <Glyph
                      name="event"
                      style={{ color: colors.secondary_base, marginRight: "8px" }}
                    />
                    {t("BookThe")}
                  </Typography.Text>
                </Col>
                <Col>
                  <Form.Item noStyle name={["startDate"]}>
                    <DatePicker
                      style={{ margin: -8, fontSize: size.s }}
                      format="DD MMM YYYY"
                      disabledDate={(date) => date.isBefore(moment(), "date")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row>
                <Col flex={"auto"}>
                  <Typography.Text strong style={{ fontSize: size.s }}>
                    <Glyph
                      name="schedule"
                      style={{ color: colors.secondary_base, marginRight: "8px" }}
                    />
                    {t("From")}
                  </Typography.Text>
                </Col>
                <Col>
                  <Form.Item noStyle name={["startDate"]}>
                    <TimePicker
                      popupClassName="filterTimePickerDropdown"
                      showNow={false}
                      style={{ margin: -8, fontSize: size.s }}
                      format="HH:mm"
                      minuteStep={5}
                      disabledHours={() =>
                        moment(form.getFieldValue("startDate")).isSame(moment(), "date") &&
                        range(0, moment().hour())
                      }
                      disabledMinutes={(selectedHour) =>
                        moment(form.getFieldValue("startDate")).isSame(moment(), "date") &&
                        moment().hour() === selectedHour
                          ? range(0, moment().minutes())
                          : []
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[20, 0]}>
                <Col xl={11}>
                  <Typography.Text strong style={{ fontSize: size.s }}>
                    <Glyph
                      name="meeting_room"
                      style={{ color: colors.secondary_base, marginRight: "8px" }}
                    />
                    {t("fastbooking.rooms.types")}
                  </Typography.Text>
                </Col>
                <Col xl={13}>
                  <Form.Item noStyle name={["types"]}>
                    <Select
                      size={"medium"}
                      style={{
                        width: "100%",
                        marginTop: -8,
                        marginBottom: -8,
                        fontSize: size.s,
                      }}
                    >
                      <Option value={null}>{t("AllSpaces")}</Option>
                      {campus?.mapData?.categories
                        .filter((c) => c.useAsFilter === true)
                        .map((categorie, index) => (
                          <Option value={categorie?.id} key={index}>
                            {categorie?.title}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[20, 0]}>
                <Col>
                  <Typography.Text strong style={{ fontSize: size.s }}>
                    <Glyph
                      name="devices"
                      style={{ color: colors.secondary_base, marginRight: "8px" }}
                    />
                    {t("Equipements")}
                  </Typography.Text>
                </Col>
                <Col flex={"auto"}>
                  <Form.Item noStyle name={["equipments"]}>
                    <Select
                      value={filters?.equipments}
                      mode="multiple"
                      size={"medium"}
                      style={{
                        width: "100%",
                        marginTop: -8,
                        marginBottom: -8,
                        fontSize: size.s,
                      }}
                      placeholder="Aucun"
                      maxTagCount="responsive"
                    >
                      {campus?.mapData?.equipments?.map((equipment, index) => (
                        <Option value={equipment?.id} key={index}>
                          {equipment?.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col span={8}>
            <Card>
              <Row gutter={[20, 0]}>
                <Col>
                  <Typography.Text strong style={{ fontSize: size.s }}>
                    <Glyph
                      name="devices"
                      style={{ color: colors.secondary_base, marginRight: "8px" }}
                    />
                    {t("Services")}
                  </Typography.Text>
                </Col>
                <Col flex={"auto"}>
                  <Form.Item noStyle name={["services"]}>
                    <Select
                      value={filters?.services}
                      size={"medium"}
                      mode="multiple"
                      maxTagCount="responsive"
                      style={{
                        width: "100%",
                        marginTop: -8,
                        marginBottom: -8,
                        fontSize: size.s,
                      }}
                      placeholder="Aucun"
                    >
                      {campus?.mapData?.services?.map((service, index) => (
                        <Option value={service?.id} key={index}>
                          {service?.title}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row> */}
      <Form
        form={form}
        initialValues={{
          equipements: filters?.equipments || [],
          services: filters?.services || [],
          siteId: sites?.find((c) => c?.id === campus?.mapData?.id)?.id,
          startDate: filters.startDate ? moment(filters.startDate) : moment(),
          types: filters?.types || null,
          startHours:
            moment(
              moment(filters.startHours).minutes(
                Math.ceil(moment(filters.startHours).minutes() / 15) * 15,
              ),
            ).format("HH:mm") ||
            moment(moment().minutes(Math.ceil(moment().minutes() / 15) * 15)).format("HH:mm"),
        }}
        onFinish={handleSubmit}
        onValuesChange={(changedValues, values) => {
          if (moment(changedValues?.startHours, "HH:mm") > moment().add(10, "minutes")) {
            openNotification();
          }
          if (changedValues.siteId) {
            confirm(sites.find((s) => s.id === values.siteId));
          }
          if (changedValues.startHours) {
            values.startDate.set({
              hour: parseInt(changedValues.startHours.split(":")[0], 10),
              minute: parseInt(changedValues.startHours.split(":")[1], 10),
            });
          }
        }}
        style={{
          display: "grid",
          gridTemplateColumns: screens.lg
            ? "repeat(3, minmax(0, 1fr))"
            : "repeat(2, minmax(0, 1fr))",
          gap: 8,
          padding: 8,
        }}
      >
        <div className="WhiteCardWithShadow">
          <Glyph name="business" className={"secondary"} />
          <Form.Item noStyle name={["siteId"]} rules={[{ required: true }]}>
            <Select
              className="accent"
              bordered={false}
              size="middle"
              isclearable="false"
              isrtl="false"
              issearchable="false"
              style={{
                width: "100%",
                marginTop: -8,
                marginBottom: -8,
                backgroundColor: colors.secondary_base.replace(/(.{2})$/i, "33"),
              }}
            >
              {sites?.map((s, index) => (
                <Option value={s?.id} key={index}>
                  {s?.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="WhiteCardWithShadow">
          <div className="WhiteCardLabel">
            <Glyph name="event" style={{ color: colors.secondary_base }} />
            {t("BookThe")}
          </div>
          <Form.Item noStyle name={["startDate"]}>
            <DatePicker
              style={{ fontSize: size.s, width: "fit-content", borderRadius: 2 }}
              size="middle"
              suffixIcon={
                screens.xl ? <Glyph name="calendar_today" style={{ fontSize: size.m }} /> : null
              }
              format={locale === "en" ? "MMMM Do YYYY" : "DD MMM YYYY"}
              disabledDate={(date) => date.isBefore(moment(), "date")}
            />
          </Form.Item>
        </div>

        <div className="WhiteCardWithShadow">
          <div className="WhiteCardLabel">
            <Glyph name="schedule" style={{ color: colors.secondary_base }} />
            {t("From")}
          </div>
          <Form.Item shouldUpdate noStyle>
            {({ getFieldValue }) => (
              <SelectHours diff={15} date={getFieldValue("startDate")} nameProps="startHours" />
            )}
          </Form.Item>
        </div>

        <div className="WhiteCardWithShadow">
          <div className="WhiteCardLabel">
            <Glyph name="meeting_room" style={{ color: colors.secondary_base }} />
            {t("fastbooking.rooms.types")}
          </div>
          <Form.Item noStyle name={["types"]}>
            <Select
              size="middle"
              dropdownMatchSelectWidth={false}
              style={{
                marginTop: -8,
                marginBottom: -8,
                fontSize: size.s,
                overflow: "hidden",
                width: "100%",
              }}
              defaultValue={!!filters?.types?.length ? filters?.types : null}
            >
              <Option value={null}>{t("AllSpaces")}</Option>
              {campus?.mapData?.categories
                .filter((c) => c.useAsFilter === true)
                .map((categorie, index) => (
                  <Option value={categorie?.id} key={index}>
                    {categorie?.title}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </div>

        <div className="WhiteCardWithShadow">
          <div className="WhiteCardLabel">
            <Glyph name="devices" style={{ color: colors.secondary_base }} />
            {t("Equipements")}
          </div>
          <Form.Item noStyle name={["equipments"]}>
            <Select
              value={filters?.equipments}
              defaultValue={filters?.equipments}
              mode="multiple"
              size={"middle"}
              style={{
                width: "100%",
                marginTop: -8,
                marginBottom: -8,
                fontSize: size.s,
              }}
              placeholder="Aucun"
              maxTagCount="responsive"
            >
              {campus?.mapData?.equipments?.map((equipment, index) => (
                <Option value={equipment?.id} key={index}>
                  {equipment?.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>

        <div className="WhiteCardWithShadow">
          <div className="WhiteCardLabel">
            <Glyph name="devices" style={{ color: colors.secondary_base }} />
            <div className="WhiteCardLabel">{t("Services")}</div>
          </div>
          <Form.Item noStyle name={["services"]}>
            <Select
              value={filters?.services}
              defaultValue={filters?.services}
              size={"middle"}
              mode="multiple"
              maxTagCount="responsive"
              style={{
                width: "100%",
                fontSize: size.s,
              }}
              placeholder="Aucun"
            >
              {campus?.mapData?.services?.map((service, index) => (
                <Option value={service?.id} key={index}>
                  {service?.title}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
      </Form>
    </Card>
  );
};

export default CardFilter;
