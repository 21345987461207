import { Select } from "antd";
import moment from "moment";
import { useTranslation } from "react-i18next";

const MonthAndYearSelector = ({
  selectedMonth,
  setSelectedMonth,
  setSelectedDate,
  clickable,
  selectedDate,
}) => {
  const { i18n } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const monthOptions = Array.from({ length: 12 }, (_, index) => ({
    value: index,
    label: moment().locale(locale).month(index).format("MMMM"),
  }));

  const yearOptions = Array.from(
    {
      length: 5,
    },
    (_, index) => ({
      value: moment().year() - 3 + index,
      label: moment().year() - 3 + index,
    }),
  );

  return (
    <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
      <Select
        style={{ width: 100 }}
        className="card"
        size={"middle"}
        disabled={!clickable ?? false}
        options={monthOptions}
        value={moment(selectedDate)?.month()}
        onChange={(v) => {
          setSelectedMonth(moment(selectedMonth).month(v));
          setSelectedDate(moment(selectedMonth).month(v));
        }}
      />
      <Select
        style={{ width: 100 }}
        className="card"
        size={"middle"}
        disabled={!clickable ?? false}
        options={yearOptions}
        value={moment(selectedDate)?.year()}
        onChange={(v) => {
          setSelectedMonth(moment(selectedMonth).year(v));
          setSelectedDate(moment(selectedMonth).year(v));
        }}
      />
    </div>
  );
};

export default MonthAndYearSelector;
