import { Button } from "antd";
import { useTranslation } from "react-i18next";
import Glyph from "../../../Common/Glyph/Glyph";
import { useSelector } from "react-redux";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const HeaderButtons = ({ setShowManageVehiclesModal, setShowCreateOrModifyBookingModal }) => {
  const { t } = useTranslation();

  const vehicles = useSelector((state) => state.parkingWS.vehicles);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "end", gap: 8 }}>
        <Button
          type="primary"
          disabled={!!!vehicles.length}
          icon={<Glyph name="add" />}
          style={{ padding: "0 34px" }}
          onClick={() => {
            logEvent(analytics, "parking_new_booking");
            setShowCreateOrModifyBookingModal((p) => !p);
          }}
        >
          {t("parking.NewBooking")}
        </Button>
        {!!vehicles?.length ? (
          <Button
            type={!!vehicles.length ? "secondary" : "primary"}
            disabled={!!!vehicles.length}
            style={{ padding: "0 34px" }}
            onClick={() => {
              logEvent(analytics, "parking_profile_access");
              setShowManageVehiclesModal((p) => !p);
            }}
          >
            {t("parking.ManageMyVehicles")}
          </Button>
        ) : (
          <Button
            type="primary"
            icon={<Glyph name="add" />}
            onClick={() => {
              logEvent(analytics, "parking_add_vehicle");
              setShowManageVehiclesModal((p) => !p);
            }}
          >
            {t("parking.AddVehicle")}
          </Button>
        )}
      </div>
    </>
  );
};

export default HeaderButtons;
