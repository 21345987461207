import { useKeycloak } from "@react-keycloak/web";
import { Button, Card, Typography } from "antd";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";
import QrCodeWidget from "./QrCodeWidget";
import Header from "./Header";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../../services/api/Firebase/Firebase";

const WidgetPage = () => {
  const { t } = useTranslation();
  const { colors } = useDesignTokens();
  const { keycloak } = useKeycloak();

  const views = useSelector((state) => state.userWS.userData?.views);

  const [fullScreen, setFullScreen] = useState(false);

  const handleDownloadApp = () => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    logger.log("userAgent", userAgent);
    if (/android/i.test(userAgent)) {
      window.open(process.env.REACT_APP_STORE_ANDROID, "_blank");
    } else if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.open(process.env.REACT_APP_STORE_IOS, "_blank");
    }
  };

  const renderWidget = (view, index) => {
    return (
      <div
        key={index}
        style={{ display: "flex", width: "100%", flexDirection: "column", gap: 8 }}
        onClick={() => {
          logEvent(analytics, `${view.title}_access_webOnMobile`, {
            from: view.type,
          });
        }}
      >
        {view.type === "qrCode" && (
          <QrCodeWidget fullScreen={fullScreen} setFullScreen={setFullScreen} />
        )}

        {view.type === "webview" && (
          <Card
            style={{ height: 68, overflow: "hidden" }}
            bodyStyle={{ display: "flex", alignItems: "center", overflow: "hidden" }}
            onClick={() => window.open(`${view.url}?token=${keycloak.token}`, "_blank")}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%  ",
              }}
            >
              <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                <Glyph
                  name={view.icon || "language"}
                  style={{ fontSize: "30px", color: colors.interactive_03 }}
                />
                <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                  <Typography.Title
                    level={5}
                    style={{
                      textTransform: "uppercase",
                      margin: 0,
                      lineHeight: "initial",
                    }}
                  >
                    {t(view.title, { ns: "csv" })}
                  </Typography.Title>
                  <Typography.Text type="secondary" style={{ lineHeight: "initial" }}>
                    {t("mobile.AccessService")}
                  </Typography.Text>
                </div>
              </div>
              <Glyph
                name={"open_in_new"}
                style={{
                  fontSize: "20px",
                  marginRight: "6px",
                  color: colors.grey_60,
                }}
              />
            </div>
          </Card>
        )}

        {view.type === "smartControl" && (
          <NavLink
            to="/smartControl"
            style={{ cursor: "pointer" }}
            onClick={() => {
              logEvent(analytics, `${view.title}_access`, {
                from: view.type,
              });
            }}
          >
            <Card
              style={{
                overflow: "hidden",
                height: "100%",
                maxHeight: fullScreen === "smartControl" ? "100%" : 68,
                transition: "max-height 0.5s",
              }}
              bodyStyle={{
                display: "flex",
                alignItems: "center",
                overflow: "hidden",
                height: "100%",
              }}
              onClick={() => setFullScreen("smartControl")}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%  ",
                }}
              >
                <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                  <Glyph
                    name={view.icon}
                    style={{ fontSize: "30px", color: colors.interactive_03 }}
                  />
                  <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
                    <Typography.Title
                      level={5}
                      style={{
                        textTransform: "uppercase",
                        margin: 0,
                        lineHeight: "initial",
                      }}
                    >
                      {t(view.title, { ns: "csv" })}
                    </Typography.Title>
                    <Typography.Text type="secondary" style={{ lineHeight: "initial" }}>
                      {t("mobile.AccessService")}
                    </Typography.Text>
                  </div>
                </div>
                <Glyph
                  name={"chevron_right"}
                  style={{
                    fontSize: "30px",
                    color: colors.grey_60,
                  }}
                />
              </div>
            </Card>
          </NavLink>
        )}
      </div>
    );
  };

  return (
    <>
      <Header />
      <div
        style={{ display: "flex", flexDirection: "column", gap: 16, padding: 16, height: "100%" }}
      >
        {(views || [])?.map(renderWidget)}
        {fullScreen !== "smartControl" && (
          <Button
            type="primary"
            style={{ width: "100%", color: "white", marginTop: "auto" }}
            onClick={handleDownloadApp}
          >
            <Glyph name="get_app" style={{ marginRight: "8px" }} />
            {t("mobile.downloadApp")}
          </Button>
        )}
      </div>
    </>
  );
};

export default WidgetPage;
