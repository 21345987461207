import { createAsyncThunk as thunk, createSelector, createSlice } from "@reduxjs/toolkit";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  profile: null,
  config: null,
  visits: [],
  searchUsers: [],
  searchVisitors: [],
  searchResidents: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/
const EXTRA_REDUCERS = {};
let siteIdStorage = localStorage.getItem("siteId");

export const getConfig = thunk("visitors/getConfig", async (_, { getState }) => {
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "visitors");
  const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
  return DWMConnector.getConfig(viewId.id, siteId);
});
EXTRA_REDUCERS[getConfig.fulfilled] = (state, action) => {
  state.config = action.payload;
};

export const listVisits = thunk("visitors/listVisits", async (_, { getState }) => {
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "visitors");
  const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
  return DWMConnector.listVisits(viewId.id, siteId);
});
EXTRA_REDUCERS[listVisits.fulfilled] = (state, action) => {
  state.visits = action.payload;
};

export const createVisit = thunk("visitors/createVisit", async (data, { getState, dispatch }) => {
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "visitors");
  const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
  await DWMConnector.createVisit(viewId.id, data, siteId);
  await dispatch(listVisits());
});
export const updateVisit = thunk("visitors/updateVisit", async (data, { getState, dispatch }) => {
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "visitors");
  const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
  await DWMConnector.updateVisit(viewId.id, data, siteId);
  await dispatch(listVisits());
});
export const cancelVisit = thunk("visitors/cancelVisit", async (data, { getState, dispatch }) => {
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "visitors");
  const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
  await DWMConnector.cancelVisit(viewId.id, data, siteId);
  await dispatch(listVisits());
});

export const searchVisitors = thunk("visitors/searchVisitors", async ({ search }, { getState }) => {
  if (search.length < 3) return INITIAL_STATE.searchVisitors;
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "visitors");
  const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
  return DWMConnector.searchVisitors(viewId.id, search, siteId);
});
EXTRA_REDUCERS[searchVisitors.fulfilled] = (state, action) => {
  state.searchVisitors = action.payload;
};

export const searchResidents = thunk(
  "visitors/searchResidents",
  async ({ search }, { getState }) => {
    if (search.length < 3) return INITIAL_STATE.searchResidents;
    const state = getState();
    const viewId = state.userWS.userData.views.find((v) => v.type === "visitors");
    const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
    return DWMConnector.searchResidents(viewId.id, search, siteId);
  },
);
EXTRA_REDUCERS[searchResidents.fulfilled] = (state, action) => {
  state.searchResidents = action.payload;
};

export const createUser = thunk("visitors/createUser", async (data, { getState }) => {
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "visitors");
  const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
  return DWMConnector.createUser(viewId.id, data, siteId);
});
export const updateUser = thunk("visitors/updateUser", async (data, { getState, dispatch }) => {
  const state = getState();
  const viewId = state.userWS.userData.views.find((v) => v.type === "visitors");
  const siteId = siteIdStorage ? siteIdStorage : state.userWS.userData?.profile.campusId;
  await DWMConnector.updateUser(viewId.id, data, siteId);
  await dispatch(listVisits());
});

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const visitorsSlice = createSlice({
  name: "visitors",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

/**
|--------------------------------------------------
| Selectors
|--------------------------------------------------
*/

export const rightsSelector = createSelector(
  (state) => state.visitorsWS.profile,
  (profile) => {
    return { rights: profile?.acmData?.rights, isVisitor: profile?.acmData?.isVisitor };
  },
);

export default visitorsSlice.reducer;
