import { Button, Card, Segmented, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import Glyph from "../../../../Common/Glyph/Glyph";
import FormPart from "../../Component/Form/FormPart";
import formParts from "../../Component/Form/formParts.json";

const EditReferentialRightForm = ({ tempData, setTempData }) => {
  const { colors, symbols } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];
  const [lang, setLang] = useState(i18n.language.split("-")[0]);
  const [tempForm, setTempForm] = useState(tempData?.form?.items || []);
  const appLocales = useSelector((state) => state.userWS?.userData?.locales);

  const getHighestTypeKey = (type) => {
    const items = tempForm.filter((item) => item.type === type);
    const highestTypeKey = items.reduce((highestKey, item) => {
      const keyNumber = parseInt(item.name.split("_")[1], 10) || 0;
      return keyNumber > highestKey ? keyNumber : highestKey;
    }, 0);
    return highestTypeKey;
  };

  const addFormPart = (d) => {
    setTempForm((prevtempForm) => {
      const newFormPart = {
        ...d,
        name: `${d.type.toUpperCase()}_${getHighestTypeKey(d.type) + 1}`,
      };
      return [...prevtempForm, newFormPart];
    });
  };

  /*useEffect(() => {
    setTempForm(tempData?.form || []);
  }, [setTempForm, tempData]);*/

  useEffect(() => {
    setTempData((prevTempData) => {
      return {
        ...prevTempData,
        form: { items: tempForm },
      };
    });
  }, [setTempData, tempForm]);

  useEffect(() => {
    logger.log("rightForm tempData", tempForm);
  }, [tempForm]);

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          borderRadius: symbols.base_shape.radius,
          backgroundColor: colors.light_background,
          padding: 10,
        }}
      >
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography.Text strong style={{ fontSize: 16 }}>
              {t("ticketingv2.AddFormPart")}
            </Typography.Text>
            <Typography.Text type="secondary" style={{ fontSize: 12 }}>
              {t("ticketingv2.AddFormPartComment")}
            </Typography.Text>
          </div>
          <div style={{ display: "flex", gap: 8 }}>
            <Button
              type="tertiary"
              disabled={!!!tempForm.length}
              size="middle"
              style={{
                display: "flex",
                gap: 8,
                padding: 12,
                right: 0,
                height: 32,
              }}
              onClick={() => setTempForm([])}
            >
              <Glyph name="delete" />
              {!!tempForm.length && t("ticketingv2.DeleteForm")}
            </Button>
            <Segmented
              size="medium"
              style={{
                backgroundColor: colors.grey_40,
                width: "fit-content",
                height: "fit-content",
              }}
              value={lang}
              options={
                !!appLocales?.length &&
                appLocales.map((l) => ({
                  label: (
                    <div
                      key={l.title}
                      style={{
                        transition: "all 0.3s ease",
                        padding: "0 8px 0 12px",
                        display: "flex",
                        alignItems: "center",
                        gap: 8,
                        color: lang === l.title ? colors.primary_dark : "white",
                      }}
                    >
                      <Typography.Text
                        strong
                        style={{ color: lang === l.title ? colors.primary_dark : "white" }}
                      >
                        {l.title?.toUpperCase()}
                      </Typography.Text>
                      {
                        // a voir les modalité de validation
                        /*tempForm?.title[l.title] ? (
                      <Glyph name="done" style={{ fontSize: 15 }} />
                    ) : (
                      <Glyph name="close" style={{ fontSize: 15 }} />
                    )*/
                      }
                    </div>
                  ),
                  value: l.title,
                }))
              }
              onChange={(value) => {
                setLang(value);
              }}
            />
          </div>
        </div>
        <div style={{ display: "flex", gap: 8, flexWrap: "wrap" }}>
          {formParts?.map((d) => {
            const hasFiles = !!tempForm.filter((item) => item.type === "files").length;
            const hasPictures = !!tempForm.filter((item) => item.type === "pictures").length;

            return (
              <Card
                key={d.name}
                style={{
                  minWidth: 122,
                  width: 122,
                  height: 80,
                  cursor:
                    (d.type === "files" && hasFiles) || (d.type === "pictures" && hasPictures)
                      ? "not-allowed"
                      : "pointer",
                  backgroundColor:
                    (d.type === "files" && hasFiles) || (d.type === "pictures" && hasPictures)
                      ? colors.light_background
                      : "white",
                }}
                onClick={() =>
                  (d.type === "files" && !hasFiles) ||
                  (d.type === "pictures" && !hasPictures) ||
                  d.type !== "files" ||
                  d.type !== "pictures"
                    ? addFormPart(d)
                    : null
                }
              >
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: 8,
                  }}
                >
                  <Glyph name={d.icon} style={{ fontSize: 24, color: colors.grey_60 }} />
                  <Typography.Text style={{ fontSize: 12, lineHeight: "14px" }}>
                    {d.title[locale]}
                  </Typography.Text>
                </div>
              </Card>
            );
          })}
        </div>
      </div>
      {!!tempForm?.length &&
        tempForm.map((formPart) => (
          <div key={formPart.name}>
            <FormPart
              tempForm={tempForm}
              setTempForm={setTempForm}
              lang={lang}
              position={tempForm.indexOf(formPart)}
              type={formPart.type}
            />
          </div>
        ))}
    </>
  );
};

export default EditReferentialRightForm;
