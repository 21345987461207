import React from "react";
import Glyph from "../../../../Common/Glyph/Glyph";
import { Input } from "antd";
import useDesignTokens from "../../../../../hook/useDesignTokens";

const FormHeader = ({ tempForm, setTempForm, lang, position }) => {
  const { colors } = useDesignTokens();

  const moveItem = (direction) => {
    setTempForm((prevTempForm) => {
      const updatedTempForm = [...prevTempForm];
      const temp = updatedTempForm[position];
      updatedTempForm[position] = updatedTempForm[position + direction];
      updatedTempForm[position + direction] = temp;
      return updatedTempForm;
    });
  };

  const deleteItem = () => {
    setTempForm((prevTempForm) => {
      const updatedTempForm = [...prevTempForm];
      updatedTempForm.splice(position, 1);
      return updatedTempForm;
    });
  };

  const changeItemTitle = (e) => {
    setTempForm((prevTempForm) => {
      const updatedTempForm = [...prevTempForm];
      updatedTempForm[position] = {
        ...updatedTempForm[position],
        title: {
          ...updatedTempForm[position].title,
          [lang]: e.target.value,
        },
      };
      return updatedTempForm;
    });
  };

  return (
    <>
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", gap: 8, alignItems: "center", width: "100%" }}>
          <Glyph
            name={tempForm[position].icon}
            style={{ fontSize: 16, color: colors.secondary_base }}
          />
          <Input
            bordered={false}
            value={tempForm[position].title[lang]}
            onChange={(e) => changeItemTitle(e)}
            style={{
              width: "100%",
              fontSize: 14,
              fontWeight: "bold",
            }}
          />
        </div>
        <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
          {tempForm.length - 1 !== position && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.light_background,
                borderRadius: "100%",
                padding: 5,
                cursor: "pointer",
              }}
              onClick={() => moveItem(1)}
            >
              <Glyph name="arrow_downward" style={{ fontSize: 10 }} />
            </div>
          )}

          {position > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: colors.light_background,
                borderRadius: "100%",
                padding: 5,
                cursor: "pointer",
              }}
              onClick={() => moveItem(-1)}
            >
              <Glyph name="arrow_upward" style={{ fontSize: 10 }} />
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "red",
              borderRadius: "100%",
              padding: 5,
              cursor: "pointer",
            }}
            onClick={deleteItem}
          >
            <Glyph name="delete" style={{ fontSize: 10, color: "white" }} />
          </div>
        </div>
      </div>
      <div style={{ width: "50%", borderBottom: `1px solid ${colors.grey_40}` }} />
    </>
  );
};

export default FormHeader;
