import { Tag, Typography } from "antd";
import Tile from "../../Common/Tile/Tile";
import { isEmpty } from "ramda";
import useDesignTokens from "../../../hook/useDesignTokens";

const DetailsMenu = ({ contentMenu }) => {
  const { size } = useDesignTokens();
  return (
    <div style={{ width: "100%" }}>
      <Typography.Text style={{ fontSize: size.m }}>{contentMenu?.subtitle}</Typography.Text>

      {contentMenu?.sections
        ?.filter((v) => v?.type === "list")
        ?.filter((v) => !isEmpty(v?.values))
        ?.map((s, index) => (
          <div style={{ marginTop: "16px" }} key={index}>
            <Typography.Text strong style={{ textTransform: "uppercase" }}>
              {s?.title}
            </Typography.Text>
            <div style={{ display: "flex", marginTop: "8px" }}>
              {s?.values?.map((value, index) => (
                <Tag key={index} style={{ marginBottom: "8px" }}>
                  {value}
                </Tag>
              ))}
            </div>
          </div>
        ))}

      {contentMenu?.sections
        ?.filter((v) => v?.type === "table")
        ?.map((s, index) => (
          <div style={{ marginTop: "16px", paddingBottom: "10px" }} key={index}>
            <Typography.Text strong style={{ textTransform: "uppercase" }}>
              {s?.title}
            </Typography.Text>
            {s?.values?.map((value, index) => (
              <Tile key={index} style={{ margin: "8px 0" }}>
                {value?.map((v) => (
                  <Typography.Text strong>{v}</Typography.Text>
                ))}
              </Tile>
            ))}
          </div>
        ))}
    </div>
  );
};

export default DetailsMenu;
