import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { Route, Switch, useHistory } from "react-router-dom";
import useDidUpdateEffect from "../../../hook/useDidUpdate";
import useMap, { useMapLayer } from "../../Common/Map/useMap";
import { TitleSource } from "../../Common/Teleporters/Title";
import FastbookingList from "./FastbookingList";
import Filter from "./Filter";
import RoomFastbooking from "./RoomFastbooking";

const mapOptions = {
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 358,
  },
};

const Fastbooking = () => {
  useMapLayer(mapOptions);
  const { t } = useTranslation();

  const { selectedOnMap } = useMap();
  const history = useHistory();
  const roomFastbooking = useSelector((state) => state.fastbookingWS.roomFastbooking);
  const planning = useSelector((state) => state.planningWS.planning);

  useDidUpdateEffect(() => {
    const item = roomFastbooking?.rooms?.find((r) => r.roomId === selectedOnMap?.id);
    const itemPlanning = planning?.slots?.items?.find((i) =>
      i?.resources.find((r) => r?.id === selectedOnMap?.id),
    );

    if (selectedOnMap?.map?.findAndOrder?.placeId)
      history.push({
        pathname: `/fastbooking/${selectedOnMap.map.findAndOrder.placeId}`,
        state: { room: item ? item : itemPlanning },
      });
  }, [selectedOnMap]);

  return (
    <>
      <TitleSource>{t("fast_booking_title", { ns: "csv" })}</TitleSource>

      <Switch>
        <Route exact path="/fastBooking">
          <FastbookingList />
        </Route>
        <Route path="/fastBooking/filter" exact>
          <Filter />
        </Route>
        <Route path="/fastBooking/:id" exact>
          <RoomFastbooking />
        </Route>
      </Switch>
    </>
  );
};

export default Fastbooking;
