import { Button, Input, message, Modal, Typography } from "antd";
import { isNil } from "ramda";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as Label } from "../../../../../assets/svg/label-presence.svg";
import { confirmPresence, listSlots } from "../../../../../services/redux/services/SpaceService";
import styles from "../../SpaceBooking.module.less";

const RequestModalPresence = ({ slot, onCancel }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const input_1 = useRef();
  const input_2 = useRef();
  const input_3 = useRef();
  const input_4 = useRef();
  const input_5 = useRef();
  const input_6 = useRef();
  const inputs = [input_1, input_2, input_3, input_4, input_5, input_6];

  const [code, setCode] = useState(["", "", "", "", "", ""]);

  useEffect(() => {
    if (!!slot) {
      setCode(["", "", "", "", "", ""]);
    }
  }, [slot]);

  // 123456
  const handleInput = (index) => (e) => {
    const key = e?.key;

    // If backspace, return to previous input
    if (key === "Backspace") {
      if (!isNil(inputs[index - 1])) {
        inputs[index - 1].current.focus();
      }
      setCode((code) => code.toSpliced(index, 1, ""));
      return;
    }

    if (isNaN(+key)) return;

    setCode((code) => code.toSpliced(index, 1, key));

    if (!isNil(inputs[index + 1])) {
      inputs[index + 1].current.focus();
    } else {
      for (const input of inputs) {
        if (!!input.current) input.current.blur();
      }
    }
  };

  return (
    <Modal
      destroyOnClose
      open={!!slot}
      onCancel={onCancel}
      title={<Typography.Text strong>{t("spas.request.confirm.presence")}</Typography.Text>}
      bodyStyle={{ display: "flex", justifyContent: "center", flexDirection: "column" }}
      footer={[
        <Button
          key="save"
          block
          type="primary"
          style={{ width: "350px" }}
          disabled={code.join("").length !== inputs.length}
          onClick={() => {
            dispatch(
              confirmPresence({
                slotId: slot.id,
                siteId: slot.request.siteId,
                code: parseInt(code.join("")),
              }),
            )
              .unwrap()
              .then(() => {
                message.success(t("spas.presence.success"));
                onCancel();
                dispatch(listSlots());
              })
              .catch((error) => {
                logger.error(error);
                if (error.code === "409") {
                  message.error(t("spas.prsence.alreadyConfirmed"));
                } else if (error.code === "403") {
                  message.error(t("spas.presence.noConfirmed"));
                } else {
                  message.error(t("spas.presence.error"));
                }
              });
          }}
        >
          {t("spas.request.validate")}
        </Button>,
      ]}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          alignItems: "center",
        }}
      >
        <Label />
        <br />
        <Typography.Text type="secondary" strong>
          Saisir le code à 6 chiffres situé sur l’étiquette de bureau
        </Typography.Text>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "row",
          alignItems: "center",
          marginTop: "60px",
        }}
      >
        <Input
          className={styles.inputNumber}
          ref={input_1}
          size="large"
          style={{ marginRight: "8px" }}
          value={code[0]}
          onKeyDown={handleInput(0)}
        />
        <Input
          className={styles.inputNumber}
          ref={input_2}
          size="large"
          style={{ marginRight: "8px" }}
          value={code[1]}
          onKeyDown={handleInput(1)}
        />
        <Input
          className={styles.inputNumber}
          ref={input_3}
          size="large"
          style={{ marginRight: "8px" }}
          value={code[2]}
          onKeyDown={handleInput(2)}
        />
        -
        <Input
          className={styles.inputNumber}
          ref={input_4}
          size="large"
          style={{ marginLeft: "8px" }}
          value={code[3]}
          onKeyDown={handleInput(3)}
        />
        <Input
          className={styles.inputNumber}
          ref={input_5}
          size="large"
          style={{ marginLeft: "8px" }}
          value={code[4]}
          onKeyDown={handleInput(4)}
        />
        <Input
          className={styles.inputNumber}
          ref={input_6}
          size="large"
          style={{ marginLeft: "8px" }}
          value={code[5]}
          onKeyDown={handleInput(5)}
        />
      </div>
    </Modal>
  );
};

export default RequestModalPresence;
