/* eslint-disable jsx-a11y/anchor-is-valid */
import { Avatar, Button, Card, Col, Input, Modal, Popover, Row, Space, Typography } from "antd";
import clsx from "clsx";
import moment from "moment";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import styles from "../SpaceBooking.module.less";

import { answerFollow, revokeFollow } from "../../../../services/redux/services/SpaceService";

import { useTranslation } from "react-i18next";
import { ReactComponent as Filter } from "../../../../assets/svg/filterSpaas.svg";
import useDesignTokens from "../../../../hook/useDesignTokens";
import Glyph from "../../../Common/Glyph/Glyph";
import PhoneBook from "../../PhoneBook/PhoneBook";
import { PhoneBookTitleTarget } from "../../PhoneBook/PhoneBookTitle";

const STEPS = {
  ACCEPTED: "ACCEPTED",
  PENDING: "PENDING",
  PHONEBOOK: "PHONEBOOK",
};

const ManagePeopleModal = ({ visible, onCancel }) => {
  const dispatch = useDispatch();
  const { colors } = useDesignTokens();
  const { t } = useTranslation();

  const followed = useSelector((state) => state.spaceServiceWS.followed);
  const pending = useSelector((state) => state.spaceServiceWS.followedPending);

  const [searchFollower, setSearch] = useState("");
  const [step, setStep] = useState(STEPS.ACCEPTED);

  const handleSearch = (evt) => {
    setSearch(evt.target.value);
  };

  const searchResult = followed
    ?.filter((value) =>
      searchFollower ? new RegExp(searchFollower, "i").test(value.displayName) : true,
    )
    .sort((a, b) => (a.displayName > b.displayName ? 1 : -1));

  useEffect(() => {
    setStep(STEPS.ACCEPTED);
  }, [visible]);

  return (
    <Modal
      destroyOnClose
      title={
        step === STEPS.ACCEPTED ? (
          <Typography.Text strong>{t("spas.manage.followingPerson")}</Typography.Text>
        ) : (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={() => setStep(STEPS.ACCEPTED)}
          >
            {step !== STEPS.PHONEBOOK && (
              <div style={{ cursor: "pointer", marginRight: "8px" }}>
                <Glyph name="arrow_back_ios" />
              </div>
            )}
            {step === STEPS.PENDING ? (
              <Typography.Text strong>{t("spas.manage.waiting.invitation")}</Typography.Text>
            ) : (
              <>
                <PhoneBookTitleTarget />
              </>
            )}
          </div>
        )
      }
      open={!!visible}
      onCancel={onCancel}
      footer={
        step !== STEPS.PHONEBOOK
          ? [
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  type="primary"
                  icon={<Glyph name="person_add" />}
                  onClick={() => setStep(STEPS.PHONEBOOK)}
                >
                  {t("spas.manage.addPerson")}
                </Button>
              </div>,
            ]
          : null
      }
      className={clsx(styles.modal)}
      bodyStyle={{ padding: "0" }}
    >
      <div
        style={{ display: step === STEPS.ACCEPTED ? "block" : "none", padding: "35px 35px 0 35px" }}
      >
        <Input
          style={{ width: "100%", margin: "16px 0" }}
          placeholder={t("spas.people.find")}
          prefix={<Glyph name="search" />}
          onChange={handleSearch}
          value={searchFollower}
        />
        {!isEmpty(pending) && (
          <Card
            style={{ marginBottom: "16px", cursor: "pointer" }}
            onClick={() => setStep(STEPS.PENDING)}
          >
            <Row gutter={[20, 0]} style={{ justifyContent: "space-between" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Col>
                  <Glyph
                    name="person_add"
                    style={{ fontSize: "36px", color: colors.pending_light }}
                  />
                </Col>
                <Col style={{ width: "250px" }}>
                  <Typography.Paragraph type="secondary" style={{ textTransform: "uppercase" }}>
                    {t("spas.manage.waiting.invitation")}
                  </Typography.Paragraph>
                  <Typography.Paragraph strong>
                    {t("spas.manage.lengthPerson")}
                  </Typography.Paragraph>
                </Col>
              </div>
              <Col style={{ display: "flex", alignItems: "end" }}>
                <div>
                  <Glyph name="navigate_next" />
                </div>
              </Col>
            </Row>
          </Card>
        )}

        {!isEmpty(followed) ? (
          <div style={{ height: "400px", overflowX: "auto", padding: "8px" }}>
            <Space direction="vertical" size="small" style={{ width: "100%" }}>
              {searchResult?.length === 0 ? (
                <div style={{ paddingTop: "20px" }}>
                  <Typography.Paragraph strong type="secondary">
                    {t("spas.manage.noResult")}
                  </Typography.Paragraph>
                </div>
              ) : (
                <>
                  <Typography.Paragraph>{t("All")}</Typography.Paragraph>
                  {followed?.map((follow, index) => (
                    <Card key={index}>
                      <Row gutter={[20, 0]} style={{ justifyContent: "space-between" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Col>
                            <Avatar src={follow?.photoUrl}>
                              {`${follow?.firstname} ${follow?.lastname}`
                                .toUpperCase()
                                .split(" ")
                                .map((i) => i.charAt(0).toUpperCase())
                                .join("")}
                            </Avatar>
                          </Col>
                          <Col>
                            <Typography.Paragraph strong>
                              {follow?.displayName}
                            </Typography.Paragraph>
                            <Typography.Paragraph>{follow?.function}</Typography.Paragraph>
                          </Col>
                        </div>

                        <Col>
                          <Popover
                            content={
                              // eslint-disable-next-line jsx-a11y/anchor-is-valid
                              <a
                                className={clsx(styles.linkCancel)}
                                onClick={() => {
                                  dispatch(revokeFollow({ followId: follow?.followId }));
                                }}
                              >
                                {t("spas.manage.stopFollow")}
                              </a>
                            }
                            placement="right"
                            trigger="click"
                          >
                            <div style={{ cursor: "pointer" }}>
                              <Glyph name="more_horiz" />
                            </div>
                          </Popover>
                        </Col>
                      </Row>
                    </Card>
                  ))}
                </>
              )}
            </Space>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexFlow: "column",
              textAlign: "center",
              paddingTop: "20px",
            }}
          >
            <Filter style={{ height: "150px" }} />
            <Typography.Paragraph strong style={{ paddingTop: "16px" }}>
              {t("spas.manage.noFollower")}
            </Typography.Paragraph>
            <Typography.Paragraph strong type="secondary">
              {t("spas.manage.searchFollower")}
            </Typography.Paragraph>
          </div>
        )}
      </div>
      <div style={{ display: step === STEPS.PENDING ? "block" : "none", padding: "35px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography.Paragraph strong>{t("spas.manage.received.invitation")}</Typography.Paragraph>
          <Typography.Paragraph strong style={{ color: colors.secondary_base }}>
            {pending?.length}
          </Typography.Paragraph>
        </div>
        <Typography.Paragraph strong type="secondary">
          {t("spas.manage.shareNotebook")}
        </Typography.Paragraph>
        <Space direction="vertical" size="small" style={{ width: "100%" }}>
          {pending?.map((pend, index) => (
            <Popover
              key={index}
              content={
                <div style={{ display: "flex", flexFlow: "column" }}>
                  <a
                    className={clsx(styles.link)}
                    style={{ marginBottom: "16px" }}
                    onClick={() => {
                      dispatch(answerFollow({ followId: pend?.followId, accepted: true }));
                    }}
                  >
                    {t("spas.manage.accepted")}
                  </a>
                  <a
                    className={clsx(styles.linkCancel)}
                    onClick={() => {
                      dispatch(answerFollow({ followId: pend?.followId, accepted: false }));
                    }}
                  >
                    {t("spas.manage.ignored")}
                  </a>
                </div>
              }
              placement="right"
              trigger="click"
            >
              <Card style={{ cursor: "pointer" }}>
                <Row gutter={[20, 0]} style={{ justifyContent: "space-between" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Col>
                      <Avatar src={pend?.photoUrl}>
                        {`${pend?.firstname} ${pend?.lastname}`
                          .toUpperCase()
                          .split(" ")
                          .map((i) => i.charAt(0).toUpperCase())
                          .join("")}
                      </Avatar>
                    </Col>
                    <Col>
                      <Typography.Paragraph strong>{pend?.displayName}</Typography.Paragraph>
                      <Typography.Paragraph>{moment(pend?.date).fromNow()}</Typography.Paragraph>
                    </Col>
                  </div>
                  <Col>
                    <div style={{ cursor: "pointer" }}>
                      <Glyph name="help" style={{ color: colors.interactive_03 }} />
                    </div>
                  </Col>
                </Row>
              </Card>
            </Popover>
          ))}
        </Space>
      </div>
      <div style={{ display: step === STEPS.PHONEBOOK ? "block" : "none", padding: "35px" }}>
        <PhoneBook onBack={() => setStep(STEPS.ACCEPTED)} />
      </div>
    </Modal>
  );
};

export default ManagePeopleModal;
