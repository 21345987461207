import { Button, Collapse, Space, Tag, Typography } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useRouter } from "../../../helpers/useRouter";
import useDesignTokens from "../../../hook/useDesignTokens";

// redux
import {
  filteredTickets,
  getListTicket,
  setGroupFilter,
} from "../../../services/redux/services/TicketingWS";
// import component
import Glyph from "../../Common/Glyph/Glyph";
import { TitleSource } from "../../Common/Teleporters/Title";
import Tile from "../../Common/Tile/Tile";
import CreateTickets from "./CreateTickets";
import Finish from "./Finish/Finish";
import Processing from "./Processing/Processing";
import Refuse from "./Refuse/Refuse";
// import css
import styles from "./Ticketing.module.less";
import Waiting from "./Waiting/Waiting";

// Firebase
import { logEvent } from "firebase/analytics";
import { analytics } from "../../../services/api/Firebase/Firebase";
import { isEmpty } from "ramda";

const { Panel } = Collapse;
const Ticketing = () => {
  const dispatch = useDispatch();
  const { colors, size } = useDesignTokens();
  const {
    query: { group },
  } = useRouter();

  const { i18n, t } = useTranslation();

  const [isModalVisible, setIsModalVisible] = useState(false);

  const groupFilter = useSelector((state) => state.ticketingWS.groupFilter);
  const groups = useSelector((state) => state.ticketingWS.category?.ticketGroups);
  const ticketing = useSelector(filteredTickets);
  const userData = useSelector((state) => state.userWS.userData);

  const showModal = () => {
    setIsModalVisible(true);
    logEvent(analytics, "ticketing_new_ticket");
  };

  useEffect(() => {
    !isEmpty(userData) &&
      dispatch(getListTicket({ locale: i18n.language.split("-")[0], page: 1, size: 100 }));
  }, [dispatch, i18n.language, userData]);

  useEffect(() => {
    if (!groups || !group) return;
    dispatch(setGroupFilter(groups.find((g) => g.tag === group)?.id));
  }, [dispatch, group, groups]);

  const setRef = useCallback(
    (node) => {
      const listener = (event) => {
        const heightDiv = event.target.scrollTop - (event.target.scrollHeight - 500);
        if (heightDiv === 0) {
          dispatch(getListTicket({ locale: i18n.language.split("-")[0] }));
        }
      };
      if (!node) return;
      node.addEventListener("scroll", listener);
      return () => {
        node.removeEventListener("scroll", listener);
      };
    },
    [dispatch, i18n.language],
  );

  return (
    <div>
      <TitleSource>{t("incident_reporting", { ns: "csv" })}</TitleSource>
      <section className={styles["requests"]}>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <div>
            <Tag.CheckableTag
              checked={!groupFilter}
              onChange={() => dispatch(setGroupFilter(null))}
            >
              <Space align="baseline">
                <Glyph name="receipt" /> {t("AllRequest")}
              </Space>
            </Tag.CheckableTag>
            {groups?.map((g) => (
              <Tag.CheckableTag
                key={g.id}
                checked={groupFilter === g.id}
                onChange={() => dispatch(setGroupFilter(g.id))}
              >
                {g.name[i18n.language.split("-")[0]]}
              </Tag.CheckableTag>
            ))}
          </div>
          <Button
            type="primary"
            style={{ height: 38 }}
            onClick={() =>
              dispatch(getListTicket({ locale: i18n.language.split("-")[0], page: 1, size: 100 }))
            }
            icon={<Glyph name="autorenew" />}
          >
            {t("refresh")}
          </Button>
        </div>
        <br />
        <Typography.Title level={3} style={{ marginBottom: 0 }}>
          {t("Requests")}
        </Typography.Title>
        <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
          <div style={{ display: "flex", alignItems: "top", overflow: "hidden" }}>
            <div style={{ display: "flex", alignItems: "top", height: "100%", paddingTop: 8 }}>
              <Button
                type="primary"
                className={styles["requests__btn"]}
                icon={<Glyph name="add" style={{ fontSize: size.xxxxl, marginLeft: "-7px" }} />}
                onClick={showModal}
              >
                <Typography.Text
                  strong
                  style={{
                    color: "white",
                    textTransform: "uppercase",
                    fontSize: size.m,
                  }}
                >
                  {t("NewTicket")}
                </Typography.Text>
              </Button>
              <div className={styles["requests__separator"]} style={{ marginTop: 8 }} />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "top",
                padding: 8,
                overflowX: "auto",
              }}
            >
              <div style={{ display: "flex", gap: 8 }}>
                <Tile className={styles["requests__tile"]} style={{ cursor: "default" }}>
                  <Glyph
                    name="hourglass_full"
                    style={{ fontSize: size.xxl, color: colors.pending_light }}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography.Text strong>{t("New")}</Typography.Text>
                    <Typography.Text
                      strong
                      style={{ fontSize: "30px", color: colors.pending_light }}
                    >
                      {ticketing?.items?.filter((i) => i?.statusId === 0).length}
                    </Typography.Text>
                  </div>
                </Tile>

                <Tile className={styles["requests__tile"]} style={{ cursor: "default" }}>
                  <Glyph
                    name="thumb_up_alt"
                    style={{ fontSize: size.xxl, color: colors.success_light }}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography.Text strong>{t("TakenIntoAccount")}</Typography.Text>
                    <Typography.Text
                      strong
                      style={{ fontSize: "30px", color: colors.success_light }}
                    >
                      {ticketing?.items?.filter((i) => i?.statusId === 1).length}
                    </Typography.Text>
                  </div>
                </Tile>

                <Tile className={styles["requests__tile"]} style={{ cursor: "default" }}>
                  <Glyph
                    name="more_horiz"
                    style={{ fontSize: size.xxl, color: colors.highlight_light }}
                  />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography.Text strong>{t("Suspended")}</Typography.Text>
                    <Typography.Text
                      strong
                      style={{ fontSize: "30px", color: colors.highlight_light }}
                    >
                      {ticketing?.items?.filter((i) => i?.statusId === 2).length}
                    </Typography.Text>
                  </div>
                </Tile>

                <Tile className={styles["requests__tile"]} style={{ cursor: "default" }}>
                  <Glyph name="cancel" style={{ fontSize: size.xxl, color: colors.error_light }} />
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "baseline",
                    }}
                  >
                    <Typography.Text strong>{t("closed")}</Typography.Text>
                    <Typography.Text strong style={{ fontSize: "30px", color: colors.error_light }}>
                      {
                        ticketing?.items?.filter((i) => i?.statusId === 3 || i.statusId === 4)
                          .length
                      }
                    </Typography.Text>
                  </div>
                </Tile>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Collapse
        defaultActiveKey={["1"]}
        accordion={true}
        bordered={true}
        expandIconPosition="right"
        style={{ marginTop: "35px" }}
        className={styles["collapse--tickets"]}
      >
        <Panel header={t("New")} key="1">
          <div ref={setRef} style={{ maxHeight: "500px", overflow: "auto", padding: "5px" }}>
            {ticketing?.items?.filter((i) => i?.statusId === 0).length !== 0 ? (
              <Waiting />
            ) : (
              t("NoWaiting")
            )}
          </div>
        </Panel>
        <Panel header={t("TakenIntoAccount")} key="2">
          <div ref={setRef} style={{ maxHeight: "500px", overflow: "auto", padding: "5px" }}>
            {ticketing?.items?.filter((i) => i?.statusId === 1).length !== 0 ? (
              <Processing />
            ) : (
              t("NoTaken")
            )}
          </div>
        </Panel>
        <Panel header={t("Suspended")} key="3">
          <div ref={setRef} style={{ maxHeight: "500px", overflow: "auto", padding: "5px" }}>
            {ticketing?.items?.filter((i) => i?.statusId === 2).length !== 0 ? (
              <Finish />
            ) : (
              t("NoSuspended")
            )}
          </div>
        </Panel>
        <Panel header={t("closed")} key="4" style={{ borderRadius: 0 }}>
          <div ref={setRef} style={{ maxHeight: "500px", overflow: "auto", padding: "5px" }}>
            {ticketing?.items?.filter((i) => i?.statusId === 3 || i.statusId === 4).length !== 0 ? (
              <Refuse />
            ) : (
              t("NoClose")
            )}
          </div>
        </Panel>
      </Collapse>

      <CreateTickets isVisible={isModalVisible} onOk={setIsModalVisible} />
    </div>
  );
};

export default Ticketing;
