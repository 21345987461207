import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  //placesInfos: {},
  //placesData: {},
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

export const getPlacesInfos = thunk(
  "agora/getPlacesInfos",
  async ({ viewId, locale }, { getState }) => {
    const state = getState();
    const placesInfos = await DWMConnector.getPlacesInfos(viewId, locale);
    return { placesInfos, viewId };
  },
);

EXTRA_REDUCERS[getPlacesInfos.fulfilled] = (state, action) => {
  state[action.payload.viewId] = state[action.payload.viewId] || {};
  state[action.payload.viewId].placesInfos = action.payload.placesInfos;
};

export const getPlacesData = thunk(
  "agora/getPlacesData",
  async ({ viewId, contentId, campusId, date, extraType, extraId }, { getState }) => {
    const placesData = await DWMConnector.getPlacesData(
      viewId,
      contentId,
      campusId,
      date,
      extraType,
      extraId,
    );
    return { placesData, extraType };
  },
);

EXTRA_REDUCERS[getPlacesData.fulfilled] = (state, action) => {
  state[action.payload.extraType] = state[action.payload.extraType] || {};
  state[action.payload.extraType].placesData = action.payload.placesData;
};

export const postPlacesData = thunk(
  "agora/postPlacesData",
  async (
    { viewId, contentId, campusId, date, extraType, extraId, zoneId, slotId, party },
    { getState },
  ) => {
    const placesData = await DWMConnector.postPlacesData(
      viewId,
      contentId,
      campusId,
      date,
      extraType,
      extraId,
      zoneId,
      slotId,
      party,
    );
    return placesData;
  },
);

export const deleteBooking = thunk(
  "agora/deleteBooking",
  async ({ viewId, contentId, campusId, extraType, extraId, action, data }, { getState }) => {
    const deleteBooking = await DWMConnector.deleteBooking(
      viewId,
      contentId,
      campusId,
      extraType,
      extraId,
      action,
      data,
    );
    return deleteBooking;
  },
);
/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const agoraSlice = createSlice({
  name: "agora",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default agoraSlice.reducer;
