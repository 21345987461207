import { Badge, Button, Card, Col, DatePicker, Form, Row, Select, Spin, Typography } from "antd";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import Tile from "../../Common/Tile/Tile";
import styles from "./Places.module.less";
import moment from "moment";
import { useEffect, useState } from "react";
import { isNil } from "ramda";
import { getPlacesData, postPlacesData } from "../../../services/redux/services/PlacesInfosWS";
import { useDispatch, useSelector } from "react-redux";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";
import { LoadingOutlined } from "@ant-design/icons";
import clsx from "clsx";
import DetailsBooking from "./DetailsBooking";
import ErrorBooking from "./ErrorBooking";
import SuccessBooking from "./SuccessBooking";
import { useTranslation } from "react-i18next";

export const STEPS_BOOKING = {
  BOOKING: "BOOKING",
  DETAILS: "DETAILS",
  SUCCESS: "SUCCESS",
  ERROR: "ERROR",
};

let time;
const Booking = ({ menu, view, placeInfo, data, setStepPlaces, setInfoBooking, infoBooking }) => {
  const [form] = Form.useForm();
  const { colors, size } = useDesignTokens();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);
  const placeData = useSelector(
    (state) =>
      state.placesInfosWS[placeInfo?.extras?.find((e) => e?.type === "restaurant-slots")?.type]
        ?.placesData?.data,
  );
  const isLoading = useSelector(createLoadingSelector(["agora/getPlacesData"]));

  const [seats, setSeats] = useState(1);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [activeIndex, setActiveIndex] = useState(null);
  const [step, setStep] = useState(STEPS_BOOKING.BOOKING);
  const [status, setStatus] = useState(null);
  const [dateBooking, setDateBooking] = useState(moment().toISOString());

  useEffect(() => {
    if (isNil(placeInfo?.extras)) return;
    dispatch(
      getPlacesData({
        viewId: view?.id,
        campusId: mapData?.id,
        date: dateBooking,
        extraType: menu?.type,
        extraId: data?.id,
        contentId: placeInfo?.id,
      }),
    );
  }, [
    data?.id,
    dateBooking,
    dispatch,
    form,
    mapData?.id,
    menu?.type,
    placeInfo?.extras,
    placeInfo?.id,
    view?.id,
  ]);

  const onSubmit = async (values) => {
    time = setTimeout(() => {
      dispatch(
        postPlacesData({
          viewId: view?.id,
          campusId: mapData?.id,
          date: moment(values.date).format("YYYY-MM-DD"),
          extraType: menu?.type,
          extraId: data?.id,
          contentId: placeInfo?.id,
          zoneId: values.zoneId,
          slotId: selectedSlot,
          party: seats,
        }),
      )
        .unwrap()
        .then((data) => {
          setStep(STEPS_BOOKING.SUCCESS);
          setStatus("success");
          setInfoBooking(data?.data);
          dispatch(
            getPlacesData({
              viewId: view?.id,
              campusId: mapData?.id,
              date: moment().toISOString(),
              extraType: placeInfo?.extras?.find((e) => e?.type === "restaurant-bookings")?.type,
              extraId: placeInfo?.extras?.find((e) => e?.type === "restaurant-bookings")?.data[0]
                ?.id,
              contentId: placeInfo?.id,
            }),
          );
        })
        .catch((error) => {
          logger.error(error);
          setStep(STEPS_BOOKING.ERROR);
          setStatus("error");
        });
    }, 6000);
  };

  return (
    <div style={{ width: "100%" }}>
      <Spin spinning={isLoading} indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}>
        {placeData && step === STEPS_BOOKING.BOOKING && (
          <Form
            form={form}
            initialValues={{
              date: moment(),
              zoneId: placeData[0]?.zoneId,
            }}
            onValuesChange={(changedValues, values) => {
              const selectedValue = values["zoneId"];
              const index = placeData.indexOf(selectedValue);
              if (changedValues.zoneId) {
                setSelectedIndex(index);
              }
              if (changedValues.date) {
                setDateBooking(moment(values.date).toISOString());
                setActiveIndex(null);
              }
            }}
            onFinish={onSubmit}
          >
            <Typography.Text strong>{t("Places.Booking.Details.Title")}</Typography.Text>
            <Card style={{ marginTop: "16px" }}>
              <Row>
                <Col flex={"auto"}>
                  <Typography.Text strong>
                    <Glyph
                      name="event"
                      style={{ color: colors.secondary_base, marginRight: "8px" }}
                    />
                    {t("Places.Booking.Details.Date")}
                  </Typography.Text>
                </Col>
                <Col>
                  <Form.Item noStyle name={["date"]}>
                    <DatePicker
                      style={{ margin: -8, fontSize: size.s }}
                      format="DD MMM YYYY"
                      disabledDate={(date) => date.isBefore(moment(), "date")}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Card>
            <Card style={{ marginTop: "8px" }}>
              <Row>
                <Col flex={"auto"}>
                  <Typography.Text strong>
                    <Glyph
                      name="event"
                      style={{ color: colors.secondary_base, marginRight: "8px" }}
                    />
                    {t("numberPeople")}
                  </Typography.Text>
                </Col>
                <Col>
                  <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <Button
                      shape="circle"
                      size="small"
                      style={{ border: "none" }}
                      icon={<Glyph name="remove_circle" />}
                      onClick={() => {
                        if (seats !== 1) setSeats(seats - 1);
                      }}
                    ></Button>
                    <Typography.Text>{seats}</Typography.Text>
                    <Button
                      shape="circle"
                      size="small"
                      style={{ border: "none" }}
                      icon={<Glyph name="add_circle" />}
                      onClick={() => {
                        setSeats(seats + 1);
                      }}
                    ></Button>
                  </div>
                </Col>
              </Row>
            </Card>

            <Card style={{ marginTop: "8px" }}>
              <div
                style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}
              >
                <Typography.Text strong>
                  <Glyph
                    name="schedule"
                    style={{ color: colors.secondary_base, marginRight: "8px" }}
                  />{" "}
                  {t("Places.Booking.Details.Hours")}
                </Typography.Text>

                <div
                  style={{
                    justifyContent: "space-between",
                    display: placeData?.length > 1 ? "block" : "none",
                  }}
                >
                  <Form.Item noStyle name={["zoneId"]}>
                    <Select
                      size="small"
                      bordered={false}
                      options={placeData.map((p) => ({
                        label: p.zoneName,
                        value: p.zoneId,
                      }))}
                    ></Select>
                  </Form.Item>
                </div>
              </div>

              <Row gutter={[10, 10]} style={{ marginTop: "16px" }}>
                {placeData[selectedIndex]?.slots?.map((slot, index) => (
                  <Col span={12} key={index}>
                    <Tile
                      onClick={() => {
                        if (
                          slot?.availability >= seats &&
                          (slot?.endHour > moment(dateBooking).format("HH:mm:ss") ||
                            moment().format("DD MM YYYY") !==
                              moment(dateBooking).format("DD MM YYYY"))
                        ) {
                          setActiveIndex(activeIndex === index ? null : index);
                          setSelectedSlot(slot.slotId);
                        }
                      }}
                      className={clsx(styles.TileSlots, {
                        [styles.activeSlots]: activeIndex === index,
                        [styles.desactiveSlots]:
                          slot?.availability < seats ||
                          (slot?.endHour < moment(dateBooking).format("HH:mm:ss") &&
                            moment().format("DD MM YYYY") ===
                              moment(dateBooking).format("DD MM YYYY")),
                      })}
                      style={{
                        flexFlow: "column",
                        alignItems: "baseline",
                        padding: "12px",
                        height: "auto",
                        margin: "0",
                        cursor:
                          slot?.availability < seats ||
                          (slot?.endHour < moment(dateBooking).format("HH:mm:ss") &&
                            moment().format("DD MM YYYY") ===
                              moment(dateBooking).format("DD MM YYYY"))
                            ? "default"
                            : "pointer",
                      }}
                    >
                      <div>
                        <Badge
                          status={
                            (slot?.availability / slot?.capacity) * 100 < 30 &&
                            (slot?.availability / slot?.capacity) * 100 > 10
                              ? "warning"
                              : (slot?.availability / slot?.capacity) * 100 < 10
                              ? "error"
                              : "success"
                          }
                          className={styles["badges"]}
                        />
                        <Typography.Text>
                          {slot?.startHour.split(":").slice(0, 2).join(":")}
                        </Typography.Text>
                      </div>
                      <Typography.Text style={{ fontSize: size.s, marginTop: "8px" }}>
                        {slot?.availability} {t("Places.Booking.Details.available")}
                      </Typography.Text>
                    </Tile>
                  </Col>
                ))}
              </Row>
            </Card>
            <Button
              disabled={isNil(activeIndex)}
              type="primary"
              icon={<Glyph name="check" />}
              style={{ width: "100%", marginTop: "16px" }}
              onClick={() => {
                setStep(STEPS_BOOKING.DETAILS);
                form.submit();
              }}
            >
              {t("Book")}
            </Button>
          </Form>
        )}
        {step === STEPS_BOOKING.DETAILS && (
          <DetailsBooking
            date={form.getFieldValue(["date"])}
            seats={seats}
            zoneId={form.getFieldValue(["zoneId"])}
            selectedSlot={selectedSlot}
            status={status}
            setStep={setStep}
            placeInfo={placeInfo}
            onCancel={() => {
              clearTimeout(time);
            }}
          />
        )}

        {step === STEPS_BOOKING.ERROR && <ErrorBooking setStep={setStep} />}
        {step === STEPS_BOOKING.SUCCESS && (
          <SuccessBooking
            setStep={setStepPlaces}
            date={form.getFieldValue(["date"])}
            seats={seats}
            zoneId={form.getFieldValue(["zoneId"])}
            selectedSlot={selectedSlot}
            placeInfo={placeInfo}
            infoBooking={infoBooking}
            setInfoBooking={setInfoBooking}
          />
        )}
      </Spin>
    </div>
  );
};

export default Booking;
