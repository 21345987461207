import { Card, Typography } from "antd";
import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import Glyph from "../../../../Common/Glyph/Glyph";

const TicketTabGeneralForm = ({ data }) => {
  const { colors, symbols, shadows } = useDesignTokens();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];

  return (
    <div
      style={{
        backgroundColor: colors.light_background,
        borderRadius: symbols.base_shape.radius,
        padding: "8px 16px 16px 16px",
        marginBottom: 16,
        marginTop: 16,
      }}
    >
      <Typography.Text strong style={{ fontSize: 20 }}>
        {t("ticketingv2.Form")}
      </Typography.Text>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 28,
          alignItems: "start",
          width: "100%",
          marginTop: 16,
        }}
      >
        {data.form?.items
          ?.filter((i) => i.type !== "files" && i.type !== "pictures")
          ?.map((f, index) => (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
                gap: f.type === "radio" || f.type === "checkbox" ? 4 : 0,
                backgroundColor:
                  f.type === "radio" || f.type === "checkbox" ? "white" : "transparent",
                padding: f.type === "radio" || f.type === "checkbox" ? 12 : 0,
                borderRadius: symbols.base_shape.radius,
                boxShadow:
                  f.type === "radio" || f.type === "checkbox"
                    ? `${shadows.x}px ${shadows.y}px ${shadows.blur}px ${shadows.color}`
                    : "none",
              }}
            >
              <div style={{ display: "flex", flexDirection: "column", marginBottom: 8 }}>
                <Typography.Text strong style={{ fontSize: 14 }}>
                  {f.title[locale] || Object.values(f.title)[0]}
                </Typography.Text>
                <Typography.Text type="secondary" style={{ fontSize: 12, lineHeight: 1 }}>
                  {f.caption ? f.caption[locale] || Object.values(f.caption)[0] : ""}
                </Typography.Text>
              </div>

              {(f.type === "textarea" || f.type === "text") && (
                <Card bodyStyle={{ padding: "8px 12px" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                      {f.answer || t("ticketingv2.NoAnswer")}
                    </Typography.Text>
                  </div>
                </Card>
              )}

              {(f.type === "radio" || f.type === "checkbox") && (
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                    alignItems: "start",
                    gap: 8,
                  }}
                >
                  {!!f.answer?.length ? (
                    <div
                      style={{ display: "flex", flexDirection: "column", gap: 8, width: "100%" }}
                    >
                      {f.value
                        .filter((v) => f.answer?.includes(v.value))
                        .map((input, index) => (
                          <div
                            key={index}
                            style={{
                              padding: 8,
                              backgroundColor: colors.light_background,
                              borderRadius: symbols.base_shape.radius,
                              width: "100%",
                              display: "flex",
                              flexGrow: 1,
                              justifyContent: "space-between",
                              border: "none",
                              cursor: f.answer ? "default" : "pointer",
                            }}
                          >
                            <Typography.Text strong style={{ fontSize: 14 }}>
                              {input.title[locale]}
                            </Typography.Text>
                            <Glyph
                              name={f.type === "radio" ? "radio_button_checked" : "check_box"}
                              style={{
                                fontSize: 20,
                                color: colors.secondary_base,
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  ) : (
                    <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                      {t("ticketingv2.NoAnswer")}
                    </Typography.Text>
                  )}
                </div>
              )}

              {(f.type === "datepicker" || f.type === "delivery") && (
                <>
                  {f.type === "delivery" && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                        width: "100%",
                        marginBottom: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          padding: 12,
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: symbols.base_shape.radius,
                          border: `2px solid ${colors.grey_40}`,
                        }}
                      >
                        <Typography.Text>
                          La commande peut être passée{" "}
                          <b>{f.orderBefore} heures avant la livraison</b>.
                        </Typography.Text>
                        <Typography.Text>
                          La commande peut être annulée jusqu'à{" "}
                          <b>
                            {f.cancelBefore} heures{" "}
                            {f.cancelBefore === "before"
                              ? "avant la livraison"
                              : "après la commande"}
                          </b>
                          .
                        </Typography.Text>
                        <Typography.Text>
                          Les week-ends et jours fériés{" "}
                          <b>{f.countRules ? "sont" : "ne sont pas"} comptabilisés</b> dans ces
                          délais.
                        </Typography.Text>
                      </div>
                    </div>
                  )}
                  <Card bodyStyle={{ padding: "8px 12px" }}>
                    <div
                      style={{
                        display: "flex",
                        gap: 8,
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        <Glyph
                          name="schedule"
                          style={{ color: colors.secondary_base, fontSize: 24 }}
                        />
                        <Typography.Text strong style={{ fontSize: 14 }}>
                          {t("ticketingv2.ChooseADate")}
                          {f.hasTime ? t("ticketingv2._andATime") : ""}
                        </Typography.Text>
                      </div>
                      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        {f.answer ? (
                          <Typography.Text strong style={{ fontSize: 14 }}>
                            {moment(f.answer).format(locale === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY")}
                          </Typography.Text>
                        ) : (
                          <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                            {t("ticketingv2.NoDateSelected")}
                          </Typography.Text>
                        )}
                        {f.hasTime &&
                          (f.answer ? (
                            <Typography.Text strong style={{ fontSize: 14 }}>
                              {moment(f.answer).format("HH:mm")}
                            </Typography.Text>
                          ) : (
                            <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                              {t("ticketingv2.NoTimeSelected")}
                            </Typography.Text>
                          ))}
                      </div>
                    </div>
                  </Card>
                </>
              )}

              {f.type === "inputNumber" && (
                <div
                  style={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                    alignItems: "start",
                  }}
                >
                  {!!f.answer?.quantity && f.price && (
                    <div style={{ position: "absolute", top: -28, right: 0, textAlign: "right" }}>
                      <Typography.Text
                        strong
                        style={{ fontSize: 16, color: colors.secondary_base }}
                      >
                        {f.answer?.quantity * f.price}
                        {` ${f.devise === "EUR" ? "€" : f.devise === "USD" ? "$" : "£"}`}
                      </Typography.Text>
                    </div>
                  )}
                  <Card style={{ width: "100%" }} bodyStyle={{}}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        <Glyph name="place" style={{ color: colors.secondary_base }} />
                        <Typography.Text strong style={{ fontSize: 14 }}>
                          {f.productName || f.description}
                        </Typography.Text>
                      </div>
                      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        <Typography.Text strong style={{ fontSize: 14 }}>
                          {f.answer?.quantity || 0}
                        </Typography.Text>
                      </div>
                    </div>
                  </Card>
                  <Typography.Text type="secondary" style={{ fontSize: 14 }}>
                    {f.productName && f.description}
                  </Typography.Text>
                </div>
              )}
            </div>
          ))}
      </div>
    </div>
  );
};

export default TicketTabGeneralForm;
