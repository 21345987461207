import { createAsyncThunk as thunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { evolve, map } from "ramda";
import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  selectedSectorId: null,
  occupancyRates: null,
  byStatus: null,
  byStatusDate: null,
  searchSpaces: [],
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

export const listRequests = thunk(
  "spaceServiceAdmin/listRequests",
  async (
    { sectorId, date = new Date().toISOString(), status, page, size, search, sortDir, sortField },
    { getState },
  ) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    return {
      date,
      byStatus: await DWMConnector.listSpaceServiceRequests({
        siteId,
        sectorId,
        date,
        status,
        page,
        size,
        search,
        sortDir,
        sortField,
      }),
    };
  },
);
EXTRA_REDUCERS[listRequests.fulfilled] = (state, action) => {
  state.byStatus = Object.assign({}, state.byStatus || {}, action.payload.byStatus);
  state.byStatusDate = action.payload.date;
};

export const searchSpace = thunk("spaceServiceAdmin/searchSpace", async (data, { getState }) => {
  const state = getState();
  const siteId = state.userWS.userData?.campus?.[0]?.id;
  return DWMConnector.searchSpaceServiceSpace({ siteId, ...data });
});
EXTRA_REDUCERS[searchSpace.fulfilled] = (state, action) => {
  state.searchSpaces = action.payload;
};

export const checkConflicts = thunk(
  "spaceServiceAdmin/checkConflicts",
  async ({ sectorId, spaceId, startDate, endDate, period, recurrence }, { getState }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    return DWMConnector.checkSpaceServiceConflicts({
      siteId,
      sectorId,
      spaceId,
      startDate,
      endDate,
      period,
      recurrence,
    });
  },
);

export const assignSpace = thunk(
  "spaceServiceAdmin/assignSpace",
  async ({ sectorId, slotId, spaceId, comment, allOccurences, forceAssign }, { getState }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    return DWMConnector.assignSpaceServiceSpace(slotId, {
      siteId,
      sectorId,
      spaceId,
      comment,
      allOccurences,
      forceAssign,
    });
  },
);
export const assignAutomatically = thunk(
  "spaceServiceAdmin/assignAutomatically",
  async (data, { getState }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    return DWMConnector.assignSpaceServiceSpaceAuto({ ...data, siteId });
  },
);

export const createRequest = thunk(
  "spaceServiceAdmin/createRequest",
  async (data, { getState }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    return DWMConnector.createSpaceServiceRequest({ ...data, siteId });
  },
);
export const createRequestsAndAutoPlace = thunk(
  "spaceServiceAdmin/createRequestsAndAutoPlace",
  async (data, { getState }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    return DWMConnector.createSpaceServiceRequestAuto({ ...data, siteId });
  },
);

export const declineSlots = thunk("spaceServiceAdmin/declineSlots", async (data, { getState }) => {
  const state = getState();
  const siteId = state.userWS.userData?.campus?.[0]?.id;
  await DWMConnector.declineSpasSlots({ ...data, siteId });
});
export const pendingSlots = thunk("spaceServiceAdmin/pendingSlots", async (data, { getState }) => {
  const state = getState();
  const siteId = state.userWS.userData?.campus?.[0]?.id;
  await DWMConnector.pendingSpasSlots({ ...data, siteId });
});

export const getOccupancyRates = thunk(
  "spaceServiceAdmin/getOccupancyRates",
  async ({ sectorId, startDate, endDate }, { getState }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    return DWMConnector.getSpaceServiceOccupancyRates({ siteId, sectorId, startDate, endDate });
  },
);
EXTRA_REDUCERS[getOccupancyRates.fulfilled] = (state, action) => {
  state.occupancyRates = action.payload;
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const spaceServiceAdminSlice = createSlice({
  name: "spaceServiceAdmin",
  initialState: INITIAL_STATE,
  reducers: {
    selectSector(state, action) {
      state.selectedSectorId = action.payload;
    },
  },
  extraReducers: (builder) => {
    for (const [key, handler] of Object.entries(EXTRA_REDUCERS)) {
      builder.addCase(key, handler);
    }
  },
});

export const byStatusWithResources = createSelector(
  (state) => state.clientsWS.campus?.mapData,
  (state) => state.spaceServiceAdminWS.byStatus,
  (mapData, byStatus) => {
    logger.log("Processing selector byStatusWithResources");
    if (!mapData || !byStatus) return null;
    return evolve({
      APPROVED: {
        items: map((slot) => ({
          ...slot,
          space: mapData.workplaces.find((w) => w.id === slot.spaceId),
        })),
      },
    })(byStatus);
  },
);

export const searchSpacesWithResources = createSelector(
  (state) => state.clientsWS.campus?.mapData,
  (state) => state.spaceServiceAdminWS.searchSpaces,
  (mapData, searchSpaces) => {
    logger.log("Processing selector byStatusWithResources");
    if (!mapData || !searchSpaces) return [];
    return searchSpaces.map((room) => {
      const roomData = mapData.resources.find((r) => r.id === room.id);
      return {
        ...roomData,
        category: mapData.categories.find((c) => c.id === roomData.categoryId),
        workplaces: room.workplaces.map((workplace) => {
          const wpData = mapData.workplaces.find((w) => w.id === workplace.id);
          return {
            ...wpData,
            category: mapData.categories.find((c) => c.id === wpData.categoryId),
            ...workplace,
          };
        }),
      };
    });
  },
);

export const { selectSector } = spaceServiceAdminSlice.actions;
export default spaceServiceAdminSlice.reducer;
