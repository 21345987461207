import { Typography } from "antd";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import FloorSelector from "../../../Common/Map/Mapbox/FloorSelector";
import {
  useMapboxInstance,
  withMapboxInstance,
} from "../../../Common/Map/Mapbox/useMapboxInstance";
import { useTranslation } from "react-i18next";

const CreateStepWorkplaceMap = ({ siteId, onMapClick, roomId }) => {
  const mapData = useSelector((state) => state.mapDataWS[siteId]?.mapData);
  const { t } = useTranslation();

  const { map, registerMap, mapReady, centerOnPlace } = useMapboxInstance();

  useEffect(() => {
    if (roomId && mapReady) {
      centerOnPlace(roomId);
    }
  }, [centerOnPlace, roomId, mapReady]);

  useEffect(() => {
    if (!mapReady || !map || !mapData) return;

    const handler = async ({ details: { id } }) => {
      const room = mapData.resources.find((i) => i.map?.swizi?.placeId === id);
      if (room) {
        const mapClick = await onMapClick(room);
        if (mapClick) centerOnPlace(id);
      }
    };

    map.on("clickOnRoom", handler);
    return () => {
      map.off("clickOnRoom", handler);
    };
  }, [centerOnPlace, map, mapData, mapReady, onMapClick]);

  return (
    <>
      <div ref={registerMap} id="workplace_map" style={{ width: "100%", height: "100%" }} />
      <div style={{ position: "absolute", top: 0, left: 0, padding: 10 }}>
        <FloorSelector />
      </div>
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "white",
          opacity: mapReady ? 0 : 1,
          transition: mapReady ? "opacity .3s ease-in-out" : "none",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography.Title level={3}>{t("ticketingv2.Loading")}</Typography.Title>
      </div>
    </>
  );
};

export default withMapboxInstance()(CreateStepWorkplaceMap);
