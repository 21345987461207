import { Dropdown, Typography } from "antd";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import ChipCard from "../../ChipCard/ChipCard";
import Glyph from "../../Glyph/Glyph";
import styles from "./Mapbox.module.less";

const SiteSelector = ({ value, onChange, filter, ...props }) => {
  const sites = useSelector((state) => state.userWS.userData?.campus || []);

  const items = useMemo(() => {
    return sites.filter(filter).map((i) => ({ label: i.title, key: i.id }));
  }, [filter, sites]);

  const _value = useMemo(() => {
    return sites.find((i) => i.id === value);
  }, [sites, value]);

  return (
    <Dropdown
      {...props}
      className={styles["floor-selector"]}
      trigger={["click"]}
      menu={{ items, onClick: ({ key }) => onChange(key) }}
    >
      <ChipCard
        style={{ minWidth: 120, cursor: "pointer" }}
        icon={<Glyph className={"secondary"} name={"business"} />}
      >
        <Typography.Text strong>{_value?.title || "No selected"}</Typography.Text>
        <Glyph name={"arrow_drop_down"} />
      </ChipCard>
    </Dropdown>
  );
};

export default SiteSelector;
