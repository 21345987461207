import { useState } from "react";
import { Card, Typography, Button, Form, Grid } from "antd";
import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

// import component
import Glyph from "../../Common/Glyph/Glyph";
import FilterOptions from "../../Common/FilterOptions/FilterOptions";

// import css
import "./Fastbooking.module.less";

// import redux
import { getRoomFastbooking, removeFilter } from "../../../services/redux/services/FastbookingWS";
import { useTranslation } from "react-i18next";
import { useMapLayer } from "../../Common/Map/useMap";
import useDesignTokens from "../../../hook/useDesignTokens";

const mapOptions = {
  padding: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 358,
  },
};

const Filter = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  useMapLayer(mapOptions);
  const screens = Grid.useBreakpoint();
  const { size } = useDesignTokens();

  const { i18n, t } = useTranslation();

  const filters = useSelector((state) => state.fastbookingWS.filters);

  const [seats, setSeats] = useState(filters.seats ? filters.seats : 1);

  return (
    <Card
      title={
        <>
          <Typography.Title level={5}>
            <NavLink to="/fastBookingV2/map">
              <Glyph
                name="arrow_back_ios"
                style={{
                  verticalAlign: "-2px",
                  fontSize: size.xxl,
                  color: "black",
                  marginRight: "8px",
                }}
              />
            </NavLink>
            {t("Filters")}
          </Typography.Title>
        </>
      }
      actions={[
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 8,
            padding: 8,
          }}
        >
          <Button
            ghost
            style={{ width: 160 }}
            onClick={() => {
              dispatch(removeFilter());
              setSeats(1);
              form.setFieldsValue({ endTime: moment().add(15, "minutes") });
              form.setFieldsValue({ startTime: moment() });
            }}
          >
            {t("Erase")}
          </Button>
          <Link to="/fastBookingV2/map" style={{ width: "inherit" }}>
            <Button
              style={{ width: 160 }}
              type="primary"
              onClick={() =>
                dispatch(
                  getRoomFastbooking({
                    locale: i18n.language.split("-")[0],
                    startDate: moment().toISOString(),
                  }),
                )
              }
            >
              {t("Apply")}
            </Button>
          </Link>
        </div>,
      ]}
      style={{
        width: screens.xl ? "35%" : 350,
        minWidth: screens.xl ? "35%" : 350,
        marginLeft: 8,
        height: "fit-content",
      }}
    >
      <div style={{ overflow: "auto", height: "fit-content" }}>
        <FilterOptions
          form={form}
          options={false}
          details={true}
          equipments={true}
          services={true}
          setSeats={setSeats}
          seats={seats}
        />
      </div>
    </Card>
  );
};

export default Filter;
