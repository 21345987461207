import { createAsyncThunk as thunk, createSlice } from "@reduxjs/toolkit";

import DWMConnector from "../../api/DWMConnector";

const INITIAL_STATE = {
  accessToken: "",
  contacts: {},
  unreadCount: 0,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

///////////////////////// INSTANTMESSAGING ///////////////////////////////
export const getSendbirdAccessToken = thunk("instantMessaging/getSendbirdAccessToken", async () => {
  const accessToken = await DWMConnector.getSendbirdAccessToken();
  return accessToken;
});

EXTRA_REDUCERS[getSendbirdAccessToken.fulfilled] = (state, action) => {
  state.accessToken = action.payload;
};

export const getSendbirdUnreadCount = thunk("instantMessaging/getSendbirdUnreadCount", async () => {
  const unreadCount = await DWMConnector.getSendbirdUnreadCount();
  return unreadCount;
});

EXTRA_REDUCERS[getSendbirdUnreadCount.fulfilled] = (state, action) => {
  state.unreadCount = action.payload.unreadCount;
};

export const getContactsUsingChat = thunk(
  "instantMessaging/getContactsUsingChat",
  async ({ siteId, search, page, size, id, groupId }, { getState }) => {
    const contacts = await DWMConnector.getContactsUsingChat(
      siteId,
      search,
      page,
      size,
      id,
      groupId,
    );
    return contacts;
  },
);

EXTRA_REDUCERS[getContactsUsingChat.fulfilled] = (state, action) => {
  if (action.payload.currentPage === 1) {
    state.contacts = action.payload;
  } else {
    if (state.contacts.currentPage !== action.payload.currentPage) {
      state.contacts = {
        ...state.contacts,
        items: [...state.contacts.items, ...action.payload.items],
        currentPage: action.payload.currentPage,
        nextPage: action.payload.nextPage,
        previousPage: action.payload.previousPage,
        totalPages: action.payload.totalPages,
      };
    }
  }
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const instantMessagingSlice = createSlice({
  name: "instantMessaging",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: EXTRA_REDUCERS,
});

export default instantMessagingSlice.reducer;
