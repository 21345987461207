import { Card, Grid, Space, Typography } from "antd";
import Tile from "../../Common/Tile/Tile";
import Glyph from "../../Common/Glyph/Glyph";
import useDesignTokens from "../../../hook/useDesignTokens";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { isEmpty } from "ramda";
import { ReactComponent as TraficPictures } from "../../../assets/svg/trafic.svg";
import { useTranslation } from "react-i18next";

const Trafic = () => {
  const { colors, size } = useDesignTokens();
  const { t } = useTranslation();
  const screens = Grid.useBreakpoint();

  const listLines = useSelector((state) => state.transportWS.listLines);

  return (
    <Card
      style={{
        width: screens.xl ? "35%" : 350,
        minWidth: screens.xl ? "35%" : 350,
        height: "fit-content",
        overflow: "auto",
        maxHeight: "100%",
      }}
      headStyle={{ minHeight: 90, maxHeight: 90 }}
      title={
        <div style={{ display: "flex" }}>
          <NavLink
            to="/transport/stop"
            style={{
              backgroundColor: colors?.grey_20,
              height: "30px",
              width: "30px",
              borderRadius: "100px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginRight: "8px",
            }}
          >
            <Glyph
              name="arrow_back_ios"
              style={{
                verticalAlign: "-2px",
                fontSize: size.xl,
                color: "black",
              }}
            />
          </NavLink>
          <div>
            <Typography.Title level={5}>{t("traffic_info")}</Typography.Title>
            <Typography.Text strong type="secondary">
              {listLines?.stopSchedules[0]?.stopName}
            </Typography.Text>
          </div>
        </div>
      }
    >
      <div
        style={{
          overflowY: listLines?.disruptions?.length < 0 ? "scroll" : "initial",
          height: "fit-content",
          paddingBottom: "15px",
        }}
      >
        {!isEmpty(listLines?.disruptions) ? (
          <Space style={{ width: "100%", paddingBottom: "15px" }} direction="vertical" size="small">
            {listLines?.disruptions?.map((disruptions, index) => (
              <Tile
                key={index}
                style={{
                  margin: "0",
                  height: "auto",
                  padding: "10px 20px",
                  cursor: "default",
                  justifyContent: "flex-start",
                }}
              >
                <Glyph
                  name="warning"
                  style={{ marginRight: "8px", fontSize: "30px", color: colors?.error_light }}
                />
                <div
                  style={{
                    display: "flex",
                    flexFlow: "column",
                    marginBottom: "8px",
                    marginLeft: "16px",
                  }}
                >
                  <Typography.Text strong>{disruptions?.category}</Typography.Text>
                  <Typography.Text>{disruptions?.message?.title}</Typography.Text>
                </div>
              </Tile>
            ))}
          </Space>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexFlow: "column",
              alignItems: "center",
            }}
          >
            <TraficPictures />
            <Typography.Text strong type="secondary" style={{ marginTop: "16px" }}>
              {t("transport_no_alerts")}
            </Typography.Text>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Trafic;
