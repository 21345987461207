import { Button, Space, Typography } from "antd";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import useDesignTokens from "../../../hook/useDesignTokens";
import { getToday, todayWithSitesSelector } from "../../../services/redux/services/SpaceService";
import Glyph from "../../Common/Glyph/Glyph";
import Tile from "../../Common/Tile/Tile";
import { DAY_TYPES, DAY_TYPES_ICONS, morningFirst } from "./SpaceBookingResident";

const Widget = () => {
  const { t } = useTranslation();
  const { colors, size } = useDesignTokens();
  const dispatch = useDispatch();

  const slots = useSelector(todayWithSitesSelector);

  useEffect(() => {
    dispatch(getToday());
  }, [dispatch]);

  return (
    <div>
      <Typography.Paragraph strong style={{ fontSize: size.l }}>
        {t("Today")}
      </Typography.Paragraph>
      <Space style={{ width: "100%" }} direction="vertical">
        {slots?.length ? (
          slots
            .slice()
            .sort(morningFirst)
            .map((slot, i) => (
              <NavLink to="/spaceBooking" key={i}>
                <Tile
                  style={{
                    height: "auto",
                    padding: "10px",
                    margin: "0",
                    width: "100%",
                    alignItems: "baseline",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column", gap: "0.8rem" }}>
                    <Space align="center">
                      <Glyph
                        style={{
                          color: colors.secondary_base,
                          fontWeight: "normal",
                          marginRight: "8px",
                        }}
                        name={DAY_TYPES_ICONS[slot.request.type]}
                      />
                      <Typography.Text strong>
                        {slot.request.type === DAY_TYPES.ON_SITE
                          ? slot.site?.title
                          : t(`spas.type.${slot.request.type}`)}
                      </Typography.Text>
                    </Space>
                    <Space align="center">
                      <Glyph
                        name="schedule"
                        style={{
                          color: colors.pending_light,
                          fontWeight: "normal",
                          marginRight: "8px",
                        }}
                      />
                      <Typography.Text strong>
                        {t(`spas.period.${slot.request?.period}`)}
                      </Typography.Text>
                    </Space>
                  </div>
                  <div>
                    <Glyph style={{ fontSize: size.xxxxl }} name="navigate_next" />
                  </div>
                </Tile>
              </NavLink>
            ))
        ) : (
          <div>
            <Typography.Paragraph strong>{t("spas.widget.noResa")}</Typography.Paragraph>
            <Button ghost style={{ width: "100%" }}>
              <Link to="/spaceBooking">{t("spas.request.new")}</Link>
            </Button>
          </div>
        )}
      </Space>
    </div>
  );
};

export default Widget;
