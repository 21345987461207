import { createSelector, createSlice, createAsyncThunk as thunk } from "@reduxjs/toolkit";
import moment from "moment";
import SpasConnector from "../../api/DWM/SpasConnector";
import DWMConnector from "../../api/DWMConnector";
import { mapDataSelector } from "./MapDataWS";

const INITIAL_STATE = {
  today: null,
  slots: [],
  team: [],
  users: [],
  followed: [],
  followedPending: [],
  presence: [],
  listDeskAvailability: null,

  planningDayList: null,
  planningDayCounts: null,
  mapFloorUsers: null,
  teleworkingLimit: null,

  teamAvailability: null,
};

/*
|--------------------------------------------------------------------------
| Async Chunks
|--------------------------------------------------------------------------
*/

const EXTRA_REDUCERS = {};

export const getToday = thunk("spaceService/getToday", async () => {
  const slots = await SpasConnector.listSpaasSlots({
    startDate: moment().format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
  });
  return slots;
});
EXTRA_REDUCERS[getToday.fulfilled] = (state, action) => {
  state.today = action.payload;
};

export const listSlots = thunk("spaceService/listSlots", async ({ startDate, endDate } = {}) => {
  if (!startDate) startDate = moment().startOf("month").subtract(1, "month").toISOString();
  if (!endDate) endDate = moment(startDate).endOf("month").add(6, "month").toISOString();
  const slots = await SpasConnector.listSpaasSlots({
    startDate,
    endDate,
  });
  return slots;
});
EXTRA_REDUCERS[listSlots.fulfilled] = (state, action) => {
  state.slots = action.payload;
};

export const createRequest = thunk("spaceService/createRequest", async (data, { dispatch }) => {
  const res = await SpasConnector.createSpaasRequest(data);
  await Promise.all([dispatch(listSlots()), dispatch(getTeleworkingLimit())]);
  return res;
});

export const createTeamRequest = thunk(
  "spaceService/createTeamRequest",
  async (data, { dispatch }) => {
    await SpasConnector.createTeamRequest(data);
    await Promise.all([dispatch(listSlots()), dispatch(getTeleworkingLimit())]);
  },
);

export const cancelSlot = thunk("spaceService/cancelSlot", async ({ id, data }, { dispatch }) => {
  await SpasConnector.cancelSpaasSlot(id, data);
  await Promise.all([dispatch(listSlots()), dispatch(getTeleworkingLimit())]);
});

export const getTeam = thunk("spaceService/getTeam", async (_, { getState }) => {
  const state = getState();
  const siteId = state.userWS.userData?.campus?.[0]?.id;
  const { team } = await SpasConnector.getSpasTeam({ siteId });
  return team;
});
EXTRA_REDUCERS[getTeam.fulfilled] = (state, action) => {
  state.team = action.payload;
};

export const addToTeam = thunk(
  "spaceService/addToTeam",
  async ({ userId }, { getState, dispatch }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    await SpasConnector.addSpasTeam({ siteId, userId });
    await dispatch(getTeam());
  },
);

export const removeFromTeam = thunk(
  "spaceService/removeFromTeam",
  async ({ userId }, { getState, dispatch }) => {
    const state = getState();
    const siteId = state.userWS.userData?.campus?.[0]?.id;
    await SpasConnector.removeSpasTeam({ siteId, userId });
    await dispatch(getTeam());
  },
);

export const searchUsers = thunk("spaceService/searchUsers", async ({ search, page, size }) => {
  const users = await DWMConnector.searchUsers({ search, page, size });
  return users;
});
EXTRA_REDUCERS[searchUsers.fulfilled] = (state, action) => {
  state.users = action.payload;
};

export const listFollowed = thunk("spaceService/listFollowed", async () => {
  const followed = await SpasConnector.listFollowed();
  return followed;
});
EXTRA_REDUCERS[listFollowed.fulfilled] = (state, action) => {
  state.followed = action.payload.followed.filter((p) => p.status === "accepted");
  state.followedPending = action.payload.followed.filter((p) => p.status === "pending");
};

export const listPresence = thunk("spaceService/listPresence", async ({ siteId, date }) => {
  const presence = await SpasConnector.listPresence({ siteId, date });
  return presence;
});
EXTRA_REDUCERS[listPresence.fulfilled] = (state, action) => {
  state.presence = action.payload;
};

export const revokeFollow = thunk(
  "spaceService/revokeFollow",
  async ({ followId }, { dispatch }) => {
    await SpasConnector.revokeFollow({ followId });
    await dispatch(listFollowed());
  },
);

export const answerFollow = thunk(
  "spaceService/answerFollow",
  async ({ followId, accepted }, { dispatch }) => {
    await SpasConnector.answerFollow({ followId, accepted });
    await dispatch(listFollowed());
  },
);

export const sendFollow = thunk("spaceService/sendFollow", async ({ userId }, { dispatch }) => {
  await SpasConnector.sendFollow({ userId });
});

export const listDeskAvailability = thunk("spaceService/listDeskAvailability", async (params) => {
  const res = await SpasConnector.listDeskAvailability(params);
  return res;
});
EXTRA_REDUCERS[listDeskAvailability.fulfilled] = (state, action) => {
  state.listDeskAvailability = action.payload?.workplaces || [];
};

export const confirmPresence = thunk(
  "spaceService/confirmPresence",
  async ({ slotId, siteId, code }) => {
    await SpasConnector.confirmPresence({ slotId, siteId, code });
  },
);

export const getTeleworkingLimit = thunk("spaceService/getTeleworkingLimit", async () => {
  const res = await SpasConnector.getTeleworkingLimit({ date: moment().format("YYYY-MM-DD") });
  return res;
});
EXTRA_REDUCERS[getTeleworkingLimit.fulfilled] = (state, action) => {
  state.teleworkingLimit = action.payload;
};

/**
 * tab === MY_TEAM
 */

export const getTeamDayList = thunk("spaceService/getTeamDayList", async (params) => {
  const res = await SpasConnector.getTeamDayList(params);
  return res;
});
EXTRA_REDUCERS[getTeamDayList.fulfilled] = (state, action) => {
  if (action.meta.arg.page === 1) {
    state.planningDayList = action.payload;
  } else {
    state.planningDayList.list = state.planningDayList.list.map((i) => {
      const changed = action.payload.list.find((j) => {
        if (j.type === "ON_SITE") return i.type === j.type && i.site.id === j.site.id;
        else return i.type === j.type;
      });
      if (changed) return { ...changed, items: i.items.concat(changed.items) };
      else return i;
    });
  }
};

export const getTeamDayCounts = thunk("spaceService/getTeamDayCounts", async (params) => {
  const res = await SpasConnector.getTeamDayCounts(params);
  return res;
});
EXTRA_REDUCERS[getTeamDayCounts.pending] = (state, action) => {
  state.planningDayCounts = INITIAL_STATE.planningDayCounts;
};
EXTRA_REDUCERS[getTeamDayCounts.fulfilled] = (state, action) => {
  state.planningDayCounts = action.payload?.list?.reduce((acc, item) => {
    return { ...acc, [item.date]: item.count };
  }, {});
};

export const listTeamMapFloorUsers = thunk("spaceService/listTeamMapFloorUsers", async (params) => {
  const res = await SpasConnector.getTeamMapList(params);
  return res;
});
EXTRA_REDUCERS[listTeamMapFloorUsers.pending] = (state, action) => {
  state.mapFloorUsers = INITIAL_STATE.mapFloorUsers;
};
EXTRA_REDUCERS[listTeamMapFloorUsers.fulfilled] = (state, action) => {
  state.mapFloorUsers = action.payload;
};

export const listTeamAvailability = thunk("spaceService/listTeamAvailability", async (params) => {
  const res = await SpasConnector.listTeamAvailability(params);
  return res;
});
EXTRA_REDUCERS[listTeamAvailability.fulfilled] = (state, action) => {
  if (action.payload?.items?.length) {
    action.payload.items = action.payload.items.map((i) => ({
      ...i,
      canBook: i.canBook ?? true,
    }));
  }
  if (action.meta.arg.page === 1) {
    state.teamAvailability = action.payload;
  } else {
    state.teamAvailability = {
      ...action.payload,
      items: state.teamAvailability.items.concat(action.payload.items),
    };
  }
};

/**
 * tab === MY_CONTACTS
 */

export const getFollowedDayList = thunk("spaceService/getFollowedDayList", async (params) => {
  const res = await SpasConnector.getFollowedDayList(params);
  return res;
});
EXTRA_REDUCERS[getFollowedDayList.pending] = (state, action) => {
  state.mapUsers = INITIAL_STATE.mapUsers;
  state.mapFloorUsers = INITIAL_STATE.mapFloorUsers;
};
EXTRA_REDUCERS[getFollowedDayList.fulfilled] = (state, action) => {
  state.planningDayList = {
    ...action.payload,
    list: action.payload?.list?.map((i) => ({ ...i, totalItems: i.items.length })),
  };
  state.mapUsers = action.payload?.list?.filter((i) => i.type === "ON_SITE");
  state.mapFloorUsers = { list: action.payload?.list?.[0]?.items || [] };
};

export const getFollowedDayCounts = thunk("spaceService/getFollowedDayCounts", async (params) => {
  const res = await SpasConnector.getFollowedDayCounts(params);
  return res;
});
EXTRA_REDUCERS[getFollowedDayCounts.pending] = (state, action) => {
  state.planningDayCounts = INITIAL_STATE.planningDayCounts;
};
EXTRA_REDUCERS[getFollowedDayCounts.fulfilled] = (state, action) => {
  state.planningDayCounts = action.payload?.list?.reduce((acc, item) => {
    return { ...acc, [item.date]: item.count };
  }, {});
};

/*
|--------------------------------------------------------------------------
| Slice
|--------------------------------------------------------------------------
*/

const spaceServiceSlice = createSlice({
  name: "spaceService",
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (builder) => {
    for (const [key, handler] of Object.entries(EXTRA_REDUCERS)) {
      builder.addCase(key, handler);
    }
  },
});

/*
|--------------------------------------------------------------------------
| Selectors
|--------------------------------------------------------------------------
*/

export const todayWithSitesSelector = createSelector(
  (state) => state.spaceServiceWS.today,
  (state) => state.userWS.userData,
  (slots, userData) => {
    logger.log("Evaluate todayWithSitesSelector", slots, userData);
    return slots?.map((slot) => {
      const site = userData?.campus.find((s) => s.id === slot.request.siteId);
      return { ...slot, site };
    });
  },
);

export const slotsWithSitesSelector = createSelector(
  (state) => state.spaceServiceWS.slots,
  (state) => state.userWS.userData,
  (slots, userData) => {
    logger.log("Evaluate slotsWithSitesSelector", slots, userData);
    return slots?.map((slot) => {
      const site = userData?.campus.find((s) => s.id === slot.request.siteId);
      return { ...slot, site };
    });
  },
);

export const followedSlotsWithSitesSelector = createSelector(
  (state) => state.spaceServiceWS.followedSlots,
  (state) => state.userWS.userData,
  (slots, userData) => {
    logger.log("Evaluate followedSlotsWithSitesSelector", slots, userData);
    return slots?.map((slot) => {
      const site = userData?.campus.find((s) => s.id === slot.request.siteId);
      return { ...slot, site };
    });
  },
);

export const desksAvailabilitySelector = createSelector(
  (state, siteId) => mapDataSelector(state, siteId),
  (state) => state.spaceServiceWS.listDeskAvailability,
  (mapData, listDeskAvailability) => {
    logger.log("mapData", mapData);
    logger.log("listDeskAvailability", listDeskAvailability);
    return (listDeskAvailability || []).map((item) => {
      const workplace = mapData?.workplaces?.find((wp) => wp.id === item.id);
      const room = mapData?.resources?.find((r) => r.id === workplace?.roomId);
      return { ...item, workplace, room };
    });
  },
);

// Generic selector for the user list on map sider
// Because contacts and team have the same structure
// But contacts are not paginated
export const mapUsersSelector = createSelector(
  (_, siteId) => siteId,
  (state) => state.spaceServiceWS.planningDayList,
  (siteId, planningDayList) => {
    logger.log("Evaluate mapUsersSelector", planningDayList, siteId);
    if (!siteId) return null;
    return planningDayList?.list?.find((i) => {
      return i.site?.id === siteId;
    });
  },
);

export default spaceServiceSlice.reducer;
