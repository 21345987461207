import { Button, Drawer, Form, Select, Typography } from "antd";
import React, { createRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAgents } from "../../../../services/redux/services/TicketingV2WS";
import priorities from "../Ticket/Component/priorities";
import { useTranslation } from "react-i18next";

const FilterDrawer = ({ showFilterDrawer, setShowFilterDrawer, rights, setFilters, siteId }) => {
  const formRef = createRef();
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const locale = i18n.language.split("-")[0];

  const [allTypes, setAllTypes] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [allSubCategories, setAllSubCategories] = useState([]);
  const [allFloors, setAllFloors] = useState([]);
  const [tempFilters, setTempFilters] = useState({});
  const [page, setPage] = useState(1);
  //const [loading, setLoading] = useState(false);

  const floors = useSelector((state) => state.ticketingV2WS.floors?.mapData[0]?.floors);
  const referential = useSelector((state) => state.ticketingV2WS.referential);
  const status = useSelector((state) => state.ticketingV2WS.status);
  const allAgents = useSelector((state) => state.ticketingV2WS.agents);

  const statusOptions = status?.map((s) => ({
    label: s.label,
    value: s.id,
  }));

  const prioritiesOptions = priorities.map((p) => ({
    label: p.label[locale],
    value: p.name,
    id: p.name,
  }));

  const agentOptions = allAgents?.items?.map((a) => ({
    label: a.firstname && a.lastname ? `${a.firstname} ${a.lastname}` : a.email ? a.email : a.id,
    value: a.id,
  }));

  // floors
  useEffect(() => {
    const getFloors = () => {
      const tempFloors = [];
      floors?.forEach((d) => {
        tempFloors.push({
          value: d.id,
          label: d.displayName,
        });
      });
      setAllFloors(tempFloors);
    };
    getFloors();
  }, [floors]);

  // types
  useEffect(() => {
    const getTypes = () => {
      const tempTypes = [];
      referential?.forEach((d) => {
        tempTypes.push({
          value: d.id,
          label: d.label,
        });
      });
      setAllTypes(tempTypes);
    };
    getTypes();
  }, [referential]);

  // categories
  useEffect(() => {
    const getCategories = () => {
      const tempCategories = [];
      referential
        ?.find((d) => d.id === tempFilters.type)
        ?.categories?.forEach((d) => {
          tempCategories.push({
            value: d.id,
            label: d.label,
          });
        });
      setAllCategories(tempCategories);
    };
    getCategories();
  }, [tempFilters.type, referential]);

  // subcategories
  useEffect(() => {
    const getSubCategories = () => {
      const tempSubCategories = [];
      referential
        ?.find((d) => d.id === tempFilters.type)
        ?.categories?.find((d) => d.id === tempFilters.cat)
        ?.subCategories?.forEach((d) => {
          tempSubCategories.push({
            value: d.id,
            label: d.label,
          });
        });
      setAllSubCategories(tempSubCategories);
    };
    getSubCategories();
  }, [tempFilters.cat, tempFilters.type, referential]);

  const handlePopupScroll = (e) => {
    const target = e.target;
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (!!allAgents.nextPage) {
        //setLoading(true);
        setPage(page + 1);
        dispatch(
          getAgents({
            //refId:
            siteId,
            page,
            size: 2,
          }),
        );
        //setLoading(false);
      }
    }
  };

  return (
    <Drawer
      placement="right"
      footerStyle={{ display: "none" }}
      headerStyle={{ display: "none" }}
      onClose={() => setShowFilterDrawer(false)}
      open={showFilterDrawer}
      contentWrapperStyle={{ backgroundColor: "white" }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <Form ref={formRef} name="control-ref">
          <div style={{ display: "flex", flexDirection: "column" }}>
            {!!allTypes?.length && (
              <Form.Item name="type">
                <>
                  <Typography.Text>{t("ticketingv2.Type")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={allTypes}
                    onChange={(type) =>
                      setTempFilters({ ...tempFilters, type: type, subCategory: null, cat: null })
                    }
                    placeholder={t("ticketingv2.AllTypes")}
                    value={tempFilters.type?.label}
                  />
                </>
              </Form.Item>
            )}

            {!!allCategories?.length && !!tempFilters.type && (
              <Form.Item name="category">
                <>
                  <Typography.Text>{t("ticketingv2.Cat")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={allCategories}
                    onChange={(cat) =>
                      setTempFilters({ ...tempFilters, cat: cat, subCategory: null })
                    }
                    placeholder={t("ticketingv2.AllCats")}
                    value={tempFilters.cat || null}
                  />
                </>
              </Form.Item>
            )}

            {!!allSubCategories?.length && !!tempFilters.cat && (
              <Form.Item name="subCategory">
                <>
                  <Typography.Text>{t("ticketingv2.SubCat")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={allSubCategories}
                    onChange={(subcat) => setTempFilters({ ...tempFilters, subCategory: subcat })}
                    placeholder={t("ticketingv2.AllSubCats")}
                    value={tempFilters.subCategory || null}
                  />
                </>
              </Form.Item>
            )}

            {!!statusOptions?.length && rights === "manager" && (
              <Form.Item name="status">
                <>
                  <Typography.Text>{t("ticketingv2.Status")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={statusOptions}
                    onChange={(status) => setTempFilters({ ...tempFilters, status: status })}
                    placeholder={t("ticketingv2.AllStatus")}
                    value={tempFilters.status?.label}
                  />
                </>
              </Form.Item>
            )}

            {!!prioritiesOptions?.length && rights === "manager" && (
              <Form.Item name="priorities">
                <>
                  <Typography.Text>{t("ticketingv2.Priority")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={prioritiesOptions}
                    onChange={(priorities) =>
                      setTempFilters({ ...tempFilters, priority: priorities })
                    }
                    placeholder={t("ticketingv2.AllPriorities")}
                    value={tempFilters.status?.label}
                  />
                </>
              </Form.Item>
            )}

            {!!agentOptions?.length && rights === "manager" && (
              <Form.Item name="assignedTo">
                <>
                  <Typography.Text>{t("ticketingv2.AssignedTo")}</Typography.Text>
                  <Select
                    allowClear
                    onPopupScroll={handlePopupScroll}
                    style={{ marginTop: 5 }}
                    options={agentOptions}
                    onChange={(agent) => setTempFilters({ ...tempFilters, agent: agent })}
                    placeholder={t("ticketingv2.AllAgents")}
                    value={tempFilters.agent?.label}
                  />
                </>
              </Form.Item>
            )}

            {floors?.length > 0 && (
              <Form.Item name="floor">
                <>
                  <Typography.Text>{t("ticketingv2.Floor")}</Typography.Text>
                  <Select
                    allowClear
                    style={{ marginTop: 5 }}
                    options={allFloors}
                    onChange={(floor) => setTempFilters({ ...tempFilters, floor: floor })}
                    placeholder={t("ticketingv2.AllFloors")}
                    value={tempFilters.floor?.label}
                  />
                </>
              </Form.Item>
            )}
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "revert-wrap",
              gap: 8,
            }}
          >
            <Button
              type="ghost"
              disabled={Object.keys(tempFilters).length === 0}
              onClick={() => {
                formRef.current.resetFields();
                setTempFilters({});
              }}
              style={{ flexGrow: 1 }}
            >
              {t("ticketingv2.DeleteAll")}
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setFilters(tempFilters);
                setShowFilterDrawer(false);
              }}
              style={{ flexGrow: 1 }}
            >
              {t("ticketingv2.ApplyFilters")}
            </Button>
          </div>
        </Form>
      </div>
    </Drawer>
  );
};

export default FilterDrawer;
