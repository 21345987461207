import { Button, Card, Input, Modal, Select, Steps, Typography } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ReactComponent as Error } from "../../../../../assets/svg/error.svg";
import { slugify } from "../../../../../helpers/utils";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import {
  addAssetToTicket,
  createTicketV2,
  getAllReferential,
  getTicketDetails,
  updateTicketV2,
} from "../../../../../services/redux/services/TicketingV2WS";
import Glyph from "../../../../Common/Glyph/Glyph";
import CreateStepWorkplaceMap from "../CreateStepWorkplaceMap";
import CreateOrEditTicketForm from "../Form/CreateOrEditTicketForm";

const CreateOrEditTicketModal = ({
  showCreateOrEditTicketModal,
  setShowCreateOrEditTicketModal,
  siteId,
  locale,
  ticketDetails,
  section,
}) => {
  const { colors } = useDesignTokens();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { stuffId } = useParams();

  const [currentStep, setCurrentStep] = useState(0);
  const [space, setSpace] = useState();
  const [newTicket, setNewTicket] = useState(
    ticketDetails
      ? {
          typeId: ticketDetails.type.id,
          categoryId: ticketDetails.category?.id,
          subCategoryId: ticketDetails.subCategory?.id,
          title: ticketDetails.title,
          description: ticketDetails.description,
          form: ticketDetails.form?.items ? { items: ticketDetails.form?.items } : null,
          location: ticketDetails.location?.id,
          attachments: ticketDetails.attachments[0],
        }
      : {
          typeId: null,
          categoryId: null,
          subCategoryId: null,
          title: "",
          description: "",
          attachments: {
            files: [],
            pictures: [],
          },
        },
  );
  const [uploading, setUploading] = useState(false);
  const [attachements, setAttachements] = useState({
    files: [],
    pictures: [],
  });

  const referential = useSelector((state) => state.ticketingV2WS.referential);
  const views = useSelector((state) => state.userWS.userData.views);
  const currentViewId = useSelector((state) => state.ticketingV2WS.currentViewId);

  const categoriesOptions = referential
    ?.find((ref) => ref.id === newTicket.typeId)
    ?.categories?.map((c) => ({
      label: c.label,
      value: c.id,
    }));

  const subCategoriesOptions = referential
    ?.find((ref) => ref.id === newTicket.typeId)
    ?.categories?.find((cat) => cat.id === newTicket.categoryId)
    ?.subCategories?.map((c) => ({
      label: c.label,
      value: c.id,
    }));

  const checkFormConsequencies = () => {
    return !!referential
      ?.find((type) => type.id === newTicket.typeId)
      ?.categories?.find((cat) => cat.id === newTicket.categoryId)?.form?.items;
  };

  useEffect(() => {
    logger.log("attachements", attachements);
  }, [attachements]);

  useEffect(() => {
    logger.log("newTicket", newTicket);
  }, [newTicket]);

  useEffect(() => {
    dispatch(
      getAllReferential({
        siteId,
        locale,
        space, //"a25b757f-00d3-4697-a6bb-2a6988cdfb39"
        viewId: views.find((v) => v.id === currentViewId)?.ticketingViews?.user || currentViewId,
      }),
    );
  }, [dispatch, locale, siteId, currentStep, space, views, currentViewId]);

  const handleSubmitTicket = async (action) => {
    setUploading(true);
    if (!newTicket.subCategoryId) {
      delete newTicket.subCategoryId;
    }

    let formData = new FormData();
    if (!!attachements?.files?.length) {
      attachements.files.forEach((file) => {
        formData.append(
          file.type?.split("/")[0] === "image"
            ? "img_" + slugify(file.name)
            : "file_" + slugify(file.name),
          file,
          file.name,
        );
      });
    }
    if (!!attachements?.pictures?.length) {
      attachements.pictures.forEach((file) => {
        formData.append(
          file.type?.split("/")[0] === "image"
            ? "img_" + slugify(file.name)
            : "file_" + slugify(file.name),
          file,
          file.name,
        );
      });
    }
    logger.log("formData", formData);

    try {
      const submitTicket = await dispatch(
        ticketDetails
          ? updateTicketV2({
              siteId,
              ticketId: ticketDetails.id,
              data: newTicket,
            })
          : createTicketV2({
              siteId,
              data: newTicket,
            }),
      );

      if (submitTicket) {
        logger.log("submitTicket", submitTicket);
        if (!!attachements?.files?.length || !!attachements?.pictures?.length) {
          await dispatch(
            addAssetToTicket({
              siteId,
              ticketId: submitTicket.payload.id,
              formData,
            }),
          );
        }
        ticketDetails
          ? await dispatch(
              getTicketDetails({
                siteId,
                locale: locale,
                ticketId: stuffId,
              }),
            )
          : history.push(`/${section}/ticket/${siteId}/${submitTicket.payload.id}`);
        setShowCreateOrEditTicketModal(false);
      }
    } catch (error) {
      logger.error("Error creating/updating ticket:", error);
    }
  };

  const onMapClick = async (room) => {
    logger.log("ticketDetails", ticketDetails, referential, room);
    return new Promise(async (resolve) => {
      if (ticketDetails) {
        // si on est en train de modifier un ticket
        const newRef = await dispatch(
          // on récupère le referentiel associé à la salle
          getAllReferential({
            siteId,
            locale,
            space: room,
          }),
        );

        if (!newRef) resolve(false);

        if (
          // si le referentiel contient le type, la catégorie et la sous-catégorie? du ticket
          ticketDetails.subCategory
            ? !!referential
                ?.find((type) => type.id === ticketDetails?.type?.id)
                ?.categories?.find((cat) => cat.id === ticketDetails?.category?.id)
                ?.subCategories?.find((subCat) => subCat.id === ticketDetails?.subCategory?.id)
            : !!referential
                ?.find((type) => type.id === ticketDetails?.type?.id)
                ?.categories?.find((cat) => cat.id === ticketDetails?.category?.id)
        ) {
          // Pas de changement de ref = pas de problème de form
          setSpace(room);
          setNewTicket((prevNewTicket) => ({
            ...prevNewTicket,
            location: room.id,
          }));
          resolve(true);
        } else {
          // on affiche un confirm modal pour demander à l'utilisateur de confirmer le changement de localisation
          Modal.confirm({
            title: t("ticketingv2.ChangeLocation"),
            content: t("ticketingv2.ChangeLocationWillResetForm"),
            onOk: () => {
              setSpace(room);
              setNewTicket((prevNewTicket) => ({
                ...prevNewTicket,
                location: room.id,
                typeId: null,
                categoryId: null,
                subCategoryId: null,
                form: { items: [] },
              }));
              resolve(true);
            },
            onCancel: () => {
              resolve(false);
            },
          });
        }
      } else {
        // si on est en train de créer un ticket
        setSpace(room.categoryId);
        setNewTicket((prevNewTicket) => ({
          ...prevNewTicket,
          location: room.id,
        }));
        resolve(true);
      }
    });
  };

  return (
    <Modal
      visible={showCreateOrEditTicketModal}
      width={655}
      bodyStyle={{ backgroundColor: colors.light_background }}
      maskClosable={false}
      onCancel={() => setShowCreateOrEditTicketModal(false)}
      title={
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Typography.Title level={4} style={{ margin: "0" }}>
            {!ticketDetails ? t("ticketingv2.NewTicket") : t("ticketingv2.ModifyTicket")}
          </Typography.Title>
        </div>
      }
      footer={
        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
          {currentStep !== 0 && (
            <Button
              type="ghost"
              onClick={() => setCurrentStep((prev) => prev - 1)}
              style={{ width: 270 }}
            >
              {t("ticketingv2.Previous")}
            </Button>
          )}
          {currentStep === 0 && (
            <Button
              type="ghost"
              onClick={() => setShowCreateOrEditTicketModal(false)}
              style={{ width: 270 }}
            >
              {t("ticketingv2.Cancel")}
            </Button>
          )}

          {currentStep !== 3 && (
            <Button
              type="primary"
              disabled={
                (currentStep === 1 && !(newTicket.typeId && newTicket.categoryId)) ||
                (currentStep === 1 &&
                  newTicket.categoryId &&
                  !newTicket.subCategoryId &&
                  !!subCategoriesOptions?.length) ||
                (currentStep === 2 && !(newTicket.title && newTicket.description))
              }
              onClick={() => setCurrentStep((prev) => prev + 1)}
              style={{ width: 270 }}
            >
              {currentStep === 0 && !newTicket.location
                ? t("ticketingv2.Pass")
                : t("ticketingv2.Next")}
            </Button>
          )}
          {currentStep === 3 &&
            (!ticketDetails ? (
              <Button
                type="primary"
                onClick={handleSubmitTicket}
                style={{ width: 270 }}
                loading={uploading}
              >
                {t("ticketingv2.CreateTicket")}
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={handleSubmitTicket}
                style={{ width: 270 }}
                loading={uploading}
              >
                {t("ticketingv2.ModifyTicket")}
              </Button>
            ))}
        </div>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: 16,
          width: "100%",
        }}
      >
        <Steps
          size="small"
          current={currentStep}
          progressDot
          style={{ marginBottom: 16 }}
          items={[
            {
              title: t("ticketingv2.Localization"),
            },
            {
              title: t("ticketingv2.Categorization"),
            },
            {
              title: t("ticketingv2.Description"),
            },
            {
              title: t("ticketingv2.Form"),
            },
          ]}
        />
        {currentStep === 0 && (
          <>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", height: 400 }}>
              <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                <Typography.Text strong style={{ fontSize: 17 }}>
                  {t("ticketingv2.Localization")}
                </Typography.Text>
                <div
                  style={{
                    overflow: "hidden",
                    position: "absolute",
                    width: "100%",
                    left: 0,
                    top: 61,
                    bottom: 83,
                  }}
                >
                  <CreateStepWorkplaceMap
                    siteId={siteId}
                    //onChange={onMapClick}
                    onMapClick={onMapClick}
                    roomId={newTicket.location}
                  />
                </div>
              </div>
            </div>
          </>
        )}
        {currentStep === 1 && (
          <>
            <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
              <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                {!!referential.length ? (
                  <Typography.Text strong style={{ fontSize: 17 }}>
                    {t("ticketingv2.MyRequestSubject")}
                  </Typography.Text>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      width: "100%",
                      height: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Error />
                    <Typography.Text strong style={{ fontSize: 17 }}>
                      {t("ticketingv2.NoRequestSubject")}
                    </Typography.Text>
                  </div>
                )}
                {ticketDetails && (
                  <Typography.Text style={{ fontSize: 12, color: colors.secondary_base }}>
                    {t("ticketingv2.ChangeTypeResetForm")}
                  </Typography.Text>
                )}
              </div>
              <div style={{ display: "flex", gap: 16, flexWrap: "wrap" }}>
                {!!referential.length &&
                  referential.map(
                    (type) =>
                      !!type.categories.length && (
                        <Card
                          key={type.id}
                          style={{
                            width: 128,
                            height: 112,
                            cursor: "pointer",
                            border:
                              newTicket.typeId === type.id
                                ? `2px solid ${colors.secondary_base}`
                                : "",
                          }}
                          onClick={() =>
                            setNewTicket({
                              ...newTicket,
                              typeId: type.id,
                              categoryId: null,
                              subCategoryId: null,
                              form: { items: [] },
                            })
                          }
                        >
                          <div
                            style={{
                              display: "flex",
                              height: "100%",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              gap: 8,
                            }}
                          >
                            <Glyph
                              name={type.icon ?? "search"}
                              style={{
                                fontSize: 24,
                                color: colors.secondary_base,
                              }}
                            />
                            <Typography.Text
                              strong
                              style={{ fontSize: 12, textAlign: "center", lineHeight: 1.2 }}
                            >
                              {type.label}
                            </Typography.Text>
                          </div>
                        </Card>
                      ),
                  )}
              </div>
            </div>
            {!!newTicket.typeId && !!categoriesOptions?.length && (
              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                  <Typography.Text strong style={{ fontSize: 17 }}>
                    {t("ticketingv2.Cat")}
                  </Typography.Text>
                  {ticketDetails && (
                    <Typography.Text style={{ fontSize: 12, color: colors.secondary_base }}>
                      {t("ticketingv2.ChangeCatResetForm")}
                    </Typography.Text>
                  )}
                </div>
                <Card>
                  <Select
                    bordered={false}
                    size={"middle"}
                    style={{
                      width: "100%",
                      marginTop: -8,
                      marginBottom: -8,
                      color: "black !important",
                      fontStyle: "bold !important",
                    }}
                    options={categoriesOptions}
                    defaultValue={{ label: t("ticketingv2.AllCats"), value: "allCats" }}
                    value={newTicket.categoryId}
                    onChange={(cat) => {
                      setNewTicket({
                        ...newTicket,
                        categoryId: cat,
                        subCategoryId: null,
                        form: {
                          items: referential
                            ?.find((t) => t.id === newTicket.typeId)
                            ?.categories?.find((c) => c.id === cat)?.form?.items,
                        },
                      });
                      //setForm();
                    }}
                  />
                </Card>
              </div>
            )}
            {!!newTicket.categoryId && !!subCategoriesOptions?.length && (
              <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
                <div style={{ display: "flex", gap: 8, alignItems: "baseline" }}>
                  <Typography.Text strong style={{ fontSize: 17 }}>
                    {t("ticketingv2.SubCat")}
                  </Typography.Text>
                  {ticketDetails && !checkFormConsequencies() && (
                    <Typography.Text style={{ fontSize: 12, color: colors.secondary_base }}>
                      {t("ticketingv2.ChangeSubCatResetForm")}
                    </Typography.Text>
                  )}
                </div>
                <Card>
                  <Select
                    bordered={false}
                    size={"middle"}
                    style={{
                      width: "100%",
                      marginTop: -8,
                      marginBottom: -8,
                      color: "black !important",
                      fontStyle: "bold !important",
                    }}
                    options={subCategoriesOptions}
                    defaultValue={{
                      label: t("ticketingv2.AllSubCat"),
                      value: "allSubCats",
                    }}
                    value={newTicket.subCategoryId}
                    onChange={(subCat) => {
                      const subCatForm = referential
                        ?.find((type) => type.id === newTicket.typeId)
                        ?.categories?.find((cat) => cat.id === newTicket.categoryId)
                        ?.subCategories.find((sc) => sc.id === subCat)?.form;
                      setNewTicket({
                        ...newTicket,
                        subCategoryId: subCat,
                        form: !!subCatForm?.items?.length ? subCatForm : newTicket.form,
                      });
                    }}
                  />
                </Card>
              </div>
            )}
          </>
        )}
        {currentStep === 2 && (
          <>
            <div style={{ display: "flex", flexDirection: "column", width: "100%", gap: 16 }}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography.Text strong style={{ fontSize: 17 }}>
                  {t("ticketingv2.ticketTitle")}
                </Typography.Text>
                <Input
                  prefix={
                    <Glyph name="edit" style={{ fontSize: 16, color: colors.pending_light }} />
                  }
                  allowClear
                  value={newTicket.title}
                  placeholder={t("ticketingv2.AddTitle")}
                  onChange={(e) =>
                    setNewTicket({
                      ...newTicket,
                      title: e.target.value,
                    })
                  }
                />
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Typography.Text strong style={{ fontSize: 17 }}>
                  {t("ticketingv2.Description")}
                </Typography.Text>
                <Input.TextArea
                  rows={4}
                  allowClear
                  value={newTicket.description}
                  placeholder={t("ticketingv2.AddDescription")}
                  onChange={(e) =>
                    setNewTicket({
                      ...newTicket,
                      description: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </>
        )}
        {currentStep === 3 && (
          <CreateOrEditTicketForm
            newTicket={newTicket}
            setNewTicket={setNewTicket}
            attachements={attachements}
            setAttachements={setAttachements}
            site={siteId}
          />
        )}
      </div>
    </Modal>
  );
};

export default CreateOrEditTicketModal;
