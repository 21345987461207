import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { Typography, Space, message } from "antd";

// import css

// import redux
import {
  getComfortLighting,
  getComfortBlinds,
  getComfortBlindsAngle,
  getComfortAcFanSpeed,
  getComfortAcOffset,
} from "../../../../services/redux/services/ConfortWS";

import { useTranslation } from "react-i18next";

// import component
import SettingsLight from "../SettingsUI/SettingsLight";
import SettingsBlind from "../SettingsUI/SettingsBlind";
import SettingsFanSpeed from "../SettingsUI/SettingsFanSpeed";
import SettingsDegrees from "../SettingsUI/SettingsDegrees";

const Settings = ({ setIsAuto, nameEquipment, setIsLoading, room }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!room) return;
    setIsLoading(true);
    let promise;
    switch (nameEquipment) {
      case "Light":
        promise = Promise.allSettled(
          room?.features?.comfort?.light?.options?.includes("dimmer")
            ? room?.features?.comfort?.light?.zones?.map((zoneLight) =>
                dispatch(getComfortLighting({ roomId: room.id, zone: zoneLight.id })).unwrap(),
              )
            : null,
        )
          .then((results) => {
            if (results.find((r) => r.status === "rejected")) {
              message.error(t("ErrorGetLight"), 5);
            }
          })
          .catch((error) => {
            logger.error(error);
          });
        break;
      case "Ventilation":
        promise = Promise.allSettled(
          room?.features?.comfort?.ac?.zones?.map((zoneAcFanSpeed) =>
            dispatch(getComfortAcFanSpeed({ roomId: room.id, zone: zoneAcFanSpeed.id })).unwrap(),
          ),
        )
          .then((results) => {
            if (results.find((r) => r.status === "rejected")) {
              message.error(t("ErrorGetFanSpeed"), 5);
            }
          })
          .catch((error) => {
            logger.error(error);
          });
        break;
      case "Temperature":
        promise = Promise.allSettled(
          room?.features?.comfort?.ac?.zones?.map((zoneAcOffset) =>
            dispatch(getComfortAcOffset({ roomId: room.id, zone: zoneAcOffset.id })).unwrap(),
          ),
        )
          .then((results) => {
            if (results.find((r) => r.status === "rejected")) {
              message.error(t("ErrorGetFanSpeed"), 5);
            }
          })
          .catch((error) => {
            logger.error(error);
          });
        break;
      case "Blind":
        promise = Promise.allSettled([
          room?.features?.comfort?.blind?.options?.includes("dimmer")
            ? room?.features?.comfort?.blind?.zones?.map((zoneBlind) =>
                dispatch(getComfortBlinds({ roomId: room.id, zone: zoneBlind.id })).unwrap(),
              )
            : null,
          room?.features?.comfort?.blind?.options?.includes("tilt")
            ? room?.features?.comfort?.blind?.zones?.map((zoneBlind) =>
                dispatch(getComfortBlindsAngle({ roomId: room.id, zone: zoneBlind.id })).unwrap(),
              )
            : null,
        ])
          .then((results) => {
            if (results.find((r) => r.status === "rejected")) {
              message.error(t("ErrorGetBlind"), 5);
            }
          })
          .catch((error) => {
            logger.error(error);
          });
        break;
      default:
        break;
    }
    promise.finally(() => {
      setIsLoading(false);
    });
  }, [dispatch, room, t, nameEquipment, setIsLoading]);

  const renderEquipement = () => {
    switch (nameEquipment) {
      case "Light":
        return room?.features?.comfort?.light?.zones?.map((zone) => (
          <SettingsLight comfort={room?.features?.comfort} zone={zone} selectedRoom={room} />
        ));
      case "Ventilation":
        return room?.features?.comfort?.ac?.zones?.map((zone) => (
          <SettingsFanSpeed
            setIsAuto={setIsAuto}
            comfort={room?.features?.comfort}
            zone={zone}
            selectedRoom={room}
          />
        ));
      case "Temperature":
        return room?.features?.comfort?.ac?.zones?.map((zone) => (
          <SettingsDegrees
            setIsAuto={setIsAuto}
            comfort={room?.features?.comfort}
            zone={zone}
            selectedRoom={room}
          />
        ));
      case "Blind":
        return room?.features?.comfort?.blind?.zones?.map((zone) => (
          <SettingsBlind comfort={room?.features?.comfort} zone={zone} selectedRoom={room} />
        ));
      default:
        break;
    }
  };

  // if (!room) {
  //   return <Redirect to="/" />;
  // }

  return (
    <>
      {nameEquipment === "Light" ? (
        <SettingsLight comfort={room?.features?.comfort} selectedRoom={room} />
      ) : nameEquipment === "Blind" ? (
        <SettingsBlind comfort={room?.features?.comfort} selectedRoom={room} />
      ) : nameEquipment === "Ventilation" ? (
        <SettingsFanSpeed
          setIsAuto={setIsAuto}
          comfort={room?.features?.comfort}
          selectedRoom={room}
        />
      ) : (
        <SettingsDegrees
          setIsAuto={setIsAuto}
          comfort={room?.features?.comfort}
          selectedRoom={room}
        />
      )}

      <Space
        style={{
          width: "100%",
          overflow: "auto",
          height: "fit-content",
          marginTop: "20px",
        }}
        direction="vertical"
        size="small"
      >
        <Typography.Text style={{ fontWeight: "bold", padding: "20px" }}>Zones</Typography.Text>
        {renderEquipement()}
      </Space>
    </>
  );
};

export default Settings;
