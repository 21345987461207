import { Button, Card, Typography, Modal, Spin } from "antd";
import Glyph from "../../Common/Glyph/Glyph";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { deleteBooking, getPlacesData } from "../../../services/redux/services/PlacesInfosWS";
import { STEPS } from "./Places";
import { useTranslation } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import styles from "./Places.module.less";
import { createLoadingSelector } from "../../../services/redux/managers/LoadingManager";

const CancelBooking = ({ data, placeInfo, view, setStep }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const mapData = useSelector((state) => state.clientsWS.campus?.mapData);
  const isLoading = useSelector(createLoadingSelector(["agora/deleteBooking"]));

  const confirm = () => {
    Modal.confirm({
      title: "Annuler ma réservation",
      content: "Souhaitez-vous annuler votre réservation ?",
      okText: <div block>Oui</div>,
      onOk: () => {
        dispatch(
          deleteBooking({
            viewId: view?.id,
            campusId: mapData?.id,
            extraType: placeInfo?.extras?.find((e) => e?.type === "restaurant-bookings")?.type,
            extraId: placeInfo?.extras?.find((e) => e?.type === "restaurant-bookings")?.data[0]?.id,
            contentId: placeInfo?.id,
            action: "delete",
            data: data,
          }),
        )
          .unwrap()
          .then(() => {
            dispatch(
              getPlacesData({
                viewId: view?.id,
                campusId: mapData?.id,
                date: moment().toISOString(),
                extraType: placeInfo?.extras?.find((e) => e?.type === "restaurant-bookings")?.type,
                extraId: placeInfo?.extras?.find((e) => e?.type === "restaurant-bookings")?.data[0]
                  ?.id,
                contentId: placeInfo?.id,
              }),
            );
            setStep(STEPS.DETAILS);
          });
      },
      cancelText: <div block>{t("No")}</div>,
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Spin
        wrapperClassName={styles["spinner"]}
        spinning={isLoading}
        indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />}
      >
        <Typography.Text strong>{t("Places.Booking.Cancel.Title")}</Typography.Text>
        <Card
          style={{ marginTop: "16px" }}
          bodyStyle={{ display: "flex", flexFlow: "column", textAlign: "center" }}
        >
          <Typography.Text strong>{moment(data?.date).format("LL")}</Typography.Text>
          <Typography.Text>
            {t("Places.Booking.Cancel.Party", { party: data?.party })}
          </Typography.Text>
          <Typography.Text>
            {data?.zoneName} {data?.startHour.split(":").slice(0, 2).join(":")} -{" "}
            {data?.endHour.split(":").slice(0, 2).join(":")}
          </Typography.Text>
          <Button
            ghost
            icon={<Glyph name="delete" />}
            style={{ marginTop: "8px" }}
            onClick={() => confirm()}
          >
            {t("Places.Booking.Cancel.Button")}
          </Button>
        </Card>
      </Spin>
    </div>
  );
};

export default CancelBooking;
