import React from "react";
import { Modal } from "antd";
import CreateStepWorkplaceMap from "./CreateStepWorkplaceMap";
import useDesignTokens from "../../../../../hook/useDesignTokens";

const MapModal = ({ roomId, siteId, setShowMapModal, showMapModal }) => {
  const { colors, symbols } = useDesignTokens();
  return (
    <>
      <Modal
        bodyStyle={{ padding: 0, height: "80vh" }}
        footer={false}
        open={showMapModal}
        onCancel={() => setShowMapModal(false)}
        style={{}}
      >
        <div
          style={{
            backgroundColor: colors.light_background,
            borderRadius: symbols.base_shape.radius,
            overflow: "hidden",
            height: "100%",
            position: "relative",
          }}
        >
          <CreateStepWorkplaceMap siteId={siteId} roomId={roomId} />
        </div>
      </Modal>
    </>
  );
};

export default MapModal;
