import { useTranslation } from "react-i18next";
import useDesignTokens from "../../../hook/useDesignTokens";
import { useDispatch, useSelector } from "react-redux";
import TransfertInfos from "./TransfertInfos";
import { isNil } from "ramda";
import { Alert, Button, Card, Col, Modal, Row, Table, Typography, Upload } from "antd";
import { ReactComponent as Delivred } from "../../../assets/svg/transfert.svg";
import Glyph from "../../Common/Glyph/Glyph";
import { useEffect, useState } from "react";
import {
  addFiles,
  getTransfertInfos,
  removeFilesInfos,
} from "../../../services/redux/services/TransfertWS";
import styles from "./Transfert.module.less";

const Transfert = () => {
  const { colors, size } = useDesignTokens();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();

  const transfertInfos = useSelector((state) => state.transfertWS.transfertInfos);
  const filesInfos = useSelector((state) => state.transfertWS.filesInfos);
  const campus = useSelector((state) => state.clientsWS.campus);
  const userData = useSelector((state) => state.userWS.userData);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!transfertInfos) dispatch(getTransfertInfos());
  }, [dispatch, transfertInfos]);

  const props = {
    onChange: ({ fileList }) => {
      dispatch(
        addFiles({
          transfertFile: fileList.map((file) => file.originFileObj),
          siteId: campus?.mapData?.id,
          locale: i18n.language.split("-")[0],
        }),
      );
    },
    beforeUpload: () => {
      return false;
    },
  };

  const columns = [
    {
      title: t("transfert.row"),
      dataIndex: "row",
      key: "row",
    },
    {
      title: t("transfert.errors"),
      dataIndex: "errors",
      key: "errors",
    },
  ];

  const data = filesInfos?.errors?.map((error, index) => {
    return {
      key: index,
      row: error.row,
      errors: error.errors.join(" / "),
    };
  });

  return (
    <div className={styles["transfert"]}>
      {!isNil(transfertInfos?.transfert) ? (
        <>
          <Row gutter={[10, 10]} style={{ marginBottom: "30px" }}>
            <Col xl={20} xxl={20} style={{ justifyContent: "end", display: "flex" }}>
              {userData?.profile?.rights?.adminClient === true && (
                <Button
                  shape="circle"
                  type="primary"
                  icon={<Glyph name="manage_accounts" />}
                  onClick={() => setIsModalOpen(true)}
                ></Button>
              )}
            </Col>
          </Row>
          <TransfertInfos />
        </>
      ) : (
        <Row gutter={[10, 10]} style={{ justifyContent: "center" }}>
          <Col xl={10} xxl={10}>
            <Row gutter={[10, 10]} style={{ marginBottom: "30px" }}>
              <Col xl={24} xxl={24} style={{ justifyContent: "end", display: "flex" }}>
                {userData?.profile?.rights?.adminClient === true && (
                  <Button
                    shape="circle"
                    type="primary"
                    icon={<Glyph name="manage_accounts" />}
                    onClick={() => setIsModalOpen(true)}
                  ></Button>
                )}
              </Col>
            </Row>
            <Row gutter={[10, 10]} style={{ justifyContent: "center" }}>
              <Col span={14}>
                <Card>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexFlow: "column",
                      alignItems: "center",
                    }}
                  >
                    <Delivred />
                    <Typography.Text strong style={{ marginTop: "16px" }}>
                      {t("transfert.noMove")}
                    </Typography.Text>
                  </div>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
      <Modal
        title={
          <Typography.Title level={5} style={{ margin: "0", textAlign: "center" }}>
            {t("transfert.addAndUpdateFile")}
          </Typography.Title>
        }
        open={isModalOpen}
        footer={null}
        onCancel={() => {
          setIsModalOpen(false);
          if (!isNil(filesInfos)) {
            dispatch(removeFilesInfos());
          }
        }}
      >
        <div className={styles.upload} style={{ display: "flex", justifyContent: "center" }}>
          <Upload {...props} className="upload-sider" maxCount={1}>
            <Button
              size="medium"
              type="primary"
              icon={
                <Glyph name="note_add" style={{ fontWeight: "normal", fontSize: size.xxxxl }} />
              }
            >
              <Typography.Text style={{ color: "white" }}>{t("transfert.addFile")}</Typography.Text>
            </Button>
          </Upload>
        </div>
        {filesInfos?.isSuccess === false && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
              flexFlow: "column",
            }}
          >
            <Alert message={t("transfert.error")} type="error" />
            <Table pagination={false} columns={columns} dataSource={data} />
          </div>
        )}
        {filesInfos?.isSuccess === true && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "16px",
              flexFlow: "column",
            }}
          >
            <Alert message={t("transfert.success")} type="success" />
            <div style={{ display: "flex", justifyContent: "center", marginTop: "16px" }}>
              <Typography.Text strong style={{ marginRight: "8px", color: colors?.success_light }}>
                {t("transfert.created")} : {filesInfos?.created}
              </Typography.Text>
              <Typography.Text strong style={{ marginRight: "8px", color: colors?.pending_light }}>
                {t("transfert.updated")} : {filesInfos?.updated}
              </Typography.Text>
              <Typography.Text strong style={{ color: colors?.error_light }}>
                {t("transfert.deleted")} : {filesInfos?.deleted}
              </Typography.Text>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

export default Transfert;
