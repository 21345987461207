import { Card, Typography } from "antd";
import { useTranslation } from "react-i18next";
import RightPanelMiscEdit from "./RightPanelMiscEdit";

const RightPanelMisc = ({ userData }) => {
  const { t } = useTranslation();

  const aboutMe = userData.aboutMe;

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        gap: "8px",
      }}
    >
      {aboutMe && (
        <div style={{ width: "100%" }}>
          {!userData.profile ? (
            <div
              style={{ marginBottom: "32px", display: "flex", flexDirection: "column", gap: "8px" }}
            >
              <Typography.Text strong>{t("contactList.biography")}</Typography.Text>

              <Typography.Text>
                {aboutMe[0].content ? aboutMe[0].content : t("contactList.noData")}
              </Typography.Text>

              <Typography.Text strong>{t("contactList.hobbies")}</Typography.Text>

              {aboutMe[1].items && aboutMe[1].items.length > 0 ? (
                aboutMe[1].items.map((item, index) => (
                  <Card key={index}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography.Text>{item}</Typography.Text>
                    </div>
                  </Card>
                ))
              ) : (
                <Typography.Text>{t("contactList.noData")}</Typography.Text>
              )}
            </div>
          ) : (
            <RightPanelMiscEdit userData={userData} />
          )}
        </div>
      )}
    </div>
  );
};

export default RightPanelMisc;
