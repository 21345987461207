import React from "react";
import { Skeleton } from "antd";
import useDesignTokens from "../../../../../hook/useDesignTokens";

const TicketSkeleton = ({ rights }) => {
  const { symbols } = useDesignTokens();
  return (
    <div
      style={{ position: "absolute", zIndex: 1000, width: "100%", height: "100%", top: 0, left: 0 }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
          padding: "0 14px",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: 24,
            position: "relative",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 6,
              marginBottom: -2,
            }}
          >
            <Skeleton paragraph={false} title={{ width: 300 }} />
            <Skeleton paragraph={false} title={{ width: 150 }} />
          </div>

          <Skeleton.Button
            style={{ width: 128, height: 50, borderRadius: 25, position: "absolute", right: 0 }}
          />
        </div>

        {/* {(rights.manager || rights.agent) && (
          <Skeleton.Button
            style={{
              width: 210,
              height: 56,
              borderRadius: symbols.base_shape.radius,
            }}
          />
        )} */}
        <div
          style={{
            display: "flex",
            gap: 8,
            flex: "none",
            marginBottom: 12,
            height: "calc(100% - 130px)",
          }}
        >
          <div style={{ width: "100%" }}>
            <Skeleton.Button
              style={{
                width: "100%",
                height: "100%",
                flexGrow: 1,
                borderRadius: symbols.base_shape.radius,
              }}
            />
          </div>
          <div style={{ width: "60%" }}>
            <Skeleton.Button
              style={{
                width: "100%",
                height: "100%",
                flexGrow: 1,
                borderRadius: symbols.base_shape.radius,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketSkeleton;
