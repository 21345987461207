import { Card, Typography, Spin } from "antd";
import React, { useState } from "react";

// import component
import Glyph from "../../../Common/Glyph/Glyph";
import FanSpeedDimmer from "./Dimmer/FanSpeedDimmer";

// import css
import styles from "./SettingsUI.module.less";

import { useTranslation } from "react-i18next";

const SettingsFanSpeed = ({ extra, zone, isAuto, setIsAuto, selectedRoom }) => {
  const { t } = useTranslation();

  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Card
      className={styles["SettingsUI"]}
      title={
        <Typography.Title level={5} style={{ display: "flex", alignItems: "center" }}>
          <Glyph name="air" className={styles["SettingsUI__pictos"]} />
          {zone ? t("Ventilation") + ` - zone ${zone.label}` : t("Ventilation")}
        </Typography.Title>
      }
      extra={
        isLoading ? (
          <Spin />
        ) : status === "error" ? (
          <Glyph name="cancel" className={styles["SettingsUI__error"]} />
        ) : status === "success" ? (
          <Glyph name="check_circle" className={styles["SettingsUI__success"]} />
        ) : (
          extra
        )
      }
      style={{ padding: "8px", margin: "0 16px 16px" }}
    >
      <FanSpeedDimmer
        setStatus={setStatus}
        setIsLoading={setIsLoading}
        zone={zone}
        isAuto={isAuto}
        setIsAuto={setIsAuto}
        isLoading={isLoading}
        selectedRoomNomap={selectedRoom}
      />
    </Card>
  );
};

export default SettingsFanSpeed;
