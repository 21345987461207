import { LoadingOutlined } from "@ant-design/icons";
import { useKeycloak } from "@react-keycloak/web";
import { Badge, Calendar, Card, Col, Row, Space, Spin, Typography } from "antd";
import { logEvent } from "firebase/analytics";
import moment from "moment";
import { T, cond, equals, isEmpty, isNil } from "ramda";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Masonry from "react-masonry-css";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import useDesignTokens from "../../../hook/useDesignTokens";
import { analytics } from "../../../services/api/Firebase/Firebase";
import { getPlanning } from "../../../services/redux/services/PlanningWS";
import Glyph from "../../Common/Glyph/Glyph";
import { TitleSource } from "../../Common/Teleporters/Title";
import Tile from "../../Common/Tile/Tile";
import Cgu from "../Cgu/Cgu";
import FastBookingWidget from "../FastBooking/Widget";
import FastBookingWidgetV2 from "../FastBookingV2/Widget";
import InstantMessagingWidget from "../InstantMessaging/Widget";
import NewsWidget from "../News/Widget";
import NewsWidget2 from "../NewsV2/Widget";
import PlacesWidget from "../Places/Widget";
import QrCode from "../QrCode/QrCode";
import SpaceBookingWidget from "../SpaceBooking/Widget";
import TicketingWidget from "../Ticketing/Widget";
import styles from "./Dashboard.module.less";

const Dashboard = () => {
  const dispatch = useDispatch();
  const { i18n, t } = useTranslation();
  const { colors } = useDesignTokens();
  const { keycloak } = useKeycloak();

  const views = useSelector((state) => state.userWS.userData?.views);
  const planning = useSelector((state) => state.planningWS.planning);
  const userData = useSelector((state) => state.userWS.userData);

  const [day, setDay] = useState(moment().format("DD/MM/YYYY"));
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    !isEmpty(userData) &&
      dispatch(
        getPlanning({
          locale: i18n.language.split("-")[0],
          startDate: moment().startOf("week").toISOString(),
          endDate: moment().endOf("month").toISOString(),
        }),
      );
  }, [dispatch, i18n.language, userData]);

  const dateCellRender = (value) => {
    const badges = planning?.slots?.items.filter(
      (i) => moment(i.startDate).format("DD/MM/YYYY") === moment(value).format("DD/MM/YYYY"),
    );
    return !isEmpty(badges) ? <Badge status="success" /> : <Badge />;
  };

  const planningDay = planning?.slots?.items
    .filter((i) => moment(i.startDate).format("DD/MM/YYYY") === day)
    .map((item) => {
      return item;
    });

  const onChangeDate = (value) => {
    logEvent(analytics, "planning_select_date_current_week");
    if (!moment(value).isSame(moment(), "month")) {
      dispatch(
        getPlanning({
          locale: i18n.language.split("-")[0],
          startDate: moment(value).startOf("month").toISOString(),
          endDate: moment(value).endOf("month").toISOString(),
        }),
      );
    } else {
      dispatch(
        getPlanning({
          locale: i18n.language.split("-")[0],
          startDate: moment().startOf("week").toISOString(),
          endDate: moment(value).endOf("month").toISOString(),
        }),
      );
    }
    setDay(moment(value).format("DD/MM/YYYY"));
  };

  const renderWidget = (view, index) => {
    if (
      (view.type === "socialnews" && !view.cold && view.layout?.dashboard) ||
      (view.type === "news" && !view.cold)
    )
      return null;

    const widgetContent = cond([
      [equals("fastBooking"), () => <FastBookingWidget planningDay={planningDay} />],
      [equals("fastBookingV2"), () => <FastBookingWidgetV2 planningDay={planningDay} />],
      [equals("ticketing"), () => <TicketingWidget view={view} />],
      [equals("spaceBooking"), () => <SpaceBookingWidget />],
      [equals("places"), () => <PlacesWidget view={view} />],
      [equals("instantMessaging"), () => <InstantMessagingWidget />],
    ])(view.type);

    const Element = cond([
      [equals("socialnews"), () => "div"],
      [equals("news"), () => "div"],
      [equals("webview"), () => "a"],
      [equals("places"), () => "div"],
      [T, () => Link],
    ])(view.type);

    return (
      <Element
        key={index}
        to={`/${view.type}${
          view.type === "ticketing" ? `?group=${view.ticketing?.groupType}` : ""
        }`}
        href={view.type === "webview" ? `${view.url}?token=${keycloak.token}` : undefined}
        target={view.type === "webview" ? "_blank" : undefined}
        rel="noreferrer"
        style={{ display: "block" }}
        onClick={() => {
          logEvent(analytics, `${view.title}_access`, {
            from: view.type,
          });
        }}
      >
        <Card>
          <div
            style={{
              minHeight: view.type === "ticketing" ? 154 : "unset",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <Space align="end">
              {view.icon && (
                <Glyph
                  name={view.icon}
                  style={{ fontSize: "30px", color: colors.interactive_03, verticalAlign: "1px" }}
                />
              )}
              <Typography.Title
                level={5}
                style={{ textTransform: "uppercase", color: colors.grey_40, cursor: "pointer" }}
              >
                {t(view.title, { ns: "csv" })}
              </Typography.Title>
            </Space>
            {view.layout.dashboard.description && (
              <Typography.Paragraph>{view.layout.dashboard.description}</Typography.Paragraph>
            )}
            {view.layout.dashboard.data && (
              <p
                dangerouslySetInnerHTML={{
                  __html: view?.layout?.dashboard?.data[0]?.content?.slice(0, 200),
                }}
              />
            )}
            {widgetContent}
          </div>
        </Card>
      </Element>
    );
  };

  useEffect(() => {
    if (isEmpty(userData) || isNil(userData?.settings) || isEmpty(userData?.settings)) return;
    if (
      moment(userData?.settings?.cguUpdatedAt).isAfter(moment(userData?.settings?.cguReadAt)) ||
      moment(userData?.settings?.privacyPolicyUpdatedAt).isAfter(
        moment(userData?.settings?.cguReadAt),
      ) ||
      isNil(userData?.settings?.cguReadAt) ||
      isEmpty(userData?.settings?.cguReadAt)
    ) {
      setIsModalOpen(true);
    }
  }, [
    userData,
    userData?.settings?.cguReadAt,
    userData?.settings?.cguUpdatedAt,
    userData?.settings?.privacyPolicyUpdatedAt,
  ]);

  return (
    <Row gutter={[10, 10]} style={{ justifyContent: "center", height: "100%" }}>
      <TitleSource>{t("home", { ns: "csv" })}</TitleSource>
      <Col xl={16} xxl={12}>
        <Row gutter={[10, 10]}>
          {!!(views || []).find(
            (v) =>
              (v.type === "socialnews" && !v.cold && v.layout?.dashboard) ||
              (v.type === "news" && v.layout?.dashboard && !v.cold),
          ) && (
            <Col span={24}>
              {!!(views || []).find((v) => v.type === "socialnews") ? (
                <NewsWidget2 />
              ) : (
                <NewsWidget />
              )}
            </Col>
          )}
          <Col span={24}>
            <Masonry className="my-masonry-grid" breakpointCols={2}>
              {(views || [])
                .reduce((acc, curr) => (curr.layout?.dashboard ? [...acc, curr] : acc), [])
                .sort((a, b) => a.layout.dashboard.position - b.layout.dashboard.position)
                .map(renderWidget)}
            </Masonry>
          </Col>
        </Row>
      </Col>

      {userData?.views?.find((view) => view?.type === "planning") && (
        <Col xl={8} xxl={6}>
          <Card style={{ marginBottom: 16 }}>
            {!planning ? (
              <div className={styles["loader-planning"]}>
                <div>
                  <Spin indicator={<LoadingOutlined style={{ fontSize: 48 }} spin />} />
                  <br />
                  <Typography.Title level={3} style={{ textAlign: "center" }}>
                    {t("LoadingPlanning")}
                  </Typography.Title>
                </div>
              </div>
            ) : (
              <>
                <Typography.Title
                  level={5}
                  style={{ textTransform: "uppercase", color: colors.grey_40, margin: 0 }}
                >
                  {t("agenda", { ns: "csv" })}
                </Typography.Title>
                <Calendar
                  mode="month"
                  fullscreen={false}
                  dateCellRender={dateCellRender}
                  onChange={onChangeDate}
                />
                <div
                  style={{
                    content: "",
                    display: "block",
                    height: "1px",
                    width: "90%",
                    margin: "15px auto",
                    backgroundColor: "#A8BEC3",
                  }}
                ></div>
                <div style={{ overflow: "auto", maxHeight: "270px" }}>
                  {planningDay &&
                    planningDay.map((p, index) => (
                      <Tile
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          height: "auto",
                          padding: "10px",
                          alignItems: "start",
                          margin: "0 10px 10px 0",
                        }}
                        className={styles["planning"]}
                        key={index}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column",
                            marginRight: "20px",
                            minWidth: "50px",
                          }}
                        >
                          <Typography.Paragraph strong>
                            {p.isAllDay ? "00:00" : moment(p.startDate).format("HH:mm")}
                          </Typography.Paragraph>
                          <Typography.Paragraph strong>
                            {p.isAllDay ? "23:59" : moment(p.endDate).format("HH:mm")}
                          </Typography.Paragraph>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            flexFlow: "column",
                            marginBottom: "0px",
                            minWidth: "200px",
                          }}
                        >
                          <Typography.Paragraph strong>
                            <Glyph
                              name="event"
                              style={{
                                verticalAlign: "-3px",
                                marginRight: "8px",
                                color: colors.secondary_base,
                              }}
                            />
                            {p.title}
                          </Typography.Paragraph>
                          {p.resources.length !== 0 ? (
                            <Typography.Paragraph strong style={{ marginBottom: "0px" }}>
                              <Glyph
                                name="place"
                                style={{
                                  verticalAlign: "-3px",
                                  marginRight: "8px",
                                  color: colors.secondary_base,
                                }}
                              />
                              {p.locations.map((location, index) => (
                                <span key={index}>{location?.title}</span>
                              ))}
                            </Typography.Paragraph>
                          ) : null}
                        </div>
                      </Tile>
                    ))}
                </div>
              </>
            )}
          </Card>
        </Col>
      )}

      {process.env.REACT_APP_QRCODE === "true" && <QrCode />}

      <Cgu
        isModalOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      />
    </Row>
  );
};

export default Dashboard;
