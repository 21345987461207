import { Button, Card, Col, Divider, Form, Typography, Image } from "antd";
import ReactQuill, { Quill } from "react-quill";
import Glyph from "../../../../Common/Glyph/Glyph";
import useDesignTokens from "../../../../../hook/useDesignTokens";
import styles from "../../News.module.less";
import { isEmpty, isNil } from "ramda";
import { useRef, useState } from "react";
import Tile from "../../../../Common/Tile/Tile";
import { useKeycloak } from "@react-keycloak/web";
import PicturesLibrary from "./PicturesLibrary";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import I18nInput from "../../../../Common/Inputs/I18nInputs/I18nInput";
import AddIframe from "./AddIframe";
import imageCompressor from "quill-image-compress";
import AddPicture from "./AddPicture";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

Quill.register("modules/imageCompressor", imageCompressor);

const Toolbar = ({
  size,
  news,
  lang,
  contentNews,
  setContentNews,
  setSelectPictures,
  selectPictures,
}) => {
  const { colors, symbols } = useDesignTokens();
  const { t, i18n } = useTranslation();
  const reactQuillRef = useRef();
  const { sectionId } = useParams();

  const [disabled, setDisabled] = useState(true);
  const { keycloak } = useKeycloak();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isIframe, setIsIframe] = useState(false);
  const [url, setUrl] = useState();
  const [cursorPos, setCursorPos] = useState();
  const [isPicture, setIsPicture] = useState(false);

  const userData = useSelector((state) => state.userWS.userData);

  const handleContentChange = (value) => {
    let content = contentNews;
    content = value !== "<p><br></p>" ? value : value.replace("<p><br></p>", "");
    setContentNews({ ...contentNews, [lang]: content });
  };

  return (
    <>
      <Tile className={styles.toolbar} key={lang}>
        <div
          className={clsx({ [styles.disabled]: disabled })}
          style={{ display: !disabled ? "none" : "block" }}
        ></div>

        <div id={`corps`}>
          <select className="ql-header" defaultValue={""} onChange={(e) => e.persist()}>
            <option value="1"></option>
            <option value="2"></option>
            <option value=""></option>
          </select>
          <div className="ql-formats">
            <button className="ql-bold"></button>
            <button className="ql-italic"></button>
            <button className="ql-underline"></button>
          </div>
          <div className="ql-formats">
            <button className="ql-list" value="ordered"></button>
            <button className="ql-list" value="bullet"></button>
            <select className="ql-align">
              <option selected></option>
              <option value="center"></option>
              <option value="right"></option>
              <option value="justify"></option>
            </select>
          </div>
          <div className="ql-formats">
            <button className="ql-link"></button>
          </div>
          <div
            className="ql-formats"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsIframe(true);
              setCursorPos(reactQuillRef.current.getEditorSelection());
            }}
          >
            <Glyph name="code" />
          </div>
          <div
            className="ql-formats"
            style={{ cursor: "pointer" }}
            onClick={() => {
              setIsPicture(true);
              setCursorPos(reactQuillRef.current.getEditorSelection());
            }}
          >
            <Glyph name="image" />
          </div>
        </div>
      </Tile>
      <Divider />
      <Col xl={size} xxl={size} style={{ margin: "0 auto" }}>
        <Card>
          <Form.Item
            // noStyle
            name={["title"]}
            rules={[
              {
                required: true,
                message: t("obligatory"),
              },
            ]}
          >
            <I18nInput
              className={styles["input"]}
              locale={lang}
              placeholder={t("news.editor.title")}
              style={{ fontWeight: "bold", fontSize: size.xxxxl }}
              onFocus={() => setDisabled(true)}
            />
          </Form.Item>

          <Form.Item
            // noStyle
            name={["subtitle"]}
            rules={[
              {
                required: !userData?.views?.find((u) => u?.id === parseInt(sectionId))?.cold
                  ? true
                  : false,
                message: t("obligatory"),
              },
            ]}
          >
            <I18nInput
              className={styles["input"]}
              locale={lang}
              placeholder={t("news.editor.subtitle")}
              style={{ margin: "8px 0 0 0", fontWeight: "bold", fontSize: size.xl }}
              onFocus={() => setDisabled(true)}
            />
          </Form.Item>

          <div
            style={{
              position: "relative",
              marginTop: "30px",
              height: 300,
              overflow: "hidden",
              borderRadius: symbols.base_shape.radius,
            }}
          >
            {(isEmpty(news) || isNil(news) || isNil(news?.header)) && isNil(selectPictures) && (
              <div
                className={styles.addPictures}
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                <Typography.Text style={{ display: "flex", flexFlow: "column" }}>
                  <Glyph
                    name="add_photo_alternate"
                    style={{ fontSize: "100px", color: colors.grey_60 }}
                  />
                  {t("news.editor.header")}
                </Typography.Text>
              </div>
            )}

            {!isNil(selectPictures) ? (
              <div
                className={styles["newsEditorPictures"]}
                style={{ display: "flex", alignItems: "center", height: 300 }}
              >
                <Image
                  preview={false}
                  src={`${selectPictures?.url}?access_token=${keycloak.token}`}
                />
              </div>
            ) : !isEmpty(news) && !isNil(news) && !isNil(news?.header) ? (
              <div
                className={styles["newsEditorPictures"]}
                style={{ display: "flex", alignItems: "center", height: 300 }}
              >
                <Image
                  preview={false}
                  src={`${news?.header[i18n.language.split("-")[0]]?.url}?access_token=${
                    keycloak.token
                  }`}
                />
              </div>
            ) : null}

            {(!isNil(selectPictures) || (!isEmpty(news) && !isNil(news))) && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 998,
                  right: "20px",
                  bottom: "20px",
                  display: "flex",
                }}
              >
                <Button
                  icon={<Glyph name="edit" />}
                  shape="circle"
                  type="secondary"
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                ></Button>
              </div>
            )}
          </div>
          <ReactQuill
            key={lang}
            ref={reactQuillRef}
            value={contentNews[lang]}
            style={{ marginTop: "16px", marginBottom: "16px", height: "700px", padding: 8 }}
            readOnly={false}
            modules={{
              toolbar: "#corps",
            }}
            placeholder={t("news.editor.content")}
            onFocus={() => setDisabled(false)}
            onChange={(value) => handleContentChange(value)}
          />
        </Card>
      </Col>

      <AddIframe
        reactQuillRef={reactQuillRef}
        isIframe={isIframe}
        onCancel={() => {
          setIsIframe(false);
        }}
        setUrl={setUrl}
        url={url}
        cursorPos={cursorPos}
      />

      <PicturesLibrary
        setSelectPictures={setSelectPictures}
        selectPictures={selectPictures}
        isModalOpen={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      />

      <AddPicture
        reactQuillRef={reactQuillRef}
        isPicture={isPicture}
        onCancel={() => {
          setIsPicture(false);
        }}
        cursorPos={cursorPos}
      />
    </>
  );
};

export default Toolbar;
