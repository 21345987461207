/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Switch, Route, useHistory } from "react-router-dom";
import { TitleSource } from "../../../Common/Teleporters/Title";

import "./App.less";
import SwiziNews from "./lib/SwiziNewsEditor";
import { I18nextProvider, useTranslation } from "react-i18next";
// import i18n from "./lib/SwiziNewsEditor/lang/i18n";

// import tokens
import colors from "@tokens/web/colors.json";
import { Typography } from "antd";
import { useSelector } from "react-redux";

function App({ common, campusData, newsInfo }) {
  const history = useHistory();
  const [url, setUrl] = useState(null);
  const { t, i18n } = useTranslation();

  const views = useSelector((state) => state.userWS.userData?.views);

  let userData = localStorage.getItem("auth_data_dwm");
  userData = JSON.parse(userData);

  const commonProps = Object.assign(
    {},
    {
      token: userData.idToken,
      swiziApiUrl: process.env.REACT_APP_SWIZI_API_URL,
      swiziApiKey: process.env.REACT_APP_SWIZI_API_KEY,
      swiziSectionId: process.env.REACT_APP_SWIZI_SECTION_ID,
      appId: process.env.REACT_APP_ID,
      rmApiKey: campusData?.resourceManager?.apiKey,
      rmSecretKey: campusData?.resourceManager?.secretKey,
      rmApiUrl: process.env.REACT_APP_RM_URL,
    },
    common,
  );

  const newEditorProps = {
    ...commonProps,
    format: "localized",
    primaryColor: colors.primary_base,
    textColor: "#ffffff",
    isReminder: false,
    schneiderApiUrl: "https://api-dev.schneider.my-swizi.io",
    schneiderApiKey: "9a21666aabdd8e10f88fee5e8040c5a7",
    clientId: "schneider_c:5e2eed4c853811083cd35c5a",
    allowedGroups: ["6357", "6638", "6345"],
  };

  const newsViewerProps = {
    ...commonProps,

    accentColor: colors.secondary_base,
    topicTitleColor: colors.primary_dark,
    topicTitleBigColor: colors.grey_40,
    textColor: colors.primary_dark,
    textBackground: "#ffffff",

    onGoToNews: (topicId, contentId) => goToNews(topicId, contentId),
  };

  const goToNews = (topicId, contentId) => {
    logger.log("content", common);
    let url = "/news/" + common.swiziSectionId + "/";
    if (topicId) {
      url += topicId;
      if (contentId) url += "/" + contentId;
    }
    history.push(url);
    setUrl(url);
  };
  return (
    <I18nextProvider i18n={i18n}>
      <div className="App" style={{ height: "100vh" }}>
        <TitleSource>
          {t(views?.find((v) => v?.id === parseInt(common?.swiziSectionId))?.title, {
            ns: "csv",
          })}
        </TitleSource>
        <Switch>
          <Route path="/news/:sectionId/editor" exact>
            <SwiziNews.Editor {...newEditorProps} campusData={campusData} />
          </Route>
          <Route
            path="/news/:sectionId/:topicId/:contentId"
            render={(props) => {
              return (
                <SwiziNews.Viewer
                  {...newsViewerProps}
                  selectedTopicId={props.match.params.topicId}
                  selectedContentId={props.match.params.contentId}
                  campusData={campusData}
                  newsInfo={newsInfo}
                />
              );
            }}
          />
          <Route
            path="/news/:sectionId/:topicId"
            render={(props) => {
              return (
                <SwiziNews.Viewer
                  {...newsViewerProps}
                  selectedTopicId={props.match.params.topicId}
                  campusData={campusData}
                  newsInfo={newsInfo}
                />
              );
            }}
          />
          <Route path="/news/:sectionId" exact>
            <SwiziNews.Viewer {...newsViewerProps} newsInfo={newsInfo} />
          </Route>
          {/* <Route path="/" exact>
            <SwiziNews.Viewer {...newsViewerProps} showTrendingsOnly />
          </Route> */}
        </Switch>
      </div>
    </I18nextProvider>
  );
}

export default App;
