import { Button, Modal, Typography } from "antd";
import { useState } from "react";
import SegmentedLanguageSelector from "../SegmentedLanguageSelector";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const CreateRefModal = ({ showCreateRefModal, setShowCreateRefModal, type, createReferential }) => {
  const { t } = useTranslation();
  const appLocales = useSelector((state) => state.userWS?.userData?.locales);
  const [tempData, setTempData] = useState({
    type: type,
    label: Object.fromEntries(appLocales.map((l) => [l.title, ""])),
    icon: "",
  });

  const toSnakeCase = (str) => {
    return str
      .toLowerCase()
      .replace(/\s+/g, "")
      .replace(/[^a-zA-Z0-9]/g, "");
  };

  const handleSubmit = () => {
    createReferential({
      name: toSnakeCase(Object.values(tempData?.label).filter((value) => value !== "")[0]),
      label: tempData.label,
      [type === "type" && "icon"]: tempData?.[type === "type" && "icon"],
      type: type,
    });
  };

  return (
    <Modal
      open={showCreateRefModal}
      width={655}
      onCancel={() => setShowCreateRefModal(false)}
      title={null}
      footer={null}
      bodyStyle={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        padding: "16px",
      }}
    >
      <div style={{ display: "flex" }}>
        <Typography.Text strong style={{ fontSize: 16 }}>
          {t("ticketingv2.Add_")}
          {type === "type" && t("ticketingv2.aType")}
          {type === "category" && t("ticketingv2.aCat")}
          {type === "subCategory" && t("ticketingv2.aSubCat")}
        </Typography.Text>
      </div>

      <SegmentedLanguageSelector
        tempData={tempData}
        setTempData={setTempData}
        icon={type === "type"}
        caption={t("ticketingv2.NameAndIcon")}
        type={type}
      />

      <div style={{ display: "flex", marginTop: 24 }}>
        <div style={{ display: "flex", justifyContent: "space-around", width: "100%" }}>
          <Button
            type="tertiary"
            onClick={() => setShowCreateRefModal(false)}
            style={{ width: 270 }}
          >
            {t("ticketingv2.Cancel")}
          </Button>
          <Button
            type="primary"
            disabled={!Object.values(tempData?.label).some(Boolean)}
            onClick={handleSubmit}
            style={{ width: 270 }}
          >
            {t("ticketingv2.Continue")}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default CreateRefModal;
